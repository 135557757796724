import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
    OrganisationInfoInput,
    useOrganisationInfoDetails,
    useUpdateOrganisationInfo,
} from "../../../hooks/OrganisationInfoHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import OrganisationInfoForm from "./OrganisationInfoForm";
import { useMissingImagesQuickfix } from "../../../hooks/useMissingImagesQuickfix";

type RouteParams = {
    id: string;
};

export default function UpdateOrganisationInfoForm() {
    const { id } = useParams<RouteParams>();
    if (!id) {
        throw new Error("UpdateOrganisationInfoForm: Missing id");
    }

    const { value: organisationInfo, state } = useOrganisationInfoDetails(id);
    const saveCallback = useUpdateOrganisationInfo(id);

    const hotfixSBG2191Images = useMissingImagesQuickfix(organisationInfo?.images);

    const defaultValues = useMemo(() => {
        if (!organisationInfo) {
            return undefined;
        }

        return {
            title: organisationInfo.title,
            category: organisationInfo.category.id,
            minimumAge: organisationInfo.minimumAge,
            maximumAge: organisationInfo.maximumAge,
            description: organisationInfo.description,
            locations: organisationInfo.locations,
            contactName: organisationInfo.contact.name,
            contactEmail: organisationInfo.contact.email,
            contactPhoneNumber: organisationInfo.contact.phoneNumber,
            isActive: organisationInfo.isActive,
            organisationTag: organisationInfo.organisationTag?.id,
            externalLinks: organisationInfo.externalLinks,
            images: hotfixSBG2191Images.map((i) => i.id),
        } as OrganisationInfoInput;
    }, [organisationInfo, hotfixSBG2191Images]);

    if (state === RequestState.LOADING) {
        return <InlineLoading />;
    }

    if (!organisationInfo) {
        return <div>{strings.organisationInfoNotFound}</div>;
    }

    return (
        <OrganisationInfoForm
            saveCallback={saveCallback}
            storedImages={hotfixSBG2191Images}
            defaultValues={defaultValues}
        />
    );
}
