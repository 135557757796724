import { PropsWithChildren, useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";
import { BaseAPI, Configuration, ConfigurationParameters } from "../openapi/backend";
import { REACT_APP_BACKEND_URL } from "../utils.ts/Env";

export type CreateApi<T> = (params: ConfigurationParameters) => T;
export type CreateConfiguration = (params: ConfigurationParameters) => Configuration;

type ApiContextProps<T extends BaseAPI> = {
    contextProvider: React.Provider<T>;
    createApi: CreateApi<T>;
};

const backendParams: ConfigurationParameters = { basePath: REACT_APP_BACKEND_URL };

const includeAuthToken = (
    params: ConfigurationParameters,
    isAuthenticated: boolean,
    token?: string,
): ConfigurationParameters => {
    if (!token || !isAuthenticated) {
        return params;
    }

    return {
        headers: { Authorization: "Bearer " + token },
        ...params,
    };
};

export const ApiContextProvider = <T extends BaseAPI>({
    contextProvider: ContextProvider,
    createApi,
    children,
}: PropsWithChildren<ApiContextProps<T>>) => {
    // IMPORTANT: if more things will alter backendParams it will need a seperate state
    // so we can replace the current config
    const { isAuthenticated, user } = useAuth();
    const token = user?.id_token;
    const api = useRef(createApi(includeAuthToken(backendParams, isAuthenticated, token)));

    useEffect(() => {
        const params = includeAuthToken(backendParams, isAuthenticated, token);
        api.current.setConfiguration(new Configuration(params));
    }, [isAuthenticated, token]);

    return <ContextProvider value={api.current}>{children}</ContextProvider>;
};
