/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    IndicatorStatus,
    IndicatorStatusFromJSON,
    IndicatorStatusFromJSONTyped,
    IndicatorStatusToJSON,
    LinkTarget,
    LinkTargetFromJSON,
    LinkTargetFromJSONTyped,
    LinkTargetToJSON,
    QuestionOutput,
    QuestionOutputFromJSON,
    QuestionOutputFromJSONTyped,
    QuestionOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface IndicatorDescriptiveOutput
 */
export interface IndicatorDescriptiveOutput {
    /**
     *
     * @type {number}
     * @memberof IndicatorDescriptiveOutput
     */
    id: number;
    /**
     *
     * @type {IndicatorStatus}
     * @memberof IndicatorDescriptiveOutput
     */
    status: IndicatorStatus;
    /**
     *
     * @type {string}
     * @memberof IndicatorDescriptiveOutput
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof IndicatorDescriptiveOutput
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof IndicatorDescriptiveOutput
     */
    happeningTitle?: string;
    /**
     *
     * @type {Date}
     * @memberof IndicatorDescriptiveOutput
     */
    startDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof IndicatorDescriptiveOutput
     */
    endDate?: Date;
    /**
     *
     * @type {LinkTarget}
     * @memberof IndicatorDescriptiveOutput
     */
    linkTarget: LinkTarget;
    /**
     *
     * @type {boolean}
     * @memberof IndicatorDescriptiveOutput
     */
    includeNotes: boolean;
    /**
     *
     * @type {Array<QuestionOutput>}
     * @memberof IndicatorDescriptiveOutput
     */
    questions: Array<QuestionOutput>;
    /**
     *
     * @type {boolean}
     * @memberof IndicatorDescriptiveOutput
     */
    canAddContributors: boolean;
    /**
     *
     * @type {boolean}
     * @memberof IndicatorDescriptiveOutput
     */
    canAddCollaborators: boolean;
}

/**
 * Check if a given object implements the IndicatorDescriptiveOutput interface.
 */
export function instanceOfIndicatorDescriptiveOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "linkTarget" in value;
    isInstance = isInstance && "includeNotes" in value;
    isInstance = isInstance && "questions" in value;
    isInstance = isInstance && "canAddContributors" in value;
    isInstance = isInstance && "canAddCollaborators" in value;

    return isInstance;
}

export function IndicatorDescriptiveOutputFromJSON(json: any): IndicatorDescriptiveOutput {
    return IndicatorDescriptiveOutputFromJSONTyped(json, false);
}

export function IndicatorDescriptiveOutputFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): IndicatorDescriptiveOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : IndicatorStatusFromJSON(json["status"]),
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        happeningTitle: !exists(json, "happeningTitle") ? (undefined as any) : json["happeningTitle"],
        startDate: !exists(json, "startDate") ? (undefined as any) : new Date(json["startDate"]),
        endDate: !exists(json, "endDate") ? (undefined as any) : new Date(json["endDate"]),
        linkTarget: !exists(json, "linkTarget") ? (undefined as any) : LinkTargetFromJSON(json["linkTarget"]),
        includeNotes: !exists(json, "includeNotes") ? (undefined as any) : json["includeNotes"],
        questions: !exists(json, "questions")
            ? (undefined as any)
            : ((json["questions"] as Array<any>) ?? []).map(QuestionOutputFromJSON),
        canAddContributors: !exists(json, "canAddContributors") ? (undefined as any) : json["canAddContributors"],
        canAddCollaborators: !exists(json, "canAddCollaborators") ? (undefined as any) : json["canAddCollaborators"],
    };
}

export function IndicatorDescriptiveOutputToJSON(value?: IndicatorDescriptiveOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
