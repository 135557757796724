import { AnchorHTMLAttributes, ButtonHTMLAttributes, useMemo } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import InlineLoading from "../InlineLoading/InlineLoading";
import styles from "./TextButton.module.scss";

type ButtonType = "primary" | "secondary" | "tertiary" | "tertiary-alert" | "alternate" | "toolbar";

export interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    icon?: string;
    iconAlt?: string; // can be left undefined if the button text is descriptive enough
    isLoading?: boolean;
    buttonType?: ButtonType;
}

export default function TextButton({
    type,
    text,
    onClick,
    icon,
    iconAlt,
    className,
    isLoading = false,
    disabled,
    buttonType = "primary",
}: TextButtonProps) {
    const blockingOnClick = useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        return onClick;
    }, [onClick, isLoading]);

    return (
        <button
            className={cx(styles.button, styles[buttonType], className)}
            onClick={blockingOnClick}
            type={type ?? "button"}
            disabled={disabled}
        >
            <span className={cx(styles.buttonText, isLoading && styles.hide)}>{text}</span>
            {icon && <img className={`${isLoading && styles.hide}`} src={icon} alt={iconAlt ?? ""} />}

            {isLoading && <InlineLoading className={styles.loading} />}
        </button>
    );
}

interface LinkButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    text: string;
    icon?: string;
    iconAlt?: string;
    buttonType?: ButtonType;
}

export const LinkButton = ({ text: label, icon, iconAlt, buttonType, className, href, ...props }: LinkButtonProps) => {
    const Component = href && href.startsWith("/") ? Link : "a";

    return (
        <Component
            to={href!}
            href={href}
            className={cx(styles.button, styles.link, className, buttonType && styles[buttonType])}
            {...props}
        >
            <span className={styles.buttonText}>{label}</span>
            {icon && <img src={icon} alt={iconAlt ?? ""} />}
        </Component>
    );
};
