import { useCallback, useEffect, useMemo, useContext } from "react";
import { useForm } from "react-hook-form";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import Form from "../../core/Form/Form/Form";
import { SmallModal } from "../../core/Modal/Modal";
import { UpdateClusterOrganizerInput, useClusterOrganizerUpdate } from "../../../hooks/ClusterHooks";
import { TagCollection, TagType, TagViewModel, UserOutput } from "../../../openapi/backend";
import { isApprover, isChiefEditor, isEditor } from "../../../authentication/UserMethods";
import { canOnlySuggestHappenings } from "../../../authentication/Permissions";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { TagsContext } from "../../../contexts/TagsContext";
import InlineLoading from "../../core/InlineLoading/InlineLoading";

type FormInput = Omit<UpdateClusterOrganizerInput, "id">;

interface ContainerProps {
    isOpen: boolean;
    onDismiss: () => void;
    onSuccess: () => void;
    clusterId: string;
    organizer?: TagViewModel;
}

export interface ChangeClusterOrganizerModalProps {
    isOpen: boolean;
    onSubmit: (input: Omit<UpdateClusterOrganizerInput, "id">) => void;
    onDismiss: () => void;
    error?: Response;
    organizer?: TagViewModel;
    user: UserOutput;
    tags: TagCollection;
    isSubmitting: boolean;
}

const ChangeClusterOrganizerModalContainer = ({
    isOpen,
    onDismiss,
    onSuccess,
    clusterId,
    organizer,
}: ContainerProps) => {
    const { profile } = useContext(ProfileContext);
    const tags = useContext(TagsContext).activeTags;

    const [{ state, error }, updateOrganizer] = useClusterOrganizerUpdate();
    const onSubmit = useCallback(
        (input: Omit<UpdateClusterOrganizerInput, "id">) => updateOrganizer({ id: clusterId, ...input }),
        [updateOrganizer, clusterId],
    );

    useEffect(() => {
        if (state === RequestState.DONE) {
            onDismiss();
            onSuccess();
        }
    }, [state, onDismiss, onSuccess]);

    if (!profile || !tags) {
        return <InlineLoading />;
    }

    return (
        <ChangeClusterOrganizerModal
            isOpen={isOpen}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            error={error}
            organizer={organizer}
            user={profile}
            tags={tags}
            isSubmitting={state === RequestState.LOADING}
        />
    );
};

const ChangeClusterOrganizerModal = ({
    isOpen,
    onSubmit,
    onDismiss,
    error,
    organizer,
    user,
    tags,
    isSubmitting,
}: ChangeClusterOrganizerModalProps) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<FormInput>();

    const happeningGroupOptions = useMemo(() => {
        let temp: TagViewModel[];

        switch (true) {
            case isChiefEditor(user) || isApprover(user):
                temp = [...tags.happeningGroups, ...tags.partners];
                break;
            case isEditor(user):
                temp = tags.happeningGroups.filter(
                    (tag) =>
                        tag.id === organizer?.id ||
                        user.tags.some((t) => t.id === tag.id || tag.parentTags?.includes(t.id)),
                );
                break;
            case canOnlySuggestHappenings(user):
                temp = [
                    tags.happeningGroups.find((t) => t.id === organizer?.id),
                    ...user.tags.filter((t) => t.isActive && t.type === TagType.Partner),
                ].filter((t) => t !== undefined) as TagViewModel[];
                break;
            default:
                temp = tags.happeningGroups;
        }

        return temp.map((t) => ({ label: t.detailedName, value: t.id }));
    }, [tags.happeningGroups, tags.partners, user, organizer]);

    return (
        <SmallModal isOpen={isOpen} onDismiss={onDismiss} title={strings.update}>
            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                submitText={strings.save}
                cancelText={strings.cancel}
                waitingForSubmit={isSubmitting}
            >
                <FormDataListInput
                    name={"body"}
                    options={happeningGroupOptions}
                    label={strings.happeningGroup}
                    control={control}
                    defaultValue={undefined}
                    errors={errors.body}
                    list={"happeningGroupOptions"}
                />
            </Form>
        </SmallModal>
    );
};

export default ChangeClusterOrganizerModalContainer;
