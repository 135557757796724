import { KeiEducationType } from "../openapi/backend";

type GradeOption = {
    label: string;
    value: number;
};

export function getGradeOptions(educationType: KeiEducationType): GradeOption[] {
    const gradeOptions: GradeOption[] = [];
    const maxGrade = educationType === KeiEducationType.SecondarySchool ? 6 : 8;
    for (let i = 1; i <= maxGrade; i++) {
        gradeOptions.push({
            label: `${i}`,
            value: i,
        });
    }

    return gradeOptions;
}
