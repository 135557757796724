import React, { useCallback, useContext, useEffect } from "react";
import { MediaLibraryDataContext } from "../../../contexts/MediaLibraryDataContext";
import { useGetMediaLibrary, useRemoveFromUploadedByMe } from "../../../hooks/MediaHooks";
import strings from "../../../localization/strings";
import { ImageViewModel } from "../../../openapi/backend";
import { ActionType } from "../../../reducers/MediaLibraryReducer";
import { MediaLibraryInputProps, MediaLibraryModalProps } from "../../../types/MediaLibraryType";
import { toastSuccess } from "../../../utils.ts/Toaster";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { MediaLibrary } from "./MediaLibrary";

export type Props = MediaLibraryInputProps & MediaLibraryModalProps;

export const MediaLibraryContainer = (props: Props) => {
    const [requestState, callback] = useGetMediaLibrary();
    const [removeFromMyRequestState, removeFromMyCallback] = useRemoveFromUploadedByMe();
    const [{ content, shouldFetch, query }, dispatch] = useContext(MediaLibraryDataContext);

    const setQuery = useCallback((q: string) => dispatch({ type: ActionType.search, value: q }), [dispatch]);

    const onRemoveFromMy = useCallback(
        (media: ImageViewModel) => {
            removeFromMyCallback(media.id);
        },
        [removeFromMyCallback],
    );

    useEffect(() => {
        // @NOTE(Lejun) Currently shouldFetch is used as a way for child components to force a re-fetch of the media library
        // This is needed because the logics for the add/update reducer actions are not designed yet.
        // Refer to the reducer for more details.
        if (!shouldFetch) {
            return;
        }

        callback(query);
    }, [callback, query, shouldFetch]);

    useEffect(() => {
        if (requestState.value) {
            dispatch({ type: ActionType.set, value: requestState.value, purpose: "library" });
        }
    }, [dispatch, requestState]);

    useEffect(() => {
        if (removeFromMyRequestState.value) {
            dispatch({ type: ActionType.removeFromMy, value: removeFromMyRequestState.value });
            toastSuccess(
                strings.formatString(
                    strings.mediaDeletedFromUploadedByMe,
                    removeFromMyRequestState.value?.originalFileName,
                ) as string,
            );
        }
    }, [dispatch, removeFromMyRequestState]);

    return (
        <>
            <MediaLibrary
                {...props}
                libraryContent={content}
                query={query}
                setQuery={setQuery}
                onRemoveFromMy={onRemoveFromMy}
            />
            {requestState.error && (
                <ErrorMessage error={requestState.error} defaultMessage={strings.somethingWentWrong} isToast />
            )}
            {removeFromMyRequestState.error && <ErrorMessage error={removeFromMyRequestState.error} isToast />}
        </>
    );
};
