import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";
import { HappeningViewModel } from "../openapi/backend";
import { useMemberOptions, useProjectLeaderOptions } from "../hooks/UserHooks";
import { getHappeningGroupOptions } from "../utils.ts/GetHappeningGroupOptions";
import { ProfileContext } from "./ProfileContext";
import { TagsContext } from "./TagsContext";
import { Operation } from "../types/Operation";

type ClonedHappening = Omit<HappeningViewModel, "id">;

export type HappeningCloneContextType = {
    happening?: ClonedHappening;
    setHappening: (happening?: HappeningViewModel) => void;
};

export const HappeningCloneContext = React.createContext<HappeningCloneContextType>({
    setHappening() {},
});

export const HappeningCloneContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [value, setValue] = useState<ClonedHappening | undefined>();
    const { profile } = useContext(ProfileContext);
    const tags = useContext(TagsContext).activeTags;

    const allowedHappeningGroupIds = useMemo<string[] | undefined>(
        () => profile && getHappeningGroupOptions(Operation.CREATE, profile, tags).map((tag) => tag.id),
        [profile, tags],
    );

    // Get project leaders without passing a happeningId,
    // or else the cloned happening's current values will always be included.
    const { value: leaderOptions } = useProjectLeaderOptions();
    const { value: memberOptions } = useMemberOptions();

    const setHappening = useCallback(
        (happening?: HappeningViewModel) => {
            if (!happening) {
                setValue(undefined);
                return;
            }

            if (!leaderOptions || !memberOptions) {
                return;
            }

            // Only clone values that you are authorized for
            const shouldCloneHappeningGroup =
                happening.happeningGroup &&
                allowedHappeningGroupIds &&
                allowedHappeningGroupIds.includes(happening.happeningGroup.id);
            const shouldCloneProjectLeader =
                happening.projectLeader && leaderOptions.some((x) => x.id === happening.projectLeader?.id);
            const shouldCloneContact = happening.contact && leaderOptions.some((x) => x.id === happening.contact?.id);
            const projectMembers = happening.projectMembers.filter((member) =>
                memberOptions.some((x) => x.id === member.id),
            );

            const { id, ...idLess } = happening;
            setValue({
                ...idLess,
                publicationDate: undefined,
                happeningGroup: shouldCloneHappeningGroup ? happening.happeningGroup : undefined,
                projectLeader: shouldCloneProjectLeader ? happening.projectLeader : undefined,
                contact: shouldCloneContact ? happening.contact : undefined,
                projectMembers,
            });
        },
        [allowedHappeningGroupIds, leaderOptions, memberOptions],
    );

    const context = useMemo<HappeningCloneContextType>(() => {
        return {
            happening: value,

            setHappening,
        };
    }, [setHappening, value]);

    return <HappeningCloneContext.Provider value={context}>{children}</HappeningCloneContext.Provider>;
};
