import SelectField, { SelectFieldProps } from "../SelectField/SelectField";
import { useCallback } from "react";
import MultiField, { FieldProps } from "../MultiField/MultiField";

export type MultiSelectFieldProps = Omit<
    SelectFieldProps<string>,
    "value" | "onChange" | "multiple" | "defaultValue" | "transform"
> & {
    value?: string | string[];
    onChange: (selected: string[]) => void;
};

const stringTransform = (val: string) => val;
const MultiSelectField = ({ value, name, onChange, options, ...props }: MultiSelectFieldProps) => {
    const Field = useCallback(
        ({ index, fieldValue, listValue, onChange }: FieldProps<string>) => (
            <SelectField
                {...props}
                value={fieldValue}
                options={options.filter((x) => x.value === fieldValue || !listValue.includes(x.value))}
                name={`${name}.${index}.value`}
                transform={stringTransform}
                onChange={onChange}
            />
        ),
        [name, options, props],
    );

    return <MultiField Field={Field} value={value} onChange={onChange} />;
};

export default MultiSelectField;
