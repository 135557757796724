import { useMemo } from "react";
import { ImageViewModel } from "../openapi/backend";
import { hasValues } from "../utils.ts/Array";
import { DEFAULT_STORED_IMAGES } from "../constants/Defaults";

export function getImagesWithoutQuickfixImages(images: ImageViewModel[] | undefined) {
    if (hasValues(images)) {
        return images.filter((i) => i.fileName !== "placeholder-3e0a9c5d-fb9a-46ee-8545-057ebfea62e7.png");
    }
    return DEFAULT_STORED_IMAGES;
}

export function useMissingImagesQuickfix(images: ImageViewModel[] | undefined) {
    return useMemo(() => {
        return getImagesWithoutQuickfixImages(images);
    }, [images]);
}
