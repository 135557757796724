import React, { PropsWithChildren } from "react";
import { Configuration } from "../openapi/backend";
import { ClustersApi } from "../openapi/backend/apis/ClustersApi";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<ClustersApi> = (params) => new ClustersApi(new Configuration(params));

export const ClustersApiContext = React.createContext(createApi({}));

export const ClustersApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={ClustersApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
