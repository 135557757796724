import { canSendGeneralNotifications, canViewEmbeddedCodesTab } from "../authentication/Permissions";
import EmbeddedCodes from "../components/Forms/EmbeddedCodes/EmbeddedCodes";
import Notifications from "../components/Notifications/Notifications";
import NotificationsHistoryContainer from "../components/Notifications/NotificationsHistoryContainer";
import { AttachmentsContextProvider } from "../contexts/AttachmentsContext";
import strings from "../localization/strings";
import { NavigationTab } from "../types/NavigationTab";
import useTabRoute from "./UseTabRoute";

const notificationsTabs: NavigationTab[] = [
    {
        pathname: "send",
        label: strings.notifications,
        element: (
            <AttachmentsContextProvider>
                <Notifications />
            </AttachmentsContextProvider>
        ),
    },
    {
        pathname: "sent",
        label: strings.eventHistory,
        element: <NotificationsHistoryContainer />,
    },
    {
        pathname: "embedded-codes",
        label: strings.embeddedCodes,
        element: <EmbeddedCodes />,
        permissions: [canViewEmbeddedCodesTab],
    },
];

const useNotificationsRoute = () => useTabRoute("notifications", notificationsTabs, [canSendGeneralNotifications]);

export default useNotificationsRoute;
