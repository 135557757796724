import cx from "classnames";
import React, { useCallback, useState } from "react";
import strings from "../../../localization/strings";
import { ImageViewModel, MediaCategoryViewModel } from "../../../openapi/backend";
import { getCategoryName } from "../../../utils.ts/Media";
import CollapsibleListItem from "../CollapsibleListItem/CollapsibleListItem";
import LazyImage from "../LazyImage/LazyImage";
import styles from "./MediaCategoryListItem.module.scss";
import TrashIcon from "../../../assets/remove_icon_white.svg";
import ConfirmSoftDeleteMediaModal from "../ConfirmSoftDeleteMediaModal/ConfirmSoftDeleteMediaModal";
import CheckIcon from "../../../assets/check_icon.svg";

export type SelectionType = "single" | "multiple";

type Props = {
    className?: string;
    category: MediaCategoryViewModel;
    onMediaClick: (media: ImageViewModel) => void;
    onDeleteClick?: (media: ImageViewModel) => void;
    selectedMedia?: ImageViewModel[];
    defaultExpanded?: boolean;
    type?: SelectionType;
};

export default function MediaCategoryListItem({
    category,
    onMediaClick,
    onDeleteClick,
    selectedMedia,
    className,
    defaultExpanded,
    type = "multiple",
}: Props) {
    const [removeTarget, setRemoveTarget] = useState<ImageViewModel | undefined>(undefined);
    const clearRemoveTarget = useCallback(() => setRemoveTarget(undefined), []);

    const onRemoveImage = useCallback(() => {
        if (onDeleteClick && removeTarget) {
            onDeleteClick(removeTarget);
        }

        clearRemoveTarget();
    }, [onDeleteClick, removeTarget, clearRemoveTarget]);

    return (
        <>
            <CollapsibleListItem
                className={cx(styles.container, className)}
                title={
                    strings.formatString(
                        "{0} ({1})",
                        getCategoryName(category),
                        category.media.length.toString(),
                    ) as string
                }
                contentClassName={styles.categoryContent}
                defaultExpanded={defaultExpanded}
            >
                {category.media.map((image) => {
                    const selectedIndex = selectedMedia
                        ? selectedMedia.findIndex((selectedImage) => selectedImage.id === image.id)
                        : -1;

                    return (
                        <div key={image.id} className={styles.mediaContainer}>
                            <button
                                className={styles.item}
                                type="button"
                                onClick={() => onMediaClick(image)}
                                title={image.altText}
                            >
                                <LazyImage
                                    className={styles.image}
                                    width="100%"
                                    height="100%"
                                    src={image.href}
                                    alt={image.altText}
                                    draggable={false}
                                />
                                {selectedIndex !== -1 && (
                                    <div className={styles.selectedBox}>
                                        {type === "single" ? (
                                            <img
                                                src={CheckIcon}
                                                alt={strings.selected}
                                                className={styles.selectedIcon}
                                            />
                                        ) : (
                                            <p className={styles.selectedIndex}>{selectedIndex + 1}</p>
                                        )}
                                    </div>
                                )}
                            </button>
                            {onDeleteClick && (
                                <button
                                    className={styles.deleteButton}
                                    type="button"
                                    onClick={() => setRemoveTarget(image)}
                                >
                                    <LazyImage
                                        className={styles.image}
                                        width="100%"
                                        height="100%"
                                        src={TrashIcon}
                                        alt={strings.delete}
                                        draggable={false}
                                    />
                                </button>
                            )}
                        </div>
                    );
                })}
            </CollapsibleListItem>
            <ConfirmSoftDeleteMediaModal
                target={removeTarget}
                onCancel={clearRemoveTarget}
                onConfirm={onRemoveImage}
                message={strings.confirmDeleteMediaFromUploadedByMe}
            />
        </>
    );
}
