/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
    ArrangementApplicationOperation,
    ArrangementApplicationOperationFromJSON,
    ArrangementApplicationOperationFromJSONTyped,
    ArrangementApplicationOperationToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface ApplicationEventOutput
 */
export interface ApplicationEventOutput {
    /**
     *
     * @type {number}
     * @memberof ApplicationEventOutput
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof ApplicationEventOutput
     */
    date: Date;
    /**
     *
     * @type {ApplicationStatus}
     * @memberof ApplicationEventOutput
     */
    statusChange?: ApplicationStatus;
    /**
     *
     * @type {string}
     * @memberof ApplicationEventOutput
     */
    message?: string;
    /**
     *
     * @type {Array<ArrangementApplicationOperation>}
     * @memberof ApplicationEventOutput
     */
    change: Array<ArrangementApplicationOperation>;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof ApplicationEventOutput
     */
    triggeredBy?: ProfileShortOutput;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof ApplicationEventOutput
     */
    newReviewer?: ProfileShortOutput;
    /**
     *
     * @type {string}
     * @memberof ApplicationEventOutput
     */
    newFileName?: string;
}

/**
 * Check if a given object implements the ApplicationEventOutput interface.
 */
export function instanceOfApplicationEventOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "change" in value;

    return isInstance;
}

export function ApplicationEventOutputFromJSON(json: any): ApplicationEventOutput {
    return ApplicationEventOutputFromJSONTyped(json, false);
}

export function ApplicationEventOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationEventOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        date: !exists(json, "date") ? (undefined as any) : new Date(json["date"]),
        statusChange: !exists(json, "statusChange")
            ? (undefined as any)
            : ApplicationStatusFromJSON(json["statusChange"]),
        message: !exists(json, "message") ? (undefined as any) : json["message"],
        change: !exists(json, "change")
            ? (undefined as any)
            : ((json["change"] as Array<any>) ?? []).map(ArrangementApplicationOperationFromJSON),
        triggeredBy: !exists(json, "triggeredBy")
            ? (undefined as any)
            : ProfileShortOutputFromJSON(json["triggeredBy"]),
        newReviewer: !exists(json, "newReviewer")
            ? (undefined as any)
            : ProfileShortOutputFromJSON(json["newReviewer"]),
        newFileName: !exists(json, "newFileName") ? (undefined as any) : json["newFileName"],
    };
}

export function ApplicationEventOutputToJSON(value?: ApplicationEventOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
