import { useCallback, useEffect, useState } from "react";

export enum RequestState {
    IDLE,
    LOADING,
    DONE,
    ERROR,
}

export type ApiCallNoResult = {
    state: RequestState.IDLE | RequestState.LOADING;
    value?: never;
    error?: never;
};

export type ApiCallResolved<T> = {
    state: RequestState.DONE;
    value: T;
    error?: never;
};

export type ApiCallFailed = {
    state: RequestState.ERROR;
    value?: never;
    error: Response;
};

export type ApiCallState<T> = ApiCallNoResult | ApiCallResolved<T> | ApiCallFailed;

export type ApiFunction<T, A extends Array<any>> = (...args: A) => Promise<T>;
export type ApiCallbackReturn<T, A extends Array<any>> = [ApiCallState<T>, ApiFunction<T, A>, () => void];

export const readyForRequest = (state: RequestState): boolean => state !== RequestState.LOADING;
export const readyForSingleRequest = (state: RequestState): boolean =>
    readyForRequest(state) && state !== RequestState.DONE;

export function useApiCall<T>(apiCall: () => Promise<T>): ApiCallState<T> {
    const [callState, go] = useApiCallback(apiCall);

    useEffect(() => {
        go();
    }, [go]);

    return callState;
}

export function useApiCallback<T, A extends Array<any>>(apiCall: ApiFunction<T, A>): ApiCallbackReturn<T, A> {
    const [callState, setCallState] = useState<ApiCallState<T>>(() => ({ state: RequestState.IDLE }));

    const reset = useCallback(() => {
        setCallState({ state: RequestState.IDLE });
    }, []);

    const callback = useCallback(
        async (...args: A) => {
            setCallState({ state: RequestState.LOADING });
            try {
                const response = await apiCall(...args);
                setCallState({ state: RequestState.DONE, value: response });
                return response;
            } catch (error: any) {
                if (!(error instanceof Response)) {
                    throw error;
                }

                setCallState({ state: RequestState.ERROR, error });

                return Promise.reject(error);
            }
        },
        [apiCall],
    );

    return [callState, callback, reset];
}
