import { useCallback, useContext } from "react";
import { OrganisationApiContext } from "../contexts/OrganisationApiContext";
import {
    CreateOrganisationInfoRequest,
    GetOrganisationInfoOverviewForBackofficeRequest,
    OrganisationInfoBackofficeListViewModelPaginatedViewModel,
    OrganisationInfoViewModel,
    TagViewModel,
    UpdateOrganisationInfoRequest,
} from "../openapi/backend";
import { hasValues } from "../utils.ts/Array";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, useApiCall, useApiCallback } from "./UseApiCall";

export type OrganisationInfoFilter = Pick<
    GetOrganisationInfoOverviewForBackofficeRequest,
    "query" | "isActive" | "page"
> & {
    categories?: TagViewModel[];
};

export type GetOrganisationInfoInput = Pick<
    GetOrganisationInfoOverviewForBackofficeRequest,
    "orderBy" | "sortOrder" | "itemsPerPage"
>;
export type GetOrgansationInfoFun = (
    input: GetOrganisationInfoInput,
    filter: OrganisationInfoFilter,
) => Promise<OrganisationInfoBackofficeListViewModelPaginatedViewModel>;

export const useGetOrganisationInfoOverview = (): [
    ApiCallState<OrganisationInfoBackofficeListViewModelPaginatedViewModel>,
    GetOrgansationInfoFun,
    () => void,
] => {
    const api = useContext(OrganisationApiContext);
    const callback = useCallback<GetOrgansationInfoFun>(
        (input, filter) => {
            return api.getOrganisationInfoOverviewForBackoffice({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
                ...filter,
                categories: hasValues(filter.categories) ? filter.categories.map((t) => t.id) : undefined,
            });
        },
        [api],
    );

    return useApiCallback(callback);
};

export type CreateOrganisationInfoInput = Omit<CreateOrganisationInfoRequest, "version">;
export type CreateOrganisationInfoFun = (input: CreateOrganisationInfoInput) => Promise<OrganisationInfoViewModel>;

export const useCreateOrganisationInfo = (): [
    ApiCallState<OrganisationInfoViewModel>,
    CreateOrganisationInfoFun,
    () => void,
] => {
    const api = useContext(OrganisationApiContext);
    const callback = useCallback<CreateOrganisationInfoFun>(
        (input) => {
            return api.createOrganisationInfo({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
            });
        },
        [api],
    );

    return useApiCallback(callback);
};

export type OrganisationInfoInput = CreateOrganisationInfoInput | UpdateOrganisationInfoInput;

export const useGetAvailableOrganisationTags = (tagId?: string) => {
    const api = useContext(OrganisationApiContext);
    const callback = useCallback(() => {
        return api.getOrganisationTagsWithoutInfoPage({
            version: REACT_APP_BACKEND_VERSION,
            current: tagId,
        });
    }, [api, tagId]);
    return useApiCall(callback);
};

export type UpdateOrganisationInfoInput = Omit<UpdateOrganisationInfoRequest, "version" | "id">;
export type UpdateOrganisationInfoFun = (input: UpdateOrganisationInfoInput) => Promise<OrganisationInfoViewModel>;

export const useUpdateOrganisationInfo = (
    id: string,
): [ApiCallState<OrganisationInfoViewModel>, UpdateOrganisationInfoFun, () => void] => {
    const api = useContext(OrganisationApiContext);
    const callback = useCallback<UpdateOrganisationInfoFun>(
        (input) => {
            return api.updateOrganisationInfo({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
                id,
            });
        },
        [api, id],
    );

    return useApiCallback(callback);
};

export const useOrganisationInfoDetails = (id: string): ApiCallState<OrganisationInfoViewModel> => {
    const api = useContext(OrganisationApiContext);
    const callback = useCallback(() => api.getOrganisationInfo({ version: REACT_APP_BACKEND_VERSION, id }), [api, id]);
    return useApiCall(callback);
};
