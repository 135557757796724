import React, { useCallback } from "react";
import { Control, FieldError, useController } from "react-hook-form";
import { ImageViewModel, Video } from "../../../../openapi/backend";
import { MediaTargetType } from "../../../../types/MediaLibraryType";
import MediaPreview from "../../MediaPreview/MediaPreview";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

interface PartialFormData {
    images?: number[];
    videos?: Video[];
}

interface Props<TFieldValues extends PartialFormData> {
    className?: string;
    alreadyStoredImages: ImageViewModel[];
    type: MediaTargetType;
    control: Control<TFieldValues, object>;
    errors?: FieldError[];
    excludeVideos?: boolean;
}

export default function FormMediaLibraryInput<TFieldValues extends PartialFormData>({
    className,
    alreadyStoredImages,
    errors,
    control,
    ...props
}: Props<TFieldValues>) {
    const {
        // Currently the value of images is not synced with the form because there is a discrepancy between the data models
        // number[] vs ImageViewModel[]. So instead MediaPreview is the top level component that handles the data. If needed this should be moved to higher.
        field: { onChange: setImages },
    } = useController({
        name: "images" as FieldName<TFieldValues, number[]>,
        control,
    });
    const {
        field: { onChange: setVideos, value: videos },
    } = useController({
        name: "videos" as FieldName<TFieldValues, Video[]>,
        control,
    });

    const setMediaData = useCallback(
        (nextImages: ImageViewModel[], nextVideos?: Video[]) => {
            setImages(nextImages.map((i) => i.id));
            setVideos(nextVideos);
        },
        [setImages, setVideos],
    );

    return (
        <FormFieldContainer className={className} name="images" errors={errors}>
            <MediaPreview
                images={alreadyStoredImages}
                videos={videos as Video[] | undefined}
                onMediaChange={setMediaData}
                {...props}
            />
        </FormFieldContainer>
    );
}
