import { useEffect, useMemo, useState } from "react";
import strings from "../../../localization/strings";
import TextButton from "../TextButton/TextButton";
import styles from "./FloatingPublishModal.module.scss";
import Datepicker from "../Inputs/DatePicker/DatePicker";
import moment from "moment";
import { StringSelectField } from "../Inputs/SelectField/SelectField";
import { HourOptions, MinuteOptions } from "../../../types/TimeOptions";
import { SubmitHandler } from "react-hook-form";
import { roundToNearestMinutes } from "date-fns";

interface FloatingPublishModalProps {
    setPublicationDate: (value: Date) => void;
    onSubmit: SubmitHandler<any>;
    onSecondarySubmit: SubmitHandler<any>;
    waitingForSubmit?: boolean;
    initialPublicationDate?: Date;
}

export default function FloatingPublishModal({
    setPublicationDate,
    onSubmit,
    onSecondarySubmit,
    waitingForSubmit,
    initialPublicationDate,
}: FloatingPublishModalProps) {
    const dateFormat = "DD-MM-YYYY";
    const startDate = useMemo(() => {
        return moment(roundToNearestMinutes(new Date(), { nearestTo: 5 })).add(1, "hour");
    }, []);

    const [date, setDate] = useState<Date>(
        initialPublicationDate ? moment(initialPublicationDate).toDate() : startDate.toDate(),
    );
    const roundedDateTime = useMemo(() => moment(roundToNearestMinutes(date, { nearestTo: 5 })).add(1, "hour"), [date]);

    const [hours, setHours] = useState<string>(moment(initialPublicationDate || startDate).format("HH"));
    const [minutes, setMinutes] = useState<string>(moment(initialPublicationDate || startDate).format("mm"));
    const [selectedDateTime, setSelectedDateTime] = useState<Date>(date);

    useEffect(() => {
        setSelectedDateTime(moment(date).hours(Number(hours)).minutes(Number(minutes)).toDate());
    }, [setSelectedDateTime, date, hours, minutes]);

    const hourOptions = useMemo(() => {
        let options = HourOptions;

        if (roundedDateTime.format(dateFormat) === startDate.format(dateFormat)) {
            const filterFromTime = startDate.format("HH");
            options = HourOptions.slice(HourOptions.findIndex((item) => item === filterFromTime));
        }

        return options.map((time) => ({ label: time, value: time }));
    }, [roundedDateTime, startDate]);

    const minuteOptions = useMemo(() => {
        let options = MinuteOptions;

        if (roundedDateTime.format(dateFormat) === startDate.format(dateFormat) && hours === startDate.format("HH")) {
            const filterFromTime = startDate.format("mm");
            options = MinuteOptions.slice(MinuteOptions.findIndex((item) => item === filterFromTime));
        }

        return options.map((time) => ({ label: time, value: time }));
    }, [hours, roundedDateTime, startDate]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.dateTimeContainer}>
                    <div>
                        <label>{strings.date}</label>
                        <Datepicker
                            className={styles.dateTimePicker}
                            placeholderText={strings.date}
                            minDate={startDate.toDate()}
                            onChange={(selected) => {
                                if (selected) {
                                    setDate(selected);
                                }
                            }}
                            selected={date}
                        />
                    </div>
                    <div>
                        <label>{strings.time}</label>
                        <div className={styles.timeFields}>
                            <StringSelectField
                                className={styles.dateTimePicker}
                                value={hours}
                                onChange={setHours}
                                name={`publicationDate`}
                                options={hourOptions}
                            />
                            <div className={styles.colonText}>:</div>
                            <StringSelectField
                                className={styles.dateTimePicker}
                                value={minutes}
                                onChange={setMinutes}
                                name={`publicationDate`}
                                options={minuteOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.modalButtonContainer}>
                    <TextButton
                        text={strings.planPublish}
                        onClick={(data) => {
                            setPublicationDate(selectedDateTime);
                            onSubmit(data);
                        }}
                        isLoading={waitingForSubmit}
                    />
                    {onSecondarySubmit && (
                        <TextButton
                            text={strings.publishDirectly}
                            onClick={onSecondarySubmit}
                            isLoading={waitingForSubmit}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
