import { ReactNode, useMemo } from "react";
import { Column } from "react-table";
import strings from "../../localization/strings";
import { ProfileOutput } from "../../openapi/backend";
import StaticTable from "../core/StaticTable/StaticTable";
import ProfileRegistration from "../core/ProfileRegistration/ProfileRegistration";

export type ProfileRegistrationsTableProps = {
    profile: ProfileOutput;
};

type RegistrationsTableItem = {
    Registration: ReactNode;
};

export default function ProfileRegistrationsTable({ profile }: ProfileRegistrationsTableProps) {
    const columns: Column<RegistrationsTableItem>[] = [
        {
            Header: strings.name,
            accessor: "Registration",
            width: "100%",
        },
    ];

    const data = useMemo<RegistrationsTableItem[]>(
        () =>
            profile.registrations.map((registration, index) => {
                const profileRegistration = (
                    <ProfileRegistration
                        key={index}
                        name={registration.happeningTitle}
                        status={registration.status}
                        requiresPayment={registration.requiresPayment}
                        medicalNotes={registration.medicalNotes}
                        payment={registration.payment}
                        notes={registration.notes}
                        extraQuestionAnswers={registration.extraQuestionAnswers}
                    />
                );

                return {
                    Registration: <span>{profileRegistration}</span>,
                };
            }),
        [profile.registrations],
    );

    return <StaticTable columns={columns} data={data} />;
}
