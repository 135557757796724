/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum KeiBudgetType {
    SchoolItems = "SchoolItems",
    Clothes = "Clothes",
}

export function KeiBudgetTypeFromJSON(json: any): KeiBudgetType {
    return KeiBudgetTypeFromJSONTyped(json, false);
}

export function KeiBudgetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeiBudgetType {
    return json as KeiBudgetType;
}

export function KeiBudgetTypeToJSON(value?: KeiBudgetType | null): any {
    return value as any;
}
