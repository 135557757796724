import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import strings from "../../../localization/strings";
import { UpdateHappeningRequest, UpdateTarget } from "../../../openapi/backend";
import Form from "../../core/Form/Form/Form";
import FormRadioGroup from "../../core/Form/FormRadioGroup/FormRadioGroup";
import Modal from "../../core/Modal/Modal";

type FormInput = Pick<UpdateHappeningRequest, "updateTarget">;

interface SaveHappeningModalProps {
    isOpen: boolean;
    onConfirm: (updateTarget: UpdateTarget) => void;
    onDismiss: () => void;
    error?: Response;
    isSubmitting: boolean;
}

const SaveOpenHappeningModal = ({ isOpen, onConfirm, onDismiss, error, isSubmitting }: SaveHappeningModalProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<FormInput>();

    const target = watch("updateTarget");

    const onSubmit = useCallback((data: FormInput) => onConfirm(data.updateTarget!), [onConfirm]);

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={strings.confirmation}>
            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                submitText={strings.save}
                cancelText={strings.cancel}
                waitingForSubmit={isSubmitting}
            >
                <FormRadioGroup
                    name={"updateTarget"}
                    title={strings.recurrenceUpdateTitle}
                    options={[
                        {
                            label: strings.recurrenceUpdateTargetThis,
                            value: UpdateTarget.This,
                        },
                        {
                            label: strings.recurrenceUpdateTargetUpcoming,
                            value: UpdateTarget.Upcoming,
                        },
                        {
                            label: strings.recurrenceUpdateTargetAll,
                            value: UpdateTarget.All,
                        },
                    ]}
                    control={control}
                    required
                    value={target}
                    errors={errors.updateTarget}
                />
            </Form>
        </Modal>
    );
};

export default SaveOpenHappeningModal;
