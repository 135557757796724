/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    ProfileOutput,
    ProfileOutputFromJSON,
    ProfileOutputFromJSONTyped,
    ProfileOutputToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface UserListOutput
 */
export interface UserListOutput {
    /**
     *
     * @type {string}
     * @memberof UserListOutput
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof UserListOutput
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof UserListOutput
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof UserListOutput
     */
    familyName: string;
    /**
     *
     * @type {Date}
     * @memberof UserListOutput
     */
    dateOfBirth?: Date;
    /**
     *
     * @type {string}
     * @memberof UserListOutput
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof UserListOutput
     */
    profession?: string;
    /**
     *
     * @type {ImageViewModel}
     * @memberof UserListOutput
     */
    avatar?: ImageViewModel;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof UserListOutput
     */
    tags: Array<TagViewModel>;
    /**
     *
     * @type {Array<Role>}
     * @memberof UserListOutput
     */
    roles: Array<Role>;
    /**
     *
     * @type {Array<ProfileOutput>}
     * @memberof UserListOutput
     */
    subprofiles: Array<ProfileOutput>;
}

/**
 * Check if a given object implements the UserListOutput interface.
 */
export function instanceOfUserListOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "roles" in value;
    isInstance = isInstance && "subprofiles" in value;

    return isInstance;
}

export function UserListOutputFromJSON(json: any): UserListOutput {
    return UserListOutputFromJSONTyped(json, false);
}

export function UserListOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        givenName: !exists(json, "givenName") ? (undefined as any) : json["givenName"],
        familyName: !exists(json, "familyName") ? (undefined as any) : json["familyName"],
        dateOfBirth: !exists(json, "dateOfBirth") ? (undefined as any) : new Date(json["dateOfBirth"]),
        phoneNumber: !exists(json, "phoneNumber") ? (undefined as any) : json["phoneNumber"],
        profession: !exists(json, "profession") ? (undefined as any) : json["profession"],
        avatar: !exists(json, "avatar") ? (undefined as any) : ImageViewModelFromJSON(json["avatar"]),
        tags: !exists(json, "tags")
            ? (undefined as any)
            : ((json["tags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        roles: !exists(json, "roles") ? (undefined as any) : ((json["roles"] as Array<any>) ?? []).map(RoleFromJSON),
        subprofiles: !exists(json, "subprofiles")
            ? (undefined as any)
            : ((json["subprofiles"] as Array<any>) ?? []).map(ProfileOutputFromJSON),
    };
}

export function UserListOutputToJSON(value?: UserListOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
