import "react-toastify/dist/ReactToastify.css";
import React from "react";
import ReactDOM from "react-dom";
import styles from "./index.module.scss";
import App from "./App";
import Apis from "./contexts/ApiProviderCollection";
import OidcConfig from "./config/OidcConfig";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./authentication/AuthProvider";
import { ProfileContextProvider } from "./contexts/ProfileContext";
import { TagsContextProvider } from "./contexts/TagsContext";
import { ToastContainer } from "react-toastify";
import { REACT_APP_GOOGLE_API_KEY } from "./utils.ts/Env";
import { loadScript } from "./utils.ts/LoadScript";
import { RegistrationRulesContextProvider } from "./contexts/RegistrationRulesContext";
import { HappeningCloneContextProvider } from "./contexts/HappeningCloneContext";

loadScript(`https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AuthProvider {...OidcConfig}>
                <Apis>
                    <ProfileContextProvider>
                        <TagsContextProvider>
                            <RegistrationRulesContextProvider>
                                <HappeningCloneContextProvider>
                                    <App />
                                </HappeningCloneContextProvider>
                            </RegistrationRulesContextProvider>
                        </TagsContextProvider>
                    </ProfileContextProvider>
                </Apis>
            </AuthProvider>
        </BrowserRouter>
        <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="light"
            toastClassName={styles.toastMessage}
            className={styles.toastContainer}
        />
    </React.StrictMode>,
    document.getElementById("root"),
);
