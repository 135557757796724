/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    JscApplicationOutput,
    instanceOfJscApplicationOutput,
    JscApplicationOutputFromJSON,
    JscApplicationOutputFromJSONTyped,
    JscApplicationOutputToJSON,
} from "./JscApplicationOutput";
import {
    KeiComputerApplicationOutput,
    instanceOfKeiComputerApplicationOutput,
    KeiComputerApplicationOutputFromJSON,
    KeiComputerApplicationOutputFromJSONTyped,
    KeiComputerApplicationOutputToJSON,
} from "./KeiComputerApplicationOutput";
import {
    KeiParticipasApplicationOutput,
    instanceOfKeiParticipasApplicationOutput,
    KeiParticipasApplicationOutputFromJSON,
    KeiParticipasApplicationOutputFromJSONTyped,
    KeiParticipasApplicationOutputToJSON,
} from "./KeiParticipasApplicationOutput";
import {
    KeiSchoolTripApplicationOutput,
    instanceOfKeiSchoolTripApplicationOutput,
    KeiSchoolTripApplicationOutputFromJSON,
    KeiSchoolTripApplicationOutputFromJSONTyped,
    KeiSchoolTripApplicationOutputToJSON,
} from "./KeiSchoolTripApplicationOutput";

/**
 * @type Apply200Response
 *
 * @export
 */
export type Apply200Response =
    | JscApplicationOutput
    | KeiComputerApplicationOutput
    | KeiParticipasApplicationOutput
    | KeiSchoolTripApplicationOutput;

export function Apply200ResponseFromJSON(json: any): Apply200Response {
    return Apply200ResponseFromJSONTyped(json, false);
}

export function Apply200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Apply200Response {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...JscApplicationOutputFromJSONTyped(json, true),
        ...KeiComputerApplicationOutputFromJSONTyped(json, true),
        ...KeiParticipasApplicationOutputFromJSONTyped(json, true),
        ...KeiSchoolTripApplicationOutputFromJSONTyped(json, true),
    };
}

export function Apply200ResponseToJSON(value?: Apply200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfJscApplicationOutput(value)) {
        return JscApplicationOutputToJSON(value as JscApplicationOutput);
    }
    if (instanceOfKeiComputerApplicationOutput(value)) {
        return KeiComputerApplicationOutputToJSON(value as KeiComputerApplicationOutput);
    }
    if (instanceOfKeiParticipasApplicationOutput(value)) {
        return KeiParticipasApplicationOutputToJSON(value as KeiParticipasApplicationOutput);
    }
    if (instanceOfKeiSchoolTripApplicationOutput(value)) {
        return KeiSchoolTripApplicationOutputToJSON(value as KeiSchoolTripApplicationOutput);
    }

    return {};
}
