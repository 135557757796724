/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    IndicatorStatus,
    IndicatorStatusFromJSON,
    IndicatorStatusFromJSONTyped,
    IndicatorStatusToJSON,
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface IndicatorListOutput
 */
export interface IndicatorListOutput {
    /**
     *
     * @type {number}
     * @memberof IndicatorListOutput
     */
    id: number;
    /**
     *
     * @type {IndicatorStatus}
     * @memberof IndicatorListOutput
     */
    status: IndicatorStatus;
    /**
     *
     * @type {string}
     * @memberof IndicatorListOutput
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof IndicatorListOutput
     */
    description?: string;
    /**
     *
     * @type {Date}
     * @memberof IndicatorListOutput
     */
    startDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof IndicatorListOutput
     */
    endDate?: Date;
    /**
     *
     * @type {OrganizerViewModel}
     * @memberof IndicatorListOutput
     */
    organisation?: OrganizerViewModel;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof IndicatorListOutput
     */
    organizer?: ProfileShortOutput;
    /**
     *
     * @type {number}
     * @memberof IndicatorListOutput
     */
    measurementCount: number;
}

/**
 * Check if a given object implements the IndicatorListOutput interface.
 */
export function instanceOfIndicatorListOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "measurementCount" in value;

    return isInstance;
}

export function IndicatorListOutputFromJSON(json: any): IndicatorListOutput {
    return IndicatorListOutputFromJSONTyped(json, false);
}

export function IndicatorListOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorListOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : IndicatorStatusFromJSON(json["status"]),
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        startDate: !exists(json, "startDate") ? (undefined as any) : new Date(json["startDate"]),
        endDate: !exists(json, "endDate") ? (undefined as any) : new Date(json["endDate"]),
        organisation: !exists(json, "organisation")
            ? (undefined as any)
            : OrganizerViewModelFromJSON(json["organisation"]),
        organizer: !exists(json, "organizer") ? (undefined as any) : ProfileShortOutputFromJSON(json["organizer"]),
        measurementCount: !exists(json, "measurementCount") ? (undefined as any) : json["measurementCount"],
    };
}

export function IndicatorListOutputToJSON(value?: IndicatorListOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
