export type Person = {
    givenName: string;
    familyName: string;
};

export function getName(profile?: Person) {
    if (!profile) {
        return "";
    }

    switch (true) {
        case profile.familyName && !profile.givenName:
            return profile.familyName;
        case !profile.familyName && profile.givenName:
            return profile.givenName;
        default:
            return `${profile.givenName} ${profile.familyName}`;
    }
}
