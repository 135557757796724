import styles from "./ApplicationForm.module.scss";
import strings from "../../../localization/strings";
import FormField from "../../core/Form/FormField/FormField";
import { MAX_PARTICIPANT_AGE } from "../../../constants/Validation";
import EuroSymbol from "../../../assets/euro_symbol.svg";
import moment from "moment";
import FormDatepicker from "../../core/Form/FormDatePicker/FormDatePicker";
import { Control, FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormBooleanSelect, FormNumberSelect, FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import { PropsWithChildren, useMemo } from "react";
import { getStringForGenderChild } from "../../../utils.ts/GetGenderString";
import { getGenderOptions } from "../../../utils.ts/StateOptions";
import {
    FormType,
    KeiBudgetType,
    KeiComputerApplicationInput,
    KeiEducationType,
    KeiParticipasApplicationInput,
    KeiSchoolTripApplicationInput,
} from "../../../openapi/backend";
import { getGradeOptions } from "../../../utils.ts/GradeOptions";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import FormMultiSelectField from "../../core/Form/FormMultiSelectField/FormMultiSelectField";
import SearchPlacesListInput from "../../core/Inputs/SearchPlacesListInput/SearchPlacesListInput";

type KeiApplicationInput = KeiComputerApplicationInput | KeiParticipasApplicationInput | KeiSchoolTripApplicationInput;

interface KeiApplicationFieldsProps<T extends KeiApplicationInput> {
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
    control: Control<T, object>;
    watch: UseFormWatch<T>;
}

const genderOptions = getGenderOptions();

export default function KeiApplicationFields({
    form,
    ...props
}: KeiApplicationFieldsProps<KeiApplicationInput> & { form: FormType }) {
    switch (form) {
        case FormType.KeiComputer:
            return <KeiComputerApplicationFields {...(props as any)} />;
        case FormType.KeiParticipas:
            return <KeiParticipasApplicationFields {...(props as any)} />;
        case FormType.KeiSchoolTrip:
            return <KeiSchoolTripApplicationFields {...(props as any)} />;
        default:
            return <div>{strings.applicationTypeUnsupported}</div>;
    }
}

function KeiComputerApplicationFields({
    register,
    errors,
    control,
    watch,
    ...props
}: KeiApplicationFieldsProps<KeiComputerApplicationInput>) {
    return (
        <Fields register={register as any} control={control as any} watch={watch as any} errors={errors} {...props}>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.questionsRemarksTitleComputer}</h2>

                <FormBooleanSelect
                    name={"isPurchase"}
                    options={[
                        { label: strings.purchase, value: true },
                        { label: strings.rent, value: false },
                    ]}
                    label={strings.buyOrRentLaptop}
                    control={control}
                    errors={errors.isPurchase}
                    placeholder=" "
                />

                <FormField
                    name="price"
                    label={strings.requirementsPriceLaptopComputer}
                    register={register}
                    errors={errors.price}
                    placeholder=""
                    type="number"
                    min={0.0}
                    icon={EuroSymbol}
                    step={0.01}
                />

                <FormFieldMultiline
                    rows={4}
                    name={"computerDescription"}
                    label={strings.computerDescription}
                    register={register}
                    errors={errors.computerDescription}
                    required
                />
            </div>
        </Fields>
    );
}

function KeiParticipasApplicationFields({
    register,
    errors,
    control,
    watch,
    ...props
}: KeiApplicationFieldsProps<KeiParticipasApplicationInput>) {
    return (
        <Fields register={register as any} control={control as any} watch={watch as any} errors={errors} {...props}>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.questionsRemarksTitleParticipas}</h2>
                <FormField
                    name="participasNumber"
                    label={strings.participasNumber}
                    register={register}
                    errors={errors.participasNumber}
                    placeholder=""
                />
                <FormMultiSelectField
                    name={"budgetRequests" as never}
                    label={strings.whichBudget}
                    placeholder={" "}
                    options={[
                        { label: strings.schoolBudget, value: KeiBudgetType.SchoolItems },
                        { label: strings.clothesBudget, value: KeiBudgetType.Clothes },
                    ]}
                    control={control}
                    errors={errors.budgetRequests}
                    required
                />
            </div>
        </Fields>
    );
}

function KeiSchoolTripApplicationFields({
    register,
    errors,
    control,
    watch,
    ...props
}: KeiApplicationFieldsProps<KeiSchoolTripApplicationInput>) {
    return (
        <Fields register={register as any} control={control as any} watch={watch as any} errors={errors} {...props}>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.questionsRemarksTitleSchoolTrip}</h2>
                <FormField
                    name="price"
                    label={strings.requirementsPriceSchoolTrip}
                    register={register}
                    errors={errors.price}
                    placeholder=""
                    type="number"
                    min={0.0}
                    icon={EuroSymbol}
                    step={0.01}
                />

                <FormFieldMultiline
                    rows={4}
                    name={"tripDescription"}
                    label={strings.schoolTripDescription}
                    register={register}
                    errors={errors.tripDescription}
                    required
                />
            </div>
        </Fields>
    );
}

function Fields({
    register,
    errors,
    control,
    watch,
    children,
}: PropsWithChildren<KeiApplicationFieldsProps<KeiApplicationInput>>) {
    const childDateOfBirth = watch("childDateOfBirth");
    const childEducationType = watch("childEducationType");
    const grade = watch("grade");

    const localizedGenderOptions = useMemo(
        () => genderOptions.map((o) => ({ value: o.value, label: getStringForGenderChild(o.value) })),
        [],
    );

    return (
        <>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.personalInformationChild}</h2>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <FormField
                            name="childGivenName"
                            label={strings.childGivenName}
                            register={register}
                            errors={errors.childGivenName}
                            required
                            placeholder=""
                        />
                        <FormField
                            name="childFamilyName"
                            label={strings.childFamilyName}
                            register={register}
                            errors={errors.childFamilyName}
                            required
                            placeholder=""
                        />
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.column}>
                        <FormStringSelect
                            name="childGender"
                            label={strings.childGender}
                            control={control}
                            errors={errors.childGender}
                            required
                            options={localizedGenderOptions}
                            placeholder=" "
                        />

                        <FormDatepicker
                            name="childDateOfBirth"
                            title={strings.childDateOfBirth}
                            control={control}
                            value={childDateOfBirth}
                            maxDate={new Date()}
                            minDate={moment().subtract(MAX_PARTICIPANT_AGE, "years").toDate()}
                            required
                            errors={errors.childDateOfBirth}
                            useUtc={true}
                        />
                    </div>
                </div>
                <FormField
                    name="passNumber"
                    label={strings.passNumber}
                    register={register}
                    errors={errors.passNumber}
                    required
                    placeholder=""
                />
            </div>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.schoolInfo}</h2>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <SearchPlacesListInput
                            list={"childSchool"}
                            name={"childSchool" as never}
                            control={control}
                            single
                            errors={errors.childSchool}
                            label={strings.school}
                            placeholder={strings.search}
                        />

                        <FormStringSelect
                            name="childEducationType"
                            label={strings.childEducationType}
                            control={control}
                            errors={errors.childEducationType}
                            required
                            options={[
                                { value: KeiEducationType.PrimarySchool, label: strings.primarySchool },
                                { value: KeiEducationType.SecondarySchool, label: strings.secondarySchool },
                            ]}
                            placeholder=" "
                        />

                        <FormNumberSelect
                            name={"grade" as never}
                            options={getGradeOptions(childEducationType)}
                            label={strings.grade}
                            control={control}
                            value={grade}
                            errors={errors.grade}
                            placeholder=" "
                        />
                    </div>
                </div>
            </div>
            {children}
        </>
    );
}
