import { PropsWithChildren } from "react";
import styles from "./IdentityPage.module.scss";
import Logo from "../../assets/logo_full.svg";
import strings from "../../localization/strings";

export const LinkContainer = ({ children }: PropsWithChildren<{}>) => (
    <div className={styles.linkContainer}>{children}</div>
);

// Page that looks like the ones we have on the Identity part of the platform
const IdentityPage = ({ children }: PropsWithChildren<{}>) => (
    <div className={styles.page}>
        <div className={styles.cover}>
            <img className={styles.logo} src={Logo} alt={strings.logo} />
        </div>
        <div className={styles.container}>
            <div className={styles.content}>{children}</div>
        </div>
    </div>
);

export default IdentityPage;
