import { toast, ToastOptions } from "react-toastify";
import styles from "../index.module.scss";

export function closeToastMessage(id: string) {
    toast.dismiss(id);
}

export function getToastFeedbackId(id: string) {
    return `loading-${id}`;
}

export function toastSuccess(message: string, options?: ToastOptions<{}>) {
    toast.success(message, options);
}

export function toastError(message: string) {
    toast.error(message, { autoClose: 5000 });
}

export function toastWaitingForFeedback(message: string, feedbackId: string) {
    toast.loading(message, {
        toastId: getToastFeedbackId(feedbackId),
        autoClose: false,
        closeOnClick: true,
        closeButton: true,
        className: styles.wide,
    });
}

export function toastFeedbackReceived(message: string, id?: string) {
    if (id && toast.isActive(getToastFeedbackId(id))) {
        toast.update(getToastFeedbackId(id), { render: message, autoClose: 3000, isLoading: false, type: "success" });
    } else {
        toastSuccess(message, { className: styles.wide });
    }
}
