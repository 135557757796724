import { PropsWithChildren } from "react";
import styles from "./FlexRow.module.scss";

interface Props {
    className?: string;
}

/**
 * A flex container with direction row that positions children with default spacing
 */
const FlexRow = ({ className, children }: PropsWithChildren<Props>) => (
    <div className={`${styles.container} ${className}`}>{children}</div>
);

export default FlexRow;
