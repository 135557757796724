import { useCallback, useContext, useMemo, useState } from "react";
import { LocalHappeningContext } from "../../../contexts/LocalHappeningContext";
import { AppointmentViewModel, LocationType } from "../../../openapi/backend";
import { getLocationTypeString } from "../../../utils.ts/GetLocationTypeString";
import CancelAppointmentModal from "../../Forms/ViewHappening/CancelAppointmentModal";
import FlexRow from "../../Layouts/FlexRow/FlexRow";
import AppointmentDataItem from "../AppointmentDataItem/AppointmentDataItem";
import PaymentTooltip from "../PaymentTooltip/PaymentTooltip";
import { RemoveButton } from "../RoundButton/RoundButton";
import styles from "./AppointmentItem.module.scss";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import NotesIcon from "../../../assets/info_icon.svg";

export interface AppointmentItemProps {
    happeningId: string;
    appointment: AppointmentViewModel;
    locationType: LocationType;
    price: number;
    isAllowedToUnsubscribe: boolean;
}

export default function AppointmentItem({
    happeningId,
    appointment,
    locationType,
    price,
    isAllowedToUnsubscribe,
}: AppointmentItemProps) {
    const { refresh } = useContext(LocalHappeningContext);
    const isPast = useMemo(() => appointment.sessionEnd < new Date(), [appointment.sessionEnd]);
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const openAppointmentModal = useCallback(() => setCancelModalOpen(true), []);
    const closeAppointmentModal = useCallback(() => setCancelModalOpen(false), []);
    const onSuccessAppointment = useCallback(() => refresh(), [refresh]);

    return (
        <div className={styles.container}>
            <AppointmentDataItem
                appointment={appointment}
                text={appointment.location ? appointment.location.address : getLocationTypeString(locationType)}
            />
            <FlexRow>
                {appointment.notes && (
                    <TooltipIcon message={appointment.notes} icon={NotesIcon} className={styles.tooltip} />
                )}

                {price > 0 && (
                    <PaymentTooltip className={styles.tooltip} requiresPayment={appointment.requiresPayment} />
                )}

                {isAllowedToUnsubscribe && !appointment.cancelled && !isPast && (
                    <RemoveButton onClick={openAppointmentModal} className={styles.tooltip} />
                )}
            </FlexRow>
            {cancelModalOpen && (
                <CancelAppointmentModal
                    happeningId={happeningId}
                    appointmentId={appointment.id}
                    onDismiss={closeAppointmentModal}
                    onSuccess={onSuccessAppointment}
                />
            )}
        </div>
    );
}
