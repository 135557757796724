import moment from "moment";
import strings from "../localization/strings";
import { ApplicationEventOutput, Gender } from "../openapi/backend";
import { formatDate } from "./DateTime";
import { formatAsPrice } from "./Formatting";
import { applicationStatusToString } from "./GetApplicationStatus";
import { getStringForGender, getStringForGenderChild } from "./GetGenderString";
import { getName } from "./GetName";
import { getStringForEducationType } from "./GetEducationType";

export function getApplicationFieldName(path: string | undefined) {
    switch (path) {
        case "/reviewerId":
            return strings.arrangementIntermediary;
        case "/givenName":
            return strings.applicationFormGivenName;
        case "/familyName":
            return strings.applicationFormFamilyName;
        case "/street":
            return strings.applicationFormStreet;
        case "/houseNumber":
            return strings.applicationFormHouseNumber;
        case "/postalCode":
            return strings.applicationFormPostalCode;
        case "/gender":
            return strings.applicationFormGender;
        case "/city":
            return strings.applicationFormCity;
        case "/email":
            return strings.applicationFormEmail;
        case "/dateOfBirth":
            return strings.applicationFormBirthdate;
        case "/phoneNumber":
            return strings.applicationFormPhoneNumber;
        case "/socialAssistance":
            return strings.applicationFormFinancialStatus;
        case "/reasonOfDeficiency":
            return strings.applicationFormReasonOfDeficiency;
        case "/cityPassName":
            return strings.applicationFormCityPassName;
        case "/cityPassNumber":
            return strings.applicationFormCityPassNumber;
        case "/sportsClubName":
            return strings.applicationFormSportsClubName;
        case "/intendedActivity":
            return strings.applicationFormSportsClubActivity;
        case "/activityCity":
            return strings.applicationFormSportsClubCity;
        case "/membershipFee":
            return strings.applicationFormContributionFee;
        case "/membershipStartDate":
            return strings.applicationFormContributionStartDate;
        case "/membershipEndDate":
            return strings.applicationFormContributionEndDate;
        case "/additionalRequirements":
            return strings.applicationFormOtherNeeds;
        case "/requirementsPrice":
            return strings.applicationFormRequiredAmount;
        case "/requirementsShop":
            return strings.applicationFormStore;
        case "/requirementsCity":
            return strings.applicationFormStoreAddress;
        case "/supplement":
            return strings.applicationFormAdditionalRemarks;
        case "/status":
            return strings.state;
        case "/childGivenName":
            return strings.childGivenName;
        case "/childFamilyName":
            return strings.childFamilyName;
        case "/childGender":
            return strings.childGender;
        case "/childDateOfBirth":
            return strings.childDateOfBirth;
        case "/passNumber":
            return strings.passNumber;
        case "/childSchool":
            return strings.school;
        case "/childEducationType":
            return strings.childEducationType;
        case "/grade":
            return strings.grade;
        case "/isPurchase":
            return strings.buyOrRentLaptop;
        case "/price":
            return strings.price;
        case "/computerDescription":
            return strings.computerDescription;
        case "/tripDescription":
            return strings.schoolTripDescription;
        case "/budgetRequests":
            return strings.whichBudget;
        case "/participasNumber":
            return strings.participasNumber;
        case "/hasCityPass":
            return strings.applicationFormHasCityPass;
        case "/fileId":
            return strings.file;
        default:
            return path;
    }
}

export function getApplicationChangeEventValue(
    path: string | undefined,
    value: any | undefined,
    additionalData: ApplicationEventOutput,
) {
    switch (path) {
        case "/reviewerId":
            return getName(additionalData.newReviewer);
        case "/gender":
            return getStringForGender(value as Gender);
        case "/childGender":
            return getStringForGenderChild(value as Gender);
        case "/dateOfBirth":
        case "/membershipStartDate":
        case "/membershipEndDate":
        case "/childDateOfBirth":
            return value ? formatDate(moment(value).utc(true).toDate()) : "-";
        case "/membershipFee":
        case "/requirementsPrice":
            return value ? formatAsPrice(value) : "-";
        case "/status":
            return applicationStatusToString(value);
        case "/childEducationType":
            return getStringForEducationType(value);
        case "/hasCityPass":
            return value ? strings.yes : strings.no;
        case "/fileId":
            return additionalData.newFileName || "-";
        default:
            return value;
    }
}
