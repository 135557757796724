import { useCallback, useEffect, useMemo, useState } from "react";
import { Control, FieldError, FieldValues, useController } from "react-hook-form";
import useLocationSearch from "../../../../hooks/UseLocationSearch";
import strings from "../../../../localization/strings";
import { LocationInputModel } from "../../../../openapi/backend";
import { hasValues } from "../../../../utils.ts/Array";
import InputField, { InputFieldProps } from "../../Inputs/InputField/InputField";
import SearchInput from "../../Inputs/SearchInput/SearchInput";
import Map from "../../Map/Map";
import { MarkerLocation } from "../../Map/MapMarker";
import { RemoveButton } from "../../RoundButton/RoundButton";
import TextButton from "../../TextButton/TextButton";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";
import styles from "./FormAddressListInput.module.scss";

type Props<TForm extends FieldValues, TName extends FieldName<TForm, Array<LocationInputModel>>> = Pick<
    InputFieldProps,
    "disabled"
> & {
    name: TName;
    control: Control<TForm>;
    errors?: Array<{
        name?: FieldError;
        address?: FieldError;
        longitude?: FieldError;
        latitude?: FieldError;
    }>;
};

const FormAddressListInput = <TForm extends FieldValues, TName extends FieldName<TForm, Array<LocationInputModel>>>({
    name,
    control,
    errors,
    disabled,
}: Props<TForm, TName>) => {
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        rules: { required: true, minLength: 1 },
    });

    const locationList = useMemo(() => {
        return (value || []) as Array<LocationInputModel>;
    }, [value]);

    const [location, search, isSearching] = useLocationSearch();
    const [firstSearchPerformed, setFirstSearchPerformed] = useState(false);
    const [locationName, setLocationName] = useState("");
    const locationNotFoundError = useMemo(
        () => !isSearching && !location && firstSearchPerformed,
        [location, firstSearchPerformed, isSearching],
    );

    const [markerLocation, setMarkerLocation] = useState<MarkerLocation | undefined>();

    useEffect(() => {
        if (!location) {
            return;
        }

        setMarkerLocation((oldValue) => ({ ...oldValue, ...location }));
    }, [location]);

    useEffect(() => {
        setMarkerLocation((oldValue) => ({ ...oldValue, name: locationName }));
    }, [locationName]);

    const isLocationNameInvalid = useMemo(
        () => !!markerLocation?.address && locationName.length === 0,
        [markerLocation, locationName],
    );

    const onLocationNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setLocationName(e.target.value);
    }, []);

    const isValidEntry = useMemo(
        () => !!markerLocation?.address && locationName.length > 0,
        [markerLocation, locationName],
    );

    const onAddLocationClick = useCallback(() => {
        onChange([...locationList, { name: locationName, ...location }]);
        setLocationName("");
        setMarkerLocation(undefined);
        setFirstSearchPerformed(false);
    }, [onChange, locationName, location, locationList]);

    const onRemoveLocationClick = useCallback(
        (index: number) => {
            onChange(locationList.filter((_, i) => i !== index));
        },
        [onChange, locationList],
    );

    return (
        <div className={styles.container}>
            <div className={styles.inputSection}>
                <div className={styles.fields}>
                    <FormFieldContainer
                        name={"formAddressListInputTitle"}
                        label={strings.locationName}
                        errors={
                            isLocationNameInvalid ? { type: "pattern", message: strings.mandatoryField } : undefined
                        }
                    >
                        <InputField
                            invalid={isLocationNameInvalid}
                            value={locationName}
                            onChange={onLocationNameChange}
                        />
                    </FormFieldContainer>
                    <FormFieldContainer
                        label={strings.address}
                        name="searchAddress"
                        errors={
                            locationNotFoundError ? { type: "pattern", message: strings.addressNotFound } : undefined
                        }
                    >
                        <SearchInput
                            name="searchAddress"
                            // Use key to reset search value on list value change
                            key={`searchAddress-${locationList.length}`}
                            onSearch={(query) => {
                                setFirstSearchPerformed(true);
                                search(query);
                            }}
                            disabled={disabled}
                        />
                        {!locationNotFoundError && <div className={styles.subText}>{strings.checkAddress}</div>}
                    </FormFieldContainer>
                    <TextButton
                        className={styles.addButton}
                        text={strings.addAddress}
                        onClick={onAddLocationClick}
                        disabled={!isValidEntry}
                    />
                </div>
                <Map location={markerLocation} className={styles.mapContainer} />
            </div>
            <FormFieldContainer
                name={locationName}
                className={styles.listSection}
                label={strings.locations}
                errors={hasValues(errors) ? { type: "pattern", message: strings.mandatoryField } : undefined}
            >
                <div className={styles.locationList}>
                    {hasValues(locationList) ? (
                        locationList.map((li, index) => (
                            <div key={index} className={styles.locationRow}>
                                <div className={styles.locationTitle}>{`${li.name}, ${li.address || ""}`}</div>
                                <RemoveButton
                                    onClick={() => onRemoveLocationClick(index)}
                                    className={styles.deleteButton}
                                />
                            </div>
                        ))
                    ) : (
                        <div className={styles.subText}>{strings.emptyLocationList}</div>
                    )}
                </div>
            </FormFieldContainer>
        </div>
    );
};

export default FormAddressListInput;
