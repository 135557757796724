import { HappeningInput, UpdateHappeningInput } from "../../../hooks/HappeningsHooks";
import { getImagesWithoutQuickfixImages } from "../../../hooks/useMissingImagesQuickfix";
import { HappeningViewModel, TagType } from "../../../openapi/backend";
import { hasValues } from "../../../utils.ts/Array";

export function getDefaultValuesFromHappening(
    happening: Omit<HappeningViewModel, "id"> & Partial<Pick<HappeningViewModel, "id">>,
): Partial<UpdateHappeningInput | HappeningInput> {
    const internalTags = happening.tags.filter((tag) => {
        return tag.type === TagType.Internal;
    });
    const images = getImagesWithoutQuickfixImages(happening.images);
    const defaultValues: Partial<UpdateHappeningInput | HappeningInput> = {
        happeningType: happening.type,
        id: happening.id,
        timeAndDates: happening.dateTimes,
        videos: happening.videos,
        images: images.map((image) => image.id),
        requiresApprovalFromMarketing: happening.requiresApprovalFromMarketing,
        state: happening.state,
        minPeoplePerGroup: happening.minPeoplePerGroup,
        maxPeoplePerGroup: happening.maxPeoplePerGroup,
        price: happening.price,
        priceForIndividual: happening.priceForIndividual,
        isProRata: happening.isProRata,
        locationAddress: happening.location?.address,
        locationName: happening.location?.name,
        locationLatitude: happening.location?.latitude,
        locationLongitude: happening.location?.longitude,
        title: happening.title,
        description: happening.description,
        requiresRegistration: happening.requiresRegistration,
        maxNumberOfRegistrations:
            happening.maxNumberOfRegistrations > 0 ? happening.maxNumberOfRegistrations : undefined,
        happeningGroup: happening.happeningGroup?.id,
        minAge: happening.minAge,
        maxAge: happening.maxAge,
        restrictAge: happening.restrictAge,
        isAccessible: happening.isAccessible,
        happeningTypes: happening.tags
            .filter((tag) => {
                return tag.type === TagType.HappeningType;
            })
            .map((tag) => tag.id),
        internalTags: internalTags.length > 0 ? internalTags.map((tag) => tag.id) : undefined,
        tagInputs: happening.tags
            .filter((tag) => tag.type === TagType.Partner || tag.type === TagType.HappeningGroup)
            .map((tag) => ({
                id: tag.id,
                responsibilities: tag.responsibilities,
                isShownInCommunication: tag.isShownInCommunication,
            })),
        projectLeader: happening.projectLeader?.id,
        contact: happening.contact?.id,
        projectMembers: happening.projectMembers.map((member) => member.id),
        projectCoLeaders: happening.projectCoLeaders.map((member) => member.id),
        areas: happening.tags.filter(
            (tag) =>
                tag.type === TagType.District ||
                tag.type === TagType.Neighbourhood ||
                tag.type === TagType.Municipality,
        ),
        infoLevel: happening.infoLevel,
        recurrence: happening.recurrence ? [happening.recurrence] : undefined,
        askForSchool: happening.askForSchool,
        schools: happening.schools,
        allowedPaymentMethods: happening.allowedPaymentMethods,
        locationType: happening.locationType,
        publicationDate: happening.publicationDate,
        displayState: happening.displayState,
        registrationStartDate: happening.registrationStartDate,
        registrationEndDate: happening.registrationEndDate,
        restrictRegistrationsDate: !!happening.registrationStartDate || !!happening.registrationEndDate,
        restrictRegistrationsSchools: happening.schools.length > 0,
        hasExtraQuestions: happening.extraQuestions.length > 0,
        extraQuestions: happening.extraQuestions.map((question) => ({
            ...question,
            isMultipleChoice: hasValues(question.options),
        })),
        restrictRegistrationsAreas:
            happening.tags.filter(
                (tag) =>
                    tag.type === TagType.District ||
                    tag.type === TagType.Neighbourhood ||
                    tag.type === TagType.Municipality,
            ).length > 0,
        targetGroup: happening.targetGroup ? happening.targetGroup : undefined,
        targetOrganisations: happening.targetOrganisations
            ? happening.targetOrganisations.map((organisation) => organisation.id)
            : undefined,
        durationInMinutes: happening.durationInMinutes,
        cluster: happening.cluster?.id,
        happeningRegistrationType: happening.happeningRegistrationType,
        canOnlyRegisterAsGroup: happening.canOnlyRegisterAsGroup,
        link: happening.link,
        linkLabel: happening.linkLabel,
    };

    return defaultValues;
}
