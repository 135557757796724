import styles from "./TextInputWithButton.module.scss";
import InputField, { InputFieldProps } from "../InputField/InputField";
import { useCallback, useEffect, useState } from "react";
import useRealBlur from "../../../../hooks/useRealBlur";
import cx from "classnames";

export type TextInputWithButtonProps = Omit<InputFieldProps, "type" | "defaultValue"> & {
    onActivate: (value: string) => void;
    icon: string;
    value?: string;
    defaultValue?: string;
    buttonDisabled?: boolean;
    activateOnBlur?: boolean;
    onButtonKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
};

const TextInputWithButton = ({
    className,
    onActivate,
    icon,
    value,
    defaultValue = "",
    disabled,
    buttonDisabled,
    activateOnBlur,
    onButtonKeyDown,
    ...props
}: TextInputWithButtonProps) => {
    const [input, setInput] = useState(value || defaultValue);
    const [searchedValue, setSearchedValue] = useState<string>();
    const search = useCallback(
        (value: string) => {
            if (value === searchedValue) {
                return;
            }

            setSearchedValue(value);
            onActivate(value);
        },
        [searchedValue, onActivate],
    );

    const onBlur = useRealBlur(useCallback(() => onActivate(input), [input, onActivate]));
    useEffect(() => setInput(value || defaultValue), [value, defaultValue]);

    return (
        <div className={cx(styles.container, className)} onBlur={activateOnBlur ? onBlur : undefined}>
            <InputField
                value={input}
                onChange={(e) => {
                    setInput(e.currentTarget.value);
                }}
                type="text"
                {...props}
                className={styles.searchField}
                onKeyPress={(e) => {
                    if (e.key !== "Enter") {
                        return;
                    }
                    e.preventDefault();
                    search(input);
                }}
                disabled={disabled}
            />
            <button
                type="button"
                className={styles.searchButton}
                onClick={() => onActivate(input)}
                disabled={disabled || buttonDisabled}
                onKeyDown={onButtonKeyDown}
            >
                <img src={icon} alt={props.placeholder} />
            </button>
        </div>
    );
};

export default TextInputWithButton;
