import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import ActionDelete from "../../assets/action_delete_icon.svg";
import ActionEdit from "../../assets/action_edit_icon.svg";
import ActionView from "../../assets/action_view_icon.svg";
import { canEditSpecificMeasurement } from "../../authentication/Permissions";
import { ProfileContext } from "../../contexts/ProfileContext";
import { GetMeasurementsFun, MeasurementsFilter, useDeleteMeasurement } from "../../hooks/MeasurementHooks";
import { useOrganiserOptions } from "../../hooks/TagHooks";
import { RequestState } from "../../hooks/UseApiCall";
import { useMemberOptions } from "../../hooks/UserHooks";
import {
    DateFilter,
    FilterSpecs,
    NamedOptionsFilter,
    PageNumberFilter,
    StringFilter,
    useFilters,
} from "../../hooks/UseSearchParam";
import strings from "../../localization/strings";
import {
    MeasurementListOutput,
    MeasurementListOutputPaginatedViewModel,
    MeasurementOrderBy,
    SortOrder,
} from "../../openapi/backend";
import { MeasurementFilterKeys } from "../../types/MeasurementFilterKeys";
import { UserNamedType } from "../../types/UserNamedType";
import { formatDate } from "../../utils.ts/DateTime";
import { getName } from "../../utils.ts/GetName";
import { getMeasurementsStateOptions } from "../../utils.ts/StateOptions";
import { getEditMeasurementLink } from "../../utils.ts/Urls";
import ConfirmationModal from "../core/ConfirmationModal/ConfirmationModal";
import EllipsisMenu from "../core/EllipsisMenu/EllipsisMenu";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import MeasurementsFilterBar from "../core/FilterBar/MeasurementsFilterBar";
import StateLabel from "../core/StateLabel/StateLabel";
import Table from "../core/Table/Table";

interface MeasurementsProps {
    measurements?: MeasurementListOutputPaginatedViewModel;
    error?: Response;
    getMeasurements: GetMeasurementsFun;
    excludeFilters?: MeasurementFilterKeys;
}

type MeasurementTableItem = {
    [key in MeasurementOrderBy | "Actions"]?: ReactNode;
};

const stateOptions = getMeasurementsStateOptions();

const columns: Column<MeasurementTableItem>[] = [
    {
        Header: strings.date,
        accessor: MeasurementOrderBy.Date,
    },
    {
        Header: strings.name,
        accessor: MeasurementOrderBy.IndicatorName,
    },
    {
        Header: strings.formIndicatorFieldOrganisation,
        accessor: MeasurementOrderBy.Organisation,
    },
    {
        Header: strings.measurer,
        accessor: MeasurementOrderBy.Measurer,
    },
    {
        Header: strings.state,
        accessor: MeasurementOrderBy.Status,
    },
    {
        Header: strings.actions,
        accessor: "Actions",
        disableSortBy: true,
        width: 20,
    },
];

export default function Measurements({ measurements, error, getMeasurements, excludeFilters }: MeasurementsProps) {
    const navigate = useNavigate();
    const { profile } = useContext(ProfileContext);
    const [deleteTarget, setDeleteTarget] = useState<MeasurementListOutput | undefined>(undefined);
    const [orderBy, setOrderBy] = useState(MeasurementOrderBy.Default);
    const [sortOrder, setSortOrder] = useState(SortOrder.Ascending);

    const organisationOptions = useOrganiserOptions();
    const { value: measurers, state: measurersState } = useMemberOptions();
    const [deleteMeasurementState, deleteMeasurement, resetDeleteRequest] = useDeleteMeasurement();

    const measurerOptions: UserNamedType[] = useMemo(
        () => (measurers ? measurers.map((member) => ({ ...member, name: getName(member) })) : []),
        [measurers],
    );

    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            startDate: new DateFilter(),
            statuses: new NamedOptionsFilter(stateOptions),
            page: new PageNumberFilter(),
            organisations: new NamedOptionsFilter(organisationOptions),
            measurers: new NamedOptionsFilter(measurerOptions),
            date: new DateFilter(),
        }),
        [organisationOptions, measurerOptions],
    );

    const [rawFilters, setFilters] = useFilters(filterSpecs);
    const filters = rawFilters as MeasurementsFilter;

    const loadMeasurements = useCallback(() => {
        // Wait until the project leaders options have been loaded, because the filters are dependent on them.
        // Otherwise, we get multiple getIndicators calls in quick succession which causes a race condition

        // (the last response to come in will be the "winner").
        // If the organisers call fails, start loading regardless.

        const loadingMeasurersDone = measurerOptions.length > 0 || measurersState === RequestState.ERROR;
        if (loadingMeasurersDone) {
            return getMeasurements(orderBy, sortOrder, filters);
        }
    }, [getMeasurements, orderBy, sortOrder, filters, measurerOptions, measurersState]);

    useEffect(() => {
        loadMeasurements();
    }, [loadMeasurements]);

    useEffect(() => {
        if (deleteMeasurementState.state === RequestState.DONE) {
            loadMeasurements();
            setDeleteTarget(undefined);
            resetDeleteRequest();
        }
    }, [loadMeasurements, deleteMeasurementState.state, resetDeleteRequest]);

    const data = useMemo<MeasurementTableItem[]>(() => {
        return (
            measurements?.items?.map((measurement) => {
                const canEdit = canEditSpecificMeasurement(measurement, profile);

                const actions = [
                    canEdit && {
                        icon: ActionDelete,
                        onClick: () => {
                            setDeleteTarget(measurement);
                        },
                        alt: strings.formatString(
                            strings.ellipsisMenuAltDeleteMeasurement,
                            measurement.indicatorName,
                        ) as string,
                        title: strings.ellipsisMenuTitleDelete,
                    },
                    canEdit && {
                        icon: ActionEdit,
                        onClick: () => {
                            navigate(getEditMeasurementLink(measurement.id));
                        },
                        alt: strings.formatString(
                            strings.ellipsisMenuAltEditMeasurement,
                            measurement.indicatorName,
                        ) as string,
                        title: strings.ellipsisMenuTitleEdit,
                    },
                    !canEdit && {
                        icon: ActionView,
                        onClick: () => {
                            navigate(getEditMeasurementLink(measurement.id));
                        },
                        alt: strings.formatString(
                            strings.ellipsisMenuAltViewMeasurement,
                            measurement.indicatorName,
                        ) as string,
                        title: strings.ellipsisMenuTitleView,
                    },
                ];
                return {
                    [MeasurementOrderBy.Date]: measurement.createdAt ? formatDate(measurement.createdAt) : "-",
                    [MeasurementOrderBy.IndicatorName]:
                        measurement.indicatorName + (measurement.isLinkedToHappening ? " ∞" : ""),
                    [MeasurementOrderBy.Organisation]: measurement.organisation?.name || "",
                    [MeasurementOrderBy.Measurer]: measurement.measurer ? getName(measurement.measurer) : "",
                    [MeasurementOrderBy.Status]: <StateLabel state={measurement.status} />,
                    Actions: <EllipsisMenu items={actions} />,
                };
            }) || []
        );
    }, [measurements, profile, navigate]);

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const onFilterChange = useCallback(
        (filter: MeasurementsFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const handleSort = useCallback(
        (sortBy) => {
            // We only sort on 1 column so only take the first element in sortBy
            if (sortBy[0]) {
                setOrderBy(sortBy[0].id);
                setSortOrder(sortBy[0].desc ? SortOrder.Ascending : SortOrder.Descending);
            } else {
                // If no sorting is applied, revert back to default sorting
                setOrderBy(MeasurementOrderBy.Default);
                setSortOrder(SortOrder.Ascending);
            }
        },
        [setOrderBy, setSortOrder],
    );

    return (
        <div>
            <MeasurementsFilterBar onChange={onFilterChange} value={filters} exclude={excludeFilters} />
            {error && <ErrorMessage error={error} />}
            <Table
                columns={columns}
                data={data}
                onSort={handleSort}
                setPage={setPage}
                emptyString={strings.noMeasurementsFound}
                forcePage={filters.page}
                paginationMeta={measurements?.meta}
                rowSelectable={false}
            />
            {deleteTarget && (
                <ConfirmationModal
                    isOpen={true}
                    onCancel={() => setDeleteTarget(undefined)}
                    onConfirm={() => deleteMeasurement(deleteTarget.id)}
                    isLoading={deleteMeasurementState.state === RequestState.LOADING}
                    error={deleteMeasurementState.error}
                    errorMessage={strings.errorRemoveMeasurement}
                    confirmText={strings.yesDelete}
                >
                    {strings.formatString(strings.confirmDeleteMeasurement, deleteTarget.indicatorName)}
                </ConfirmationModal>
            )}
        </div>
    );
}
