import cx from "classnames";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import strings from "../../../../localization/strings";
import { hourSelectOptions, minuteSelectOptions } from "../../../../types/TimeOptions";
import { formatDate } from "../../../../utils.ts/DateTime";
import DatePicker, { DatePickerProps } from "../DatePicker/DatePicker";
import { StringSelectField } from "../SelectField/SelectField";
import styles from "./DateTimePicker.module.scss";

type Props<CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined> = Omit<
    DatePickerProps<CustomModifierNames, WithRange>,
    "value" | "onChange"
> & {
    dateTitle?: string;
    timeTitle?: string;
    value: Date | undefined;
    onChange: (date: Date | undefined) => void;
};

function DateTimePicker<CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>({
    className,
    dateTitle,
    timeTitle,
    onChange,
    value,
    ...props
}: Props<CustomModifierNames, WithRange>) {
    const [date, setDate] = useState<string>(() => (value ? formatDate(value) : ""));
    const [hours, setHours] = useState<string>(() => (value ? formatDate(value, "HH") : hourSelectOptions[0].value));
    const [minutes, setMinutes] = useState<string>(() =>
        value ? formatDate(value, "mm") : minuteSelectOptions[0].value,
    );

    useEffect(() => {
        if (date && hours && minutes) {
            onChange(moment(`${date} ${hours}:${minutes}`, ["DD/MM/YYYY HH:mm", "DD-MM-YYY HH:mm"]).toDate());
        } else {
            onChange(undefined);
        }
    }, [date, hours, minutes, onChange]);

    return (
        <div className={cx(styles.container, className)}>
            <div className={styles.dateContainer}>
                <label>{dateTitle || strings.date}</label>
                <DatePicker
                    {...props}
                    name={`${props.name}_date`}
                    className={styles.dateTimeInput}
                    placeholderText={strings.date}
                    onChange={(date) => {
                        setDate(date ? formatDate(date as Date) : "");
                    }}
                    // Use selected instead of value to allow editing of input
                    selected={date ? moment(date, ["DD/MM/YYYY", "DD-MM-YYYY"]).toDate() : undefined}
                />
            </div>
            <div>
                <label>{strings.time}</label>
                <div className={styles.timeFieldContainer}>
                    <StringSelectField
                        name={`${props.name}_hours`}
                        className={styles.dateTimeInput}
                        value={hours}
                        onChange={setHours}
                        options={hourSelectOptions}
                    />
                    <div className={styles.colonText}>:</div>
                    <StringSelectField
                        name={`${props.name}_minutes`}
                        className={styles.dateTimeInput}
                        value={minutes}
                        onChange={setMinutes}
                        options={minuteSelectOptions}
                    />
                </div>
            </div>
        </div>
    );
}

export default DateTimePicker;
