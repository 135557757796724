/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum TagOrderBy {
    Default = "Default",
    Name = "Name",
    Type = "Type",
    IsActive = "isActive",
}

export function TagOrderByFromJSON(json: any): TagOrderBy {
    return TagOrderByFromJSONTyped(json, false);
}

export function TagOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagOrderBy {
    return json as TagOrderBy;
}

export function TagOrderByToJSON(value?: TagOrderBy | null): any {
    return value as any;
}
