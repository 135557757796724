import moment from "moment";

export const InitialTimeAndDates = () => {
    const start = moment().add(7, "days");
    const remainder = 15 - (start.minute() % 15);
    const dateTime = moment(start).add(remainder, "minutes");

    return {
        sessionStart: dateTime.toDate(),
        sessionEnd: dateTime.add(15, "minutes").toDate(),
    };
};
