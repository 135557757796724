import { FieldError, UseFormRegister } from "react-hook-form";
import strings from "../../localization/strings";
import styles from "./AttachmentsField.module.scss";
import FormFileInput from "../core/Form/FormFileInput/FormFileInput";
import { useContext, useMemo } from "react";
import { AttachmentsContext } from "../../contexts/AttachmentsContext";
import FileList from "../core/FileList/FileList";
import { hasValues } from "../../utils.ts/Array";

interface AttachmentsFieldProps {
    register: UseFormRegister<any>;
    required?: boolean;
    multiple?: boolean;
}

export default function AttachmentsField({ register, required = true, multiple = false }: AttachmentsFieldProps) {
    const { file, selectedFiles, addFile } = useContext(AttachmentsContext);

    const hasFile = useMemo(() => !!file || hasValues(selectedFiles), [file, selectedFiles]);
    return (
        <div className={styles.container}>
            <h2 className={styles.Header}>{strings.attachments}</h2>
            {(multiple || !hasFile) && (
                <FormFileInput
                    register={register}
                    errors={
                        required && !hasFile
                            ? ({ type: "pattern", message: strings.mandatoryField } as FieldError)
                            : undefined
                    }
                    name={"files"}
                    onChange={addFile}
                    label={strings.add}
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                    multiple={multiple}
                />
            )}

            <FileList className={styles.thumbnail} />
        </div>
    );
}
