import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { DUPLICATE_ID_KEY } from "../../../constants/Route";
import { useCreateIndicator, useGetIndicatorCallback } from "../../../hooks/IndicatorHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { getDefaultValuesFromIndicator } from "../../../utils.ts/IndicatorUtils";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import IndicatorForm from "./IndicatorForm";

export default function CreateIndicatorForm() {
    const [searchParams] = useSearchParams();

    const indicatorId = searchParams.get(DUPLICATE_ID_KEY);
    const [{ value: indicator, state, error }, getIndicator] = useGetIndicatorCallback();
    const saveCallback = useCreateIndicator();

    useEffect(() => {
        if (indicatorId) {
            getIndicator(parseInt(indicatorId));
        }
    }, [indicatorId, getIndicator]);

    const defaultValues = useMemo(() => {
        return getDefaultValuesFromIndicator(indicator, !!indicatorId);
    }, [indicator, indicatorId]);

    if (indicatorId && [RequestState.LOADING, RequestState.IDLE].includes(state)) {
        return <InlineLoading />;
    }

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (indicatorId && !indicator) {
        return <div>{strings.indicatorNotFound}</div>;
    }

    return <IndicatorForm saveCallback={saveCallback} defaultValues={defaultValues} />;
}
