import styles from "./FilterBar.module.scss";
import { PropsWithChildren } from "react";
import strings from "../../../localization/strings";
import { CrossButton } from "../CrossButton/CrossButton";

interface BaseFilterBarProps {
    onReset?: () => void;
}
export interface FilterBarProps<T> extends BaseFilterBarProps {
    onChange: (filter: Partial<T>) => void;
    onReset?: () => void;
}

export interface FilterBarPropsWithValue<T> extends FilterBarProps<T> {
    value: Partial<T>;
}

export default function FilterBar({ children, onReset }: PropsWithChildren<BaseFilterBarProps>) {
    return (
        <div className={styles.container}>
            {children}
            {onReset && <CrossButton alt={strings.resetFilters} onClick={onReset} />}
        </div>
    );
}
