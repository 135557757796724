import { useCallback, useEffect, useState } from "react";
import styles from "./AccountForm.module.scss";
import { RequestState } from "../../../hooks/UseApiCall";
import { useUpdateUser, useUser } from "../../../hooks/UserHooks";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import EditAccount from "./EditAccount";
import EditButtons from "./EditButtons";
import { useGetArrangements } from "../../../hooks/ArrangementHooks";

type EditAccountContainerProps = {
    id: string;
    onDismiss: () => void;
};

const EditAccountContainer = ({ id, onDismiss }: EditAccountContainerProps) => {
    const [request, getUser] = useUser();
    const [updateRequest, updateUser] = useUpdateUser();
    const [error, setError] = useState<Response>();
    const arrangementRequest = useGetArrangements();

    useEffect(() => {
        getUser(id);
    }, [id, getUser]);

    const refresh = useCallback(() => {
        getUser(id);
    }, [id, getUser]);

    useEffect(() => {
        if (updateRequest.state === RequestState.DONE) {
            onDismiss();
        } else if (updateRequest.state === RequestState.ERROR) {
            setError(updateRequest.error);
        }
    }, [onDismiss, updateRequest]);

    if (request.state === RequestState.ERROR) {
        return <ErrorMessage error={request.error} isToast />;
    }

    if (request.state !== RequestState.DONE) {
        return <InlineLoading className={styles.loading} />;
    }

    if (arrangementRequest.state === RequestState.ERROR) {
        return <ErrorMessage error={arrangementRequest.error} isToast />;
    }

    if (arrangementRequest.state !== RequestState.DONE) {
        return <InlineLoading className={styles.loading} />;
    }

    return (
        <EditAccount
            user={request.value}
            onDismiss={onDismiss}
            onSubmit={(input) => updateUser(id, input)}
            error={error}
            EditButtons={
                <EditButtons subject={request.value} onDismiss={onDismiss} onError={setError} refresh={refresh} />
            }
            isSubmitting={updateRequest.state === RequestState.LOADING}
            arrangements={arrangementRequest.value}
        />
    );
};

export default EditAccountContainer;
