import { useContext, useCallback } from "react";
import { MediaApiContext } from "../contexts/MediaApiContext";
import { ImageInputModel, UpdateMediaRequest } from "../openapi/backend";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { useApiCall, useApiCallback } from "./UseApiCall";

export const useGetMediaLibrary = () => {
    const api = useContext(MediaApiContext);
    const callback = useCallback(
        (query: string) =>
            api.getLibrary({
                version: REACT_APP_BACKEND_VERSION,
                query,
            }),
        [api],
    );
    return useApiCallback(callback);
};

export const useGetMedia = (mediaId: number) => {
    const api = useContext(MediaApiContext);
    const callback = useCallback(
        () =>
            api.getMedia({
                version: REACT_APP_BACKEND_VERSION,
                id: mediaId,
            }),
        [api, mediaId],
    );
    return useApiCall(callback);
};

export const useCreateMediaImage = () => {
    const api = useContext(MediaApiContext);
    const callback = useCallback(
        (input: ImageInputModel) =>
            api.createMedia({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
            }),
        [api],
    );
    return useApiCallback(callback);
};

export const useDeleteMedia = (mediaId: number) => {
    const api = useContext(MediaApiContext);
    const callback = useCallback(
        () =>
            api.deleteMedia({
                version: REACT_APP_BACKEND_VERSION,
                id: mediaId,
            }),
        [api, mediaId],
    );
    return useApiCallback(callback);
};

export const useRemoveFromUploadedByMe = () => {
    const api = useContext(MediaApiContext);
    const callback = useCallback(
        (mediaId: number) =>
            api.removeFromUploadedByMe({
                version: REACT_APP_BACKEND_VERSION,
                id: mediaId,
            }),
        [api],
    );
    return useApiCallback(callback);
};

type MediaPatch = UpdateMediaRequest["imageModelOperation"];
export const useUpdateMedia = (mediaId: number) => {
    const api = useContext(MediaApiContext);
    const callback = useCallback(
        (patch: MediaPatch) =>
            api.updateMedia({
                version: REACT_APP_BACKEND_VERSION,
                id: mediaId,
                imageModelOperation: patch,
            }),
        [api, mediaId],
    );
    return useApiCallback(callback);
};
