import SearchIcon from "../../../../assets/search_icon.svg";
import { useCallback, useState } from "react";
import TextInputWithButton, { TextInputWithButtonProps } from "../TextInputWithButton/TextInputWithButton";

type Props = Omit<TextInputWithButtonProps, "type" | "defaultValue" | "onActivate" | "icon"> & {
    onSearch: (value: string) => void;
    value?: string;
    defaultValue?: string;
};

const SearchInput = ({ onSearch, ...props }: Props) => {
    const [searchedValue, setSearchedValue] = useState<string>();
    const search = useCallback(
        (value: string) => {
            if (value === searchedValue) {
                return;
            }

            setSearchedValue(value);
            onSearch(value);
        },
        [searchedValue, onSearch],
    );

    return <TextInputWithButton onActivate={search} icon={SearchIcon} {...props} />;
};

export default SearchInput;
