/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ReasonForCancellation {
    UnforeseenCircumstances = "UnforeseenCircumstances",
    BadWeather = "BadWeather",
    InsufficientRegistrations = "InsufficientRegistrations",
}

export function ReasonForCancellationFromJSON(json: any): ReasonForCancellation {
    return ReasonForCancellationFromJSONTyped(json, false);
}

export function ReasonForCancellationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReasonForCancellation {
    return json as ReasonForCancellation;
}

export function ReasonForCancellationToJSON(value?: ReasonForCancellation | null): any {
    return value as any;
}
