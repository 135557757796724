import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import InputField from "../InputField/InputField";
import { FormOption } from "../SelectField/SelectField";

export interface DataListInputProps
    extends Omit<
        React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        "onChange" | "multiple"
    > {
    name: string;
    value?: string;
    defaultValue?: string;
    options: FormOption<string>[];
    list: string;
    onChange?: (selected: string) => void;
}

const findSelectedOption = (options: FormOption<string>[], value: string) =>
    options.find((x) => x.label === value) ?? options.find((x) => x.value === value);

const getValue = (options: FormOption<string>[], value?: string, defaultValue?: string) => {
    const selectedValue = value && findSelectedOption(options, value);
    if (selectedValue) {
        return selectedValue.label;
    }
    const selectedDefaultValue = defaultValue && findSelectedOption(options, defaultValue);
    if (selectedDefaultValue) {
        return selectedDefaultValue.label;
    }

    return "";
};

/**
 * An input field that lets a user choose an option from a list that is filtered while typing.
 */
export default function DataListInput({ options, value, defaultValue, list, onChange, ...props }: DataListInputProps) {
    const Options = useMemo(() => options.map(({ label, value }) => <option key={value} value={label} />), [options]);
    // We control the input through innerValue, innerValue cannot be undefined or the input won't listen to changes
    const [innerValue, setInnerValue] = useState<string>(() => getValue(options, value, defaultValue));
    const [innerValueInvalid, setInnerValueInvalid] = useState(false);

    const onValueChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            const selected = findSelectedOption(options, inputValue);
            if (selected) {
                onChange && onChange(selected?.value);
                setInnerValue(selected.label);
            } else {
                setInnerValue(inputValue);
                if (inputValue.length > 0) {
                    setInnerValueInvalid(true);
                } else {
                    setInnerValueInvalid(false);
                }
            }
        },
        [options, onChange],
    );

    useEffect(() => {
        setInnerValue(getValue(options, value));
        setInnerValueInvalid(false);
    }, [options, value, setInnerValue]);

    return (
        <>
            <InputField
                {...props}
                list={list}
                value={innerValue}
                onChange={onValueChange}
                invalid={innerValueInvalid}
            />
            <datalist id={list}>{Options}</datalist>
        </>
    );
}
