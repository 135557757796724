/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    FormType,
    FormTypeFromJSON,
    FormTypeFromJSONTyped,
    FormTypeToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface ArrangementListOutput
 */
export interface ArrangementListOutput {
    /**
     *
     * @type {string}
     * @memberof ArrangementListOutput
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof ArrangementListOutput
     */
    name: string;
    /**
     *
     * @type {ImageViewModel}
     * @memberof ArrangementListOutput
     */
    logo: ImageViewModel;
    /**
     *
     * @type {FormType}
     * @memberof ArrangementListOutput
     */
    form?: FormType;
}

/**
 * Check if a given object implements the ArrangementListOutput interface.
 */
export function instanceOfArrangementListOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "logo" in value;

    return isInstance;
}

export function ArrangementListOutputFromJSON(json: any): ArrangementListOutput {
    return ArrangementListOutputFromJSONTyped(json, false);
}

export function ArrangementListOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrangementListOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        logo: !exists(json, "logo") ? (undefined as any) : ImageViewModelFromJSON(json["logo"]),
        form: !exists(json, "form") ? (undefined as any) : FormTypeFromJSON(json["form"]),
    };
}

export function ArrangementListOutputToJSON(value?: ArrangementListOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
