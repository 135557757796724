import React, { PropsWithChildren, useState } from "react";
import { Apply200Response, FileViewModel } from "../openapi/backend";

type ContextType = (Apply200Response & { file?: FileViewModel }) | undefined;

export const EditApplicationContext = React.createContext<
    [ContextType, React.Dispatch<React.SetStateAction<ContextType>>]
>([undefined, () => {}]);

export const EditApplicationContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const state = useState<ContextType>(undefined);

    return <EditApplicationContext.Provider value={state}>{children}</EditApplicationContext.Provider>;
};
