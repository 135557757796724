import styles from "./ResultMessage.module.scss";
import { RequestState } from "../../../hooks/UseApiCall";
import SuccessIcon from "../../../assets/green_check_icon.svg";
import ErrorIcon from "../../../assets/alert_icon.svg";

interface ResultMessageProps {
    state: RequestState;
    errorText: string;
    successText: string;
    className?: string;
}

interface MessageProps {
    text: string;
    icon: string;
    className?: string;
}

const Message = ({ icon, text, className }: MessageProps) => (
    <div className={`${styles.message} ${className}`}>
        <img src={icon} alt="" className={styles.icon} />
        {text}
    </div>
);

export default function ResultMessage({ state, errorText, successText, className }: ResultMessageProps) {
    return (
        <>
            {state === RequestState.DONE && <Message text={successText} icon={SuccessIcon} className={className} />}
            {state === RequestState.ERROR && <Message text={errorText} icon={ErrorIcon} className={className} />}
        </>
    );
}
