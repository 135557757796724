/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ApplicationEventOutput,
    ApplicationEventOutputFromJSON,
    ApplicationEventOutputFromJSONTyped,
    ApplicationEventOutputToJSON,
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
    ArrangementListOutput,
    ArrangementListOutputFromJSON,
    ArrangementListOutputFromJSONTyped,
    ArrangementListOutputToJSON,
    FormType,
    FormTypeFromJSON,
    FormTypeFromJSONTyped,
    FormTypeToJSON,
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    UserListOutput,
    UserListOutputFromJSON,
    UserListOutputFromJSONTyped,
    UserListOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface JscApplicationOutput
 */
export interface JscApplicationOutput {
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    socialAssistance?: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    reasonOfDeficiency: string;
    /**
     *
     * @type {boolean}
     * @memberof JscApplicationOutput
     */
    hasCityPass: boolean;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    cityPassName?: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    cityPassNumber?: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    sportsClubName: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    intendedActivity: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    activityCity: string;
    /**
     *
     * @type {number}
     * @memberof JscApplicationOutput
     */
    membershipFee: number;
    /**
     *
     * @type {Date}
     * @memberof JscApplicationOutput
     */
    membershipStartDate: Date;
    /**
     *
     * @type {Date}
     * @memberof JscApplicationOutput
     */
    membershipEndDate: Date;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    additionalRequirements?: string;
    /**
     *
     * @type {number}
     * @memberof JscApplicationOutput
     */
    requirementsPrice?: number;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    requirementsShop?: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    requirementsCity?: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    id: string;
    /**
     *
     * @type {FormType}
     * @memberof JscApplicationOutput
     */
    type: FormType;
    /**
     *
     * @type {ApplicationStatus}
     * @memberof JscApplicationOutput
     */
    status: ApplicationStatus;
    /**
     *
     * @type {ArrangementListOutput}
     * @memberof JscApplicationOutput
     */
    arrangement: ArrangementListOutput;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    familyName: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    houseNumber: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    postalCode: string;
    /**
     *
     * @type {Gender}
     * @memberof JscApplicationOutput
     */
    gender: Gender;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    email: string;
    /**
     *
     * @type {Date}
     * @memberof JscApplicationOutput
     */
    dateOfBirth: Date;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    supplement?: string;
    /**
     *
     * @type {UserListOutput}
     * @memberof JscApplicationOutput
     */
    reviewer?: UserListOutput;
    /**
     *
     * @type {UserListOutput}
     * @memberof JscApplicationOutput
     */
    applicant?: UserListOutput;
    /**
     *
     * @type {string}
     * @memberof JscApplicationOutput
     */
    subjectMunicipality?: string;
    /**
     *
     * @type {Array<ApplicationEventOutput>}
     * @memberof JscApplicationOutput
     */
    events: Array<ApplicationEventOutput>;
}

/**
 * Check if a given object implements the JscApplicationOutput interface.
 */
export function instanceOfJscApplicationOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reasonOfDeficiency" in value;
    isInstance = isInstance && "hasCityPass" in value;
    isInstance = isInstance && "sportsClubName" in value;
    isInstance = isInstance && "intendedActivity" in value;
    isInstance = isInstance && "activityCity" in value;
    isInstance = isInstance && "membershipFee" in value;
    isInstance = isInstance && "membershipStartDate" in value;
    isInstance = isInstance && "membershipEndDate" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "arrangement" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "houseNumber" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function JscApplicationOutputFromJSON(json: any): JscApplicationOutput {
    return JscApplicationOutputFromJSONTyped(json, false);
}

export function JscApplicationOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): JscApplicationOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        socialAssistance: !exists(json, "socialAssistance") ? (undefined as any) : json["socialAssistance"],
        reasonOfDeficiency: !exists(json, "reasonOfDeficiency") ? (undefined as any) : json["reasonOfDeficiency"],
        hasCityPass: !exists(json, "hasCityPass") ? (undefined as any) : json["hasCityPass"],
        cityPassName: !exists(json, "cityPassName") ? (undefined as any) : json["cityPassName"],
        cityPassNumber: !exists(json, "cityPassNumber") ? (undefined as any) : json["cityPassNumber"],
        sportsClubName: !exists(json, "sportsClubName") ? (undefined as any) : json["sportsClubName"],
        intendedActivity: !exists(json, "intendedActivity") ? (undefined as any) : json["intendedActivity"],
        activityCity: !exists(json, "activityCity") ? (undefined as any) : json["activityCity"],
        membershipFee: !exists(json, "membershipFee") ? (undefined as any) : json["membershipFee"],
        membershipStartDate: !exists(json, "membershipStartDate")
            ? (undefined as any)
            : new Date(json["membershipStartDate"]),
        membershipEndDate: !exists(json, "membershipEndDate")
            ? (undefined as any)
            : new Date(json["membershipEndDate"]),
        additionalRequirements: !exists(json, "additionalRequirements")
            ? (undefined as any)
            : json["additionalRequirements"],
        requirementsPrice: !exists(json, "requirementsPrice") ? (undefined as any) : json["requirementsPrice"],
        requirementsShop: !exists(json, "requirementsShop") ? (undefined as any) : json["requirementsShop"],
        requirementsCity: !exists(json, "requirementsCity") ? (undefined as any) : json["requirementsCity"],
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        type: !exists(json, "type") ? (undefined as any) : FormTypeFromJSON(json["type"]),
        status: !exists(json, "status") ? (undefined as any) : ApplicationStatusFromJSON(json["status"]),
        arrangement: !exists(json, "arrangement")
            ? (undefined as any)
            : ArrangementListOutputFromJSON(json["arrangement"]),
        givenName: !exists(json, "givenName") ? (undefined as any) : json["givenName"],
        familyName: !exists(json, "familyName") ? (undefined as any) : json["familyName"],
        street: !exists(json, "street") ? (undefined as any) : json["street"],
        houseNumber: !exists(json, "houseNumber") ? (undefined as any) : json["houseNumber"],
        postalCode: !exists(json, "postalCode") ? (undefined as any) : json["postalCode"],
        gender: !exists(json, "gender") ? (undefined as any) : GenderFromJSON(json["gender"]),
        city: !exists(json, "city") ? (undefined as any) : json["city"],
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        dateOfBirth: !exists(json, "dateOfBirth") ? (undefined as any) : new Date(json["dateOfBirth"]),
        phoneNumber: !exists(json, "phoneNumber") ? (undefined as any) : json["phoneNumber"],
        supplement: !exists(json, "supplement") ? (undefined as any) : json["supplement"],
        reviewer: !exists(json, "reviewer") ? (undefined as any) : UserListOutputFromJSON(json["reviewer"]),
        applicant: !exists(json, "applicant") ? (undefined as any) : UserListOutputFromJSON(json["applicant"]),
        subjectMunicipality: !exists(json, "subjectMunicipality") ? (undefined as any) : json["subjectMunicipality"],
        events: !exists(json, "events")
            ? (undefined as any)
            : ((json["events"] as Array<any>) ?? []).map(ApplicationEventOutputFromJSON),
    };
}

export function JscApplicationOutputToJSON(value?: JscApplicationOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
