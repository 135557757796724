import { useMemo, HTMLAttributes } from "react";
import strings from "../../../localization/strings";
import styles from "./LengthIndicator.module.scss";
import AlertIcon from "./../../../assets/alert_icon.svg";

interface LengthIndicatorProps {
    length: number;
    maxLength: number;
}

export default function LengthIndicator({ length, maxLength }: LengthIndicatorProps) {
    const indicatorProps = useMemo<HTMLAttributes<HTMLDivElement>>(() => {
        return !(maxLength && length >= maxLength)
            ? {
                  className: styles.lengthIndicator,
              }
            : {
                  className: `${styles.lengthIndicator} ${styles.alert}`,
                  role: "alert",
                  "aria-label": strings.formatString(strings.inputMaxReached, maxLength) as string,
              };
    }, [length, maxLength]);

    return (
        <div {...indicatorProps}>
            {length >= maxLength && <img src={AlertIcon} alt={strings.warning} />} {length}/{maxLength}
        </div>
    );
}
