import React from "react";
import { useCreateOrganisationInfo } from "../../../hooks/OrganisationInfoHooks";
import { ImageViewModel } from "../../../openapi/backend";
import OrganisationInfoForm from "./OrganisationInfoForm";

const STORED_IMAGES = [] as Array<ImageViewModel>;

export default function CreateOrganisationInfoForm() {
    return <OrganisationInfoForm saveCallback={useCreateOrganisationInfo()} storedImages={STORED_IMAGES} />;
}
