import { useCallback, useMemo } from "react";
import { IndicatorStateOption } from "../../../types/DropdownOption";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import { getIndicatorStateOptions } from "../../../utils.ts/StateOptions";
import { statusToString } from "../../../utils.ts/GetStatus";
import StateLabel from "../StateLabel/StateLabel";
import strings from "../../../localization/strings";
import styles from "./FilterBar.module.scss";
import { useOrganiserOptions } from "../../../hooks/TagHooks";
import IconTagOption from "../IconTagOption/IconTagOption";
import { TagViewModel } from "../../../openapi/backend";
import { useProjectLeaderOptions } from "../../../hooks/UserHooks";
import { UserNamedType } from "../../../types/UserNamedType";
import { getName } from "../../../utils.ts/GetName";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import DatePicker from "../Inputs/DatePicker/DatePicker";
import { IndicatorsFilter } from "../../../hooks/IndicatorHooks";
import QueryFilterInput from "./QueryFilterInput";

type IndicatorFilterBarProps = FilterBarPropsWithValue<IndicatorsFilter>;
const stateOptions = getIndicatorStateOptions();

export default function IndicatorsFilterBar({ value: filters, onChange }: IndicatorFilterBarProps) {
    const organisationsOptions = useOrganiserOptions();

    const { value: organizers } = useProjectLeaderOptions();

    const organizerOptions: UserNamedType[] = useMemo(
        () => (organizers ? organizers.map((organizer) => ({ ...organizer, name: getName(organizer) })) : []),
        [organizers],
    );

    const defaultFilters = useMemo(() => {
        return {};
    }, []);

    const onReset = () => {
        onChange(defaultFilters);
    };

    const onChangeStatuses = useCallback(
        (filter: IndicatorStateOption[]) => onChange({ ...filters, statuses: filter }),
        [onChange, filters],
    );

    const onChangeOrganisations = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, organisations: filter }),
        [onChange, filters],
    );

    const onChangeOrganizer = useCallback(
        (filter: UserNamedType[]) =>
            onChange({
                ...filters,
                organizers: filter,
            }),
        [onChange, filters],
    );

    const onChangeDate = useCallback(
        (date: Date | null) => {
            date?.setHours(23, 59, 59); // toDate has to be at midnight to include activities on that day
            onChange({ ...filters, date: date || undefined });
        },
        [onChange, filters],
    );

    return (
        <FilterBar onReset={onReset}>
            <QueryFilterInput filters={filters} onChange={onChange} />

            <div className={styles.dateFilter}>
                <DatePicker
                    startDate={filters.date}
                    onChange={onChangeDate}
                    className={styles.datePicker}
                    placeholderText={strings.date}
                    selected={filters.date}
                />
            </div>

            <MultiselectDropdown
                options={organisationsOptions}
                optionToKey={(option) => option.id}
                renderOption={(option) => <IconTagOption tag={option} />}
                placeholder={strings.happeningGroup}
                name={"organisationsFilter"}
                className={styles.filter}
                onItemSelect={onChangeOrganisations}
                value={filters.organisations}
                searchable
                searchPlaceholder={strings.searchByOrganisation}
            />

            <MultiselectDropdown
                options={organizerOptions}
                renderOption={(option) => (
                    <div className={styles.group}>
                        <ProfileIcon profile={option} />
                        <span>{option.name}</span>
                    </div>
                )}
                placeholder={strings.projectLeader}
                name={"organizersFilter"}
                className={styles.filter}
                onItemSelect={onChangeOrganizer}
                value={filters.organizers}
                searchable
                searchPlaceholder={strings.searchByProjectLeaderName}
            />

            <MultiselectDropdown
                options={stateOptions}
                optionToString={(option) => statusToString(option.value)}
                renderOption={(option) => (
                    <div>
                        <StateLabel state={option.value} />
                    </div>
                )}
                placeholder={strings.state}
                name={"statusesFilter"}
                className={styles.filter}
                onItemSelect={onChangeStatuses}
                value={filters.statuses}
            />
        </FilterBar>
    );
}
