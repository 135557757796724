import { HappeningRegistrationOutput, HappeningTimeAndDateViewModel } from "../openapi/backend";

export const findAttendencyBySessionId = (registration: HappeningRegistrationOutput, sessionId: number) => {
    return registration.attendencies.find((att) => att.sessionId === sessionId);
};

export const calculateMaxAttendance = (registrationDate: Date, activities: HappeningTimeAndDateViewModel[]) => {
    const relevantActivities = activities.filter(
        (activity) => !activity.cancelled && new Date(activity.sessionStart) >= new Date(registrationDate),
    );

    return relevantActivities.length;
};
