import strings from "../localization/strings";
import { Gender } from "../openapi/backend";

export function getStringForGender(gender: Gender | undefined): string {
    switch (gender) {
        case Gender.Female:
            return strings.female;
        case Gender.Male:
            return strings.male;
        case Gender.Other:
            return strings.other;
        case Gender.Unknown:
            return strings.ratherNotSay;
        default:
            return strings.unknown;
    }
}

export function getStringForGenderChild(gender: Gender | undefined): string {
    switch (gender) {
        case Gender.Female:
            return strings.girl;
        case Gender.Male:
            return strings.boy;
        case Gender.Other:
            return strings.other;
        case Gender.Unknown:
            return strings.ratherNotSay;
        default:
            return strings.unknown;
    }
}
