/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
    MediaUsage,
    MediaUsageFromJSON,
    MediaUsageFromJSONTyped,
    MediaUsageToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface MediaViewModel
 */
export interface MediaViewModel {
    /**
     *
     * @type {number}
     * @memberof MediaViewModel
     */
    id: number;
    /**
     *
     * @type {ImageViewModel}
     * @memberof MediaViewModel
     */
    image: ImageViewModel;
    /**
     *
     * @type {string}
     * @memberof MediaViewModel
     */
    originalFileName: string;
    /**
     *
     * @type {string}
     * @memberof MediaViewModel
     */
    fileSize: string;
    /**
     *
     * @type {Date}
     * @memberof MediaViewModel
     */
    lastUpdated: Date;
    /**
     *
     * @type {Array<MediaUsage>}
     * @memberof MediaViewModel
     */
    usages: Array<MediaUsage>;
    /**
     *
     * @type {Array<TagViewModel>}
     * @memberof MediaViewModel
     */
    tags: Array<TagViewModel>;
    /**
     *
     * @type {TagViewModel}
     * @memberof MediaViewModel
     */
    category?: TagViewModel;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof MediaViewModel
     */
    uploader?: ProfileShortOutput;
}

/**
 * Check if a given object implements the MediaViewModel interface.
 */
export function instanceOfMediaViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "originalFileName" in value;
    isInstance = isInstance && "fileSize" in value;
    isInstance = isInstance && "lastUpdated" in value;
    isInstance = isInstance && "usages" in value;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function MediaViewModelFromJSON(json: any): MediaViewModel {
    return MediaViewModelFromJSONTyped(json, false);
}

export function MediaViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        image: !exists(json, "image") ? (undefined as any) : ImageViewModelFromJSON(json["image"]),
        originalFileName: !exists(json, "originalFileName") ? (undefined as any) : json["originalFileName"],
        fileSize: !exists(json, "fileSize") ? (undefined as any) : json["fileSize"],
        lastUpdated: !exists(json, "lastUpdated") ? (undefined as any) : new Date(json["lastUpdated"]),
        usages: !exists(json, "usages")
            ? (undefined as any)
            : ((json["usages"] as Array<any>) ?? []).map(MediaUsageFromJSON),
        tags: !exists(json, "tags")
            ? (undefined as any)
            : ((json["tags"] as Array<any>) ?? []).map(TagViewModelFromJSON),
        category: !exists(json, "category") ? (undefined as any) : TagViewModelFromJSON(json["category"]),
        uploader: !exists(json, "uploader") ? (undefined as any) : ProfileShortOutputFromJSON(json["uploader"]),
    };
}

export function MediaViewModelToJSON(value?: MediaViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
