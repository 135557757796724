import "./App.css";
import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./components/Home/Home";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import MyProfileContainer from "./components/Profile/MyProfileContainer";
import useUserRoute from "./hooks/UseUserRoute";
import useAppsettingsRoute from "./hooks/UseAppsettingsRoute";
import useHappeningsRoute from "./hooks/UseHappeningsRoute";
import { ProfileContext } from "./contexts/ProfileContext";
import LoadingSession from "./components/core/LoadingSession/LoadingSession";
import { canManageMediaLibrary, canViewBO } from "./authentication/Permissions";
import BackoffPage from "./components/BackoffPage/BackoffPage";
import Notifications from "./components/Notifications/Notifications";
import useNotificationsRoute from "./hooks/UseNotificationsRoute";
import UpgradeAcrPage from "./components/UpgradeAcrPage/UpgradeAcrPage";
import useApplicationsRoute from "./hooks/UseApplicationsRoute";
import moment from "moment";
import strings from "./localization/strings";
import { CustomLocale } from "./utils.ts/DateTime";
import Bouncer from "./authentication/Bouncer";
import { MediaLibraryManagementContainer } from "./components/core/MediaLibraryManagement/MediaLibraryManagementContainer";
import { MediaLibraryDataProvider } from "./contexts/MediaLibraryDataContext";
import useMonitoringRoute from "./hooks/UseMonitoringRoute";

export default function App() {
    moment.updateLocale(strings.getLanguage(), CustomLocale);
    const happeningsRoute = useHappeningsRoute();
    const appsettingsRoute = useAppsettingsRoute();
    const notificationsRoute = useNotificationsRoute();
    const applicationsRoute = useApplicationsRoute();
    const monitoringRoute = useMonitoringRoute();
    const appUsersRoute = useUserRoute();
    const { profile, error } = useContext(ProfileContext);

    if (error && error.status === 403) {
        return <UpgradeAcrPage />;
    }

    if (!profile) {
        return <LoadingSession />;
    }

    if (!canViewBO(profile)) {
        return <BackoffPage />;
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="*" element={<PageNotFound />} />
                    {happeningsRoute}
                    {appUsersRoute}
                    {appsettingsRoute}
                    {notificationsRoute}
                    {applicationsRoute}
                    {monitoringRoute}
                    <Route
                        path="media"
                        element={
                            <Bouncer
                                permissions={[canManageMediaLibrary]}
                                element={
                                    <MediaLibraryDataProvider>
                                        <MediaLibraryManagementContainer />
                                    </MediaLibraryDataProvider>
                                }
                            />
                        }
                    />
                    <Route element={<MyProfileContainer />} path="profile" />
                    <Route element={<Notifications />} path="notifications" />
                </Route>
            </Routes>
        </div>
    );
}
