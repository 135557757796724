import React from "react";
import { MediaLibraryDataProvider } from "../../../contexts/MediaLibraryDataContext";
import strings from "../../../localization/strings";
import { MediaLibraryInputProps, MediaLibraryModalProps } from "../../../types/MediaLibraryType";
import Modal from "../Modal/Modal";
import { MediaLibraryContainer } from "./MediaLibraryContainer";

export type Props = MediaLibraryInputProps & MediaLibraryModalProps;

export const MediaLibraryModal = (props: Props) => (
    <Modal isOpen={true} title={strings.mediaLibraryModalTitle} onClose={props.onClose}>
        <MediaLibraryDataProvider>
            <MediaLibraryContainer {...props} />
        </MediaLibraryDataProvider>
    </Modal>
);
