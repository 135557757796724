import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { ImageInputModel } from "../../../openapi/backend";
import { FormImageInputModel } from "../../../types/MediaLibraryType";
import { acceptImageFileTypes } from "../../../utils.ts/Media";
import FormField from "../Form/FormField/FormField";
import TextButton from "../TextButton/TextButton";
import styles from "./ImageUploadInput.module.scss";
import { validationSchema } from "./ImageUploadValidation";

interface Props {
    className?: string;
    requestStatus: RequestState;
    onAdd: (data: ImageInputModel) => void;
}

export default function ImageUploadInput({ className, requestStatus, onAdd }: Props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormImageInputModel>({
        defaultValues: { altText: "", fileList: undefined },
        resolver: yupResolver(validationSchema) as any,
    });

    const onSubmit = useMemo(() => {
        return handleSubmit((data) => {
            if (requestStatus === RequestState.LOADING) {
                return;
            }

            const { fileList, ...input } = data;
            onAdd({ ...input, file: fileList.item(0) as Blob });
        });
    }, [handleSubmit, requestStatus, onAdd]);

    const onKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                e.preventDefault();
                onSubmit();
            }
        },
        [onSubmit],
    );

    return (
        <div className={cx(styles.container, className)}>
            <FormField
                type="file"
                name="fileList"
                errors={errors?.fileList}
                accept={acceptImageFileTypes}
                placeholder={strings.mediaUploadFileInputPlaceholder}
                register={register}
                inputClassName={cx(styles.inputNoMargin, styles.fileInput)}
                required
            />
            <FormField
                register={register}
                name="altText"
                placeholder={strings.titleImage}
                errors={errors?.altText}
                inputClassName={styles.inputNoMargin}
                required
                onKeyPress={onKeyPress}
            />
            <TextButton
                onClick={onSubmit}
                className={styles.add}
                text={strings.upload}
                isLoading={requestStatus === RequestState.LOADING}
            />
        </div>
    );
}
