import {
    ApplicationStatus,
    Gender,
    IndicatorStatus,
    LinkTarget,
    MeasurementStatus,
    Responsibilities,
} from "../openapi/backend";
import { HappeningState } from "../openapi/backend/models/HappeningState";
import {
    ApplicationStatusOption,
    GenderOption,
    HappeningStateOption,
    IndicatorStateOption,
    LinkTargetOption,
    MeasurementsStateOptions,
    ResponsibilityOption,
} from "../types/DropdownOption";

export const getStateOptions = (allowedStates: HappeningState[]): HappeningStateOption[] =>
    [
        HappeningState.Open,
        HappeningState.Concept,
        HappeningState.PlannedForPublication,
        HappeningState.Rejected,
        HappeningState.PartnerSuggestion,
        HappeningState.Cancelled,
    ]
        // allow if empty or not in excludeStates
        .filter((state) => allowedStates.length === 0 || allowedStates.includes(state))
        .map((state) => ({
            name: state as keyof typeof HappeningState,
            value: HappeningState[state as keyof typeof HappeningState],
        }));

export const getStateOptionsForm = (): HappeningStateOption[] =>
    [
        HappeningState.Open,
        HappeningState.Concept,
        HappeningState.PlannedForPublication,
        HappeningState.Rejected,
        HappeningState.PartnerSuggestion,
        HappeningState.Cancelled,
        HappeningState.Archived,
    ].map((state) => ({
        name: state as keyof typeof HappeningState,
        value: HappeningState[state as keyof typeof HappeningState],
    }));

export const getIndicatorStateOptions = (): IndicatorStateOption[] =>
    [
        IndicatorStatus.Concept,
        IndicatorStatus.Open,
        IndicatorStatus.Archived,
        IndicatorStatus.PlannedForPublication,
    ].map((state) => ({
        name: state as keyof typeof IndicatorStatus,
        value: IndicatorStatus[state as keyof typeof IndicatorStatus],
    }));

export const getMeasurementsStateOptions = (): MeasurementsStateOptions[] =>
    [MeasurementStatus.Concept, MeasurementStatus.Open, MeasurementStatus.Closed].map((state) => ({
        name: state as keyof typeof MeasurementStatus,
        value: MeasurementStatus[state as keyof typeof MeasurementStatus],
    }));

export const getResponsibilityOptions = (): ResponsibilityOption[] =>
    Object.keys(Responsibilities).map((state) => ({
        name: state as keyof typeof Responsibilities,
        value: Responsibilities[state as keyof typeof Responsibilities],
    }));

export const getApplicationStatusOptions = (): ApplicationStatusOption[] =>
    [
        ApplicationStatus.Received,
        ApplicationStatus.InReview,
        ApplicationStatus.PendingAction,
        ApplicationStatus.Submitted,
        ApplicationStatus.NotSubmitted,
        ApplicationStatus.Rejected,
        ApplicationStatus.Approved,
    ].map((state) => ({
        name: state as keyof typeof ApplicationStatus,
        value: ApplicationStatus[state as keyof typeof ApplicationStatus],
    }));

export const getGenderOptions = (): GenderOption[] =>
    Object.keys(Gender).map((state) => ({
        name: state as keyof typeof Gender,
        value: Gender[state as keyof typeof Gender],
    }));

export const getLinkTargetOptions = (): LinkTargetOption[] =>
    Object.keys(LinkTarget).map((state) => ({
        name: state as keyof typeof LinkTarget,
        value: LinkTarget[state as keyof typeof LinkTarget],
    }));
