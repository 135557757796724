/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NotificationFilterType {
    General = "General",
    Happening = "Happening",
}

export function NotificationFilterTypeFromJSON(json: any): NotificationFilterType {
    return NotificationFilterTypeFromJSONTyped(json, false);
}

export function NotificationFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationFilterType {
    return json as NotificationFilterType;
}

export function NotificationFilterTypeToJSON(value?: NotificationFilterType | null): any {
    return value as any;
}
