import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import MultiSelectField, { MultiSelectFieldProps } from "../../Inputs/MultiListSelect/MultiSelectField";
import { FieldName } from "../FormTypes";

interface FormMultiSelectFieldProps<TFieldValues extends FieldValues, TName extends FieldName<TFieldValues, string[]>>
    extends Omit<MultiSelectFieldProps, "onChange"> {
    label: string;
    name: TName;
    control: Control<TFieldValues, object>;
    errors?: FieldError[] | FieldError;
}

export default function FormMultiSelectField<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, string[]>,
>({ control, name, label, errors, required, ...fieldProps }: FormMultiSelectFieldProps<TFieldValues, TName>) {
    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value } }) => (
                    <MultiSelectField {...fieldProps} name={name} value={value} onChange={onChange} />
                )}
            />
        </FormFieldContainer>
    );
}
