import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import { TagsContext } from "../../contexts/TagsContext";
import { GetOrgansationInfoFun, OrganisationInfoFilter } from "../../hooks/OrganisationInfoHooks";
import {
    InclusionFilter,
    NamedOptionsFilter,
    PageNumberFilter,
    StringFilter,
    useFilters,
} from "../../hooks/UseSearchParam";
import strings from "../../localization/strings";
import { OrganisationInfoOrderBy, PaginationMeta, SortOrder } from "../../openapi/backend";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import { OrganisationFilterBar } from "../core/FilterBar/OrganisationInfoFilterBar";
import Table from "../core/Table/Table";

interface InfoItem {
    Id: string;
}

export interface Props<T extends InfoItem> {
    columns: Column<T>[];
    data: T[];
    meta?: PaginationMeta;
    getItems: GetOrgansationInfoFun;
    error?: Response;
}

// @TODO different story SBG-1384

const itemsPerPage = 10;

export default function OrganisationInfoTable<T extends InfoItem>({ columns, data, meta, getItems, error }: Props<T>) {
    const navigate = useNavigate();
    const [orderBy, setOrderBy] = useState(OrganisationInfoOrderBy.Default);
    const [sortOrder, setSortOrder] = useState(SortOrder.Ascending);
    const tags = useContext(TagsContext).allTags;

    const filterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            isActive: new InclusionFilter(),
            categories: new NamedOptionsFilter(tags.categories),
            page: new PageNumberFilter(),
        }),
        [tags.categories],
    );

    const [rawFilters, setFilters] = useFilters(filterSpecs);
    const filters = rawFilters as OrganisationInfoFilter;

    const onFilterChange = useCallback(
        (filter: OrganisationInfoFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const loadItems = useCallback(
        () => getItems({ orderBy, sortOrder, itemsPerPage }, filters),
        [getItems, orderBy, sortOrder, filters],
    );

    useEffect(() => {
        loadItems();
    }, [loadItems]);

    const handleSort = useCallback(
        (sortBy) => {
            // We only sort on 1 column so only take the first element in sortBy
            if (sortBy[0]) {
                setOrderBy(sortBy[0].id);
                setSortOrder(sortBy[0].desc ? SortOrder.Ascending : SortOrder.Descending);
            } else {
                // If no sorting is applied, revert back to default sorting
                setOrderBy(OrganisationInfoOrderBy.Default);
                setSortOrder(SortOrder.Ascending);
            }
        },
        [setOrderBy, setSortOrder],
    );

    const onRowClick = useCallback(
        (item: InfoItem) => {
            navigate(`/appsettings/organisation-info/${item.Id}`);
        },
        [navigate],
    );

    return (
        <div>
            <OrganisationFilterBar onChange={onFilterChange} value={filters} />

            {error && <ErrorMessage error={error} />}

            {!error && (
                <Table
                    columns={columns}
                    data={data}
                    emptyString={strings.noOrganisationInfoFound}
                    setPage={setPage}
                    paginationMeta={meta}
                    onSort={handleSort}
                    onRowClick={onRowClick}
                    forcePage={filters.page}
                />
            )}
        </div>
    );
}
