import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import CameraGreenIcon from "../../../assets/camera_green.svg";
import CameraRedIcon from "../../../assets/camera_red.svg";
import NotesIcon from "../../../assets/info_icon.svg";
import MedicalIcon from "../../../assets/medical_icon.svg";
import { LocalHappeningContext } from "../../../contexts/LocalHappeningContext";
import { useDeleteGroup, useMoveRegistrationToTeam } from "../../../hooks/HappeningsHooks";
import { useUnsubscribe } from "../../../hooks/RegistrationHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { HappeningType, HappeningViewModel, TeamViewModel } from "../../../openapi/backend";
import { hasValues } from "../../../utils.ts/Array";
import { formatDate } from "../../../utils.ts/DateTime";
import { getStringForGender } from "../../../utils.ts/GetGenderString";
import { getName } from "../../../utils.ts/GetName";
import { toastError, toastSuccess } from "../../../utils.ts/Toaster";
import UnsubscribeParticipantReasonModal from "../../Forms/ViewHappening/UnsubscribeParticipantReasonModal";
import FlexRow from "../../Layouts/FlexRow/FlexRow";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import SelectField, { FormOption } from "../Inputs/SelectField/SelectField";
import PaymentTooltip from "../PaymentTooltip/PaymentTooltip";
import { RemoveButton } from "../RoundButton/RoundButton";
import StaticTable from "../StaticTable/StaticTable";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import styles from "./TeamRegistration.module.scss";
import QATooltip from "../TooltipIcon/QATooltip";

interface TeamRegistrationProps {
    team: TeamViewModel;
    happening: HappeningViewModel;
    isAllowedToManage: boolean;
}

type TableItem = {
    Name: string;
    Contact: string;
    Gender: string;
    DateOfBirth: string;
    PostalCode: string;
    AssignTeam: React.ReactNode;
    ExtraInformation: React.ReactNode;
};

const itemPerPage = 10;

export default function TeamRegistration({ team, happening, isAllowedToManage }: TeamRegistrationProps) {
    const { refresh } = useContext(LocalHappeningContext);
    const [unsubModalOpen, setUnsubModalOpen] = useState(false);
    const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
    const [{ state: unsubState, error: unsubError }, unsubscribe] = useUnsubscribe();
    const [{ state: deleteGroupState, error: deleteGroupError }, deleteGroup] = useDeleteGroup();
    const [{ state: moveRegistrationToTeamState }, moveRegistrationToTeam] = useMoveRegistrationToTeam();
    const isStandardHappening = useMemo(() => happening.type === HappeningType.Happening, [happening.type]);

    useEffect(() => {
        if (unsubState === RequestState.DONE) {
            refresh();
            setUnsubModalOpen(false);
        }
    }, [unsubState, refresh]);

    useEffect(() => {
        if (deleteGroupState === RequestState.DONE) {
            refresh();
            setDeleteGroupModalOpen(false);
        }
    }, [deleteGroupState, refresh]);

    useEffect(() => {
        if (moveRegistrationToTeamState === RequestState.DONE) {
            refresh();
            toastSuccess(strings.moveTeamSuccess);
        }
    }, [moveRegistrationToTeamState, refresh]);

    useEffect(() => {
        if (moveRegistrationToTeamState === RequestState.ERROR) {
            toastError(strings.moveTeamError);
        }
    }, [moveRegistrationToTeamState]);

    const columns: Column<TableItem>[] = [
        {
            Header: strings.name,
            accessor: "Name",
            width: "15%",
        },
        {
            Header: strings.contact,
            accessor: "Contact",
            width: "15%",
        },
        {
            Header: strings.gender,
            accessor: "Gender",
            width: "10%",
        },
        {
            Header: strings.dateOfBirth,
            accessor: "DateOfBirth",
            width: "10%",
        },
        {
            Header: strings.postalCode,
            accessor: "PostalCode",
            width: "10%",
        },
        {
            Header: strings.team,
            accessor: "AssignTeam",
        },
        {
            Header: "",
            accessor: "ExtraInformation",
        },
    ];

    const getOptions = useCallback(
        (currentTeamId: string): FormOption<string>[] => {
            return (
                happening.teams
                    ?.filter(
                        ({ id: teamId, members }) =>
                            teamId !== currentTeamId && members.length < happening.maxPeoplePerGroup,
                    )
                    ?.map(({ id: teamId, teamName }) => {
                        return {
                            label: teamName,
                            value: teamId,
                        };
                    }) || []
            );
        },
        [happening.teams, happening.maxPeoplePerGroup],
    );

    const data = useMemo<TableItem[]>(() => {
        return team.members.map((member) => {
            // GroupId here is the id of the corresponding registration
            const options = getOptions(team.id);

            const { medicalNotes, notes, approvedForMarketing, extraQuestionAnswers, requiresPayment, payment } =
                happening.registrations.find((registration) => registration.id === member.groupId) || {};

            return {
                Name: getName(member),
                Contact:
                    happening.registrations.find((registration) => registration.id === member.groupId)
                        ?.participantName || "-",
                Gender: getStringForGender(member.gender),
                DateOfBirth: member.dateOfBirth ? formatDate(member.dateOfBirth) : "-",
                PostalCode: member.postalCode || "-",
                AssignTeam:
                    member.isIndividualRegistration && hasValues(options) ? (
                        <SelectField
                            name={`assign-${member.id}`}
                            options={options}
                            transform={(x) => x}
                            placeholder={strings.chooseTeam}
                            onChange={(teamId) => moveRegistrationToTeam(happening.id, teamId, member.groupId)}
                        />
                    ) : (
                        <></>
                    ),
                ExtraInformation: (
                    <FlexRow className={styles.extraInfo}>
                        {medicalNotes && <TooltipIcon message={medicalNotes} icon={MedicalIcon} />}

                        {notes && <TooltipIcon message={notes} icon={NotesIcon} />}
                        <QATooltip extraQuestionAnswers={extraQuestionAnswers} className={styles.tooltip} />
                        {happening.requiresApprovalFromMarketing &&
                            (approvedForMarketing ? (
                                <TooltipIcon message={strings.approvedForMarketing} icon={CameraGreenIcon} />
                            ) : (
                                <TooltipIcon
                                    message={strings.notApprovedForMarketing}
                                    icon={CameraRedIcon}
                                    actionRequired={!approvedForMarketing}
                                />
                            ))}

                        {isStandardHappening && (happening.price > 0 || happening.priceForIndividual > 0) && (
                            <PaymentTooltip requiresPayment={!!requiresPayment} payment={payment} />
                        )}
                    </FlexRow>
                ),
            };
        });
    }, [
        team,
        getOptions,
        moveRegistrationToTeam,
        happening.id,
        happening.registrations,
        happening.priceForIndividual,
        happening.price,
        isStandardHappening,
        happening.requiresApprovalFromMarketing,
    ]);

    const onUnsubscribe = useCallback(
        (reason: string) => {
            unsubscribe({
                happeningId: happening.id,
                profileIds: team.registrations.map((x) => x.participantId),
                reason,
            });
        },
        [unsubscribe, happening.id, team],
    );

    const onDeleteGroup = useCallback(() => {
        deleteGroup(team.id, happening.id);
    }, [deleteGroup, happening, team]);

    return (
        <div className={styles.container}>
            <div className={styles.mainRow}>
                <div className={styles.groupName}>
                    {strings.formatString(
                        strings.teamRegistrationName,
                        team.teamName,
                        team.members.length.toString(),
                        happening.maxPeoplePerGroup > 0 ? happening.maxPeoplePerGroup.toString() : strings.unlimited,
                    )}
                    {happening.minPeoplePerGroup > 0 && team.members.length < happening.minPeoplePerGroup && (
                        <span className={styles.attention}>{strings.teamRegistrationInsufficient}</span>
                    )}
                </div>

                <ConfirmationModal
                    isOpen={deleteGroupModalOpen}
                    onCancel={() => setDeleteGroupModalOpen(false)}
                    onConfirm={onDeleteGroup}
                    isLoading={deleteGroupState === RequestState.LOADING}
                    error={deleteGroupError}
                    errorMessage={strings.errorRemoveTeam}
                >
                    {strings.formatString(strings.confirmRemoveTeam, team.teamName)}
                </ConfirmationModal>

                <UnsubscribeParticipantReasonModal
                    isOpen={unsubModalOpen}
                    onUnsubscribe={onUnsubscribe}
                    onDismiss={() => setUnsubModalOpen(false)}
                    isLoading={unsubState === RequestState.LOADING}
                    error={unsubError}
                />

                <FlexRow>
                    {isAllowedToManage && (
                        <RemoveButton
                            onClick={() => {
                                if (!hasValues(team.members)) {
                                    setDeleteGroupModalOpen(true);
                                } else {
                                    setUnsubModalOpen(true);
                                }
                            }}
                        />
                    )}
                </FlexRow>
            </div>

            <StaticTable
                columns={columns}
                data={data}
                itemsPerPage={itemPerPage}
                renderHeader
                showPagination={data.length > itemPerPage}
            />
        </div>
    );
}
