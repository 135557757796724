import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import React from "react";
import styles from "./EllipsisMenu.module.scss";
import "@szhsin/react-menu/dist/index.css";
import EllipsisIcon from "../../../assets/ellipsis_icon.svg";
import CrossIcon from "../../../assets/action_close_icon.svg";
import strings from "../../../localization/strings";

type Item = {
    icon: string;
    onClick: () => void;
    alt: string;
    title: string;
};

interface Props {
    items: Array<Item | undefined | false>;
}

export default function EllipsisMenu({ items }: Props) {
    return (
        <div className={styles.container}>
            <Menu
                onClick={(e) => e.stopPropagation()}
                menuButton={({ open }) => (
                    <MenuButton
                        className={styles.menuButton}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <img
                            src={open ? CrossIcon : EllipsisIcon}
                            alt={open ? strings.ellipsisMenuAltOpened : strings.ellipsisMenuAltClosed}
                        />
                    </MenuButton>
                )}
                menuClassName={styles.menu}
                align="center"
                direction="left"
                viewScroll="initial"
                gap={8}
                unmountOnClose
            >
                {items.map((item, index) => {
                    if (!item) {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={index}
                            className={styles.menuItem}
                            onClick={(e) => {
                                e.stopPropagation = true;
                                item.onClick();
                            }}
                            title={item.title}
                        >
                            <img src={item.icon} alt={item.alt} className={styles.icon} />
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
