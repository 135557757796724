/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MeasurementOrderBy {
    Default = "Default",
    IndicatorName = "IndicatorName",
    Date = "Date",
    Organisation = "Organisation",
    Organizer = "Organizer",
    Measurer = "Measurer",
    Status = "Status",
}

export function MeasurementOrderByFromJSON(json: any): MeasurementOrderBy {
    return MeasurementOrderByFromJSONTyped(json, false);
}

export function MeasurementOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementOrderBy {
    return json as MeasurementOrderBy;
}

export function MeasurementOrderByToJSON(value?: MeasurementOrderBy | null): any {
    return value as any;
}
