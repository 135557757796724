/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationStatus {
    Received = "Received",
    InReview = "InReview",
    PendingAction = "PendingAction",
    Submitted = "Submitted",
    Rejected = "Rejected",
    Approved = "Approved",
    NotSubmitted = "NotSubmitted",
}

export function ApplicationStatusFromJSON(json: any): ApplicationStatus {
    return ApplicationStatusFromJSONTyped(json, false);
}

export function ApplicationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationStatus {
    return json as ApplicationStatus;
}

export function ApplicationStatusToJSON(value?: ApplicationStatus | null): any {
    return value as any;
}
