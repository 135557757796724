import React from "react";
import { HappeningTimeAndDateInput } from "../../../openapi/backend";
import { FieldError } from "react-hook-form";
import FormFieldContainer from "../Form/FormFieldContainer/FormFieldContainer";
import DateTimePicker from "./DateTimePicker";

interface DateTimePickerContainerProps {
    dateTimes: HappeningTimeAndDateInput[];
    setDateTimes: (dates: HappeningTimeAndDateInput[]) => void;
    concept?: boolean;
    single?: boolean;
    errors?: { sessionStart?: FieldError; sessionEnd?: FieldError }[];
}

export default function DateTimePickerContainer({
    dateTimes,
    setDateTimes,
    concept,
    single,
    errors,
}: DateTimePickerContainerProps) {
    return (
        <div>
            {dateTimes.map((dateTime, index) => {
                return (
                    <FormFieldContainer
                        key={index}
                        name={`dateTime-${index}`}
                        errors={errors && errors[index] && (errors[index].sessionStart || errors[index].sessionEnd)}
                    >
                        <DateTimePicker
                            dateTimes={dateTimes}
                            setDateTimes={setDateTimes}
                            concept={concept}
                            single={single}
                            index={index}
                        />
                    </FormFieldContainer>
                );
            })}
        </div>
    );
}
