import { GoogleProvider, OpenStreetMapProvider } from "leaflet-geosearch";
import { useState, useCallback, useMemo } from "react";
import { REACT_APP_GOOGLE_API_KEY } from "../utils.ts/Env";

export interface FoundLocation {
    address?: string;
    longitude?: number;
    latitude?: number;
}

function useGeocoder() {
    return useMemo(
        () =>
            REACT_APP_GOOGLE_API_KEY
                ? new GoogleProvider({
                      params: {
                          key: REACT_APP_GOOGLE_API_KEY,
                          language: "nl",
                          region: "nl",
                      },
                  })
                : new OpenStreetMapProvider(),
        [],
    );
}

const useLocationSearch = (): [FoundLocation | undefined, (query: string) => void, boolean] => {
    const geocoder = useGeocoder();
    const [isSearching, setIsSearching] = useState(false);
    const [location, setLocation] = useState<FoundLocation>();

    const search = useCallback(
        (query: string) => {
            if (query.length <= 0) {
                return;
            }

            setIsSearching(true);

            geocoder
                .search({ query })
                .then((results) => {
                    if (results.length < 1) {
                        setLocation(undefined);
                        return;
                    }

                    var { label, x, y } = results[0];
                    setLocation({
                        address: label,
                        longitude: x,
                        latitude: y,
                    });
                })
                .finally(() => {
                    setIsSearching(false);
                });
        },
        [setLocation, geocoder],
    );

    return useMemo(() => [location, search, isSearching], [location, search, isSearching]);
};

export default useLocationSearch;
