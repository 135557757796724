import strings from "../localization/strings";
import { HappeningNotificationGroup } from "../openapi/backend";

export function getStringForHappeningNotificationGroup(type: string) {
    switch (type) {
        case HappeningNotificationGroup.Registered:
            return strings.registeredParticipants;
        case HappeningNotificationGroup.Favorited:
            return strings.favoritedParticipants;
        case HappeningNotificationGroup.StandBy:
            return strings.standbyList;
        default:
            return strings.unknown;
    }
}
