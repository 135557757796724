import { useCallback, useContext } from "react";
import { TagsContext } from "../../../contexts/TagsContext";
import { OrganisationInfoFilter } from "../../../hooks/OrganisationInfoHooks";
import strings from "../../../localization/strings";
import { TagViewModel } from "../../../openapi/backend";
import IconTagOption from "../IconTagOption/IconTagOption";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import styles from "./FilterBar.module.scss";
import FilterInclusionMultiselectDropdown, {
    FilterInclusionOption,
    getFilterValueFromOption,
} from "../Inputs/FilterInclusionMultiselectDropdown/FilterInclusionMultiselectDropdown";
import QueryFilterInput from "./QueryFilterInput";

export function OrganisationFilterBar({ value: filters, onChange }: FilterBarPropsWithValue<OrganisationInfoFilter>) {
    const onReset = useCallback(() => onChange({}), [onChange]);
    const tags = useContext(TagsContext).allTags;

    const onChangeCategory = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, categories: filter }),
        [onChange, filters],
    );

    const onChangeActiveState = useCallback(
        (selection: FilterInclusionOption[]) => onChange({ ...filters, isActive: getFilterValueFromOption(selection) }),
        [onChange, filters],
    );

    return (
        <FilterBar onReset={onReset}>
            <QueryFilterInput filters={filters} onChange={onChange} />
            <MultiselectDropdown
                options={tags.categories}
                optionToKey={(option) => option.id}
                renderOption={(option) => <IconTagOption tag={option} />}
                placeholder={strings.category}
                name={"categoryFilter"}
                className={styles.filter}
                onItemSelect={onChangeCategory}
                value={filters.categories}
                searchable
                searchPlaceholder={strings.searchByCategory}
            />
            <FilterInclusionMultiselectDropdown
                placeholder={strings.state}
                name={"isActiveFilter"}
                onItemSelect={onChangeActiveState}
                className={`${styles.filter} ${styles.narrow}`}
                state={filters.isActive}
                withLabel={strings.enumActive}
                withoutLabel={strings.enumInactive}
            />
        </FilterBar>
    );
}
