/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum HappeningOrderBy {
    Default = "Default",
    Name = "Name",
    Date = "Date",
    ProjectLeader = "ProjectLeader",
    HappeningGroup = "HappeningGroup",
    Location = "Location",
    Participants = "Participants",
    State = "State",
    Distance = "Distance",
    HappeningOpenedOn = "HappeningOpenedOn",
    Random = "Random",
}

export function HappeningOrderByFromJSON(json: any): HappeningOrderBy {
    return HappeningOrderByFromJSONTyped(json, false);
}

export function HappeningOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): HappeningOrderBy {
    return json as HappeningOrderBy;
}

export function HappeningOrderByToJSON(value?: HappeningOrderBy | null): any {
    return value as any;
}
