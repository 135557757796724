import { useContext } from "react";
import { RegistrationRulesContext } from "../../../contexts/RegistrationRulesContext";
import { RegistrationRulesTable } from "./RegistrationRulesTable";

export default function RegistrationRules() {
    const { registrationRules, refresh } = useContext(RegistrationRulesContext);

    return (
        <div>
            <RegistrationRulesTable registrationRules={registrationRules} getRegistrationRules={refresh} />
        </div>
    );
}
