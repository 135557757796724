import { useEffect, useMemo } from "react";
import { ErrorOption } from "react-hook-form";
import styles from "./AccountForm.module.scss";
import { useCreateUserCallback } from "../../../hooks/UserHooks";
import { CustomErrorTypes } from "../../core/Form/FormFieldContainer/FormFieldContainer";
import strings from "../../../localization/strings";
import { RequestState } from "../../../hooks/UseApiCall";
import CreateAccount from "./CreateAccountForm";
import { useGetArrangements } from "../../../hooks/ArrangementHooks";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import InlineLoading from "../../core/InlineLoading/InlineLoading";

export default function CreateAccountContainer(props: { onDismiss: () => void }) {
    const [{ state, error }, createUser] = useCreateUserCallback();
    const globalError = useMemo<Response | undefined>(
        () => (!!error && error.status !== 409 ? error : undefined),
        [error],
    );
    const emailError = useMemo<ErrorOption | undefined>(
        () =>
            error?.status === 409
                ? { type: CustomErrorTypes.EmailConflict.valueOf(), message: strings.emailAlreadyInUse }
                : undefined,
        [error],
    );
    const arrangementRequest = useGetArrangements();

    useEffect(() => {
        if (state === RequestState.DONE) {
            props.onDismiss();
        }
    }, [state, props]);

    if (arrangementRequest.state === RequestState.ERROR) {
        return <ErrorMessage error={arrangementRequest.error} isToast />;
    }

    if (arrangementRequest.state !== RequestState.DONE) {
        return <InlineLoading className={styles.loading} />;
    }

    return (
        <CreateAccount
            emailError={emailError}
            globalError={globalError}
            onDismiss={props.onDismiss}
            onSubmit={createUser}
            isSubmitting={state === RequestState.LOADING}
            arrangements={arrangementRequest.value}
        />
    );
}
