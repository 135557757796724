import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar, ChartProps } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.defaults.font.family = "Inter";
ChartJS.defaults.font.size = 16;
ChartJS.defaults.color = "#262424";

type Props = Pick<ChartProps<"bar">, "data" | "options">;

export default function BarChart(props: Props) {
    return <Bar {...props} />;
}
