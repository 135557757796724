import { Buffer } from "buffer";
import fileDownload from "js-file-download";
import { useCallback, useContext } from "react";
import { HappeningsApiContext } from "../contexts/HappeningsApiContext";
import { Profile } from "../contexts/ProfileContext";
import {
    CancelHappeningRequest,
    CreateHappeningRequest,
    FilterInclusion,
    GetHappeningsRequest,
    HappeningListItemViewModelPaginatedViewModel,
    HappeningOrderBy,
    HappeningState,
    HappeningTimeAndDateInput,
    LocationInputModel,
    SortOrder,
    TagViewModel,
    UsagePurpose,
    UserSubset,
} from "../openapi/backend";
import { HappeningStateOption, HappeningSubsetOption } from "../types/DropdownOption";
import { QuestionFieldValue } from "../types/FormInputTypes";
import { HappeningSubset } from "../types/HappeningSubset";
import { UserNamedType } from "../types/UserNamedType";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, useApiCall, useApiCallback } from "./UseApiCall";

function mapTargetGroup(target: HappeningSubset): UserSubset[] {
    switch (target) {
        case HappeningSubset.Participant:
            return [UserSubset.Participants];
        case HappeningSubset.NotVisible:
            return [UserSubset.NotVisibleForParticipation];
        case HappeningSubset.Internal:
            return [
                UserSubset.AllOrganisations,
                UserSubset.InternalOrganisations,
                UserSubset.PartnerOrganisations,
                UserSubset.SelectedOrganisations,
            ];
        default:
            return [];
    }
}
export type HappeningFilter = Pick<GetHappeningsRequest, "query" | "fromDate" | "toDate" | "projectMemberId"> & {
    groups?: TagViewModel[];
    states?: HappeningStateOption[];
    projectLeaders?: UserNamedType[];
    happeningTypes?: TagViewModel[];
    page?: number;
    happeningSubsets?: HappeningSubsetOption[];
};
export type GetHappeningsFun = (
    orderBy: HappeningOrderBy,
    sortOrder: SortOrder,
    itemsPerPage: number,
    filters?: HappeningFilter,
) => void;

function mapHappeningSubsetFilters(happeningSubsets: HappeningSubset[]): {
    targetGroups: UserSubset[];
    isAccessible: FilterInclusion;
    isClustered: FilterInclusion;
    isOrganizedByPartner: FilterInclusion;
    privateHappenings: FilterInclusion;
    externalHappenings: FilterInclusion;
} {
    const isPrivate = happeningSubsets.includes(HappeningSubset.Private);
    const isPublic = happeningSubsets.includes(HappeningSubset.Public);

    return {
        targetGroups: happeningSubsets.flatMap(mapTargetGroup),
        isAccessible: happeningSubsets.includes(HappeningSubset.Accessible)
            ? FilterInclusion.With
            : FilterInclusion.Both,
        isClustered: happeningSubsets.includes(HappeningSubset.Clustered) ? FilterInclusion.With : FilterInclusion.Both,
        isOrganizedByPartner: happeningSubsets.includes(HappeningSubset.OrganizedByPartner)
            ? FilterInclusion.With
            : FilterInclusion.Both,
        privateHappenings:
            isPrivate && !isPublic
                ? FilterInclusion.With
                : isPublic && !isPrivate
                ? FilterInclusion.With
                : FilterInclusion.Both,
        externalHappenings: happeningSubsets.includes(HappeningSubset.External)
            ? FilterInclusion.With
            : FilterInclusion.Both,
    };
}
export const useHappeningsOverview = (): [
    ApiCallState<HappeningListItemViewModelPaginatedViewModel>,
    GetHappeningsFun,
    () => void,
] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, filter = {}) => {
            const allowedStates = [HappeningState.Concept, HappeningState.Open];
            const transformedFilter = {
                ...filter,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                states: allowedStates.filter(
                    (state) =>
                        filter?.states?.length === 0 ||
                        filter?.states?.map((s: HappeningStateOption) => s.value).includes(state),
                ),
                projectLeaders: filter?.projectLeaders?.map((leader: Profile) => leader.id),
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                ...mapHappeningSubsetFilters(
                    (filter.happeningSubsets || []).map((o: HappeningSubsetOption) => o.value),
                ),
            };

            return api.getHappenings({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                itemsPerPage,
                itemsToSkip: 0,
                ...transformedFilter,
                purpose: UsagePurpose.Management,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const usePartnerSuggestionsOverview = (): [
    ApiCallState<HappeningListItemViewModelPaginatedViewModel>,
    GetHappeningsFun,
    () => void,
] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, filter = {}) => {
            const allowedStates = [HappeningState.PartnerSuggestion, HappeningState.Rejected, HappeningState.Concept];
            const transformedFilter = {
                ...filter,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                states: allowedStates.filter(
                    (state) =>
                        filter?.states?.length === 0 ||
                        filter?.states?.map((s: HappeningStateOption) => s.value).includes(state),
                ),
                projectLeaders: filter?.projectLeaders?.map((leader: Profile) => leader.id),
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                ...mapHappeningSubsetFilters(
                    (filter.happeningSubsets || []).map((o: HappeningSubsetOption) => o.value),
                ),
            };

            return api.getHappenings({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                itemsPerPage,
                itemsToSkip: 0,
                ...transformedFilter,
                purpose: UsagePurpose.Management,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useMyHappeningsOverview = (): [
    ApiCallState<HappeningListItemViewModelPaginatedViewModel>,
    GetHappeningsFun,
    () => void,
] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, filter = {}) => {
            const transformedFilter = {
                ...filter,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                states: filter?.states?.map((state: HappeningStateOption) => state.value),
                projectLeaders: filter?.projectLeaders?.map((leader: Profile) => leader.id),
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                ...mapHappeningSubsetFilters(
                    (filter.happeningSubsets || []).map((o: HappeningSubsetOption) => o.value),
                ),
            };

            return api.getMyHappenings({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                itemsPerPage,
                itemsToSkip: 0,
                ...transformedFilter,
                purpose: UsagePurpose.Management,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useCancelledHappeningsOverview = (): [
    ApiCallState<HappeningListItemViewModelPaginatedViewModel>,
    GetHappeningsFun,
    () => void,
] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, filter = {}) => {
            const transformedFilter = {
                ...filter,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                projectLeaders: filter?.projectLeaders?.map((leader: Profile) => leader.id),
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                ...mapHappeningSubsetFilters(
                    (filter.happeningSubsets || []).map((o: HappeningSubsetOption) => o.value),
                ),
            };

            return api.getHappenings({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                itemsPerPage,
                itemsToSkip: 0,
                ...transformedFilter,
                states: [HappeningState.Cancelled],
                purpose: UsagePurpose.Management,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useInternalHappeningsOverview = (): [
    ApiCallState<HappeningListItemViewModelPaginatedViewModel>,
    GetHappeningsFun,
    () => void,
] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, filter = {}) => {
            const allowedStates = [HappeningState.Concept, HappeningState.Open];
            const transformedFilter = {
                ...filter,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                states: allowedStates.filter(
                    (state) =>
                        filter?.states?.length === 0 ||
                        filter?.states?.map((s: HappeningStateOption) => s.value).includes(state),
                ),
                projectLeaders: filter?.projectLeaders?.map((leader: Profile) => leader.id),
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                ...mapHappeningSubsetFilters(
                    (filter.happeningSubsets || []).map((o: HappeningSubsetOption) => o.value),
                ),
            };

            return api.getHappenings({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                itemsPerPage,
                itemsToSkip: 0,
                ...transformedFilter,
                isInternal: FilterInclusion.With,
                purpose: UsagePurpose.Management,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useArchivedHappeningsOverview = (): [
    ApiCallState<HappeningListItemViewModelPaginatedViewModel>,
    GetHappeningsFun,
    () => void,
] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, filter = {}) => {
            const transformedFilter = {
                ...filter,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                projectLeaders: filter?.projectLeaders?.map((leader: Profile) => leader.id),
                happeningTypes: filter?.happeningTypes?.map((type: TagViewModel) => type.id),
                ...mapHappeningSubsetFilters(
                    (filter.happeningSubsets || []).map((o: HappeningSubsetOption) => o.value),
                ),
            };

            return api.getHappenings({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                itemsPerPage,
                itemsToSkip: 0,
                ...transformedFilter,
                states: [HappeningState.Archived],
                purpose: UsagePurpose.Management,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useSearchHappeningsOverview = () => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (itemsPerPage: number, filter: HappeningFilter) => {
            return api.getHappenings({
                ...filter,
                version: REACT_APP_BACKEND_VERSION,
                orderBy: HappeningOrderBy.Date,
                sortOrder: SortOrder.Ascending,
                itemsPerPage,
                itemsToSkip: 0,
                full: FilterInclusion.Without,
                groups: filter?.groups?.map((g: TagViewModel) => g.id),
                states: [HappeningState.Open],
                projectLeaders: undefined,
                happeningTypes: undefined,
                targetGroups: undefined,
                privateHappenings: FilterInclusion.Without,
                isInternal: FilterInclusion.Without,
                isAccessible: FilterInclusion.Both,
                isClustered: FilterInclusion.Both,
                isOrganizedByPartner: FilterInclusion.Both,
                externalHappenings: FilterInclusion.Without,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export type HappeningInput = Omit<CreateHappeningRequest, "version" | "tags" | "location" | "extraQuestions"> & {
    happeningGroup: string;
    happeningTypes: string[];
    partners: string[];
    internalTags: string[];
    areas: Omit<TagViewModel, "childTags">[];
    locationAddress?: string;
    locationName?: string;
    locationLongitude?: number;
    locationLatitude?: number;
    displayState?: HappeningState;
    extraQuestions: QuestionFieldValue[];
};

export type UpdateHappeningInput = HappeningInput & {
    id: string;
    restrictRegistrationsDate: boolean;
    restrictRegistrationsSchools: boolean;
    restrictRegistrationsAreas: boolean;
    hasExtraQuestions: boolean;
};

export const useCreateHappeningCallback = () => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (input: HappeningInput) => {
            const maxNumberOfRegistrations =
                input.maxNumberOfRegistrations === undefined || isNaN(input.maxNumberOfRegistrations)
                    ? undefined
                    : input.maxNumberOfRegistrations;
            const minAge = input.minAge === undefined || isNaN(input.minAge) ? undefined : input.minAge;
            const maxAge = input.maxAge === undefined || isNaN(input.maxAge) ? undefined : input.maxAge;

            const timeAndDates = input.timeAndDates && filterDuplicateDates(input.timeAndDates);
            const videos = input.videos?.filter((video) => video.href !== "");
            const images = input.images; // @TODO(SBG-2641) fix this typing/call
            const tags = [
                ...input.happeningTypes,
                ...(input.partners ?? []),
                ...(input.areas ?? []).map((tag) => tag.id),
                ...(input.internalTags ?? []),
            ];
            const organizer = input.happeningGroup;

            const location: LocationInputModel[] =
                input.locationName &&
                input.locationAddress &&
                input.locationLongitude !== undefined &&
                input.locationLongitude !== undefined
                    ? [
                          {
                              name: input.locationName,
                              address: input.locationAddress,
                              longitude: input.locationLongitude,
                              latitude: input.locationLatitude,
                          },
                      ]
                    : [];

            return api.createHappening({
                ...input,
                organizer,
                tags,
                videos,
                maxNumberOfRegistrations,
                minAge,
                maxAge,
                images,
                timeAndDates,
                location,
                version: REACT_APP_BACKEND_VERSION,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useUpdateHappeningCallback = () => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (input: UpdateHappeningInput) => {
            const maxNumberOfRegistrations =
                input.maxNumberOfRegistrations === undefined || isNaN(input.maxNumberOfRegistrations)
                    ? undefined
                    : input.maxNumberOfRegistrations;
            const minAge = input.minAge === undefined || isNaN(input.minAge) ? undefined : input.minAge;
            const maxAge = input.maxAge === undefined || isNaN(input.maxAge) ? undefined : input.maxAge;

            const timeAndDates = input.timeAndDates && filterDuplicateDates(input.timeAndDates);
            const videos = input.videos?.filter((video) => video.href !== "");
            const images = input.images; // @TODO(SBG-2641) fix this typing/call
            const tags = [
                ...input.happeningTypes,
                ...input.partners,
                ...input.areas.map((tag) => tag.id),
                ...input.internalTags,
            ];
            const organizer = input.happeningGroup;

            const location: LocationInputModel[] =
                input.locationName &&
                input.locationAddress &&
                input.locationLongitude !== undefined &&
                input.locationLongitude !== undefined
                    ? [
                          {
                              name: input.locationName,
                              address: input.locationAddress,
                              longitude: input.locationLongitude,
                              latitude: input.locationLatitude,
                          },
                      ]
                    : [];
            return api.updateHappening({
                ...input,
                id: input.id,
                organizer,
                tags,
                videos,
                images,
                maxNumberOfRegistrations,
                minAge,
                maxAge,
                timeAndDates,
                location,
                version: REACT_APP_BACKEND_VERSION,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useHappeningDetails = (id: string) => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(() => api.getHappening({ version: REACT_APP_BACKEND_VERSION, id }), [api, id]);
    return useApiCall(callback);
};

export const useGetHappeningDetails = (id: string) => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(() => api.getHappening({ version: REACT_APP_BACKEND_VERSION, id }), [api, id]);
    return useApiCallback(callback);
};

export const useHappeningDelete = (happeningId: string = "") => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        () => api.deleteHappening({ version: REACT_APP_BACKEND_VERSION, id: happeningId }),
        [api, happeningId],
    );
    return useApiCallback(callback);
};

export const useHappeningArchive = (happeningId: string = "") => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        () => api.archiveHappening({ version: REACT_APP_BACKEND_VERSION, id: happeningId }),
        [api, happeningId],
    );
    return useApiCallback(callback);
};

export const useHappeningPublish = (happeningId: string = "") => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        () => api.openHappening({ version: REACT_APP_BACKEND_VERSION, id: happeningId }),
        [api, happeningId],
    );
    return useApiCallback(callback);
};

export const useRejectSuggestion = (happeningId: string) => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (reason: string) => api.rejectHappening({ version: REACT_APP_BACKEND_VERSION, reason, id: happeningId }),
        [api, happeningId],
    );
    return useApiCallback(callback);
};

export type CancelHappeningInput = Omit<CancelHappeningRequest, "version">;
export const useHappeningCancel = (): [ApiCallState<string>, (input: CancelHappeningInput) => void, () => void] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (input: CancelHappeningInput) => api.cancelHappening({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );
    return useApiCallback(callback);
};

export type DownloadHappeningParticipantsOutput = {
    contentType: string;
    fileDownloadName: string;
    // Base64 string
    fileContents: string;
};
export const useDownloadHappeningParticipants = (): [ApiCallState<void>, (id: string) => void, () => void] => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        async (id) => {
            const fileData = (await api.downloadHappeningParticipants({
                id,
                version: REACT_APP_BACKEND_VERSION,
            })) as DownloadHappeningParticipantsOutput;

            let contentBuffer = Buffer.from(fileData.fileContents, "base64");
            fileDownload(contentBuffer, fileData.fileDownloadName, fileData.contentType);
        },
        [api],
    );
    return useApiCallback(callback);
};

const filterDuplicateDates = (dates: HappeningTimeAndDateInput[]): HappeningTimeAndDateInput[] => {
    return dates.filter(
        (item, index) =>
            index === 0 || // Always allow the first
            !dates?.some(
                (e, idx) =>
                    item.sessionStart?.toISOString() === e.sessionStart?.toISOString() &&
                    index !== idx &&
                    item.sessionEnd?.toISOString() === e.sessionEnd?.toISOString(),
            ),
    );
};

export const useMoveRegistrationToTeam = () => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (happeningId: string, teamId: string, registrationId: string) =>
            api.moveRegistrationToTeam({
                version: REACT_APP_BACKEND_VERSION,
                happeningId: happeningId,
                teamId: teamId,
                registrationId: registrationId,
            }),
        [api],
    );
    return useApiCallback(callback);
};

export const useCreateGroup = (happeningId: string) => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (name: string) =>
            api.createTeam({
                version: REACT_APP_BACKEND_VERSION,
                happeningId: happeningId,
                body: name,
            }),
        [api, happeningId],
    );
    return useApiCallback(callback);
};

export const useDeleteGroup = () => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (teamId: string, happeningId: string) =>
            api.deleteTeam({
                version: REACT_APP_BACKEND_VERSION,
                happeningId,
                teamId,
            }),
        [api],
    );
    return useApiCallback(callback);
};

export const useHappeningsAutocompleteSuggestions = () => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (query: string) => {
            return api
                .getHappenings({
                    version: REACT_APP_BACKEND_VERSION,
                    orderBy: HappeningOrderBy.Default,
                    itemsPerPage: 10,
                    itemsToSkip: 0,
                    states: [HappeningState.Concept, HappeningState.Open, HappeningState.PlannedForPublication],
                    purpose: UsagePurpose.Any,
                    query,
                })
                .then((response) => response.items);
        },
        [api],
    );
    return useApiCallback(callback);
};
