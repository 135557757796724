import React, { useCallback, useMemo } from "react";
import MultiselectDropdown from "../MultiselectDropdown/MultiselectDropdown";
import { FilterInclusion } from "../../../../openapi/backend";
import FilterInclusionLabel from "../../FilterInclusionLabel/FilterInclusionLabel";

export type FilterInclusionOption = {
    name: string;
    value: FilterInclusion;
};

interface FilterInclusionMultiselectDropdownProps {
    placeholder: string;
    name: string;
    className?: string;
    state?: FilterInclusion;
    onChange?: (value: FilterInclusionOption[]) => void;
    onItemSelect?: (value: FilterInclusionOption[]) => void;
    withLabel: string;
    withoutLabel: string;
    withStyle?: string;
    withoutStyle?: string;
}

export function getFilterValueFromOption(options: FilterInclusionOption[]) {
    if (options.length === 0) {
        return undefined;
    } else if (options.length === 1) {
        return options[0].value;
    } else {
        return FilterInclusion.Both;
    }
}

export function getOptionValueFromFilter(filterValue: FilterInclusion | undefined) {
    switch (filterValue) {
        case FilterInclusion.With:
        case FilterInclusion.Without:
            return [filterValue];
        case FilterInclusion.Both:
            return [FilterInclusion.With, FilterInclusion.Without];
        default:
            return [];
    }
}

export default function FilterInclusionMultiselectDropdown({
    placeholder,
    name,
    className,
    state,
    onChange,
    onItemSelect,
    withLabel,
    withoutLabel,
    withStyle,
    withoutStyle,
}: FilterInclusionMultiselectDropdownProps) {
    const renderStateOption = useCallback(
        (option: FilterInclusionOption) => {
            switch (option.value) {
                case FilterInclusion.With:
                    return (
                        <div>
                            <FilterInclusionLabel
                                value={true}
                                withLabel={withLabel}
                                withoutLabel={withoutLabel}
                                withStyle={withStyle}
                                withoutStyle={withoutStyle}
                            />
                        </div>
                    );
                case FilterInclusion.Without:
                    return (
                        <div>
                            <FilterInclusionLabel
                                value={false}
                                withLabel={withLabel}
                                withoutLabel={withoutLabel}
                                withStyle={withStyle}
                                withoutStyle={withoutStyle}
                            />
                        </div>
                    );
                default:
                    return null;
            }
        },
        [withLabel, withoutLabel, withStyle, withoutStyle],
    );

    const filterInclusionOptions = useMemo(
        () => [
            {
                name: withLabel,
                value: FilterInclusion.With,
            },
            {
                name: withoutLabel,
                value: FilterInclusion.Without,
            },
        ],
        [withLabel, withoutLabel],
    );

    const stateValue = useMemo(
        () => getOptionValueFromFilter(state).map((val) => filterInclusionOptions.find((o) => o.value === val)!),
        [state, filterInclusionOptions],
    );

    return (
        <MultiselectDropdown
            options={filterInclusionOptions}
            placeholder={placeholder}
            name={name}
            className={className}
            onChange={onChange}
            onItemSelect={onItemSelect}
            value={stateValue}
            renderOption={renderStateOption}
        />
    );
}
