import { useEffect, useState } from "react";
import { ArrayPath, Control, FieldError, useFieldArray, UseFormRegister } from "react-hook-form";
import { basicUrl } from "../../../../constants/Validation";
import strings from "../../../../localization/strings";
import { hasValues } from "../../../../utils.ts/Array";
import { QuickAddButton, RemoveButton } from "../../RoundButton/RoundButton";
import FormField from "../FormField/FormField";
import styles from "./FormExternalReferencesInput.module.scss";

export interface Props<T> {
    name: ArrayPath<T>;
    register: UseFormRegister<T>;
    control: Control<T>;
    errors?: { name?: FieldError; url?: FieldError }[];
}

export default function FormExternalReferencesListInput<T>({ name, register, control, errors, ...props }: Props<T>) {
    const { fields: referenceList, append, remove, prepend, update } = useFieldArray<any>({ control, name });

    const [nameValue, setNameValue] = useState("");
    const [hrefValue, setHrefValue] = useState("");
    const [nameError, setNameError] = useState(undefined as any);
    const [hrefError, setHrefError] = useState(undefined as any);

    useEffect(() => {
        if (!hrefValue.match(basicUrl) && hrefValue.length > 0) {
            setHrefError({ type: "pattern", message: strings.basicUrlError });
        } else {
            setHrefError(undefined);
        }
    }, [hrefValue]);

    useEffect(() => {
        if (nameValue.length === 0 && hrefValue.length > 0) {
            setNameError({ type: "required", message: strings.mandatoryField });
        } else {
            setNameError(undefined);
        }
    }, [nameValue, hrefValue]);

    useEffect(() => {
        if (hasValues(referenceList)) {
            return;
        }

        // Add first empty element for input
        prepend({ name: "", url: "" }, { shouldFocus: false });
    }, [prepend, referenceList]);

    return (
        <div className={styles.container}>
            {referenceList.map((reference, index) => {
                return (
                    <div className={styles.row} key={reference.id}>
                        <div className={styles.input}>
                            <FormField
                                key={reference.id}
                                name={`${name}.${index}.name`}
                                register={register}
                                errors={(index === 0 && nameError) || (errors && errors[index] && errors[index].name)}
                                required={hrefValue.length > 0 ? true : false}
                                placeholder={""}
                                onChange={(event) => index === 0 && setNameValue(event.target.value)}
                                {...props}
                            />
                        </div>
                        <div className={styles.input}>
                            <FormField
                                key={reference.id}
                                name={`${name}.${index}.url`}
                                register={register}
                                errors={(index === 0 && hrefError) || (errors && errors[index] && errors[index].url)}
                                required={index === 0 ? false : true}
                                placeholder={""}
                                validationPattern={{
                                    value: basicUrl,
                                    message: strings.basicUrlError,
                                }}
                                onChange={(event) => index === 0 && setHrefValue(event.target.value)}
                                {...props}
                            />
                        </div>
                        {index === 0 ? (
                            <QuickAddButton
                                disabled={nameError || hrefError || nameValue.length === 0 || hrefValue.length === 0}
                                onClick={() => {
                                    if (!nameError && !hrefError) {
                                        append({ name: nameValue, url: hrefValue });
                                        setNameValue("");
                                        setHrefValue("");
                                        update(0, { name: "", url: "" });
                                    }
                                }}
                                className={styles.button}
                            />
                        ) : (
                            <RemoveButton onClick={() => remove(index)} className={styles.button} />
                        )}
                    </div>
                );
            })}
        </div>
    );
}
