import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldError, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { canViewMonitoringStatistics } from "../../../authentication/Permissions";
import { MAX_TITLE_LENGTH } from "../../../constants/Validation";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { TagsContext } from "../../../contexts/TagsContext";
import { ApiCallState, ApiFunction, RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { IndicatorInputModel } from "../../../models/IndicatorModels";
import { IndicatorStatus, LinkTarget, MeasurementStatisticOutput } from "../../../openapi/backend";
import { Operation } from "../../../types/Operation";
import { hasValues } from "../../../utils.ts/Array";
import { getHappeningGroupOptions } from "../../../utils.ts/GetHappeningGroupOptions";
import { getStringForLinkTarget } from "../../../utils.ts/GetLinkTargetString";
import { getName } from "../../../utils.ts/GetName";
import { isActiveIndicatorState } from "../../../utils.ts/IndicatorUtils";
import { getLinkTargetOptions } from "../../../utils.ts/StateOptions";
import Accordion from "../../core/Accordion/Accordion";
import { HorizontalBarChart } from "../../core/charts/BarChart/HorizontalBarChart";
import { CrossButton } from "../../core/CrossButton/CrossButton";
import Form from "../../core/Form/Form/Form";
import FormCheckbox from "../../core/Form/FormCheckbox/FormCheckbox";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import LeaderDataListInput from "../../core/Form/FormDataList/LeaderDataListInput";
import FormDatepicker from "../../core/Form/FormDatePicker/FormDatePicker";
import FormField from "../../core/Form/FormField/FormField";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import FormHappeningInput from "../../core/Form/FormHappeningInput/FormHappeningInput";
import MemberDataListInput from "../../core/Form/FormMultiDataListInput/MemberDataListInput";
import FormQuestionBuilderField from "../../core/Form/FormQuestionBuilderField/FormQuestionBuilderField";
import { FormBooleanSelect, FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import StateLabel from "../../core/StateLabel/StateLabel";
import TextButton from "../../core/TextButton/TextButton";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import FlexRow from "../../Layouts/FlexRow/FlexRow";
import CancelModal from "../Happening/CancelModal";
import styles from "./IndicatorForm.module.scss";
import { conceptValidationSchema, validationSchema } from "./IndicatorValidation";
import moment from "moment";

interface Props {
    saveCallback: [ApiCallState<any>, ApiFunction<any, [data: IndicatorInputModel]>, () => void];
    defaultValues?: Partial<IndicatorInputModel>;
    statistics?: MeasurementStatisticOutput[];
    pageTitle?: string;
    readOnly?: boolean;
}

function prepareForSubmission(data: IndicatorInputModel) {
    // Do any kind of modifications to the data before submitting to the API
    let modifications: Partial<IndicatorInputModel> = {
        endDate: moment(data.endDate).endOf("day").toDate(),
    };

    return { ...data, ...modifications };
}

const emptyOptions = [] as any[];

const validationResolver = (data: Partial<IndicatorInputModel>, context: any, options: any) => {
    let schema;
    switch (true) {
        case data.status === IndicatorStatus.Concept:
            schema = conceptValidationSchema;
            break;
        default:
            schema = validationSchema;
            break;
    }

    return yupResolver(schema)(data, context, options);
};

const linkTargetOptions = getLinkTargetOptions();

export default function IndicatorForm({
    saveCallback,
    defaultValues,
    statistics,
    pageTitle = strings.indicatorFormTitleNew,
    readOnly,
}: Props) {
    const { profile: user } = useContext(ProfileContext);
    const tags = useContext(TagsContext).activeTags;
    const [cancelFormOpen, setCancelFormOpen] = useState(false);
    const happeningGroupOptions = useMemo(
        () =>
            user
                ? getHappeningGroupOptions(Operation.UPDATE, user, tags).map((t) => ({
                      label: t.detailedName,
                      value: t.id,
                  }))
                : emptyOptions,
        [tags, user],
    );

    const localizedLinkTargetOptions = useMemo(
        () => linkTargetOptions.map((o) => ({ value: o.value, label: getStringForLinkTarget(o.value) })),
        [],
    );

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setFocus,
        watch,
        setValue,
    } = useForm<IndicatorInputModel>({
        defaultValues: {
            linkTarget: LinkTarget.None,
            happeningInput: { query: "", value: undefined },
            canAddCollaborators: true,
            canAddContributors: true,
            ...defaultValues,
        },
        resolver: validationResolver as any,
    });

    // entityState is set as the starting value and not the form value because the form value switches back and forth due to the buttons
    const entityState = defaultValues?.status ?? IndicatorStatus.Concept;

    const organisation = watch("organisationId");
    const organizer = watch("organizerId");
    const startDate = watch("startDate");
    const endDate = watch("endDate");
    const includeNotes = watch("includeNotes");

    const [{ state, error }, save] = saveCallback;
    const navigate = useNavigate();

    const preparedSave = useCallback<typeof save>((data) => save(prepareForSubmission(data)), [save]);
    const goBack = useCallback(() => navigate(-1), [navigate]);

    useEffect(() => {
        if (state === RequestState.DONE) {
            goBack();
        }
    }, [state, goBack]);

    useEffect(() => {
        setFocus("name");
    }, [setFocus]);

    const onSaveConcept = useCallback(() => {
        setValue("status", IndicatorStatus.Concept);
        handleSubmit(preparedSave as any)();
    }, [setValue, handleSubmit, preparedSave]);

    const onPublish = useCallback(() => {
        if (entityState === IndicatorStatus.Concept) {
            setValue("status", IndicatorStatus.Open);
        }
        handleSubmit(preparedSave as any)();
    }, [preparedSave, handleSubmit, setValue, entityState]);

    const getSecondaryButtons = useMemo(() => {
        switch (true) {
            case entityState === IndicatorStatus.Concept:
                return (
                    <TextButton
                        onClick={onSaveConcept}
                        text={strings.saveConcept}
                        buttonType="alternate"
                        isLoading={state === RequestState.LOADING}
                    />
                );
            default:
                return undefined;
        }
    }, [state, entityState, onSaveConcept]);

    const { measurementLabels, measurementData } = useMemo(() => {
        if (!statistics) {
            return { measurementLabels: [], measurementData: [] };
        }

        return {
            measurementLabels: statistics.map((s) => getName(s.measurer)),
            measurementData: statistics.map((s) => s.count),
        };
    }, [statistics]);

    return (
        <FormPageLayout
            title={pageTitle}
            actionButtons={
                <div className={styles.titleActionButtons}>
                    <CrossButton
                        onClick={() => {
                            if (readOnly) {
                                goBack();
                                return;
                            }

                            setCancelFormOpen(true);
                        }}
                        alt={strings.cancel}
                        className={styles.closeButton}
                    />
                </div>
            }
        >
            <Form
                className={styles.form}
                onSubmit={onPublish}
                handleSubmit={handleSubmit}
                secondaryButtons={getSecondaryButtons}
                error={error}
                submitText={entityState === IndicatorStatus.Concept ? strings.publish : strings.save}
                waitingForSubmit={state === RequestState.LOADING}
                onDismiss={() => setCancelFormOpen(true)}
                readOnly={readOnly}
            >
                <Accordion
                    className={styles.formSection}
                    title={strings.details}
                    collapsible={true}
                    open
                    headerContent={
                        <div className={styles.headerContent}>
                            <StateLabel state={entityState} />
                        </div>
                    }
                >
                    <FormField
                        name={"name"}
                        label={strings.formIndicatorFieldName}
                        register={register}
                        errors={errors.name}
                        required
                        placeholder={""}
                        maxLength={MAX_TITLE_LENGTH}
                        readOnly={readOnly}
                    />
                    <FormDataListInput
                        name={"organisationId"}
                        options={happeningGroupOptions}
                        label={strings.formIndicatorFieldOrganisation}
                        control={control}
                        defaultValue={organisation}
                        errors={errors.organisationId}
                        required
                        list={"organisationOptions"}
                        readOnly={readOnly}
                    />
                    <FormFieldMultiline
                        rows={4}
                        name={"description"}
                        label={strings.formIndicatorFieldDescription}
                        register={register}
                        errors={errors.description}
                        required
                        readOnly={readOnly}
                    />
                </Accordion>
                <Accordion
                    className={styles.formSection}
                    title={strings.formIndicatorHeaderLinking}
                    collapsible={true}
                    open
                >
                    <FormHappeningInput
                        name="happeningInput"
                        control={control}
                        errors={errors.happeningInput?.value}
                        required
                        label={strings.formIndicatorFieldLinkHappening}
                        className={styles.spaceBottom}
                        readOnly={readOnly}
                    />
                    <FormStringSelect
                        name="linkTarget"
                        label={strings.formIndicatorFieldLinkTarget}
                        control={control}
                        errors={errors.linkTarget}
                        required
                        options={localizedLinkTargetOptions}
                        placeholder=" "
                        readOnly={readOnly}
                    />
                </Accordion>
                <Accordion
                    className={styles.formSection}
                    title={strings.formIndicatorHeaderTeam}
                    collapsible={true}
                    open
                >
                    <FlexRow className={styles.row}>
                        <LeaderDataListInput
                            name={"organizerId"}
                            label={strings.formIndicatorFieldOrganizer}
                            control={control}
                            errors={errors.organizerId}
                            required
                            value={organizer}
                            list="organizerOptions"
                            readOnly={readOnly}
                        />
                        <MemberDataListInput
                            name={"measurers"}
                            label={strings.formIndicatorFieldTeam}
                            errors={errors.measurers}
                            control={control}
                            list="measurerOptions"
                            readOnly={readOnly}
                        />
                    </FlexRow>
                </Accordion>
                <Accordion
                    className={styles.formSection}
                    title={strings.formIndicatorHeaderCollaboration}
                    collapsible={true}
                    open
                >
                    <FormBooleanSelect
                        name="canAddContributors"
                        label={strings.formIndicatorFieldContributors}
                        control={control}
                        errors={errors.canAddContributors}
                        readOnly={readOnly}
                        options={[
                            { label: strings.yes, value: true },
                            { label: strings.no, value: false },
                        ]}
                    />
                    <FormBooleanSelect
                        name="canAddCollaborators"
                        label={strings.formIndicatorFieldCollaborators}
                        control={control}
                        errors={errors.canAddCollaborators}
                        readOnly={readOnly}
                        options={[
                            { label: strings.yes, value: true },
                            { label: strings.no, value: false },
                        ]}
                    />
                </Accordion>
                <Accordion
                    className={styles.formSection}
                    title={strings.formIndicatorHeaderDuration}
                    collapsible={true}
                    open
                >
                    <FlexRow className={styles.row}>
                        <FormDatepicker
                            name={"startDate"}
                            title={strings.formIndicatorFieldStartDate}
                            control={control}
                            value={startDate}
                            minDate={new Date()}
                            errors={errors.startDate}
                            readOnly={readOnly}
                            required
                        />
                        <FormDatepicker
                            name={"endDate"}
                            title={strings.formIndicatorFieldEndDate}
                            control={control}
                            value={endDate}
                            minDate={startDate || new Date()}
                            errors={errors.endDate}
                            readOnly={readOnly}
                            required
                        />
                    </FlexRow>
                </Accordion>
                <Accordion
                    className={styles.formSection}
                    title={strings.formIndicatorHeaderQuestionnaire}
                    collapsible={true}
                    open
                >
                    <FormQuestionBuilderField
                        control={control}
                        name="questions"
                        errors={errors.questions as unknown as FieldError}
                        readOnly={readOnly}
                    />
                    <FormCheckbox
                        name="includeNotes"
                        label={strings.formIndicatorIncludeNotes}
                        value={includeNotes}
                        control={control}
                        readOnly={readOnly}
                    />
                </Accordion>
                {canViewMonitoringStatistics(user) && isActiveIndicatorState(entityState) && (
                    <Accordion
                        className={styles.formSection}
                        title={strings.formIndicatorHeaderStatistics}
                        collapsible={true}
                        open={false}
                    >
                        {hasValues(statistics) ? (
                            <HorizontalBarChart
                                title={strings.measurementGraphTitle}
                                labels={measurementLabels}
                                data={measurementData}
                                dataLabel={strings.measurementGraphUnitLabel}
                            />
                        ) : (
                            strings.noStatisticsAvailable
                        )}
                    </Accordion>
                )}
            </Form>
            {cancelFormOpen && (
                <CancelModal isOpen={cancelFormOpen} onDismiss={() => setCancelFormOpen(false)} onSuccess={goBack} />
            )}
        </FormPageLayout>
    );
}
