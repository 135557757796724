import styles from "./Switch.module.scss";
import { default as ReactSwitch } from "react-switch";
import cx from "classnames";

interface SwitchProps {
    leftText: string;
    rightText: string;
    value: boolean;
    setValue: (value: boolean) => void;
}

export default function Switch({ value, setValue, leftText, rightText }: SwitchProps) {
    return (
        <ReactSwitch
            className={styles.switch}
            width={140}
            checked={value}
            onChange={setValue}
            checkedIcon={<div className={cx(styles.switchLabel, styles.switchLabelLeft)}>{leftText}</div>}
            uncheckedIcon={<div className={cx(styles.switchLabel, styles.switchLabelRight)}>{rightText}</div>}
            offColor={"#272450"}
            onColor={"#889AA4"}
        />
    );
}
