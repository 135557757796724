/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum Tutorial {
    CreateSubprofile = "CreateSubprofile",
}

export function TutorialFromJSON(json: any): Tutorial {
    return TutorialFromJSONTyped(json, false);
}

export function TutorialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tutorial {
    return json as Tutorial;
}

export function TutorialToJSON(value?: Tutorial | null): any {
    return value as any;
}
