import { useCallback, useState, PropsWithChildren, useMemo, useContext } from "react";
import { UpdateTagInput } from "../../hooks/TagHooks";
import { useTagSearch } from "../../hooks/useTagSearch";
import strings from "../../localization/strings";
import { EnhancedTagViewModel } from "../../models/EnhancedTagViewModel";
import { TagType, TagViewModel } from "../../openapi/backend";
import ApiKeyInput from "../core/Inputs/ApiKeyInput/ApiKeyInput";
import ColorInput from "../core/Inputs/ColorInput/ColorInput";
import IconInput from "../core/Inputs/IconInput/IconInput";
import InputField from "../core/Inputs/InputField/InputField";
import { QuickAddButton, VisibilityButton } from "../core/RoundButton/RoundButton";
import styles from "./TagManagement.module.scss";
import TagDescriptionButton from "../core/TagDescriptionButton/TagDescriptionButton";
import MultiselectDropdown from "../core/Inputs/MultiselectDropdown/MultiselectDropdown";
import { TagsContext } from "../../contexts/TagsContext";

interface TagFilterProps {
    filterValue: string;
    onChange: (name: string) => void;
    placeholder: string;
    createTag: (name: string) => void;
    filteredTags: Array<EnhancedTagViewModel>;
}

function isUniqueTag(tags: Array<EnhancedTagViewModel>, filter: string) {
    const trimmedFilter = filter.trim();
    if (trimmedFilter.length === 0) {
        return false;
    }

    return !tags.map(({ name }) => name.toLowerCase()).includes(trimmedFilter.toLowerCase());
}

const TagFilter = ({ filterValue, filteredTags, placeholder, onChange, createTag }: TagFilterProps) => {
    return (
        <InputField
            className={styles.input}
            onChange={(e) => {
                const name = e.target.value;
                onChange(name);
            }}
            value={filterValue}
            placeholder={placeholder}
            onKeyUp={(e) => {
                if (e.key === "Enter" && isUniqueTag(filteredTags, filterValue)) {
                    createTag(filterValue);
                }
            }}
        />
    );
};

interface CreateTagButtonProps {
    label: string;
    tagName: string;
    createTag: (name: string) => void;
    disabled: boolean;
}

const CreateTagButton = ({ label, tagName, createTag, disabled }: CreateTagButtonProps) => {
    return (
        <div className={styles.buttonContainer}>
            <QuickAddButton onClick={() => createTag(tagName)} label={label} disabled={disabled} />
        </div>
    );
};

interface TagViewProps {
    tag: EnhancedTagViewModel;
}

const TagView = ({ tag }: TagViewProps) => {
    return (
        <div
            className={`${styles.tag} ${!tag.isActive && styles.inactive} ${
                tag.type === TagType.Partner ? styles.compactView : ""
            }`}
        >
            {tag.name}
        </div>
    );
};

interface TagButtonsProps {
    tag: EnhancedTagViewModel;
    parentName?: string;
    toggleVisibility: (tag: TagViewModel) => void;
    changeColor: (tag: UpdateTagInput, color: string) => void;
    changeLogo: (tag: UpdateTagInput, newLogo: File) => void;
    setApiKey: (tag: UpdateTagInput, apiKey: string) => void;
    changeParents: (tag: UpdateTagInput, parents: string[] | undefined) => void;
}

function prefixParentName(value: string, parentName?: string) {
    return parentName ? `${parentName}-${value}` : value;
}

const TAG_TYPES_WITH_COLOR_ICON = [TagType.HappeningGroup, TagType.Category, TagType.Partner];
const TAG_TYPES_WITH_MOLLIE_KEY = [TagType.HappeningGroup, TagType.Partner];
const TAG_TYPES_WITH_INDUSTRIES = [TagType.Partner];

const emptyList = [] as TagViewModel[];

const TagButtons = ({
    tag,
    parentName,
    toggleVisibility,
    changeColor,
    changeLogo,
    setApiKey,
    changeParents,
    children,
}: PropsWithChildren<TagButtonsProps>) => {
    const tags = useContext(TagsContext);
    var inputTag = useMemo(() => ({ ...tag, childTags: tag.childTags?.map((x) => x.id) }), [tag]);
    const industryOptions = useMemo(() => {
        return tags.allTags.industries;
    }, [tags]);

    const selectedIndustries = useMemo(() => {
        if (!inputTag.parentTags) {
            return emptyList;
        }

        return industryOptions.filter((o) => inputTag.parentTags!.includes(o.id));
    }, [industryOptions, inputTag.parentTags]);

    const onChangeIndustries = useCallback(
        (selected: TagViewModel[]) =>
            changeParents(
                inputTag,
                selected.map((t) => t.id),
            ),
        [changeParents, inputTag],
    );

    const prefixedName = useMemo(() => prefixParentName(tag.name, parentName), [tag.name, parentName]);

    return (
        <div className={styles.buttonContainer}>
            {TAG_TYPES_WITH_INDUSTRIES.includes(tag.type) && (
                <MultiselectDropdown
                    options={industryOptions}
                    optionToKey={(option) => option.id}
                    placeholder={strings.industries}
                    name={"industriesAssociation"}
                    className={styles.dropdown}
                    onChange={onChangeIndustries}
                    value={selectedIndustries}
                    searchable
                    searchPlaceholder={strings.searchByIndustry}
                />
            )}
            <VisibilityButton
                onClick={() => toggleVisibility(tag)}
                isActive={tag.isActive}
                targetLabel={prefixedName}
            />
            {TAG_TYPES_WITH_COLOR_ICON.includes(tag.type) && (
                <>
                    <ColorInput
                        name={prefixedName + "-color"}
                        label={strings.formatString(strings.changeColorOf, prefixedName) as string}
                        defaultValue={tag.color}
                        onBlur={(e) => changeColor(inputTag, e.currentTarget.value)}
                    />
                    <IconInput
                        group={tag}
                        name={`${prefixedName}-logo`}
                        label={strings.formatString(strings.addLogo, prefixedName) as string}
                        onChange={(file) => changeLogo(inputTag, file)}
                    />
                </>
            )}
            {TAG_TYPES_WITH_MOLLIE_KEY.includes(tag.type) && (
                <ApiKeyInput
                    requestState={tag.requestState}
                    hasValue={tag.hasApiKey}
                    onSave={(value) => setApiKey(inputTag, value)}
                />
            )}
            {children}
        </div>
    );
};

interface NestedTagRowProps extends Omit<TagButtonsProps, "tag"> {
    tag: EnhancedTagViewModel;
    nested?: boolean;
    placeholder?: string;
    parentName?: string;
    hasDescription?: boolean;
    createTag?: (name: string, parentTag?: string) => void;
}

const NestedTagRow = ({
    tag,
    nested,
    placeholder,
    parentName,
    hasDescription,
    toggleVisibility,
    changeColor,
    changeLogo,
    setApiKey,
    changeParents,
    createTag,
}: NestedTagRowProps) => {
    const [showInput, setShowInput] = useState(false);

    const doShowInput = useCallback(() => setShowInput(true), []);
    const { search, setSearch, filteredTags } = useTagSearch(tag.childTags as EnhancedTagViewModel[]);
    const extendedCreateTag = useCallback(
        (name: string) => {
            createTag && createTag(name, tag.id);
            setSearch("");
        },
        [createTag, setSearch, tag.id],
    );

    return (
        <div className={styles.row} key={tag.id}>
            <div className={styles.tagRow}>
                <TagView tag={tag} />
                <TagButtons
                    tag={tag}
                    toggleVisibility={toggleVisibility}
                    changeColor={changeColor}
                    changeLogo={changeLogo}
                    setApiKey={setApiKey}
                    changeParents={changeParents}
                    parentName={parentName}
                >
                    {nested && <QuickAddButton onClick={doShowInput} disabled={showInput} />}
                </TagButtons>
            </div>
            {hasDescription && <TagDescriptionButton tag={tag} maxLength={178} />}

            {nested && (
                <div className={styles.nested}>
                    {showInput && (
                        <div className={styles.header}>
                            <TagFilter
                                filterValue={search}
                                onChange={setSearch}
                                placeholder={placeholder || ""}
                                createTag={extendedCreateTag}
                                filteredTags={filteredTags}
                            />
                            <CreateTagButton
                                label={placeholder || ""}
                                tagName={search}
                                createTag={extendedCreateTag}
                                disabled={!isUniqueTag(filteredTags, search)}
                            />
                        </div>
                    )}
                    {tag.childTags?.map((t) => (
                        <NestedTagRow
                            key={t.id}
                            tag={t as EnhancedTagViewModel}
                            toggleVisibility={toggleVisibility}
                            changeColor={changeColor}
                            changeLogo={changeLogo}
                            changeParents={changeParents}
                            setApiKey={setApiKey}
                            parentName={tag.name}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

interface Props {
    title: string;
    addLabel: string;
    tags: EnhancedTagViewModel[];
    nested?: boolean;
    nestedAddLabel?: string;
    hasDescription?: boolean;
    createTag: (name: string, parentTag?: string) => void;
    toggleVisibility: (tag: TagViewModel) => void;
    changeColor: (tag: UpdateTagInput, color: string) => void;
    changeLogo: (tag: UpdateTagInput, newLogo: File) => void;
    setApiKey: (tag: UpdateTagInput, apiKey: string) => void;
    changeParents: (tag: UpdateTagInput, parents: string[] | undefined) => void;
}

const TagManagement = ({
    title,
    tags,
    addLabel,
    nested,
    nestedAddLabel,
    hasDescription,
    createTag,
    toggleVisibility,
    changeColor,
    changeLogo,
    changeParents,
    setApiKey,
}: Props) => {
    const { search, setSearch, filteredTags } = useTagSearch(tags);
    const extendedCreateTag = useCallback(
        (name: string) => {
            createTag(name);
            setSearch("");
        },
        [createTag, setSearch],
    );

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.header}>
                <TagFilter
                    filterValue={search}
                    onChange={setSearch}
                    placeholder={addLabel}
                    createTag={extendedCreateTag}
                    filteredTags={filteredTags}
                />
                <CreateTagButton
                    label={addLabel}
                    tagName={search}
                    createTag={extendedCreateTag}
                    disabled={!isUniqueTag(filteredTags, search)}
                />
            </div>
            {filteredTags.map((tag) => (
                <NestedTagRow
                    key={tag.id}
                    tag={tag}
                    nested={nested}
                    toggleVisibility={toggleVisibility}
                    changeColor={changeColor}
                    changeLogo={changeLogo}
                    setApiKey={setApiKey}
                    createTag={createTag}
                    changeParents={changeParents}
                    placeholder={nestedAddLabel}
                    hasDescription={hasDescription}
                />
            ))}
        </div>
    );
};

export default TagManagement;
