import strings from "../localization/strings";
import { Role } from "../openapi/backend";

export function rolesToString(roles: Role[]): string {
    return roles.map(getStringForRole).join(", ");
}

export default function getStringForRole(role: Role) {
    switch (role) {
        case Role.ApplicationAdmin:
            return strings.applicationAdmin;
        case Role.ChiefEditor:
            return strings.chiefEditor;
        case Role.Editor:
            return strings.editor;
        case Role.Partner:
            return strings.partner;
        case Role.UserAdmin:
            return strings.userAdmin;
        case Role.Marketing:
            return strings.marketing;
        case Role.Approver:
            return strings.approver;
        case Role.ChiefIntermediary:
            return strings.chiefIntermediaryRole;
        case Role.Intermediary:
            return strings.intermediaryRole;
        case Role.PartnerIntermediary:
            return strings.partnerIntermediaryRole;
        case Role.Participant:
        default:
            return strings.participant;
    }
}
