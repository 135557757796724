import React from "react";
import strings from "../../../localization/strings";
import HappeningsSearchTableContainer from "../../core/HappeningsSearchTable/HappeningsSearchTableContainer";
import Modal from "../../core/Modal/Modal";

export interface Props {
    happeningId: string;
    registrationId: string;
    participantName: string;
    onDismiss: () => void;
    onSuccess: () => void;
}

export const AssignHappeningModal = ({ onDismiss, ...props }: Props) => {
    return (
        <Modal
            isOpen={true}
            onDismiss={onDismiss}
            title={strings.formatString(strings.assignToHappeningTitle, props.participantName) as string}
        >
            <HappeningsSearchTableContainer {...props} />
        </Modal>
    );
};
