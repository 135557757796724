/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ArrangementListOutput,
    ArrangementListOutputFromJSON,
    ArrangementListOutputFromJSONTyped,
    ArrangementListOutputToJSON,
    ArrangementType,
    ArrangementTypeFromJSON,
    ArrangementTypeFromJSONTyped,
    ArrangementTypeToJSON,
    FormType,
    FormTypeFromJSON,
    FormTypeFromJSONTyped,
    FormTypeToJSON,
    ImageViewModel,
    ImageViewModelFromJSON,
    ImageViewModelFromJSONTyped,
    ImageViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface ArrangementOutput
 */
export interface ArrangementOutput {
    /**
     *
     * @type {ArrangementType}
     * @memberof ArrangementOutput
     */
    type: ArrangementType;
    /**
     *
     * @type {string}
     * @memberof ArrangementOutput
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof ArrangementOutput
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ArrangementOutput
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof ArrangementOutput
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof ArrangementOutput
     */
    terms?: string;
    /**
     *
     * @type {ImageViewModel}
     * @memberof ArrangementOutput
     */
    logo: ImageViewModel;
    /**
     *
     * @type {ImageViewModel}
     * @memberof ArrangementOutput
     */
    coverImage: ImageViewModel;
    /**
     *
     * @type {string}
     * @memberof ArrangementOutput
     */
    link?: string;
    /**
     *
     * @type {FormType}
     * @memberof ArrangementOutput
     */
    form?: FormType;
    /**
     *
     * @type {Array<ArrangementListOutput>}
     * @memberof ArrangementOutput
     */
    children?: Array<ArrangementListOutput>;
}

/**
 * Check if a given object implements the ArrangementOutput interface.
 */
export function instanceOfArrangementOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "logo" in value;
    isInstance = isInstance && "coverImage" in value;

    return isInstance;
}

export function ArrangementOutputFromJSON(json: any): ArrangementOutput {
    return ArrangementOutputFromJSONTyped(json, false);
}

export function ArrangementOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArrangementOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, "type") ? (undefined as any) : ArrangementTypeFromJSON(json["type"]),
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        terms: !exists(json, "terms") ? (undefined as any) : json["terms"],
        logo: !exists(json, "logo") ? (undefined as any) : ImageViewModelFromJSON(json["logo"]),
        coverImage: !exists(json, "coverImage") ? (undefined as any) : ImageViewModelFromJSON(json["coverImage"]),
        link: !exists(json, "link") ? (undefined as any) : json["link"],
        form: !exists(json, "form") ? (undefined as any) : FormTypeFromJSON(json["form"]),
        children: !exists(json, "children")
            ? (undefined as any)
            : ((json["children"] as Array<any>) ?? []).map(ArrangementListOutputFromJSON),
    };
}

export function ArrangementOutputToJSON(value?: ArrangementOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
