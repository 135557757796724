import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCreateAppointment } from "../../../hooks/RegistrationHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { HappeningTimeAndDateInput, LocationType, LocationViewModel } from "../../../openapi/backend";
import { InitialTimeAndDates } from "../../../utils.ts/InitialTimeAndDates";
import DateTimePickerContainer from "../../core/DateTimePicker/DateTimePickerContainer";
import Form from "../../core/Form/Form/Form";
import FormAddressInput from "../../core/Form/FormAddressInput/FormAddressInput";
import Modal from "../../core/Modal/Modal";
import { FormData, LOCATION_REQUIRED_TYPES, validationSchema } from "./AppointmentValidation";
import styles from "./CreateAppointmentModal.module.scss";
import { ProfileContext } from "../../../contexts/ProfileContext";
import LeaderDataListInput from "../../core/Form/FormDataList/LeaderDataListInput";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";

type FormInput = FormData;

interface ContainerProps {
    onDismiss: () => void;
    onSuccess: () => void;
    registrationId: string;
    happeningId: string;
    locationType: LocationType;
    location?: LocationViewModel;
}

export interface CreateAppointmentModalProps {
    onSubmit: (input: FormInput) => void;
    onDismiss: () => void;
    error?: Response;
    isSubmitting: boolean;
    happeningId: string;
    locationType: LocationType;
    location?: LocationViewModel;
}

const CreateAppointmentModalContainer = ({
    onDismiss,
    onSuccess,
    registrationId,
    happeningId,
    locationType,
    location,
}: ContainerProps) => {
    const [{ state, error }, create] = useCreateAppointment(registrationId);
    const onSubmit = useCallback(
        (input: FormInput) =>
            create({
                ...input,
                sessions: input.timeAndDates,
            }),
        [create],
    );

    useEffect(() => {
        if (state === RequestState.DONE) {
            onDismiss();
            onSuccess();
        }
    }, [state, onDismiss, onSuccess]);

    return (
        <CreateAppointmentModal
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            error={error}
            isSubmitting={state === RequestState.LOADING}
            happeningId={happeningId}
            locationType={locationType}
            location={location}
        />
    );
};

const CreateAppointmentModal = ({
    onSubmit,
    onDismiss,
    error,
    isSubmitting,
    happeningId,
    locationType,
    location,
}: CreateAppointmentModalProps) => {
    const { profile } = useContext(ProfileContext);
    const form = useForm<FormInput>({
        defaultValues: {
            timeAndDates: [InitialTimeAndDates()],
            locationType: locationType,
            locationName: location?.name,
            locationAddress: location?.address,
            locationLongitude: location?.longitude,
            locationLatitude: location?.latitude,
            appointer: profile?.id,
        },
        resolver: yupResolver(validationSchema),
    });
    const {
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        control,
        register,
    } = form;

    const sessionValue = watch("timeAndDates");
    const setSessionValue = useCallback(
        (dates: HappeningTimeAndDateInput[]) => {
            setValue("timeAndDates", dates);
        },
        [setValue],
    );

    return (
        <Modal isOpen={true} onDismiss={onDismiss} title={strings.addAppointment}>
            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                submitText={strings.save}
                cancelText={strings.cancel}
                waitingForSubmit={isSubmitting}
            >
                <LeaderDataListInput
                    name="appointer"
                    label={strings.appointer}
                    errors={errors.appointer}
                    control={control}
                    list="memberOptions"
                    happeningId={happeningId}
                />
                <DateTimePickerContainer
                    dateTimes={sessionValue}
                    setDateTimes={setSessionValue}
                    concept={true}
                    errors={errors.timeAndDates}
                />
                {LOCATION_REQUIRED_TYPES.includes(locationType) && (
                    <FormAddressInput
                        className={styles.location}
                        required
                        form={form}
                        defaultAddress={location?.address}
                    />
                )}
                <FormFieldMultiline
                    rows={4}
                    name="notes"
                    label={strings.notes}
                    register={register}
                    errors={errors.notes}
                />
            </Form>
        </Modal>
    );
};

export default CreateAppointmentModalContainer;
