import { OrganizerViewModel } from "../../../openapi/backend";
import { Size } from "../../../types/Size";
import AvatarIcon from "./AvatarIcon";

interface Props {
    group: OrganizerViewModel;
    size?: Size;
    className?: string;
}

const GroupIcon = ({ group, ...props }: Props) => (
    <AvatarIcon name={group.name} color={group.color} image={group.logo?.href} {...props} />
);

export default GroupIcon;
