import cx from "classnames";
import strings from "../../../../localization/strings";
import styles from "./NavigationMenu.module.scss";
import SBGLogo from "../../../../assets/logo_sbg.svg";
import SBGFullLogo from "../../../../assets/logo_full.svg";
import NavigationMenuItem from "../NavigationMenuItem/NavigationMenuItem";

import UsersIcon from "../../../../assets/users_icon.svg";
import MediaIcon from "../../../../assets/insert_photo_white.svg";
import GearIcon from "../../../../assets/gear_icon.svg";
import ApplicationsIcon from "../../../../assets/applications_icon.svg";
import MonitoringIcon from "../../../../assets/monitoring_icon.svg";
import { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../../../contexts/ProfileContext";
import {
    canAssignApplications,
    canSendGeneralNotifications,
    canViewAppSettings,
    canViewAppSettingsDefaultTabs,
    canViewApplications,
    canViewHappenings,
    canViewUsers,
    canManageMediaLibrary,
    canViewMonitoring,
} from "../../../../authentication/Permissions";
import HappeningsIcon from "../../../../assets/calendar_icon.svg";
import NotificationsIcon from "../../../../assets/notifications_icon.svg";

const activeTime = 2000;
const activationTime = 1500;

export default function NavigationMenu({ className = "" }: { className?: string }) {
    const { profile: user } = useContext(ProfileContext);
    const [isExpanded, expand] = useState(true);
    const [mouseIn, setMouseIn] = useState(false);

    useEffect(() => {
        var timeoutHandler: NodeJS.Timeout | undefined = undefined;
        if (isExpanded && !mouseIn) {
            timeoutHandler = setTimeout(() => expand(false), activeTime);
        } else if (!isExpanded && mouseIn) {
            timeoutHandler = setTimeout(() => expand(true), activationTime);
        }

        return () => timeoutHandler && clearTimeout(timeoutHandler);
    }, [mouseIn, isExpanded]);

    return (
        <nav
            className={cx(styles.menu, isExpanded && styles.expanded, className)}
            onMouseEnter={() => setMouseIn(true)}
            onMouseLeave={() => setMouseIn(false)}
        >
            <div className={styles.logoContainer}>
                <img src={isExpanded ? SBGFullLogo : SBGLogo} alt={strings.sbg} className={styles.logo} />
            </div>

            {/* Routes (pathnames) are available from App.tsx */}
            {canViewHappenings(user) && (
                <NavigationMenuItem
                    text={strings.happenings}
                    icon={HappeningsIcon}
                    pathname={"/activities/participant"}
                    showText={isExpanded}
                />
            )}
            {canViewMonitoring(user) && (
                <NavigationMenuItem
                    text={strings.monitoring}
                    icon={MonitoringIcon}
                    pathname={"/monitoring/measurements/all"}
                    showText={isExpanded}
                />
            )}
            {canViewApplications(user) && (
                <NavigationMenuItem
                    text={strings.applications}
                    icon={ApplicationsIcon}
                    pathname={canAssignApplications(user) ? "/applications/all" : "/applications/my"}
                    showText={isExpanded}
                />
            )}
            {canManageMediaLibrary(user) && (
                <NavigationMenuItem text={strings.media} icon={MediaIcon} pathname={"/media"} showText={isExpanded} />
            )}
            {canSendGeneralNotifications(user) && (
                <NavigationMenuItem
                    text={strings.marketing}
                    icon={NotificationsIcon}
                    pathname={"/notifications/send"}
                    showText={isExpanded}
                />
            )}
            {canViewUsers(user) && (
                <NavigationMenuItem
                    text={strings.userManagement}
                    icon={UsersIcon}
                    pathname={"/users/participants"}
                    showText={isExpanded}
                />
            )}
            {canViewAppSettings(user) && (
                <NavigationMenuItem
                    text={strings.functionalManagement}
                    icon={GearIcon}
                    pathname={
                        canViewAppSettingsDefaultTabs(user)
                            ? "/appsettings/happeninggroups"
                            : "/appsettings/embedded-codes"
                    }
                    showText={isExpanded}
                />
            )}
        </nav>
    );
}
