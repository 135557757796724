import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";
import CheckboxInput from "../../Inputs/CheckboxInput/CheckboxInput";

interface FormCheckboxProps<TValue, TForm extends FieldValues, TName extends FieldName<TForm, TValue>> {
    title?: string;
    label?: string;
    name: TName;
    control: Control<TForm>;
    errors?: FieldError | FieldError[];
    value?: TValue;
    readOnly?: boolean;
}

interface CheckboxFieldProps<T>
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value" | "defaultValue"> {
    name: string;
    required?: boolean;
    disabled?: boolean;
    label?: string;
    value?: T;
    defaultValue?: T;
    onChange?: (selected: T) => any;
}

export default function FormCheckbox<TValue, TForm extends FieldValues, TName extends FieldName<TForm, TValue>>({
    name,
    title,
    label,
    errors,
    control,
    ...fieldProps
}: FormCheckboxProps<TValue, TForm, TName>) {
    return (
        <FormFieldContainer name={name} label={title} errors={errors}>
            <Controller
                name={name}
                control={control}
                render={({ field: { ref, ...field } }) => {
                    return <CheckboxField label={label} {...fieldProps} {...field} />;
                }}
            />
        </FormFieldContainer>
    );
}

const CheckboxField = (props: Omit<CheckboxFieldProps<boolean>, "transform">) => <CheckboxInput {...props} />;
