import strings from "../localization/strings";
import { UserSubset } from "../openapi/backend";

export function getStringForHappeningTargetGroup(type: UserSubset) {
    switch (type) {
        case UserSubset.AllOrganisations:
            return strings.targetGroupAllOrganisations;
        case UserSubset.InternalOrganisations:
            return strings.targetGroupAllInternalOrganisations;
        case UserSubset.PartnerOrganisations:
            return strings.targetGroupPartnerOrganisations;
        case UserSubset.SelectedOrganisations:
            return strings.targetGroupSelectedOrganisations;
        case UserSubset.NotVisibleForParticipation:
            return strings.targetGroupNotVisibleForParticipation;
        default:
            return strings.unknown;
    }
}
