import strings from "../../../localization/strings";
import styles from "./ProfileRegistration.module.scss";
import MedicalIcon from "../../../assets/medical_icon.svg";
import NotesIcon from "../../../assets/info_icon.svg";
import AnswerIcon from "../../../assets/answer_icon.svg";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import { AnswerOutput, PaymentViewModel, RegistrationStatus } from "../../../openapi/backend";
import PaymentTooltip from "../PaymentTooltip/PaymentTooltip";
import { hasValues } from "../../../utils.ts/Array";

interface ProfileRegistrationProps {
    name: string;
    status: RegistrationStatus;
    requiresPayment: boolean;
    notes?: string;
    medicalNotes?: string;
    payment?: PaymentViewModel;
    extraQuestionAnswers?: AnswerOutput[];
}

export default function ProfileRegistration({
    name,
    status,
    requiresPayment,
    notes,
    medicalNotes,
    payment,
    extraQuestionAnswers,
}: ProfileRegistrationProps) {
    return (
        <div className={styles.container}>
            <div
                className={`${styles.label} ${status === RegistrationStatus.Canceled && styles.canceled}`}
                aria-label={`${strings.openProfileOf} ${name}`}
            >
                {name}
            </div>

            <div className={styles.buttonContainer}>
                <PaymentTooltip className={styles.tooltip} requiresPayment={requiresPayment} payment={payment} />

                {medicalNotes && <TooltipIcon message={medicalNotes} icon={MedicalIcon} className={styles.tooltip} />}

                {notes && <TooltipIcon message={notes} icon={NotesIcon} className={styles.tooltip} />}
                {hasValues(extraQuestionAnswers) && (
                    <TooltipIcon
                        message={`${extraQuestionAnswers.map((answer, index) => {
                            if (index === extraQuestionAnswers.length - 1) {
                                return `${answer.question}\n\n${answer.value}`;
                            } else {
                                return `${answer.question}\n\n${answer.value}\n\n--------\n\n`;
                            }
                        })}`}
                        icon={AnswerIcon}
                        className={styles.tooltip}
                    />
                )}
            </div>
        </div>
    );
}
