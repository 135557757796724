import { useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { ProfileContext } from "../../../../contexts/ProfileContext";
import { NavigationTab } from "../../../../types/NavigationTab";
import { shouldBounce } from "../../../../utils.ts/BouncerUtils";
import styles from "./NavigationTabs.module.scss";

interface NavigationTabsProps {
    tabs: NavigationTab[];
}

export default function NavigationTabs({ tabs }: NavigationTabsProps) {
    const location = useLocation();
    const { profile: user } = useContext(ProfileContext);

    const pathname = location.pathname;
    const allowedTabs = useMemo(() => tabs.filter((t) => !shouldBounce(t.permissions, user)), [tabs, user]);
    const activeTab = useMemo(
        () => allowedTabs.find((tab) => pathname.endsWith(tab.pathname)),
        [pathname, allowedTabs],
    );

    const tabList = useMemo(() => {
        return allowedTabs.map((tab, index) => (
            <Link
                key={index}
                to={tab.pathname}
                id={`tablinkTo-${tab}`}
                className={`${styles.tabItem} ${activeTab === tab && styles.activeTab}`}
            >
                <label htmlFor={`tablinkTo-${tab}`} className={styles.text}>
                    {tab.label}
                </label>
            </Link>
        ));
    }, [allowedTabs, activeTab]);

    return (
        <div className={styles.header}>
            <div className={styles.tabContainer}>{tabList}</div>
            <div className={styles.childrenContainer}>{activeTab?.header}</div>
        </div>
    );
}
