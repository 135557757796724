import styles from "./DisplacementButton.module.scss";
import React from "react";
import cx from "classnames";
import strings from "../../../localization/strings";

type Props = {
    className?: string;
    onUpClick?: () => void;
    onDownClick?: () => void;
};

export default function DisplacementButton({ className, onUpClick, onDownClick }: Props) {
    return (
        <div className={cx(styles.container, className)}>
            <button
                className={styles.directionButton}
                onClick={onUpClick}
                type="button"
                disabled={!onUpClick}
                aria-label={strings.moveUp}
            >
                ↑
            </button>
            <button
                className={styles.directionButton}
                onClick={onDownClick}
                type="button"
                disabled={!onDownClick}
                aria-label={strings.moveDown}
            >
                ↓
            </button>
        </div>
    );
}
