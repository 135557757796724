import { Size } from "../../../types/Size";
import { getName } from "../../../utils.ts/GetName";
import AvatarIcon from "./AvatarIcon";

export interface ProfileIconProfile {
    givenName: string;
    familyName: string;
    avatar?: { href: string };
}

interface Props {
    profile: ProfileIconProfile;
    size?: Size;
    className?: string;
}

const ProfileIcon = ({ profile, ...props }: Props) => (
    <AvatarIcon name={getName(profile)} image={profile.avatar?.href} {...props} />
);

export default ProfileIcon;
