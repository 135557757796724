import styles from "./FilterInclusionLabel.module.scss";
import { useMemo } from "react";

interface Props {
    value: boolean;
    withLabel: string;
    withoutLabel: string;
    withStyle?: string;
    withoutStyle?: string;
}

export default function FilterInclusionLabel({
    value,
    withLabel: withString,
    withoutLabel: withoutString,
    withStyle,
    withoutStyle,
}: Props) {
    const style = useMemo(
        () => (value ? withStyle ?? styles.with : withoutStyle ?? styles.without),
        [value, withStyle, withoutStyle],
    );
    const label = useMemo(() => (value ? withString : withoutString), [value, withString, withoutString]);

    return (
        <div className={`${styles.label} ${style}`}>
            <label>{label}</label>
        </div>
    );
}
