import { useMemo } from "react";
import { useMemberOptions } from "../../../../hooks/UserHooks";
import { FormOption } from "../../Inputs/SelectField/SelectField";
import FormMultiDataListInput, { FormMultiDataListInputProps } from "./FormMultiDataListInput";
import { FieldName } from "../FormTypes";
import { FieldValues } from "react-hook-form";
import { getName } from "../../../../utils.ts/GetName";

type MemberDataListInputProps<TForm extends FieldValues, TName extends FieldName<TForm, string[]>> = Omit<
    FormMultiDataListInputProps<TForm, TName>,
    "options"
> & { happeningId?: string };

const MemberDataListInput = <TForm extends FieldValues, TName extends FieldName<TForm, string[]>>({
    happeningId,
    ...props
}: MemberDataListInputProps<TForm, TName>) => {
    const { value } = useMemberOptions(happeningId);
    const options = useMemo<FormOption<string>[]>(
        () => (value ? value.map((user) => ({ label: getName(user), value: user.id })) : []),
        [value],
    );

    if (options && options.length < 1) {
        return null;
    }

    return <FormMultiDataListInput options={options} {...props} />;
};

export default MemberDataListInput;
