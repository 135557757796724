import React, { ChangeEvent, useRef } from "react";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldError, UseFormRegister } from "react-hook-form";
import TextButton from "../../TextButton/TextButton";
import strings from "../../../../localization/strings";
import styles from "./FormFileInput.module.scss";

interface FormFileInputProps {
    name: string;
    accept?: string;
    label: string;
    errors?: FieldError;
    containerClassName?: string;
    required?: boolean;
    buttonDisabled?: boolean;
    register: UseFormRegister<any>;
    onChange: (file: File) => void;
    multiple: boolean;
}

export default function FormFileInput({
    name,
    accept,
    label,
    errors,
    containerClassName,
    required = false,
    buttonDisabled = false,
    register,
    onChange,
    multiple,
}: FormFileInputProps) {
    const hiddenFileUpload = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        hiddenFileUpload.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            for (let i = 0; i < event.target.files.length; i++) {
                onChange(event.target.files[i]);
            }
        }
    };

    return (
        <FormFieldContainer name={name} label={label} errors={errors} className={containerClassName}>
            <div>
                <TextButton
                    onClick={handleClick}
                    text={strings.chooseFile}
                    className={styles.fileInputButton}
                    disabled={buttonDisabled}
                />
                <input
                    accept={accept}
                    type="file"
                    {...register(name, { required: required })}
                    onChange={handleFileChange}
                    ref={hiddenFileUpload}
                    className={styles.fileInput}
                    multiple={multiple}
                />
            </div>
        </FormFieldContainer>
    );
}
