import { useEffect, useState } from "react";
import styles from "./SelectField.module.scss";
import cx from "classnames";
import { HappeningState } from "../../../../openapi/backend";

export type FormOption<T> = { label: string; value: T };

export interface SelectFieldProps<T>
    extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "onChange" | "multiple" | "value" | "defaultValue"> {
    name: string;
    options: FormOption<T>[];
    required?: boolean;
    disabled?: boolean;
    value?: T;
    defaultValue?: T;
    onChange?: (selected: T) => any;
    transform: (value: string) => T;
    className?: string;
    readOnly?: boolean;
    lockedValue?: string;
}

function SelectField<T>({
    name,
    options,
    placeholder,
    defaultValue,
    value,
    onChange,
    transform,
    className,
    lockedValue,
    readOnly,
    ...props
}: SelectFieldProps<T>) {
    const [innerValue, setInnerValue] = useState<T | undefined>(value !== undefined ? value : defaultValue);
    const selectorStyle = cx(styles.formSelect, innerValue !== undefined ? styles.enabled : styles.disabled);

    useEffect(
        () => setInnerValue((current) => (!placeholder && current === undefined ? options[0].value : current)),
        [setInnerValue, placeholder, options],
    );

    useEffect(() => {
        setInnerValue(value !== undefined ? value : defaultValue);
    }, [value, setInnerValue, defaultValue]);

    if (lockedValue) {
        return <div className={cx(styles.lockedValue, className)}>{lockedValue}</div>;
    } else if (readOnly) {
        return (
            <div className={cx(selectorStyle, styles.readOnly, className)}>
                {options.find((o) => o.value === value)?.label}
            </div>
        );
    }

    return (
        <select
            id={`input-${name}`}
            placeholder={placeholder}
            className={cx(selectorStyle, className)}
            onChange={(e) => onChange && onChange(transform(e.currentTarget.value))}
            value={innerValue !== undefined ? String(innerValue) : ""}
            {...props}
        >
            {placeholder && (
                <option value={""} disabled hidden>
                    {placeholder}
                </option>
            )}
            {options.map((option, index) => (
                <option value={String(option.value)} key={index}>
                    {option.label}
                </option>
            ))}
        </select>
    );
}

export const NumberSelectField = (props: Omit<SelectFieldProps<number>, "transform">) => (
    <SelectField {...props} transform={Number} />
);
export const StringSelectField = (props: Omit<SelectFieldProps<string>, "transform">) => (
    <SelectField {...props} transform={(val) => val} />
);
export const BooleanSelectField = (props: Omit<SelectFieldProps<boolean>, "transform">) => (
    <SelectField {...props} transform={(val) => val === "true"} />
);
export const HappeningStateSelectField = (props: Omit<SelectFieldProps<HappeningState>, "transform">) => (
    <SelectField {...props} transform={(val) => val as HappeningState} />
);

export default SelectField;
