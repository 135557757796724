import strings from "../../../localization/strings";
import { PaymentViewModel } from "../../../openapi/backend";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import EuroSymbol from "../../../assets/euro_symbol_large.svg";
import { formatAsPrice } from "../../../utils.ts/Formatting";
import { getStringForPaymentStatus } from "../../../utils.ts/GetPaymentStatus";
import { useMemo } from "react";
import { toastSuccess } from "../../../utils.ts/Toaster";

interface PaymentTooltipProps {
    className?: string;
    requiresPayment: boolean;
    payment?: PaymentViewModel;
}

function hasMollieId(payment?: PaymentViewModel) {
    return !!payment?.externalId;
}

function getPaymentMessage(requiresPayment: boolean, payment?: PaymentViewModel): string {
    switch (true) {
        case requiresPayment && hasMollieId(payment):
            return strings.formatString(
                strings.paymentStatusRequiredOnline,
                payment!.externalId!,
                getStringForPaymentStatus(payment!.status),
            ) as string;
        case requiresPayment && !hasMollieId(payment):
            return strings.paymentStatusRequired;
        case !requiresPayment && hasMollieId(payment):
            return strings.formatString(
                strings.paymentStatusDoneOnline,
                formatAsPrice(payment!.amount),
                payment!.externalId!,
                getStringForPaymentStatus(payment!.status),
            ) as string;
        case !requiresPayment && !hasMollieId(payment):
            return strings.paymentStatusDoneCash;
    }

    return strings.paymentStatusRequired;
}

const PaymentTooltip = ({ className, requiresPayment, payment }: PaymentTooltipProps) => {
    const onClick = useMemo(() => {
        if (!hasMollieId(payment)) {
            return undefined;
        }

        return async () => {
            await navigator.clipboard.writeText(payment!.externalId!);
            toastSuccess(strings.confirmCopyExternalPaymentId);
        };
    }, [payment]);

    return (
        <TooltipIcon
            icon={EuroSymbol}
            message={getPaymentMessage(requiresPayment, payment)}
            className={className}
            actionRequired={requiresPayment}
            onClick={onClick}
        />
    );
};

export default PaymentTooltip;
