import { useContext, useMemo } from "react";
import styles from "./ProfileButton.module.scss";
import { ReactComponent as DownArrow } from "../../../assets/DownArrow.svg";
import strings from "../../../localization/strings";
import { Link, useLocation } from "react-router-dom";
import { ProfileContext } from "../../../contexts/ProfileContext";
import ProfileContent from "../ProfileContent/ProfileContent";
import { useSignout } from "../../../authentication/useSignout";
import FloatingMenuButton from "../FloatingMenuButton/FloatingMenuButton";

export default function ProfileButton() {
    const location = useLocation();
    const { profile } = useContext(ProfileContext);
    const signout = useSignout();

    const currentlyActive = useMemo(() => {
        if (location.pathname === "/profile") {
            return styles.active;
        }
    }, [location.pathname]);

    const options = useMemo(
        () => [
            <Link id={`linkToProfile`} to={"profile"}>
                <label htmlFor="linkToProfile" className={`${styles.linkLabel} ${currentlyActive}`}>
                    {strings.profile}
                </label>
            </Link>,
            <button onClick={signout}>{strings.logout}</button>,
        ],
        [currentlyActive, signout],
    );

    return (
        <FloatingMenuButton options={options}>
            <button className={styles.profileButton}>
                {profile && <ProfileContent profile={profile} />}
                <DownArrow />
            </button>
        </FloatingMenuButton>
    );
}
