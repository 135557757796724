import SimpleTextButton from "../SimpleTextButton/SimpleTextButton";
import styles from "./ProfileField.module.scss";

interface ProfileFieldProps {
    label: string;
    value: string;
    onClick?: () => void;
    allowNewLines?: boolean;
}

export default function ProfileField({ label, value, onClick, allowNewLines }: ProfileFieldProps) {
    return (
        <div className={styles.container}>
            <div className={styles.text}>{`${label}:`}</div>
            <div className={styles.values}>
                {onClick ? (
                    <SimpleTextButton onClick={onClick} text={value} />
                ) : (
                    <div className={`${styles.text} ${allowNewLines ? styles.allowNewLines : ""}`}>{value}</div>
                )}
            </div>
        </div>
    );
}
