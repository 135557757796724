import { UserShortOutput } from "../../../openapi/backend";
import styles from "./ContactDetails.module.scss";
import { getName } from "../../../utils.ts/GetName";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import { Size } from "../../../types/Size";
import strings from "../../../localization/strings";
import FieldView from "../FieldView/FieldView";
import { Link } from "react-router-dom";
import { getMailLink, getPhoneLink } from "../../../utils.ts/Urls";

interface ContactDetailsProps {
    contact?: UserShortOutput;
}

export default function ContactDetails({ contact }: ContactDetailsProps) {
    if (!contact) {
        return null;
    }

    const { phoneNumber, email } = contact;

    return (
        <FieldView label={strings.suggestionDoneBy}>
            <div className={styles.container}>
                <div className={styles.profileIcon}>
                    <ProfileIcon profile={contact} size={Size.MEDIUM} />
                </div>
                <div className={styles.detailsContainer}>
                    <div className={styles.detailsTextName}>{getName(contact)}</div>
                    {phoneNumber && (
                        <Link className={styles.detailsText} to={getPhoneLink(phoneNumber)}>{`${phoneNumber}, `}</Link>
                    )}
                    <Link className={styles.detailsText} to={getMailLink(email)}>
                        {email}
                    </Link>
                </div>
            </div>
        </FieldView>
    );
}
