import React from "react";
import { TagViewModel } from "../../../openapi/backend";
import GroupIcon from "../AvatarIcon/GroupIcon";
import styles from "./IconTagOption.module.scss";

interface Props {
    tag: TagViewModel;
}

export default function IconTagOption({ tag }: Props) {
    return (
        <div className={styles.container}>
            <GroupIcon group={tag} />
            <span className={styles.label}>{tag.detailedName}</span>
        </div>
    );
}
