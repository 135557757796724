import styles from "./SearchDataListInput.module.scss";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FieldName } from "../../Form/FormTypes";
import MultiDataListInputLocationSearch from "../MultiDataListInput/MultiDataListInputLocationSearch";
import FormFieldContainer from "../../Form/FormFieldContainer/FormFieldContainer";
import { TagViewModel } from "../../../../openapi/backend";

export interface SearchDataListInputProps<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, TagViewModel[]>,
> {
    list: string;
    name: TName;
    label?: string;
    control: Control<TFieldValues, object>;
}

export default function SearchDataListInput<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, TagViewModel[]>,
>({ list, name, label, control }: SearchDataListInputProps<TFieldValues, TName>) {
    return (
        <div className={styles.container}>
            <FormFieldContainer name={name} label={label}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <MultiDataListInputLocationSearch list={list} name={name} value={value} onChange={onChange} />
                    )}
                />
            </FormFieldContainer>
        </div>
    );
}
