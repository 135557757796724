import React, { useMemo } from "react";
import cx from "classnames";
import { ApplicationStatus } from "../../../openapi/backend";
import { applicationStatusToString } from "../../../utils.ts/GetApplicationStatus";
import styles from "./ApplicationStatusLabel.module.scss";

interface Props {
    status?: ApplicationStatus;
}

function getStyle(status: ApplicationStatus) {
    switch (status) {
        case ApplicationStatus.Approved:
            return styles.approved;
        case ApplicationStatus.InReview:
            return styles.inReview;
        case ApplicationStatus.NotSubmitted:
            return styles.notSubmitted;
        case ApplicationStatus.PendingAction:
            return styles.pendingAction;
        case ApplicationStatus.Received:
            return styles.received;
        case ApplicationStatus.Rejected:
            return styles.rejected;
        case ApplicationStatus.Submitted:
            return styles.submitted;
        default:
            return styles.received;
    }
}

export default function ApplicationStatusLabel({ status }: Props) {
    const statusStyle = useMemo(() => status && getStyle(status), [status]);

    return (
        <label id={status} className={cx(styles.container, statusStyle)} htmlFor={status}>
            {status && applicationStatusToString(status)}
        </label>
    );
}
