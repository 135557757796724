import React, { useCallback } from "react";
import styles from "./CheckboxGroupInput.module.scss";

interface CheckboxInputType<T> {
    label: string;
    value: T;
}

export type Props<T extends string> = {
    name?: string;
    options: Array<CheckboxInputType<T>>;
    onChange?: (value: Array<T>) => void;
    value?: Array<T>;
    required?: boolean;
    disabled?: boolean;
};

function CheckboxGroupInput<T extends string>({ name, options, onChange, value, ...props }: Props<T>) {
    const onCheckChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            if (!onChange) {
                return;
            }

            let updatedValue = value || [];
            if (e.target.checked) {
                // add to value if it doesn't exist yet
                if (updatedValue.indexOf(e.target.value as T) === -1) {
                    updatedValue.push(e.target.value as T);
                }
            } else {
                updatedValue = updatedValue.filter((v) => v !== e.target.value);
            }

            onChange(updatedValue);
        },
        [onChange, value],
    );

    return (
        <div className={styles.container}>
            {options.map(({ label, value: v }) => (
                <label className={styles.label} key={v}>
                    <input
                        type="checkbox"
                        data-testid="CheckboxInput"
                        className={styles.input}
                        name={name}
                        value={v}
                        checked={value && value.indexOf(v) > -1}
                        onChange={onCheckChange}
                        {...props}
                    />
                    <span className={styles.text} title={label}>
                        {label.charAt(0)}
                    </span>
                </label>
            ))}
        </div>
    );
}

export default CheckboxGroupInput;
