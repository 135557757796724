import { ReactNode, useMemo } from "react";
import { Column } from "react-table";
import strings from "../../../localization/strings";
import { HappeningViewModel, ProfileOutput } from "../../../openapi/backend";
import ReserveListItem from "../../core/ReserveListItem/ReserveListItem";
import StaticTable from "../../core/StaticTable/StaticTable";
import { getAge } from "../../../utils.ts/Profile";

export type ReserveListTableProps = {
    happening: HappeningViewModel;
    reserveList: ProfileOutput[];
    onSelectParticipant: (id: string) => void;
};

type ReserveListTableItem = {
    Registration: ReactNode;
};

export default function ReserveListTable({ reserveList, happening, onSelectParticipant }: ReserveListTableProps) {
    const columns: Column<ReserveListTableItem>[] = [
        {
            Header: strings.name,
            accessor: "Registration",
            width: "100%",
        },
    ];

    const data = useMemo<ReserveListTableItem[]>(
        () =>
            reserveList.map((profile, index) => {
                const name = `${profile.givenName} ${profile.familyName}`;
                const participantRegistration = (
                    <ReserveListItem
                        key={index}
                        name={name}
                        medicalNotes={profile.medicalNotes}
                        onClick={() => onSelectParticipant(profile.id)}
                        participantId={profile.id}
                        happeningId={happening.id}
                        age={profile.dateOfBirth ? getAge(profile.dateOfBirth) : 0}
                    />
                );

                return {
                    Registration: <span>{participantRegistration}</span>,
                };
            }),
        [reserveList, happening, onSelectParticipant],
    );

    return <StaticTable columns={columns} data={data} />;
}
