import { usePartnerSuggestionsOverview } from "../../hooks/HappeningsHooks";
import { HappeningState } from "../../openapi/backend";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import { HappeningSubset } from "../../types/HappeningSubset";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["happeningTypes"];
const excludeHappeningSubsets = [HappeningSubset.OrganizedByPartner];
const allowedStates = [HappeningState.PartnerSuggestion, HappeningState.Rejected, HappeningState.Concept];

export default function PartnerSuggestionsContainer() {
    const [happeningsState, getHappenings] = usePartnerSuggestionsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
            excludeHappeningSubsets={excludeHappeningSubsets}
            allowedStates={allowedStates}
        />
    );
}
