/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    ImageModelOperation,
    ImageModelOperationFromJSON,
    ImageModelOperationToJSON,
    MediaCategoryViewModel,
    MediaCategoryViewModelFromJSON,
    MediaCategoryViewModelToJSON,
    MediaViewModel,
    MediaViewModelFromJSON,
    MediaViewModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from "../models";

export interface CreateMediaRequest {
    version: string;
    altText: string;
    file: Blob;
    categoryId?: string;
}

export interface DeleteMediaRequest {
    id: number;
    version: string;
}

export interface GetLibraryRequest {
    version: string;
    query?: string;
}

export interface GetMediaRequest {
    id: number;
    version: string;
}

export interface RemoveFromUploadedByMeRequest {
    id: number;
    version: string;
}

export interface UpdateMediaRequest {
    id: number;
    version: string;
    imageModelOperation?: Array<ImageModelOperation>;
}

/**
 * no description
 */
export class MediaApi extends runtime.BaseAPI {
    /**
     */
    async createMediaRaw(requestParameters: CreateMediaRequest): Promise<runtime.ApiResponse<MediaViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createMedia.",
            );
        }
        if (requestParameters.altText === null || requestParameters.altText === undefined) {
            throw new runtime.RequiredError(
                "altText",
                "Required parameter requestParameters.altText was null or undefined when calling createMedia.",
            );
        }
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError(
                "file",
                "Required parameter requestParameters.file was null or undefined when calling createMedia.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.altText !== undefined) {
            formParams.append("AltText", requestParameters.altText as any);
            hasParams = true;
        }
        if (requestParameters.file !== undefined) {
            formParams.append("File", requestParameters.file as any);
            hasParams = true;
        }
        if (requestParameters.categoryId !== undefined) {
            formParams.append("categoryId", requestParameters.categoryId as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Media`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MediaViewModelFromJSON(jsonValue));
    }
    /**
     */
    async createMedia(requestParameters: CreateMediaRequest): Promise<MediaViewModel> {
        const response = await this.createMediaRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deleteMediaRaw(requestParameters: DeleteMediaRequest): Promise<runtime.ApiResponse<MediaViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteMedia.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteMedia.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MediaViewModelFromJSON(jsonValue));
    }
    /**
     */
    async deleteMedia(requestParameters: DeleteMediaRequest): Promise<MediaViewModel> {
        const response = await this.deleteMediaRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getLibraryRaw(
        requestParameters: GetLibraryRequest,
    ): Promise<runtime.ApiResponse<Array<MediaCategoryViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getLibrary.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Media`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaCategoryViewModelFromJSON));
    }
    /**
     */
    async getLibrary(requestParameters: GetLibraryRequest): Promise<Array<MediaCategoryViewModel>> {
        const response = await this.getLibraryRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMediaRaw(requestParameters: GetMediaRequest): Promise<runtime.ApiResponse<MediaViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getMedia.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMedia.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MediaViewModelFromJSON(jsonValue));
    }
    /**
     */
    async getMedia(requestParameters: GetMediaRequest): Promise<MediaViewModel> {
        const response = await this.getMediaRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async removeFromUploadedByMeRaw(
        requestParameters: RemoveFromUploadedByMeRequest,
    ): Promise<runtime.ApiResponse<MediaViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling removeFromUploadedByMe.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling removeFromUploadedByMe.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Media/{id}/removeFromUploadedByMe`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MediaViewModelFromJSON(jsonValue));
    }
    /**
     */
    async removeFromUploadedByMe(requestParameters: RemoveFromUploadedByMeRequest): Promise<MediaViewModel> {
        const response = await this.removeFromUploadedByMeRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateMediaRaw(requestParameters: UpdateMediaRequest): Promise<runtime.ApiResponse<MediaViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateMedia.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateMedia.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters["Content-Type"] = "application/json-patch+json";
        const response = await this.request({
            path: `/v{version}/Media/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PATCH",
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.imageModelOperation?.map(ImageModelOperationToJSON),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MediaViewModelFromJSON(jsonValue));
    }
    /**
     */
    async updateMedia(requestParameters: UpdateMediaRequest): Promise<MediaViewModel> {
        const response = await this.updateMediaRaw(requestParameters);
        return await response.value();
    }
}
