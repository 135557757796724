import TextButton from "../TextButton/TextButton";
import AddIcon from "../../../assets/add_icon.svg";
import strings from "../../../localization/strings";

interface ToolbarButtonProps {
    text: string;
    onClick?: () => void;
}

export default function ToolbarButton({ text, onClick }: ToolbarButtonProps) {
    return (
        <TextButton
            buttonType="toolbar"
            text={text}
            onClick={onClick}
            icon={AddIcon}
            iconAlt={strings.createHappening}
        />
    );
}
