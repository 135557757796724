import moment from "moment";
import { useMemo } from "react";
import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import DateTimePicker from "../../Inputs/DateTimePicker/DateTimePicker";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

type ValidationType = "maxDate" | "max1Year" | "max1YearPeriod";
interface DatepickerProps {
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    validationType?: ValidationType;
}
interface FormDatepickerProps<TValue extends Date, TForm extends FieldValues, TName extends FieldName<TForm, TValue>>
    extends Omit<DatepickerProps, "onChange"> {
    dateTitle?: string;
    timeTitle?: string;
    name: TName | any;
    control: Control<TForm>;
    required?: boolean;
    errors?: FieldError | FieldError[];
}

function validateMaxDate(maxDate?: Date) {
    if (!maxDate) {
        return () => true;
    }

    const date = moment(maxDate);

    return (value: any) => value && moment(value).isSameOrBefore(date);
}

export default function FormDateTimePicker<
    TValue extends Date,
    TForm extends FieldValues,
    TName extends FieldName<TForm, TValue>,
>({
    name,
    dateTitle,
    timeTitle,
    errors,
    required,
    control,
    validationType = "maxDate",
    ...fieldProps
}: FormDatepickerProps<TValue, TForm, TName>) {
    const customValidation = useMemo(() => {
        const validationObject: any = {};

        switch (validationType) {
            case "max1Year":
                validationObject.sessionMax1Year = validateMaxDate(fieldProps.maxDate);
                break;
            case "max1YearPeriod":
                validationObject.periodMax1Year = validateMaxDate(fieldProps.maxDate);
                break;
            case "maxDate":
                validationObject.maxDate = validateMaxDate(fieldProps.maxDate);
        }

        return validationObject;
    }, [validationType, fieldProps.maxDate]);

    return (
        <FormFieldContainer name={name} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required, validate: fieldProps.maxDate ? customValidation : undefined }}
                render={({ field: { ref, value, ...field } }) => {
                    const formValue = value as Date | undefined;
                    return (
                        <DateTimePicker
                            {...fieldProps}
                            {...field}
                            dateTitle={dateTitle}
                            timeTitle={timeTitle}
                            value={formValue}
                        />
                    );
                }}
            />
        </FormFieldContainer>
    );
}
