import { ReactNode } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "./AccountForm.module.scss";
import { ArrangementListOutput, TagType, UserOutput } from "../../../openapi/backend";
import Form from "../../core/Form/Form/Form";
import { UpdateUserInput } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import Accordion from "../../core/Accordion/Accordion";
import AuthorizationFields from "./AuthorizationFields";
import Profile from "../../Profile/Profile";
import { doNothing } from "../../../utils.ts/DoNothingCallback";

interface EditAccountProps {
    user: UserOutput;
    error?: Response;
    onDismiss: () => void;
    onSubmit: (input: UpdateUserInput) => void;
    EditButtons: ReactNode;
    isSubmitting: boolean;
    arrangements: ArrangementListOutput[];
}

const validationSchema = yup.object({
    roles: yup.array().min(1),
    happeningGroups: yup.array(),
    partners: yup.array(),
});

export default function EditAccount({
    user,
    onSubmit,
    onDismiss,
    error,
    EditButtons,
    isSubmitting,
    arrangements,
}: EditAccountProps) {
    const {
        handleSubmit,
        control,
        formState: { errors },
        unregister,
        watch,
    } = useForm<UpdateUserInput>({
        defaultValues: {
            ...user,
            happeningGroups: user.tags.filter((x) => x.type === TagType.HappeningGroup).map((x) => x.id),
            partners: user.tags.filter((x) => x.type === TagType.Partner).map((x) => x.id),
            authorizedArrangements: user.authorizedArrangements.map((x) => x.id),
            preferredFilters: user.preferredFilters.map((x) => x.id),
        },
        resolver: yupResolver(validationSchema),
    });

    return (
        <div className={styles.container}>
            <Profile user={user} profile={user} EditButtons={undefined} onSelectProfile={doNothing} />

            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                secondaryButtons={EditButtons}
                waitingForSubmit={isSubmitting}
            >
                <Accordion title={strings.details} collapsible={true} open={true}>
                    <AuthorizationFields
                        control={control}
                        watch={watch}
                        unregister={unregister}
                        errors={errors}
                        arrangements={arrangements}
                    />
                </Accordion>
            </Form>
        </div>
    );
}
