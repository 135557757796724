import { PermissionCheck } from "../authentication/Permissions";
import { Profile } from "../contexts/ProfileContext";

export function shouldBounce(permissions?: PermissionCheck[], user?: Profile) {
    if (!permissions) {
        return false;
    }

    return permissions.some((permission) => !permission(user));
}
