import React, { PropsWithChildren } from "react";
import { Configuration, RegistrationRulesApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<RegistrationRulesApi> = (params) => new RegistrationRulesApi(new Configuration(params));

export const RegistrationRuleApiContext = React.createContext(createApi({}));

export const RegistrationRuleApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={RegistrationRuleApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
