import { FieldErrors, UseFormRegister } from "react-hook-form";
import strings from "../../../localization/strings";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import styles from "./ApplicationForm.module.scss";

interface SupplementFieldProps {
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
}

export default function SupplementField({ register, errors }: SupplementFieldProps) {
    return (
        <div className={styles.section}>
            <h2 className={styles.sectionHeader}>{strings.applicationFormHeaderAdditionalInformation}</h2>
            <div className={styles.row}>
                <FormFieldMultiline
                    rows={4}
                    name={"supplement"}
                    label={strings.applicationFormAdditionalRemarks}
                    register={register}
                    errors={errors.supplement}
                />
            </div>
        </div>
    );
}
