import strings from "../localization/strings";
import { PaymentStatus } from "../openapi/backend";

export function getStringForPaymentStatus(type: PaymentStatus) {
    switch (type) {
        case PaymentStatus.Unknown:
            return strings.unknown;
        case PaymentStatus.Open:
            return strings.paymentStatusEnumOpen;
        case PaymentStatus.Canceled:
            return strings.paymentStatusEnumCanceled;
        case PaymentStatus.Pending:
            return strings.paymentStatusEnumPending;
        case PaymentStatus.Authorized:
            return strings.paymentStatusEnumAuthorized;
        case PaymentStatus.Expired:
            return strings.paymentStatusEnumExpired;
        case PaymentStatus.Failed:
            return strings.paymentStatusEnumFailed;
        case PaymentStatus.Paid:
            return strings.paymentStatusEnumPaid;
        case PaymentStatus.Refunded:
            return strings.paymentStatusEnumRefunded;
    }
}
