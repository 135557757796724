import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiCallState, RequestState } from "./UseApiCall";

export default function useErrorOrNavigate<T>(
    requestState: ApiCallState<T>,
    setError: (error: Response | undefined) => void,
    to: string | number | (() => void) = -1,
) {
    const navigate = useNavigate();
    useEffect(() => {
        if (requestState.state === RequestState.ERROR) {
            setError(requestState.error);
            return;
        }
        setError(undefined);

        if (requestState.state === RequestState.DONE) {
            if (typeof to === "string") {
                navigate(to);
            } else if (typeof to === "function") {
                to();
            } else {
                navigate(to);
            }
        }
    }, [requestState, setError, navigate, to]);
}
