import { useGetMyMeasurements } from "../../hooks/MeasurementHooks";
import { MeasurementFilterKeys } from "../../types/MeasurementFilterKeys";
import Measurements from "./Measurements";

const excludeFilters: MeasurementFilterKeys = ["measurers"];

export function MyMeasurementsContainer() {
    const [{ value: measurementsValue, error: measurementsError }, getMeasurements] = useGetMyMeasurements();

    return (
        <Measurements
            measurements={measurementsValue}
            error={measurementsError}
            getMeasurements={getMeasurements}
            excludeFilters={excludeFilters}
        />
    );
}

export default MyMeasurementsContainer;
