import strings from "../../../localization/strings";
import ConfirmationModal from "../../core/ConfirmationModal/ConfirmationModal";

interface ContainerProps {
    isOpen: boolean;
    onDismiss: () => void;
    onSuccess: () => void;
}

const CancelModal = ({ isOpen, onDismiss, onSuccess }: ContainerProps) => {
    return (
        <ConfirmationModal isOpen={isOpen} onCancel={onDismiss} onConfirm={onSuccess}>
            {strings.cancelChanges}
        </ConfirmationModal>
    );
};

export default CancelModal;
