/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RegistrationRuleViewModel,
    RegistrationRuleViewModelFromJSON,
    RegistrationRuleViewModelToJSON,
} from "../models";

export interface CreateRegistrationRuleRequest {
    version: string;
    startDate: Date;
    endDate: Date;
    organisationId: string;
    maxFreeRegistrations: number;
}

export interface DeleteRegistrationRuleRequest {
    id: number;
    version: string;
}

export interface GetRegistrationRulesRequest {
    version: string;
}

/**
 * no description
 */
export class RegistrationRulesApi extends runtime.BaseAPI {
    /**
     */
    async createRegistrationRuleRaw(
        requestParameters: CreateRegistrationRuleRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createRegistrationRule.",
            );
        }
        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError(
                "startDate",
                "Required parameter requestParameters.startDate was null or undefined when calling createRegistrationRule.",
            );
        }
        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError(
                "endDate",
                "Required parameter requestParameters.endDate was null or undefined when calling createRegistrationRule.",
            );
        }
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError(
                "organisationId",
                "Required parameter requestParameters.organisationId was null or undefined when calling createRegistrationRule.",
            );
        }
        if (requestParameters.maxFreeRegistrations === null || requestParameters.maxFreeRegistrations === undefined) {
            throw new runtime.RequiredError(
                "maxFreeRegistrations",
                "Required parameter requestParameters.maxFreeRegistrations was null or undefined when calling createRegistrationRule.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.startDate !== undefined) {
            formParams.append("StartDate", (requestParameters.startDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.endDate !== undefined) {
            formParams.append("EndDate", (requestParameters.endDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.organisationId !== undefined) {
            formParams.append("OrganisationId", requestParameters.organisationId as any);
            hasParams = true;
        }
        if (requestParameters.maxFreeRegistrations !== undefined) {
            formParams.append("MaxFreeRegistrations", requestParameters.maxFreeRegistrations as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/RegistrationRules`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async createRegistrationRule(requestParameters: CreateRegistrationRuleRequest): Promise<void> {
        await this.createRegistrationRuleRaw(requestParameters);
    }
    /**
     */
    async deleteRegistrationRuleRaw(
        requestParameters: DeleteRegistrationRuleRequest,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteRegistrationRule.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteRegistrationRule.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/RegistrationRules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteRegistrationRule(requestParameters: DeleteRegistrationRuleRequest): Promise<void> {
        await this.deleteRegistrationRuleRaw(requestParameters);
    }
    /**
     */
    async getRegistrationRulesRaw(
        requestParameters: GetRegistrationRulesRequest,
    ): Promise<runtime.ApiResponse<Array<RegistrationRuleViewModel>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getRegistrationRules.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/RegistrationRules`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegistrationRuleViewModelFromJSON));
    }
    /**
     */
    async getRegistrationRules(
        requestParameters: GetRegistrationRulesRequest,
    ): Promise<Array<RegistrationRuleViewModel>> {
        const response = await this.getRegistrationRulesRaw(requestParameters);
        return await response.value();
    }
}
