import { useCallback, useEffect, useMemo, useState } from "react";
import CheckIcon from "../../../assets/checkmark.svg";
import CrossIcon from "../../../assets/cross_red.svg";
import MinusIcon from "../../../assets/minus.svg";
import styles from "./ToggleButton.module.scss";

interface ToggleButtonProps {
    value?: boolean;
    onChange: (selected: any) => void;
}

export default function ToggleButton({ value, onChange }: ToggleButtonProps) {
    const [open, setOpen] = useState(false);
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const StatusIcon = useMemo(() => {
        switch (true) {
            case localValue === true:
                return CheckIcon;
            case localValue === false:
                return CrossIcon;
            default:
                return MinusIcon;
        }
    }, [localValue]);

    const onClickValue = useCallback(
        (selected?: boolean) => {
            setOpen(false);
            setLocalValue(selected);
            onChange(selected);
        },
        [setOpen, setLocalValue, onChange],
    );

    return open ? (
        <div className={`${styles.buttonContainer} ${styles.openButtonShadow}`}>
            <button className={styles.toggleButton} onClick={() => setOpen(false)}>
                <img src={StatusIcon} alt="status-icon" />
            </button>
            {localValue !== true && (
                <button className={styles.toggleButton} onClick={() => onClickValue(true)}>
                    <img src={CheckIcon} alt="check-icon" />
                </button>
            )}
            {localValue !== false && (
                <button className={styles.toggleButton} onClick={() => onClickValue(false)}>
                    <img src={CrossIcon} alt="cross-icon" />
                </button>
            )}
        </div>
    ) : (
        <div className={`${styles.buttonContainer} ${styles.closedToggleButton}`}>
            <button className={`${styles.toggleButton}`} onClick={() => setOpen(true)}>
                <img src={StatusIcon} alt="status-icon" />
            </button>
        </div>
    );
}
