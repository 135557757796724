import { TagViewModel } from "../../../openapi/backend";
import { Size } from "../../../types/Size";
import { hasValues } from "../../../utils.ts/Array";
import { getLocalizedResponsibilityLabels } from "../../../utils.ts/GetResponsibilitiesLabel";
import AvatarIcon from "./AvatarIcon";
import styles from "./AvatarIcon.module.scss";
import VisibilityOffIcon from "../../../assets/visibility_off.svg";
import strings from "../../../localization/strings";

interface Props {
    partner: TagViewModel;
    showResponsibilities?: boolean;
    showVisibilityInCommunication?: boolean;
}

const PartnerIcon = ({ partner, showResponsibilities, showVisibilityInCommunication }: Props) => (
    <div className={styles.partnerContainer}>
        <AvatarIcon name={partner.name} color={partner.color} image={partner.logo?.href} size={Size.MEDIUM} />
        <div className={styles.partnerName}>{partner.name}</div>
        {showVisibilityInCommunication && !partner.isShownInCommunication && (
            <img className={styles.visibility} src={VisibilityOffIcon} alt={strings.isNotShownInCommunication} />
        )}
        {showResponsibilities && hasValues(partner.responsibilities) && (
            <span className={styles.partnerRole}>{` (${getLocalizedResponsibilityLabels(
                partner.responsibilities,
            )})`}</span>
        )}
    </div>
);

export default PartnerIcon;
