import { useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { useProjectLeaderOptions } from "../../../../hooks/UserHooks";
import FormDataListInput, { FormDataListInputProps } from "./FormDataListInput";
import { FieldName } from "../FormTypes";
import { FormOption } from "../../Inputs/SelectField/SelectField";
import { getName } from "../../../../utils.ts/GetName";

type LeaderDataListInputProps<TForm extends FieldValues, TName extends FieldName<TForm, string>> = Omit<
    FormDataListInputProps<TForm, TName>,
    "options"
> & { happeningId?: string };

// Also to be used for contactpersons
const LeaderSelect = <TForm extends FieldValues, TName extends FieldName<TForm, string>>({
    happeningId,
    ...props
}: LeaderDataListInputProps<TForm, TName>) => {
    const { value } = useProjectLeaderOptions(happeningId);
    const options = useMemo<FormOption<string>[]>(
        () => (value ? value.map((user) => ({ label: getName(user), value: user.id })) : []),
        [value],
    );

    if (options && options.length < 1) {
        return null;
    }

    return <FormDataListInput options={options} {...props} />;
};

export default LeaderSelect;
