import styles from "./HappeningField.module.scss";

interface HappeningFieldProps {
    title: string;
    value: string | number | string[];
    list?: boolean;
}
export default function HappeningField({ title, value, list = false }: HappeningFieldProps) {
    return (
        <div className={styles.cardField}>
            <label className={styles.cardFieldLabel}>{title}</label>
            {list ? (
                <ul className={styles.cardFieldList}>
                    {value instanceof Array &&
                        value.map((item, index) => (
                            <li className={styles.cardFieldItem} key={`${title}-${index}`}>
                                {item}
                            </li>
                        ))}
                </ul>
            ) : (
                <div className={styles.cardFieldValue}>{value}</div>
            )}
        </div>
    );
}
