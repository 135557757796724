import { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { canEditHappening } from "../../../authentication/Permissions";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { TagsContext } from "../../../contexts/TagsContext";
import { useHappeningDetails, useUpdateHappeningCallback } from "../../../hooks/HappeningsHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import { useMissingImagesQuickfix } from "../../../hooks/useMissingImagesQuickfix";
import strings from "../../../localization/strings";
import { ReferenceOutput } from "../../../openapi/backend";
import { Operation } from "../../../types/Operation";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import HappeningForm from "../Happening/HappeningForm";
import styles from "./HappeningForm.module.scss";
import { getDefaultValuesFromHappening } from "./HappeningUtils";

export default function UpdateHappeningContainer() {
    const tags = useContext(TagsContext).activeTags;
    const { profile } = useContext(ProfileContext);
    const { happeningId } = useParams();
    const { value: happening, state: happeningState } = useHappeningDetails(happeningId || "");

    const navigate = useNavigate();

    const hotfixSBG2344Images = useMissingImagesQuickfix(happening?.images);

    const onSuccess = useCallback(
        (_: ReferenceOutput) => {
            // Navigate back to the happening. Since it's an update and usually initiated from the happening detail page,
            // we don't need to navigate to the happening page.
            // It's sufficient to just go back to the previous page.
            navigate(-1);
        },
        [navigate],
    );

    if (!profile || !tags) {
        return <InlineLoading />;
    }

    if (happeningState === RequestState.LOADING || happeningState === RequestState.IDLE) {
        return <div>{strings.loading}</div>;
    }

    if (!happening) {
        return <div>{strings.happeningNotFound}</div>;
    }

    const isAllowedToEditHappening = canEditHappening(happening, profile);

    if (!isAllowedToEditHappening) {
        return <div>{strings.notAllowedToEditHappening}</div>;
    }

    const defaultValues = getDefaultValuesFromHappening(happening);

    return (
        <HappeningForm
            title={strings.updateHappening}
            user={profile}
            tags={tags}
            onDismiss={() => navigate(-1)}
            onSaved={onSuccess}
            callback={useUpdateHappeningCallback}
            defaultValues={defaultValues}
            storedImages={hotfixSBG2344Images}
            className={styles.fullPageForm}
            type={happening.type}
            operation={Operation.UPDATE}
            happeningId={happeningId}
            contact={happening.createdBy ?? happening.contact}
            registrations={happening.registrations}
        />
    );
}
