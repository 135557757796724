import cx from "classnames";
import { canAssignApplications } from "../../../authentication/Permissions";
import strings from "../../../localization/strings";
import { Person, getName } from "../../../utils.ts/GetName";
import ApplicationStatusLabel from "../../core/ApplicationStatusLabel/ApplicationStatusLabel";
import ProfileContent from "../../core/ProfileContent/ProfileContent";
import InfoBlock from "./InfoBlock";
import styles from "./ApplicationForm.module.scss";
import { ApplicationStatus, ArrangementListOutput, UserListOutput } from "../../../openapi/backend";
import MultiselectDropdown from "../../core/Inputs/MultiselectDropdown/MultiselectDropdown";
import { useCallback, useContext, useMemo } from "react";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { ProfileContext } from "../../../contexts/ProfileContext";

export interface HeaderApplication {
    arrangement: ArrangementListOutput;
    status?: ApplicationStatus;
    applicant?: Person;
    subjectMunicipality?: string;
}

interface ApplicationHeaderProps {
    application: HeaderApplication;
    intermediaries?: UserListOutput[];
    watch: UseFormWatch<any>;
    setValue: UseFormSetValue<any>;
}

export default function ApplicationHeader({ application, intermediaries, watch, setValue }: ApplicationHeaderProps) {
    const reviewerOptions = useMemo(() => intermediaries?.map((i) => ({ ...i, name: getName(i) })), [intermediaries]);
    const reviewerId = watch("reviewerId");
    const { profile } = useContext(ProfileContext);

    const reviewerValue = useMemo(() => {
        const selectedReviewer = reviewerOptions?.find((r) => r.id === reviewerId);
        if (selectedReviewer) {
            return [selectedReviewer];
        } else {
            return [];
        }
    }, [reviewerId, reviewerOptions]);

    const onChangeReviewer = useCallback(
        (selected: typeof reviewerOptions) => setValue("reviewerId", selected?.at(0)?.id),
        [setValue],
    );

    return (
        <>
            <div className={styles.header}>
                <h1 className={styles.subject}>{application.arrangement.name || "-"}</h1>
                <ApplicationStatusLabel status={application.status} />
            </div>
            <div className={cx(styles.applicationInfo, styles.dividerBottom)}>
                <InfoBlock label={strings.applicant}>
                    <p className={styles.text}>{getName(application.applicant) || "-"}</p>
                </InfoBlock>
                <InfoBlock label={strings.municipality}>
                    <p className={styles.text}>{application.subjectMunicipality || "-"}</p>
                </InfoBlock>
                {reviewerOptions && (
                    <InfoBlock label={strings.arrangementIntermediary}>
                        <MultiselectDropdown
                            options={reviewerOptions}
                            renderOption={(option) => <ProfileContent profile={option} />}
                            renderSelection={(options) => <ProfileContent profile={options[0]} />}
                            placeholder="-"
                            name={"reviewerSelector"}
                            className={styles.reviewSelector}
                            onChange={onChangeReviewer}
                            value={reviewerValue}
                            searchable
                            searchPlaceholder={strings.searchByApplicationReviewer}
                            restrictToSingle
                            disabled={!canAssignApplications(profile)}
                        />
                    </InfoBlock>
                )}
            </div>
        </>
    );
}
