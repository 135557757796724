import useImageInput from "../../../../hooks/UseImageInput";
import { OrganizerViewModel } from "../../../../openapi/backend";
import { acceptImageFileTypes } from "../../../../utils.ts/Media";
import AvatarIcon from "../../AvatarIcon/AvatarIcon";
import GroupIcon from "../../AvatarIcon/GroupIcon";
import styles from "./IconInput.module.scss";

interface IconInputProps
    extends Omit<
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        "type" | "onChange"
    > {
    name: string;
    label: string;
    group: OrganizerViewModel;
    onChange?: (file: File) => void;
}

const IconInput = ({ name, label, group, onChange, ...props }: IconInputProps) => {
    const [value, onImageChange] = useImageInput(onChange);

    return (
        <div className={styles.container}>
            <input
                id={name}
                name={name}
                className={styles.input}
                type="file"
                aria-label={label}
                onChange={onImageChange}
                accept={acceptImageFileTypes}
                {...props}
            />
            <label htmlFor={name} className={styles.label} tabIndex={0}>
                {value ? (
                    <AvatarIcon color={group.color} image={value} className={styles.icon} name={group.name} />
                ) : (
                    <GroupIcon group={group} className={styles.icon} />
                )}
            </label>
        </div>
    );
};

export default IconInput;
