import strings from "../localization/strings";
import { NotificationChannel } from "../openapi/backend";

export function getStringForNotificationType(type: string) {
    switch (type) {
        case NotificationChannel.Email:
            return strings.emailShort;
        case NotificationChannel.PushNotification:
            return strings.pushnotification;
        default:
            return strings.unknown;
    }
}
