import { useCallback, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { TextModal } from "../components/core/Modal/Modal";
import TextButton from "../components/core/TextButton/TextButton";
import { ProfileContext } from "../contexts/ProfileContext";
import strings from "../localization/strings";
import { canViewBO } from "./Permissions";
import { useSignout } from "./useSignout";

const INACTIVITY_TIMEOUT = 1000 * 60 * 15; // 15 minutes
const WARNING_WINDOW = 1000 * 60 * 10; // 1 minute

const IdleGuard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { profile } = useContext(ProfileContext);
    const signout = useSignout();
    const { resume, pause, activate, isLeader } = useIdleTimer({
        timeout: INACTIVITY_TIMEOUT, // - WARNING_WINDOW,
        promptTimeout: WARNING_WINDOW,
        onIdle: () => {
            if (isLeader()) {
                signout();
            }
        },
        onPrompt: () => {
            setIsOpen(true);
        },
        crossTab: true,
        syncTimers: 5,
        leaderElection: true,
        onActive: () => {
            setIsOpen(false);
        },
    });

    const handleModalDismiss = useCallback(() => {
        activate();
    }, [activate]);

    useEffect(() => {
        if (!profile || !canViewBO(profile)) {
            activate();
            pause();
        } else {
            resume();
        }
    }, [profile, resume, pause, activate]);

    return (
        <TextModal
            title={strings.inactivityTitle}
            isOpen={isOpen}
            onDismiss={handleModalDismiss}
            text={strings.inactivity}
            Buttons={<TextButton text={strings.close} onClick={handleModalDismiss} />}
        />
    );
};

export default IdleGuard;
