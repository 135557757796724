import AccountsTable from "./AccountsTable";
import { useDownloadBackofficeUsers, UserFilter, useUsers } from "../../hooks/UserHooks";
import { Role, SortOrder, TagType, UserOrderBy } from "../../openapi/backend";
import { Column } from "react-table";
import strings from "../../localization/strings";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import { rolesToString } from "../../utils.ts/GetRole";
import { EditAccountModal } from "../Modals/EditAccountModal";
import { NamedOptionsFilter, PageNumberFilter, StringFilter, useFilters } from "../../hooks/UseSearchParam";
import { BackofficeUserFilterBar } from "../core/FilterBar/UserFilterBars";
import { TagsContext } from "../../contexts/TagsContext";
import { NavigationTab } from "../../types/NavigationTab";
import { UserAccountsHeader } from "./AccountsHeader";
import { getName } from "../../utils.ts/GetName";

interface BackofficeUserItem {
    Id: string;
    Name: ReactNode;
    Profession: string;
    HappeningGroup: string;
    PhoneNumber: string;
    Email: string;
    Role: string;
}

export default function useBackofficeAccountsTab(): NavigationTab {
    const [backofficeUsersState, getBackofficeUsers] = useUsers();
    const [, downloadBackofficeUsers] = useDownloadBackofficeUsers();

    // Filter
    const tags = useContext(TagsContext).allTags;
    const filterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            happeningGroups: new NamedOptionsFilter(tags.happeningGroups),
            page: new PageNumberFilter(),
        }),
        [tags],
    );
    const [rawFilter, setFilter] = useFilters(filterSpecs);
    const filter = rawFilter as UserFilter;
    const onFilterChange = useCallback(
        (filter: UserFilter) => {
            setFilter({ ...filter, page: 1 });
        },
        [setFilter],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilter({ ...filter, page: p });
        },
        [filter, setFilter],
    );

    // Header
    const callbackDownload = useCallback(() => downloadBackofficeUsers(filter), [filter, downloadBackofficeUsers]);

    const header = <UserAccountsHeader createUserTitle={strings.addAccount} downloadUsers={callbackDownload} />;

    // Element
    const filterBar = <BackofficeUserFilterBar onChange={onFilterChange} value={filter} />;

    const columns: Column<BackofficeUserItem>[] = [
        {
            Header: strings.name,
            accessor: "Name",
            width: "20%",
        },
        {
            Header: strings.function,
            accessor: "Profession",
            width: "15%",
        },
        {
            Header: strings.happeningGroup,
            accessor: "HappeningGroup",
            width: "20%",
        },
        {
            Header: strings.phone,
            accessor: "PhoneNumber",
            width: "15%",
        },
        {
            Header: strings.email,
            accessor: "Email",
            width: "15%",
        },
        {
            Header: strings.role,
            accessor: "Role",
        },
    ];

    const data = useMemo<BackofficeUserItem[]>(() => {
        return (
            backofficeUsersState.value?.items.map((user) => ({
                Id: user.id,
                Name: getName(user),
                Profession: user.profession ?? strings.unknownProfession,
                HappeningGroup:
                    user.tags.filter((t) => t.type === TagType.HappeningGroup).length > 0
                        ? user.tags
                              .filter((t) => t.type === TagType.HappeningGroup)
                              .map((t) => t.name)
                              .join(", ")
                        : strings.unknownHappeningGroup,
                PhoneNumber: user.phoneNumber ?? strings.unknownPhoneNumber,
                Email: user.email,
                Role: rolesToString(user.roles),
            })) ?? []
        );
    }, [backofficeUsersState]);

    const callbackUsers = useCallback(
        (orderBy: UserOrderBy, sortOrder: SortOrder, itemsPerPage: number) =>
            getBackofficeUsers(
                orderBy,
                sortOrder,
                itemsPerPage,
                [
                    Role.UserAdmin,
                    Role.ApplicationAdmin,
                    Role.ChiefEditor,
                    Role.Editor,
                    Role.Marketing,
                    Role.Approver,
                    Role.ChiefIntermediary,
                    Role.Intermediary,
                ],
                filter,
            ),
        [getBackofficeUsers, filter],
    );

    const element = (
        <AccountsTable
            columns={columns}
            data={data}
            meta={backofficeUsersState.value?.meta}
            page={filter.page}
            setPage={setPage}
            getUsers={callbackUsers}
            error={backofficeUsersState.error}
            filterBar={filterBar}
            AccountModal={EditAccountModal}
        />
    );

    return {
        pathname: "accounts",
        label: strings.colleagues,
        element,
        header,
    };
}
