/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum NotificationGroup {
    Participants = "Participants",
    Partners = "Partners",
    Editors = "Editors",
    OrganizationAccounts = "OrganizationAccounts",
    ApplicationAdmins = "ApplicationAdmins",
    UserAdmins = "UserAdmins",
    Marketing = "Marketing",
    Approvers = "Approvers",
    Intermediaries = "Intermediaries",
}

export function NotificationGroupFromJSON(json: any): NotificationGroup {
    return NotificationGroupFromJSONTyped(json, false);
}

export function NotificationGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationGroup {
    return json as NotificationGroup;
}

export function NotificationGroupToJSON(value?: NotificationGroup | null): any {
    return value as any;
}
