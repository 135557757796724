import { ComponentType, ReactNode, useCallback, useEffect, useState } from "react";
import strings from "../../localization/strings";
import Table from "../core/Table/Table";
import { PaginationMeta, SortOrder, UserOrderBy } from "../../openapi/backend";
import { Column } from "react-table";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import { AccountModalProps } from "../Modals/EditAccountModal";
import { ProfileModalProps } from "../Modals/ProfileModal";

interface UserItem {
    Id: string;
}

export interface AccountsTableProps<T extends UserItem> {
    columns: Column<T>[];
    data: T[];
    page?: number;
    setPage: (page: number) => void;
    meta?: PaginationMeta;
    getUsers: (orderBy: UserOrderBy, sortOrder: SortOrder, itemsPerPage: number, page?: number) => void;
    error?: Response;
    filterBar: ReactNode;
    AccountModal: ComponentType<AccountModalProps & ProfileModalProps>;
}

export default function AccountsTable<T extends UserItem>({
    columns,
    data,
    page,
    setPage,
    meta,
    getUsers,
    error,
    filterBar,
    AccountModal,
}: AccountsTableProps<T>) {
    const [orderBy, setOrderBy] = useState(UserOrderBy.Default);
    const [sortOrder, setSortOrder] = useState(SortOrder.Ascending);
    const [itemsPerPage] = useState(10);

    const loadUsers = useCallback(
        () => getUsers(orderBy, sortOrder, itemsPerPage),
        [getUsers, orderBy, sortOrder, itemsPerPage],
    );

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const handleSort = useCallback(
        (sortBy) => {
            // We only sort on 1 column so only take the first element in sortBy
            if (sortBy[0]) {
                setOrderBy(sortBy[0].id);
                setSortOrder(sortBy[0].desc ? SortOrder.Ascending : SortOrder.Descending);
            } else {
                // If no sorting is applied, revert back to default sorting
                setOrderBy(UserOrderBy.Default);
                setSortOrder(SortOrder.Ascending);
            }
        },
        [setOrderBy, setSortOrder],
    );

    const [selectedUser, setSelectedUser] = useState<string>();
    const onCloseModal = useCallback(() => {
        setSelectedUser(undefined);
        loadUsers();
    }, [setSelectedUser, loadUsers]);

    return (
        <>
            <div>
                {filterBar}

                {error && <ErrorMessage error={error} />}

                {!error && (
                    <Table
                        columns={columns}
                        data={data}
                        emptyString={strings.noUsersFound}
                        setPage={setPage}
                        paginationMeta={meta}
                        onSort={handleSort}
                        onRowClick={(user: T) => {
                            setSelectedUser(user.Id);
                        }}
                        forcePage={page}
                    />
                )}
            </div>
            <AccountModal selectedUserId={selectedUser} onSelectProfile={setSelectedUser} onClose={onCloseModal} />
        </>
    );
}
