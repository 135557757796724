import { Outlet } from "react-router-dom";
import Header from "../core/Header/Header";
import NavigationMenu from "../core/Navigation/NavigationMenu/NavigationMenu";
import styles from "./Layout.module.scss";

export default function Layout() {
    return (
        <div className={styles.layout}>
            <NavigationMenu className={styles.nav} />
            <div className={styles.innerLayout}>
                <Header />
                <main className={styles.outlet}>
                    <Outlet />
                </main>
            </div>
        </div>
    );
}
