import { Column } from "react-table";
import strings from "../../localization/strings";
import { ReactNode, useMemo } from "react";

import OrganisationInfoTable from "./OrganisationInfoTable";
import { useGetOrganisationInfoOverview } from "../../hooks/OrganisationInfoHooks";
import FilterInclusionLabel from "../core/FilterInclusionLabel/FilterInclusionLabel";

interface OrganisationInfoItem {
    Id: string;
    Title: string;
    Organisation: string;
    Category: string;
    IsActive: ReactNode;
}

export default function OrganisationInfoContainer() {
    const [overviewState, getOverview] = useGetOrganisationInfoOverview();

    const columns: Column<OrganisationInfoItem>[] = [
        {
            Header: strings.title,
            accessor: "Title",
            width: "30%",
        },
        {
            Header: strings.happeningGroup,
            accessor: "Organisation",
            width: "30%",
        },
        {
            Header: strings.category,
            accessor: "Category",
            width: "25%",
        },
        {
            Header: strings.state,
            accessor: "IsActive",
            width: "15%",
        },
    ];

    const data = useMemo<OrganisationInfoItem[]>(() => {
        return (
            overviewState.value?.items.map((item) => ({
                Id: item.id,
                Title: item.title,
                Organisation: item.organisationTag?.detailedName ?? "-",
                Category: item.category?.detailedName ?? "-",
                IsActive: (
                    <FilterInclusionLabel
                        value={item.isActive}
                        withLabel={strings.enumActive}
                        withoutLabel={strings.enumInactive}
                    />
                ),
            })) ?? []
        );
    }, [overviewState]);

    return (
        <OrganisationInfoTable
            columns={columns}
            data={data}
            meta={overviewState.value?.meta}
            getItems={getOverview}
            error={overviewState.error}
        />
    );
}
