import strings from "../localization/strings";
import { QuestionType } from "../openapi/backend";

export function getStringForQuestionType(type: QuestionType | undefined): string {
    switch (type) {
        case QuestionType.Numeric:
            return strings.formIndicatorQuestionTypeNumeric;
        case QuestionType.MultipleChoice:
            return strings.formIndicatorQuestionTypeMultipleChoice;
        case QuestionType.Open:
            return strings.formIndicatorQuestionTypeOpen;
        default:
            return strings.unknown;
    }
}
