import cx from "classnames";
import React, { useCallback, useState } from "react";
import strings from "../../../localization/strings";
import { ImageViewModel, MediaCategoryViewModel } from "../../../openapi/backend";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import Accordion from "../Accordion/Accordion";
import MediaLibraryImageUploadInputContainer from "../ImageUploadInput/MediaLibraryImageUploadInputContainer";
import SearchInput from "../Inputs/SearchInput/SearchInput";
import MediaCategoryListItem from "../MediaCategoryListItem/MediaCategoryListItem";
import { MediaDetailsModal } from "../MediaDetails/MediaDetailsModal";
import styles from "./MediaLibraryManagement.module.scss";

export type Props = {
    libraryContent: MediaCategoryViewModel[];
    query: string;
    setQuery: (query: string) => void;
};

export const MediaLibraryManagement = ({ libraryContent, query, setQuery }: Props) => {
    const [viewMedia, setViewMedia] = useState<number | undefined>();

    const onDismissViewMedia = useCallback(() => setViewMedia(undefined), []);
    const onMediaClick = useCallback((media: ImageViewModel) => {
        setViewMedia(media.id);
    }, []);

    return (
        <>
            <FormPageLayout className={styles.container} title={strings.mediaLibraryManagementPageTitle}>
                <Accordion collapsible={false} className={styles.content} title="">
                    <div className={styles.actionsContainer}>
                        <div className={styles.uploadImageContainer}>
                            <h2 className={styles.addHeader}>{strings.mediaLibraryAddImageHeader}</h2>
                            <MediaLibraryImageUploadInputContainer />
                        </div>
                        <div className={styles.searchContainer}>
                            <h2 className={styles.addHeader}>{strings.searchHeader}</h2>
                            <SearchInput
                                value={query}
                                onSearch={setQuery}
                                placeholder={strings.searchMediaPlaceholder}
                                autoFocus={true}
                                activateOnBlur={true}
                            />
                        </div>
                    </div>
                    {query && (
                        <h2 className={styles.searchResultsHeader}>
                            {strings.formatString(strings.searchMediaResultsHeader, query)}
                        </h2>
                    )}
                    <div className={cx(styles.separator, styles.dashed)} />
                    <div className={cx(styles.categoryContainer)}>
                        {libraryContent.map((category, index) => {
                            return (
                                <MediaCategoryListItem
                                    key={category.id}
                                    className={styles.category}
                                    category={category}
                                    onMediaClick={onMediaClick}
                                    defaultExpanded={index === 0}
                                />
                            );
                        })}
                    </div>
                </Accordion>
            </FormPageLayout>
            {viewMedia !== undefined && <MediaDetailsModal mediaId={viewMedia} onClose={onDismissViewMedia} />}
        </>
    );
};
