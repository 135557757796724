import produce from "immer";
import { MEDIA_LIBRARY_MY_CATEGORY_ID, MEDIA_LIBRARY_UNCATEGORIZED_ID } from "../constants/MediaLibraryConstants";
import { MediaCategoryViewModel, MediaViewModel } from "../openapi/backend";

export type MediaLibraryState = {
    content: MediaCategoryViewModel[];
    shouldFetch: boolean;
    query: string;
};

export type Purpose = "library" | "management";

export function getInitialState(): MediaLibraryState {
    return { content: [], shouldFetch: true, query: "" };
}

export enum ActionType {
    clear,
    set,
    add,
    update,
    removeFromMy,
    removeFromLibrary,
    search,
}

interface ClearAction {
    type: ActionType.clear;
}

interface SetAction {
    type: ActionType.set;
    value: MediaCategoryViewModel[];
    purpose: Purpose;
}

interface AddAction {
    type: ActionType.add;
    value: MediaViewModel;
}

interface UpdateAction {
    type: ActionType.update;
    value: MediaViewModel;
}

interface RemoveFromMyAction {
    type: ActionType.removeFromMy;
    value: MediaViewModel;
}

interface RemoveFromLibraryAction {
    type: ActionType.removeFromLibrary;
    value: MediaViewModel;
}

interface SearchAction {
    type: ActionType.search;
    value: string;
}

export type MediaLibraryAction =
    | ClearAction
    | SetAction
    | AddAction
    | UpdateAction
    | RemoveFromMyAction
    | RemoveFromLibraryAction
    | SearchAction;

export const MediaLibraryReducer = (state: MediaLibraryState, action: MediaLibraryAction): MediaLibraryState => {
    switch (action.type) {
        case ActionType.clear:
            return produce(state, (draft: MediaLibraryState) => {
                draft.content = [];
                draft.shouldFetch = false;
            });
        case ActionType.set:
            return produce(state, (draft: MediaLibraryState) => {
                switch (action.purpose) {
                    case "management":
                        draft.content = action.value
                            .filter((c) => c.id !== MEDIA_LIBRARY_MY_CATEGORY_ID)
                            .sort((a) => (a.id === MEDIA_LIBRARY_UNCATEGORIZED_ID ? -1 : 0));
                        break;
                    case "library":
                    default:
                        draft.content = action.value;
                        break;
                }

                draft.shouldFetch = false;
            });
        case ActionType.add:
            // @NOTE(Lejun): This requires too many logics because of possibly adding the image to a category we don't have locally
            // or when a query is applied to the content.
            // For now we will just refresh the full library with the SetAction
            return produce(state, (draft: MediaLibraryState) => {
                draft.shouldFetch = true;
            });
        case ActionType.update:
            // @NOTE(Lejun): This requires too many logics because of possibly moving the image to another category
            // or when a query is applied to the content.
            // For now we will just refresh the full library with the SetAction
            return produce(state, (draft: MediaLibraryState) => {
                draft.shouldFetch = true;
            });
        case ActionType.removeFromLibrary:
            return produce(state, (draft: MediaLibraryState) => {
                draft.shouldFetch = true;
            });
        case ActionType.removeFromMy:
            return produce(state, (draft: MediaLibraryState) => {
                const myCategory = draft.content.find((c) => c.id === MEDIA_LIBRARY_MY_CATEGORY_ID);
                const imageIndexToRemove = myCategory?.media?.findIndex((m) => m.id === action.value.id) ?? -1;
                if (imageIndexToRemove >= 0) {
                    myCategory?.media?.splice(imageIndexToRemove, 1);
                }
            });
        case ActionType.search:
            return produce(state, (draft: MediaLibraryState) => {
                draft.shouldFetch = true;
                draft.query = action.value;
            });
        default:
            return state;
    }
};
