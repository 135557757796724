import strings from "../localization/strings";
import { Responsibilities } from "../openapi/backend";

export function getResponsibilityLabel(responsibility: Responsibilities) {
    switch (responsibility) {
        case Responsibilities.Development:
            return strings.enumResponsibilitiesDevelopment;
        case Responsibilities.Financing:
            return strings.enumResponsibilitiesFinancing;
        case Responsibilities.Support:
            return strings.enumResponsibilitiesSupport;
        case Responsibilities.Execution:
            return strings.enumResponsibilitiesExecution;
        case Responsibilities.Warrant:
            return strings.enumResponsibilitiesWarrant;
        default:
            return strings.unknown;
    }
}

export function getLocalizedResponsibilityLabels(responsibilities: Responsibilities[]) {
    return responsibilities.map(getResponsibilityLabel).join(", ");
}
