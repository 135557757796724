import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";
import SelectField, { SelectFieldProps } from "../../Inputs/SelectField/SelectField";
import { HappeningState } from "../../../../openapi/backend";

interface FormSelectProps<TValue, TForm extends FieldValues, TName extends FieldName<TForm, TValue>>
    extends SelectFieldProps<TValue> {
    label?: string;
    name: TName;
    control: Control<TForm>;
    errors?: FieldError | FieldError[];
    value?: TValue;
}

export default function FormSelect<TValue, TForm extends FieldValues, TName extends FieldName<TForm, TValue>>({
    name,
    label,
    errors,
    control,
    required,
    ...fieldProps
}: FormSelectProps<TValue, TForm, TName>) {
    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{
                    required,
                }}
                render={({ field: { ref, ...field } }) => <SelectField {...fieldProps} {...field} />}
            />
        </FormFieldContainer>
    );
}

export const FormNumberSelect = <TForm extends FieldValues, TName extends FieldName<TForm, number>>(
    props: Omit<FormSelectProps<number, TForm, TName>, "transform">,
) => <FormSelect {...props} transform={Number} />;
export const FormStringSelect = <TForm extends FieldValues, TName extends FieldName<TForm, string>>(
    props: Omit<FormSelectProps<string, TForm, TName>, "transform">,
) => <FormSelect {...props} transform={(val) => val} />;
export const FormBooleanSelect = <TForm extends FieldValues, TName extends FieldName<TForm, boolean>>(
    props: Omit<FormSelectProps<boolean, TForm, TName>, "transform">,
) => <FormSelect {...props} transform={(val) => val === "true"} />;
export const FormHappeningStateSelect = <TForm extends FieldValues, TName extends FieldName<TForm, HappeningState>>(
    props: Omit<FormSelectProps<HappeningState, TForm, TName>, "transform">,
) => <FormSelect {...props} transform={(val) => val as HappeningState} />;
