import { useEffect } from "react";
import { useCancelAppointment } from "../../../hooks/AppointmentHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import UnsubscribeParticipantReasonModal from "./UnsubscribeParticipantReasonModal";

interface Props {
    happeningId: string;
    appointmentId: number;
    onSuccess: () => void;
    onDismiss: () => void;
}
export default function CancelAppointmentModal({ happeningId, appointmentId, onSuccess, onDismiss }: Props) {
    const [{ state, error }, cancel] = useCancelAppointment(happeningId, appointmentId);

    useEffect(() => {
        if (state === RequestState.DONE) {
            onDismiss();
            onSuccess();
        }
    }, [state, onDismiss, onSuccess]);

    return (
        <UnsubscribeParticipantReasonModal
            isOpen={true}
            onUnsubscribe={cancel}
            onDismiss={onDismiss}
            isLoading={state === RequestState.LOADING}
            error={error}
            titleLabel={strings.cancelAppointment}
            confirmLabel={strings.cancelAppointment}
            reasonLabel={strings.cancelAppointmentReasonDescription}
        />
    );
}
