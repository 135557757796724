import { useState, ButtonHTMLAttributes, PropsWithChildren } from "react";
import InlineLoading from "../InlineLoading/InlineLoading";
import styles from "./DropdownButton.module.scss";
import ChevronDown from "../../../assets/chevron_down_white.svg";
import ChevronUp from "../../../assets/chevron_up_white.svg";
import strings from "../../../localization/strings";
import { CrossButton } from "../CrossButton/CrossButton";

export interface DropdownButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    isLoading: boolean;
    altText?: string;
    dropdownTitle?: string;
}

export const DropdownButton = ({
    text,
    isLoading,
    altText,
    dropdownTitle,
    onClick,
    children,
}: PropsWithChildren<DropdownButtonProps>) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.button}>
            <button type={"button"} className={styles.internalButton} onClick={onClick}>
                {!isLoading && text}
                {isLoading && <InlineLoading className={styles.loading} />}
            </button>

            <div className={styles.divider} />
            <button className={styles.internalButton} type={"button"} onClick={() => setIsOpen(!isOpen)}>
                <img src={isOpen ? ChevronUp : ChevronDown} alt={altText} />
            </button>

            {isOpen && (
                <div className={styles.floatingContainer}>
                    <div className={styles.title}>
                        {dropdownTitle}
                        <CrossButton onClick={() => setIsOpen(false)} aria-label={strings.close} />
                    </div>
                    {children}
                </div>
            )}
        </div>
    );
};
