import React, { useCallback } from "react";
import strings from "../../../localization/strings";
import Modal from "../../core/Modal/Modal";
import TextButton from "../../core/TextButton/TextButton";
import CreateRegistrationRule from "../../Forms/RegistrationRules/CreateRegistrationRuleForm";

export const RegistrationRulesInfoHeader = () => {
    const [showModal, setShowModal] = React.useState(false);
    const open = useCallback(() => setShowModal(true), []);
    const close = useCallback(() => setShowModal(false), []);

    return (
        <header>
            <Modal title={strings.createRegistrationRule} isOpen={showModal} onClose={close}>
                <CreateRegistrationRule onDismiss={close} />
            </Modal>
            <TextButton text={strings.newRegistrationRule} onClick={open} />
        </header>
    );
};
