import { useCallback, useMemo } from "react";
import { MeasurementsFilter } from "../../../hooks/MeasurementHooks";
import { useOrganiserOptions } from "../../../hooks/TagHooks";
import { useMemberOptions } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import { TagViewModel } from "../../../openapi/backend";
import { MeasurementsStateOptions } from "../../../types/DropdownOption";
import { MeasurementFilterKeys } from "../../../types/MeasurementFilterKeys";
import { UserNamedType } from "../../../types/UserNamedType";
import { getName } from "../../../utils.ts/GetName";
import { statusToString } from "../../../utils.ts/GetStatus";
import { getMeasurementsStateOptions } from "../../../utils.ts/StateOptions";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import IconTagOption from "../IconTagOption/IconTagOption";
import DatePicker from "../Inputs/DatePicker/DatePicker";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import StateLabel from "../StateLabel/StateLabel";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import styles from "./FilterBar.module.scss";
import QueryFilterInput from "./QueryFilterInput";

type MeasurementsFilterBarProps = FilterBarPropsWithValue<MeasurementsFilter> & {
    exclude?: MeasurementFilterKeys;
};
const stateOptions = getMeasurementsStateOptions();

export default function MeasurementsFilterBar({ value: filters, onChange, exclude }: MeasurementsFilterBarProps) {
    const organisationsOptions = useOrganiserOptions();
    const { value: measurers } = useMemberOptions();

    const measurerOptions: UserNamedType[] = useMemo(
        () => (measurers ? measurers.map((member) => ({ ...member, name: getName(member) })) : []),
        [measurers],
    );

    const defaultFilters = useMemo(() => {
        return {};
    }, []);

    const onReset = () => {
        onChange(defaultFilters);
    };

    const onChangeStatuses = useCallback(
        (filter: MeasurementsStateOptions[]) => onChange({ ...filters, statuses: filter }),
        [onChange, filters],
    );

    const onChangeOrganisations = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, organisations: filter }),
        [onChange, filters],
    );

    const onChangeMeasurer = useCallback(
        (filter: UserNamedType[]) =>
            onChange({
                ...filters,
                measurers: filter,
            }),
        [onChange, filters],
    );

    const onChangeDate = useCallback(
        (date: Date | null) => {
            date?.setHours(23, 59, 59); // toDate has to be at midnight to include activities on that day
            onChange({ ...filters, date: date || undefined });
        },
        [onChange, filters],
    );

    const shouldShowQuery = useMemo(() => !exclude?.includes("query"), [exclude]);
    const shouldShowDate = useMemo(() => !exclude?.includes("date"), [exclude]);
    const shouldShowOrganisations = useMemo(() => !exclude?.includes("organisations"), [exclude]);
    const shouldShowMeasurers = useMemo(() => !exclude?.includes("measurers"), [exclude]);
    const shouldShowStatuses = useMemo(() => !exclude?.includes("statuses"), [exclude]);

    return (
        <FilterBar onReset={onReset}>
            {shouldShowQuery && <QueryFilterInput filters={filters} onChange={onChange} />}

            {shouldShowDate && (
                <div className={styles.dateFilter}>
                    <DatePicker
                        startDate={filters.date}
                        onChange={onChangeDate}
                        className={styles.datePicker}
                        placeholderText={strings.date}
                        selected={filters.date}
                    />
                </div>
            )}

            {shouldShowOrganisations && (
                <MultiselectDropdown
                    options={organisationsOptions}
                    optionToKey={(option) => option.id}
                    renderOption={(option) => <IconTagOption tag={option} />}
                    placeholder={strings.happeningGroup}
                    name={"organisationsFilterMeasurements"}
                    className={styles.filter}
                    onItemSelect={onChangeOrganisations}
                    value={filters.organisations}
                    searchable
                    searchPlaceholder={strings.searchByOrganisation}
                />
            )}

            {shouldShowMeasurers && (
                <MultiselectDropdown
                    options={measurerOptions}
                    renderOption={(option) => (
                        <div className={styles.group}>
                            <ProfileIcon profile={option} />
                            <span>{option.name}</span>
                        </div>
                    )}
                    placeholder={strings.measurer}
                    name={"measurersFilterMeasurements"}
                    className={styles.filter}
                    onItemSelect={onChangeMeasurer}
                    value={filters.measurers}
                    searchable
                    searchPlaceholder={strings.searchByMeasurerName}
                />
            )}

            {shouldShowStatuses && (
                <MultiselectDropdown
                    options={stateOptions}
                    optionToString={(option) => statusToString(option.value)}
                    renderOption={(option) => (
                        <div>
                            <StateLabel state={option.value} />
                        </div>
                    )}
                    placeholder={strings.state}
                    name={"statusesFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeStatuses}
                    value={filters.statuses}
                />
            )}
        </FilterBar>
    );
}
