const getVariable = (name: string): string => {
    const result = process.env[name];

    if (result === null || result === undefined) {
        throw Error(`Could not find required env variable '${name}'`);
    }

    return result;
};

export const REACT_APP_BACKEND_VERSION = getVariable("REACT_APP_BACKEND_VERSION");
export const REACT_APP_BACKEND_URL = getVariable("REACT_APP_BACKEND_URL");
export const REACT_APP_IDENTITY_URL = getVariable("REACT_APP_IDENTITY_URL");
export const REACT_APP_OIDC_AUTHORITY = getVariable("REACT_APP_OIDC_AUTHORITY");
export const REACT_APP_OIDC_CLIENT_ID = getVariable("REACT_APP_OIDC_CLIENT_ID");
export const REACT_APP_HOST = getVariable("REACT_APP_HOST");
export const REACT_APP_GOOGLE_API_KEY = getVariable("REACT_APP_GOOGLE_API_KEY");
export const REACT_APP_BUNDLE_IDENTIFIER = getVariable("REACT_APP_BUNDLE_IDENTIFIER");
export const REACT_APP_FRONTEND_CLIENT_URL = getVariable("REACT_APP_FRONTEND_CLIENT_URL");
export const REACT_APP_ClUSTER_DYNAMIC_APP_URL = getVariable("REACT_APP_ClUSTER_DYNAMIC_APP_URL");
