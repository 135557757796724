import { useEffect } from "react";
import moment from "moment";
import { Control, FieldErrors, UseFormRegister, UseFormWatch, UseFormSetValue } from "react-hook-form";
import styles from "./ApplicationForm.module.scss";
import strings from "../../../localization/strings";
import FormField from "../../core/Form/FormField/FormField";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import { MIN_PRICE_VALUE } from "../../../constants/Validation";
import EuroSymbol from "../../../assets/euro_symbol.svg";
import FormDatepicker from "../../core/Form/FormDatePicker/FormDatePicker";
import { FormType, JscApplicationInput } from "../../../openapi/backend";
import FormCheckbox from "../../core/Form/FormCheckbox/FormCheckbox";

interface ScApplicationFieldsProps {
    register: UseFormRegister<JscApplicationInput>;
    errors: FieldErrors<JscApplicationInput>;
    form: FormType;
    control: Control<JscApplicationInput, object>;
    watch: UseFormWatch<JscApplicationInput>;
    setValue: UseFormSetValue<JscApplicationInput>;
}

export default function ScApplicationFields({
    register,
    errors,
    form,
    control,
    watch,
    setValue,
}: ScApplicationFieldsProps) {
    const membershipStartDate = watch("membershipStartDate");
    const membershipEndDate = watch("membershipEndDate");
    const hasCityPass = watch("hasCityPass");

    useEffect(() => {
        if (!hasCityPass) {
            setValue("cityPassName", undefined);
            setValue("cityPassNumber", undefined);
        }
    }, [hasCityPass, setValue]);

    const isVscApplication = form === FormType.Vsc;

    return (
        <>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.applicationFormHeaderFinancial}</h2>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <FormFieldMultiline
                            rows={4}
                            name={"socialAssistance"}
                            label={strings.applicationFormFinancialStatus}
                            register={register}
                            errors={errors.socialAssistance}
                            required
                        />
                        <FormFieldMultiline
                            rows={4}
                            name={"reasonOfDeficiency"}
                            label={strings.applicationFormReasonOfDeficiency}
                            register={register}
                            errors={errors.reasonOfDeficiency}
                            required
                        />
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.column}>
                        <FormCheckbox
                            name="hasCityPass"
                            label={strings.applicationFormHasCityPass}
                            control={control}
                            errors={errors.hasCityPass}
                        />
                        <FormField
                            name="cityPassName"
                            label={strings.applicationFormCityPassName}
                            register={register}
                            errors={errors.cityPassName}
                            required={hasCityPass}
                            placeholder=""
                            disabled={!hasCityPass}
                        />
                        <FormField
                            name="cityPassNumber"
                            label={strings.applicationFormCityPassNumber}
                            register={register}
                            errors={errors.cityPassNumber}
                            required={hasCityPass}
                            placeholder=""
                            disabled={!hasCityPass}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.applicationFormHeaderSportClub}</h2>
                <div className={styles.row}>
                    <FormField
                        name="sportsClubName"
                        label={strings.applicationFormSportsClubName}
                        register={register}
                        errors={errors.sportsClubName}
                        required
                        placeholder=""
                    />
                    <FormField
                        name="activityCity"
                        label={strings.applicationFormSportsClubCity}
                        register={register}
                        errors={errors.activityCity}
                        required
                        placeholder=""
                    />
                    <FormField
                        name="intendedActivity"
                        label={
                            isVscApplication
                                ? strings.applicationFormSportsClubActivity
                                : strings.applicationFormSportsClubActivityChild
                        }
                        register={register}
                        errors={errors.intendedActivity}
                        required
                        placeholder=""
                    />
                </div>
            </div>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.applicationFormHeaderContribution}</h2>
                <div className={styles.row}>
                    <FormField
                        name="membershipFee"
                        label={strings.applicationFormContributionFee}
                        register={register}
                        errors={errors.membershipFee}
                        required
                        placeholder={isVscApplication ? strings.applicationFormContributionFeePlaceholder : ""}
                        type="number"
                        min={MIN_PRICE_VALUE}
                        icon={EuroSymbol}
                        step={0.01}
                    />
                    <FormDatepicker
                        name="membershipStartDate"
                        title={strings.applicationFormContributionStartDate}
                        control={control}
                        value={membershipStartDate}
                        required
                        errors={errors.membershipStartDate}
                        useUtc={true}
                    />
                    <FormDatepicker
                        name="membershipEndDate"
                        title={strings.applicationFormContributionEndDate}
                        control={control}
                        value={membershipEndDate}
                        minDate={membershipStartDate}
                        maxDate={moment(membershipStartDate).add(1, "years").toDate()}
                        required
                        validationType="max1YearPeriod"
                        errors={errors.membershipEndDate}
                        useUtc={true}
                    />
                </div>
            </div>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.applicationFormHeaderOther}</h2>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <FormField
                            name="additionalRequirements"
                            label={
                                isVscApplication
                                    ? strings.applicationFormOtherNeeds
                                    : strings.applicationFormOtherNeedsChild
                            }
                            register={register}
                            errors={errors.additionalRequirements}
                            placeholder=""
                        />
                        <FormField
                            name="requirementsPrice"
                            label={strings.applicationFormRequiredAmount}
                            register={register}
                            errors={errors.requirementsPrice}
                            placeholder=""
                            type="number"
                            min={0.0}
                            icon={EuroSymbol}
                            step={0.01}
                        />
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.column}>
                        <FormField
                            name="requirementsShop"
                            label={strings.applicationFormStore}
                            register={register}
                            errors={errors.requirementsShop}
                            placeholder=""
                        />
                        <FormField
                            name="requirementsCity"
                            label={strings.applicationFormStoreAddress}
                            register={register}
                            errors={errors.requirementsCity}
                            placeholder=""
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
