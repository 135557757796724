import { useEffect, useMemo, useState } from "react";
import styles from "./AvatarInput.module.scss";
import { Profile } from "../../../../contexts/ProfileContext";
import { Size } from "../../../../types/Size";
import ProfileIcon, { ProfileIconProfile } from "../../AvatarIcon/ProfileIcon";
import strings from "../../../../localization/strings";
import { MAX_IMAGE_SIZE } from "../../../../constants/Validation";
import { acceptImageFileTypes, isAllowedImageFileType } from "../../../../utils.ts/Media";
import { toastError } from "../../../../utils.ts/Toaster";

interface AvatarInputProps {
    profile: Profile;
    name: string;
    onChange?: (file: File, id?: string) => void;
}

const AvatarInput = ({ profile, name, onChange }: AvatarInputProps) => {
    const [value, setValue] = useState<File>();
    const innerProfile = useMemo<ProfileIconProfile>(
        () => ({ ...profile, avatar: value ? { href: URL.createObjectURL(value) } : profile.avatar }),
        [value, profile],
    );

    const [error, setError] = useState(false);

    useEffect(() => {
        if (value && onChange) {
            onChange(value, profile.id);
        }
    }, [value, onChange, profile.id]);

    return (
        <>
            <input
                className={styles.hidden}
                type="file"
                id={name}
                name={name}
                accept={acceptImageFileTypes}
                onChange={(e) => {
                    const file = e.target.files?.item(0);
                    if (file) {
                        if (!isAllowedImageFileType(file)) {
                            toastError(strings.invalidImageTypeError);
                        } else if (file.size <= MAX_IMAGE_SIZE) {
                            setError(false);
                            setValue(e.target.files?.item(0) ?? undefined);
                        } else {
                            setError(true);
                        }
                    }
                }}
            />
            <label className={styles.label} htmlFor={name}>
                <ProfileIcon profile={innerProfile} size={Size.XLARGE} />
                <div className={styles.text}>{strings.update}</div>
            </label>

            {error && <div className={styles.avatarImageError}>{strings.fileSizeTooLarge}</div>}
        </>
    );
};

export default AvatarInput;
