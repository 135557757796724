import cx from "classnames";
import { debounce } from "lodash";
import React, { FocusEventHandler, useCallback, useMemo, useRef, useState } from "react";
import { ApiCallbackReturn, RequestState } from "../../../../hooks/UseApiCall";
import strings from "../../../../localization/strings";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import InputField from "../InputField/InputField";
import styles from "./RemoteSuggestionInput.module.scss";

type SuggestionItem<T> = { displayLabel: string; inputLabel?: string; value: T };
type Value<T> = { query: string; value: T | undefined };

export type Props<T, R> = {
    className?: string;
    callbackHook: ApiCallbackReturn<Array<R>, [string]>;
    onChangeValue: (change: Value<T>) => void;
    value: Value<T>;
    mapSuggestions: (value: R[]) => Array<SuggestionItem<T>>;
    name: string;
    readOnly?: boolean;
};

function focusSearchInputElement(ref: React.RefObject<HTMLDivElement>) {
    (ref.current?.firstChild as HTMLElement | null)?.focus();
}

function focusLiElement(ref: React.RefObject<HTMLUListElement>, index = 0) {
    (ref.current?.children[index] as HTMLElement | null)?.focus();
}

export default function RemoteSuggestionInput<T, R>({
    className,
    value,
    mapSuggestions,
    callbackHook,
    onChangeValue,
    name,
    readOnly,
}: Props<T, R>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const suggestionRef = useRef<HTMLUListElement>(null);
    const [{ value: requestResponse, state, error }, search, reset] = callbackHook;
    const [isFocused, setIsFocused] = useState(false);
    const isLoading = state === RequestState.LOADING;

    const onFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const onBlur = useCallback<FocusEventHandler<HTMLDivElement>>((event) => {
        // Check if the new focused element is a child of the container
        if (containerRef.current?.contains(event.relatedTarget)) {
            return;
        }

        setIsFocused(false);
    }, []);

    const debouncedSearch = useMemo(
        () =>
            debounce(async (input: string) => {
                if (input.length <= 1) {
                    reset();
                    return;
                }

                search(input);
            }, 300),
        [search, reset],
    );

    const suggestions = useMemo(() => {
        if (!requestResponse) {
            return undefined;
        }

        return mapSuggestions(requestResponse);
    }, [mapSuggestions, requestResponse]);

    return (
        <div ref={containerRef} className={cx(styles.container, className)} onFocus={onFocus} onBlur={onBlur}>
            <InputField
                value={value.query}
                onChange={(event) => {
                    const query = event.target.value;

                    debouncedSearch(query);
                    onChangeValue({ query, value: query.length === 0 ? undefined : value.value });
                }}
                className={styles.searchField}
                isLoading={isLoading}
                onKeyDown={(e) => {
                    if (e.key === "ArrowDown") {
                        focusLiElement(suggestionRef);
                        e.preventDefault();
                    }
                }}
                name={name}
                readOnly={readOnly}
            />
            {isFocused && suggestions !== undefined && (
                <>
                    {suggestions.length === 0 ? (
                        <div className={styles.noResults} aria-live="polite">
                            {strings.noResultsFound}
                        </div>
                    ) : (
                        <ul ref={suggestionRef} role="listbox" className={styles.suggestions}>
                            {suggestions.map((suggestion, index) => {
                                function triggerChange() {
                                    onChangeValue({
                                        query: suggestion.inputLabel ?? suggestion.displayLabel,
                                        value: suggestion.value,
                                    });
                                    reset();
                                }
                                return (
                                    <li
                                        className={styles.suggestionItem}
                                        key={index}
                                        onClick={triggerChange}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
                                                triggerChange();
                                                e.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            let handled = false;
                                            switch (true) {
                                                case e.key === "ArrowUp" && index === 0:
                                                    focusSearchInputElement(containerRef);
                                                    handled = true;
                                                    break;
                                                case e.key === "ArrowUp":
                                                    focusLiElement(suggestionRef, index - 1);
                                                    handled = true;
                                                    break;
                                                case e.key === "ArrowDown" && index < suggestions.length:
                                                    focusLiElement(suggestionRef, index + 1);
                                                    handled = true;
                                                    break;
                                                case e.key === "Escape":
                                                    (document.activeElement as HTMLElement | null)?.blur();
                                                    handled = true;
                                                    break;
                                            }

                                            if (handled) {
                                                e.preventDefault();
                                            }
                                        }}
                                        role="option"
                                        aria-selected={value.value === suggestion.value}
                                        tabIndex={0}
                                    >
                                        {suggestion.displayLabel}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </>
            )}
            {error && <ErrorMessage error={error} isToast />}
        </div>
    );
}
