import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CancelHappeningInput, useHappeningCancel } from "../../../hooks/HappeningsHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { UpdateTarget } from "../../../openapi/backend";
import { ReasonForCancellation } from "../../../openapi/backend/models/ReasonForCancellation";
import { enumValues } from "../../../utils.ts/Enum";
import getStringForReason from "../../../utils.ts/ReasonForCancellation";
import Form from "../../core/Form/Form/Form";
import FormRadioGroup from "../../core/Form/FormRadioGroup/FormRadioGroup";
import { FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import { FormOption } from "../../core/Inputs/SelectField/SelectField";
import Modal from "../../core/Modal/Modal";
import { HappeningTimeAndDateViewModel } from "../../../openapi/backend";
import { canCancelSession } from "../../../utils.ts/Happening";

type FormInput = Omit<CancelHappeningInput, "id">;

interface ContainerProps {
    isOpen: boolean;
    onDismiss: () => void;
    onSuccess: () => void;
    happeningId: string;
    dateId?: number;
    isRecurrence: boolean;
    dateTimes: HappeningTimeAndDateViewModel[];
}

export interface CancelHappeningModalProps {
    isOpen: boolean;
    onSubmit: (input: Omit<CancelHappeningInput, "id">) => void;
    onDismiss: () => void;
    error?: Response;
    showSeriesCancelOptions: boolean;
    showRecurrenceCancelOptions: boolean;
    isSubmitting: boolean;
}

const cancelOptions: FormOption<string>[] = enumValues(ReasonForCancellation).map((reason) => ({
    label: getStringForReason(reason),
    value: reason,
}));

const CancelHappeningModalContainer = ({
    isOpen,
    onDismiss,
    onSuccess,
    happeningId,
    dateId,
    isRecurrence,
    dateTimes,
}: ContainerProps) => {
    const [{ state, error }, cancelHappening] = useHappeningCancel();
    const showSeriesCancelOptions = !isRecurrence && dateTimes.length > 1 && !!dateId;
    const onPrepareOnSubmit = useCallback(
        (input: Omit<CancelHappeningInput, "id">) => {
            var selectableSessions = dateTimes
                .filter((session) => canCancelSession(session))
                .map((session) => session.id);
            const modifications = {} as Partial<CancelHappeningInput>;
            // Activity is a series
            if (showSeriesCancelOptions) {
                modifications.target = undefined;
                switch (input.target) {
                    case UpdateTarget.This:
                        modifications.dateTimes = [dateId];
                        break;
                    case UpdateTarget.Upcoming:
                        modifications.dateTimes = selectableSessions.slice(selectableSessions.indexOf(dateId));
                        break;
                    case UpdateTarget.All:
                        modifications.dateTimes = selectableSessions;
                        break;
                }
            }

            return { ...input, ...modifications };
        },
        [dateTimes, dateId, showSeriesCancelOptions],
    );

    const onSubmit = useCallback(
        (input: Omit<CancelHappeningInput, "id">) => {
            const preparedInput = onPrepareOnSubmit(input);
            cancelHappening({ id: happeningId, ...preparedInput });
        },
        [cancelHappening, onPrepareOnSubmit, happeningId],
    );

    useEffect(() => {
        if (state === RequestState.DONE) {
            onDismiss();
            onSuccess();
        }
    }, [state, onDismiss, onSuccess]);

    return (
        <CancelHappeningModal
            isOpen={isOpen}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            error={error}
            showRecurrenceCancelOptions={isRecurrence}
            showSeriesCancelOptions={!isRecurrence && dateTimes.length > 1 && !!dateId}
            isSubmitting={state === RequestState.LOADING}
        />
    );
};

const CancelHappeningModal = ({
    isOpen,
    onSubmit,
    onDismiss,
    error,
    showRecurrenceCancelOptions,
    showSeriesCancelOptions,
    isSubmitting,
}: CancelHappeningModalProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>();

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} title={strings.confirmation}>
            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                submitText={strings.yes}
                cancelText={strings.no}
                waitingForSubmit={isSubmitting}
            >
                <FormStringSelect
                    name={"reasonForCancellation"}
                    options={cancelOptions}
                    label={strings.cancelReason}
                    control={control}
                    errors={errors.reasonForCancellation}
                    placeholder=" "
                    required
                />
                {showRecurrenceCancelOptions && (
                    <FormRadioGroup
                        name={"target"}
                        title={strings.recurrenceUpdateSelect}
                        options={[
                            {
                                label: strings.recurrenceUpdateTargetThis,
                                value: UpdateTarget.This,
                            },
                            {
                                label: strings.recurrenceUpdateTargetUpcoming,
                                value: UpdateTarget.Upcoming,
                            },
                        ]}
                        control={control}
                        required
                        errors={errors.target}
                    />
                )}
                {showSeriesCancelOptions && (
                    <FormRadioGroup
                        name={"target"}
                        title={strings.seriesCancelSelect}
                        options={[
                            {
                                label: strings.seriesCancelTargetThis,
                                value: UpdateTarget.This,
                            },
                            {
                                label: strings.seriesCancelTargetUpcoming,
                                value: UpdateTarget.Upcoming,
                            },
                            {
                                label: strings.seriesCancelTargetAll,
                                value: UpdateTarget.All,
                            },
                        ]}
                        control={control}
                        required
                        errors={errors.target}
                    />
                )}
            </Form>
        </Modal>
    );
};

export default CancelHappeningModalContainer;
