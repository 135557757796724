import { IndicatorInputModel } from "../models/IndicatorModels";
import { IndicatorOutput, IndicatorStatus, QuestionType } from "../openapi/backend";

export function getDefaultValuesFromIndicator(indicator: IndicatorOutput | undefined, isDuplication: boolean = false) {
    if (!indicator) {
        return undefined;
    }

    const values = {
        ...indicator,
        measurers: indicator.measurers.map((m) => m.id),
        questions: indicator.questions.map((q) => ({
            ...q,
            isMultipleChoice: q.type === QuestionType.MultipleChoice,
        })),
        organisationId: indicator.organisation?.id,
        organizerId: indicator.organizer?.id,
        happeningInput: {
            query: indicator.happening?.title || "",
            value: indicator.happening?.id,
        },
        canAddCollaborators: indicator.canAddCollaborators,
        canAddContributors: indicator.canAddContributors,
    } as IndicatorInputModel;

    // Cleanup some object properties when duplicating
    if (isDuplication) {
        delete (values as any).id;
        delete (values as any).status;
        values.questions = values.questions.map((q) => {
            delete q.id;
            return q;
        });
    }

    return values;
}

export function isActiveIndicatorState(state: IndicatorStatus) {
    return [IndicatorStatus.Open, IndicatorStatus.Archived].includes(state);
}
