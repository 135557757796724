import { Dispatch, SetStateAction } from "react";
import { FieldError } from "react-hook-form";
import FormFieldContainer from "../Form/FormFieldContainer/FormFieldContainer";
import DateRangePicker, { DateRange } from "./DateRangePicker";

interface DateRangePickerContainerProps {
    dateRange: DateRange;
    setDateRange: Dispatch<SetStateAction<DateRange>>;
    error?: { startDate?: FieldError; endDate?: FieldError };
}

export default function DateRangePickerContainer({
    dateRange,
    setDateRange: setDateTimes,
    error,
}: DateRangePickerContainerProps) {
    return (
        <FormFieldContainer name={"dateRange"} errors={error && (error.startDate || error.endDate)}>
            <DateRangePicker dateRange={dateRange} setDateRange={setDateTimes} />
        </FormFieldContainer>
    );
}
