/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum OptionalBoolean {
    Unknown = "Unknown",
    True = "True",
    False = "False",
}

export function OptionalBooleanFromJSON(json: any): OptionalBoolean {
    return OptionalBooleanFromJSONTyped(json, false);
}

export function OptionalBooleanFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalBoolean {
    return json as OptionalBoolean;
}

export function OptionalBooleanToJSON(value?: OptionalBoolean | null): any {
    return value as any;
}
