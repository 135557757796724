import { Control, Controller, FieldError, FieldValues, UseFormSetValue } from "react-hook-form";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";
import styles from "./FormResponsibilityListInput.module.scss";
import MultiselectDropdown from "../../Inputs/MultiselectDropdown/MultiselectDropdown";
import { HappeningTagInput, Responsibilities } from "../../../../openapi/backend";
import { getResponsibilityOptions } from "../../../../utils.ts/StateOptions";
import { getResponsibilityLabel } from "../../../../utils.ts/GetResponsibilitiesLabel";
import { hasValues } from "../../../../utils.ts/Array";
import strings from "../../../../localization/strings";
import { useCallback, useEffect, useMemo, useState } from "react";
import DataListInput from "../../Inputs/DataListInput/DataListInput";
import { QuickAddButton, RemoveButton } from "../../RoundButton/RoundButton";
import CheckboxInput from "../../Inputs/CheckboxInput/CheckboxInput";

const options = getResponsibilityOptions();

interface FormResponsibilityProps<
    TValue extends Array<HappeningTagInput>,
    TForm extends FieldValues,
    TName extends FieldName<TForm, TValue>,
> {
    title?: string;
    name: TName;
    control: Control<TForm>;
    errors?: Array<{ responsibilities?: FieldError[] }>;
    value?: TValue;
    setValue: UseFormSetValue<TForm>;
    partnerOptions: Array<{ label: string; value: string }>;
}

type InnerType = Partial<HappeningTagInput>;

export default function FormResponsibilityListInput<
    TValue extends Array<HappeningTagInput>,
    TForm extends FieldValues,
    TName extends FieldName<TForm, TValue>,
>({
    name,
    title = "",
    errors,
    control,
    value,
    setValue,
    partnerOptions,
}: FormResponsibilityProps<TValue, TForm, TName>) {
    const [innerValue, setInnerValue] = useState<InnerType[]>(() => [
        { id: undefined, responsibilities: [], isShownInCommunication: true },
        ...(hasValues(value) ? value : []),
    ]);

    useEffect(() => {
        setValue(name, innerValue as any);
    }, [name, setValue, innerValue]);

    const append = useCallback(
        () =>
            setInnerValue((current) => [
                { id: undefined, responsibilities: [], isShownInCommunication: true },
                ...current.slice(1),
                current[0],
            ]),
        [],
    );
    const validPartnerIds = useMemo(() => innerValue.map((v) => v.id), [innerValue]);

    return (
        <div className={styles.container}>
            <div className={styles.headerRow}>
                <div className={styles.header}>{title}</div>
                <div className={styles.header}>{strings.responsibilityRole}</div>
            </div>
            {innerValue.map((item, index) => (
                <div key={index} className={styles.row}>
                    <DataListInput
                        name={`${name}.${index}.id`}
                        list={`partnerOptions-${index}`}
                        options={partnerOptions.filter(
                            (x) => x.value === item.id || !validPartnerIds.includes(x.value),
                        )}
                        onChange={(selected) => {
                            setInnerValue((current) =>
                                current.map((x, i) => {
                                    const newItem = { ...x };
                                    if (i === index) {
                                        newItem.id = selected;
                                    }
                                    return newItem;
                                }),
                            );
                        }}
                        value={item.id}
                        className={styles.partnerInput}
                    />
                    <FormFieldContainer
                        name={`${name}.${index}.responsibilities`}
                        className={styles.responsibilityField}
                        errors={errors?.at(index)?.responsibilities}
                    >
                        <Controller
                            name={`${name}.${index}.responsibilities` as any}
                            control={control}
                            rules={{
                                required: !!item.id,
                            }}
                            shouldUnregister={true}
                            render={({ field: { ref, value, ...field } }) => {
                                const { responsibilities } = item;

                                return (
                                    <MultiselectDropdown
                                        className={styles.dropdown}
                                        placeholder={""}
                                        options={options}
                                        optionToString={(option) => getResponsibilityLabel(option.value)}
                                        {...field}
                                        onChange={(list) => {
                                            setInnerValue((current) =>
                                                current.map((x, i) =>
                                                    i === index
                                                        ? { ...x, responsibilities: list.map((r) => r.value) }
                                                        : x,
                                                ),
                                            );
                                        }}
                                        value={
                                            hasValues(responsibilities)
                                                ? responsibilities.map((r: Responsibilities) => ({
                                                      name: r,
                                                      value: r,
                                                  }))
                                                : []
                                        }
                                    />
                                );
                            }}
                        />
                    </FormFieldContainer>
                    <div className={styles.actionButton}>
                        {index === 0 ? (
                            <QuickAddButton onClick={append} />
                        ) : (
                            <RemoveButton
                                onClick={() => setInnerValue((current) => current.filter((_, i) => i !== index))}
                            />
                        )}
                    </div>
                    <CheckboxInput
                        name={`${name}.${index}.isShownInCommunication`}
                        label={strings.showInCommunication}
                        className={styles.checkbox}
                        value={item.isShownInCommunication}
                        onChange={(isChecked) => {
                            setInnerValue((current) =>
                                current.map((x, i) => (i === index ? { ...x, isShownInCommunication: isChecked } : x)),
                            );
                        }}
                    />
                </div>
            ))}
        </div>
    );
}
