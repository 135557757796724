import strings from "../../../localization/strings";
import { HappeningViewModel, LocationType, LocationViewModel, TagType } from "../../../openapi/backend";
import HappeningField from "../HappeningField/HappeningField";
import styles from "./HappeningCard.module.scss";
import Map from "../Map/Map";
import { DEFAULT_COLOR } from "../../../constants/Defaults";
import { formatAsPrice } from "../../../utils.ts/Formatting";
import { getLocationTypeString } from "../../../utils.ts/GetLocationTypeString";
import { getDurationString } from "../../../utils.ts/GetDurationString";

interface PrivateHappeningCardProps {
    privateHappening: HappeningViewModel;
}

const Location = ({ location }: { location: LocationViewModel }) => (
    <div className={`${styles.container} ${styles.locationContainer}`}>
        {location.name} <br /> {location.address}
        <Map location={location} className={styles.map} />
    </div>
);

export default function HappeningCard({ privateHappening }: PrivateHappeningCardProps) {
    const {
        maxNumberOfRegistrations,
        happeningGroup,
        location,
        price,
        tags,
        minAge,
        maxAge,
        locationType,
        durationInMinutes,
    } = privateHappening;

    return (
        <div className={styles.happeningCard} style={{ backgroundColor: happeningGroup?.color ?? DEFAULT_COLOR }}>
            <div className={styles.container}>
                <HappeningField
                    title={strings.targetAudience}
                    value={strings.formatString(strings.ageRangeDiscription, minAge, maxAge) as string}
                />
                <HappeningField title={strings.maxRegistrations} value={maxNumberOfRegistrations} />
                <HappeningField title={strings.cost} value={formatAsPrice(price)} />
                {durationInMinutes !== undefined && (
                    <HappeningField title={strings.duration} value={getDurationString(durationInMinutes)} />
                )}
            </div>

            <div className={styles.container}>
                <HappeningField
                    title={strings.happeningTypes}
                    value={
                        tags
                            .filter((x) => x.type === TagType.HappeningType)
                            .map((tag) => tag.name)
                            .join(", ") ?? strings.unknown
                    }
                />
                <HappeningField title={strings.happeningGroup} value={happeningGroup?.detailedName || "-"} />
                {locationType && (
                    <HappeningField title={strings.location} value={getLocationTypeString(locationType)} />
                )}
            </div>

            {locationType === LocationType.OnLocation && location && <Location location={location} />}
        </div>
    );
}
