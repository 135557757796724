import { ProfileOutput, UserOutput } from "../openapi/backend";

export const isUser = (profile: ProfileOutput): profile is UserOutput =>
    profile.isBlocked !== undefined || profile.isBlocked !== null;

export function getAge(birthday: Date) {
    var today = new Date();
    var thisYear = 0;
    if (today.getMonth() < birthday.getMonth()) {
        thisYear = 1;
    } else if (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate()) {
        thisYear = 1;
    }
    var age = today.getFullYear() - birthday.getFullYear() - thisYear;
    return age;
}
