/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    NotificationChannel,
    NotificationChannelFromJSON,
    NotificationChannelFromJSONTyped,
    NotificationChannelToJSON,
} from "./";

/**
 *
 * @export
 * @interface UserNotificationViewModel
 */
export interface UserNotificationViewModel {
    /**
     *
     * @type {number}
     * @memberof UserNotificationViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof UserNotificationViewModel
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof UserNotificationViewModel
     */
    body: string;
    /**
     *
     * @type {NotificationChannel}
     * @memberof UserNotificationViewModel
     */
    channel: NotificationChannel;
    /**
     *
     * @type {string}
     * @memberof UserNotificationViewModel
     */
    link?: string;
    /**
     *
     * @type {boolean}
     * @memberof UserNotificationViewModel
     */
    readByUser: boolean;
    /**
     *
     * @type {Date}
     * @memberof UserNotificationViewModel
     */
    createdOn: Date;
}

/**
 * Check if a given object implements the UserNotificationViewModel interface.
 */
export function instanceOfUserNotificationViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "channel" in value;
    isInstance = isInstance && "readByUser" in value;
    isInstance = isInstance && "createdOn" in value;

    return isInstance;
}

export function UserNotificationViewModelFromJSON(json: any): UserNotificationViewModel {
    return UserNotificationViewModelFromJSONTyped(json, false);
}

export function UserNotificationViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): UserNotificationViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        title: !exists(json, "title") ? (undefined as any) : json["title"],
        body: !exists(json, "body") ? (undefined as any) : json["body"],
        channel: !exists(json, "channel") ? (undefined as any) : NotificationChannelFromJSON(json["channel"]),
        link: !exists(json, "link") ? (undefined as any) : json["link"],
        readByUser: !exists(json, "readByUser") ? (undefined as any) : json["readByUser"],
        createdOn: !exists(json, "createdOn") ? (undefined as any) : new Date(json["createdOn"]),
    };
}

export function UserNotificationViewModelToJSON(value?: UserNotificationViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
