import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import DataListInput, { DataListInputProps } from "../../Inputs/DataListInput/DataListInput";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

export interface FormDataListInputProps<TForm extends FieldValues, TName extends FieldName<TForm, string>>
    extends DataListInputProps {
    label: string;
    name: TName;
    control: Control<TForm>;
    errors?: FieldError;
    containerClassName?: string;
}

export default function FormDataListInput<TForm extends FieldValues, TName extends FieldName<TForm, string>>({
    name,
    label,
    errors,
    control,
    required,
    containerClassName,
    ...fieldProps
}: FormDataListInputProps<TForm, TName>) {
    return (
        <FormFieldContainer className={containerClassName} name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field: { ref, ...field } }) => <DataListInput {...fieldProps} {...field} />}
            />
        </FormFieldContainer>
    );
}
