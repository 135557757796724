import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import moment from "moment";
import strings from "../../../localization/strings";
import { RegistrationRuleViewModel } from "../../../openapi/backend";
import Table from "../../core/Table/Table";
import { RemoveButton } from "../../core/RoundButton/RoundButton";
import { TextModal } from "../../core/Modal/Modal";
import TextButton from "../../core/TextButton/TextButton";
import { useRegistrationRuleDelete } from "../../../hooks/RegistrationRuleHooks";
import { RequestState } from "../../../hooks/UseApiCall";

export type RegistrationRulesTableProps = {
    registrationRules?: Array<RegistrationRuleViewModel>;
    getRegistrationRules: () => void;
};

interface RegistrationRuleItem {
    Rule: ReactNode;
    Remove: ReactNode;
}

export function RegistrationRulesTable({ registrationRules, getRegistrationRules }: RegistrationRulesTableProps) {
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [registrationRuleToDelete, setRegistrationRuleToDelete] = useState(-1);
    const [{ state: deleteRegistrationRuleState }, deleteRegistrationRule] = useRegistrationRuleDelete();

    const handleRemoveRegistrationRule = useCallback(() => {
        deleteRegistrationRule(registrationRuleToDelete);
        setShowConfirmationPopup(false);
    }, [deleteRegistrationRule, registrationRuleToDelete]);

    // Refresh after deleting a registration rule.
    useEffect(() => {
        if (deleteRegistrationRuleState === RequestState.DONE) {
            getRegistrationRules();
        }
    }, [deleteRegistrationRuleState, getRegistrationRules]);

    const columns: Column<RegistrationRuleItem>[] = [
        {
            Header: strings.rule,
            accessor: "Rule",
            width: "100%",
        },
        {
            Header: "",
            accessor: "Remove",
            width: "0%",
        },
    ];

    const data = useMemo<RegistrationRuleItem[]>(() => {
        return (
            registrationRules?.map((rule) => ({
                Rule: (
                    <span>
                        {strings.formatString(
                            strings.registrationRuleRow,
                            <strong>{rule.organisation.detailedName}</strong>,
                            <strong>{moment(rule.startDate).format("DD-MM-YY")}</strong>,
                            <strong>{moment(rule.endDate).format("DD-MM-YY")}</strong>,
                            <strong>{rule.maxFreeRegistrations.toString()}</strong>,
                        )}
                    </span>
                ),
                Remove: (
                    <RemoveButton
                        disabled={deleteRegistrationRuleState === RequestState.LOADING}
                        onClick={() => {
                            setShowConfirmationPopup(true);
                            setRegistrationRuleToDelete(rule.id);
                        }}
                    />
                ),
            })) ?? []
        );
    }, [registrationRules, deleteRegistrationRuleState]);

    return (
        <div>
            <TextModal
                isOpen={showConfirmationPopup}
                onDismiss={() => setShowConfirmationPopup(false)}
                title={strings.confirmation}
                text={strings.deleteRegistrationRuleWarning}
                Buttons={
                    <>
                        <TextButton text={strings.yes} onClick={handleRemoveRegistrationRule} />
                        <TextButton
                            text={strings.no}
                            onClick={() => setShowConfirmationPopup(false)}
                            buttonType="alternate"
                        />
                    </>
                }
            />
            <Table columns={columns} data={data} onRowClick={() => {}} rowSelectable={false} />
        </div>
    );
}
