import { cloneElement, isValidElement, PropsWithChildren, ReactNode, useMemo } from "react";
import styles from "./FloatingMenuButton.module.scss";
import cx from "classnames";

interface Props {
    options: Array<ReactNode>;
    className?: string;
}

export default function FloatingMenuButton({ className, options, children }: PropsWithChildren<Props>) {
    const menuOptions = useMemo<Array<ReactNode>>(() => {
        return options.map((option, index) => {
            return (
                isValidElement(option) &&
                cloneElement(option, {
                    key: index,
                    className: option.props.className
                        ? `${option.props.className} ${styles.menuItem}`
                        : styles.menuItem,
                })
            );
        });
    }, [options]);

    return (
        <div className={cx(styles.container, className)}>
            {children}
            <div className={styles.menu}>{menuOptions}</div>
        </div>
    );
}
