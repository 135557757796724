import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { canEditIndicator } from "../../../authentication/Permissions";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { useGetIndicator, useUpdateIndicator } from "../../../hooks/IndicatorHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { getDefaultValuesFromIndicator } from "../../../utils.ts/IndicatorUtils";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import IndicatorForm from "./IndicatorForm";

type RouteParams = {
    indicatorId: string;
};

export default function UpdateIndicatorForm() {
    const { indicatorId } = useParams<RouteParams>();
    if (!indicatorId) {
        throw new Error("UpdateIndicatorForm: Missing id");
    }

    const { profile } = useContext(ProfileContext);
    const { value: indicator, state, error } = useGetIndicator(parseInt(indicatorId));
    const saveCallback = useUpdateIndicator(parseInt(indicatorId));

    const defaultValues = useMemo(() => {
        return getDefaultValuesFromIndicator(indicator);
    }, [indicator]);

    if ([RequestState.LOADING, RequestState.IDLE].includes(state)) {
        return <InlineLoading />;
    }

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (!indicator) {
        return <div>{strings.indicatorNotFound}</div>;
    }

    return (
        <IndicatorForm
            saveCallback={saveCallback}
            defaultValues={defaultValues}
            statistics={indicator.statistics}
            pageTitle={strings.indicatorFormTitleDetails}
            readOnly={!canEditIndicator(profile)}
        />
    );
}
