import * as yup from "yup";
import { MeasurementInputModel } from "../../../models/IndicatorModels";
import { LinkTarget } from "../../../openapi/backend";
import { answerSchema } from "../../../utils.ts/Validation";

export const conceptValidationSchema = yup.object({});

export const validationSchema: yup.SchemaOf<MeasurementInputModel> = yup.object({
    answers: yup.array(answerSchema),
    notes: yup.string().when("$isNotesRequired", {
        is: true,
        then: (schema) => schema.required(),
    }),
    tagTargetId: yup.string().when("linkTarget", { is: LinkTarget.Partner, then: (schema) => schema.required() }),
    participantInput: yup.object({
        query: yup.string(),
        value: yup.string().when("$linkTarget", {
            is: LinkTarget.Profile,
            then: (schema) => schema.required(),
        }),
    }),
    collaborators: yup.array(yup.string().required()),
    contributors: yup.array(yup.string().required()),
}) as any;
