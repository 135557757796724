import { ReactNode, useState } from "react";
import styles from "./Accordion.module.scss";
import ChevronDown from "../../../assets/chevron_down.svg";
import ChevronUp from "../../../assets/chevron_up.svg";
import strings from "../../../localization/strings";
import cx from "classnames";

interface AccordionProps {
    title: ReactNode;
    collapsible?: boolean;
    onClose?: () => void;
    children: ReactNode;
    open?: boolean;
    className?: string;
    contentClassName?: string;
    headerContent?: ReactNode;
    headerTitleClassName?: string;
}

interface HeaderProps {
    title: ReactNode;
    setCollapsed: (collapsed: boolean) => void;
    collapsed: boolean;
    headerTitleClassName?: string;
}

interface StaticHeaderProps extends HeaderProps {
    headerContent?: ReactNode;
}

const CollapsibleHeader = ({ title, setCollapsed, collapsed, headerTitleClassName }: HeaderProps) => (
    <button
        type="button"
        className={styles.titlebar}
        onClick={() => setCollapsed(!collapsed)}
        aria-label={`${collapsed ? strings.close : strings.open} ${title}`}
    >
        <h1 className={cx(styles.title, headerTitleClassName)}>{title}</h1>
        <img src={collapsed ? ChevronDown : ChevronUp} alt={strings.close} />
    </button>
);

const StaticHeader = ({ title, headerContent, headerTitleClassName }: StaticHeaderProps) => (
    <div className={styles.titlebar}>
        <h1 className={cx(styles.title, headerTitleClassName)}>{title}</h1>
        {headerContent}
    </div>
);

export default function Accordion({
    title,
    collapsible,
    onClose,
    children,
    open,
    className,
    contentClassName,
    headerContent,
    headerTitleClassName,
    ...props
}: AccordionProps) {
    const [collapsed, setCollapsed] = useState(!(!collapsible || open));

    return (
        <div className={`${styles.accordion} ${className}`}>
            {collapsible && !headerContent ? (
                <CollapsibleHeader
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    title={title}
                    headerTitleClassName={headerTitleClassName}
                />
            ) : (
                <StaticHeader
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                    title={title}
                    headerContent={headerContent}
                    headerTitleClassName={headerTitleClassName}
                />
            )}
            <div className={`${styles.content} ${collapsed && styles.hidden} ${contentClassName}`}>{children}</div>
        </div>
    );
}
