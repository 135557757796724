import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./FormPageLayout.module.scss";
import cx from "classnames";

type Background = "default" | "white";

interface Props {
    title: string;
    actionButtons?: ReactNode;
    className?: string;
    background?: Background;
}

export default function FormPageLayout({
    title,
    children,
    actionButtons,
    background,
    className,
}: PropsWithChildren<Props>) {
    return (
        <div className={cx(styles.container, background && styles[background], className)}>
            <div className={styles.titleBar}>
                <h1 className={styles.title}>{title}</h1>

                {actionButtons ? <div className={styles.buttons}>{actionButtons}</div> : null}
            </div>

            {children}
        </div>
    );
}
