import React, { PropsWithChildren } from "react";
import { Configuration, OrganisationInfoApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<OrganisationInfoApi> = (params) => new OrganisationInfoApi(new Configuration(params));

export const OrganisationApiContext = React.createContext(createApi({}));

export const OrganisationInfoApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={OrganisationApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
