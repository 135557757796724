/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    JscApplicationInput,
    instanceOfJscApplicationInput,
    JscApplicationInputFromJSON,
    JscApplicationInputFromJSONTyped,
    JscApplicationInputToJSON,
} from "./JscApplicationInput";
import {
    KeiComputerApplicationInput,
    instanceOfKeiComputerApplicationInput,
    KeiComputerApplicationInputFromJSON,
    KeiComputerApplicationInputFromJSONTyped,
    KeiComputerApplicationInputToJSON,
} from "./KeiComputerApplicationInput";
import {
    KeiParticipasApplicationInput,
    instanceOfKeiParticipasApplicationInput,
    KeiParticipasApplicationInputFromJSON,
    KeiParticipasApplicationInputFromJSONTyped,
    KeiParticipasApplicationInputToJSON,
} from "./KeiParticipasApplicationInput";
import {
    KeiSchoolTripApplicationInput,
    instanceOfKeiSchoolTripApplicationInput,
    KeiSchoolTripApplicationInputFromJSON,
    KeiSchoolTripApplicationInputFromJSONTyped,
    KeiSchoolTripApplicationInputToJSON,
} from "./KeiSchoolTripApplicationInput";

/**
 * @type ApplyRequest
 *
 * @export
 */
export type ApplyRequest =
    | JscApplicationInput
    | KeiComputerApplicationInput
    | KeiParticipasApplicationInput
    | KeiSchoolTripApplicationInput;

export function ApplyRequestFromJSON(json: any): ApplyRequest {
    return ApplyRequestFromJSONTyped(json, false);
}

export function ApplyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyRequest {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ...JscApplicationInputFromJSONTyped(json, true),
        ...KeiComputerApplicationInputFromJSONTyped(json, true),
        ...KeiParticipasApplicationInputFromJSONTyped(json, true),
        ...KeiSchoolTripApplicationInputFromJSONTyped(json, true),
    };
}

export function ApplyRequestToJSON(value?: ApplyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfJscApplicationInput(value)) {
        return JscApplicationInputToJSON(value as JscApplicationInput);
    }
    if (instanceOfKeiComputerApplicationInput(value)) {
        return KeiComputerApplicationInputToJSON(value as KeiComputerApplicationInput);
    }
    if (instanceOfKeiParticipasApplicationInput(value)) {
        return KeiParticipasApplicationInputToJSON(value as KeiParticipasApplicationInput);
    }
    if (instanceOfKeiSchoolTripApplicationInput(value)) {
        return KeiSchoolTripApplicationInputToJSON(value as KeiSchoolTripApplicationInput);
    }

    return {};
}
