import React, { PropsWithChildren } from "react";
import { Configuration, FilesApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<FilesApi> = (params) => new FilesApi(new Configuration(params));

export const FilesApiContext = React.createContext(createApi({}));

export const FilesApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={FilesApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
