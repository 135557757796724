import { useGetNotifications } from "../../hooks/NotificationHooks";
import NotificationsHistoryTable from "./NotificationsHistoryTable";

export default function NotificationsHistoryContainer() {
    const [notificationsState, getNotifications] = useGetNotifications();

    return (
        <NotificationsHistoryTable
            notifications={notificationsState.value}
            getNotifications={getNotifications}
            error={notificationsState.error}
        />
    );
}
