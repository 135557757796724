import * as yup from "yup";
import { MAX_EXTRA_QUESTION_LENGTH, MAX_IMAGE_SIZE, mediaUrl } from "../constants/Validation";
import strings from "../localization/strings";
import { AnswerInput, HappeningTimeAndDateInput, QuestionType, Video } from "../openapi/backend";
import { QuestionFieldValue } from "../types/FormInputTypes";
import { hasValues } from "./Array";
import { asNumber } from "./Formatting";

/* Add a required method for array schema's that checks whether there is at least 1 value).
 * As opposed to .min(1) this used the required error msg.
 */
yup.addMethod(yup.array, "required", function required() {
    return this.test("required", hasValues);
});

yup.addMethod(yup.string, "matches", function matches(regex: string, msg: string) {
    return this.test("pattern", msg, (val?: string) => !!val && new RegExp(regex).test(val));
});

/**
 * Use this number instead of yup.number(), as the latter fails on empty values as it will be trying to cast NaN to numbers.
 */
export const safeNumber = yup.number().transform(asNumber);

export const imageSchema = yup.object({
    altText: yup.string().required(),
    file: yup
        .mixed()
        .required()
        .test("FileSize", (file) => {
            return !file?.size || file.size < MAX_IMAGE_SIZE;
        }),
});

// We support partial videos because empty fields are also validated.
// The happeninghooks filter videos without href, so for now this is safe.
export const videoSchema: yup.SchemaOf<Partial<Video>> = yup.object({
    altText: yup.string().when("href", { is: (val: string) => !!val, then: (schema) => schema.required() }),
    href: yup.string().test("pattern", strings.urlError, (val) => !val || !!val.match(mediaUrl)),
});

export const modelWithImagesSchema = yup.object({
    images: yup.array().of(yup.number()).required().min(1),
});

export const futureTimeAndDateSchema: yup.SchemaOf<HappeningTimeAndDateInput> = yup.object({
    sessionStart: yup
        .date()
        .required()
        .test("SessionBeforeNow", (date) => !!date && date > new Date()),
    sessionEnd: yup
        .date()
        .required()
        .test("SessionBeforeNow", (date) => !!date && date > new Date()),
});

export const questionSchema: yup.SchemaOf<QuestionFieldValue> = yup.object({
    id: yup.number(),
    type: yup.mixed().oneOf(Object.values(QuestionType)).required(),
    title: yup.string().required().max(MAX_EXTRA_QUESTION_LENGTH),
    options: yup
        .array()
        .of(yup.string().required() as any)
        .when("type", {
            is: QuestionType.MultipleChoice,
            then: (schema) => schema.required().min(1),
            otherwise: (schema) => schema.nullable(),
        }),
    isRequired: yup.boolean().default(false),
    isMultipleChoice: yup.boolean().default(false),
    multipleOptionsAllowed: yup.boolean().default(false),
});

export const answerSchema: yup.SchemaOf<AnswerInput> = yup.object().shape({
    questionId: yup.number().required(),
    value: yup
        .string()
        .required()
        .when("type", {
            is: QuestionType.Numeric,
            then: (schema) =>
                schema.test("min", (val) => {
                    const parsed = asNumber(val);

                    return parsed !== undefined && parsed >= 0;
                }),
        }),
});
