import styles from "./Map.module.scss";
import MapMarker, { MarkerLocation } from "./MapMarker";
import ReactDOMServer from "react-dom/server";
import { DivIcon, LatLngTuple } from "leaflet";
import { MapContainer, useMap, Marker, TileLayer } from "react-leaflet";
import { useEffect, useMemo, useRef } from "react";
import "leaflet/dist/leaflet.css"; // need to import this somewhere or the map will look wack

interface SearchControlProps {
    location?: MarkerLocation;
}

interface MapProps {
    location?: MarkerLocation;
    className?: string;
}

const NO_LOCATION_ZOOM = 9;
const LOCATION_ZOOM = 15;
const DEFAULT_CENTER: LatLngTuple = [52.0443914, 4.4540106]; // Balijbos

const getPosition = (location?: MarkerLocation): LatLngTuple | undefined => {
    if (!location) {
        return undefined;
    }

    var { longitude, latitude } = location;
    if (longitude === undefined || latitude === undefined) {
        return undefined;
    }

    return [latitude, longitude];
};

const createMarkerIcon = (location: MarkerLocation) =>
    new DivIcon({
        html: ReactDOMServer.renderToString(<MapMarker {...location} />),
        className: styles.markerContainer,
        iconSize: [200, 30], // height of the marker and width of the container
        iconAnchor: [11.5, 30], // size of the icon and half the width
    });

const LocationControl = ({ location }: SearchControlProps) => {
    const map = useMap();
    const position = useMemo(() => getPosition(location), [location]);
    const initialPosition = useRef(position);

    useEffect(() => {
        if (position && initialPosition.current !== position) {
            map.flyTo(position, LOCATION_ZOOM);
        }
    }, [position, map]);

    const icon = useMemo(() => (location ? createMarkerIcon(location) : undefined), [location]);

    if (!position) {
        return null;
    }

    return <Marker position={position} icon={icon} />;
};

const Map = ({ className, location }: MapProps) => {
    const startPosition = useRef<LatLngTuple>(getPosition(location) || DEFAULT_CENTER);
    const startZoom = useRef(location ? LOCATION_ZOOM : NO_LOCATION_ZOOM);

    return (
        <MapContainer
            center={startPosition.current}
            zoom={startZoom.current}
            className={className}
            attributionControl={false}
            zoomControl={false}
        >
            <TileLayer
                url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                maxZoom={20}
            />
            <LocationControl location={location} />
        </MapContainer>
    );
};

export default Map;
