import strings from "../localization/strings";
import { LocationType } from "../openapi/backend";

export function getLocationTypeString(locationType: LocationType) {
    switch (locationType) {
        case LocationType.AtHome:
            return strings.locationAtHome;
        case LocationType.OnLocation:
            return strings.atLocation;
        case LocationType.Online:
            return strings.onlineStream;
        case LocationType.ToBeDetermined:
            return strings.locationToBeDetermined;
        default:
            return strings.unknown;
    }
}
