import { FieldError, useForm } from "react-hook-form";
import styles from "./ImportUsersTab.module.scss";
import FormPageLayout from "../FormPageLayout/FormPageLayout";
import { ImportUsersInput, useImportUsers } from "../../hooks/UserHooks";
import strings from "../../localization/strings";
import { NavigationTab } from "../../types/NavigationTab";
import Form from "../core/Form/Form/Form";
import FormField from "../core/Form/FormField/FormField";
import Accordion from "../core/Accordion/Accordion";
import { RequestState } from "../../hooks/UseApiCall";
import { useEffect, useState } from "react";
import { DotnetError } from "../core/ErrorMessage/ErrorMessage";
import ResultMessage from "../core/ResultMessage/ResultMessage";

export default function useImportUsersTab(): NavigationTab {
    const element = ImportUsersForm();

    return {
        pathname: "import",
        label: strings.import,
        element,
    };
}

interface FieldErrors {
    [key: string]: FieldError[];
}

var parseErrors = (response: DotnetError): FieldErrors | undefined => {
    if (!response.errors) {
        return undefined;
    }

    var errors = Object.entries(response.errors).reduce((errorCollection, [key, value]) => {
        errorCollection[key.toLowerCase()] = value.map((error) => ({ type: "pattern", message: error }));
        return errorCollection;
    }, {} as FieldErrors);

    return errors;
};

const ImportUsersForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
    } = useForm<ImportUsersInput>({});
    const [{ state, error }, onSubmit] = useImportUsers();
    const [importErrors, setErrors] = useState<FieldErrors>({});

    // Clearing file allows changing and re-uploading file directly
    // Otherwise you'll trigger ERR_UPLOAD_FILE_CHANGED
    useEffect(() => {
        if (state !== RequestState.LOADING) {
            reset();
        }
    }, [state, reset]);

    useEffect(() => {
        if (error) {
            error.json().then((x: DotnetError) => {
                const parsedErrors = parseErrors(x);
                if (parsedErrors) {
                    setErrors(parsedErrors);
                }
            });
        } else {
            setErrors({});
        }
    }, [setError, error]);

    return (
        <FormPageLayout title={strings.import} className={styles.pageLayout}>
            <Form
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                className={styles.form}
                waitingForSubmit={state === RequestState.LOADING}
            >
                <Accordion title={strings.instructions} collapsible>
                    <span>{strings.importGeneralInstruction}</span>
                    <ul>
                        <li>{strings.importHeaderInstruction}</li>
                        <li>{strings.importColumnInstruction}</li>
                        <li>{strings.importOrganisationInstruction}</li>
                        <li>{strings.importRoleInstruction}</li>
                    </ul>
                </Accordion>
                <Accordion title={strings.import} collapsible open={true}>
                    <FormField
                        name={"file"}
                        type="file"
                        label={strings.importFile}
                        placeholder={strings.importFile}
                        register={register}
                        required
                        errors={(errors.file as any) || importErrors.file}
                    />
                </Accordion>
            </Form>
            <ResultMessage
                state={state}
                successText={strings.importSent}
                errorText={strings.somethingWentWrong}
                className={styles.message}
            />
        </FormPageLayout>
    );
};
