import React, { useCallback, useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import strings from "../../../localization/strings";
import { MediaViewModel } from "../../../openapi/backend";
import {
    MediaDetailInput,
    MediaDetailsModalProps,
    TagViewModelWithoutChildTags,
} from "../../../types/MediaLibraryType";
import Form from "../Form/Form/Form";
import { MediaDeleteConfirmationModal } from "../MediaDeleteConfirmationModal/MediaDeleteConfirmationModal";
import TextButton from "../TextButton/TextButton";
import styles from "./MediaDetails.module.scss";
import cx from "classnames";
import { formatAsDate } from "../../../utils.ts/Formatting";
import { getName } from "../../../utils.ts/GetName";
import FormField from "../Form/FormField/FormField";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import { TagsContext } from "../../../contexts/TagsContext";
import { DropdownOption } from "../../../types/DropdownOption";
import FormFieldContainer from "../Form/FormFieldContainer/FormFieldContainer";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./MediaDetailsValidation";

export type Props = MediaDetailsModalProps & {
    media: MediaViewModel;
    onDelete: () => void;
    onUpdate: (input: MediaDetailInput) => void;
    waitingForResponse?: boolean;
};

export const MediaDetails = ({ media, onClose, onDelete, onUpdate, waitingForResponse }: Props) => {
    const { mediaCategories, mediaTags } = useContext(TagsContext).allTags;
    const categoryOptions = useMemo(
        () =>
            mediaCategories.map((c) => ({
                name: c.name,
                value: c as TagViewModelWithoutChildTags,
            })),
        [mediaCategories],
    );

    const mediaTagOptions = useMemo(
        () =>
            mediaTags.map((c) => ({
                name: c.name,
                value: c as TagViewModelWithoutChildTags,
            })),
        [mediaTags],
    );

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
    } = useForm<MediaDetailInput>({
        defaultValues: {
            altText: media.image.altText,
            category: categoryOptions.filter((o) => o.value.id === media.category?.id),
            tags: mediaTagOptions.filter((o) => media.tags.find((t) => t.id === o.value.id)),
        },
        resolver: yupResolver(validationSchema),
    });
    const [showConfirm, setShowConfirm] = useState(false);
    const categoryValue = watch("category");
    const tagsValue = watch("tags");

    const doShowConfirm = useCallback(() => setShowConfirm(true), []);
    const doHideConfirm = useCallback(() => setShowConfirm(false), []);

    const onConfirmDelete = useCallback(() => {
        onDelete();
        doHideConfirm();
    }, [onDelete, doHideConfirm]);

    const changeCategory = useCallback(
        (value: DropdownOption<TagViewModelWithoutChildTags>[]) => setValue("category", value),
        [setValue],
    );

    const changeTags = useCallback(
        (value: DropdownOption<TagViewModelWithoutChildTags>[]) => setValue("tags", value),
        [setValue],
    );

    return (
        <>
            <Form
                className={styles.container}
                onSubmit={onUpdate}
                handleSubmit={handleSubmit}
                onDismiss={onClose}
                waitingForSubmit={waitingForResponse}
                tertiaryButtons={
                    <TextButton
                        className={styles.deleteButton}
                        text={strings.delete}
                        buttonType="secondary"
                        onClick={doShowConfirm}
                        isLoading={waitingForResponse}
                    />
                }
            >
                <div className={styles.contentContainer}>
                    <div className={styles.cover}>
                        <img className={styles.image} src={media.image.href} alt={strings.imagePreview} />
                    </div>
                    <div className={styles.details}>
                        <p className={cx(styles.body, styles.bold)}>{media.originalFileName}</p>
                        <p className={styles.body}>
                            {strings.formatString(
                                strings.mediaDetailsLine2,
                                media.fileSize,
                                formatAsDate(media.lastUpdated, "DD MMM YYYY HH:mm"),
                            )}
                        </p>
                        <p className={styles.body}>{getName(media.uploader)}</p>
                        <FormField
                            name={"altText"}
                            label={strings.imageTitle}
                            register={register}
                            errors={errors.altText}
                            required
                            placeholder=""
                        />
                        <FormFieldContainer name="category" label={strings.category}>
                            <MultiselectDropdown
                                options={categoryOptions}
                                placeholder=""
                                name={"category"}
                                className={styles.dropdown}
                                onChange={changeCategory}
                                value={categoryValue}
                                searchPlaceholder={strings.searchByCategory}
                                restrictToSingle
                            />
                        </FormFieldContainer>
                        <FormFieldContainer name="tags" label={strings.tags}>
                            <MultiselectDropdown
                                options={mediaTagOptions}
                                placeholder=""
                                name={"tags"}
                                className={styles.dropdown}
                                onChange={changeTags}
                                value={tagsValue}
                                searchable
                                searchPlaceholder={strings.searchByMediaTag}
                            />
                        </FormFieldContainer>
                    </div>
                </div>
            </Form>
            {showConfirm && (
                <MediaDeleteConfirmationModal
                    usages={media.usages}
                    onClose={doHideConfirm}
                    onConfirm={onConfirmDelete}
                />
            )}
        </>
    );
};
