import { useCallback, useContext, useMemo } from "react";
import { TagsContext } from "../../../contexts/TagsContext";
import { HappeningFilter } from "../../../hooks/HappeningsHooks";
import { useOrganiserOptions } from "../../../hooks/TagHooks";
import { useProjectLeaderOptions } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import { HappeningState, TagViewModel } from "../../../openapi/backend";
import { HappeningStateOption, HappeningSubsetOption } from "../../../types/DropdownOption";
import { HappeningFilterKeys } from "../../../types/HappeningFilterKeys";
import { UserNamedType } from "../../../types/UserNamedType";
import { statusToString } from "../../../utils.ts/GetStatus";
import { getName } from "../../../utils.ts/GetName";
import { getStateOptions } from "../../../utils.ts/StateOptions";
import { getHappeningSubsetOptions, happeningSubsetToString } from "../../../utils.ts/TargetGroupOptions";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import IconTagOption from "../IconTagOption/IconTagOption";
import DatePicker from "../Inputs/DatePicker/DatePicker";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import StateLabel from "../StateLabel/StateLabel";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import styles from "./FilterBar.module.scss";
import QueryFilterInput from "./QueryFilterInput";
import { HappeningSubset } from "../../../types/HappeningSubset";

type Props = FilterBarPropsWithValue<HappeningFilter> & {
    excludeFilters?: HappeningFilterKeys;
    excludeHappeningSubsets?: HappeningSubset[];
    allowedStates?: HappeningState[];
    autoFocus?: boolean;
};

export default function HappeningFilterBar({
    value: filters,
    onChange,
    excludeFilters,
    excludeHappeningSubsets,
    allowedStates, // Defaults to all states
    autoFocus,
}: Props) {
    const stateOptions = getStateOptions(allowedStates ?? []);
    const defaultFilters = useMemo(() => {
        return { fromDate: undefined, toDate: undefined };
    }, []);
    const tags = useContext(TagsContext).allTags;
    const { value: projectLeaders } = useProjectLeaderOptions();
    const organiserOptions = useOrganiserOptions();
    const leaders: UserNamedType[] = useMemo(
        () => (projectLeaders ? projectLeaders.map((leader) => ({ ...leader, name: getName(leader) })) : []),
        [projectLeaders],
    );
    const happeningSubsetOptions = getHappeningSubsetOptions(excludeHappeningSubsets);

    const onReset = () => {
        onChange(defaultFilters);
    };

    const onChangeGroup = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, groups: filter }),
        [onChange, filters],
    );
    const onChangeStatus = useCallback(
        (filter: HappeningStateOption[]) => onChange({ ...filters, states: filter }),
        [onChange, filters],
    );
    const onChangeLeader = useCallback(
        (filter: UserNamedType[]) => onChange({ ...filters, projectLeaders: filter }),
        [onChange, filters],
    );
    const onChangeInterest = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, happeningTypes: filter }),
        [onChange, filters],
    );
    const onChangeDate = useCallback(
        ([fromDate, toDate]: [Date | null, Date | null]) => {
            toDate?.setHours(23, 59, 59); // toDate has to be at midnight to include activities on that day
            onChange({ ...filters, fromDate: fromDate || undefined, toDate: toDate || undefined });
        },
        [onChange, filters],
    );
    const onChangeHappeningSubset = useCallback(
        (filter: HappeningSubsetOption[]) => {
            return onChange({ ...filters, happeningSubsets: filter });
        },
        [onChange, filters],
    );

    const shouldShowQuery = useMemo(() => !excludeFilters?.includes("query"), [excludeFilters]);
    const shouldShowGroups = useMemo(() => !excludeFilters?.includes("groups"), [excludeFilters]);
    const shouldShowStates = useMemo(() => !excludeFilters?.includes("states"), [excludeFilters]);
    const shouldShowDate = useMemo(
        () => !excludeFilters?.includes("fromDate") && !excludeFilters?.includes("toDate"),
        [excludeFilters],
    );
    const shouldShowProjectLeaders = useMemo(() => !excludeFilters?.includes("projectLeaders"), [excludeFilters]);
    const shouldShowHappeningTypes = useMemo(() => !excludeFilters?.includes("happeningTypes"), [excludeFilters]);
    const shouldShowUserSubsets = useMemo(() => !excludeFilters?.includes("happeningSubsets"), [excludeFilters]);

    return (
        <FilterBar onReset={onReset}>
            {shouldShowQuery && <QueryFilterInput filters={filters} onChange={onChange} autoFocus={autoFocus} />}
            {shouldShowDate && (
                <div className={styles.dateFilter}>
                    <DatePicker
                        startDate={filters.fromDate}
                        endDate={filters.toDate}
                        onChange={onChangeDate}
                        selectsRange
                        className={styles.datePicker}
                        placeholderText={strings.date}
                    />
                </div>
            )}
            {shouldShowProjectLeaders && (
                <MultiselectDropdown
                    options={leaders}
                    renderOption={(option) => (
                        <div className={styles.group}>
                            <ProfileIcon profile={option} />
                            <span>{option.name}</span>
                        </div>
                    )}
                    placeholder={strings.projectLeader}
                    name={"leaderFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeLeader}
                    value={filters.projectLeaders}
                    searchable
                    searchPlaceholder={strings.searchByProjectLeaderName}
                />
            )}
            {shouldShowGroups && (
                <MultiselectDropdown
                    options={organiserOptions}
                    optionToKey={(option) => option.id}
                    renderOption={(option) => <IconTagOption tag={option} />}
                    placeholder={strings.happeningGroup}
                    name={"groupFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeGroup}
                    value={filters.groups}
                    searchable
                    searchPlaceholder={strings.searchByOrganisation}
                />
            )}
            {shouldShowStates && (
                <MultiselectDropdown
                    options={stateOptions}
                    optionToString={(option) => statusToString(option.value)}
                    renderOption={(option) => (
                        <div>
                            <StateLabel state={option.value} />
                        </div>
                    )}
                    placeholder={strings.state}
                    name={"statusFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeStatus}
                    value={filters.states}
                />
            )}
            {shouldShowHappeningTypes && (
                <MultiselectDropdown
                    options={tags.happeningTypes}
                    placeholder={strings.interests}
                    name={"interestFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeInterest}
                    value={filters.happeningTypes}
                />
            )}
            {shouldShowUserSubsets && (
                <MultiselectDropdown
                    options={happeningSubsetOptions}
                    optionToString={(option) => happeningSubsetToString(option.value)}
                    placeholder={strings.happeningsSubset}
                    name={"happeningSubsetFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeHappeningSubset}
                    value={filters.happeningSubsets}
                />
            )}
        </FilterBar>
    );
}
