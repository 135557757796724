import styles from "./StateLabel.module.scss";
import { HappeningState } from "../../../openapi/backend/models/HappeningState";
import { statusToString, StatusType } from "../../../utils.ts/GetStatus";
import { IndicatorStatus, MeasurementStatus } from "../../../openapi/backend";

interface StateLabelProps {
    state: StatusType;
}

export default function StateLabel({ state }: StateLabelProps) {
    const getStyle = () => {
        switch (state) {
            case HappeningState.Open:
            case MeasurementStatus.Open:
            case IndicatorStatus.Open:
                return styles.open;
            case HappeningState.Concept:
            case MeasurementStatus.Concept:
            case IndicatorStatus.Concept:
                return styles.concept;
            case HappeningState.PartnerSuggestion:
                return styles.suggestion;
            case HappeningState.Archived:
            case IndicatorStatus.Archived:
                return styles.archived;
            case HappeningState.Cancelled:
            case HappeningState.Rejected:
                return styles.cancelled;
            case HappeningState.PlannedForPublication:
            case IndicatorStatus.PlannedForPublication:
                return styles.plannedForPublication;
            case MeasurementStatus.Closed:
                return styles.closed;
            default:
                return styles.open;
        }
    };

    return (
        <div id={state} className={`${styles.label} ${getStyle()}`}>
            <label htmlFor={state}>{statusToString(state)}</label>
        </div>
    );
}
