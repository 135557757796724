import { useSearchParams } from "react-router-dom";
import { CLUSTER_KEY } from "../constants/Route";

/**
 * Fetches a search parameter and maps it to the desired type.
 * To avoid dependency problems, map to primitive values so React can recognize actual differences.
 * @param key - The key for which the parameter should be found
 * @param map - A mapping function that parses the value. Should map to number, bool or string.
 * @returns
 */
function useMappedParam<T>(key: string, map: (value: string | null) => T): T {
    const [searchParams] = useSearchParams();
    return map(searchParams.get(key));
}

const useDefaultMappedParam = (key: string) => useMappedParam(key, (value) => value || undefined);

export function useClusterIdFromSearchParams() {
    return useDefaultMappedParam(CLUSTER_KEY);
}
