import React, { PropsWithChildren, useEffect, useState } from "react";
import { useRegistrationRulesOverview } from "../hooks/RegistrationRuleHooks";
import { RequestState } from "../hooks/UseApiCall";
import { RegistrationRuleViewModel } from "../openapi/backend";

export type RegistrationRulesContextType = {
    registrationRules?: Array<RegistrationRuleViewModel>;
    state: RequestState;
    error?: Response;
    refresh: () => void;
};

/**
 * Context that provides the registrations rules data.
 */
export const RegistrationRulesContext = React.createContext<RegistrationRulesContextType>({
    refresh() {},
    state: RequestState.DONE,
});

export const RegistrationRulesContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [{ value: registrationRules, state, error }, refresh] = useRegistrationRulesOverview();

    const [value, setValue] = useState<RegistrationRulesContextType>({ registrationRules, refresh, state, error });

    useEffect(() => refresh(), [refresh]);
    useEffect(() => {
        if (state === RequestState.DONE || state === RequestState.ERROR) {
            setValue({ registrationRules, refresh, state, error });
        }
    }, [registrationRules, refresh, state, error]);

    return <RegistrationRulesContext.Provider value={value}>{children}</RegistrationRulesContext.Provider>;
};
