import { ButtonHTMLAttributes } from "react";
import styles from "./CrossButton.module.scss";
import CrossIcon from "../../../assets/cross.svg";
import cx from "classnames";

interface CrossProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    alt?: string;
    onClick: () => void;
}

export const CrossButton = ({ alt, onClick, className, ...props }: CrossProps) => (
    <button {...props} className={cx(styles.button, className)} onClick={onClick}>
        <img src={CrossIcon} alt={alt} />
    </button>
);
