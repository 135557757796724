import { useContext, useEffect, useState } from "react";
import { MediaLibraryDataContext } from "../../../contexts/MediaLibraryDataContext";
import { useCreateMediaImage } from "../../../hooks/MediaHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import { ActionType } from "../../../reducers/MediaLibraryReducer";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import ImageUploadInput from "./ImageUploadInput";

interface Props {
    className?: string;
}

export default function MediaLibraryImageUploadInputContainer(props: Props) {
    const [requestState, create, resetRequest] = useCreateMediaImage();
    const [version, setVersion] = useState(0);
    const [, dispatch] = useContext(MediaLibraryDataContext);

    useEffect(() => {
        if (requestState.state === RequestState.DONE) {
            // Use version as a key to force a re-render of the component
            setVersion((v) => (v + 1) % 10);
            dispatch({ type: ActionType.add, value: requestState.value });
            resetRequest();
        }
    }, [requestState, resetRequest, dispatch]);

    return (
        <>
            <ImageUploadInput key={`image-v${version}`} {...props} requestStatus={requestState.state} onAdd={create} />
            {requestState.error && <ErrorMessage error={requestState.error} isToast />}
        </>
    );
}
