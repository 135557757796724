import strings from "../localization/strings";
import { KeiEducationType } from "../openapi/backend";

export function getStringForEducationType(type: KeiEducationType | undefined): string {
    switch (type) {
        case KeiEducationType.PrimarySchool:
            return strings.primarySchool;
        case KeiEducationType.SecondarySchool:
            return strings.secondarySchool;
        default:
            return strings.unknown;
    }
}
