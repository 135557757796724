import * as yup from "yup";
import { DropdownOption } from "../../../types/DropdownOption";
import { MediaDetailInput, TagViewModelWithoutChildTags } from "../../../types/MediaLibraryType";

export const validationSchema: yup.SchemaOf<MediaDetailInput> = yup.object({
    altText: yup.string().required(),
    // @NOTE(Lejun) I couldn't figure out how to make this work
    category: yup.array().of(yup.mixed<DropdownOption<TagViewModelWithoutChildTags>>()) as any,
    tags: yup.array().of(yup.mixed<DropdownOption<TagViewModelWithoutChildTags>>()) as any,
});
