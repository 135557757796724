import React, { useCallback, useContext, useMemo } from "react";
import { TagsContext } from "../../../contexts/TagsContext";
import { useGetIntermediaries } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import { TagViewModel } from "../../../openapi/backend";
import { UserNamedType } from "../../../types/UserNamedType";
import { getName } from "../../../utils.ts/GetName";
import { getApplicationStatusOptions } from "../../../utils.ts/StateOptions";
import DatePicker from "../Inputs/DatePicker/DatePicker";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import styles from "./FilterBar.module.scss";
import { applicationStatusToString } from "../../../utils.ts/GetApplicationStatus";
import { ApplicationStatusOption } from "../../../types/DropdownOption";
import ApplicationStatusLabel from "../ApplicationStatusLabel/ApplicationStatusLabel";
import { ApplicationFilterKeys } from "../../../types/ApplicationFilterKeys";
import { ApplicationFilter } from "../../../hooks/ArrangementHooks";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import QueryFilterInput from "./QueryFilterInput";

const statusOptions = getApplicationStatusOptions();
function getDefaultFilters() {
    return { fromDate: undefined, toDate: undefined };
}

type Props = FilterBarPropsWithValue<ApplicationFilter> & {
    exclude?: ApplicationFilterKeys;
};

export default function ApplicationFilterBar({ value: filters, onChange, exclude }: Props) {
    const tags = useContext(TagsContext).allTags;
    const { value: intermediaries } = useGetIntermediaries();
    const intermediaryOptions: UserNamedType[] = useMemo(
        () => (intermediaries ? intermediaries.map((i) => ({ ...i, name: getName(i) })) : []),
        [intermediaries],
    );

    const onReset = useCallback(() => {
        onChange(getDefaultFilters());
    }, [onChange]);

    const onChangeStatus = useCallback(
        (filter: ApplicationStatusOption[]) => onChange({ ...filters, statuses: filter }),
        [onChange, filters],
    );
    const onChangeIntermediaries = useCallback(
        (filter: UserNamedType[]) => onChange({ ...filters, reviewers: filter }),
        [onChange, filters],
    );
    const onChangeMunicipality = useCallback(
        (selected: TagViewModel[]) => onChange({ ...filters, municipalities: selected }),
        [onChange, filters],
    );
    const onChangeDate = useCallback(
        ([fromDate, toDate]: [Date | null, Date | null]) => {
            toDate?.setHours(23, 59, 59); // toDate has to be at midnight to include activities on that day
            onChange({ ...filters, fromDate: fromDate || undefined, toDate: toDate || undefined });
        },
        [onChange, filters],
    );

    const shouldShowQuery = useMemo(() => !exclude?.includes("query"), [exclude]);
    const shouldShowMunicipality = useMemo(() => !exclude?.includes("municipalities"), [exclude]);
    const shouldShowIntermediaries = useMemo(() => !exclude?.includes("reviewers"), [exclude]);
    const shouldShowStatus = useMemo(() => !exclude?.includes("statuses"), [exclude]);
    const shouldShowDate = useMemo(() => !exclude?.includes("fromDate") && !exclude?.includes("toDate"), [exclude]);

    return (
        <FilterBar onReset={onReset}>
            {shouldShowQuery && <QueryFilterInput filters={filters} onChange={onChange} />}
            {shouldShowMunicipality && (
                <MultiselectDropdown
                    options={tags.municipalities}
                    placeholder={strings.municipality}
                    name={"municipalityFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeMunicipality}
                    value={filters.municipalities}
                    searchable
                    searchPlaceholder={strings.searchByMunicipality}
                />
            )}
            {shouldShowIntermediaries && (
                <MultiselectDropdown
                    options={intermediaryOptions}
                    renderOption={(option) => (
                        <div className={styles.group}>
                            <ProfileIcon profile={option} />
                            <span>{option.name}</span>
                        </div>
                    )}
                    placeholder={strings.arrangementIntermediary}
                    name={"intermediaryFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeIntermediaries}
                    value={filters.reviewers}
                    searchable
                    searchPlaceholder={strings.searchByApplicationReviewer}
                />
            )}
            {shouldShowStatus && (
                <MultiselectDropdown
                    options={statusOptions}
                    optionToString={(option) => applicationStatusToString(option.value)}
                    renderOption={(option) => (
                        <div>
                            <ApplicationStatusLabel status={option.value} />
                        </div>
                    )}
                    placeholder={strings.state}
                    name={"statusFilter"}
                    className={styles.filter}
                    onItemSelect={onChangeStatus}
                    value={filters.statuses}
                />
            )}
            {shouldShowDate && (
                <div className={styles.dateFilter}>
                    <DatePicker
                        startDate={filters.fromDate}
                        endDate={filters.toDate}
                        onChange={onChangeDate}
                        selectsRange
                        className={styles.datePicker}
                        placeholderText={strings.date}
                    />
                </div>
            )}
        </FilterBar>
    );
}
