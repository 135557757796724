import React, { useCallback, useEffect, useMemo } from "react";
import { useClusterCoverUpdate } from "../../../hooks/ClusterHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import { ImageViewModel } from "../../../openapi/backend";
import { MediaLibraryModalProps } from "../../../types/MediaLibraryType";
import { hasValues } from "../../../utils.ts/Array";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { MediaLibraryModal } from "./MediaLibraryModal";

export type Props = MediaLibraryModalProps & {
    clusterId: string;
    currentSelectedImage: ImageViewModel | undefined;
    onSuccess: () => void;
};

export const ClusterMediaLibraryModalContainer = ({ clusterId, currentSelectedImage, onSuccess, ...props }: Props) => {
    const [updateRequestState, updateCallback] = useClusterCoverUpdate(clusterId);

    const images = useMemo(() => {
        if (!currentSelectedImage) {
            return [];
        }
        return [currentSelectedImage];
    }, [currentSelectedImage]);

    const onConfirm = useCallback(
        (selectedImages: ImageViewModel[]) => {
            if (!hasValues(selectedImages)) {
                return;
            }

            updateCallback(selectedImages[0].id);
            return true;
        },
        [updateCallback],
    );

    useEffect(() => {
        if (updateRequestState.state === RequestState.DONE) {
            onSuccess();
        }
    }, [updateRequestState.state, onSuccess]);

    return (
        <>
            <MediaLibraryModal
                {...props}
                excludeVideos
                onConfirm={onConfirm}
                images={images}
                type="cluster"
                isWaitingForResponse={updateRequestState.state === RequestState.LOADING}
                minImages={1}
                single
            />
            {updateRequestState.error && <ErrorMessage error={updateRequestState.error} isToast />}
        </>
    );
};
