import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useCreateGroup } from "../../../hooks/HappeningsHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { GroupFormInput } from "../../../types/HappeningGroupType";
import Form from "../../core/Form/Form/Form";
import FormField from "../../core/Form/FormField/FormField";
import { SmallModal } from "../../core/Modal/Modal";
import { validationSchema } from "./GroupValidation";

interface ContainerProps {
    onDismiss: () => void;
    onSuccess: () => void;
    happeningId: string;
}

export interface Props {
    onSubmit: (input: GroupFormInput) => void;
    onDismiss: () => void;
    error?: Response;
    isSubmitting: boolean;
}

const CreateNewGroupModalContainer = ({ onDismiss, onSuccess, happeningId }: ContainerProps) => {
    const [{ state, error }, create] = useCreateGroup(happeningId);
    const onSubmit = useCallback((input: GroupFormInput) => create(input.name), [create]);

    useEffect(() => {
        if (state === RequestState.DONE) {
            onDismiss();
            onSuccess();
        }
    }, [state, onDismiss, onSuccess]);

    return (
        <CreateGroupModal
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            error={error}
            isSubmitting={state === RequestState.LOADING}
        />
    );
};

const CreateGroupModal = ({ onSubmit, onDismiss, error, isSubmitting }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<GroupFormInput>({
        resolver: yupResolver(validationSchema),
    });

    return (
        <SmallModal isOpen onDismiss={onDismiss} title={strings.createNewTeam}>
            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                submitText={strings.createNewTeamSubmit}
                cancelText={strings.cancel}
                waitingForSubmit={isSubmitting}
            >
                <FormField
                    name="name"
                    label={strings.groupName}
                    errors={errors.name}
                    placeholder=""
                    required
                    register={register}
                />
            </Form>
        </SmallModal>
    );
};

export default CreateNewGroupModalContainer;
