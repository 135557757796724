import React from "react";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

type Props = LazyLoadImageProps;

const LazyImage = (props: Props) => {
    return <LazyLoadImage effect="opacity" threshold={100} {...props} />;
};

export default LazyImage;
