import React, { useCallback, useContext, useEffect } from "react";
import { MediaLibraryDataContext } from "../../../contexts/MediaLibraryDataContext";
import { useGetMediaLibrary } from "../../../hooks/MediaHooks";
import strings from "../../../localization/strings";
import { ActionType } from "../../../reducers/MediaLibraryReducer";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { MediaLibraryManagement } from "./MediaLibraryManagement";

export const MediaLibraryManagementContainer = () => {
    const [requestState, callback] = useGetMediaLibrary();
    const [{ content, shouldFetch, query }, dispatch] = useContext(MediaLibraryDataContext);

    const setQuery = useCallback((q: string) => dispatch({ type: ActionType.search, value: q }), [dispatch]);

    useEffect(() => {
        // @NOTE(Lejun) Currently shouldFetch is used as a way for child components to force a re-fetch of the media library
        // This is needed because the logics for the add/update reducer actions are not designed yet.
        // Refer to the reducer for more details.
        if (!shouldFetch) {
            return;
        }
        callback(query);
    }, [callback, query, shouldFetch]);

    useEffect(() => {
        if (requestState.value) {
            dispatch({ type: ActionType.set, value: requestState.value, purpose: "management" });
        }
    }, [dispatch, requestState]);

    return (
        <>
            <MediaLibraryManagement libraryContent={content} query={query} setQuery={setQuery} />
            {requestState.error && (
                <ErrorMessage error={requestState.error} defaultMessage={strings.somethingWentWrong} isToast />
            )}
        </>
    );
};
