import { useCallback } from "react";
import strings from "../../localization/strings";
import { REACT_APP_IDENTITY_URL } from "../../utils.ts/Env";
import TextButton from "../core/TextButton/TextButton";
import IdentityPage, { LinkContainer } from "../IdentityPage/IdentityPage";
import { useSessionClear, useSignout } from "../../authentication/useSignout";

const UpgradeAcrPage = () => {
    const sessionClear = useSessionClear();
    const signout = useSignout();

    const configure = useCallback(() => {
        sessionClear();
        window.location.href = `${REACT_APP_IDENTITY_URL}/settings`;
    }, [sessionClear]);

    return (
        <IdentityPage>
            <h1>{strings.mfaRequired}</h1>
            <p>{strings.mfaExplenation}</p>
            <p>{strings.mfaTroubleshoot}</p>
            <LinkContainer>
                <TextButton onClick={configure} text={strings.settings} />
                <TextButton onClick={signout} text={strings.retryLogin} />
            </LinkContainer>
        </IdentityPage>
    );
};

export default UpgradeAcrPage;
