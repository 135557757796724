import { useCallback, useContext } from "react";
import { ApiCallState, useApiCall, useApiCallback } from "./UseApiCall";
import {
    Role,
    SortOrder,
    TagViewModel,
    UserListOutput,
    UserListOutputPaginatedViewModel,
    UserOrderBy,
    UserOutput,
} from "../openapi/backend/models";
import { UserApiContext } from "../contexts/UserApiContext";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import {
    CreateUserRequest,
    DownloadBackofficeUsersRequest,
    DownloadParticipantsRequest,
    DownloadPartnersRequest,
    GetUsersRequest,
    UpdateUserRequest,
} from "../openapi/backend/apis/UserApi";
import { UpdateProfileRequest } from "../openapi/backend/apis/ProfilesApi";
import strings from "../localization/strings";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";
import { ProfilesApiContext } from "../contexts/ProfilesApiContext";
import { ProfileOutput } from "../openapi/backend";

export const useWhoAmI = (): [ApiCallState<UserOutput>, () => void, () => void] => {
    const api = useContext(UserApiContext);
    const callback = useCallback(() => api.whoAmI({ version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export type UpdateUserInput = {
    happeningGroups?: string[];
    partners?: string[];
} & Omit<UpdateUserRequest, "version" | "id" | "tags">;
/**
 * Update function for editing a user (as a user admin).
 * This is different from editing profile as this is concerned about permissions.
 */
export const useUpdateUser = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback(
        (id: string, input: UpdateUserInput) => {
            var tags = [...(input.happeningGroups ?? []), ...(input.partners ?? [])];
            return api.updateUser({ ...input, id, version: REACT_APP_BACKEND_VERSION, tags: tags });
        },
        [api],
    );
    return useApiCallback(callback);
};

export type UpdateProfileInput = Omit<UpdateProfileRequest, "version" | "id">;
export const useUpdateProfile = () => {
    const api = useContext(ProfilesApiContext);
    const callback = useCallback(
        (input: UpdateProfileInput, id?: string) =>
            id
                ? api.updateProfile({ id, version: REACT_APP_BACKEND_VERSION, ...input })
                : api.updateMyProfile({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );
    return useApiCallback(callback);
};

export const useUpdateAvatar = () => {
    const api = useContext(ProfilesApiContext);
    const callback = useCallback(
        (file: File, id?: string) =>
            id
                ? api.updateProfilePicture({
                      id,
                      version: REACT_APP_BACKEND_VERSION,
                      file,
                      altText: strings.profilePicture,
                  })
                : api.updateMyProfilePicture({
                      version: REACT_APP_BACKEND_VERSION,
                      file,
                      altText: strings.profilePicture,
                  }),
        [api],
    );
    return useApiCallback(callback);
};

export type UserInput = Omit<CreateUserRequest, "version"> & {
    happeningGroups?: string[];
    partners?: string[];
};
export const useCreateUserCallback = (): [ApiCallState<number>, (request: UserInput) => void, () => void] => {
    const api = useContext(UserApiContext);
    const callback = useCallback(
        (input: UserInput) => {
            const tags = [...(input?.happeningGroups ?? []), ...(input?.partners ?? [])];
            return api.createUser({ ...input, tags, version: REACT_APP_BACKEND_VERSION });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useProjectLeaderOptions = (happeningId?: string): ApiCallState<UserListOutput[]> => {
    const api = useContext(UserApiContext);
    const callback = useCallback(
        () => api.getPossibleProjectLeaders({ version: REACT_APP_BACKEND_VERSION, happeningId }),
        [api, happeningId],
    );
    return useApiCall(callback);
};

export const useMemberOptions = (happeningId?: string): ApiCallState<UserListOutput[]> => {
    const api = useContext(UserApiContext);
    const callback = useCallback(
        () => api.getPossibleProjectMembers({ version: REACT_APP_BACKEND_VERSION, happeningId }),
        [api, happeningId],
    );
    return useApiCall(callback);
};

export type UserFilter = Pick<GetUsersRequest, "query" | "page"> & {
    partners?: TagViewModel[];
    happeningGroups?: TagViewModel[];
};
export type GetUsersFun = (
    orderBy: UserOrderBy,
    sortOrder: SortOrder,
    itemsPerPage: number,
    roles?: Role[],
    filter?: UserFilter,
) => void;
export const useUsers = (): [ApiCallState<UserListOutputPaginatedViewModel>, GetUsersFun, () => void] => {
    const api = useContext(UserApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, itemsPerPage, roles, filter) => {
            const transformedFilter = {
                ...filter,
                partners: filter?.partners?.map((tag: TagViewModel) => tag.id),
                happeningGroups: filter?.happeningGroups?.map((tag: TagViewModel) => tag.id),
            };

            return api.getUsers({
                version: REACT_APP_BACKEND_VERSION,
                itemsPerPage,
                roles,
                orderBy,
                sortOrder,
                ...transformedFilter,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export type GetUserFun = (id: string) => void;
export const useUser = (): [ApiCallState<UserOutput>, GetUserFun, () => void] => {
    const api = useContext(UserApiContext);
    const callback = useCallback((id) => api.getUser({ id, version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export const useProfile = (): [ApiCallState<ProfileOutput>, GetUserFun, () => void] => {
    const api = useContext(ProfilesApiContext);
    const callback = useCallback((id) => api.getProfile({ id, version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export type DownloadUsersRequest =
    | DownloadParticipantsRequest
    | DownloadPartnersRequest
    | DownloadBackofficeUsersRequest;
export type DownloadUsersApiCall = (req: DownloadUsersRequest) => Promise<object>;
export type DownloadUsersFun = (filter?: UserFilter) => void;
export type DownloadUsersOutput = {
    contentType: string;
    fileDownloadName: string;
    // Base64 string
    fileContents: string;
};

const useDownloadUsers = (
    downloadUsersCall: DownloadUsersApiCall,
): [ApiCallState<void>, DownloadUsersFun, () => void] => {
    const callback = useCallback(
        async (filter?: UserFilter) => {
            const fileData = (await downloadUsersCall({
                version: REACT_APP_BACKEND_VERSION,
                ...filter,
                partners: filter?.partners?.map((tag: TagViewModel) => tag.id),
                happeningGroups: filter?.happeningGroups?.map((tag: TagViewModel) => tag.id),
            })) as DownloadUsersOutput;

            let contentBuffer = Buffer.from(fileData.fileContents, "base64");
            fileDownload(contentBuffer.toString(), fileData.fileDownloadName, fileData.contentType);
        },
        [downloadUsersCall],
    );

    return useApiCallback(callback);
};

export const useDownloadParticipants = (): [ApiCallState<void>, DownloadUsersFun, () => void] => {
    const api = useContext(UserApiContext);
    return useDownloadUsers(api.downloadParticipants.bind(api));
};

export const useDownloadPartners = (): [ApiCallState<void>, DownloadUsersFun, () => void] => {
    const api = useContext(UserApiContext);
    return useDownloadUsers(api.downloadPartners.bind(api));
};

export const useDownloadBackofficeUsers = (): [ApiCallState<void>, DownloadUsersFun, () => void] => {
    const api = useContext(UserApiContext);
    return useDownloadUsers(api.downloadBackofficeUsers.bind(api));
};

export const useResetPassword = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback((id: string) => api.resetPassword({ id, version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export const useBlockUser = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback((id: string) => api.blockUser({ id, version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export const useUnblockUser = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback((id: string) => api.unblockUser({ id, version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export const useDeleteUser = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback((id: string) => api.deleteUser({ id, version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCallback(callback);
};

export type ImportUsersInput = { file: FileList };
export const useImportUsers = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback(
        (input: ImportUsersInput) => api.importUsers({ file: input.file[0], version: REACT_APP_BACKEND_VERSION }),
        [api],
    );
    return useApiCallback(callback);
};

export const useGetIntermediaries = () => {
    const api = useContext(UserApiContext);
    const callback = useCallback(() => api.getIntermediaries({ version: REACT_APP_BACKEND_VERSION }), [api]);
    return useApiCall(callback);
};
export const useGetParticipants = () => {
    const api = useContext(ProfilesApiContext);
    const callback = useCallback(
        (query: string) => {
            return api.getParticipants({ version: REACT_APP_BACKEND_VERSION, emailQuery: query });
        },
        [api],
    );
    return useApiCallback(callback);
};
