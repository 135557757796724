import React, { PropsWithChildren } from "react";
import { Configuration, MediaApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<MediaApi> = (params) => new MediaApi(new Configuration(params));

export const MediaApiContext = React.createContext(createApi({}));

export const MediaApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={MediaApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
