import { RequestState } from "../../../../hooks/UseApiCall";
import TextInputWithButton from "../TextInputWithButton/TextInputWithButton";
import SaveIcon from "../../../../assets/save_icon.svg";
import LoadingIcon from "../../../../assets/loading_icon.svg";
import SuccessIcon from "../../../../assets/check_icon.svg";
import ErrorIcon from "../../../../assets/alert_icon.svg";
import { useState } from "react";
import TextButton from "../../TextButton/TextButton";
import strings from "../../../../localization/strings";

export interface ApiKeyInputProps {
    hasValue: boolean;
    requestState: RequestState;
    onSave: (key: string) => void;
}

const getStateIcon = (state: RequestState, hasValue: boolean): string => {
    switch (state) {
        case RequestState.LOADING:
            return LoadingIcon;
        case RequestState.DONE:
            return hasValue ? SuccessIcon : ErrorIcon;
        case RequestState.ERROR:
            return ErrorIcon;
        default:
            return SaveIcon;
    }
};

export default function ApiKeyInput({ hasValue, requestState, onSave }: ApiKeyInputProps) {
    const [showInput, setShowInput] = useState(false);
    let icon = getStateIcon(requestState, hasValue);

    return (
        <div>
            {showInput ? (
                <TextInputWithButton
                    icon={icon}
                    onActivate={onSave}
                    autoFocus={true}
                    buttonDisabled={requestState === RequestState.LOADING}
                    activateOnBlur={false}
                />
            ) : (
                <TextButton
                    text={hasValue ? strings.editApiKey : strings.addApiKey}
                    onClick={() => setShowInput(true)}
                />
            )}
        </div>
    );
}
