import LazyImage from "../LazyImage/LazyImage";
import styles from "./FileList.module.scss";
import TrashIcon from "../../../assets/remove_icon_white.svg";
import strings from "../../../localization/strings";
import { useCallback, useContext, useState } from "react";
import { AttachmentsContext } from "../../../contexts/AttachmentsContext";
import FileDeleteConfirmationModal from "../FileDeleteConfirmationModal/FileDeleteConfirmationModal";
import fileDownload from "js-file-download";
import cx from "classnames";

function renderFileIcon(fileName: string, downloadFile: () => void, deleteFile: () => void, key: string) {
    return (
        <div key={key}>
            <div className={styles.fileContainer}>
                <button className={styles.item} type="button" onClick={downloadFile}>
                    <div className={styles.fileButton}>
                        <div className={styles.fileExtension}>{`.${fileName.split(".").pop()?.toUpperCase()}`}</div>
                    </div>
                </button>
                <button className={styles.deleteButton} type="button" onClick={deleteFile}>
                    <LazyImage
                        className={styles.image}
                        width="100%"
                        height="100%"
                        src={TrashIcon}
                        alt={strings.delete}
                        draggable={false}
                    />
                </button>
            </div>
            <div className={styles.fileName}>{fileName}</div>
        </div>
    );
}

type Props = {
    className?: string;
};

export default function FileList({ className }: Props) {
    const { file, selectedFiles, setSelectedFiles, setExistingFile, downloadFile } = useContext(AttachmentsContext);
    const [deleteFile, setDeleteFile] = useState<number>(-1);

    const [showConfirm, setShowConfirm] = useState(false);
    const doShowConfirm = useCallback(() => setShowConfirm(true), []);
    const doHideConfirm = useCallback(() => setShowConfirm(false), []);

    const [showConfirmSelection, setShowConfirmSelection] = useState(false);
    const doHideConfirmSelection = useCallback(() => setShowConfirmSelection(false), []);
    const doShowConfirmSelection = useCallback((index: number) => {
        setDeleteFile(index);
        setShowConfirmSelection(true);
    }, []);

    const onDelete = useCallback(() => {
        setExistingFile(undefined);
        doHideConfirm();
    }, [setExistingFile, doHideConfirm]);

    const onDeleteSelection = useCallback(
        (index) => {
            const files = selectedFiles.slice(0, index).concat(selectedFiles.slice(index + 1));
            setSelectedFiles(files);
            setDeleteFile(-1);
            doHideConfirmSelection();
        },
        [selectedFiles, setSelectedFiles, doHideConfirmSelection],
    );

    const downloadSelectedFile = useCallback(
        (index) => {
            if (selectedFiles) {
                fileDownload(selectedFiles[index], selectedFiles[index].name);
            }
        },
        [selectedFiles],
    );

    return (
        <div className={cx(styles.listContainer, className)}>
            {file &&
                renderFileIcon(
                    file.orginalFileName,
                    () => downloadFile(file.id, file.orginalFileName, true),
                    doShowConfirm,
                    "File-1",
                )}

            {selectedFiles.map((_, index) =>
                renderFileIcon(
                    selectedFiles[index].name,
                    () => downloadSelectedFile(index),
                    () => doShowConfirmSelection(index),
                    `File-${index}`,
                ),
            )}

            {file && (
                <FileDeleteConfirmationModal
                    message={
                        strings.formatString(strings.confirmDeleteFile, file.orginalFileName || file.fileName) as string
                    }
                    show={showConfirm}
                    onClose={doHideConfirm}
                    onDelete={onDelete}
                />
            )}

            {deleteFile > -1 && (
                <FileDeleteConfirmationModal
                    message={strings.formatString(strings.confirmDeleteFile, selectedFiles[deleteFile].name) as string}
                    show={showConfirmSelection}
                    onClose={doHideConfirmSelection}
                    onDelete={() => onDeleteSelection(deleteFile)}
                />
            )}
        </div>
    );
}
