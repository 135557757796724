import React, { ReactNode, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSession from "../components/core/LoadingSession/LoadingSession";
import { ProfileContext } from "../contexts/ProfileContext";
import { shouldBounce } from "../utils.ts/BouncerUtils";
import { PermissionCheck } from "./Permissions";

type Props = {
    permissions?: PermissionCheck[];
    element: ReactNode;
};

const Bouncer = ({ permissions, element }: Props) => {
    const { profile: user } = useContext(ProfileContext);
    const navigate = useNavigate();

    useEffect(() => {
        // no user means we should wait for it to be loaded
        if (user && shouldBounce(permissions, user)) {
            navigate("/");
        }
    }, [permissions, user, navigate]);

    if (shouldBounce(permissions, user)) {
        return <LoadingSession />;
    }

    return <>{element}</>;
};

export default Bouncer;
