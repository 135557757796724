import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import strings from "../../localization/strings";
import TextButton from "../core/TextButton/TextButton";

interface Props {
    to: string;
}

export const OrganisationInfoHeader = ({ to }: Props) => {
    const navigate = useNavigate();

    const openNewPageForm = useCallback(() => {
        navigate(to);
    }, [navigate, to]);

    return <TextButton text={strings.newOrganisationInfo} onClick={openNewPageForm} />;
};
