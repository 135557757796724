import { canViewUsers } from "../authentication/Permissions";
import useTabRoute from "./UseTabRoute";
import usePartnerAccountsTab from "../components/Accounts/PartnerAccountsContainers";
import useBackofficeAccountsTab from "../components/Accounts/BackofficeAccountsContainers";
import useParticipantAccountsTab from "../components/Accounts/ParticipantAccountsContainers";
import useImportUsersTab from "../components/Accounts/ImportUsersTab";

const useUserRoute = () => {
    const userTabs = [
        useParticipantAccountsTab(),
        usePartnerAccountsTab(),
        useBackofficeAccountsTab(),
        useImportUsersTab(),
    ];

    return useTabRoute("users", userTabs, [canViewUsers]);
};

export default useUserRoute;
