import { ReactNode, useContext, useMemo, useState } from "react";
import { Column } from "react-table";
import { canManageHappeningParticipants } from "../../../authentication/Permissions";
import { ProfileContext } from "../../../contexts/ProfileContext";
import strings from "../../../localization/strings";
import { HappeningViewModel, TeamViewModel } from "../../../openapi/backend";
import { getName } from "../../../utils.ts/GetName";
import SearchInput from "../../core/Inputs/SearchInput/SearchInput";
import StaticTable from "../../core/StaticTable/StaticTable";
import TeamRegistration from "../../core/TeamRegistration/TeamRegistration";

export type RegistrationsTableProps = {
    teams: TeamViewModel[];
    happening: HappeningViewModel;
    searchable?: boolean;
};

type TableItem = {
    Team: ReactNode;
};

const columns: Column<TableItem>[] = [
    {
        Header: "",
        accessor: "Team",
        width: "100%",
    },
];

export default function TeamsTable({ teams, happening, searchable }: RegistrationsTableProps) {
    const { profile } = useContext(ProfileContext);
    const canManageParticipants = canManageHappeningParticipants(happening, profile);
    const [query, setQuery] = useState("");

    const data = useMemo<TableItem[]>(() => {
        const normalizedQuery = query.trim().toLowerCase();
        return teams
            .filter(
                (t) =>
                    t.teamName.toLowerCase().includes(normalizedQuery) ||
                    t.members.some((m) => getName(m).toLowerCase().includes(normalizedQuery)),
            )
            .map((team, index) => {
                return {
                    Team: (
                        <TeamRegistration
                            key={index}
                            team={team}
                            happening={happening}
                            isAllowedToManage={canManageParticipants}
                        />
                    ),
                };
            });
    }, [teams, happening, canManageParticipants, query]);

    return (
        <div>
            {searchable && <SearchInput value={query} onSearch={setQuery} placeholder={strings.searchRegistration} />}
            <StaticTable columns={columns} data={data} emptyString={strings.noResultsFound} itemsPerPage={5} />
        </div>
    );
}
