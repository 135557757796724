import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterInclusion } from "../openapi/backend";
import { findOptionsByNames, NamedOption } from "../utils.ts/NamedOption";

export type SearchParamValue = string | null;

// Use instead of useSearchParams to keep track of one specific parameter
export default function useSearchParam(key: string): [SearchParamValue, (value: SearchParamValue) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = searchParams.get(key);

    const setSearchParam = useCallback(
        (value: SearchParamValue) => {
            const newParams = new URLSearchParams(searchParams);

            if (value === null) {
                newParams.delete(key);
            } else {
                newParams.set(key, value);
            }

            setSearchParams(newParams);
        },
        [searchParams, setSearchParams, key],
    );

    return [value, setSearchParam];
}

export function useArraySearchParam(key: string): [string[], (value: string[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = searchParams.getAll(key);

    const setArraySearchParam = useCallback(
        (value: string[]) => {
            const newParams = new URLSearchParams(searchParams);
            newParams.delete(key);
            value?.forEach((item) => newParams.append(key, item));

            setSearchParams(newParams);
        },
        [searchParams, setSearchParams, key],
    );

    return [value, setArraySearchParam];
}

export enum FilterType {
    String,
    Date,
    NamedOptions,
    Inclusion,
    PageNumber,
}
export class StringFilter {
    type: FilterType.String = FilterType.String;
}
export class DateFilter {
    type: FilterType.Date = FilterType.Date;
}
export class NamedOptionsFilter {
    type: FilterType.NamedOptions = FilterType.NamedOptions;
    options: NamedOption[];

    constructor(availableOptions: NamedOption[]) {
        this.options = availableOptions;
    }
}
export class InclusionFilter {
    type: FilterType.Inclusion = FilterType.Inclusion;
}
export class PageNumberFilter {
    type: FilterType.PageNumber = FilterType.PageNumber;
}

export type FilterSpec = StringFilter | DateFilter | NamedOptionsFilter | InclusionFilter | PageNumberFilter;
export type FilterSpecs = { [key: string]: FilterSpec };

type StringFilterValue = string | undefined;
type DateFilterValue = Date | undefined;
type NamedOptionsFilterValue = NamedOption[] | undefined;
type InclusionFilterValue = FilterInclusion | undefined;
type PageNumberFilterValue = number | undefined;
export type FilterValue =
    | StringFilterValue
    | DateFilterValue
    | NamedOptionsFilterValue
    | InclusionFilterValue
    | PageNumberFilterValue;
export type FilterValues = { [key: string]: FilterValue };

export function GetSearchParamsFromFilters(specs: FilterSpecs, values: FilterValues, searchParams?: URLSearchParams) {
    const newParams = new URLSearchParams(searchParams);

    for (const [key, filter] of Object.entries(specs)) {
        switch (filter.type) {
            case FilterType.String:
                const stringValue = values[key] as StringFilterValue;
                if (stringValue) {
                    newParams.set(key, stringValue);
                } else {
                    newParams.delete(key);
                }
                break;
            case FilterType.Inclusion:
                const inclusionValue = values[key] as InclusionFilterValue;
                if (inclusionValue) {
                    newParams.set(key, inclusionValue);
                } else {
                    newParams.delete(key);
                }
                break;
            case FilterType.Date:
                const dateValue = values[key] as DateFilterValue;
                if (dateValue) {
                    newParams.set(key, dateValue.toISOString());
                } else {
                    newParams.delete(key);
                }
                break;
            case FilterType.NamedOptions:
                newParams.delete(key);

                const namedOptionsValue = values[key] as NamedOptionsFilterValue;
                namedOptionsValue?.forEach((val) => newParams.append(key, val.name));
                break;
            case FilterType.PageNumber:
                const pageNumberValue = values[key] as PageNumberFilterValue;
                if (pageNumberValue) {
                    newParams.set(key, pageNumberValue.toString());
                }
                break;
        }
    }

    return newParams;
}

// Use filters that are stored in the search params of the URL
export function useFilters(filters: FilterSpecs): [FilterValues, (values: FilterValues) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const filterValues = useMemo(() => {
        const values: FilterValues = {};

        for (const [key, filter] of Object.entries(filters)) {
            switch (filter.type) {
                case FilterType.Inclusion:
                case FilterType.String:
                    values[key] = searchParams.get(key) || undefined;
                    break;
                case FilterType.Date:
                    const value = searchParams.get(key);
                    values[key] = value ? new Date(value) : undefined;
                    break;
                case FilterType.NamedOptions:
                    const names = searchParams.getAll(key);
                    values[key] = findOptionsByNames(names, filter.options);
                    break;
                case FilterType.PageNumber:
                    const pageNumber = searchParams.get(key);
                    values[key] = pageNumber ? parseInt(pageNumber) : undefined;
                    break;
            }
        }

        return values;
    }, [searchParams, filters]);

    const setFilterValues = useCallback(
        (values: FilterValues) => {
            const newParams = GetSearchParamsFromFilters(filters, values, searchParams);

            setSearchParams(newParams);
        },
        [searchParams, setSearchParams, filters],
    );

    return [filterValues, setFilterValues];
}
