import { useCallback, useContext } from "react";
import { RegistrationApiContext } from "../contexts/RegistrationApiContext";
import {
    AttendOutput,
    AttendRequest,
    CreateAppointmentRequest,
    RegisterRequest,
    UnsubscribeRequest,
} from "../openapi/backend";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, useApiCallback } from "./UseApiCall";

export type AttendInput = Omit<AttendRequest, "version">;
export const useAttend = (): [ApiCallState<AttendOutput>, (input: AttendInput) => void, () => void] => {
    const api = useContext(RegistrationApiContext);
    const callback = useCallback(
        (input: AttendInput) => api.attend({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );

    return useApiCallback(callback);
};

export type UnsubscribeInput = Omit<UnsubscribeRequest, "version">;
export const useUnsubscribe = (): [ApiCallState<void>, (input: UnsubscribeInput) => void, () => void] => {
    const api = useContext(RegistrationApiContext);
    const callback = useCallback(
        (input: UnsubscribeInput) => {
            return api.unsubscribe({ version: REACT_APP_BACKEND_VERSION, ...input });
        },
        [api],
    );

    return useApiCallback(callback);
};

export type AppointmentInput = Omit<CreateAppointmentRequest, "version" | "registrationId">;
export const useCreateAppointment = (registrationId: string) => {
    const api = useContext(RegistrationApiContext);
    const callback = useCallback(
        (input: AppointmentInput) =>
            api.createAppointment({ version: REACT_APP_BACKEND_VERSION, registrationId, ...input }),
        [api, registrationId],
    );

    return useApiCallback(callback);
};

// Only use for registering participants as an editor/partner
export type RegisterInput = Pick<RegisterRequest, "happeningId" | "existingProfiles">;
export const useRegister = () => {
    const api = useContext(RegistrationApiContext);
    const callback = useCallback(
        (input: RegisterInput) => {
            return api.register({ version: REACT_APP_BACKEND_VERSION, skipPayment: true, ...input });
        },
        [api],
    );

    return useApiCallback(callback);
};

export const useRefer = (registrationId: string) => {
    const api = useContext(RegistrationApiContext);
    const callback = useCallback(
        (happeningId: string) => {
            return api.refer({ version: REACT_APP_BACKEND_VERSION, happeningId, registrationId });
        },
        [api, registrationId],
    );

    return useApiCallback(callback);
};
