import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetIndicatorCallback, useMeasureIndicator } from "../../../hooks/IndicatorHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { Operation } from "../../../types/Operation";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import MeasurementForm from "./MeasurementForm";

export default function CreateMeasurementForm() {
    const { id } = useParams<{ id: string }>();

    // Should never happen because of routing
    if (id === undefined) {
        throw new Error("Indicator id is required");
    }

    const idNumber = parseInt(id, 10);
    const [{ value: indicator, state, error }, getIndicator] = useGetIndicatorCallback();
    const saveCallback = useMeasureIndicator(idNumber);

    useEffect(() => {
        getIndicator(idNumber);
    }, [getIndicator, idNumber]);

    if ([RequestState.LOADING, RequestState.IDLE].includes(state)) {
        return <InlineLoading />;
    }

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (!indicator) {
        return <div>{strings.indicatorNotFound}</div>;
    }

    return <MeasurementForm saveCallback={saveCallback} indicator={indicator} operation={Operation.CREATE} />;
}
