import React, { useCallback, ReactNode } from "react";
import styles from "./RadioGroupInput.module.scss";

interface RadioInputType<T> {
    label: string;
    value: T;
    tail?: ReactNode;
}

export type Props<T extends string> = {
    name?: string;
    options: Array<RadioInputType<T>>;
    onChange?: (value: T) => void;
    value?: T;
    required?: boolean;
    disabled?: boolean;
};

function RadioGroupInput<T extends string>({ name, options, onChange, value, ...props }: Props<T>) {
    const onRadioChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            if (!onChange) {
                return;
            }

            onChange(e.target.value as T);
        },
        [onChange],
    );

    return (
        <div className={styles.container}>
            {options.map(({ label, value: v, tail }) => (
                <div className={styles.optionContainer} key={v}>
                    <label className={styles.label}>
                        <input
                            type="radio"
                            data-testid={`RadioGroupInput_${v}`}
                            className={styles.input}
                            name={name}
                            value={v}
                            checked={value === v}
                            onChange={onRadioChange}
                            {...props}
                        />
                        <span className={styles.text}>{label}</span>
                    </label>
                    {tail}
                </div>
            ))}
        </div>
    );
}

export default RadioGroupInput;
