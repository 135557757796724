import cx from "classnames";
import { useMemo } from "react";
import { AppointmentViewModel } from "../../../openapi/backend";
import styles from "./AppointmentDataItem.module.scss";

interface AppointmentItemProps {
    appointment: AppointmentViewModel;
    text: string;
}

export default function AppointmentDataItem({ appointment, text }: AppointmentItemProps) {
    const isPast = useMemo(() => appointment.sessionEnd < new Date(), [appointment.sessionEnd]);

    return (
        <span className={cx(styles.container, appointment.cancelled && styles.cancelled, isPast && styles.past)}>
            {text}
        </span>
    );
}
