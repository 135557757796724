import styles from "./SimpleTextButton.module.scss";
import cx from "classnames";

type DisplayType = "default" | "secondary" | "tertiary";

interface SimpleTextButtonProps {
    text: string;
    onClick?: () => void;
    type?: DisplayType;
    className?: string;
}

export default function SimpleTextButton({ className, text, onClick, type = "default" }: SimpleTextButtonProps) {
    return (
        <button
            className={cx(styles.simpleButton, styles[type], className)}
            onClick={onClick}
            aria-label={text}
            type={"button"}
        >
            {text}
        </button>
    );
}
