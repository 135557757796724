/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    AnswerInput,
    AnswerInputFromJSON,
    AnswerInputToJSON,
    IndicatorStatus,
    IndicatorStatusFromJSON,
    IndicatorStatusToJSON,
    MeasurementListOutputPaginatedViewModel,
    MeasurementListOutputPaginatedViewModelFromJSON,
    MeasurementListOutputPaginatedViewModelToJSON,
    MeasurementOrderBy,
    MeasurementOrderByFromJSON,
    MeasurementOrderByToJSON,
    MeasurementOutput,
    MeasurementOutputFromJSON,
    MeasurementOutputToJSON,
    MeasurementStatus,
    MeasurementStatusFromJSON,
    MeasurementStatusToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from "../models";

export interface DeleteMeasurementRequest {
    id: number;
    version: string;
}

export interface GetMeasurementRequest {
    id: number;
    version: string;
}

export interface GetMeasurementsRequest {
    version: string;
    measurers?: Array<string>;
    sanitizedQuery?: string;
    query?: string;
    date?: Date;
    organisations?: Array<string>;
    organizers?: Array<string>;
    indicatorStatuses?: Array<IndicatorStatus>;
    statuses?: Array<MeasurementStatus>;
    orderBy?: MeasurementOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
}

export interface GetMyMeasurementsRequest {
    version: string;
    query?: string;
    date?: Date;
    organisations?: Array<string>;
    organizers?: Array<string>;
    indicatorStatuses?: Array<IndicatorStatus>;
    statuses?: Array<MeasurementStatus>;
    orderBy?: MeasurementOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
}

export interface UpdateMeasurementRequest {
    id: number;
    version: string;
    status?: MeasurementStatus;
    notes?: string;
    answers?: Array<AnswerInput>;
    profileTargetId?: string;
    tagTargetId?: string;
    createdAt?: Date;
    contributors?: Array<string>;
    collaborators?: Array<string>;
}

/**
 * no description
 */
export class MeasurementsApi extends runtime.BaseAPI {
    /**
     */
    async deleteMeasurementRaw(requestParameters: DeleteMeasurementRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteMeasurement.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteMeasurement.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Measurements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteMeasurement(requestParameters: DeleteMeasurementRequest): Promise<void> {
        await this.deleteMeasurementRaw(requestParameters);
    }
    /**
     */
    async getMeasurementRaw(requestParameters: GetMeasurementRequest): Promise<runtime.ApiResponse<MeasurementOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getMeasurement.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMeasurement.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Measurements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementOutputFromJSON(jsonValue));
    }
    /**
     */
    async getMeasurement(requestParameters: GetMeasurementRequest): Promise<MeasurementOutput> {
        const response = await this.getMeasurementRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMeasurementsRaw(
        requestParameters: GetMeasurementsRequest,
    ): Promise<runtime.ApiResponse<MeasurementListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMeasurements.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.measurers && requestParameters.measurers.length > 0) {
            queryParameters["Measurers"] = requestParameters.measurers;
        }
        if (requestParameters.sanitizedQuery !== undefined) {
            queryParameters["SanitizedQuery"] = requestParameters.sanitizedQuery;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.date !== undefined) {
            queryParameters["Date"] = (requestParameters.date as any).toUTCString();
        }
        if (requestParameters.organisations && requestParameters.organisations.length > 0) {
            queryParameters["Organisations"] = requestParameters.organisations;
        }
        if (requestParameters.organizers && requestParameters.organizers.length > 0) {
            queryParameters["Organizers"] = requestParameters.organizers;
        }
        if (requestParameters.indicatorStatuses && requestParameters.indicatorStatuses.length > 0) {
            queryParameters["IndicatorStatuses"] = requestParameters.indicatorStatuses;
        }
        if (requestParameters.statuses && requestParameters.statuses.length > 0) {
            queryParameters["Statuses"] = requestParameters.statuses;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Measurements`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            MeasurementListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getMeasurements(requestParameters: GetMeasurementsRequest): Promise<MeasurementListOutputPaginatedViewModel> {
        const response = await this.getMeasurementsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getMyMeasurementsRaw(
        requestParameters: GetMyMeasurementsRequest,
    ): Promise<runtime.ApiResponse<MeasurementListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getMyMeasurements.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.date !== undefined) {
            queryParameters["Date"] = (requestParameters.date as any).toUTCString();
        }
        if (requestParameters.organisations && requestParameters.organisations.length > 0) {
            queryParameters["Organisations"] = requestParameters.organisations;
        }
        if (requestParameters.organizers && requestParameters.organizers.length > 0) {
            queryParameters["Organizers"] = requestParameters.organizers;
        }
        if (requestParameters.indicatorStatuses && requestParameters.indicatorStatuses.length > 0) {
            queryParameters["IndicatorStatuses"] = requestParameters.indicatorStatuses;
        }
        if (requestParameters.statuses && requestParameters.statuses.length > 0) {
            queryParameters["Statuses"] = requestParameters.statuses;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Measurements/my`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            MeasurementListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getMyMeasurements(
        requestParameters: GetMyMeasurementsRequest,
    ): Promise<MeasurementListOutputPaginatedViewModel> {
        const response = await this.getMyMeasurementsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateMeasurementRaw(
        requestParameters: UpdateMeasurementRequest,
    ): Promise<runtime.ApiResponse<MeasurementOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateMeasurement.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateMeasurement.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.status !== undefined) {
            formParams.append("Status", requestParameters.status as any);
            hasParams = true;
        }
        if (requestParameters.notes !== undefined) {
            formParams.append("Notes", requestParameters.notes as any);
            hasParams = true;
        }
        if (requestParameters.answers) {
            requestParameters.answers.forEach((element, index) => {
                appendToParams(formParams, `Answers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.profileTargetId !== undefined) {
            formParams.append("ProfileTargetId", requestParameters.profileTargetId as any);
            hasParams = true;
        }
        if (requestParameters.tagTargetId !== undefined) {
            formParams.append("TagTargetId", requestParameters.tagTargetId as any);
            hasParams = true;
        }
        if (requestParameters.createdAt !== undefined) {
            formParams.append("CreatedAt", (requestParameters.createdAt as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.contributors) {
            requestParameters.contributors.forEach((element, index) => {
                appendToParams(formParams, `Contributors[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.collaborators) {
            requestParameters.collaborators.forEach((element, index) => {
                appendToParams(formParams, `Collaborators[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/Measurements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MeasurementOutputFromJSON(jsonValue));
    }
    /**
     */
    async updateMeasurement(requestParameters: UpdateMeasurementRequest): Promise<MeasurementOutput> {
        const response = await this.updateMeasurementRaw(requestParameters);
        return await response.value();
    }
}
