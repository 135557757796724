import React, { useEffect, useMemo, useState } from "react";
import { useTable, Column, useSortBy, usePagination } from "react-table";
import styles from "./Table.module.scss";
import ChevronDown from "../../../assets/chevron_down.svg";
import ChevronUp from "../../../assets/chevron_up.svg";
import strings from "../../../localization/strings";
import { PaginationMeta } from "../../../openapi/backend";
import ReactPaginate from "react-paginate";
import cx from "classnames";
import { doNothing } from "../../../utils.ts/DoNothingCallback";

export interface TableProps<T extends object> {
    columns: Column<T>[];
    data: T[];
    emptyString?: string;
    rowSelectable?: boolean;
    renderHeader?: boolean;
    onRowClick?: (rowItem: T) => void;
    onSort?: (sortBy: any) => void;
    fixedLayout?: boolean;

    // Pagination
    paginationMeta?: PaginationMeta;
    setPage?: (page: number) => void;
    className?: string;
    forcePage?: number;
    showPagination?: boolean;
}

export default function Table<T extends object>({
    columns,
    data,
    renderHeader = true,
    emptyString = "",
    rowSelectable = true,
    onSort,
    onRowClick = doNothing,
    fixedLayout,
    paginationMeta,
    setPage,
    className,
    forcePage,
    showPagination = true,
}: TableProps<T>) {
    const [metaData, setMetaData] = useState({} as PaginationMeta);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageCount,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
            manualPagination: true,
            pageCount: metaData.totalPages,
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        if (paginationMeta) {
            setMetaData(paginationMeta);
        }
    }, [paginationMeta]);

    useEffect(() => {
        onSort && onSort(sortBy);
    }, [onSort, sortBy]);

    const headerCells = useMemo(
        () =>
            headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                        <React.Fragment key={index}>
                            {onSort && !column.disableSortBy && (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={styles.sortable}
                                    style={{ width: column.width }}
                                >
                                    <button
                                        className={styles.button}
                                        aria-label={`${strings.sort} ${column.render("Header")} ${
                                            column.isSorted
                                                ? column.isSortedDesc
                                                    ? strings.descending
                                                    : strings.ascending
                                                : ""
                                        }`}
                                    >
                                        {column.render("Header")}
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <img
                                                        src={ChevronDown}
                                                        className={styles.sortButton}
                                                        alt={`${strings.sort} ${column.render("Header")}`}
                                                    />
                                                ) : (
                                                    <img
                                                        src={ChevronUp}
                                                        className={styles.sortButton}
                                                        alt={`${strings.sort} ${column.render("Header")}`}
                                                    />
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </span>
                                    </button>
                                </th>
                            )}
                            {(!onSort || column.disableSortBy) && (
                                <th {...column.getHeaderProps()} style={{ width: column.width }}>
                                    {column.render("Header")}
                                </th>
                            )}
                        </React.Fragment>
                    ))}
                </tr>
            )),
        [headerGroups, onSort],
    );

    const rowCells = useMemo(
        () =>
            page.map((row) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()} onClick={() => onRowClick(row.original)}>
                        {row.cells.map((cell, index) => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {rowSelectable ? (
                                        <button className={styles.button} tabIndex={index === 0 ? 0 : -1}>
                                            {cell.render("Cell")}
                                        </button>
                                    ) : (
                                        <div tabIndex={index === 0 ? 0 : -1}>{cell.render("Cell")}</div>
                                    )}
                                </td>
                            );
                        })}
                    </tr>
                );
            }),
        [page, prepareRow, onRowClick, rowSelectable],
    );

    const tableProps = getTableProps();
    return (
        <div className={cx(styles.table, className)}>
            <table {...tableProps} className={cx(tableProps.className, fixedLayout && styles.fixedLayout)}>
                {renderHeader && <thead>{headerCells}</thead>}
                {rowCells.length > 0 ? (
                    <tbody {...getTableBodyProps()} className={rowSelectable ? styles.rowSelectable : ""}>
                        {rowCells}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            {/* Quickfix delayed fade-in so I don't see emptyString all the time */}
                            <td colSpan={columns.length} className={styles.fadeIn}>
                                {emptyString}
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>

            {showPagination && (
                <ReactPaginate
                    nextLabel={<img src={ChevronUp} className={styles.paginationChevron} alt={strings.next} />}
                    onPageChange={(page) => setPage && setPage(page.selected + 1)}
                    pageCount={pageCount || 1}
                    previousLabel={
                        <img src={ChevronDown} className={styles.paginationChevron} alt={strings.previous} />
                    }
                    containerClassName={styles.pagination}
                    disabledClassName={styles.paginationLinkDisabled}
                    activeClassName={styles.paginationLinkActive}
                    forcePage={Math.max(forcePage ? forcePage - 1 : 0, 0)}
                />
            )}
        </div>
    );
}
