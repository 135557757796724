import * as yup from "yup";
import { AppointmentInput } from "../../../hooks/RegistrationHooks";
import { HappeningTimeAndDateInput, LocationType } from "../../../openapi/backend";
import { futureTimeAndDateSchema, safeNumber } from "../../../utils.ts/Validation";

export type FormData = Omit<AppointmentInput, "sessions"> & {
    timeAndDates: HappeningTimeAndDateInput[];
    locationType: LocationType;
};

export const LOCATION_REQUIRED_TYPES = [LocationType.OnLocation, LocationType.ToBeDetermined, LocationType.AtHome];

export const validationSchema: yup.SchemaOf<FormData> = yup.object({
    locationAddress: yup.string().when("locationType", {
        is: (t: LocationType) => LOCATION_REQUIRED_TYPES.includes(t),
        then: (schema) => schema.required(),
    }),
    locationName: yup.string().when("locationType", {
        is: (t: LocationType) => LOCATION_REQUIRED_TYPES.includes(t),
        then: (schema) => schema.required(),
    }),
    locationLongitude: yup.mixed().when("locationType", {
        is: (t: LocationType) => LOCATION_REQUIRED_TYPES.includes(t),
        then: safeNumber.required(),
        otherwise: safeNumber,
    }),
    locationLatitude: yup.mixed().when("locationType", {
        is: (t: LocationType) => LOCATION_REQUIRED_TYPES.includes(t),
        then: safeNumber.required(),
        otherwise: safeNumber,
    }),
    timeAndDates: yup.array(futureTimeAndDateSchema).min(1),
    locationType: yup.mixed().oneOf(Object.values(LocationType)),
    appointer: yup.string().required(),
    notes: yup.string(),
});
