import styles from "./RoundButton.module.scss";
import strings from "../../../localization/strings";
import HiddenIcon from "../../../assets/hidden_icon.svg";
import VisibleIcon from "../../../assets/visible_icon.svg";
import QuickAddButtonIcon from "../../../assets/quick_add_button.svg";
import RemoveButtonIcon from "../../../assets/remove_icon.svg";
import CancelButtonIcon from "../../../assets/cross.svg";

interface ButtonProps {
    onClick: () => void;
    label?: string;
    className?: string;
    disabled?: boolean;
}

interface VisibilityButtonProps extends ButtonProps {
    isActive: boolean;
    targetLabel: string;
}

interface RoundButtonProps extends ButtonProps {
    icon: string;
    label: string;
    alternate?: boolean;
}

const RoundButton = ({ icon, label, onClick, alternate, className, disabled }: RoundButtonProps) => (
    <button
        className={`${styles.roundButton} ${(alternate || disabled) && styles.alternate} ${className}`}
        aria-label={label}
        disabled={disabled}
        onClick={onClick}
        type="button"
    >
        <img src={icon} alt={label} />
    </button>
);

export const VisibilityButton = ({ onClick, isActive, targetLabel, ...props }: VisibilityButtonProps) => (
    <RoundButton
        onClick={onClick}
        label={`${isActive ? strings.hide : strings.show} ${targetLabel}`}
        icon={isActive ? VisibleIcon : HiddenIcon}
        alternate={!isActive}
        {...props}
    />
);

export const QuickAddButton = ({ onClick, label, ...props }: ButtonProps) => (
    <RoundButton onClick={onClick} label={label || strings.add} icon={QuickAddButtonIcon} {...props} />
);

export const RemoveButton = ({ onClick, label, ...props }: ButtonProps) => (
    <RoundButton onClick={onClick} label={strings.remove} alternate {...props} icon={RemoveButtonIcon} />
);

export const CancelButton = ({ onClick, label, ...props }: ButtonProps) => (
    <RoundButton onClick={onClick} label={strings.remove} alternate {...props} icon={CancelButtonIcon} />
);
