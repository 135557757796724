import moment from "moment";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import DatePicker from "../../Inputs/DatePicker/DatePicker";
import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import { FieldName } from "../FormTypes";
import { useMemo } from "react";

type ValidationType = "maxDate" | "max1Year" | "max1YearPeriod";
interface DatepickerProps {
    placeholder?: string;
    value?: Date;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    validationType?: ValidationType;
    readOnly?: boolean;
}
interface FormDatepickerProps<TValue extends Date, TForm extends FieldValues, TName extends FieldName<TForm, TValue>>
    extends Omit<DatepickerProps, "onChange"> {
    title?: string;
    name: TName | any;
    control: Control<TForm>;
    required?: boolean;
    useUtc?: boolean;
    errors?: FieldError | FieldError[];
}

function validateMaxDate(maxDate?: Date, useUtc?: boolean) {
    if (!maxDate) {
        return () => true;
    }

    const date = moment(maxDate).utc(useUtc);

    return (value: any) => value && moment(value).utc(useUtc).isSameOrBefore(date, "date");
}

export default function FormDatepicker<
    TValue extends Date,
    TForm extends FieldValues,
    TName extends FieldName<TForm, TValue>,
>({
    placeholder,
    value,
    name,
    title = "",
    errors,
    required,
    useUtc,
    control,
    validationType = "maxDate",
    ...fieldProps
}: FormDatepickerProps<TValue, TForm, TName>) {
    const customValidation = useMemo(() => {
        const validationObject: any = {};

        switch (validationType) {
            case "max1Year":
                validationObject.sessionMax1Year = validateMaxDate(fieldProps.maxDate, useUtc);
                break;
            case "max1YearPeriod":
                validationObject.periodMax1Year = validateMaxDate(fieldProps.maxDate, useUtc);
                break;
            case "maxDate":
                validationObject.maxDate = validateMaxDate(fieldProps.maxDate, useUtc);
        }

        return validationObject;
    }, [validationType, fieldProps.maxDate, useUtc]);
    return (
        <FormFieldContainer name={name} label={title} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required, validate: fieldProps.maxDate ? customValidation : undefined }}
                render={({ field: { ref, onChange, ...field } }) => {
                    const parsedDate = value && useUtc ? moment(value).utc(useUtc).startOf("day").toDate() : value;
                    return (
                        <DatePicker
                            {...fieldProps}
                            {...field}
                            onChange={(...args) => {
                                const [date, ...tail] = args;
                                const modifiedArgs = [
                                    date && useUtc ? moment(date).utc(useUtc).startOf("day").toDate() : date,
                                    ...tail,
                                ];
                                // Our code doesn't like null values, but undefined is fine
                                if (modifiedArgs[0] === null) {
                                    modifiedArgs[0] = undefined;
                                }
                                return onChange(...modifiedArgs);
                            }}
                            placeholderText={placeholder}
                            selected={parsedDate}
                        />
                    );
                }}
            />
        </FormFieldContainer>
    );
}
