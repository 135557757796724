import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useTagCollection } from "../hooks/TagHooks";
import { TagCollection } from "../openapi/backend/models/TagCollection";

const emptyCollection: TagCollection = {
    happeningGroups: [],
    happeningTypes: [],
    municipalities: [],
    others: [],
    partners: [],
    serviceGroups: [],
    targetGroups: [],
    categories: [],
    industries: [],
    internal: [],
    mediaCategories: [],
    mediaTags: [],
};

type TagData = {
    activeTags: TagCollection;
    allTags: TagCollection;
};
const emptyTagData: TagData = {
    // Should be used as options when creating / updating objects
    activeTags: { ...emptyCollection },
    // Should only be used for searching / filtering
    allTags: { ...emptyCollection },
};

const filterOnlyActive = (collection: TagCollection): TagCollection => {
    const activeTagCollection = { ...emptyCollection };

    for (let [key, value] of Object.entries(collection) as [keyof TagCollection, any[]][]) {
        activeTagCollection[key] = value.filter((tag) => tag.isActive);
    }

    return activeTagCollection;
};

/**
 * Context that provides the Tag data. undefined data means it's still being loaded so act accordingly.
 */
export const TagsContext = React.createContext<TagContext>({
    ...emptyTagData,
    refresh() {},
});

export interface TagContext extends TagData {
    refresh: () => void;
}

export const TagsContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [{ value }, refreshTags] = useTagCollection();
    const [allTags, setAllTags] = useState<TagCollection>(emptyCollection);

    const refreshTagData = useCallback(
        () =>
            refreshTags({
                filterInactive: false, // Also get inactive tags
                compactOnly: true, // Only compact tags as SBG regularly only wants those as options in dropdowns. Dropdowns with search options can fetch the non-compact ones.
            }),
        [refreshTags],
    );

    useEffect(refreshTagData, [refreshTagData]);

    useEffect(() => {
        if (value) {
            setAllTags((current) => ({
                ...current,
                ...value,
            }));
        }
    }, [value, setAllTags]);

    const context = useMemo<TagContext>(() => {
        return {
            allTags,
            activeTags: filterOnlyActive(allTags),
            refresh: refreshTagData,
        };
    }, [allTags, refreshTagData]);

    return <TagsContext.Provider value={context}>{children}</TagsContext.Provider>;
};
