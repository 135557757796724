import React, { useMemo } from "react";
import { MAX_EXTRA_QUESTION_LENGTH } from "../../../../constants/Validation";
import strings from "../../../../localization/strings";
import { QuestionType } from "../../../../openapi/backend";
import { getStringForQuestionType } from "../../../../utils.ts/GetQuestionTypeString";
import FormFieldContainer from "../../Form/FormFieldContainer/FormFieldContainer";
import InputField from "../InputField/InputField";
import QuestionField, { QuestionFieldProps } from "../QuestionField/QuestionField";
import { StringSelectField } from "../SelectField/SelectField";
import styles from "./MultiTypeQuestionField.module.scss";
import CheckboxInput from "../CheckboxInput/CheckboxInput";

export type Props = QuestionFieldProps;

const typeOptions = [
    { label: strings.formIndicatorQuestionTypeNumeric, value: QuestionType.Numeric },
    { label: strings.formIndicatorQuestionTypeMultipleChoice, value: QuestionType.MultipleChoice },
];

export default function MultiTypeQuestionField(props: Props) {
    const { name, value, onChange, readOnly } = props;

    const localizedTypeOptions = useMemo(
        () => typeOptions.map((o) => ({ value: o.value, label: getStringForQuestionType(o.value) })),
        [],
    );

    return (
        <div className={styles.container}>
            <FormFieldContainer
                name={`${name}.type`}
                label={strings.formIndicatorQuestionTypeHeader}
                className={styles.type}
            >
                <StringSelectField
                    name={`${name}.type`}
                    options={localizedTypeOptions}
                    value={value.type}
                    placeholder=" "
                    onChange={(selected) => onChange({ ...value, type: selected as QuestionType })}
                    readOnly={readOnly}
                />
            </FormFieldContainer>
            <FormFieldContainer
                name={`${name}.title`}
                label={strings.formIndicatorQuestionHeader}
                className={styles.question}
            >
                {value.type === QuestionType.MultipleChoice ? (
                    <>
                        <QuestionField
                            hideExtraOptions
                            titlePlaceholder=""
                            {...props}
                            manipulationType="append"
                            rearrangeable
                        />
                        <CheckboxInput
                            className={styles.checkbox}
                            onChange={(checked) => onChange({ ...value, multipleOptionsAllowed: checked })}
                            name={`${name}.multipleOptionsAllowed`}
                            label={strings.formAllowMultipleOptions}
                            value={value?.multipleOptionsAllowed}
                            readOnly={readOnly}
                        />
                    </>
                ) : (
                    <InputField
                        name={`${name}.title`}
                        placeholder=""
                        value={value.title}
                        onChange={(e) => onChange({ ...value, title: e.currentTarget.value })}
                        maxLength={MAX_EXTRA_QUESTION_LENGTH}
                        readOnly={readOnly}
                    />
                )}
            </FormFieldContainer>
            <FormFieldContainer
                name={`${name}.isRequired`}
                label={strings.formIndicatorQuestionIsRequired}
                className={styles.isRequired}
            >
                <CheckboxInput
                    name={`${name}.isRequired`}
                    value={!!value.isRequired}
                    onChange={(isRequired) => onChange({ ...value, isRequired })}
                    readOnly={readOnly}
                />
            </FormFieldContainer>
        </div>
    );
}
