import React, { PropsWithChildren, useEffect } from "react";
import { AuthProvider, useAuth } from "react-oidc-context";
import { Routes, Route, useNavigate } from "react-router-dom";
import LoadingSession from "../components/core/LoadingSession/LoadingSession";
import oidcConfig from "../config/OidcConfig";
import { logoutKey } from "./useSignout";

/**
 * You'll reach here on succesful login with code, state, scope parameters. The library handles this automatically,
 * but we use this oppertunity to redirect the user so they aren't bothered with the weird url in screen.
 */
const RedirectCallback = () => {
    const { isLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        navigate("/");
    }, [isLoading, navigate]);

    return null;
};

/** Protects your children from unauthenticated users.
 *  Ensures that nested children will have access to a valid access token.
 *
 *  Notes on flow:
 *  On start, a token refresh is attempted.
 *  If that fails, the user is redirected for an active signin attempt.
 */
const Protected = ({ children }: PropsWithChildren<{}>) => {
    const { user, error, isAuthenticated, signinRedirect, isLoading } = useAuth();
    const readyAndAuthenticated = user && isAuthenticated;

    useEffect(() => {
        if ((!readyAndAuthenticated && !isLoading) || error) {
            signinRedirect();
        }
    }, [signinRedirect, readyAndAuthenticated, isLoading, error]);

    // Handle logout of other tabs
    useEffect(() => {
        var onStorageEvent = (event: StorageEvent) => {
            if (event.key === logoutKey) {
                // Don't redirect immediately because sign-out needs to finish first
                setTimeout(signinRedirect, 1000);
            }
        };

        window.addEventListener("storage", onStorageEvent);

        return () => window.removeEventListener("storage", onStorageEvent);
    }, [signinRedirect]);

    if (!readyAndAuthenticated) {
        return <LoadingSession />;
    }

    return <>{children}</>;
};

const Provider = ({ children }: PropsWithChildren<{}>) => {
    return (
        <AuthProvider {...oidcConfig}>
            <Routes>
                <Route path="/auth/callback" element={<RedirectCallback />} />
                <Route path="/*" element={<Protected>{children}</Protected>} />
            </Routes>
        </AuthProvider>
    );
};

export default Provider;
