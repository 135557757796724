import strings from "../localization/strings";

export function getDurationString(duration: number) {
    const hours = Math.floor(duration / 60);
    const remainderMinutes = duration % 60;

    if (hours > 0) {
        if (remainderMinutes > 0) {
            return `${hours} ${strings.hour} ${strings.and} ${remainderMinutes} ${strings.minutes}`;
        } else {
            return `${hours} ${strings.hour}`;
        }
    } else {
        return `${remainderMinutes} ${strings.minutes}`;
    }
}
