import { useCallback, useState } from "react";
import { UseFormRegister, FieldError } from "react-hook-form";
import { asNumber } from "../../../../utils.ts/Formatting";
import InputField from "../../Inputs/InputField/InputField";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import styles from "./FormField.module.scss";
import cx from "classnames";

export interface FormFieldProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    name: string;
    label?: string;
    register?: UseFormRegister<any>;
    errors?: FieldError;
    placeholder: string;
    required?: boolean;
    icon?: string;
    filterPattern?: RegExp;
    replaceFilter?: string;
    validationPattern?: {
        value: RegExp;
        message: string;
    };
    containerClassName?: string;
    inputClassName?: string;
}

export default function FormField({
    name,
    label,
    register,
    errors,
    required,
    icon,
    type,
    disabled,
    filterPattern,
    replaceFilter,
    validationPattern,
    maxLength,
    defaultValue,
    min,
    max,
    containerClassName,
    inputClassName,
    ...props
}: FormFieldProps) {
    const [innerDefaultValue, setDefaultValue] = useState(defaultValue);

    const filterClipboard = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            const pastedText = e.clipboardData.getData("text");
            const modifiedText = filterPattern ? pastedText.replace(filterPattern, "") : pastedText;
            e.currentTarget.value = replaceFilter ? replaceFilter + modifiedText : modifiedText;
            if (register && register(name).onChange) {
                register(name).onChange({ target: { name, value: modifiedText } });
            }
        },
        [register, name, filterPattern, replaceFilter],
    );

    const setValueAs = useCallback(
        (v: string | number | string[]) => {
            let value: any = v;
            if (type === "number") {
                value = asNumber(v);
            } else if ((type === "date" || type === "datetime-local") && typeof v === "string") {
                value = new Date(v as string);
            }

            if (value === innerDefaultValue) {
                return value;
            }

            setDefaultValue(value);
            return value;
        },
        [innerDefaultValue, type],
    );

    return (
        <FormFieldContainer name={name} label={label} errors={errors} className={containerClassName}>
            <InputField
                id={`input-${name}`}
                formRegistration={
                    register
                        ? register(name, {
                              required: required,
                              disabled,
                              pattern: validationPattern,
                              maxLength,
                              setValueAs,
                              min,
                              max,
                          })
                        : ({} as any)
                }
                className={cx(styles.formField, icon && styles.icon, inputClassName)}
                onPaste={filterPattern ? filterClipboard : undefined}
                style={{ backgroundImage: `url(${icon})` }}
                type={type}
                aria-required={required}
                maxLength={maxLength}
                defaultValue={innerDefaultValue}
                min={min}
                max={max}
                {...(errors ? { "aria-describedby": `${name}-error`, "aria-invalid": true } : {})}
                {...props}
            />
        </FormFieldContainer>
    );
}
