/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    IndicatorHappening,
    IndicatorHappeningFromJSON,
    IndicatorHappeningFromJSONTyped,
    IndicatorHappeningToJSON,
    IndicatorStatus,
    IndicatorStatusFromJSON,
    IndicatorStatusFromJSONTyped,
    IndicatorStatusToJSON,
    LinkTarget,
    LinkTargetFromJSON,
    LinkTargetFromJSONTyped,
    LinkTargetToJSON,
    MeasurementStatisticOutput,
    MeasurementStatisticOutputFromJSON,
    MeasurementStatisticOutputFromJSONTyped,
    MeasurementStatisticOutputToJSON,
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
    QuestionOutput,
    QuestionOutputFromJSON,
    QuestionOutputFromJSONTyped,
    QuestionOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface IndicatorOutput
 */
export interface IndicatorOutput {
    /**
     *
     * @type {number}
     * @memberof IndicatorOutput
     */
    id: number;
    /**
     *
     * @type {IndicatorStatus}
     * @memberof IndicatorOutput
     */
    status: IndicatorStatus;
    /**
     *
     * @type {string}
     * @memberof IndicatorOutput
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof IndicatorOutput
     */
    description?: string;
    /**
     *
     * @type {Date}
     * @memberof IndicatorOutput
     */
    createdAt: Date;
    /**
     *
     * @type {Date}
     * @memberof IndicatorOutput
     */
    updatedAt: Date;
    /**
     *
     * @type {Date}
     * @memberof IndicatorOutput
     */
    startDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof IndicatorOutput
     */
    endDate?: Date;
    /**
     *
     * @type {LinkTarget}
     * @memberof IndicatorOutput
     */
    linkTarget: LinkTarget;
    /**
     *
     * @type {boolean}
     * @memberof IndicatorOutput
     */
    includeNotes: boolean;
    /**
     *
     * @type {OrganizerViewModel}
     * @memberof IndicatorOutput
     */
    organisation?: OrganizerViewModel;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof IndicatorOutput
     */
    organizer?: ProfileShortOutput;
    /**
     *
     * @type {IndicatorHappening}
     * @memberof IndicatorOutput
     */
    happening?: IndicatorHappening;
    /**
     *
     * @type {Array<ProfileShortOutput>}
     * @memberof IndicatorOutput
     */
    measurers: Array<ProfileShortOutput>;
    /**
     *
     * @type {Array<QuestionOutput>}
     * @memberof IndicatorOutput
     */
    questions: Array<QuestionOutput>;
    /**
     *
     * @type {Array<MeasurementStatisticOutput>}
     * @memberof IndicatorOutput
     */
    statistics: Array<MeasurementStatisticOutput>;
    /**
     *
     * @type {boolean}
     * @memberof IndicatorOutput
     */
    canAddContributors: boolean;
    /**
     *
     * @type {boolean}
     * @memberof IndicatorOutput
     */
    canAddCollaborators: boolean;
}

/**
 * Check if a given object implements the IndicatorOutput interface.
 */
export function instanceOfIndicatorOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "linkTarget" in value;
    isInstance = isInstance && "includeNotes" in value;
    isInstance = isInstance && "measurers" in value;
    isInstance = isInstance && "questions" in value;
    isInstance = isInstance && "statistics" in value;
    isInstance = isInstance && "canAddContributors" in value;
    isInstance = isInstance && "canAddCollaborators" in value;

    return isInstance;
}

export function IndicatorOutputFromJSON(json: any): IndicatorOutput {
    return IndicatorOutputFromJSONTyped(json, false);
}

export function IndicatorOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : IndicatorStatusFromJSON(json["status"]),
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        description: !exists(json, "description") ? (undefined as any) : json["description"],
        createdAt: !exists(json, "createdAt") ? (undefined as any) : new Date(json["createdAt"]),
        updatedAt: !exists(json, "updatedAt") ? (undefined as any) : new Date(json["updatedAt"]),
        startDate: !exists(json, "startDate") ? (undefined as any) : new Date(json["startDate"]),
        endDate: !exists(json, "endDate") ? (undefined as any) : new Date(json["endDate"]),
        linkTarget: !exists(json, "linkTarget") ? (undefined as any) : LinkTargetFromJSON(json["linkTarget"]),
        includeNotes: !exists(json, "includeNotes") ? (undefined as any) : json["includeNotes"],
        organisation: !exists(json, "organisation")
            ? (undefined as any)
            : OrganizerViewModelFromJSON(json["organisation"]),
        organizer: !exists(json, "organizer") ? (undefined as any) : ProfileShortOutputFromJSON(json["organizer"]),
        happening: !exists(json, "happening") ? (undefined as any) : IndicatorHappeningFromJSON(json["happening"]),
        measurers: !exists(json, "measurers")
            ? (undefined as any)
            : ((json["measurers"] as Array<any>) ?? []).map(ProfileShortOutputFromJSON),
        questions: !exists(json, "questions")
            ? (undefined as any)
            : ((json["questions"] as Array<any>) ?? []).map(QuestionOutputFromJSON),
        statistics: !exists(json, "statistics")
            ? (undefined as any)
            : ((json["statistics"] as Array<any>) ?? []).map(MeasurementStatisticOutputFromJSON),
        canAddContributors: !exists(json, "canAddContributors") ? (undefined as any) : json["canAddContributors"],
        canAddCollaborators: !exists(json, "canAddCollaborators") ? (undefined as any) : json["canAddCollaborators"],
    };
}

export function IndicatorOutputToJSON(value?: IndicatorOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
