import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import DurationInput, { DurationInputProps } from "../../Inputs/DurationInput/DurationInput";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

interface FormDurationInputProps<TForm extends FieldValues, TName extends FieldName<TForm, number>>
    extends Omit<DurationInputProps, "onChange" | "value"> {
    name: TName;
    label: string;
    control: Control<TForm>;
    errors?: FieldError | FieldError[];
    required?: boolean;
}

export default function FormDurationInput<TForm extends FieldValues, TName extends FieldName<TForm, number>>({
    name,
    label,
    control,
    required,
    errors,
    ...props
}: FormDurationInputProps<TForm, TName>) {
    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value } }) => (
                    <DurationInput {...props} name={name} value={value} onChange={onChange} />
                )}
            />
        </FormFieldContainer>
    );
}
