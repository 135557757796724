/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    AnswerInput,
    AnswerInputFromJSON,
    AnswerInputToJSON,
    IndicatorListOutputPaginatedViewModel,
    IndicatorListOutputPaginatedViewModelFromJSON,
    IndicatorListOutputPaginatedViewModelToJSON,
    IndicatorOrderBy,
    IndicatorOrderByFromJSON,
    IndicatorOrderByToJSON,
    IndicatorOutput,
    IndicatorOutputFromJSON,
    IndicatorOutputToJSON,
    IndicatorStatus,
    IndicatorStatusFromJSON,
    IndicatorStatusToJSON,
    LinkTarget,
    LinkTargetFromJSON,
    LinkTargetToJSON,
    MeasurementStatus,
    MeasurementStatusFromJSON,
    MeasurementStatusToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    QuestionInput,
    QuestionInputFromJSON,
    QuestionInputToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from "../models";

export interface CreateIndicatorRequest {
    version: string;
    status: IndicatorStatus;
    name: string;
    measurers: Array<string>;
    questions: Array<QuestionInput>;
    description?: string;
    startDate?: Date;
    endDate?: Date;
    linkTarget?: LinkTarget;
    includeNotes?: boolean;
    organisationId?: string;
    organizerId?: string;
    happeningId?: string;
    canAddContributors?: boolean;
    canAddCollaborators?: boolean;
}

export interface DeleteIndicatorRequest {
    id: number;
    version: string;
}

export interface GetIndicatorRequest {
    id: number;
    version: string;
}

export interface GetIndicatorsRequest {
    version: string;
    query?: string;
    date?: Date;
    sanitizedDate?: Date;
    organisations?: Array<string>;
    organizers?: Array<string>;
    statuses?: Array<IndicatorStatus>;
    sanitizedQuery?: string;
    orderBy?: IndicatorOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
}

export interface MeasureRequest {
    id: number;
    version: string;
    status?: MeasurementStatus;
    notes?: string;
    answers?: Array<AnswerInput>;
    profileTargetId?: string;
    tagTargetId?: string;
    createdAt?: Date;
    contributors?: Array<string>;
    collaborators?: Array<string>;
}

export interface UpdateIndicatorRequest {
    id: number;
    version: string;
    status: IndicatorStatus;
    name: string;
    measurers: Array<string>;
    questions: Array<QuestionInput>;
    description?: string;
    startDate?: Date;
    endDate?: Date;
    linkTarget?: LinkTarget;
    includeNotes?: boolean;
    organisationId?: string;
    organizerId?: string;
    happeningId?: string;
    canAddContributors?: boolean;
    canAddCollaborators?: boolean;
}

/**
 * no description
 */
export class IndicatorsApi extends runtime.BaseAPI {
    /**
     */
    async createIndicatorRaw(requestParameters: CreateIndicatorRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createIndicator.",
            );
        }
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError(
                "status",
                "Required parameter requestParameters.status was null or undefined when calling createIndicator.",
            );
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling createIndicator.",
            );
        }
        if (requestParameters.measurers === null || requestParameters.measurers === undefined) {
            throw new runtime.RequiredError(
                "measurers",
                "Required parameter requestParameters.measurers was null or undefined when calling createIndicator.",
            );
        }
        if (requestParameters.questions === null || requestParameters.questions === undefined) {
            throw new runtime.RequiredError(
                "questions",
                "Required parameter requestParameters.questions was null or undefined when calling createIndicator.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.status !== undefined) {
            formParams.append("Status", requestParameters.status as any);
            hasParams = true;
        }
        if (requestParameters.name !== undefined) {
            formParams.append("Name", requestParameters.name as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.startDate !== undefined) {
            formParams.append("StartDate", (requestParameters.startDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.endDate !== undefined) {
            formParams.append("EndDate", (requestParameters.endDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.linkTarget !== undefined) {
            formParams.append("LinkTarget", requestParameters.linkTarget as any);
            hasParams = true;
        }
        if (requestParameters.includeNotes !== undefined) {
            formParams.append("IncludeNotes", requestParameters.includeNotes as any);
            hasParams = true;
        }
        if (requestParameters.organisationId !== undefined) {
            formParams.append("OrganisationId", requestParameters.organisationId as any);
            hasParams = true;
        }
        if (requestParameters.organizerId !== undefined) {
            formParams.append("OrganizerId", requestParameters.organizerId as any);
            hasParams = true;
        }
        if (requestParameters.measurers) {
            requestParameters.measurers.forEach((element, index) => {
                appendToParams(formParams, `Measurers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.questions) {
            requestParameters.questions.forEach((element, index) => {
                appendToParams(formParams, `Questions[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningId !== undefined) {
            formParams.append("HappeningId", requestParameters.happeningId as any);
            hasParams = true;
        }
        if (requestParameters.canAddContributors !== undefined) {
            formParams.append("CanAddContributors", requestParameters.canAddContributors as any);
            hasParams = true;
        }
        if (requestParameters.canAddCollaborators !== undefined) {
            formParams.append("CanAddCollaborators", requestParameters.canAddCollaborators as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Indicators`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async createIndicator(requestParameters: CreateIndicatorRequest): Promise<number> {
        const response = await this.createIndicatorRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deleteIndicatorRaw(requestParameters: DeleteIndicatorRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteIndicator.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteIndicator.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Indicators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteIndicator(requestParameters: DeleteIndicatorRequest): Promise<void> {
        await this.deleteIndicatorRaw(requestParameters);
    }
    /**
     */
    async getIndicatorRaw(requestParameters: GetIndicatorRequest): Promise<runtime.ApiResponse<IndicatorOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getIndicator.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getIndicator.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Indicators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => IndicatorOutputFromJSON(jsonValue));
    }
    /**
     */
    async getIndicator(requestParameters: GetIndicatorRequest): Promise<IndicatorOutput> {
        const response = await this.getIndicatorRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getIndicatorsRaw(
        requestParameters: GetIndicatorsRequest,
    ): Promise<runtime.ApiResponse<IndicatorListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getIndicators.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.date !== undefined) {
            queryParameters["Date"] = (requestParameters.date as any).toUTCString();
        }
        if (requestParameters.sanitizedDate !== undefined) {
            queryParameters["SanitizedDate"] = (requestParameters.sanitizedDate as any).toUTCString();
        }
        if (requestParameters.organisations && requestParameters.organisations.length > 0) {
            queryParameters["Organisations"] = requestParameters.organisations;
        }
        if (requestParameters.organizers && requestParameters.organizers.length > 0) {
            queryParameters["Organizers"] = requestParameters.organizers;
        }
        if (requestParameters.statuses && requestParameters.statuses.length > 0) {
            queryParameters["Statuses"] = requestParameters.statuses;
        }
        if (requestParameters.sanitizedQuery !== undefined) {
            queryParameters["SanitizedQuery"] = requestParameters.sanitizedQuery;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/Indicators`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            IndicatorListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getIndicators(requestParameters: GetIndicatorsRequest): Promise<IndicatorListOutputPaginatedViewModel> {
        const response = await this.getIndicatorsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async measureRaw(requestParameters: MeasureRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling measure.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling measure.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.status !== undefined) {
            formParams.append("Status", requestParameters.status as any);
            hasParams = true;
        }
        if (requestParameters.notes !== undefined) {
            formParams.append("Notes", requestParameters.notes as any);
            hasParams = true;
        }
        if (requestParameters.answers) {
            requestParameters.answers.forEach((element, index) => {
                appendToParams(formParams, `Answers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.profileTargetId !== undefined) {
            formParams.append("ProfileTargetId", requestParameters.profileTargetId as any);
            hasParams = true;
        }
        if (requestParameters.tagTargetId !== undefined) {
            formParams.append("TagTargetId", requestParameters.tagTargetId as any);
            hasParams = true;
        }
        if (requestParameters.createdAt !== undefined) {
            formParams.append("CreatedAt", (requestParameters.createdAt as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.contributors) {
            requestParameters.contributors.forEach((element, index) => {
                appendToParams(formParams, `Contributors[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.collaborators) {
            requestParameters.collaborators.forEach((element, index) => {
                appendToParams(formParams, `Collaborators[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/Indicators/{id}/measure`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async measure(requestParameters: MeasureRequest): Promise<number> {
        const response = await this.measureRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async updateIndicatorRaw(requestParameters: UpdateIndicatorRequest): Promise<runtime.ApiResponse<IndicatorOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateIndicator.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateIndicator.",
            );
        }
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError(
                "status",
                "Required parameter requestParameters.status was null or undefined when calling updateIndicator.",
            );
        }
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError(
                "name",
                "Required parameter requestParameters.name was null or undefined when calling updateIndicator.",
            );
        }
        if (requestParameters.measurers === null || requestParameters.measurers === undefined) {
            throw new runtime.RequiredError(
                "measurers",
                "Required parameter requestParameters.measurers was null or undefined when calling updateIndicator.",
            );
        }
        if (requestParameters.questions === null || requestParameters.questions === undefined) {
            throw new runtime.RequiredError(
                "questions",
                "Required parameter requestParameters.questions was null or undefined when calling updateIndicator.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.status !== undefined) {
            formParams.append("Status", requestParameters.status as any);
            hasParams = true;
        }
        if (requestParameters.name !== undefined) {
            formParams.append("Name", requestParameters.name as any);
            hasParams = true;
        }
        if (requestParameters.description !== undefined) {
            formParams.append("Description", requestParameters.description as any);
            hasParams = true;
        }
        if (requestParameters.startDate !== undefined) {
            formParams.append("StartDate", (requestParameters.startDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.endDate !== undefined) {
            formParams.append("EndDate", (requestParameters.endDate as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.linkTarget !== undefined) {
            formParams.append("LinkTarget", requestParameters.linkTarget as any);
            hasParams = true;
        }
        if (requestParameters.includeNotes !== undefined) {
            formParams.append("IncludeNotes", requestParameters.includeNotes as any);
            hasParams = true;
        }
        if (requestParameters.organisationId !== undefined) {
            formParams.append("OrganisationId", requestParameters.organisationId as any);
            hasParams = true;
        }
        if (requestParameters.organizerId !== undefined) {
            formParams.append("OrganizerId", requestParameters.organizerId as any);
            hasParams = true;
        }
        if (requestParameters.measurers) {
            requestParameters.measurers.forEach((element, index) => {
                appendToParams(formParams, `Measurers[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.questions) {
            requestParameters.questions.forEach((element, index) => {
                appendToParams(formParams, `Questions[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningId !== undefined) {
            formParams.append("HappeningId", requestParameters.happeningId as any);
            hasParams = true;
        }
        if (requestParameters.canAddContributors !== undefined) {
            formParams.append("CanAddContributors", requestParameters.canAddContributors as any);
            hasParams = true;
        }
        if (requestParameters.canAddCollaborators !== undefined) {
            formParams.append("CanAddCollaborators", requestParameters.canAddCollaborators as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/Indicators/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => IndicatorOutputFromJSON(jsonValue));
    }
    /**
     */
    async updateIndicator(requestParameters: UpdateIndicatorRequest): Promise<IndicatorOutput> {
        const response = await this.updateIndicatorRaw(requestParameters);
        return await response.value();
    }
}
