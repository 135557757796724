import { Outlet } from "react-router-dom";
import styles from "./TabLayout.module.scss";
import NavigationTabs from "../core/Navigation/NavigationTabs/NavigationTabs";
import { NavigationTab } from "../../types/NavigationTab";

interface TabLayoutProps {
    tabs: NavigationTab[];
}

export default function TabLayout({ tabs }: TabLayoutProps) {
    return (
        <div className={styles.layout}>
            <NavigationTabs tabs={tabs} />
            <div className={styles.outlet}>
                <Outlet />
            </div>
        </div>
    );
}
