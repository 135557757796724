import { useCallback, useContext, useEffect } from "react";
import ProfileForm from "./ProfileForm";
import { ProfileContext } from "../../contexts/ProfileContext";
import { UpdateProfileInput, useUpdateAvatar, useUpdateProfile } from "../../hooks/UserHooks";
import { RequestState } from "../../hooks/UseApiCall";

const MyProfileContainer = () => {
    const { profile, refresh } = useContext(ProfileContext);
    const [{ state }, updateProfileCall] = useUpdateProfile();
    const [{ state: avatarState }, updateAvatar] = useUpdateAvatar();
    useEffect(() => {
        if (state === RequestState.DONE) {
            refresh();
        }
    }, [refresh, state]);
    useEffect(() => {
        if (avatarState === RequestState.DONE) {
            refresh();
        }
    }, [refresh, avatarState]);

    // We need an additional wrapper because the react form sends an additional parameter which messes up things
    const updateProfile = useCallback((input: UpdateProfileInput) => updateProfileCall(input), [updateProfileCall]);

    if (!profile) {
        return <div></div>;
    }

    return (
        <ProfileForm
            profile={profile}
            updateProfile={updateProfile}
            isSubmitting={state === RequestState.LOADING}
            updateAvatar={updateAvatar}
        />
    );
};

export default MyProfileContainer;
