import { ReactNode, useCallback } from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import styles from "./Modal.module.scss";

import CloseButton from "../../../assets/cross.svg";
import strings from "../../../localization/strings";
import FlexRow from "../../Layouts/FlexRow/FlexRow";

type Props = {
    title: string;
    isOpen: boolean;
    onDismiss?: () => void;
    onClose?: () => void;
    children?: ReactNode;
    className?: string;
};

export default function Modal({ title, isOpen, onDismiss, onClose, children, className }: Props) {
    const handleClose = useCallback(() => {
        if (onClose) {
            return onClose();
        } else if (onDismiss) {
            return onDismiss();
        }
    }, [onClose, onDismiss]);
    return (
        <DialogOverlay className={styles.overlay} isOpen={isOpen} onDismiss={onDismiss}>
            <DialogContent className={`${styles.modal} ${className}`} aria-label={title}>
                <div className={styles.title}>
                    {title}
                    <button className={styles.closeButton} onClick={handleClose} aria-label={strings.close}>
                        <img src={CloseButton} alt={strings.close} />
                    </button>
                </div>

                <div className={styles.content}>{children}</div>
            </DialogContent>
        </DialogOverlay>
    );
}

export const SmallModal = ({ className, ...props }: Props) => (
    <Modal className={`${styles.small} ${className}`} {...props} />
);

type TextModalProps = {
    text: string;
    Buttons: ReactNode;
} & Omit<Props, "children">;
export const TextModal = ({ text, Buttons, ...props }: TextModalProps) => (
    <SmallModal {...props}>
        <p className={styles.text}>{text}</p>
        <FlexRow>{Buttons}</FlexRow>
    </SmallModal>
);
