import { ChangeEvent, useCallback, useState } from "react";

/**
 * Provides a handler for file inputs. Returns an onchange function that transforms input values to src urls.
 */
const useImageInput = (
    onChange?: (file: File) => void,
): [string | undefined, (e: ChangeEvent<HTMLInputElement>) => void] => {
    const [value, setValue] = useState<string>();

    const innerOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.files === null) {
                return;
            }

            const file = e.currentTarget.files[0];
            const src = URL.createObjectURL(file);
            setValue(src);
            onChange && onChange(file);
        },
        [setValue, onChange],
    );

    return [value, innerOnChange];
};

export default useImageInput;
