import { Route } from "react-router-dom";
import Bouncer from "../authentication/Bouncer";
import { PermissionCheck } from "../authentication/Permissions";
import TabLayout from "../components/TabLayout/TabLayout";
import { NavigationTab } from "../types/NavigationTab";

// This needs to be a function that returns a Route instead of a component as react-router-dom only accepts Route components
const useTabRoute = (path: string, tabs: NavigationTab[], permissions?: PermissionCheck[]) => {
    const subRoutes = tabs.map((tab) => (
        <Route
            key={`${path}/${tab.pathname}`}
            path={tab.pathname}
            element={<Bouncer permissions={tab.permissions} element={tab.element} />}
        />
    ));

    return (
        <Route path={path} element={<Bouncer permissions={permissions} element={<TabLayout tabs={tabs} />} />}>
            {subRoutes}
        </Route>
    );
};

export default useTabRoute;
