import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import ActionAddMeasurement from "../../assets/action_add_measurement_icon.svg";
import ActionDelete from "../../assets/action_delete_icon.svg";
import ActionDuplicate from "../../assets/action_duplicate_icon.svg";
import ActionEdit from "../../assets/action_edit_icon.svg";
import ActionView from "../../assets/action_view_icon.svg";
import {
    canCreateIndicator,
    canCreateSpecificMeasurement,
    canEditIndicator,
    canViewMonitoringStatistics,
} from "../../authentication/Permissions";
import { ProfileContext } from "../../contexts/ProfileContext";
import { GetIndicatorsFun, IndicatorsFilter, useDeleteIndicator } from "../../hooks/IndicatorHooks";
import { useOrganiserOptions } from "../../hooks/TagHooks";
import { RequestState } from "../../hooks/UseApiCall";
import { useProjectLeaderOptions } from "../../hooks/UserHooks";
import {
    DateFilter,
    FilterSpecs,
    NamedOptionsFilter,
    PageNumberFilter,
    StringFilter,
    useFilters,
} from "../../hooks/UseSearchParam";
import strings from "../../localization/strings";
import {
    IndicatorListOutput,
    IndicatorListOutputPaginatedViewModel,
    IndicatorOrderBy,
    SortOrder,
} from "../../openapi/backend";
import { UserNamedType } from "../../types/UserNamedType";
import { formatDate } from "../../utils.ts/DateTime";
import { getName } from "../../utils.ts/GetName";
import { getIndicatorStateOptions } from "../../utils.ts/StateOptions";
import { getAddMeasurementLink, getIndicatorDuplicateLink, getIndicatorLink } from "../../utils.ts/Urls";
import ConfirmationModal from "../core/ConfirmationModal/ConfirmationModal";
import EllipsisMenu from "../core/EllipsisMenu/EllipsisMenu";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import IndicatorsFilterBar from "../core/FilterBar/IndicatorsFilterBar";
import StateLabel from "../core/StateLabel/StateLabel";
import Table from "../core/Table/Table";

interface AllIndicatorsProps {
    indicators?: IndicatorListOutputPaginatedViewModel;
    error?: Response;
    getIndicators: GetIndicatorsFun;
}

interface IndicatorTableItem {
    Id: number;
    Name: ReactNode;
    MeasurementCount: number;
    StartDate: ReactNode;
    Organisation: ReactNode;
    Organizer: ReactNode;
    Status: ReactNode;
    Actions: ReactNode;
}

const stateOptions = getIndicatorStateOptions();

const columns: Column<IndicatorTableItem>[] = [
    {
        Header: strings.name,
        accessor: "Name",
    },
    {
        Header: strings.measurementsCount,
        accessor: "MeasurementCount",
        width: 40,
    },
    {
        Header: strings.date,
        accessor: "StartDate",
    },
    {
        Header: strings.formIndicatorFieldOrganisation,
        accessor: "Organisation",
    },
    {
        Header: strings.formIndicatorFieldOrganizer,
        accessor: "Organizer",
    },
    {
        Header: strings.state,
        accessor: "Status",
    },
    {
        Header: strings.actions,
        accessor: "Actions",
        disableSortBy: true,
        width: 20,
    },
];

const restrictedColumns = columns.filter((column) => column.accessor !== "MeasurementCount");

export default function AllIndicators({ indicators, error, getIndicators }: AllIndicatorsProps) {
    const { profile } = useContext(ProfileContext);
    const navigate = useNavigate();
    const [orderBy, setOrderBy] = useState(IndicatorOrderBy.Default);
    const [sortOrder, setSortOrder] = useState(SortOrder.Ascending);
    const [deleteTarget, setDeleteTarget] = useState<IndicatorListOutput | undefined>(undefined);

    const organisationOptions = useOrganiserOptions();

    const { value: organizers, state: organizersState } = useProjectLeaderOptions();
    const [deleteIndicatorState, deleteIndicator, resetDeleteRequest] = useDeleteIndicator();

    const organizerOptions: UserNamedType[] = useMemo(
        () => (organizers ? organizers.map((organizer) => ({ ...organizer, name: getName(organizer) })) : []),
        [organizers],
    );

    const filterSpecs: FilterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            startDate: new DateFilter(),
            statuses: new NamedOptionsFilter(stateOptions),
            page: new PageNumberFilter(),
            organisations: new NamedOptionsFilter(organisationOptions),
            organizers: new NamedOptionsFilter(organizerOptions),
            date: new DateFilter(),
        }),
        [organisationOptions, organizerOptions],
    );

    const [rawFilters, setFilters] = useFilters(filterSpecs);
    const filters = rawFilters as IndicatorsFilter;

    const loadIndicators = useCallback(() => {
        // Wait until the project leaders options have been loaded, because the filters are dependent on them.
        // Otherwise, we get multiple getIndicators calls in quick succession which causes a race condition

        // (the last response to come in will be the "winner").
        // If the organisers call fails, start loading regardless.

        const loadingOrganisersDone = organizerOptions.length > 0 || organizersState === RequestState.ERROR;
        if (loadingOrganisersDone) {
            return getIndicators(orderBy, sortOrder, filters);
        }
    }, [getIndicators, orderBy, sortOrder, filters, organizerOptions, organizersState]);

    useEffect(() => {
        loadIndicators();
    }, [loadIndicators]);

    useEffect(() => {
        if (deleteIndicatorState.state === RequestState.DONE) {
            loadIndicators();
            setDeleteTarget(undefined);
            resetDeleteRequest();
        }
    }, [loadIndicators, deleteIndicatorState.state, resetDeleteRequest]);

    const data = useMemo<IndicatorTableItem[]>(() => {
        return (
            indicators?.items?.map((indicator) => {
                const canEdit = canEditIndicator(profile);
                const actions = [
                    canCreateSpecificMeasurement(indicator, profile) && {
                        icon: ActionAddMeasurement,
                        onClick: () => {
                            navigate(getAddMeasurementLink(indicator.id));
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltAddMeasurement, indicator.name) as string,
                        title: strings.ellipsisMenuTitleAddMeasurement,
                    },
                    canEdit && {
                        icon: ActionDelete,
                        onClick: () => {
                            setDeleteTarget(indicator);
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltDelete, indicator.name) as string,
                        title: strings.ellipsisMenuTitleDelete,
                    },
                    canCreateIndicator(profile) && {
                        icon: ActionDuplicate,
                        onClick: () => {
                            navigate(getIndicatorDuplicateLink(indicator.id));
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltDuplicate, indicator.name) as string,
                        title: strings.ellipsisMenuTitleDuplicate,
                    },
                    canEdit && {
                        icon: ActionEdit,
                        onClick: () => {
                            navigate(getIndicatorLink(indicator.id));
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltEdit, indicator.name) as string,
                        title: strings.ellipsisMenuTitleEdit,
                    },
                    !canEdit && {
                        icon: ActionView,
                        onClick: () => {
                            navigate(getIndicatorLink(indicator.id));
                        },
                        alt: strings.formatString(strings.ellipsisMenuAltView, indicator.name) as string,
                        title: strings.ellipsisMenuTitleView,
                    },
                ];

                return {
                    Id: indicator.id,
                    Name: indicator.name,
                    MeasurementCount: indicator.measurementCount,
                    StartDate: indicator.startDate ? formatDate(indicator.startDate) : "-",
                    Organisation: indicator.organisation?.name || "",
                    Organizer: indicator.organizer ? getName(indicator.organizer) : "",
                    Status: <StateLabel state={indicator.status} />,
                    Actions: <EllipsisMenu items={actions} />,
                };
            }) || []
        );
    }, [indicators, navigate, profile]);

    const setPage = useCallback(
        (p: number) => {
            setFilters({ ...filters, page: p });
        },
        [filters, setFilters],
    );

    const onFilterChange = useCallback(
        (filter: IndicatorsFilter) => {
            setFilters({ ...filter, page: 1 });
        },
        [setFilters],
    );

    const handleSort = useCallback(
        (sortBy) => {
            // We only sort on 1 column so only take the first element in sortBy
            if (sortBy[0]) {
                setOrderBy(sortBy[0].id);
                setSortOrder(sortBy[0].desc ? SortOrder.Ascending : SortOrder.Descending);
            } else {
                // If no sorting is applied, revert back to default sorting
                setOrderBy(IndicatorOrderBy.Default);
                setSortOrder(SortOrder.Ascending);
            }
        },
        [setOrderBy, setSortOrder],
    );

    return (
        <div>
            <IndicatorsFilterBar onChange={onFilterChange} value={filters} />
            {error && <ErrorMessage error={error} />}
            <Table
                columns={canViewMonitoringStatistics(profile) ? columns : restrictedColumns}
                data={data}
                onSort={handleSort}
                setPage={setPage}
                emptyString={strings.noIndicatorsFound}
                forcePage={filters.page}
                paginationMeta={indicators?.meta}
                rowSelectable={false}
            />
            {deleteTarget && (
                <ConfirmationModal
                    isOpen={true}
                    onCancel={() => setDeleteTarget(undefined)}
                    onConfirm={() => deleteIndicator(deleteTarget.id)}
                    isLoading={deleteIndicatorState.state === RequestState.LOADING}
                    error={deleteIndicatorState.error}
                    errorMessage={strings.errorRemoveIndicator}
                    confirmText={strings.yesDelete}
                >
                    {strings.formatString(strings.confirmDeleteIndicator, deleteTarget.name)}
                </ConfirmationModal>
            )}
        </div>
    );
}
