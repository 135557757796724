import * as yup from "yup";
import { MAX_TITLE_LENGTH } from "../../../constants/Validation";
import strings from "../../../localization/strings";
import { IndicatorInputModel } from "../../../models/IndicatorModels";
import { questionSchema } from "../../../utils.ts/Validation";

export const conceptValidationSchema = yup.object({
    name: yup.string().required().max(MAX_TITLE_LENGTH),
});

export const validationSchema: yup.SchemaOf<IndicatorInputModel> = yup.object({
    name: yup.string().required().max(MAX_TITLE_LENGTH),
    description: yup.string().required(),
    organisationId: yup.string().required(),
    organizerId: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup
        .date()
        .required()
        .when("startDate", {
            is: (startDate: Date | undefined) => !!startDate,
            then: (schema) =>
                schema.test(
                    "pattern",
                    strings.sessionRegistrationBefore,
                    (val: any, context: any) => context.parent.startDate <= val,
                ),
        }),
    measurers: yup.array().of(yup.string().required()),
    linkTarget: yup.string().required(),
    questions: yup
        .array(questionSchema)
        .when("includeNotes", { is: false, then: (schema) => schema.min(1, strings.minErrorIndicatorQuestions) }),
    includeNotes: yup.bool().required(),
    happeningInput: yup.object({
        query: yup.string(),
        value: yup.string(),
    }),
    canAddCollaborators: yup.boolean().required(),
    canAddContributors: yup.boolean().required(),
}) as any;
