import { useCallback, useContext } from "react";
import { RegistrationRuleApiContext } from "../contexts/RegistrationRuleApiContext";
import { CreateRegistrationRuleRequest, RegistrationRuleViewModel } from "../openapi/backend";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, useApiCallback } from "./UseApiCall";

export type RegistrationRuleInput = Omit<CreateRegistrationRuleRequest, "version">;
export const useCreateRegistrationRuleCallBack = (): [
    ApiCallState<void>,
    (input: RegistrationRuleInput) => void,
    () => void,
] => {
    const api = useContext(RegistrationRuleApiContext);
    const callback = useCallback(
        (input: RegistrationRuleInput) => api.createRegistrationRule({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );

    return useApiCallback(callback);
};

export type GetRegistrationRulesFun = () => void;
export const useRegistrationRulesOverview = (): [
    ApiCallState<Array<RegistrationRuleViewModel>>,
    GetRegistrationRulesFun,
    () => void,
] => {
    const api = useContext(RegistrationRuleApiContext);
    const callback = useCallback(() => {
        return api.getRegistrationRules({
            version: REACT_APP_BACKEND_VERSION,
        });
    }, [api]);
    return useApiCallback(callback);
};

export const useRegistrationRuleDelete = (): [ApiCallState<void>, (id: number) => void, () => void] => {
    const api = useContext(RegistrationRuleApiContext);
    const callback = useCallback((id) => api.deleteRegistrationRule({ version: REACT_APP_BACKEND_VERSION, id }), [api]);

    return useApiCallback(callback);
};
