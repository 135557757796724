import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { canEditSpecificMeasurement } from "../../../authentication/Permissions";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { useGetMeasurement, useUpdateMeasurement } from "../../../hooks/MeasurementHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { Operation } from "../../../types/Operation";
import { getDefaultValuesFromMeasurement } from "../../../utils.ts/MeasurementUtils";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import MeasurementForm from "./MeasurementForm";

export default function UpdateMeasurementForm() {
    const { id } = useParams<{ id: string }>();
    const { profile } = useContext(ProfileContext);

    // Should never happen because of routing
    if (id === undefined) {
        throw new Error("Measurement id is required");
    }

    const idNumber = parseInt(id, 10);
    const { value: measurement, state, error } = useGetMeasurement(idNumber);
    const saveCallback = useUpdateMeasurement(idNumber);

    const defaultValues = useMemo(() => {
        return getDefaultValuesFromMeasurement(measurement);
    }, [measurement]);

    if ([RequestState.LOADING, RequestState.IDLE].includes(state)) {
        return <InlineLoading />;
    }

    if (error) {
        return <ErrorMessage error={error} />;
    }

    if (!measurement) {
        return <div>{strings.measurementNotFound}</div>;
    }

    return (
        <MeasurementForm
            saveCallback={saveCallback}
            indicator={measurement.indicator}
            defaultValues={defaultValues}
            operation={Operation.UPDATE}
            pageTitle={strings.measurementFormTitleDetails}
            measurer={measurement.measurer}
            readOnly={!canEditSpecificMeasurement(measurement, profile)}
        />
    );
}
