import * as yup from "yup";
import {
    MAX_AGE,
    MAX_LINK_LABEL_LENGTH,
    MAX_TITLE_LENGTH,
    MIN_AGE,
    MIN_ALLOWED_PARTICIPANTS_VALUE,
    MIN_ALLOWED_PARTICIPANT_TEAM_SIZE,
    MIN_PRICE_VALUE,
    basicUrlHttps,
    pricePattern,
} from "../../../constants/Validation";
import { HappeningInput } from "../../../hooks/HappeningsHooks";
import strings from "../../../localization/strings";
import {
    DayOfWeek,
    HappeningRegistrationType,
    HappeningState,
    HappeningTagInput,
    HappeningTimeAndDateInput,
    HappeningType,
    InfoLevel,
    LocationType,
    MonthlyRecurrenceType,
    PaymentMethod,
    RecurrenceInput,
    RecurrenceType,
    UpdateTarget,
    UserSubset,
} from "../../../openapi/backend";
import {
    futureTimeAndDateSchema,
    modelWithImagesSchema,
    questionSchema,
    safeNumber,
    videoSchema,
} from "../../../utils.ts/Validation";

/* The validation schema's for both regular Happening and Concept are defined in this file.
 * Note: the typing of yup.array(...) inside yup.object(...) seems to be broken, hence we're bound to casting to any
 */

export type RecurrenceEndingType = "date" | "count" | undefined;

// Fields that are used in the form but are not sent to the backend
interface ExtraFields {
    isFree: boolean;
    isRecurrence: boolean;
    recurrenceEndingType: RecurrenceEndingType;
    isBeingPublished?: boolean;
    restrictRegistrationsDate: boolean;
    restrictRegistrationsSchools: boolean;
    restrictRegistrationsAreas: boolean;
    hasExtraQuestions: boolean;
}

export type FormData = HappeningInput & ExtraFields;

const tagInputSchema: yup.SchemaOf<HappeningTagInput> = yup.object({
    id: yup.string() as any,
    responsibilities: yup
        .array(yup.string().required() as any)
        .when("id", { is: (val?: string) => !!val, then: (schema) => schema.required() }),
    isShownInCommunication: yup.boolean().default(true),
});

const timeAndDateSchema: yup.SchemaOf<HappeningTimeAndDateInput> = yup.object({
    sessionStart: yup.date().required(),
    sessionEnd: yup.date().required(),
});

const recurrenceSchema: yup.SchemaOf<RecurrenceInput> = yup.object({
    recurrenceType: yup.mixed().oneOf(Object.values(RecurrenceType)),
    startDate: yup.date().required(),
    endDate: yup.date().test("required", (val, context) => !!val || !!context.parent.repeatMax),
    repeatMax: safeNumber.test("required", (val, context) => !!val || !!context.parent.endDate),
    repeatFrequency: safeNumber.required().min(1),
    monthlyRecurrenceType: yup
        .mixed()
        .oneOf(Object.values(MonthlyRecurrenceType))
        .when("recurrenceType", { is: RecurrenceType.Monthly, then: (schema) => schema.required() }),
    weeklyDays: yup.array().when("recurrenceType", {
        is: RecurrenceType.Weekly,
        then: (schema) => schema.of(yup.mixed().oneOf(Object.values(Object.values(DayOfWeek)))).required(),
        otherwise: (schema) => schema.max(0),
    }),
});

export const conceptValidationSchema = yup.object({
    title: yup.string().required().max(MAX_TITLE_LENGTH),
});

export const validationSchema: yup.SchemaOf<FormData> = modelWithImagesSchema.shape({
    title: yup.string().required().max(MAX_TITLE_LENGTH),
    isBeingPublished: yup.boolean().default(false),
    isFree: yup.boolean().default(true),
    isRecurrence: yup.boolean().default(false),
    recurrenceEndingType: yup.mixed().oneOf(["date", "count"]),
    restrictAge: yup.boolean().default(false),
    isAccessible: yup.boolean().default(false),
    state: yup.mixed().oneOf(Object.values(HappeningState)),
    requiresRegistration: yup.boolean().default(false),
    requiresApprovalFromMarketing: yup.boolean().default(false),
    description: yup.string().required(),
    minAge: safeNumber.when("targetGroup", {
        is: UserSubset.Participants,
        then: (schema) => schema.required().min(MIN_AGE).max(MAX_AGE),
    }),
    maxAge: safeNumber.when("targetGroup", {
        is: UserSubset.Participants,
        then: (schema) =>
            schema
                .required()
                .min(MIN_AGE)
                .max(MAX_AGE)
                .test("pattern", strings.ageError, (val: any, context: any) => context.parent.minAge <= val),
    }),
    schools: yup.array(yup.string().required() as any),
    askForSchool: yup.boolean().default(false),
    tagInputs: yup.array(tagInputSchema as any),
    infoLevel: yup.mixed().oneOf(Object.values(InfoLevel)),
    maxNumberOfRegistrations: safeNumber.min(0),
    maxReserves: safeNumber,
    minPeoplePerGroup: safeNumber.when("happeningRegistrationType", {
        is: HappeningRegistrationType.GroupRegistration,
        then: safeNumber.min(0),
    }),
    maxPeoplePerGroup: safeNumber.when("happeningRegistrationType", {
        is: HappeningRegistrationType.GroupRegistration,
        then: safeNumber
            .min(0)
            .test("pattern", strings.soloGroupError, (val: any) => {
                return val !== 1;
            })
            .test("pattern", strings.groupError, (val: any, context: any) => {
                const isMinMaxRestricted =
                    context.parent.minPeoplePerGroup >= MIN_ALLOWED_PARTICIPANTS_VALUE &&
                    val >= MIN_ALLOWED_PARTICIPANT_TEAM_SIZE;
                if (!isMinMaxRestricted) {
                    return true;
                }

                return context.parent.minPeoplePerGroup <= val;
            }),
    }),
    contact: yup.string().required(),
    projectLeader: yup.string().required(),
    projectMembers: yup.array(yup.string().required() as any),
    projectCoLeaders: yup.array(yup.string().required() as any),
    timeAndDates: yup.mixed().when("isBeingPublished", {
        is: true,
        then: yup.array(futureTimeAndDateSchema),
        otherwise: yup.array(timeAndDateSchema),
    }),
    price: safeNumber.when("isFree", {
        is: true,
        then: (schema) => schema.default(0),
        otherwise: (schema) =>
            schema
                .required()
                .min(MIN_PRICE_VALUE)
                .test("pattern", strings.stepError, (val?: number) => pricePattern.test(val?.toString() || "")),
    }),
    discountedPrice: safeNumber,
    isProRata: yup.boolean().default(true),
    videos: yup.array(videoSchema as any),
    recurrence: yup.array(recurrenceSchema as any).max(1),
    updateTarget: yup.mixed().oneOf(Object.values(UpdateTarget)),
    allowedPaymentMethods: yup.mixed().oneOf(Object.values(PaymentMethod)),
    organizer: yup.string(),
    // extra's
    happeningGroup: yup.string().required(),
    happeningTypes: yup.array(yup.string().required() as any).when("targetGroup", {
        is: UserSubset.Participants,
        then: (schema) => schema.required(),
    }),
    internalTags: yup.array(yup.string().required() as any),
    targetGroup: yup.string().required(),
    targetOrganisations: yup
        .array(yup.string().required() as any)
        .when("targetGroup", { is: UserSubset.SelectedOrganisations, then: (schema) => schema.required() }),
    partners: yup.array(yup.string().required() as any),
    areas: yup.array(yup.object({ id: yup.string() }) as any),
    locationAddress: yup
        .string()
        .when("locationType", { is: LocationType.OnLocation, then: (schema) => schema.required() }),
    locationName: yup
        .string()
        .when("locationType", { is: LocationType.OnLocation, then: (schema) => schema.required() }),
    locationLongitude: yup
        .mixed()
        .when("locationType", { is: LocationType.OnLocation, then: safeNumber.required(), otherwise: safeNumber }),
    locationLatitude: yup
        .mixed()
        .when("locationType", { is: LocationType.OnLocation, then: safeNumber.required(), otherwise: safeNumber }),
    registrationStartDate: yup.date().when(["restrictRegistrationsDate", "happeningType"], {
        is: (restrictRegistrationsDate: boolean, happeningType: HappeningType) =>
            restrictRegistrationsDate || happeningType === HappeningType.PrivateHappening,
        then: (schema) => schema.required(),
    }),
    registrationEndDate: yup
        .date()
        .nullable()
        .when(["restrictRegistrationsDate", "happeningType"], {
            is: (restrictRegistrationsDate: boolean, happeningType: HappeningType) =>
                restrictRegistrationsDate || happeningType === HappeningType.PrivateHappening,
            then: (schema) =>
                schema
                    .when("happeningType", {
                        is: HappeningType.PrivateHappening,
                        then: (s) => s.required(),
                    })
                    .test(
                        "pattern",
                        strings.sessionRegistrationBefore,
                        // If value is required, then it's already guarded above.
                        // But when it's not required an empty value should return true on this test.
                        (val: any, context: any) => !val || context.parent.registrationStartDate <= val,
                    ),
        }),
    extraQuestions: yup.array(questionSchema),
    priceForIndividual: safeNumber.when("isFree", {
        is: true,
        then: (schema) => schema.default(undefined),
        otherwise: (schema) =>
            schema.when(["happeningRegistrationType", "canOnlyRegisterAsGroup"], {
                is: (type: HappeningRegistrationType, canOnlyRegisterAsGroup: boolean) =>
                    type === HappeningRegistrationType.GroupRegistration && !canOnlyRegisterAsGroup,
                then: (schema) =>
                    schema
                        .required()
                        .min(MIN_PRICE_VALUE)
                        .test("pattern", strings.stepError, (val?: number) => pricePattern.test(val?.toString() || "")),
                otherwise: (schema) => schema.default(undefined),
            }),
    }),
    durationInMinutes: safeNumber.when("happeningType", {
        is: (type: HappeningType) => type === HappeningType.PrivateHappening,
        then: (schema) => schema.required().min(1),
    }),
    link: yup
        .string()
        .when("happeningType", {
            is: (type: HappeningType) => type === HappeningType.ExternalHappening,
            then: (schema) => schema.required(),
        })
        .test("pattern", strings.invalidUrl, (val?: string) => basicUrlHttps.test(val || "") || val === ""),
    linklabel: yup.string().max(MAX_LINK_LABEL_LENGTH),
}) as any;
