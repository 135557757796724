import cx from "classnames";
import { useCallback, useMemo, useState } from "react";
import CrossIcon from "../../../assets/cross_white.svg";
import InsertPhotoIcon from "../../../assets/insert_photo.svg";
import VideoPlaceholder from "../../../assets/videocam_black_24dp.svg";
import strings from "../../../localization/strings";
import { ImageViewModel, Video } from "../../../openapi/backend";
import { MediaTargetType } from "../../../types/MediaLibraryType";
import { hasValues } from "../../../utils.ts/Array";
import ConfirmSoftDeleteMediaModal from "../ConfirmSoftDeleteMediaModal/ConfirmSoftDeleteMediaModal";
import { MediaLibraryModal } from "../MediaLibrary/MediaLibraryModal";
import styles from "./MediaPreview.module.scss";

interface Props {
    className?: string;
    images: ImageViewModel[];
    type: MediaTargetType;
    videos?: Video[];
    onMediaChange: (images: ImageViewModel[], videos?: Video[]) => void;
    excludeVideos?: boolean;
}

const MediaPreview = ({ className, images, type, videos, onMediaChange, excludeVideos }: Props) => {
    const [localImages, setLocalImages] = useState<ImageViewModel[]>(images);
    const [openLibrary, setOpenLibrary] = useState(false);
    const [removeTarget, setRemoveTarget] = useState<ImageViewModel | undefined>(undefined);

    const doCloseLibrary = useCallback(() => setOpenLibrary(false), []);
    const doOpenLibrary = useCallback(() => setOpenLibrary(true), []);

    const onMediaUpdate = useCallback(
        (selectedImages: ImageViewModel[], selectedVideos?: Video[]) => {
            setLocalImages(selectedImages);
            onMediaChange(selectedImages, selectedVideos);
        },
        [onMediaChange],
    );

    const clearRemoveTarget = useCallback(() => setRemoveTarget(undefined), []);

    const onRemoveImage = useCallback(() => {
        const next = localImages.filter((i) => i.id !== removeTarget?.id);
        setLocalImages(next);
        onMediaChange(next, videos);
        clearRemoveTarget();
    }, [localImages, onMediaChange, videos, removeTarget, clearRemoveTarget]);

    const confirmDeleteMessage = useMemo(() => {
        switch (type) {
            case "happening":
                return strings.confirmDeleteHappeningImage;
            case "organisationInfo":
                return strings.confirmDeleteOrganisationInfoImage;
        }
    }, [type]);

    return (
        <>
            <div className={cx(styles.container, className)}>
                {localImages.map((image) => (
                    <div key={image.id} className={styles.item} title={image.altText}>
                        <img className={styles.image} src={image.href} alt={image.altText} />
                        <button
                            type="button"
                            title={image.altText}
                            onClick={() => setRemoveTarget(image)}
                            className={styles.remove}
                        >
                            <img className={styles.removeIcon} src={CrossIcon} alt={image.altText} />
                        </button>
                    </div>
                ))}
                {hasValues(videos) &&
                    videos.map((video: Video, index: number) => {
                        return (
                            <div key={`${video.href} - ${index}`} className={cx(styles.item)} title={video.altText}>
                                <img className={styles.image} src={VideoPlaceholder} alt={video.altText} />
                            </div>
                        );
                    })}
                <button className={cx(styles.item, styles.add)} type="button" onClick={doOpenLibrary}>
                    <img className={styles.addIcon} src={InsertPhotoIcon} alt={strings.addMedia} />
                    <p className={styles.addLabel}>{strings.addMedia}</p>
                </button>
            </div>
            {openLibrary && (
                <MediaLibraryModal
                    images={localImages}
                    videos={videos}
                    onClose={doCloseLibrary}
                    onConfirm={onMediaUpdate}
                    excludeVideos={excludeVideos}
                    type={type}
                />
            )}
            <ConfirmSoftDeleteMediaModal
                target={removeTarget}
                onCancel={clearRemoveTarget}
                onConfirm={onRemoveImage}
                message={confirmDeleteMessage}
            />
        </>
    );
};

export default MediaPreview;
