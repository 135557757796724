/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    MeasurementStatus,
    MeasurementStatusFromJSON,
    MeasurementStatusFromJSONTyped,
    MeasurementStatusToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface MeasurementListOutput
 */
export interface MeasurementListOutput {
    /**
     *
     * @type {number}
     * @memberof MeasurementListOutput
     */
    id: number;
    /**
     *
     * @type {MeasurementStatus}
     * @memberof MeasurementListOutput
     */
    status: MeasurementStatus;
    /**
     *
     * @type {Date}
     * @memberof MeasurementListOutput
     */
    createdAt: Date;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof MeasurementListOutput
     */
    measurer: ProfileShortOutput;
    /**
     *
     * @type {string}
     * @memberof MeasurementListOutput
     */
    indicatorName: string;
    /**
     *
     * @type {TagViewModel}
     * @memberof MeasurementListOutput
     */
    organisation?: TagViewModel;
    /**
     *
     * @type {boolean}
     * @memberof MeasurementListOutput
     */
    isLinkedToHappening?: boolean;
}

/**
 * Check if a given object implements the MeasurementListOutput interface.
 */
export function instanceOfMeasurementListOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "measurer" in value;
    isInstance = isInstance && "indicatorName" in value;

    return isInstance;
}

export function MeasurementListOutputFromJSON(json: any): MeasurementListOutput {
    return MeasurementListOutputFromJSONTyped(json, false);
}

export function MeasurementListOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementListOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : MeasurementStatusFromJSON(json["status"]),
        createdAt: !exists(json, "createdAt") ? (undefined as any) : new Date(json["createdAt"]),
        measurer: !exists(json, "measurer") ? (undefined as any) : ProfileShortOutputFromJSON(json["measurer"]),
        indicatorName: !exists(json, "indicatorName") ? (undefined as any) : json["indicatorName"],
        organisation: !exists(json, "organisation") ? (undefined as any) : TagViewModelFromJSON(json["organisation"]),
        isLinkedToHappening: !exists(json, "isLinkedToHappening") ? (undefined as any) : json["isLinkedToHappening"],
    };
}

export function MeasurementListOutputToJSON(value?: MeasurementListOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
