import { useContext, useCallback } from "react";
import { HappeningsApiContext } from "../contexts/HappeningsApiContext";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { useApiCallback } from "./UseApiCall";

export const useCancelAppointment = (happeningId: string, appointmentId: number) => {
    const api = useContext(HappeningsApiContext);
    const callback = useCallback(
        (reason: string) =>
            api.cancelHappening({
                version: REACT_APP_BACKEND_VERSION,
                id: happeningId,
                dateTimes: [appointmentId],
                reason,
            }),
        [api, happeningId, appointmentId],
    );
    return useApiCallback(callback);
};
