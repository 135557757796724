/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    generalEmailNotifications: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    generalPushNotifications: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    happeningEmailNotificationsWhenRegistered: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    happeningPushNotificationsWhenRegistered: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    happeningEmailNotificationsWhenStandBy: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    happeningPushNotificationsWhenStandBy: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    happeningEmailNotificationsWhenFavorited: boolean;
    /**
     *
     * @type {boolean}
     * @memberof NotificationSettings
     */
    happeningPushNotificationsWhenFavorited: boolean;
}

/**
 * Check if a given object implements the NotificationSettings interface.
 */
export function instanceOfNotificationSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "generalEmailNotifications" in value;
    isInstance = isInstance && "generalPushNotifications" in value;
    isInstance = isInstance && "happeningEmailNotificationsWhenRegistered" in value;
    isInstance = isInstance && "happeningPushNotificationsWhenRegistered" in value;
    isInstance = isInstance && "happeningEmailNotificationsWhenStandBy" in value;
    isInstance = isInstance && "happeningPushNotificationsWhenStandBy" in value;
    isInstance = isInstance && "happeningEmailNotificationsWhenFavorited" in value;
    isInstance = isInstance && "happeningPushNotificationsWhenFavorited" in value;

    return isInstance;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return NotificationSettingsFromJSONTyped(json, false);
}

export function NotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettings {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        generalEmailNotifications: !exists(json, "generalEmailNotifications")
            ? (undefined as any)
            : json["generalEmailNotifications"],
        generalPushNotifications: !exists(json, "generalPushNotifications")
            ? (undefined as any)
            : json["generalPushNotifications"],
        happeningEmailNotificationsWhenRegistered: !exists(json, "happeningEmailNotificationsWhenRegistered")
            ? (undefined as any)
            : json["happeningEmailNotificationsWhenRegistered"],
        happeningPushNotificationsWhenRegistered: !exists(json, "happeningPushNotificationsWhenRegistered")
            ? (undefined as any)
            : json["happeningPushNotificationsWhenRegistered"],
        happeningEmailNotificationsWhenStandBy: !exists(json, "happeningEmailNotificationsWhenStandBy")
            ? (undefined as any)
            : json["happeningEmailNotificationsWhenStandBy"],
        happeningPushNotificationsWhenStandBy: !exists(json, "happeningPushNotificationsWhenStandBy")
            ? (undefined as any)
            : json["happeningPushNotificationsWhenStandBy"],
        happeningEmailNotificationsWhenFavorited: !exists(json, "happeningEmailNotificationsWhenFavorited")
            ? (undefined as any)
            : json["happeningEmailNotificationsWhenFavorited"],
        happeningPushNotificationsWhenFavorited: !exists(json, "happeningPushNotificationsWhenFavorited")
            ? (undefined as any)
            : json["happeningPushNotificationsWhenFavorited"],
    };
}

export function NotificationSettingsToJSON(value?: NotificationSettings | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
