import { useCallback, useEffect, useState } from "react";
import { RequestState } from "../../hooks/UseApiCall";
import { useProfile, useUser } from "../../hooks/UserHooks";
import strings from "../../localization/strings";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import EditButtons from "../Forms/AccountForm/EditButtons";
import Profile from "./Profile";

interface ProfileContainerProps {
    id: string;
    onSelectProfile: (id: string) => void;
    onDismiss: () => void;
}

const ProfileContainer = ({ id, onSelectProfile, onDismiss }: ProfileContainerProps) => {
    const [{ state: userState, error: userError, value: user }, getUser] = useUser();
    const [{ state: profileState, error: profileError, value: profile }, getProfile] = useProfile();
    const [editingUserProfile, setEditingUserProfile] = useState<boolean>(true);
    const [error, setError] = useState<Response>();
    // TODO: SBG-713 Add email verification information

    useEffect(() => {
        if (id) {
            getProfile(id);
        }
    }, [id, getProfile]);

    useEffect(() => {
        if (profile && profile.contactPerson) {
            setEditingUserProfile(false);
            getUser(profile.contactPerson.id);
        } else {
            getUser(id);
        }
    }, [profile, id, getUser]);

    const refresh = useCallback(() => {
        getProfile(id);
    }, [id, getProfile]);

    if (userState === RequestState.LOADING || profileState === RequestState.LOADING) {
        return <div>{strings.loading}</div>;
    }

    if (userError && profileError) {
        return <ErrorMessage error={userError} />;
    }

    if (!profile || !user) {
        return <div>{strings.userNotFound}</div>;
    }

    return (
        <Profile
            user={editingUserProfile ? user : undefined}
            profile={profile}
            onSelectProfile={onSelectProfile}
            error={error}
            EditButtons={
                <EditButtons
                    subjectUser={user}
                    subject={profile}
                    onDismiss={onDismiss}
                    onError={setError}
                    refresh={refresh}
                />
            }
        />
    );
};

export default ProfileContainer;
