/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = "Add",
    Remove = "Remove",
    Replace = "Replace",
    Move = "Move",
    Copy = "Copy",
    Test = "Test",
    Invalid = "Invalid",
}

export function OperationTypeFromJSON(json: any): OperationType {
    return OperationTypeFromJSONTyped(json, false);
}

export function OperationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationType {
    return json as OperationType;
}

export function OperationTypeToJSON(value?: OperationType | null): any {
    return value as any;
}
