import styles from "./SearchPlacesListInput.module.scss";
import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import { FieldName } from "../../Form/FormTypes";
import FormFieldContainer from "../../Form/FormFieldContainer/FormFieldContainer";
import MultiDataListInputPlacesSearch from "../MultiDataListInput/MultiDataListInputPlacesSearch";

export interface SearchPlacesListInputProps<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, string[]>,
> {
    list: string;
    name: TName;
    label?: string;
    control: Control<TFieldValues, object>;
    single?: boolean;
    errors?: FieldError | FieldError[];
    placeholder?: string;
}

export default function SearchPlacesListInput<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, string[]>,
>({
    list,
    name,
    label,
    control,
    single = false,
    errors,
    placeholder,
}: SearchPlacesListInputProps<TFieldValues, TName>) {
    return (
        <div className={styles.container}>
            <FormFieldContainer name={name} label={label} errors={errors}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <MultiDataListInputPlacesSearch
                            list={list}
                            name={name}
                            value={single ? [value] : value}
                            onChange={onChange}
                            single={single}
                            placeholder={placeholder}
                        />
                    )}
                />
            </FormFieldContainer>
        </div>
    );
}
