import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import {
    REACT_APP_OIDC_AUTHORITY,
    REACT_APP_OIDC_CLIENT_ID,
    REACT_APP_HOST,
    REACT_APP_IDENTITY_URL,
} from "../utils.ts/Env";

const oidcConfig: UserManagerSettings = {
    authority: REACT_APP_OIDC_AUTHORITY,
    client_id: REACT_APP_OIDC_CLIENT_ID,
    redirect_uri: `${REACT_APP_HOST}/auth/callback`,
    response_type: "code",
    post_logout_redirect_uri: REACT_APP_IDENTITY_URL,
    scope: "openid offline",
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export default oidcConfig;
