import moment from "moment";
import strings from "../localization/strings";
import { DayOfWeek, HappeningState, MonthlyRecurrenceType, RecurrenceOutput, RecurrenceType } from "../openapi/backend";

// See spec: https://262.ecma-international.org/5.1/#sec-15.9.1.1
export const DATE_MIN_VALUE = new Date(-8640000000000000);
export const DATE_MAX_VALUE = new Date(8640000000000000);

export const CustomLocale = {
    weekdays: [
        strings.sunday,
        strings.monday,
        strings.tuesday,
        strings.wednesday,
        strings.thursday,
        strings.friday,
        strings.saturday,
    ],
    week: {
        dow: 1,
    },
};

const WEEKDAYS = [
    strings.sunday,
    strings.monday,
    strings.tuesday,
    strings.wednesday,
    strings.thursday,
    strings.friday,
    strings.saturday,
];
const OCCURRENCE = [strings.first, strings.second, strings.third, strings.fourth, strings.fifth];

const LOCALIZEDWEEKDAYS = {
    [DayOfWeek.Monday]: strings.monday.toLowerCase(),
    [DayOfWeek.Tuesday]: strings.tuesday.toLowerCase(),
    [DayOfWeek.Wednesday]: strings.wednesday.toLowerCase(),
    [DayOfWeek.Thursday]: strings.thursday.toLowerCase(),
    [DayOfWeek.Friday]: strings.friday.toLowerCase(),
    [DayOfWeek.Saturday]: strings.saturday.toLowerCase(),
    [DayOfWeek.Sunday]: strings.sunday.toLowerCase(),
};

export function getNthDayOfWeekString(date: Date) {
    return `${OCCURRENCE[Math.ceil(date.getDate() / 7) - 1]} ${WEEKDAYS[date.getDay()]}`.toLowerCase();
}

function getFrequencyPeriod(frequency: number, type: RecurrenceType) {
    switch (true) {
        case frequency === 1 && type === RecurrenceType.Monthly:
            return strings.month;
        case frequency === 1 && type === RecurrenceType.Weekly:
            return strings.week;
        case frequency !== 1 && type === RecurrenceType.Monthly:
            return `${frequency}${strings.ordinalAffix} ${strings.months}`;
        case frequency !== 1 && type === RecurrenceType.Weekly:
            return `${frequency} ${strings.weeks}`;
    }

    return "";
}
function replaceLast(value: string, find: string, replace: string) {
    var lastIndex = value.lastIndexOf(find);

    if (lastIndex === -1) {
        return value;
    }

    var beginString = value.substring(0, lastIndex);
    var endString = value.substring(lastIndex + find.length);

    return beginString + replace + endString;
}

function getDayString({ recurrenceType, monthlyRecurrenceType, weeklyDays, startDate }: RecurrenceOutput) {
    switch (true) {
        case recurrenceType === RecurrenceType.Weekly && weeklyDays?.length === 1:
            return LOCALIZEDWEEKDAYS[weeklyDays![0]];
        case recurrenceType === RecurrenceType.Weekly && weeklyDays?.length !== 1:
            return replaceLast(weeklyDays!.map((d) => LOCALIZEDWEEKDAYS[d]).join(", "), ",", ` ${strings.and}`);
        case recurrenceType === RecurrenceType.Monthly && monthlyRecurrenceType === MonthlyRecurrenceType.Date:
            return `${strings.day} ${startDate.getDate()}`;
        case recurrenceType === RecurrenceType.Monthly &&
            monthlyRecurrenceType === MonthlyRecurrenceType.WeekDayOfMonth:
            return `${strings.the} ${getNthDayOfWeekString(startDate)}`;
    }

    return "";
}

export function getRecurrenceDescription(recurrence: RecurrenceOutput, happeningState: HappeningState) {
    if (happeningState === HappeningState.Concept) {
        return strings.recurrenceViewHappeningConcept;
    }

    const description = recurrence.endDate
        ? strings.recurrenceViewHappeningEndDate
        : strings.recurrenceViewHappeningRepeatMax;

    const frequency = getFrequencyPeriod(recurrence.repeatFrequency, recurrence.recurrenceType);
    const day = getDayString(recurrence);

    const ending = recurrence.endDate ? moment(recurrence.endDate).format("LL") : recurrence.repeatMax!;

    return strings.formatString(description, frequency, day, ending);
}

export function padToTwoDigits(value: number) {
    return value.toString().padStart(2, "0");
}

export function formatTime(date: Date) {
    return moment(date).format("HH:mm");
}

export function formatSessionTime(from: Date, to: Date) {
    return `${formatTime(from)} - ${formatTime(to)}`;
}

export function formatDate(date: Date, format = "DD/MM/YYYY") {
    return moment(date).format(format);
}
