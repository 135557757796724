import { useForm } from "react-hook-form";
import strings from "../../../localization/strings";
import Form from "../../core/Form/Form/Form";
import FormField from "../../core/Form/FormField/FormField";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RequestState } from "../../../hooks/UseApiCall";
import { TagsContext } from "../../../contexts/TagsContext";
import DateRangePickerContainer from "../../core/DateRangePicker/DateRangePickerContainer";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import { RegistrationRuleInput, useCreateRegistrationRuleCallBack } from "../../../hooks/RegistrationRuleHooks";
import { DateRange } from "../../core/DateRangePicker/DateRangePicker";
import moment from "moment";
import styles from "./CreateRegistrationRuleForm.module.scss";
import { RegistrationRulesContext } from "../../../contexts/RegistrationRulesContext";

export default function CreateRegistrationRule(props: { onDismiss: () => void }) {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegistrationRuleInput>();

    const { happeningGroups } = useContext(TagsContext).activeTags;
    const { refresh } = useContext(RegistrationRulesContext);

    const [{ state, error }, createRegistrationRule] = useCreateRegistrationRuleCallBack();

    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: moment().add(1, "days").toDate(),
        endDate: moment().add(2, "days").toDate(),
    });

    const happeningGroupOptions = useMemo(
        () => happeningGroups.map((group) => ({ label: group.detailedName, value: group.id })),
        [happeningGroups],
    );

    useEffect(() => {
        if (state === RequestState.DONE) {
            refresh();
            props.onDismiss();
        }
    }, [state, props, refresh]);

    const onSave = useCallback(
        (request: any) => {
            createRegistrationRule({
                ...request,
                ...dateRange,
            });
        },
        [createRegistrationRule, dateRange],
    );

    return (
        <Form
            onSubmit={onSave}
            handleSubmit={handleSubmit}
            onDismiss={props.onDismiss}
            error={error}
            submitText={strings.createRegistrationRuleButton}
            waitingForSubmit={state === RequestState.LOADING}
        >
            <div className={styles.fieldContainer}>
                <FormDataListInput
                    name={"organisationId"}
                    options={happeningGroupOptions}
                    label={strings.happeningGroup}
                    control={control}
                    errors={errors.organisationId}
                    required
                    list={"happeningGroupOptions"}
                />
            </div>

            <div className={styles.fieldContainer}>
                <DateRangePickerContainer dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <div className={styles.fieldContainer}>
                <FormField
                    name={"maxFreeRegistrations"}
                    label={strings.registrationsAmount}
                    type={"number"}
                    min={0}
                    register={register}
                    errors={errors.maxFreeRegistrations}
                    required
                    placeholder={""}
                />
            </div>
        </Form>
    );
}
