import strings from "../../../localization/strings";
import SearchInput from "../Inputs/SearchInput/SearchInput";

interface Filter {
    query?: string;
}

interface QueryFilterInputProps<T extends Filter> {
    filters: T;
    onChange: (filter: T) => void;
    autoFocus?: boolean;
}

export default function QueryFilterInput<T extends Filter>({ filters, onChange, autoFocus }: QueryFilterInputProps<T>) {
    return (
        <SearchInput
            value={filters.query}
            onSearch={(query) => onChange({ ...filters, query })}
            placeholder={strings.search}
            onBlur={(e) => filters.query !== e.target.value && onChange({ ...filters, query: e.target.value })}
            autoFocus={autoFocus}
        />
    );
}
