/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    ApplicationStatus,
    ApplicationStatusFromJSON,
    ApplicationStatusFromJSONTyped,
    ApplicationStatusToJSON,
    ArrangementListOutput,
    ArrangementListOutputFromJSON,
    ArrangementListOutputFromJSONTyped,
    ArrangementListOutputToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
    UserListOutput,
    UserListOutputFromJSON,
    UserListOutputFromJSONTyped,
    UserListOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface ArrangementApplicationListOutput
 */
export interface ArrangementApplicationListOutput {
    /**
     *
     * @type {string}
     * @memberof ArrangementApplicationListOutput
     */
    id: string;
    /**
     *
     * @type {ApplicationStatus}
     * @memberof ArrangementApplicationListOutput
     */
    status: ApplicationStatus;
    /**
     *
     * @type {Date}
     * @memberof ArrangementApplicationListOutput
     */
    createdAt: Date;
    /**
     *
     * @type {ArrangementListOutput}
     * @memberof ArrangementApplicationListOutput
     */
    arrangement: ArrangementListOutput;
    /**
     *
     * @type {UserListOutput}
     * @memberof ArrangementApplicationListOutput
     */
    applicant?: UserListOutput;
    /**
     *
     * @type {UserListOutput}
     * @memberof ArrangementApplicationListOutput
     */
    reviewer?: UserListOutput;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof ArrangementApplicationListOutput
     */
    subject?: ProfileShortOutput;
    /**
     *
     * @type {string}
     * @memberof ArrangementApplicationListOutput
     */
    subjectMunicipality?: string;
    /**
     *
     * @type {string}
     * @memberof ArrangementApplicationListOutput
     */
    subjectId?: string;
}

/**
 * Check if a given object implements the ArrangementApplicationListOutput interface.
 */
export function instanceOfArrangementApplicationListOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "arrangement" in value;

    return isInstance;
}

export function ArrangementApplicationListOutputFromJSON(json: any): ArrangementApplicationListOutput {
    return ArrangementApplicationListOutputFromJSONTyped(json, false);
}

export function ArrangementApplicationListOutputFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ArrangementApplicationListOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : ApplicationStatusFromJSON(json["status"]),
        createdAt: !exists(json, "createdAt") ? (undefined as any) : new Date(json["createdAt"]),
        arrangement: !exists(json, "arrangement")
            ? (undefined as any)
            : ArrangementListOutputFromJSON(json["arrangement"]),
        applicant: !exists(json, "applicant") ? (undefined as any) : UserListOutputFromJSON(json["applicant"]),
        reviewer: !exists(json, "reviewer") ? (undefined as any) : UserListOutputFromJSON(json["reviewer"]),
        subject: !exists(json, "subject") ? (undefined as any) : ProfileShortOutputFromJSON(json["subject"]),
        subjectMunicipality: !exists(json, "subjectMunicipality") ? (undefined as any) : json["subjectMunicipality"],
        subjectId: !exists(json, "subjectId") ? (undefined as any) : json["subjectId"],
    };
}

export function ArrangementApplicationListOutputToJSON(value?: ArrangementApplicationListOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
