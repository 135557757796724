/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum IndicatorStatus {
    Concept = "Concept",
    Open = "Open",
    Archived = "Archived",
    PlannedForPublication = "PlannedForPublication",
}

export function IndicatorStatusFromJSON(json: any): IndicatorStatus {
    return IndicatorStatusFromJSONTyped(json, false);
}

export function IndicatorStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndicatorStatus {
    return json as IndicatorStatus;
}

export function IndicatorStatusToJSON(value?: IndicatorStatus | null): any {
    return value as any;
}
