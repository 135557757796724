import AccountsTable from "./AccountsTable";
import { useDownloadPartners, UserFilter, useUsers } from "../../hooks/UserHooks";
import { Role, SortOrder, TagType, UserOrderBy } from "../../openapi/backend";
import { Column } from "react-table";
import strings from "../../localization/strings";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import { getName } from "../../utils.ts/GetName";
import { EditAccountModal } from "../Modals/EditAccountModal";
import { NamedOptionsFilter, PageNumberFilter, StringFilter, useFilters } from "../../hooks/UseSearchParam";
import { PartnerFilterBar } from "../core/FilterBar/UserFilterBars";
import { TagsContext } from "../../contexts/TagsContext";
import { UserAccountsHeader } from "./AccountsHeader";
import { NavigationTab } from "../../types/NavigationTab";

interface PartnerItem {
    Id: string;
    Name: ReactNode;
    Profession: string;
    PartnerGroup: string;
    PhoneNumber: string;
    Email: string;
}

export default function usePartnerAccountsTab(): NavigationTab {
    const [partnerUsersState, getPartnerUsers] = useUsers();
    const [, downloadPartners] = useDownloadPartners();

    // Filter
    const tags = useContext(TagsContext).allTags;
    const filterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
            partners: new NamedOptionsFilter(tags.partners),
            page: new PageNumberFilter(),
        }),
        [tags],
    );
    const [rawFilter, setFilter] = useFilters(filterSpecs);
    const filter = rawFilter as UserFilter;
    const onFilterChange = useCallback(
        (filter: UserFilter) => {
            setFilter({ ...filter, page: 1 });
        },
        [setFilter],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilter({ ...filter, page: p });
        },
        [filter, setFilter],
    );

    // Header
    const callbackDownload = useCallback(() => downloadPartners(filter), [filter, downloadPartners]);

    const header = <UserAccountsHeader createUserTitle={strings.addPartner} downloadUsers={callbackDownload} />;

    // Element
    const filterBar = <PartnerFilterBar onChange={onFilterChange} value={filter} />;

    const columns: Column<PartnerItem>[] = [
        {
            Header: strings.name,
            accessor: "Name",
            width: "20%",
        },
        {
            Header: strings.function,
            accessor: "Profession",
            width: "15%",
        },
        {
            Header: strings.happeningGroup,
            accessor: "PartnerGroup",
            width: "20%",
        },
        {
            Header: strings.phone,
            accessor: "PhoneNumber",
            width: "15%",
        },
        {
            Header: strings.email,
            accessor: "Email",
        },
    ];

    const data = useMemo<PartnerItem[]>(() => {
        return (
            partnerUsersState.value?.items.map((user) => ({
                Id: user.id,
                Name: `${getName(user)}`,
                Profession: user.profession ?? strings.unknownProfession,
                PartnerGroup:
                    user.tags.filter((t) => t.type === TagType.Partner).length > 0
                        ? user.tags
                              .filter((t) => t.type === TagType.Partner)
                              .map((t) => t.name)
                              .join(", ")
                        : strings.unknownPartnerGroup,
                PhoneNumber: user.phoneNumber ?? strings.unknownPhoneNumber,
                Email: user.email,
            })) ?? []
        );
    }, [partnerUsersState]);

    const callbackUsers = useCallback(
        (orderBy: UserOrderBy, sortOrder: SortOrder, itemsPerPage: number) =>
            getPartnerUsers(orderBy, sortOrder, itemsPerPage, [Role.Partner], filter),
        [getPartnerUsers, filter],
    );

    const element = (
        <AccountsTable
            columns={columns}
            data={data}
            meta={partnerUsersState.value?.meta}
            page={filter.page}
            setPage={setPage}
            getUsers={callbackUsers}
            error={partnerUsersState.error}
            filterBar={filterBar}
            AccountModal={EditAccountModal}
        />
    );

    return {
        pathname: "partners",
        label: strings.partners,
        element,
        header,
    };
}
