import { AnswerOutput } from "../../../openapi/backend";
import { hasValues } from "../../../utils.ts/Array";
import AnswerIcon from "../../../assets/answer_icon.svg";
import TooltipIcon from "./TooltipIcon";

interface QATooltipProps {
    extraQuestionAnswers?: AnswerOutput[];
    className?: string;
}

export default function QATooltip({ extraQuestionAnswers, className }: QATooltipProps) {
    if (!hasValues(extraQuestionAnswers)) {
        return null;
    }

    return (
        <TooltipIcon
            message={extraQuestionAnswers
                .map((answer) => `${answer.question}\n\n${answer.value}`)
                .join("\n\n--------\n\n")}
            icon={AnswerIcon}
            className={className}
        />
    );
}
