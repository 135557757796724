import { ReactNode } from "react";
import strings from "../../../../localization/strings";
import InputField from "../InputField/InputField";
import styles from "./QuestionField.module.scss";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import MultiInputField from "../MultiField/MultiInputField";
import SubInputLayout from "../SubInputLayout/SubInputLayout";
import { MAX_EXTRA_QUESTION_LENGTH } from "../../../../constants/Validation";
import { QuestionFieldValue } from "../../../../types/FormInputTypes";
import { ManipulationType } from "../MultiField/MultiField";

export interface QuestionFieldProps {
    name: string;
    indexButton?: ReactNode;
    value: Partial<QuestionFieldValue>;
    onChange: (value: Partial<QuestionFieldValue>) => void;
    hideExtraOptions?: boolean;
    titlePlaceholder?: string;
    manipulationType?: ManipulationType;
    rearrangeable?: boolean;
    readOnly?: boolean;
}

export default function QuestionField({
    name,
    indexButton,
    value,
    onChange,
    hideExtraOptions,
    titlePlaceholder,
    manipulationType,
    rearrangeable,
    readOnly,
}: QuestionFieldProps) {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <InputField
                    name={`${name}.title`}
                    placeholder={titlePlaceholder ?? strings.extraQuestionPlaceholder}
                    value={value.title}
                    onChange={(e) => onChange({ ...value, title: e.currentTarget.value })}
                    maxLength={MAX_EXTRA_QUESTION_LENGTH}
                    readOnly={readOnly}
                />
                {indexButton}
            </div>
            {!hideExtraOptions && (
                <CheckboxInput
                    name={`${name}.isMultipleChoice`}
                    value={!!value.isMultipleChoice}
                    label={strings.configureMultipleChoice}
                    onChange={(isMultipleChoice) => onChange({ ...value, isMultipleChoice })}
                    readOnly={readOnly}
                />
            )}
            {value.isMultipleChoice && (
                <SubInputLayout>
                    <MultiInputField
                        name={`${name}.options`}
                        value={value.options}
                        onChange={(options) => onChange({ ...value, options })}
                        manipulationType={manipulationType}
                        rearrangeable={rearrangeable}
                        readOnly={readOnly}
                    />
                </SubInputLayout>
            )}
            {!hideExtraOptions && (
                <CheckboxInput
                    name={`${name}.isRequired`}
                    value={!!value.isRequired}
                    label={strings.requiresExtraQuestion}
                    onChange={(isRequired) => onChange({ ...value, isRequired })}
                    readOnly={readOnly}
                />
            )}
        </div>
    );
}
