import { PropsWithChildren, useMemo } from "react";
import { FieldError } from "react-hook-form";
import strings from "../../../../localization/strings";
import styles from "./FormFieldContainer.module.scss";

interface FormFieldContainerProps {
    name: string;
    label?: string;
    errors?: FieldError | FieldError[];
    className?: string;
}

export enum CustomErrorTypes {
    EmailConflict = "EmailConflict",
    FileSize = "FileSize",
    SessionBeforeNow = "SessionBeforeNow",
    InvalidImageFile = "InvalidImageFile",
}

function GetErrorMessageForErrorType(error: FieldError) {
    switch (error.type) {
        case CustomErrorTypes.EmailConflict.valueOf():
            return strings.emailAlreadyInUse;
        case CustomErrorTypes.FileSize.valueOf():
            return strings.fileSizeTooLarge;
        case CustomErrorTypes.SessionBeforeNow.valueOf():
            return strings.sessionBeforeNow;
        case CustomErrorTypes.InvalidImageFile.valueOf():
            return strings.invalidImageTypeError;
        case "sessionMax1Year":
            return strings.sessionMax1Year;
        case "periodMax1Year":
            return strings.periodMax1Year;
        case "maxDate":
            // @NOTE(Lejun) Not sure if we will ever use this message
            return strings.unknownError;
        case "required":
            return strings.mandatoryField;
        case "pattern":
            return error.message;
        case "min":
            var minRegex = /[0-9]+$/g;
            var match = error.message?.match(minRegex);
            if (!match) {
                return error.message ?? strings.unknownError;
            }
            var min = match[0];

            return strings.formatString(strings.minError, min);
        case "max":
            return strings.formatString(strings.maxError, (error.ref as any).max);
        default:
            return strings.unknownError;
    }
}

/**
 * Wrap an input within a container that provides a matching label and error message.
 */
const FormFieldContainer = ({
    name,
    label,
    errors,
    className,
    children,
}: PropsWithChildren<FormFieldContainerProps>) => {
    const Errors = useMemo(() => {
        if (!errors) {
            return [];
        }

        return Array.isArray(errors)
            ? errors.map((error) => GetErrorMessageForErrorType(error))
            : [GetErrorMessageForErrorType(errors)];
    }, [errors]);

    return (
        <div className={`${styles.formFieldContainer} ${className}`}>
            {label && (
                <label id={`label-${name}`} className={styles.formFieldLabel} htmlFor={`input-${name}`}>
                    {label}
                </label>
            )}
            {children}
            {Errors.length > 0 && (
                <div id={`${name}-error`} className={styles.formError}>
                    {Errors.length === 1 && Errors[0]}
                    {Errors.length > 1 && (
                        <ul>
                            {Errors.map((e, i) => (
                                <li key={i}>{e}</li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

export default FormFieldContainer;
