import cx from "classnames";
import styles from "./ApplicationForm.module.scss";
import { PropsWithChildren } from "react";

export default function InfoBlock({
    className,
    label,
    children,
}: PropsWithChildren<{ className?: string; label: string }>) {
    return (
        <div className={cx(styles.infoBlock, className)}>
            <p className={styles.label}>{label}</p>
            {children}
        </div>
    );
}
