import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";
import CheckboxGroupInput, { Props as CheckboxProps } from "../../Inputs/CheckboxGroupInput/CheckboxGroupInput";

interface FormCheckboxProps<TValue extends string, TForm extends FieldValues, TName extends FieldName<TForm, TValue>>
    extends Omit<CheckboxProps<TValue>, "onChange"> {
    title?: string;
    name: TName | any;
    control: Control<TForm>;
    errors?: FieldError | FieldError[];
}

export default function FormCheckboxGroup<
    TValue extends string,
    TForm extends FieldValues,
    TName extends FieldName<TForm, TValue>,
>({ name, title = "", errors, required, control, ...fieldProps }: FormCheckboxProps<TValue, TForm, TName>) {
    return (
        <FormFieldContainer name={name} label={title} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required, minLength: required ? 1 : 0 }}
                render={({ field: { ref, ...field } }) => <CheckboxGroupInput {...fieldProps} {...field} />}
            />
        </FormFieldContainer>
    );
}
