import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import CameraGreenIcon from "../../../assets/camera_green.svg";
import CameraRedIcon from "../../../assets/camera_red.svg";
import NotesIcon from "../../../assets/info_icon.svg";
import MedicalIcon from "../../../assets/medical_icon.svg";
import { LocalHappeningContext } from "../../../contexts/LocalHappeningContext";
import { useAttend, useUnsubscribe } from "../../../hooks/RegistrationHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import {
    AppointmentViewModel,
    HappeningType,
    LocationType,
    LocationViewModel,
    OptionalBoolean,
    PaymentViewModel,
    RegistrationStatus,
} from "../../../openapi/backend";
import { hasValues } from "../../../utils.ts/Array";
import { RemoveButton } from "../../core/RoundButton/RoundButton";
import { AssignHappeningModal } from "../../Forms/AssignHappeningModal/AssignHappeningModal";
import CreateAppointmentModalContainer from "../../Forms/CreateAppointment/CreateAppointmentModal";
import AppointmentsTable from "../../Forms/ViewHappening/AppointmentsTable";
import UnsubscribeParticipantReasonModal from "../../Forms/ViewHappening/UnsubscribeParticipantReasonModal";
import FlexRow from "../../Layouts/FlexRow/FlexRow";
import PaymentTooltip from "../PaymentTooltip/PaymentTooltip";
import TextButton from "../TextButton/TextButton";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import styles from "./HappeningRegistration.module.scss";
import ToggleButton from "../ToggleButton/ToggleButton";
import { findAttendencyBySessionId } from "../../../utils.ts/AttendencyUtils";
import { HappeningCombinedRegistrationOutput } from "../../../utils.ts/HappeningType";
import QATooltip from "../TooltipIcon/QATooltip";
import moment from "moment";

interface HappeningRegistrationProps {
    registration: HappeningCombinedRegistrationOutput;
    name: string;
    status: RegistrationStatus;
    happeningType: HappeningType;
    locationType: LocationType;
    age: number;
    requiresPayment: boolean;
    requiresApprovalFromMarketing: boolean;
    price: number;
    approvedForMarketing: boolean;
    notes?: string;
    medicalNotes?: string;
    payment?: PaymentViewModel;
    happeningId: string;
    participantId: string;
    isAllowedToManage: boolean;
    attendance?: { attended: number; sessions: number };
    sessionId?: number;
    onClick: () => void;
    appointments: AppointmentViewModel[];
    registrationId: string;
    location?: LocationViewModel;
}

export default function HappeningRegistration({
    registration,
    name,
    status,
    happeningType,
    locationType,
    age,
    requiresPayment,
    requiresApprovalFromMarketing,
    price,
    approvedForMarketing,
    notes,
    medicalNotes,
    payment,
    happeningId,
    participantId,
    isAllowedToManage,
    attendance,
    sessionId,
    onClick,
    appointments,
    registrationId,
    location,
}: HappeningRegistrationProps) {
    const { happening, refresh } = useContext(LocalHappeningContext);
    const [unsubModalOpen, setUnsubModalOpen] = useState(false);
    const [addAppointmentModalOpen, setAddAppointmentModalOpen] = useState(false);
    const [assignHappeningModalOpen, setAssignHappeningModalOpen] = useState(false);
    const [{ state: unsubState, error: unsubError }, unsubscribe] = useUnsubscribe();
    const isPrivateHappening = useMemo(() => happeningType === HappeningType.PrivateHappening, [happeningType]);
    const isStandardHappening = useMemo(() => happeningType === HappeningType.Happening, [happeningType]);
    const [attendRequestState, doAttend] = useAttend();

    useEffect(() => {
        if (unsubState === RequestState.DONE) {
            refresh();
            setUnsubModalOpen(false);
        }
    }, [unsubState, refresh]);

    useEffect(() => {
        if (attendRequestState.state === RequestState.DONE) {
            refresh();
        }
    }, [attendRequestState.state, refresh]);

    const openAppointmentModal = useCallback(() => setAddAppointmentModalOpen(true), []);
    const closeAppointmentModal = useCallback(() => setAddAppointmentModalOpen(false), []);
    const onSuccessAppointment = useCallback(() => {
        refresh();
        closeAppointmentModal();
    }, [refresh, closeAppointmentModal]);

    const openAssignHappeningModal = useCallback(() => setAssignHappeningModalOpen(true), []);
    const closeAssignHappeningModal = useCallback(() => setAssignHappeningModalOpen(false), []);
    const onSuccessAssignHappening = useCallback(() => {
        refresh();
        closeAssignHappeningModal();
    }, [refresh, closeAssignHappeningModal]);

    const attendValue = useMemo(
        () => (sessionId ? findAttendencyBySessionId(registration, sessionId)?.present : undefined),
        [registration, sessionId],
    );

    const attendancyCountString = useMemo(() => {
        if (!attendance) {
            return;
        }

        return strings.formatString(
            strings.attendanceSummary,
            attendance.attended.toString(),
            attendance.sessions.toString(),
        );
    }, [attendance]);

    const selectedDateTime = useMemo(() => {
        if (sessionId === undefined) {
            return;
        }

        return happening?.dateTimes.find((x) => x.id === sessionId);
    }, [happening, sessionId]);

    const isActiveRegistration = useMemo(() => {
        if (isPrivateHappening) {
            return status === RegistrationStatus.Active;
        }

        if (registration.cancelledOn) {
            if (moment(selectedDateTime?.sessionEnd).isBefore(moment(registration.cancelledOn))) {
                return true;
            } else {
                return false;
            }
        }

        // Happening is not canceled when no cancellation date is set
        return true;
    }, [isPrivateHappening, selectedDateTime, registration, status]);

    return (
        <div className={styles.container}>
            <div className={styles.mainRow}>
                {!!sessionId && isActiveRegistration && (
                    <ToggleButton
                        value={attendValue}
                        onChange={(value) => {
                            if (sessionId === undefined) {
                                return;
                            }

                            const attendency = {
                                participantId: registration.participantId,
                                happeningId: registration.happeningId,
                                happeningDateTimeId: sessionId,
                                attending: value,
                                paidCash: registration.paidCash ? OptionalBoolean.True : OptionalBoolean.False,
                            };
                            doAttend(attendency);
                        }}
                    />
                )}
                <button
                    type="button"
                    onClick={onClick}
                    className={`${styles.label} ${!isActiveRegistration && styles.canceled}`}
                    aria-label={`${strings.openProfileOf} ${name}`}
                >
                    {name} {!!attendance && (strings.formatString("({0})", attendancyCountString as string) as string)}
                </button>

                <UnsubscribeParticipantReasonModal
                    isOpen={unsubModalOpen}
                    onUnsubscribe={(reason: string) =>
                        unsubscribe({
                            happeningId: happeningId,
                            profileIds: [participantId],
                            reason,
                        })
                    }
                    onDismiss={() => setUnsubModalOpen(false)}
                    isLoading={unsubState === RequestState.LOADING}
                    error={unsubError}
                />

                <FlexRow>
                    <div className={styles.labelAge}>{`${age} ${strings.years}`}</div>

                    {medicalNotes && (
                        <TooltipIcon message={medicalNotes} icon={MedicalIcon} className={styles.tooltip} />
                    )}

                    {notes && <TooltipIcon message={notes} icon={NotesIcon} className={styles.tooltip} />}

                    <QATooltip className={styles.tooltip} extraQuestionAnswers={registration.extraQuestionAnswers} />

                    {requiresApprovalFromMarketing &&
                        (approvedForMarketing ? (
                            <TooltipIcon
                                message={strings.approvedForMarketing}
                                icon={CameraGreenIcon}
                                className={styles.tooltip}
                            />
                        ) : (
                            <TooltipIcon
                                message={strings.notApprovedForMarketing}
                                icon={CameraRedIcon}
                                className={styles.tooltip}
                                actionRequired={!approvedForMarketing}
                            />
                        ))}

                    {isStandardHappening && price > 0 && (
                        <PaymentTooltip
                            className={styles.tooltip}
                            requiresPayment={requiresPayment}
                            payment={payment}
                        />
                    )}

                    {status === RegistrationStatus.Active &&
                        appointments.filter((x) => !x.cancelled).length === 0 &&
                        isAllowedToManage && (
                            <RemoveButton onClick={() => setUnsubModalOpen(true)} className={styles.tooltip} />
                        )}
                </FlexRow>
            </div>
            {isPrivateHappening && (
                <div className={styles.appointmentsContainer}>
                    {isAllowedToManage && isActiveRegistration && (
                        <div className={styles.actionButtonContainer}>
                            <TextButton
                                className={styles.actionButton}
                                text={strings.addAppointment}
                                onClick={openAppointmentModal}
                            />
                            {!hasValues(appointments) && (
                                <TextButton
                                    className={styles.actionButton}
                                    text={strings.assignTo}
                                    onClick={openAssignHappeningModal}
                                />
                            )}
                        </div>
                    )}

                    {hasValues(appointments) && (
                        <AppointmentsTable
                            happeningId={happeningId}
                            appointments={appointments}
                            locationType={locationType}
                            price={price}
                            isAllowedToUnsubscribe={isAllowedToManage}
                        />
                    )}
                </div>
            )}
            {addAppointmentModalOpen && (
                <CreateAppointmentModalContainer
                    registrationId={registrationId}
                    happeningId={happeningId}
                    locationType={locationType}
                    onDismiss={closeAppointmentModal}
                    onSuccess={onSuccessAppointment}
                    location={location}
                />
            )}
            {assignHappeningModalOpen && (
                <AssignHappeningModal
                    happeningId={happeningId}
                    registrationId={registrationId}
                    participantName={name}
                    onDismiss={closeAssignHappeningModal}
                    onSuccess={onSuccessAssignHappening}
                />
            )}
        </div>
    );
}
