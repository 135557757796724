/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ApplicationOrderBy {
    Default = "Default",
    Applicant = "Applicant",
    Municipality = "Municipality",
    Reviewer = "Reviewer",
    Status = "Status",
    CreationDate = "CreationDate",
}

export function ApplicationOrderByFromJSON(json: any): ApplicationOrderBy {
    return ApplicationOrderByFromJSONTyped(json, false);
}

export function ApplicationOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationOrderBy {
    return json as ApplicationOrderBy;
}

export function ApplicationOrderByToJSON(value?: ApplicationOrderBy | null): any {
    return value as any;
}
