import { useCallback, useEffect, useState } from "react";
import styles from "./CheckboxInput.module.scss";
import classNames from "classnames";

type Props = {
    className?: string;
    name?: string;
    label?: string;
    defaultValue?: boolean;
    onChange?: (value: boolean) => void;
    value?: boolean;
    readOnly?: boolean;
};

const CheckboxInput = ({ className, name, label, defaultValue = false, value, onChange, readOnly }: Props) => {
    const [checked, setChecked] = useState(value || defaultValue);

    useEffect(() => {
        if (!readOnly && onChange && value !== checked) {
            onChange(checked);
        }
    }, [checked, value, onChange, readOnly]);

    const onClick = useCallback(
        (e) => {
            if (readOnly) {
                return;
            }
            setChecked(e.target.checked);
        },
        [readOnly],
    );

    const onToggle = useCallback(() => {
        if (readOnly) {
            return;
        }
        setChecked((c) => !c);
    }, [readOnly]);

    return (
        <div className={classNames(styles.container, readOnly && styles.readOnly, className)}>
            <input
                type="checkbox"
                className={styles.defaultCheckbox}
                name={name}
                value={checked ? "true" : "false"}
                checked={checked}
                onChange={onClick}
                id={name}
                disabled={readOnly}
            />

            <div className={`${styles.checkbox} ${checked && styles.selected}`} onClick={onToggle} />
            {label && (
                <label htmlFor={name} className={styles.label}>
                    {label}
                </label>
            )}
        </div>
    );
};

export default CheckboxInput;
