import { FocusEventHandler, useCallback, FocusEvent } from "react";

function hasParent(parent: HTMLElement, target: HTMLElement): boolean {
    // Pedy's claim
    if (!target) {
        return false;
    }

    return target.parentNode === parent || hasParent(parent, target.parentNode as HTMLElement);
}

/**
 * Creates a blur function that does not trigger when a child is focused.
 * In normal onBlur, the function is triggered if any component other than the current is focused
 * @param onBlur The onBlur function you wish to trigger.
 * @returns the function you should use
 */
const useRealBlur = <T extends HTMLElement>(onBlur: FocusEventHandler<T>) =>
    useCallback(
        (e: FocusEvent<T, Element>) => {
            if (hasParent(e.currentTarget, e.relatedTarget as HTMLElement)) {
                // focused a child, so did not really blur
                return;
            }

            onBlur(e);
        },
        [onBlur],
    );

export default useRealBlur;
