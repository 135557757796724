import { ReactNode, useMemo } from "react";
import { Column } from "react-table";
import strings from "../../../localization/strings";
import { AppointmentViewModel, LocationType } from "../../../openapi/backend";
import { formatSessionTime } from "../../../utils.ts/DateTime";
import { formatAsDate } from "../../../utils.ts/Formatting";
import AppointmentDataItem from "../../core/AppointmentDataItem/AppointmentDataItem";
import AppointmentItem from "../../core/AppointmentItem/AppointmentItem";
import StaticTable from "../../core/StaticTable/StaticTable";
import ProfileContent from "../../core/ProfileContent/ProfileContent";
import { Size } from "../../../types/Size";

export type AppointmentTableProps = {
    happeningId: string;
    locationType: LocationType;
    price: number;
    appointments: AppointmentViewModel[];
    isAllowedToUnsubscribe: boolean;
};

type AppointmentTableItem = {
    Date: ReactNode;
    Time: ReactNode;
    Appointer: ReactNode;
    Location: ReactNode;
};

const itemPerPage = 5;

export default function AppointmentsTable({
    happeningId,
    appointments,
    locationType,
    price,
    isAllowedToUnsubscribe,
}: AppointmentTableProps) {
    const columns: Column<AppointmentTableItem>[] = [
        {
            Header: strings.appointmentDate,
            accessor: "Date",
            width: "15%",
        },
        {
            Header: strings.appointmentTime,
            accessor: "Time",
            width: "15%",
        },
        {
            Header: strings.appointer,
            accessor: "Appointer",
            width: "20%",
        },
        {
            Header: strings.appointmentLocation,
            accessor: "Location",
            width: "50%",
        },
    ];

    const data = useMemo<AppointmentTableItem[]>(
        () =>
            appointments.map((appointment) => {
                return {
                    Date: (
                        <AppointmentDataItem appointment={appointment} text={formatAsDate(appointment.sessionStart)} />
                    ),
                    Time: (
                        <AppointmentDataItem
                            appointment={appointment}
                            text={formatSessionTime(appointment.sessionStart, appointment.sessionEnd)}
                        />
                    ),
                    Appointer: appointment.appointer ? (
                        <ProfileContent profile={appointment.appointer} size={Size.SMALL} />
                    ) : null,
                    Location: (
                        <AppointmentItem
                            happeningId={happeningId}
                            appointment={appointment}
                            locationType={locationType}
                            price={price}
                            isAllowedToUnsubscribe={isAllowedToUnsubscribe}
                        />
                    ),
                };
            }),
        [happeningId, appointments, locationType, price, isAllowedToUnsubscribe],
    );

    return (
        <StaticTable
            columns={columns}
            data={data}
            renderHeader={true}
            itemsPerPage={itemPerPage}
            showPagination={data.length > itemPerPage}
        />
    );
}
