import TextButton, { LinkButton } from "../core/TextButton/TextButton";
import { REACT_APP_IDENTITY_URL } from "../../utils.ts/Env";
import strings from "../../localization/strings";
import IdentityPage, { LinkContainer } from "../IdentityPage/IdentityPage";
import { useSignout } from "../../authentication/useSignout";

const BackoffPage = () => {
    const signout = useSignout();

    return (
        <IdentityPage>
            <h1>{strings.blockedTitle}</h1>
            <p>{strings.blockedContent}</p>
            <LinkContainer>
                <LinkButton href={`${REACT_APP_IDENTITY_URL}/settings`} text={strings.settings} />
                <TextButton onClick={signout} text={strings.logout} />
            </LinkContainer>
        </IdentityPage>
    );
};

export default BackoffPage;
