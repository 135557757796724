import { useCallback } from "react";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import { NotificationsFilter } from "../../../hooks/NotificationHooks";
import QueryFilterInput from "./QueryFilterInput";

export function NotificationFilterBar({ value: filters, onChange }: FilterBarPropsWithValue<NotificationsFilter>) {
    const onReset = useCallback(() => onChange({}), [onChange]);

    return (
        <FilterBar onReset={onReset}>
            <QueryFilterInput filters={filters} onChange={onChange} />
        </FilterBar>
    );
}
