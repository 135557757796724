import { useCallback, useEffect, useState } from "react";
import { TagInput, useUpdateTag } from "../../../hooks/TagHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import { toastError, toastSuccess } from "../../../utils.ts/Toaster";
import strings from "../../../localization/strings";
import { TagViewModel } from "../../../openapi/backend";
import styles from "./TagDescriptionButton.module.scss";
import LengthIndicator from "../LengthIndicator/LengthIndicator";

interface TagDescriptionButtonProps {
    className?: string;
    tag: TagViewModel;
    maxLength?: number;
}

export type UpdateTagDescription = TagInput & {
    id: string;
    description: string;
};

export default function TagDescriptionButton({ className, tag, maxLength }: TagDescriptionButtonProps) {
    const [{ state: updateState, value: updatedValue }, updateTag] = useUpdateTag();
    const [value, setValue] = useState<string>(tag.description ?? "");
    const [localTag, setLocalTag] = useState<TagViewModel>(tag);

    const changeDescription = useCallback(
        (newDescription: string) => {
            const inputTag = { ...localTag, childTags: localTag.childTags?.map((x) => x.id) };
            if (
                (localTag.description === undefined && newDescription.length === 0) ||
                (localTag.description && newDescription === localTag.description)
            ) {
                return;
            }

            updateTag({
                ...inputTag,
                description: newDescription,
            });
        },
        [localTag, updateTag],
    );

    useEffect(() => {
        if (updateState === RequestState.DONE && updatedValue) {
            setLocalTag(updatedValue);
        }
    }, [updateState, updatedValue]);

    useEffect(() => {
        if (updateState === RequestState.DONE) {
            toastSuccess(strings.formatString(strings.updatedDescription, localTag.name) as string);
        }
    }, [localTag.name, updateState]);

    useEffect(() => {
        if (updateState === RequestState.ERROR) {
            toastError(strings.formatString(strings.updatedDescriptionError, localTag.name) as string);
        }
    }, [localTag.name, updateState]);

    return (
        <div className={`${styles.container} ${className}`}>
            <textarea
                maxLength={maxLength}
                rows={3}
                className={styles.tagDescriptionButton}
                onChange={(e) => setValue(e.target.value)}
                placeholder={strings.addDescription}
                value={value}
                onBlur={() => changeDescription(value)}
            />
            {maxLength && <LengthIndicator length={value.length} maxLength={maxLength} />}
        </div>
    );
}
