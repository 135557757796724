import React, { PropsWithChildren } from "react";
import { Configuration } from "../openapi/backend";
import { UserApi } from "../openapi/backend/apis/UserApi";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<UserApi> = (params) => new UserApi(new Configuration(params));

export const UserApiContext = React.createContext(createApi({}));

export const UserApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={UserApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
