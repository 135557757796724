import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import ErrorMessage from "../components/core/ErrorMessage/ErrorMessage";
import { useGetHappeningDetails } from "../hooks/HappeningsHooks";
import { RequestState } from "../hooks/UseApiCall";
import { HappeningViewModel } from "../openapi/backend";

interface IHappeningContext {
    happening: HappeningViewModel | undefined;
    isLoading: boolean;
    isNotFound: boolean;
    refresh: () => void;
}

export const LocalHappeningContext = React.createContext<IHappeningContext>({
    happening: undefined,
    isLoading: false,
    isNotFound: false,
    refresh: () => {},
});

export const LocalHappeningContextProvider = ({ children, id }: PropsWithChildren<{ id: string }>) => {
    const [happening, setHappening] = useState<HappeningViewModel | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isNotFound, setNotFound] = useState<boolean>(false);
    const [requestState, getHappening] = useGetHappeningDetails(id);

    useEffect(() => {
        getHappening();
    }, [getHappening]);

    useEffect(() => {
        if (requestState.value) {
            setHappening(requestState.value);
        }
    }, [requestState.value]);

    useEffect(() => {
        setLoading([RequestState.IDLE, RequestState.LOADING].includes(requestState.state));
        setNotFound(requestState.state === RequestState.ERROR);
    }, [requestState.state]);

    return (
        <LocalHappeningContext.Provider
            value={useMemo(
                () => ({ happening, isLoading, isNotFound, refresh: getHappening }),
                [happening, isLoading, isNotFound, getHappening],
            )}
        >
            {children}
            {requestState.error && <ErrorMessage error={requestState.error} isToast />}
        </LocalHappeningContext.Provider>
    );
};
