import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { QuickAddButton, RemoveButton } from "../../RoundButton/RoundButton";
import styles from "./MultiField.module.scss";
import strings from "../../../../localization/strings";
import { kajify } from "../../../../utils.ts/Array";
import DisplacementButton from "../../DisplacementButton/DisplacementButton";
import produce from "immer";

export type ManipulationType = "append" | "prepend";

type MultiInputFieldProps<T> = {
    value?: T | T[];
    onChange: (value: T[]) => void;
    label?: string;
    Field: (props: FieldProps<T>) => JSX.Element;
    defaultValue?: T;
    compensateForLabel?: boolean;
    manipulationType?: ManipulationType;
    rearrangeable?: boolean;
    readOnly?: boolean;
    isEmptyValue?: (value: T | undefined) => boolean;
};

export type FieldProps<T> = {
    fieldValue?: T;
    listValue: T[];
    index: number;
    onChange: (value: T) => void;
};

type ListItemProps<T> = {
    index: number;
    totalSize: number;
    label?: string;
    fieldValue?: T;
    listValue: T[];
    append: () => void;
    remove: () => void;
    onChange: (value: T) => void;
    Field: (props: FieldProps<T>) => JSX.Element;
    compensateForLabel?: boolean;
    addButtonIndex?: number;
    rearrangeable?: boolean;
    onUpClick: () => void;
    onDownClick: () => void;
    readOnly?: boolean;
};

function ListItem<T>({
    index,
    totalSize,
    label,
    fieldValue,
    listValue,
    append,
    remove,
    onChange,
    Field,
    compensateForLabel,
    addButtonIndex = 0,
    rearrangeable,
    onDownClick,
    onUpClick,
    readOnly,
}: ListItemProps<T>) {
    const isAddRow = index === addButtonIndex;
    return (
        <>
            {label && (
                <div className={cx(styles.rowField, styles.label, compensateForLabel && styles.noMarginBottom)}>
                    {strings.formatString(label, index + 1)}
                </div>
            )}
            <div className={styles.rowField}>
                <Field index={index} fieldValue={fieldValue} listValue={listValue} onChange={onChange} />
                {rearrangeable && !readOnly && (
                    <DisplacementButton
                        className={cx(styles.displaceButton, compensateForLabel && styles.compensateForLabel)}
                        onUpClick={index === 0 ? undefined : onUpClick}
                        onDownClick={index + 1 === totalSize ? undefined : onDownClick}
                    />
                )}
                {!readOnly && (
                    <div className={cx(styles.indexButton, compensateForLabel && styles.compensateForLabel)}>
                        {isAddRow ? <QuickAddButton onClick={append} /> : <RemoveButton onClick={remove} />}
                    </div>
                )}
            </div>
        </>
    );
}

function MultiField<T>({
    value,
    onChange,
    label,
    Field,
    defaultValue,
    compensateForLabel,
    manipulationType = "prepend",
    rearrangeable,
    readOnly,
    isEmptyValue,
}: MultiInputFieldProps<T>) {
    const [innerValue, setInnerValue] = useState<(T | undefined)[]>(() => {
        const valueArray = kajify(value);

        if (manipulationType === "prepend") {
            return [defaultValue, ...valueArray];
        } else {
            return [...valueArray, defaultValue];
        }
    });
    const [swapCount, setSwapCount] = useState(0);

    const Inputs = useMemo(() => {
        //@NOTE(Lejun) In my opinion a hacky way to hide empty inputs for readonly view, but it works
        const displayedItems = readOnly
            ? innerValue.filter((x) => (isEmptyValue ? !isEmptyValue(x) : !!x))
            : innerValue;

        return displayedItems.map((item, index, list) => {
            return (
                <ListItem
                    key={`${index}-${innerValue.length}-${swapCount}`}
                    index={index}
                    fieldValue={item}
                    listValue={innerValue.filter((x) => !!x) as T[]}
                    append={() => {
                        if (manipulationType === "prepend") {
                            setInnerValue((current) => [defaultValue, ...current.slice(1), current[0]]);
                        } else {
                            setInnerValue((current) => [...current, defaultValue]);
                        }
                    }}
                    remove={() => setInnerValue((current) => current.filter((_, i) => i !== index))}
                    onChange={(newValue: T) =>
                        setInnerValue((current) => current.map((x, i) => (i === index ? newValue : x)))
                    }
                    label={label}
                    Field={Field}
                    compensateForLabel={compensateForLabel}
                    addButtonIndex={manipulationType === "prepend" ? 0 : innerValue.length - 1}
                    rearrangeable={rearrangeable}
                    onDownClick={() => {
                        setInnerValue((current) => {
                            const next = produce(current, (draft) => {
                                const temp = draft[index];
                                draft[index] = draft[index + 1];
                                draft[index + 1] = temp;
                            });
                            return next;
                        });
                        setSwapCount((current) => (current + 1) % 3);
                    }}
                    onUpClick={() => {
                        setInnerValue((current) => {
                            const next = produce(current, (draft) => {
                                const temp = draft[index];
                                draft[index] = draft[index - 1];
                                draft[index - 1] = temp;
                            });
                            return next;
                        });
                        setSwapCount((current) => (current + 1) % 3);
                    }}
                    totalSize={list.length}
                    readOnly={readOnly}
                />
            );
        });
    }, [
        innerValue,
        Field,
        label,
        defaultValue,
        compensateForLabel,
        manipulationType,
        rearrangeable,
        swapCount,
        readOnly,
        isEmptyValue,
    ]);

    useEffect(() => {
        onChange(innerValue.filter((x) => !!x) as T[]);
    }, [innerValue]); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={styles.selectFields}>{Inputs}</div>;
}

export default MultiField;
