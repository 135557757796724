import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import { RemoteSearchInput } from "../../../../models/IndicatorModels";
import RemoteHappeningSuggestionInput from "../../Inputs/RemoteSuggestionInput/RemoteHappeningSuggestionInput";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

interface Props<TFieldValues extends FieldValues, TName extends FieldName<TFieldValues, RemoteSearchInput>> {
    label: string;
    name: TName;
    control: Control<TFieldValues, object>;
    errors?: FieldError;
    required?: boolean;
    className?: string;
    readOnly?: boolean;
}

export default function FormHappeningInput<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, RemoteSearchInput>,
>({ control, name, label, errors, required, ...fieldProps }: Props<TFieldValues, TName>) {
    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value } }) => (
                    <RemoteHappeningSuggestionInput
                        {...fieldProps}
                        name={`${name}.query`}
                        value={value}
                        onChangeValue={onChange}
                    />
                )}
            />
        </FormFieldContainer>
    );
}
