import { DUPLICATE_ID_KEY } from "../constants/Route";
import { HappeningViewModel } from "../openapi/backend";
import { REACT_APP_BACKEND_URL, REACT_APP_FRONTEND_CLIENT_URL, REACT_APP_ClUSTER_DYNAMIC_APP_URL } from "./Env";

export const registrationLink = (happening: HappeningViewModel): string =>
    `${REACT_APP_BACKEND_URL}/register/${happening.id}`;

export const happeningDetailLink = (happening: HappeningViewModel): string =>
    `${REACT_APP_FRONTEND_CLIENT_URL}/activiteit/${happening.id}`;

export const happeningCardLink = (happening: HappeningViewModel): string =>
    `${REACT_APP_FRONTEND_CLIENT_URL}/preview/${happening.id}`;

export const happeningSuggestionOverviewLink = "/activities/suggestions";
export const createHappeningLink = "/activities/new";
export const createPrivateHappeningLink = "/activities/private/new";
export const createExternalHappeningLink = "/activities/external/new";

export const getHappeningLink = (id: string) => {
    return `/activities/${id}`;
};

export const getHappeningDuplicateLink = (id: string) => {
    const query = new URLSearchParams({ [DUPLICATE_ID_KEY]: id.toString() });
    return `${createHappeningLink}?${query.toString()}`;
};

export const getPrivateHappeningDuplicateLink = (id: string) => {
    const query = new URLSearchParams({ [DUPLICATE_ID_KEY]: id.toString() });
    return `${createPrivateHappeningLink}?${query.toString()}`;
};

export const getUpdateHappeningLink = (id: string) => {
    return `/activities/${id}/update`;
};
export const getUpdatePrivateHappeningLink = (id: string) => `/activities/private/${id}/update`;

export const getUpdateExternalHappeningLink = (id: string) => `/activities/external/${id}/update`;

export const getIframeLink = (filters?: string): string => {
    const queryParams = filters ? `&${filters}` : "";
    return `${REACT_APP_FRONTEND_CLIENT_URL}?host=iframe${queryParams}`;
};

export const getApplicationLink = (id: string) => {
    return `/applications/${id}`;
};

export const createApplicationLink = "/applications/new";

export const getApplicationDuplicateLink = (id: string) => {
    const query = new URLSearchParams({ [DUPLICATE_ID_KEY]: id });
    return `${createApplicationLink}?${query.toString()}`;
};

export function getDynamicClusterAppUrl(id: string): string {
    var link = REACT_APP_ClUSTER_DYNAMIC_APP_URL.replace("<id>", id);

    const fallbackUrl = `${REACT_APP_FRONTEND_CLIENT_URL}/cluster/${id}?share=1`;

    return link.replace("<fallbackUrl>", fallbackUrl);
}

export const createIndicatorLink = "/monitoring/indicators/new";

export const getIndicatorLink = (id: number) => {
    return `/monitoring/indicators/${id}`;
};
export const getIndicatorDuplicateLink = (id: number) => {
    const query = new URLSearchParams({ [DUPLICATE_ID_KEY]: id.toString() });
    return `${createIndicatorLink}?${query.toString()}`;
};
export const getAddMeasurementLink = (id: number | string) => {
    return `/monitoring/indicators/${id}/new`;
};
export const getEditMeasurementLink = (id: number | string) => {
    return `/monitoring/measurement/${id}`;
};

export const getMailLink = (email: string) => `mailto:{email}`;

export const getPhoneLink = (phonenumber: string) => `tel:{phonenumber}`;
