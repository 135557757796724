import ReactTooltip from "react-tooltip";
import styles from "./TooltipIcon.module.scss";

interface TooltipIconProps {
    message: string;
    icon?: string;
    className?: string;
    actionRequired?: boolean;
    onClick?: () => void;
}

const TooltipIcon = ({ message, icon, className, actionRequired, onClick }: TooltipIconProps) => {
    let color = undefined;
    switch (actionRequired) {
        case false:
            color = styles.greenButton;
            break;
        case true:
            color = styles.redButton;
            break;
        default:
            color = undefined;
    }

    return (
        <div>
            <ReactTooltip className={styles.window} />
            <div
                className={`${className} ${styles.tooltip} ${!!onClick && styles.clickable} ${color}`}
                data-tip={message}
                onClick={onClick}
            >
                <img src={icon} alt={message} />
            </div>
        </div>
    );
};

export default TooltipIcon;
