import { debounce } from "lodash";
import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { RequestState } from "../../../../hooks/UseApiCall";
import { useSchoolPlaces } from "../../../../utils.ts/GooglePlacesAPI";
import InputField from "../InputField/InputField";
import styles from "./DataListInput.module.scss";
import strings from "../../../../localization/strings";
import { toastError } from "../../../../utils.ts/Toaster";

export interface DataListInputPlacesSearchProps
    extends Omit<
        React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        "onChange" | "multiple"
    > {
    name: string;
    value?: string;
    list: string;
    onChange: (selected?: string) => void;
}

const findSelectedOption = (options: any[], value: string) => options.find((x) => x.label === value);

export default function DataListInputSearch({ value, list, name, onChange, ...props }: DataListInputPlacesSearchProps) {
    const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
    const [innerValue, setInnerValue] = useState("");
    const [{ value: schoolPlaces, state }, getSchools] = useSchoolPlaces();
    const [showMessage, setShowMessage] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const [gsError, setGsError] = useState<Error | undefined>();

    const debouncedSearch = useMemo(
        () =>
            debounce(async (query: string) => {
                try {
                    await getSchools(query);
                    setGsError(undefined);
                } catch (e: any) {
                    setGsError(e);
                }
            }, 1000),
        [getSchools],
    );

    useEffect(() => {
        setLoading(true);
        if (innerValue.length > 0) {
            debouncedSearch(innerValue);
        }
    }, [innerValue, debouncedSearch, setLoading]);

    useEffect(() => {
        if (gsError) {
            toastError(strings.schoolsWentWrong);
        }
    }, [gsError]);

    useMemo(() => {
        if (state === RequestState.DONE) {
            setLoading(false);

            if (schoolPlaces?.predictions && schoolPlaces.predictions.length > 0) {
                const options = schoolPlaces.predictions.map((place) => ({
                    label: place.description,
                    value: place.description,
                }));
                setOptions(options);
            }
        }
    }, [schoolPlaces, state, setLoading]);

    useEffect(() => {
        if (
            state === RequestState.DONE &&
            schoolPlaces?.predictions &&
            schoolPlaces.predictions.length <= 0 &&
            innerValue.length > 0 &&
            !loading
        ) {
            setShowMessage(strings.noSchoolsFound);
        } else {
            setShowMessage(undefined);
        }
    }, [schoolPlaces, state, innerValue, loading]);

    const onValueChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            const selected = findSelectedOption(options, inputValue);

            if (selected) {
                setInnerValue("");
            } else {
                setInnerValue(inputValue);
            }
            onChange && onChange(selected?.value);
        },
        [options, onChange],
    );

    const Options = useMemo(() => options.map(({ label, value }) => <option key={value} value={label} />), [options]);

    return (
        <>
            <div className={styles.container}>
                <InputField
                    {...props}
                    list={list}
                    value={value || innerValue}
                    onChange={onValueChange}
                    className={styles.searchField}
                    onKeyPress={(e) => {
                        if (e.key !== "Enter") {
                            return;
                        }
                        e.preventDefault();
                    }}
                />
            </div>
            {showMessage && <div className={styles.subText}>{showMessage}</div>}
            <datalist id={list}>{Options}</datalist>
        </>
    );
}
