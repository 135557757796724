import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import styles from "./DateRangePicker.module.scss";
import strings from "../../../localization/strings";
import Datepicker from "../Inputs/DatePicker/DatePicker";

export interface DateRange {
    startDate: Date;
    endDate: Date;
}

interface DateTimePickerProps {
    dateRange: DateRange;
    setDateRange: Dispatch<SetStateAction<DateRange>>;
}

type HeaderLabelProps = {
    label: string;
};
const HeaderLabel = ({ label }: HeaderLabelProps) => <label className={styles.label}>{label}</label>;

export default function DateRangePicker({ dateRange: dateTime, setDateRange: onChange }: DateTimePickerProps) {
    return (
        <div className={styles.container}>
            <HeaderLabel label={strings.registrationRulePeriod} />

            <div className={styles.contentContainer}>
                <div className={styles.dateField}>
                    <Datepicker
                        disabled={moment(dateTime.startDate).isBefore()}
                        placeholderText={strings.date}
                        selected={dateTime.startDate}
                        minDate={moment().toDate()}
                        onChange={(selected) => {
                            if (selected) {
                                let start = moment(selected);
                                let end = dateTime.endDate;

                                if (moment(selected).isSameOrAfter(end, "day")) {
                                    end = new Date(`${start.clone().add(1, "day").format("YYYY-MM-DD")}`);
                                }

                                onChange({
                                    startDate: new Date(`${start.format("YYYY-MM-DD")}`),
                                    endDate: end,
                                });
                            }
                        }}
                    />
                </div>

                <div className={styles.betweenLabel}>
                    <HeaderLabel label={strings.until} />
                </div>

                <div className={styles.dateField}>
                    <Datepicker
                        disabled={moment(dateTime.endDate).isBefore()}
                        placeholderText={strings.date}
                        selected={dateTime.endDate}
                        minDate={moment(dateTime.startDate).add(1, "day").toDate()}
                        onChange={(selected) => {
                            if (selected) {
                                onChange({
                                    startDate: dateTime.startDate,
                                    endDate: new Date(`${moment(selected).format("YYYY-MM-DD")}`),
                                });
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
