import { debounce } from "lodash";
import React, { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { useTags } from "../../../../hooks/TagHooks";
import { TagType, TagViewModel } from "../../../../openapi/backend";
import InputField from "../InputField/InputField";
import { FormOption } from "../SelectField/SelectField";
import styles from "./DataListInput.module.scss";

export interface DataListInputTagSearchProps
    extends Omit<
        React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        "onChange" | "multiple"
    > {
    name: string;
    value?: string;
    list: string;
    onChange: (selected?: TagViewModel) => void;
    tagType: TagType;
    parentTags?: string[];
}

const findSelectedOption = (options: FormOption<TagViewModel>[], value: string) =>
    options.find((x) => x.label === value);

export default function DataListInputSearch({
    value,
    list,
    onChange,
    tagType,
    parentTags,
    ...props
}: DataListInputTagSearchProps) {
    const [{ value: tags }, getTags] = useTags();
    const [options, setOptions] = useState<{ label: string; value: TagViewModel }[]>([]);
    const [innerValue, setInnerValue] = useState("");

    const debouncedSearch = useMemo(
        () =>
            debounce(async (input: string) => {
                getTags({ query: input, type: tagType, parentTags: parentTags });
            }, 1000),
        [getTags, parentTags, tagType],
    );

    useEffect(() => {
        debouncedSearch(innerValue);
    }, [innerValue, debouncedSearch]);

    useEffect(() => {
        setOptions([]);
        setInnerValue("");
    }, [parentTags]);

    useMemo(() => {
        if (tags) {
            const options = tags.map((tag) => ({ label: tag.detailedName, value: tag }));
            setOptions(options);
        }
    }, [tags]);

    const onValueChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            const selected = findSelectedOption(options, inputValue);

            if (selected) {
                setInnerValue("");
            } else {
                setInnerValue(inputValue);
            }
            onChange && onChange(selected?.value);
        },
        [options, onChange],
    );

    const Options = useMemo(
        () => options.map(({ label, value }) => <option key={value.id} value={label} />),
        [options],
    );

    return (
        <>
            <div className={styles.container}>
                <InputField
                    {...props}
                    list={list}
                    value={value || innerValue}
                    onChange={onValueChange}
                    className={styles.searchField}
                    onKeyPress={(e) => {
                        if (e.key !== "Enter") {
                            return;
                        }
                        e.preventDefault();
                    }}
                />
            </div>
            <datalist id={list}>{Options}</datalist>
        </>
    );
}
