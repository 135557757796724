import React, { PropsWithChildren, useEffect, useState } from "react";
import IdleGuard from "../authentication/IdleGuard";
import { RequestState } from "../hooks/UseApiCall";
import { useWhoAmI } from "../hooks/UserHooks";
import { UserOutput } from "../openapi/backend";

export type Profile = UserOutput;

export type ProfileContextType = {
    profile?: Profile;
    state: RequestState;
    error?: Response;
    refresh: () => void;
};

/**
 * Context that provides a user's profile data. undefined profile means it's still being loaded so act accordingly.
 * As long as ProfileContext is used within AuthProvider, profile should always be usable.
 */
export const ProfileContext = React.createContext<ProfileContextType>({ refresh() {}, state: RequestState.DONE });

export const ProfileContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [{ value: profile, state, error }, refresh] = useWhoAmI();
    const [value, setValue] = useState<ProfileContextType>({ profile, refresh, state, error });

    useEffect(() => refresh(), [refresh]);
    useEffect(() => {
        if (state === RequestState.DONE || state === RequestState.ERROR) {
            setValue({ profile, refresh, state, error });
        }
    }, [profile, refresh, state, error]);

    return (
        <ProfileContext.Provider value={value}>
            <IdleGuard />
            {children}
        </ProfileContext.Provider>
    );
};
