import { useCallback } from "react";
import { useAuth } from "react-oidc-context";

export const logoutKey = "logout-event";
export const useSignout = () => {
    const sessionClear = useSessionClear();
    const { signoutRedirect, user } = useAuth();
    return useCallback(() => {
        sessionClear();
        signoutRedirect({ id_token_hint: user?.id_token });
    }, [signoutRedirect, sessionClear, user]);
};

/**
 * Clears session data, which will force the application to reinitialize the session with the identity provider.
 * @returns
 */
export const useSessionClear = () => {
    return useCallback(() => {
        localStorage.clear(); // Login states are stored in localstorage but so far not yet cleaned up. This is a quickfix
        localStorage.setItem(logoutKey, "logout" + Math.random()); // Notify other tabs that there is no longer a session available
    }, []);
};
