import { useState } from "react";
import strings from "../../../localization/strings";
import ErrorMessage from "../../core/ErrorMessage/ErrorMessage";
import Modal from "../../core/Modal/Modal";
import SimpleTextButton from "../../core/SimpleTextButton/SimpleTextButton";
import TextButton from "../../core/TextButton/TextButton";
import styles from "./UnsubscribeParticipantReasonModal.module.scss";

interface UnsunscribeParticipantReasonModalProps {
    isOpen: boolean;
    isLoading: boolean;
    onUnsubscribe: (reason: string) => void;
    onDismiss: () => void;
    titleLabel?: string;
    reasonLabel?: string;
    confirmLabel?: string;
    error?: Response;
    defaultErrorMesssage?: string;
}

export default function UnsubscribeParticipantReasonModal({
    isOpen,
    isLoading,
    onUnsubscribe,
    onDismiss,
    titleLabel,
    confirmLabel,
    reasonLabel,
    error,
    defaultErrorMesssage = strings.somethingWentWrong,
}: UnsunscribeParticipantReasonModalProps) {
    const [reason, setReason] = useState("");

    return (
        <Modal isOpen={isOpen} title={titleLabel ?? strings.unsubscribeParticipant} onClose={onDismiss}>
            <div className={styles.modalContainer}>
                <div className={styles.text}>{reasonLabel ?? strings.giveReasonUnsubscribeParticipant}</div>
                <textarea rows={5} className={styles.reason} onChange={(e) => setReason(e.target.value)} />

                <div className={styles.buttonContainer}>
                    <TextButton
                        text={confirmLabel ?? strings.unsubscribeParticipant}
                        onClick={() => onUnsubscribe(reason)}
                        isLoading={isLoading}
                        disabled={reason.length <= 0}
                        className={styles.submitButton}
                    />
                    <SimpleTextButton onClick={onDismiss} text={strings.cancel} />
                </div>
                {error && <ErrorMessage error={error} defaultMessage={defaultErrorMesssage} isToast />}
            </div>
        </Modal>
    );
}
