import CategoryManagement from "../components/AppSettings/CategoryManagement";
import { Route } from "react-router-dom";
import Bouncer from "../authentication/Bouncer";
import {
    canCreateOrganisationInfo,
    canViewAppSettings,
    canViewAppSettingsDefaultTabs,
} from "../authentication/Permissions";
import HappeningGroupManagement from "../components/AppSettings/HappeningGroupManagement";
import InterestManagement from "../components/AppSettings/InterestManagement";
import OrganisationInfoContainer from "../components/AppSettings/OrganisationInfoContainer";
import { OrganisationInfoHeader } from "../components/AppSettings/OrganisationInfoHeader";
import InternalTagManagement from "../components/AppSettings/InternalTagManagement";
import PartnerGroupManagement from "../components/AppSettings/PartnerGroupManagement";
import CreateOrganisationInfoForm from "../components/Forms/OrganisationInfo/CreateOrganisationInfoForm";
import strings from "../localization/strings";
import { NavigationTab } from "../types/NavigationTab";
import useTabRoute from "./UseTabRoute";
import UpdateOrganisationInfoForm from "../components/Forms/OrganisationInfo/UpdateOrganisationInfoForm";
import RegistrationRulesContainer from "../components/AppSettings/RegistrationRules/RegistrationRulesContainer";
import { RegistrationRulesInfoHeader } from "../components/AppSettings/RegistrationRules/RegistrationRulesInfoHeader";
import MediaTagManagement from "../components/AppSettings/MediaTagManagement";

const appsettingTabs: NavigationTab[] = [
    {
        pathname: "happeninggroups",
        label: strings.happeningGroups,
        element: <HappeningGroupManagement />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "partners",
        label: strings.partnerGroups,
        element: <PartnerGroupManagement />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "interests",
        label: strings.interests,
        element: <InterestManagement />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "categories",
        label: strings.categories,
        element: <CategoryManagement />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "internal-tags",
        label: strings.internalTags,
        element: <InternalTagManagement />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "media-tags",
        label: strings.mediaTags,
        element: <MediaTagManagement />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "organisation-info",
        label: strings.organisationInfo,
        element: <OrganisationInfoContainer />,
        header: <OrganisationInfoHeader to="organisation-info/new" />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
    {
        pathname: "registration-rules",
        label: strings.registrationRules,
        element: <RegistrationRulesContainer />,
        header: <RegistrationRulesInfoHeader />,
        permissions: [canViewAppSettingsDefaultTabs],
    },
];

const useAppsettingsRoute = () => {
    return (
        <>
            {/* Separate route outside of tab structure */}
            <Route
                path="appsettings/organisation-info/new"
                element={<Bouncer permissions={[canCreateOrganisationInfo]} element={<CreateOrganisationInfoForm />} />}
            />
            <Route
                path="appsettings/organisation-info/:id"
                element={<Bouncer permissions={[canCreateOrganisationInfo]} element={<UpdateOrganisationInfoForm />} />}
            />
            {useTabRoute("appsettings", appsettingTabs, [canViewAppSettings])}
        </>
    );
};

export default useAppsettingsRoute;
