import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { REACT_APP_BACKEND_URL } from "../../utils.ts/Env";
import { toastFeedbackReceived } from "../../utils.ts/Toaster";

interface Feedback {
    message: string;
    id?: string;
}

const FeedbackClient = ({ children }: PropsWithChildren<{}>) => {
    const { user } = useAuth();
    const token = user?.id_token;

    const connection = useMemo(
        () =>
            token
                ? new HubConnectionBuilder()
                      .withUrl(`${REACT_APP_BACKEND_URL}/feedback`, {
                          accessTokenFactory: () => token,
                          withCredentials: false,
                      })
                      .withAutomaticReconnect()
                      .configureLogging(LogLevel.Warning)
                      .build()
                : undefined,
        [token],
    );

    useEffect(() => {
        if (!connection) {
            return () => {};
        }

        connection.on("ReceiveMessage", (feedback: Feedback) => {
            toastFeedbackReceived(feedback.message, feedback.id);
        });
        connection.start().catch((err) => console.error(err));

        return () => connection.stop();
    }, [connection]);

    return <>{children}</>;
};

export default FeedbackClient;
