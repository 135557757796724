import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useDownloadFile } from "../hooks/FileHooks";
import { FileViewModel } from "../openapi/backend";
import { EditApplicationContext } from "./EditApplicationContext";

export type AttachmentsContextType = {
    file?: FileViewModel;
    selectedFiles: File[];
    setExistingFile: (file?: FileViewModel) => void;
    setSelectedFiles: (file: File[]) => void;
    addFile: (File: File) => void;
    downloadFile: (fileId: number, fileName: string, exportFile: boolean) => void;
};

export const AttachmentsContext = React.createContext<AttachmentsContextType>({
    file: undefined,
    selectedFiles: [],
    setExistingFile() {},
    setSelectedFiles() {},
    addFile() {},
    downloadFile() {},
});

export const AttachmentsContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const [application] = useContext(EditApplicationContext);
    const [, downloadFile] = useDownloadFile();

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [existingFile, setExistingFile] = useState<FileViewModel | undefined>(application?.file);

    useEffect(() => {
        setExistingFile(application?.file);
        setSelectedFiles([]);
    }, [application?.file]);

    const addFile = (file: File) => {
        setSelectedFiles((values) => {
            return [...values, file];
        });
    };

    const [value, setValue] = useState<AttachmentsContextType>({
        file: existingFile,
        selectedFiles,
        setExistingFile,
        setSelectedFiles,
        addFile,
        downloadFile,
    });

    useEffect(() => {
        setValue((value) => {
            return {
                ...value,
                file: existingFile,
                selectedFiles,
            };
        });
    }, [existingFile, selectedFiles]);

    return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
};
