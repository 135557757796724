import { useCallback, useContext, useEffect, useMemo } from "react";
import { TagApiContext } from "../contexts/TagApiContext";
import { TagsContext } from "../contexts/TagsContext";
import strings from "../localization/strings";
import {
    CreateTagRequest,
    GetTagCollectionRequest,
    GetTagsHierarchyRequest,
    GetTagsRequest,
    OrganizerViewModel,
    TagViewModel,
} from "../openapi/backend";
import { TagCollection } from "../openapi/backend/models/TagCollection";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, RequestState, useApiCall, useApiCallback } from "./UseApiCall";

export type TagRequest = Omit<GetTagsRequest, "version">;
export const useTags = (): [ApiCallState<TagViewModel[]>, (request?: TagRequest) => void] => {
    const api = useContext(TagApiContext);
    const callback = useCallback(
        (request: TagRequest = {}) => api.getTags({ version: REACT_APP_BACKEND_VERSION, ...request }),
        [api],
    );
    const [{ state, error, value }, call] = useApiCallback(callback);

    const items = useMemo<TagViewModel[] | undefined>(() => value?.items, [value]);

    return [{ state, error, value: items } as any, call];
};

export type TagsHierarchyRequest = Omit<GetTagsHierarchyRequest, "version">;
export const useTagsHierarchy = (): [
    ApiCallState<TagViewModel[]>,
    (request?: TagsHierarchyRequest) => void,
    () => void,
] => {
    const api = useContext(TagApiContext);
    const callback = useCallback(
        (request: TagsHierarchyRequest = {}) =>
            api.getTagsHierarchy({ version: REACT_APP_BACKEND_VERSION, ...request }),
        [api],
    );

    return useApiCallback(callback);
};

export type TagCollectionRequest = Omit<GetTagCollectionRequest, "version">;
export const useTagCollection = (): [ApiCallState<TagCollection>, (request?: TagCollectionRequest) => void] => {
    const api = useContext(TagApiContext);
    const callback = useCallback(
        (request: TagCollectionRequest = {}) =>
            api.getTagCollection({ version: REACT_APP_BACKEND_VERSION, ...request }),
        [api],
    );
    const [{ state, error, value }, call] = useApiCallback(callback);

    const collection = useMemo<TagCollection | undefined>(() => value, [value]);

    return [{ state, error, value: collection } as any, call];
};

export const useOrganizers = (): ApiCallState<OrganizerViewModel[]> => {
    const api = useContext(TagApiContext);
    const callback = useCallback(() => api.getOrganizers({ version: REACT_APP_BACKEND_VERSION }), [api]);

    return useApiCall(callback);
};

export type TagInput = Omit<CreateTagRequest, "version" | "newLogo">;
export const useCreateTag = (): [ApiCallState<TagViewModel>, (input: TagInput) => void] => {
    const api = useContext(TagApiContext);
    const { refresh } = useContext(TagsContext);
    const callback = useCallback(
        (input: TagInput) => api.createTag({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );

    const [state, call] = useApiCallback(callback);
    useEffect(() => {
        if (state.state === RequestState.DONE) {
            refresh();
        }
    }, [refresh, state]);

    return [state, call];
};

export type UpdateTagInput = TagInput & {
    id: string;
    newLogo?: File;
};
export const useUpdateTag = (): [ApiCallState<TagViewModel>, (input: UpdateTagInput) => void] => {
    const api = useContext(TagApiContext);
    const { refresh } = useContext(TagsContext);
    const callback = useCallback(
        ({ newLogo, ...input }: UpdateTagInput) =>
            api.updateTag({
                version: REACT_APP_BACKEND_VERSION,
                newLogo: newLogo ? [{ altText: strings.logo, file: newLogo }] : [],
                ...input,
            }),
        [api],
    );

    const [state, call] = useApiCallback(callback);
    useEffect(() => {
        if (state.state === RequestState.DONE) {
            refresh();
        }
    }, [refresh, state]);

    return [state, call];
};

export const useVisibilityToggle = (): [ApiCallState<TagViewModel>, (tag: TagViewModel) => void] => {
    const api = useContext(TagApiContext);
    const { refresh } = useContext(TagsContext);
    const callback = useCallback(
        async (tag: TagViewModel): Promise<TagViewModel> => {
            tag.isActive
                ? await api.deactivateTag({ version: REACT_APP_BACKEND_VERSION, id: tag.id })
                : await api.activateTag({ version: REACT_APP_BACKEND_VERSION, id: tag.id });

            return { ...tag, isActive: !tag.isActive };
        },
        [api],
    );

    const [state, call] = useApiCallback(callback);
    useEffect(() => {
        if (state.state === RequestState.DONE) {
            refresh();
        }
    }, [refresh, state]);

    return [state, call];
};

export function useOrganiserOptions() {
    const tags = useContext(TagsContext).allTags;
    const { value: organisers, state } = useOrganizers();

    return useMemo(() => {
        switch (state) {
            case RequestState.ERROR:
                return tags.happeningGroups;
            case RequestState.DONE:
                return organisers!;
            default:
                return [];
        }
    }, [state, organisers, tags.happeningGroups]);
}
