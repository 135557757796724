import { useState } from "react";
import strings from "../../../localization/strings";
import { OrganizerViewModel, HappeningState, HappeningTimeAndDateViewModel } from "../../../openapi/backend";
import AgendaItem from "../AgendaItem/AgendaItem";
import styles from "./HappeningDate.module.scss";

type Props = {
    dateTimes: HappeningTimeAndDateViewModel[];
    happeningState: HappeningState;
    happeningGroup?: OrganizerViewModel;
    title: string;
    onCancelDate: (id: number) => void;
    previewAmount?: number;
    maxAmount?: number;
    preventCancel?: boolean;
};

export default function HappeningDate({
    dateTimes,
    happeningGroup,
    title,
    onCancelDate,
    previewAmount = 3,
    maxAmount,
    happeningState,
    preventCancel,
}: Props) {
    const [open, setOpen] = useState(false);
    const datesToRender = dateTimes.slice(0, maxAmount);
    const showButton = datesToRender.length > previewAmount;

    return (
        <div className={styles.container}>
            {dateTimes.map((dateTime: HappeningTimeAndDateViewModel, index: number) => {
                return (
                    <AgendaItem
                        key={`${title}${index}`}
                        date={dateTime}
                        happeningState={happeningState}
                        group={happeningGroup}
                        className={showButton && !open && index >= previewAmount ? styles.hideDate : undefined}
                        onCancelDate={() => onCancelDate(dateTime.id)}
                        preventCancel={preventCancel}
                    />
                );
            })}
            {showButton && (
                <div onClick={() => setOpen(!open)} className={styles.button}>
                    {open ? strings.closeDates : strings.openDates}
                </div>
            )}
        </div>
    );
}
