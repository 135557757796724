import styles from "./ColorInput.module.scss";
import ColorPickIcon from "../../../../assets/color_picker_icon.svg";
import { DEFAULT_COLOR } from "../../../../constants/Defaults";

interface ColorInputProps
    extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "type"> {
    name: string;
    label: string;
}

const ColorInput = ({ name, defaultValue = DEFAULT_COLOR, label, ...props }: ColorInputProps) => (
    <div className={styles.container}>
        <input
            className={styles.input}
            name={name}
            id={name}
            type="color"
            defaultValue={defaultValue}
            aria-label={label}
            {...props}
        />
        <label className={styles.label} htmlFor={name}>
            <img src={ColorPickIcon} className={styles.icon} alt="" />
        </label>
    </div>
);

export default ColorInput;
