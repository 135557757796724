import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";
import QuestionField from "../../Inputs/QuestionField/QuestionField";
import MultiField, { FieldProps } from "../../Inputs/MultiField/MultiField";
import { useCallback } from "react";
import strings from "../../../../localization/strings";
import { QuestionFieldValue } from "../../../../types/FormInputTypes";
import { QuestionType } from "../../../../openapi/backend";

export interface FormMultiQuestionFieldProps<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, QuestionFieldValue[]>,
> {
    label?: string;
    name: TName;
    control: Control<TFieldValues, object>;
    errors?: FieldError[];
}

const defaultValue = {
    isRequired: false,
    isMultipleChoice: false,
    type: QuestionType.Open,
} as Partial<QuestionFieldValue>;

export default function FormMultiQuestionField<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, QuestionFieldValue[]>,
>({ label, name, control, errors }: FormMultiQuestionFieldProps<TFieldValues, TName>) {
    const Field = useCallback(
        ({ index, fieldValue, onChange: fieldOnChange }: FieldProps<Partial<QuestionFieldValue>>) => {
            const onChangeManipulator = (value: Partial<QuestionFieldValue>) =>
                fieldOnChange({
                    ...value,
                    type: value.isMultipleChoice ? QuestionType.MultipleChoice : QuestionType.Open,
                    options: value.isMultipleChoice ? value.options : undefined,
                });

            return (
                <QuestionField
                    name={`${name}.${index}`}
                    value={fieldValue ?? {}}
                    onChange={onChangeManipulator}
                    rearrangeable
                />
            );
        },
        [name],
    );

    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                    <MultiField
                        Field={Field}
                        onChange={(questions) => onChange(questions.filter((x) => !!x.title))}
                        value={value}
                        label={strings.questionNumber}
                        defaultValue={defaultValue}
                        rearrangeable
                        manipulationType="append"
                    />
                )}
            />
        </FormFieldContainer>
    );
}
