import strings from "../../localization/strings";
import Modal from "../core/Modal/Modal";
import EditAccountContainer from "../Forms/AccountForm/EditAccountContainer";

export interface AccountModalProps {
    selectedUserId?: string | undefined;
    onClose: () => void;
}

export const EditAccountModal = ({ selectedUserId, onClose }: AccountModalProps) => (
    <Modal isOpen={selectedUserId !== undefined} title={strings.editAccount} onClose={onClose}>
        <EditAccountContainer id={selectedUserId || ""} onDismiss={onClose} />
    </Modal>
);
