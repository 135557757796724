import moment from "moment";
import React from "react";
import { useHappeningsAutocompleteSuggestions } from "../../../../hooks/HappeningsHooks";
import { HappeningListItemViewModel } from "../../../../openapi/backend";
import { getRelevantSession } from "../../../../utils.ts/Happening";
import RemoteSuggestionInput, { Props as InputProps } from "./RemoteSuggestionInput";

type Props<T> = Pick<InputProps<T, any>, "className" | "onChangeValue" | "value" | "name">;

function getDisplayLabel(v: HappeningListItemViewModel) {
    let label = `${v.title}`;

    const relevantDateTime = getRelevantSession(v.dateTimes);
    if (relevantDateTime) {
        label += ` (${moment(relevantDateTime.sessionStart).format("DD/MM/YYYY HH:mm")})`;
    }

    return label;
}

const mapSuggestions = (value: HappeningListItemViewModel[]) => {
    return value.map((v) => ({
        displayLabel: getDisplayLabel(v),
        inputLabel: v.title,
        value: v.id,
    }));
};

export default function RemoteHappeningSuggestionInput({ ...props }: Props<string>) {
    const searchHook = useHappeningsAutocompleteSuggestions();

    return <RemoteSuggestionInput {...props} callbackHook={searchHook} mapSuggestions={mapSuggestions} />;
}
