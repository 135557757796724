import { ReactNode, useContext, useMemo, useState } from "react";
import { Column } from "react-table";
import strings from "../../../localization/strings";
import { HappeningRegistrationOutput, HappeningType, HappeningViewModel } from "../../../openapi/backend";
import HappeningRegistration from "../../core/HappeningRegistration/HappeningRegistration";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { canManageHappeningParticipants } from "../../../authentication/Permissions";
import StaticTable from "../../core/StaticTable/StaticTable";
import SearchInput from "../../core/Inputs/SearchInput/SearchInput";
import { NumberSelectField } from "../../core/Inputs/SelectField/SelectField";
import styles from "./RegistrationsTable.module.scss";
import moment from "moment";
import { getRelevantSession } from "../../../utils.ts/Happening";
import { calculateMaxAttendance } from "../../../utils.ts/AttendencyUtils";

export type RegistrationsTableProps = {
    happening: HappeningViewModel;
    happeningRegistrations: HappeningRegistrationOutput[];
    onSelectParticipant: (id: string) => void;
    searchable?: boolean;
    groupBySessions?: boolean;
};

type RegistrationsTableItem = {
    Registration: ReactNode;
};

export default function RegistrationsTable({
    happeningRegistrations,
    happening,
    onSelectParticipant,
    searchable,
    groupBySessions,
}: RegistrationsTableProps) {
    const { profile } = useContext(ProfileContext);
    const canManageParticipants = canManageHappeningParticipants(happening, profile);
    const [query, setQuery] = useState("");

    const columns: Column<RegistrationsTableItem>[] = [
        {
            Header: strings.name,
            accessor: "Registration",
            width: "100%",
        },
    ];

    const [currentDateId, setCurrentDateId] = useState<number | undefined>(
        groupBySessions ? getRelevantSession(happening.dateTimes)?.id : undefined,
    );

    const data = useMemo<RegistrationsTableItem[]>(() => {
        let happeningRegistrationsFiltered = happeningRegistrations.filter(
            (r) =>
                r.participantName.toLowerCase().includes(query.trim().toLowerCase()) ||
                r.contactPersonName?.toLowerCase().includes(query.trim().toLowerCase()),
        );
        if (groupBySessions) {
            happeningRegistrationsFiltered = happeningRegistrationsFiltered.filter((r) => {
                const selectedSessionEnd = happening.dateTimes.find(
                    (dateTime) => dateTime.id === currentDateId,
                )?.sessionEnd;

                const sessionIsAfterRegistration = moment(r.createdOn)
                    .startOf("day")
                    .isBefore(moment(selectedSessionEnd));
                const sessionIsBeforeCancellation = r.cancelledOn
                    ? moment(selectedSessionEnd).isBefore(moment(r.cancelledOn))
                    : true;

                return sessionIsAfterRegistration && sessionIsBeforeCancellation;
            });
        }

        return happeningRegistrationsFiltered.map((registration, index) => {
            const name =
                !!registration.contactPersonName && registration.contactPersonName !== registration.participantName
                    ? `${registration.participantName} (${registration.contactPersonName})`
                    : registration.participantName;

            const attendencies =
                happening.type === HappeningType.PrivateHappening
                    ? {
                          attended: registration.attendencies.filter((x) => x.present).length,
                          sessions: registration.appointments.filter((x) => !x.cancelled).length,
                      }
                    : {
                          attended: registration.attendencies.filter((x) => x.present).length,
                          sessions: calculateMaxAttendance(registration.createdOn, happening.dateTimes),
                      };

            const participantRegistration = (
                <HappeningRegistration
                    registration={registration}
                    price={happening.price}
                    key={registration.participantId}
                    name={name}
                    happeningType={happening.type}
                    locationType={happening.locationType}
                    status={registration.status}
                    age={registration.age}
                    requiresPayment={registration.requiresPayment}
                    requiresApprovalFromMarketing={happening.requiresApprovalFromMarketing}
                    medicalNotes={registration.medicalNotes}
                    payment={registration.payment}
                    notes={registration.notes}
                    approvedForMarketing={registration.approvedForMarketing}
                    onClick={() => onSelectParticipant(registration.participantId)}
                    participantId={registration.participantId}
                    happeningId={happening.id}
                    isAllowedToManage={canManageParticipants}
                    attendance={attendencies}
                    sessionId={currentDateId}
                    appointments={registration.appointments}
                    registrationId={registration.id}
                    location={happening.location}
                />
            );

            return {
                Registration: <span>{participantRegistration}</span>,
            };
        });
    }, [
        happeningRegistrations,
        happening,
        canManageParticipants,
        onSelectParticipant,
        query,
        currentDateId,
        groupBySessions,
    ]);

    return (
        <div>
            <div className={styles.topBar}>
                {happening.dateTimes.length > 1 && groupBySessions && (
                    <NumberSelectField
                        name={"happening-date-picker"}
                        value={currentDateId}
                        className={styles.datePicker}
                        onChange={(value) => setCurrentDateId(value)}
                        options={happening.dateTimes.map((time) => {
                            return {
                                label: time.sessionStart.toLocaleDateString(strings.getLanguage(), {
                                    dateStyle: "full",
                                }),
                                value: time.id,
                            };
                        })}
                    />
                )}
                {searchable && (
                    <SearchInput
                        value={query}
                        className={styles.searchInput}
                        onSearch={setQuery}
                        placeholder={strings.searchRegistration}
                    />
                )}
            </div>
            <StaticTable columns={columns} data={data} emptyString={strings.noParticipantsFound} />
        </div>
    );
}
