import { useCallback, useEffect, useMemo, useState } from "react";
import {
    useCreateTag,
    useVisibilityToggle,
    useUpdateTag,
    UpdateTagInput,
    useTagsHierarchy,
} from "../../hooks/TagHooks";
import { RequestState } from "../../hooks/UseApiCall";
import { TagType, TagViewModel } from "../../openapi/backend";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";
import TagManagement from "./TagManagement";
import produce from "immer";
import { isAllowedImageFileType } from "../../utils.ts/Media";
import { toastError } from "../../utils.ts/Toaster";
import strings from "../../localization/strings";
import { hasValues } from "../../utils.ts/Array";

interface Props {
    tagType: TagType;
    title: string;
    addLabel: string;
    nested?: boolean;
    nestedAddLabel?: string;
    hasDescription?: boolean;
}

const TAG_TYPES_WITH_NESTED_INPUT = [TagType.HappeningGroup];

function isNestedTag(tag: TagViewModel) {
    return TAG_TYPES_WITH_NESTED_INPUT.includes(tag.type) && hasValues(tag.parentTags);
}

function getUpdatedTagState(tags: TagViewModel[], updated: TagViewModel) {
    return produce(tags, (draft) => {
        if (isNestedTag(updated)) {
            // child got updated
            const parentId = updated.parentTags!.at(0);
            const parent = draft.find((parent) => parent.id === parentId);
            if ((parent?.childTags?.length ?? 0) > 0) {
                const childIndex = parent!.childTags!.findIndex((c) => c.id === updated.id);
                if (childIndex >= 0) {
                    parent!.childTags![childIndex] = updated;
                }
            }
        } else {
            const index = draft.findIndex((t) => t.id === updated.id);
            if (index >= 0) {
                draft[index] = updated;
            }
        }
    });
}

const TagManagementContainer = ({ tagType, title, addLabel, nested, nestedAddLabel, hasDescription }: Props) => {
    const [{ value, error: getError }, refresh] = useTagsHierarchy();
    const [{ value: newTag, error: createError }, createTag] = useCreateTag();
    const [{ value: toggledTag, state: toggleState, error: toggleError }, toggleVisiblity] = useVisibilityToggle();
    const [{ value: updatedTag, state: updateState, error: updateError }, updateTag] = useUpdateTag();
    const [updatingTagId, setUpdatingTagId] = useState<string>();
    const changeColor = useCallback(
        (input: UpdateTagInput, color: string) => updateTag({ ...input, color }),
        [updateTag],
    );
    const changeLogo = useCallback(
        (input: UpdateTagInput, newLogo: File) => {
            if (!isAllowedImageFileType(newLogo)) {
                toastError(strings.invalidImageTypeError);
            } else {
                updateTag({ ...input, newLogo });
            }
        },
        [updateTag],
    );
    const setApiKey = useCallback(
        (input: UpdateTagInput, paymentApiKey: string) => {
            updateTag({ ...input, paymentApiKey });
            setUpdatingTagId(input.id);
        },
        [updateTag],
    );
    const changeParents = useCallback(
        (input: UpdateTagInput, parents: string[] | undefined) => updateTag({ ...input, parentTags: parents }),
        [updateTag],
    );
    const [tags, setTags] = useState(value || []);

    const extendedCreateTag = useCallback(
        (name: string, parentTag?: string) =>
            createTag({ name: name.trim(), type: tagType, parentTags: parentTag ? [parentTag] : undefined }),
        [createTag, tagType],
    );

    useEffect(() => refresh({ type: tagType, filterInactive: false }), [refresh, tagType]);

    useEffect(() => {
        if (value) {
            setTags(value);
        }
    }, [value]);

    useEffect(() => {
        if (newTag) {
            setTags((oldTags) => {
                if (isNestedTag(newTag)) {
                    // child got updated
                    const parentId = newTag.parentTags!.at(0);
                    return produce(oldTags, (draft) => {
                        const parent = draft.find((parent) => parent.id === parentId);
                        if (parent) {
                            if (parent.childTags) {
                                parent.childTags.unshift(newTag);
                            } else {
                                parent.childTags = [newTag];
                            }
                        }
                    });
                }

                return produce(oldTags, (draft) => {
                    draft.unshift(newTag);
                });
            });
        }
    }, [newTag]);

    useEffect(() => {
        if (toggleState !== RequestState.DONE || !toggledTag) {
            return;
        }

        setTags((oldTags) => getUpdatedTagState(oldTags, toggledTag));
    }, [toggleState, toggledTag]);
    useEffect(() => {
        if (updateState !== RequestState.DONE || !updatedTag) {
            return;
        }

        setTags((oldTags) => getUpdatedTagState(oldTags, updatedTag));
    }, [updateState, updatedTag]);

    const tagsValue = useMemo(
        () =>
            tags.map((tag) => {
                let childTags = undefined;
                if (tag.childTags) {
                    childTags = tag.childTags.map((ct) =>
                        ct.id === updatingTagId
                            ? { ...ct, requestState: updateState }
                            : { ...ct, requestState: RequestState.IDLE },
                    );
                }

                return {
                    ...tag,
                    childTags,
                    requestState: tag.id === updatingTagId ? updateState : RequestState.IDLE,
                };
            }),

        [tags, updateState, updatingTagId],
    );
    if (getError || createError || toggleError || updateError) {
        return <ErrorMessage error={getError || createError || toggleError! || updateError} />;
    }

    return (
        <TagManagement
            title={title}
            tags={tagsValue}
            addLabel={addLabel}
            nested={nested}
            createTag={extendedCreateTag}
            toggleVisibility={toggleVisiblity}
            changeColor={changeColor}
            changeLogo={changeLogo}
            setApiKey={setApiKey}
            changeParents={changeParents}
            nestedAddLabel={nestedAddLabel}
            hasDescription={hasDescription}
        />
    );
};

export default TagManagementContainer;
