import { useMyHappeningsOverview } from "../../hooks/HappeningsHooks";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["happeningTypes"];

export default function MyHappeningsContainer() {
    const [happeningsState, getMyHappenings] = useMyHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getMyHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
        />
    );
}
