import strings from "../localization/strings";
import { LinkTarget } from "../openapi/backend";

export function getStringForLinkTarget(target: LinkTarget | undefined): string {
    switch (target) {
        case LinkTarget.Profile:
            return strings.linkTargetEnumProfile;
        case LinkTarget.Partner:
            return strings.linkTargetEnumPartner;
        case LinkTarget.None:
            return strings.linkTargetEnumNone;
        default:
            return strings.unknown;
    }
}
