import { useCallback, useContext, useMemo, useState } from "react";
import { ArrangementsApiContext } from "../contexts/ArrangementsApiContext";
import {
    ApplyRequest,
    ArrangementApplicationListOutputPaginatedViewModel,
    ArrangementApplicationOperation,
    ArrangementType,
    GetApplicationsRequest,
    GetReviewApplicationsRequest,
    TagViewModel,
} from "../openapi/backend";
import { ApplicationStatusOption } from "../types/DropdownOption";
import { UserNamedType } from "../types/UserNamedType";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, RequestState, useApiCall, useApiCallback } from "./UseApiCall";
import { FilesApiContext } from "../contexts/FilesApiContext";
import { toastError } from "../utils.ts/Toaster";
import { Apply200Response } from "../openapi/backend/models/Apply200Response";

type SharedApplicationRequestArgs = GetReviewApplicationsRequest | GetApplicationsRequest;

export type ApplicationFilter = Pick<SharedApplicationRequestArgs, "query" | "fromDate" | "toDate" | "page"> & {
    statuses?: ApplicationStatusOption[];
    municipalities?: TagViewModel[];
    reviewers?: UserNamedType[];
};

export type GetApplicationInput = Pick<SharedApplicationRequestArgs, "orderBy" | "sortOrder" | "itemsPerPage">;
export type GetApplicationsFun = (
    input: GetApplicationInput,
    filter: ApplicationFilter,
) => Promise<ArrangementApplicationListOutputPaginatedViewModel>;

export const useGetReviewApplicationsOverview = (): [
    ApiCallState<ArrangementApplicationListOutputPaginatedViewModel>,
    GetApplicationsFun,
    () => void,
] => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback<GetApplicationsFun>(
        (input, filter) => {
            return api.getReviewApplications({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
                ...transformFilter(filter),
            });
        },
        [api],
    );

    return useApiCallback(callback);
};

export const useGetApplicationsOverview = (): [
    ApiCallState<ArrangementApplicationListOutputPaginatedViewModel>,
    GetApplicationsFun,
    () => void,
] => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback<GetApplicationsFun>(
        (input, filter) => {
            return api.getApplications({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
                ...transformFilter(filter),
            });
        },
        [api],
    );

    return useApiCallback(callback);
};

function transformFilter(filter: ApplicationFilter) {
    return {
        ...filter,
        municipalities: filter?.municipalities?.map((m) => m.id),
        statuses: filter?.statuses?.map((o) => o.value),
        reviewers: filter?.reviewers?.map((r) => r.id),
    };
}

export const useApplicationDetails = (id: string) => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback(() => api.getApplication({ version: REACT_APP_BACKEND_VERSION, id }), [api, id]);
    return useApiCall(callback);
};

export const useCreateApplication = () => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback(
        (arrangementId: string, input: ApplyRequest) =>
            api.apply({ version: REACT_APP_BACKEND_VERSION, applyRequest: input, id: arrangementId }),
        [api],
    );
    return useApiCallback(callback);
};

export const useCreateApplicationWithFileUpload = () => {
    const fileApi = useContext(FilesApiContext);
    const arrangementsApi = useContext(ArrangementsApiContext);

    const [requestState, setRequestState] = useState<ApiCallState<Apply200Response>>({
        state: RequestState.IDLE,
    });

    const callback = useCallback(
        async (arrangementId: string, input: ApplyRequest, file?: File) => {
            setRequestState({ state: RequestState.LOADING });

            try {
                let modifiedInput: ApplyRequest = input;
                if (file !== undefined) {
                    const fileUploadPromise: number = await fileApi.uploadFile({
                        version: REACT_APP_BACKEND_VERSION,
                        file,
                    });

                    modifiedInput = { ...input, fileId: fileUploadPromise };
                }

                const response = await arrangementsApi.apply({
                    version: REACT_APP_BACKEND_VERSION,
                    applyRequest: modifiedInput,
                    id: arrangementId,
                });

                setRequestState({ state: RequestState.DONE, value: response });
            } catch (e) {
                if (!(e instanceof Response)) {
                    throw e;
                }
                setRequestState({ state: RequestState.ERROR, error: e });
                toastError(e.statusText);
            }
        },
        [arrangementsApi, fileApi],
    );

    return useMemo(() => ({ requestState, callback }), [requestState, callback]);
};

export const usePatchApplication = (applicationId: string) => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback(
        (input: Array<ArrangementApplicationOperation>) =>
            api.patchApplication({
                version: REACT_APP_BACKEND_VERSION,
                id: applicationId,
                arrangementApplicationOperation: input,
            }),
        [api, applicationId],
    );
    return useApiCallback(callback);
};

//A combined hook for uploading files and patching the application
export const usePatchApplicationWithFileUpload = (applicationId: string) => {
    const fileApi = useContext(FilesApiContext);
    const arrangementsApi = useContext(ArrangementsApiContext);

    const [requestState, setRequestState] = useState<ApiCallState<Apply200Response>>({
        state: RequestState.IDLE,
    });

    const callback = useCallback(
        async (input: Array<ArrangementApplicationOperation>, file?: File) => {
            setRequestState({ state: RequestState.LOADING });

            try {
                let patchResponse: Apply200Response;
                if (file !== undefined) {
                    const fileUploadPromise: number = await fileApi.uploadFile({
                        version: REACT_APP_BACKEND_VERSION,
                        file,
                    });

                    patchResponse = await arrangementsApi.patchApplication({
                        version: REACT_APP_BACKEND_VERSION,
                        id: applicationId,
                        arrangementApplicationOperation: [
                            ...input,
                            { op: "replace", path: "/fileId", value: fileUploadPromise },
                        ],
                    });
                } else {
                    patchResponse = await arrangementsApi.patchApplication({
                        version: REACT_APP_BACKEND_VERSION,
                        id: applicationId,
                        arrangementApplicationOperation: input,
                    });
                }

                setRequestState({ state: RequestState.DONE, value: patchResponse });
            } catch (e) {
                if (!(e instanceof Response)) {
                    throw e;
                }
                setRequestState({ state: RequestState.ERROR, error: e });
                toastError(e.statusText);
            }
        },
        [arrangementsApi, applicationId, fileApi],
    );

    return useMemo(() => ({ requestState, callback }), [requestState, callback]);
};

export const useGetFlatAuthorizedArrangements = () => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback(
        () => api.getArrangements({ version: REACT_APP_BACKEND_VERSION, flat: true, authorizedOnly: true }),
        [api],
    );

    return useApiCall(callback);
};

export const useGetArrangements = () => {
    const api = useContext(ArrangementsApiContext);
    const callback = useCallback(
        () => api.getArrangements({ version: REACT_APP_BACKEND_VERSION, type: ArrangementType.Internal }),
        [api],
    );

    return useApiCall(callback);
};
