import { useCallback, useContext } from "react";
import { ClustersApiContext } from "../contexts/ClustersApiContext";
import { ClusterViewModel, UpdateNameOfClusterRequest, UpdateOrganizerOfClusterRequest } from "../openapi/backend";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { ApiCallState, useApiCallback } from "./UseApiCall";

export const useCreateCluster = (happeningId: string = "") => {
    const api = useContext(ClustersApiContext);
    const callback = useCallback(
        () => api.createCluster({ version: REACT_APP_BACKEND_VERSION, body: happeningId }),
        [api, happeningId],
    );
    return useApiCallback(callback);
};

export type UpdateClusterNameInput = Omit<UpdateNameOfClusterRequest, "version">;
export const useClusterNameUpdate = (): [
    ApiCallState<ClusterViewModel>,
    (input: UpdateClusterNameInput) => void,
    () => void,
] => {
    const api = useContext(ClustersApiContext);
    const callback = useCallback(
        (input: UpdateClusterNameInput) => api.updateNameOfCluster({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );
    return useApiCallback(callback);
};

export const useClusterCoverUpdate = (clusterId: string) => {
    const api = useContext(ClustersApiContext);
    const callback = useCallback(
        (imageId: number) => api.updateCoverOfCluster({ version: REACT_APP_BACKEND_VERSION, id: clusterId, imageId }),
        [api, clusterId],
    );
    return useApiCallback(callback);
};

export type UpdateClusterOrganizerInput = Omit<UpdateOrganizerOfClusterRequest, "version">;
export const useClusterOrganizerUpdate = (): [
    ApiCallState<ClusterViewModel>,
    (input: UpdateClusterOrganizerInput) => void,
    () => void,
] => {
    const api = useContext(ClustersApiContext);
    const callback = useCallback(
        (input: UpdateClusterOrganizerInput) =>
            api.updateOrganizerOfCluster({ version: REACT_APP_BACKEND_VERSION, ...input }),
        [api],
    );
    return useApiCallback(callback);
};
