import { Profile } from "../contexts/ProfileContext";
import { HappeningListItemViewModel } from "../openapi/backend";
import { BareHappening } from "../utils.ts/Happening";

export function userIsLinked(happening: BareHappening, user?: Profile) {
    if (!user) {
        return false;
    }

    return (
        (happening.projectLeader && happening.projectLeader.id === user.id) ||
        (happening.contact && happening.contact.id === user.id) ||
        happening.projectMembers.findIndex((member) => member.id === user.id) !== -1 ||
        (happening.projectCoLeaders && happening.projectCoLeaders.findIndex((member) => member.id === user.id) !== -1)
    );
}
export function userIsLinkedList(happening: HappeningListItemViewModel, user?: Profile) {
    if (!user) {
        return false;
    }

    return (
        (happening.shortProjectLeader && happening.shortProjectLeader.id === user.id) ||
        (happening.shortContact && happening.shortContact.id === user.id) ||
        (happening.shortProjectMembers &&
            happening.shortProjectMembers.findIndex((member) => member.id === user.id) !== -1) ||
        (happening.shortProjectCoLeaders &&
            happening.shortProjectCoLeaders.findIndex((member) => member.id === user.id) !== -1) ||
        false
    );
}
