import React, { PropsWithChildren } from "react";
import { HappeningsApiProvider } from "./HappeningsApiContext";
import { NotificationsApiProvider } from "./NotificationsApiContext";
import { OrganisationInfoApiProvider } from "./OrganisationApiContext";
import { ProfilesApiProvider } from "./ProfilesApiContext";
import { RegistrationApiProvider } from "./RegistrationApiContext";
import { RegistrationRuleApiProvider } from "./RegistrationRuleApiContext";
import { TagApiProvider } from "./TagApiContext";
import { UserApiProvider } from "./UserApiContext";
import { ArrangementsApiProvider } from "./ArrangementsApiContext";
import FeedbackClient from "../components/FeedbackClient/FeedbackClient";
import { ClustersApiProvider } from "./ClustersApiContext";
import { MediaApiProvider } from "./MediaApiContext";
import { FilesApiProvider } from "./FilesApiContext";
import { IndicatorsApiProvider } from "./IndicatorsApiContext";
import { MeasurementsApiProvider } from "./MeasurementsApiContext";

const ApiProviderCollection = ({ children }: PropsWithChildren<{}>) => (
    <TagApiProvider>
        <HappeningsApiProvider>
            <ClustersApiProvider>
                <FilesApiProvider>
                    <MediaApiProvider>
                        <RegistrationApiProvider>
                            <RegistrationRuleApiProvider>
                                <ProfilesApiProvider>
                                    <OrganisationInfoApiProvider>
                                        <ArrangementsApiProvider>
                                            <NotificationsApiProvider>
                                                <IndicatorsApiProvider>
                                                    <FeedbackClient>
                                                        <UserApiProvider>
                                                            <MeasurementsApiProvider>
                                                                {children}
                                                            </MeasurementsApiProvider>
                                                        </UserApiProvider>
                                                    </FeedbackClient>
                                                </IndicatorsApiProvider>
                                            </NotificationsApiProvider>
                                        </ArrangementsApiProvider>
                                    </OrganisationInfoApiProvider>
                                </ProfilesApiProvider>
                            </RegistrationRuleApiProvider>
                        </RegistrationApiProvider>
                    </MediaApiProvider>
                </FilesApiProvider>
            </ClustersApiProvider>
        </HappeningsApiProvider>
    </TagApiProvider>
);

export default ApiProviderCollection;
