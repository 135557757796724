import { useCancelledHappeningsOverview } from "../../hooks/HappeningsHooks";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["states", "happeningTypes"];

export default function CancelledHappeningsContainer() {
    const [happeningsState, getCancelledHappenings] = useCancelledHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getCancelledHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
        />
    );
}
