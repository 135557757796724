import React, { ButtonHTMLAttributes } from "react";
import styles from "./LoadingSession.module.scss";
import strings from "../../../localization/strings";
import logo from "../../../assets/logo_full_color.svg";
import { useSignout } from "../../../authentication/useSignout";

function isVisible(e: HTMLElement) {
    return window.getComputedStyle(e).opacity !== "0";
}

const LoadingSession = () => {
    const signout = useSignout();

    return (
        <main className={styles.main}>
            <LoadingRing />
            <div className={styles.text}>{strings.loadingSession}</div>
            <div className={styles.msg}>
                {strings.sessionRetry}{" "}
                <InlineButton
                    text={strings.retry}
                    onClick={(e) => isVisible(e.currentTarget.parentElement!) && signout()}
                />
                .
            </div>
            <img src={logo} alt={strings.logo} className={styles.logo} />
        </main>
    );
};

// Loading animation from https://loading.io/css
const LoadingRing = () => (
    <div className={styles.ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

interface InlineButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
}

const InlineButton = ({ text, ...props }: InlineButtonProps) => (
    <button className={styles.inlineButton} {...props}>
        {text}
    </button>
);

export default LoadingSession;
