import styles from "./ProfileContent.module.scss";
import { Profile } from "../../../contexts/ProfileContext";
import { getName } from "../../../utils.ts/GetName";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import { Size } from "../../../types/Size";

type DisplayProfile = Pick<Profile, "givenName" | "familyName" | "avatar">;

interface ProfileContentProps {
    profile: DisplayProfile;
    size?: Size;
}

const ProfileContent = ({ profile, size = Size.MEDIUM }: ProfileContentProps) => {
    return (
        <div className={styles.container}>
            <ProfileIcon profile={profile} className={styles.avatar} size={size} />
            <span className={styles.userName}>{getName(profile)}</span>
        </div>
    );
};

export default ProfileContent;
