import { DEFAULT_COLOR } from "../../../constants/Defaults";
import { Size } from "../../../types/Size";
import styles from "./AvatarIcon.module.scss";

interface AvatarIconProps {
    name: string;
    color?: string;
    image?: string;
    className?: string;
    size?: Size;
}

const getSizeStyle = (size: Size) => {
    switch (size) {
        case Size.SMALL:
            return styles.small;
        case Size.MEDIUM:
            return styles.medium;
        case Size.LARGE:
            return styles.large;
        case Size.XLARGE:
            return styles.xlarge;
    }
};

const getInitials = (name: string) => {
    var names = name.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export default function AvatarIcon({ name, image, color, size = Size.SMALL, className = "" }: AvatarIconProps) {
    const sizeStyle = getSizeStyle(size);

    if (image) {
        return (
            <img
                src={image}
                className={`${styles.label} ${sizeStyle} ${className}`}
                alt={name}
                style={{ backgroundColor: color ?? DEFAULT_COLOR }}
            />
        );
    }

    return (
        <div
            className={`${styles.label} ${sizeStyle} ${className}`}
            style={{ backgroundColor: color ?? DEFAULT_COLOR }}
        >
            {getInitials(name)}
        </div>
    );
}
