/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    AnswerOutput,
    AnswerOutputFromJSON,
    AnswerOutputFromJSONTyped,
    AnswerOutputToJSON,
    HappeningListItemViewModel,
    HappeningListItemViewModelFromJSON,
    HappeningListItemViewModelFromJSONTyped,
    HappeningListItemViewModelToJSON,
    IndicatorDescriptiveOutput,
    IndicatorDescriptiveOutputFromJSON,
    IndicatorDescriptiveOutputFromJSONTyped,
    IndicatorDescriptiveOutputToJSON,
    MeasurementStatus,
    MeasurementStatusFromJSON,
    MeasurementStatusFromJSONTyped,
    MeasurementStatusToJSON,
    OrganizerViewModel,
    OrganizerViewModelFromJSON,
    OrganizerViewModelFromJSONTyped,
    OrganizerViewModelToJSON,
    ProfileShortOutput,
    ProfileShortOutputFromJSON,
    ProfileShortOutputFromJSONTyped,
    ProfileShortOutputToJSON,
    TagViewModel,
    TagViewModelFromJSON,
    TagViewModelFromJSONTyped,
    TagViewModelToJSON,
} from "./";

/**
 *
 * @export
 * @interface MeasurementOutput
 */
export interface MeasurementOutput {
    /**
     *
     * @type {number}
     * @memberof MeasurementOutput
     */
    id: number;
    /**
     *
     * @type {MeasurementStatus}
     * @memberof MeasurementOutput
     */
    status: MeasurementStatus;
    /**
     *
     * @type {Date}
     * @memberof MeasurementOutput
     */
    createdAt: Date;
    /**
     *
     * @type {Array<AnswerOutput>}
     * @memberof MeasurementOutput
     */
    answers: Array<AnswerOutput>;
    /**
     *
     * @type {IndicatorDescriptiveOutput}
     * @memberof MeasurementOutput
     */
    indicator: IndicatorDescriptiveOutput;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof MeasurementOutput
     */
    measurer: ProfileShortOutput;
    /**
     *
     * @type {string}
     * @memberof MeasurementOutput
     */
    notes?: string;
    /**
     *
     * @type {ProfileShortOutput}
     * @memberof MeasurementOutput
     */
    profileTarget?: ProfileShortOutput;
    /**
     *
     * @type {TagViewModel}
     * @memberof MeasurementOutput
     */
    tagTarget?: TagViewModel;
    /**
     *
     * @type {HappeningListItemViewModel}
     * @memberof MeasurementOutput
     */
    happeningTarget?: HappeningListItemViewModel;
    /**
     *
     * @type {Array<ProfileShortOutput>}
     * @memberof MeasurementOutput
     */
    contributors: Array<ProfileShortOutput>;
    /**
     *
     * @type {Array<OrganizerViewModel>}
     * @memberof MeasurementOutput
     */
    collaborators: Array<OrganizerViewModel>;
}

/**
 * Check if a given object implements the MeasurementOutput interface.
 */
export function instanceOfMeasurementOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "answers" in value;
    isInstance = isInstance && "indicator" in value;
    isInstance = isInstance && "measurer" in value;
    isInstance = isInstance && "contributors" in value;
    isInstance = isInstance && "collaborators" in value;

    return isInstance;
}

export function MeasurementOutputFromJSON(json: any): MeasurementOutput {
    return MeasurementOutputFromJSONTyped(json, false);
}

export function MeasurementOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeasurementOutput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, "id") ? (undefined as any) : json["id"],
        status: !exists(json, "status") ? (undefined as any) : MeasurementStatusFromJSON(json["status"]),
        createdAt: !exists(json, "createdAt") ? (undefined as any) : new Date(json["createdAt"]),
        answers: !exists(json, "answers")
            ? (undefined as any)
            : ((json["answers"] as Array<any>) ?? []).map(AnswerOutputFromJSON),
        indicator: !exists(json, "indicator")
            ? (undefined as any)
            : IndicatorDescriptiveOutputFromJSON(json["indicator"]),
        measurer: !exists(json, "measurer") ? (undefined as any) : ProfileShortOutputFromJSON(json["measurer"]),
        notes: !exists(json, "notes") ? (undefined as any) : json["notes"],
        profileTarget: !exists(json, "profileTarget")
            ? (undefined as any)
            : ProfileShortOutputFromJSON(json["profileTarget"]),
        tagTarget: !exists(json, "tagTarget") ? (undefined as any) : TagViewModelFromJSON(json["tagTarget"]),
        happeningTarget: !exists(json, "happeningTarget")
            ? (undefined as any)
            : HappeningListItemViewModelFromJSON(json["happeningTarget"]),
        contributors: !exists(json, "contributors")
            ? (undefined as any)
            : ((json["contributors"] as Array<any>) ?? []).map(ProfileShortOutputFromJSON),
        collaborators: !exists(json, "collaborators")
            ? (undefined as any)
            : ((json["collaborators"] as Array<any>) ?? []).map(OrganizerViewModelFromJSON),
    };
}

export function MeasurementOutputToJSON(value?: MeasurementOutput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
