/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import { OperationType, OperationTypeFromJSON, OperationTypeFromJSONTyped, OperationTypeToJSON } from "./";

/**
 *
 * @export
 * @interface ImageModelOperation
 */
export interface ImageModelOperation {
    /**
     *
     * @type {OperationType}
     * @memberof ImageModelOperation
     */
    operationType?: OperationType;
    /**
     *
     * @type {string}
     * @memberof ImageModelOperation
     */
    path?: string;
    /**
     *
     * @type {string}
     * @memberof ImageModelOperation
     */
    op?: string;
    /**
     *
     * @type {string}
     * @memberof ImageModelOperation
     */
    from?: string;
    /**
     *
     * @type {any}
     * @memberof ImageModelOperation
     */
    value?: any;
}

/**
 * Check if a given object implements the ImageModelOperation interface.
 */
export function instanceOfImageModelOperation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImageModelOperationFromJSON(json: any): ImageModelOperation {
    return ImageModelOperationFromJSONTyped(json, false);
}

export function ImageModelOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageModelOperation {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        operationType: !exists(json, "operationType")
            ? (undefined as any)
            : OperationTypeFromJSON(json["operationType"]),
        path: !exists(json, "path") ? (undefined as any) : json["path"],
        op: !exists(json, "op") ? (undefined as any) : json["op"],
        from: !exists(json, "from") ? (undefined as any) : json["from"],
        value: !exists(json, "value") ? (undefined as any) : json["value"],
    };
}

export function ImageModelOperationToJSON(value?: ImageModelOperation | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
