import strings from "../localization/strings";
import { HappeningRegistrationType, HappeningType } from "../openapi/backend";

export function happeningRegistrationTypeToString(
    registrationType: HappeningRegistrationType,
    happeningType?: HappeningType,
): string {
    if (happeningType === HappeningType.ExternalHappening) {
        return strings.externalActivity;
    }

    switch (registrationType) {
        case HappeningRegistrationType.SoloRegistration:
            return strings.register;
        case HappeningRegistrationType.NoRegistration:
            return strings.walkIn;
        case HappeningRegistrationType.GroupRegistration:
            return strings.teamHappening;
        case HappeningRegistrationType.ExternalRegistration:
            return strings.externalActivity;
        default:
            return strings.unknown;
    }
}
