import { Children, PropsWithChildren, useState } from "react";
import styles from "./CollapsibleListItem.module.scss";
import ChevronDown from "../../../assets/chevron_down.svg";
import ChevronUp from "../../../assets/chevron_up.svg";
import strings from "../../../localization/strings";
import cx from "classnames";

interface Props {
    className?: string;
    contentClassName?: string;
    title: string;
    defaultExpanded?: boolean;
}

export default function CollapsibleListItem({
    className,
    contentClassName,
    title,
    defaultExpanded,
    children,
}: PropsWithChildren<Props>) {
    const [collapsed, setCollapsed] = useState(!defaultExpanded);

    return (
        <div className={cx(styles.container, className)}>
            <button
                type="button"
                className={styles.titleBar}
                onClick={() => setCollapsed((current) => !current)}
                aria-label={`${collapsed ? strings.close : strings.open} ${title}`}
            >
                <img src={collapsed ? ChevronDown : ChevronUp} alt={strings.close} />
                <h2 className={styles.title}>{title}</h2>
            </button>
            {!collapsed && !!Children.count(children) && <div className={cx(contentClassName)}>{children}</div>}
            <div className={styles.bottomDivider} />
        </div>
    );
}
