import React, { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import {
    getInitialState,
    MediaLibraryAction,
    MediaLibraryReducer,
    MediaLibraryState,
} from "../reducers/MediaLibraryReducer";

export const MediaLibraryDataContext = createContext<[MediaLibraryState, Dispatch<MediaLibraryAction>]>([
    getInitialState(),
    () => {},
]);

export const MediaLibraryDataProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer(MediaLibraryReducer, getInitialState());

    return <MediaLibraryDataContext.Provider value={reducer}>{children}</MediaLibraryDataContext.Provider>;
};
