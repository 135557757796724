/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 * - Append nested object fields in array as formdata fields
 * - Always check for nullable in "body: .map" for json patch
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

function appendToParams(formParams: { append(param: string, value: any): any }, path: string, value: any) {
    if (value === undefined) {
        return;
    } else if (Array.isArray(value)) {
        value.forEach((v, i) => appendToParams(formParams, `${path}[${i}]`, v));
    } else if (value instanceof Date) {
        formParams.append(path, value.toUTCString());
    } else if (value instanceof Blob) {
        formParams.append(path, value);
    } else if (typeof value == "object" && !!value) {
        Object.keys(value).forEach((key) => appendToParams(formParams, `${path}.${key}`, value[key]));
    } else {
        formParams.append(path, value);
    }
}

import * as runtime from "../runtime";
import {
    Gender,
    GenderFromJSON,
    GenderToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    Tutorial,
    TutorialFromJSON,
    TutorialToJSON,
    UserIsVerifiedOutput,
    UserIsVerifiedOutputFromJSON,
    UserIsVerifiedOutputToJSON,
    UserListOutput,
    UserListOutputFromJSON,
    UserListOutputToJSON,
    UserListOutputPaginatedViewModel,
    UserListOutputPaginatedViewModelFromJSON,
    UserListOutputPaginatedViewModelToJSON,
    UserOrderBy,
    UserOrderByFromJSON,
    UserOrderByToJSON,
    UserOutput,
    UserOutputFromJSON,
    UserOutputToJSON,
} from "../models";

export interface BlockUserRequest {
    id: string;
    version: string;
}

export interface CompleteTutorialRequest {
    version: string;
    tutorial?: Tutorial;
}

export interface CreateUserRequest {
    version: string;
    email: string;
    givenName: string;
    familyName: string;
    roles?: Array<Role>;
    tags?: Array<string>;
    authorizedArrangements?: Array<string>;
    dateOfBirth?: Date;
    phoneNumber?: string;
    profession?: string;
}

export interface DeleteUserRequest {
    id: string;
    version: string;
}

export interface DeregisterUserDeviceRequest {
    version: string;
    key?: string;
}

export interface DownloadBackofficeUsersRequest {
    version: string;
    roles?: Array<Role>;
    query?: string;
    emailQuery?: string;
    partners?: Array<string>;
    happeningGroups?: Array<string>;
}

export interface DownloadParticipantsRequest {
    version: string;
    roles?: Array<Role>;
    query?: string;
    emailQuery?: string;
    partners?: Array<string>;
    happeningGroups?: Array<string>;
}

export interface DownloadPartnersRequest {
    version: string;
    roles?: Array<Role>;
    query?: string;
    emailQuery?: string;
    partners?: Array<string>;
    happeningGroups?: Array<string>;
}

export interface GetBackofficeUsersRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
}

export interface GetIntermediariesRequest {
    version: string;
    query?: string;
}

export interface GetPartnersRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    query?: string;
}

export interface GetPossibleProjectLeadersRequest {
    version: string;
    query?: string;
    happeningId?: string;
}

export interface GetPossibleProjectMembersRequest {
    version: string;
    query?: string;
    happeningId?: string;
}

export interface GetUserRequest {
    id: string;
    version: string;
}

export interface GetUsersRequest {
    version: string;
    page?: number;
    itemsPerPage?: number;
    itemsToSkip?: number;
    roles?: Array<Role>;
    query?: string;
    emailQuery?: string;
    partners?: Array<string>;
    happeningGroups?: Array<string>;
    orderBy?: UserOrderBy;
    sortOrder?: SortOrder;
    isDescending?: boolean;
}

export interface ImportUsersRequest {
    version: string;
    file: Blob;
}

export interface RegisterUserDeviceRequest {
    version: string;
    key?: string;
}

export interface ResetPasswordRequest {
    id: string;
    version: string;
}

export interface UnblockUserRequest {
    id: string;
    version: string;
}

export interface UpdateUserRequest {
    id: string;
    version: string;
    givenName: string;
    familyName: string;
    roles?: Array<Role>;
    authorizedArrangements?: Array<string>;
    tags?: Array<string>;
    preferredFilters?: Array<string>;
    dateOfBirth?: Date;
    phoneNumber?: string;
    profession?: string;
    postalCode?: string;
    gender?: Gender;
    school?: string;
    medicalNotes?: string;
}

export interface UserIsVerifiedRequest {
    version: string;
}

export interface VerifyUserRequest {
    version: string;
    email?: string;
}

export interface WhoAmIRequest {
    version: string;
}

/**
 * no description
 */
export class UserApi extends runtime.BaseAPI {
    /**
     */
    async blockUserRaw(requestParameters: BlockUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling blockUser.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling blockUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/{id}/block`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async blockUser(requestParameters: BlockUserRequest): Promise<void> {
        await this.blockUserRaw(requestParameters);
    }
    /**
     */
    async completeTutorialRaw(requestParameters: CompleteTutorialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling completeTutorial.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.tutorial !== undefined) {
            formParams.append("tutorial", requestParameters.tutorial as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/User/tutorial`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async completeTutorial(requestParameters: CompleteTutorialRequest): Promise<void> {
        await this.completeTutorialRaw(requestParameters);
    }
    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling createUser.",
            );
        }
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError(
                "email",
                "Required parameter requestParameters.email was null or undefined when calling createUser.",
            );
        }
        if (requestParameters.givenName === null || requestParameters.givenName === undefined) {
            throw new runtime.RequiredError(
                "givenName",
                "Required parameter requestParameters.givenName was null or undefined when calling createUser.",
            );
        }
        if (requestParameters.familyName === null || requestParameters.familyName === undefined) {
            throw new runtime.RequiredError(
                "familyName",
                "Required parameter requestParameters.familyName was null or undefined when calling createUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.email !== undefined) {
            formParams.append("Email", requestParameters.email as any);
            hasParams = true;
        }
        if (requestParameters.givenName !== undefined) {
            formParams.append("GivenName", requestParameters.givenName as any);
            hasParams = true;
        }
        if (requestParameters.familyName !== undefined) {
            formParams.append("FamilyName", requestParameters.familyName as any);
            hasParams = true;
        }
        if (requestParameters.roles) {
            requestParameters.roles.forEach((element, index) => {
                appendToParams(formParams, `Roles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.authorizedArrangements) {
            requestParameters.authorizedArrangements.forEach((element, index) => {
                appendToParams(formParams, `AuthorizedArrangements[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append("DateOfBirth", (requestParameters.dateOfBirth as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.phoneNumber !== undefined) {
            formParams.append("PhoneNumber", requestParameters.phoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.profession !== undefined) {
            formParams.append("Profession", requestParameters.profession as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/User`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     */
    async createUser(requestParameters: CreateUserRequest): Promise<number> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling deleteUser.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deleteUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "DELETE",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }
    /**
     */
    async deregisterUserDeviceRaw(requestParameters: DeregisterUserDeviceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling deregisterUserDevice.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.key !== undefined) {
            queryParameters["key"] = requestParameters.key;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/deregisterDevice`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deregisterUserDevice(requestParameters: DeregisterUserDeviceRequest): Promise<void> {
        await this.deregisterUserDeviceRaw(requestParameters);
    }
    /**
     */
    async downloadBackofficeUsersRaw(
        requestParameters: DownloadBackofficeUsersRequest,
    ): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling downloadBackofficeUsers.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.roles) {
            requestParameters.roles.forEach((element, index) => {
                appendToParams(formParams, `Roles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.query !== undefined) {
            formParams.append("Query", requestParameters.query as any);
            hasParams = true;
        }
        if (requestParameters.emailQuery !== undefined) {
            formParams.append("EmailQuery", requestParameters.emailQuery as any);
            hasParams = true;
        }
        if (requestParameters.partners) {
            requestParameters.partners.forEach((element, index) => {
                appendToParams(formParams, `Partners[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningGroups) {
            requestParameters.happeningGroups.forEach((element, index) => {
                appendToParams(formParams, `HappeningGroups[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/User/backoffice-users/download`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse<any>(response);
    }
    /**
     */
    async downloadBackofficeUsers(requestParameters: DownloadBackofficeUsersRequest): Promise<object> {
        const response = await this.downloadBackofficeUsersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async downloadParticipantsRaw(
        requestParameters: DownloadParticipantsRequest,
    ): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling downloadParticipants.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.roles) {
            requestParameters.roles.forEach((element, index) => {
                appendToParams(formParams, `Roles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.query !== undefined) {
            formParams.append("Query", requestParameters.query as any);
            hasParams = true;
        }
        if (requestParameters.emailQuery !== undefined) {
            formParams.append("EmailQuery", requestParameters.emailQuery as any);
            hasParams = true;
        }
        if (requestParameters.partners) {
            requestParameters.partners.forEach((element, index) => {
                appendToParams(formParams, `Partners[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningGroups) {
            requestParameters.happeningGroups.forEach((element, index) => {
                appendToParams(formParams, `HappeningGroups[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/User/participants/download`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse<any>(response);
    }
    /**
     */
    async downloadParticipants(requestParameters: DownloadParticipantsRequest): Promise<object> {
        const response = await this.downloadParticipantsRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async downloadPartnersRaw(requestParameters: DownloadPartnersRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling downloadPartners.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.roles) {
            requestParameters.roles.forEach((element, index) => {
                appendToParams(formParams, `Roles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.query !== undefined) {
            formParams.append("Query", requestParameters.query as any);
            hasParams = true;
        }
        if (requestParameters.emailQuery !== undefined) {
            formParams.append("EmailQuery", requestParameters.emailQuery as any);
            hasParams = true;
        }
        if (requestParameters.partners) {
            requestParameters.partners.forEach((element, index) => {
                appendToParams(formParams, `Partners[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.happeningGroups) {
            requestParameters.happeningGroups.forEach((element, index) => {
                appendToParams(formParams, `HappeningGroups[${index}]`, element);
                hasParams = true;
            });
        }
        const response = await this.request({
            path: `/v{version}/User/partners/download`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse<any>(response);
    }
    /**
     */
    async downloadPartners(requestParameters: DownloadPartnersRequest): Promise<object> {
        const response = await this.downloadPartnersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getBackofficeUsersRaw(
        requestParameters: GetBackofficeUsersRequest,
    ): Promise<runtime.ApiResponse<UserListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getBackofficeUsers.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/backoffice-users`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getBackofficeUsers(requestParameters: GetBackofficeUsersRequest): Promise<UserListOutputPaginatedViewModel> {
        const response = await this.getBackofficeUsersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getIntermediariesRaw(
        requestParameters: GetIntermediariesRequest,
    ): Promise<runtime.ApiResponse<Array<UserListOutput>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getIntermediaries.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/intermediaries`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserListOutputFromJSON));
    }
    /**
     */
    async getIntermediaries(requestParameters: GetIntermediariesRequest): Promise<Array<UserListOutput>> {
        const response = await this.getIntermediariesRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPartnersRaw(
        requestParameters: GetPartnersRequest,
    ): Promise<runtime.ApiResponse<UserListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getPartners.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/partners`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getPartners(requestParameters: GetPartnersRequest): Promise<UserListOutputPaginatedViewModel> {
        const response = await this.getPartnersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPossibleProjectLeadersRaw(
        requestParameters: GetPossibleProjectLeadersRequest,
    ): Promise<runtime.ApiResponse<Array<UserListOutput>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getPossibleProjectLeaders.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.happeningId !== undefined) {
            queryParameters["happeningId"] = requestParameters.happeningId;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/project-leaders`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserListOutputFromJSON));
    }
    /**
     */
    async getPossibleProjectLeaders(
        requestParameters: GetPossibleProjectLeadersRequest,
    ): Promise<Array<UserListOutput>> {
        const response = await this.getPossibleProjectLeadersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getPossibleProjectMembersRaw(
        requestParameters: GetPossibleProjectMembersRequest,
    ): Promise<runtime.ApiResponse<Array<UserListOutput>>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getPossibleProjectMembers.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.happeningId !== undefined) {
            queryParameters["happeningId"] = requestParameters.happeningId;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/project-members`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserListOutputFromJSON));
    }
    /**
     */
    async getPossibleProjectMembers(
        requestParameters: GetPossibleProjectMembersRequest,
    ): Promise<Array<UserListOutput>> {
        const response = await this.getPossibleProjectMembersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<UserOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling getUser.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }
    /**
     */
    async getUser(requestParameters: GetUserRequest): Promise<UserOutput> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async getUsersRaw(
        requestParameters: GetUsersRequest,
    ): Promise<runtime.ApiResponse<UserListOutputPaginatedViewModel>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling getUsers.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.page !== undefined) {
            queryParameters["Page"] = requestParameters.page;
        }
        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters["ItemsPerPage"] = requestParameters.itemsPerPage;
        }
        if (requestParameters.itemsToSkip !== undefined) {
            queryParameters["ItemsToSkip"] = requestParameters.itemsToSkip;
        }
        if (requestParameters.roles && requestParameters.roles.length > 0) {
            queryParameters["Roles"] = requestParameters.roles;
        }
        if (requestParameters.query !== undefined) {
            queryParameters["Query"] = requestParameters.query;
        }
        if (requestParameters.emailQuery !== undefined) {
            queryParameters["EmailQuery"] = requestParameters.emailQuery;
        }
        if (requestParameters.partners && requestParameters.partners.length > 0) {
            queryParameters["Partners"] = requestParameters.partners;
        }
        if (requestParameters.happeningGroups && requestParameters.happeningGroups.length > 0) {
            queryParameters["HappeningGroups"] = requestParameters.happeningGroups;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters["OrderBy"] = requestParameters.orderBy;
        }
        if (requestParameters.sortOrder !== undefined) {
            queryParameters["SortOrder"] = requestParameters.sortOrder;
        }
        if (requestParameters.isDescending !== undefined) {
            queryParameters["IsDescending"] = requestParameters.isDescending;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) =>
            UserListOutputPaginatedViewModelFromJSON(jsonValue),
        );
    }
    /**
     */
    async getUsers(requestParameters: GetUsersRequest): Promise<UserListOutputPaginatedViewModel> {
        const response = await this.getUsersRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async importUsersRaw(requestParameters: ImportUsersRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling importUsers.",
            );
        }
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError(
                "file",
                "Required parameter requestParameters.file was null or undefined when calling importUsers.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append("File", requestParameters.file as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/User/import`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async importUsers(requestParameters: ImportUsersRequest): Promise<void> {
        await this.importUsersRaw(requestParameters);
    }
    /**
     */
    async registerUserDeviceRaw(requestParameters: RegisterUserDeviceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling registerUserDevice.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.key !== undefined) {
            queryParameters["key"] = requestParameters.key;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/registerDevice`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async registerUserDevice(requestParameters: RegisterUserDeviceRequest): Promise<void> {
        await this.registerUserDeviceRaw(requestParameters);
    }
    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling resetPassword.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling resetPassword.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/{id}/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async resetPassword(requestParameters: ResetPasswordRequest): Promise<void> {
        await this.resetPasswordRaw(requestParameters);
    }
    /**
     */
    async unblockUserRaw(requestParameters: UnblockUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling unblockUser.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling unblockUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/{id}/unblock`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async unblockUser(requestParameters: UnblockUserRequest): Promise<void> {
        await this.unblockUserRaw(requestParameters);
    }
    /**
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<UserOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                "id",
                "Required parameter requestParameters.id was null or undefined when calling updateUser.",
            );
        }
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling updateUser.",
            );
        }
        if (requestParameters.givenName === null || requestParameters.givenName === undefined) {
            throw new runtime.RequiredError(
                "givenName",
                "Required parameter requestParameters.givenName was null or undefined when calling updateUser.",
            );
        }
        if (requestParameters.familyName === null || requestParameters.familyName === undefined) {
            throw new runtime.RequiredError(
                "familyName",
                "Required parameter requestParameters.familyName was null or undefined when calling updateUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.roles) {
            requestParameters.roles.forEach((element, index) => {
                appendToParams(formParams, `Roles[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.authorizedArrangements) {
            requestParameters.authorizedArrangements.forEach((element, index) => {
                appendToParams(formParams, `AuthorizedArrangements[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.givenName !== undefined) {
            formParams.append("GivenName", requestParameters.givenName as any);
            hasParams = true;
        }
        if (requestParameters.familyName !== undefined) {
            formParams.append("FamilyName", requestParameters.familyName as any);
            hasParams = true;
        }
        if (requestParameters.tags) {
            requestParameters.tags.forEach((element, index) => {
                appendToParams(formParams, `Tags[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.preferredFilters) {
            requestParameters.preferredFilters.forEach((element, index) => {
                appendToParams(formParams, `PreferredFilters[${index}]`, element);
                hasParams = true;
            });
        }
        if (requestParameters.dateOfBirth !== undefined) {
            formParams.append("DateOfBirth", (requestParameters.dateOfBirth as any).toUTCString());
            hasParams = true;
        }
        if (requestParameters.phoneNumber !== undefined) {
            formParams.append("PhoneNumber", requestParameters.phoneNumber as any);
            hasParams = true;
        }
        if (requestParameters.profession !== undefined) {
            formParams.append("Profession", requestParameters.profession as any);
            hasParams = true;
        }
        if (requestParameters.postalCode !== undefined) {
            formParams.append("PostalCode", requestParameters.postalCode as any);
            hasParams = true;
        }
        if (requestParameters.gender !== undefined) {
            formParams.append("Gender", requestParameters.gender as any);
            hasParams = true;
        }
        if (requestParameters.school !== undefined) {
            formParams.append("School", requestParameters.school as any);
            hasParams = true;
        }
        if (requestParameters.medicalNotes !== undefined) {
            formParams.append("MedicalNotes", requestParameters.medicalNotes as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/User/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
                .replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: "PUT",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }
    /**
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<UserOutput> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async userIsVerifiedRaw(
        requestParameters: UserIsVerifiedRequest,
    ): Promise<runtime.ApiResponse<UserIsVerifiedOutput>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling userIsVerified.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/verify`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserIsVerifiedOutputFromJSON(jsonValue));
    }
    /**
     */
    async userIsVerified(requestParameters: UserIsVerifiedRequest): Promise<UserIsVerifiedOutput> {
        const response = await this.userIsVerifiedRaw(requestParameters);
        return await response.value();
    }
    /**
     */
    async verifyUserRaw(requestParameters: VerifyUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling verifyUser.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): any };
        let hasParams = false;
        let useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.email !== undefined) {
            formParams.append("email", requestParameters.email as any);
            hasParams = true;
        }
        const response = await this.request({
            path: `/v{version}/User/verify`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "POST",
            headers: headerParameters,
            query: queryParameters,
            body: hasParams ? formParams : undefined,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async verifyUser(requestParameters: VerifyUserRequest): Promise<void> {
        await this.verifyUserRaw(requestParameters);
    }
    /**
     */
    async whoAmIRaw(requestParameters: WhoAmIRequest): Promise<runtime.ApiResponse<UserOutput>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError(
                "version",
                "Required parameter requestParameters.version was null or undefined when calling whoAmI.",
            );
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        const response = await this.request({
            path: `/v{version}/User/me`.replace(
                `{${"version"}}`,
                encodeURIComponent(String(requestParameters.version)),
            ),
            method: "GET",
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserOutputFromJSON(jsonValue));
    }
    /**
     */
    async whoAmI(requestParameters: WhoAmIRequest): Promise<UserOutput> {
        const response = await this.whoAmIRaw(requestParameters);
        return await response.value();
    }
}
