import { useEffect } from "react";
import { useSearchHappeningsOverview } from "../../../hooks/HappeningsHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import HappeningsSearchTable from "./HappeningsSearchTable";
import { useRefer } from "../../../hooks/RegistrationHooks";

interface Props {
    happeningId: string;
    registrationId: string;
    onSuccess: () => void;
    participantName: string;
}

export default function HappeningsSearchTableContainer({
    happeningId,
    registrationId,
    onSuccess,
    participantName,
}: Props) {
    const [requestState, get] = useSearchHappeningsOverview();
    const [{ state: referState, error: referError }, refer] = useRefer(registrationId);

    useEffect(() => {
        if (referState === RequestState.DONE) {
            onSuccess();
        }
    }, [referState, onSuccess]);

    return (
        <>
            <HappeningsSearchTable
                happenings={requestState.value}
                getHappenings={get}
                error={requestState.error}
                onRowClick={refer}
                isLoadingAction={referState === RequestState.LOADING}
                participantName={participantName}
            />
            {referError && <ErrorMessage error={referError} isToast />}
        </>
    );
}
