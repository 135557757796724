import React, { PropsWithChildren } from "react";
import { Configuration, MeasurementsApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<MeasurementsApi> = (params) => new MeasurementsApi(new Configuration(params));

export const MeasurementsApiContext = React.createContext(createApi({}));

export const MeasurementsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={MeasurementsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
