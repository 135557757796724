import cx from "classnames";
import React, { useCallback, useState } from "react";
import strings from "../../../localization/strings";
import { CrossButton } from "../CrossButton/CrossButton";
import FormFieldContainer from "../Form/FormFieldContainer/FormFieldContainer";
import TextButton from "../TextButton/TextButton";
import styles from "./TextButtonWithMessageModal.module.scss";

interface Props {
    text: string;
    className?: string;
    onSubmit: (message: string) => void;
    title: string;
    inputTitle: string;
    isLoading: boolean;
}

export default function TextButtonWithMessageModal({ text, className, onSubmit, title, inputTitle, isLoading }: Props) {
    const [isOpen, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const doOpen = useCallback(() => setOpen(true), []);
    const doClose = useCallback(() => setOpen(false), []);

    const confirmSubmit = useCallback(() => {
        onSubmit(message);
        doClose();
    }, [onSubmit, message, doClose]);
    return (
        <div className={cx(styles.container, className)}>
            <TextButton text={text} onClick={doOpen} isLoading={isLoading} buttonType="alternate" />
            {isOpen && (
                <div className={styles.modal}>
                    <div className={styles.header}>
                        <h3 className={styles.title}>{title}</h3>
                        <CrossButton onClick={doClose} aria-label={strings.close} />
                    </div>
                    <div className={styles.content}>
                        <FormFieldContainer name="message" label={inputTitle}>
                            <textarea
                                name="input-message"
                                rows={5}
                                className={styles.input}
                                onChange={(e) => setMessage(e.target.value)}
                                defaultValue={message}
                                autoFocus
                            />
                        </FormFieldContainer>
                        <div className={styles.buttonContainer}>
                            <TextButton
                                text={strings.sendMessage}
                                onClick={confirmSubmit}
                                isLoading={isLoading}
                                disabled={message.length < 2}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
