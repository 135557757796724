/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum FormType {
    Jsc = "JSC",
    Vsc = "VSC",
    KeiComputer = "KEIComputer",
    KeiSchoolTrip = "KEISchoolTrip",
    KeiParticipas = "KEIParticipas",
}

export function FormTypeFromJSON(json: any): FormType {
    return FormTypeFromJSONTyped(json, false);
}

export function FormTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormType {
    return json as FormType;
}

export function FormTypeToJSON(value?: FormType | null): any {
    return value as any;
}
