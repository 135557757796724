import { useContext, useCallback } from "react";
import { IndicatorsApiContext } from "../contexts/IndicatorsApiContext";
import { IndicatorInputModel, MeasurementInputModel } from "../models/IndicatorModels";
import {
    GetIndicatorsRequest,
    TagViewModel,
    IndicatorOrderBy,
    SortOrder,
    IndicatorListOutputPaginatedViewModel,
} from "../openapi/backend";
import { IndicatorStateOption } from "../types/DropdownOption";
import { UserNamedType } from "../types/UserNamedType";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { measurementInputToData } from "../utils.ts/MeasurementUtils";
import { ApiCallState, useApiCall, useApiCallback } from "./UseApiCall";

export type IndicatorsFilter = Pick<GetIndicatorsRequest, "query" | "date" | "page"> & {
    statuses?: IndicatorStateOption[];
    organisations?: TagViewModel[];
    organizers?: UserNamedType[];
};

export type GetIndicatorsFun = (orderBy: IndicatorOrderBy, sortOrder: SortOrder, filters: IndicatorsFilter) => void;

export const useGetIndicators = (): [
    ApiCallState<IndicatorListOutputPaginatedViewModel>,
    GetIndicatorsFun,
    () => void,
] => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, filter: IndicatorsFilter = {}) => {
            const transformedFilter = {
                statuses: filter.statuses?.map((status) => status.value),
                organisations: filter.organisations?.map((org) => org.id),
                organizers: filter?.organizers?.map((organizer) => organizer.id),
            };

            return api.getIndicators({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                ...filter,
                ...transformedFilter,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

function indicatorInputToData(data: IndicatorInputModel) {
    return {
        ...data,
        happeningId: data.happeningInput.value,
    };
}

export const useCreateIndicator = () => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback(
        (input: IndicatorInputModel) =>
            api.createIndicator({ version: REACT_APP_BACKEND_VERSION, ...indicatorInputToData(input) }),
        [api],
    );
    return useApiCallback(callback);
};

export const useGetIndicator = (id: number) => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback(() => api.getIndicator({ version: REACT_APP_BACKEND_VERSION, id }), [api, id]);
    return useApiCall(callback);
};

export const useGetIndicatorCallback = () => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback((id: number) => api.getIndicator({ version: REACT_APP_BACKEND_VERSION, id }), [api]);
    return useApiCallback(callback);
};

export const useUpdateIndicator = (id: number) => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback(
        (input: IndicatorInputModel) =>
            api.updateIndicator({ version: REACT_APP_BACKEND_VERSION, ...indicatorInputToData(input), id }),
        [api, id],
    );
    return useApiCallback(callback);
};

export const useDeleteIndicator = () => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback(
        (id: number) => api.deleteIndicator({ version: REACT_APP_BACKEND_VERSION, id }),
        [api],
    );
    return useApiCallback(callback);
};

export const useMeasureIndicator = (id: number) => {
    const api = useContext(IndicatorsApiContext);
    const callback = useCallback(
        (input: MeasurementInputModel) =>
            api.measure({
                version: REACT_APP_BACKEND_VERSION,
                ...measurementInputToData(input),
                id,
            }),
        [api, id],
    );
    return useApiCallback(callback);
};
