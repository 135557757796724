import { useEffect, useMemo, useState } from "react";
import styles from "./MultiDataListInput.module.scss";
import { QuickAddButton, RemoveButton } from "../../RoundButton/RoundButton";
import strings from "../../../../localization/strings";
import DataListInputPlacesSearch, { DataListInputPlacesSearchProps } from "../DataListInput/DataListInputPlacesSearch";
import { useGoogleServices } from "../../../../utils.ts/GooglePlacesAPI";

export interface MultiDataListInputPlacesSearchProps
    extends Omit<DataListInputPlacesSearchProps, "value" | "onChange" | "options" | "setValue"> {
    value?: string[];
    onChange?: (selected: string[] | string) => void;
    single?: boolean;
}

interface PlaceProps {
    value: string;
    remove: () => void;
}

const Place = ({ remove, value }: PlaceProps) => (
    <div className={`${styles.rowField} ${styles.location}`}>
        <div className={styles.nameField}>{value}</div>
        <RemoveButton onClick={remove} />
    </div>
);

export default function MultiDataListInputPlacesSearch({
    value,
    onChange,
    single,
    ...props
}: MultiDataListInputPlacesSearchProps) {
    const [chosenSchool, setChosenSchool] = useState<string | undefined>();
    const [innerValue, setInnerValue] = useState<string[]>(value || []);
    const googleServices = useGoogleServices();

    const Places = useMemo(
        () =>
            innerValue?.map((item, index) => {
                return (
                    <Place
                        key={index}
                        remove={() => setInnerValue((current) => current?.filter((_, i) => i !== index))}
                        value={item}
                        {...props}
                    />
                );
            }),
        [innerValue, props],
    );

    useEffect(() => {
        if (onChange && innerValue) {
            onChange(single ? innerValue[0] : innerValue);
        }
    }, [onChange, innerValue, single]);

    return (
        <div className={styles.selectFields}>
            <div className={styles.locationSearchWindow}>
                <DataListInputPlacesSearch
                    {...props}
                    list={`${props.list}.1`}
                    name={`${props.name}.1`}
                    onChange={(e) => {
                        single && e && setInnerValue([e]);
                        single ? setChosenSchool(undefined) : setChosenSchool(e);
                    }}
                    value={chosenSchool}
                    disabled={!googleServices}
                />
                {!single && (
                    <div className={styles.addLocation}>
                        <QuickAddButton
                            onClick={() => {
                                if (chosenSchool) {
                                    setInnerValue((current) => [...current, chosenSchool]);
                                    setChosenSchool(undefined);
                                }
                            }}
                            disabled={!googleServices}
                        />
                    </div>
                )}
            </div>
            {!googleServices && <div className={styles.alert}>{strings.schoolsWentWrong}</div>}
            {Places}
        </div>
    );
}
