import React from "react";
import strings from "../../../localization/strings";
import { MediaDetailsContainerProps, MediaDetailsModalProps } from "../../../types/MediaLibraryType";
import Modal from "../Modal/Modal";
import { MediaDetailsContainer } from "./MediaDetailsContainer";

export type Props = MediaDetailsContainerProps & MediaDetailsModalProps;

export const MediaDetailsModal = (props: Props) => (
    <Modal isOpen={true} title={strings.mediaEditDetailModalTitle} onClose={props.onClose}>
        <MediaDetailsContainer {...props} />
    </Modal>
);
