import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { createPatch, Operation } from "rfc6902";
import { MediaLibraryDataContext } from "../../../contexts/MediaLibraryDataContext";
import { useDeleteMedia, useGetMedia, useUpdateMedia } from "../../../hooks/MediaHooks";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { TagViewModel } from "../../../openapi/backend";
import { ActionType } from "../../../reducers/MediaLibraryReducer";
import { MediaDetailInput, MediaDetailsContainerProps, MediaDetailsModalProps } from "../../../types/MediaLibraryType";
import { hasValues } from "../../../utils.ts/Array";
import { toastSuccess } from "../../../utils.ts/Toaster";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { MediaDetails } from "./MediaDetails";

function getAddTagOperations(original: TagViewModel[], input: TagViewModel[]): Operation[] {
    const operations: Operation[] = [];

    input.forEach((tag) => {
        if (!original.find((t) => t.id === tag.id)) {
            operations.push({
                op: "add",
                path: "/tags/-",
                value: { id: tag.id },
            });
        }
    });
    return operations;
}

function getRemoveTagOperations(original: TagViewModel[], input: TagViewModel[]): Operation[] {
    const operations: Operation[] = [];

    original.forEach((tag, i) => {
        if (!input.find((t) => t.id === tag.id)) {
            //  order matters, so we need to remove from the end first
            operations.unshift({
                op: "remove",
                path: `/tags/${i}`,
            });
        }
    });

    return operations;
}

type TransitionInput = {
    altText: string;
    categoryId?: string;
};
export type Props = MediaDetailsContainerProps & MediaDetailsModalProps;

export const MediaDetailsContainer = ({ mediaId, ...props }: Props) => {
    const fetchRequestState = useGetMedia(mediaId);
    const [deleteRequestState, deleteCallback] = useDeleteMedia(mediaId);
    const [updateRequestState, updateCallback] = useUpdateMedia(mediaId);
    const [, dispatch] = useContext(MediaLibraryDataContext);
    const { onClose } = props;

    const isWaitingForActionRequest = useMemo(
        () => [deleteRequestState.state, updateRequestState.state].includes(RequestState.LOADING),
        [deleteRequestState.state, updateRequestState.state],
    );

    useEffect(() => {
        if (deleteRequestState.state === RequestState.DONE) {
            dispatch({ type: ActionType.removeFromLibrary, value: deleteRequestState.value });
            toastSuccess(
                strings.formatString(strings.mediaDeleted, deleteRequestState.value?.originalFileName) as string,
            );
            onClose();
        }
    }, [deleteRequestState, mediaId, dispatch, onClose]);

    useEffect(() => {
        if (updateRequestState.state === RequestState.DONE) {
            dispatch({ type: ActionType.update, value: updateRequestState.value });
            toastSuccess(
                strings.formatString(strings.mediaUpdated, updateRequestState.value?.originalFileName) as string,
            );
            onClose();
        }
    }, [updateRequestState, mediaId, dispatch, onClose]);

    const doUpdate = useCallback(
        (input: MediaDetailInput) => {
            if (!fetchRequestState.value) {
                return;
            }

            const originalViewModel = fetchRequestState.value;
            const original = {
                altText: originalViewModel.image.altText,
                categoryId: originalViewModel.category?.id,
            } as TransitionInput;
            const updated = {
                altText: input.altText,
                categoryId: hasValues(input.category) ? input.category[0].value.id : undefined,
            } as TransitionInput;
            const baseOperations = createPatch(original, updated);

            const operations = baseOperations.concat(
                getAddTagOperations(
                    originalViewModel.tags,
                    input.tags.map((t) => t.value),
                ),
                getRemoveTagOperations(
                    originalViewModel.tags,
                    input.tags.map((t) => t.value),
                ),
            );

            updateCallback(operations);
        },
        [fetchRequestState.value, updateCallback],
    );

    return (
        <>
            {fetchRequestState.state === RequestState.LOADING && <div>{strings.loading}</div>}
            {fetchRequestState.value && (
                <MediaDetails
                    {...props}
                    media={fetchRequestState.value}
                    onDelete={deleteCallback}
                    onUpdate={doUpdate}
                    waitingForResponse={isWaitingForActionRequest}
                />
            )}
            {fetchRequestState.error && (
                <ErrorMessage error={fetchRequestState.error} defaultMessage={strings.somethingWentWrong} />
            )}
            {deleteRequestState.error && <ErrorMessage error={deleteRequestState.error} isToast />}
        </>
    );
};
