import React from "react";
import { useGetParticipants } from "../../../../hooks/UserHooks";
import { ProfileOutput } from "../../../../openapi/backend";
import { getName } from "../../../../utils.ts/GetName";
import RemoteSuggestionInput, { Props as InputProps } from "./RemoteSuggestionInput";

type Props<T> = Pick<InputProps<T, any>, "className" | "onChangeValue" | "value" | "name">;

function getDisplayLabel(v: ProfileOutput) {
    let label = `${getName(v)}`;

    if (v.email) {
        label += ` (${v.email})`;
    }

    return label;
}

const mapSuggestions = (value: ProfileOutput[]) => {
    return value.map((v) => ({
        displayLabel: getDisplayLabel(v),
        inputLabel: getName(v),
        value: v.id,
    }));
};

export default function RemoteParticipantSuggestionInput({ ...props }: Props<string>) {
    const searchHook = useGetParticipants();

    return <RemoteSuggestionInput {...props} callbackHook={searchHook} mapSuggestions={mapSuggestions} />;
}
