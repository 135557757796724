import strings from "../localization/strings";
import moment from "moment";

export function formatAsPrice(price: number) {
    return price.toLocaleString(strings.getLanguage(), {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export function formatAsPriceGroup(price: number) {
    return strings.formatString(strings.pricePerGroup, formatAsPrice(price)) as string;
}

export function formatAsPriceIndividual(price: number) {
    return strings.formatString(strings.pricePerPerson, formatAsPrice(price)) as string;
}

export function formatAsPriceExternal(price: number) {
    return strings.formatString(strings.priceExternal, formatAsPrice(price)) as string;
}

export const asNumber = (v: number | string | undefined | string[]): number | undefined => {
    let value: number | undefined = undefined;
    if (typeof v === "string" && v !== "") {
        var localized = v.replace(",", ".");
        value = Number.isNaN(Number(localized)) ? undefined : Number(v);
    } else if (typeof v === "number" && !Number.isNaN(v)) {
        value = v;
    }

    return value;
};

export function formatAsDate(date: Date, format = "DD-MM-YYYY") {
    return moment(date).format(format);
}
export function formatAsTime(date: Date, format = "HH:mm") {
    return moment(date).format(format);
}
