import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import styles from "./DatePicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import strings from "../../../../localization/strings";

const getLocale = () => {
    // Dynamically import locale
    return require(`date-fns/locale/${strings.getLanguage()}/index.js`);
};

export type DatePickerProps<
    CustomModifierNames extends string = never,
    WithRange extends boolean | undefined = undefined,
> = ReactDatePickerProps<CustomModifierNames, WithRange>;

function DatePicker<CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>({
    className,
    dateFormat = ["dd/MM/yyyy", "dd-MM-yyyy", "d/M/yyyy", "d-M-yyyy"],
    ...props
}: DatePickerProps<CustomModifierNames, WithRange>) {
    return (
        <ReactDatePicker
            calendarClassName={styles.calendar}
            className={`${styles.datePicker} ${className}`}
            locale={getLocale()}
            dateFormat={dateFormat}
            {...props}
        />
    );
}

export default DatePicker;
