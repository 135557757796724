import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HappeningCloneContext } from "../../../contexts/HappeningCloneContext";
import { LocalHappeningContext, LocalHappeningContextProvider } from "../../../contexts/LocalHappeningContext";
import { useCreateCluster } from "../../../hooks/ClusterHooks";
import { useHappeningArchive, useHappeningDelete, useHappeningPublish } from "../../../hooks/HappeningsHooks";
import useErrorOrNavigate from "../../../hooks/useErrorOrNavigateBack";
import strings from "../../../localization/strings";
import { HappeningType } from "../../../openapi/backend";
import {
    createExternalHappeningLink,
    createHappeningLink,
    createPrivateHappeningLink,
    getUpdateHappeningLink,
    happeningSuggestionOverviewLink,
} from "../../../utils.ts/Urls";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import { WithProfileModalProps, withProfileModal } from "../../Modals/ProfileModal";
import ClusterHeader from "../../core/ClusterHeader/ClusterHeader";
import InlineLoading from "../../core/InlineLoading/InlineLoading";
import CancelHappeningModal from "./CancelHappeningModal";
import ViewHappening from "./ViewHappening";
import styles from "./ViewHappening.module.scss";
import RejectSuggestionModal from "./RejectSuggestionModal";

type ViewHappeningContainerProps = {} & WithProfileModalProps;

type DetailsContainerProps = {
    happeningId: string;
} & WithProfileModalProps;

function ViewHappeningContainer({ onProfileSelect }: ViewHappeningContainerProps) {
    const { happeningId } = useParams();

    if (!happeningId) {
        return null;
    }

    return (
        <LocalHappeningContextProvider id={happeningId}>
            <DetailsContainer happeningId={happeningId} onProfileSelect={onProfileSelect} />
        </LocalHappeningContextProvider>
    );
}

function DetailsContainer({ happeningId, onProfileSelect }: DetailsContainerProps) {
    const { happening, isLoading, isNotFound, refresh } = useContext(LocalHappeningContext);
    const [error, setError] = useState<Response>();
    const [cancelFormOpen, setCancelFormOpen] = useState(false);
    const [rejectFormOpen, setRejectFormOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<number>();
    const navigate = useNavigate();
    const { setHappening: setHappeningToClone } = useContext(HappeningCloneContext);
    const onRejected = useCallback(() => {
        navigate(happeningSuggestionOverviewLink);
    }, [navigate]);

    const [archiveState, archiveHappening] = useHappeningArchive(happeningId);
    const [deleteState, deleteHappening] = useHappeningDelete(happeningId);
    const [publishState, publishHappening] = useHappeningPublish(happeningId);
    const [clusterState, createCluster] = useCreateCluster(happeningId);

    useErrorOrNavigate(archiveState, setError, refresh);
    useErrorOrNavigate(deleteState, setError);
    useErrorOrNavigate(publishState, setError, refresh);
    useErrorOrNavigate(clusterState, setError, refresh);

    const isLoadedHappening = useMemo(() => happening?.id === happeningId, [happening?.id, happeningId]);
    if (!isLoading && !happening && isNotFound) {
        return <div>{strings.happeningNotFound}</div>;
    }

    return (
        <FormPageLayout title={strings.seeHappening}>
            <div className={`${styles.fullPageForm} ${styles.seeHappening}`}>
                {happening?.cluster && <ClusterHeader cluster={happening?.cluster} happeningId={happeningId} />}
                {happening && isLoadedHappening && (
                    <ViewHappening
                        key={happening.id}
                        happening={happening}
                        onDelete={deleteHappening}
                        deleteState={deleteState.state}
                        onArchive={archiveHappening}
                        onPublish={publishHappening}
                        onCreateCluster={createCluster}
                        onCancel={() => {
                            setCancelFormOpen(true);
                            setSelectedDate(undefined);
                        }}
                        onCancelDate={(id) => {
                            setCancelFormOpen(true);
                            setSelectedDate(id);
                        }}
                        onSelectParticipant={onProfileSelect}
                        onUpdate={() => navigate(getUpdateHappeningLink(happening.id))}
                        onClone={() => {
                            setHappeningToClone(happening);
                            const link = (() => {
                                switch (happening.type) {
                                    case HappeningType.PrivateHappening:
                                        return createPrivateHappeningLink;
                                    case HappeningType.ExternalHappening:
                                        return createExternalHappeningLink;
                                    default:
                                        return createHappeningLink;
                                }
                            })();
                            navigate(link);
                        }}
                        onReject={() => setRejectFormOpen(true)}
                        error={error}
                    />
                )}
                {/* Show Loading when no happening yet or when changing happening (eg navigating in a cluster) */}
                {isLoading && (!happening || !isLoadedHappening) && (
                    <InlineLoading className={styles.loading} alternative />
                )}
                {cancelFormOpen && happening && (
                    <CancelHappeningModal
                        isOpen={cancelFormOpen}
                        onDismiss={() => setCancelFormOpen(false)}
                        onSuccess={refresh}
                        happeningId={happeningId}
                        dateId={selectedDate}
                        isRecurrence={!!happening.recurrence}
                        dateTimes={happening.dateTimes}
                    />
                )}
                {rejectFormOpen && (
                    <RejectSuggestionModal
                        onDismiss={() => setRejectFormOpen(false)}
                        onSuccess={onRejected}
                        happeningId={happeningId}
                    />
                )}
            </div>
        </FormPageLayout>
    );
}

export default withProfileModal(ViewHappeningContainer);
