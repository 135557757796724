import { useGetMeasurements } from "../../hooks/MeasurementHooks";
import Measurements from "./Measurements";

export function AllMeasurementsContainer() {
    const [{ value: measurementsValue, error: measurementsError }, getMeasurements] = useGetMeasurements();

    return (
        <Measurements measurements={measurementsValue} error={measurementsError} getMeasurements={getMeasurements} />
    );
}

export default AllMeasurementsContainer;
