/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum OrganisationInfoOrderBy {
    Default = "Default",
    Title = "Title",
    Organisation = "Organisation",
    Category = "Category",
    IsActive = "IsActive",
    Distance = "Distance",
}

export function OrganisationInfoOrderByFromJSON(json: any): OrganisationInfoOrderBy {
    return OrganisationInfoOrderByFromJSONTyped(json, false);
}

export function OrganisationInfoOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationInfoOrderBy {
    return json as OrganisationInfoOrderBy;
}

export function OrganisationInfoOrderByToJSON(value?: OrganisationInfoOrderBy | null): any {
    return value as any;
}
