import { useCallback } from "react";
import InputField, { InputFieldProps } from "../InputField/InputField";
import MultiField, { FieldProps, ManipulationType } from "./MultiField";

export type MultiInputFieldProps = Omit<InputFieldProps, "value" | "onChange"> & {
    value?: string | string[];
    onChange: (value: string[]) => void;
    manipulationType?: ManipulationType;
    rearrangeable?: boolean;
};

const MultiInputField = ({
    value,
    name,
    onChange,
    manipulationType,
    rearrangeable,
    ...props
}: MultiInputFieldProps) => {
    const Field = useCallback(
        ({ index, fieldValue, onChange: fieldOnChange }: FieldProps<string>) => (
            <InputField
                {...props}
                name={`${name}.${index}`}
                onBlur={(e) => fieldOnChange(e.target.value)}
                defaultValue={fieldValue}
            />
        ),
        [name, props],
    );

    return (
        <MultiField
            Field={Field}
            value={value}
            onChange={onChange}
            manipulationType={manipulationType}
            rearrangeable={rearrangeable}
            readOnly={props.readOnly}
        />
    );
};

export default MultiInputField;
