import { Route } from "react-router-dom";
import Bouncer from "../authentication/Bouncer";
import {
    canSuggestOrCreateHappenings,
    canViewHappenings,
    canViewPartnerSuggestionHappeningsTab,
} from "../authentication/Permissions";
import ParticipantHappeningsContainer from "../components/AllHappenings/ParticipantHappeningsContainer";
import MyHappenings from "../components/AllHappenings/MyHappeningsContainer";
import InternalHappenings from "../components/AllHappenings/InternalHappeningsContainer";
import PartnerSuggestionsContainer from "../components/AllHappenings/PartnerSuggestionsContainer";
import CreateHappeningContainer from "../components/Forms/Happening/CreateHappeningContainer";
import UpdateHappeningContainer from "../components/Forms/Happening/UpdateHappeningContainer";
import ViewHappeningContainer from "../components/Forms/ViewHappening/ViewHappeningContainer";
import CancelledHappenings from "../components/AllHappenings/CancelledHappeningsContainer";
import ArchivedHappenings from "../components/AllHappenings/ArchivedHappeningsContainer";
import strings from "../localization/strings";
import { NavigationTab } from "../types/NavigationTab";
import { createExternalHappeningLink, createHappeningLink, createPrivateHappeningLink } from "../utils.ts/Urls";
import useTabRoute from "./UseTabRoute";
import { HappeningType } from "../openapi/backend";
import { AttachmentsContextProvider } from "../contexts/AttachmentsContext";

const happeningTabs: NavigationTab[] = [
    { pathname: "participant", label: strings.participantActivities, element: <ParticipantHappeningsContainer /> },
    { pathname: "internal", label: strings.internalHappenings, element: <InternalHappenings /> },
    { pathname: "my", label: strings.myHappenings, element: <MyHappenings /> },
    {
        pathname: "suggestions",
        label: strings.partnerSuggestions,
        element: <PartnerSuggestionsContainer />,
        permissions: [canViewPartnerSuggestionHappeningsTab],
    },
    { pathname: "canceled", label: strings.cancelled, element: <CancelledHappenings /> },
    { pathname: "archived", label: strings.archived, element: <ArchivedHappenings /> },
];

const useHappeningsRoute = () => (
    <>
        {/* Separate route outside of tab structure */}
        <Route
            path={createHappeningLink}
            element={
                <Bouncer
                    permissions={[canSuggestOrCreateHappenings]}
                    element={<CreateHappeningContainer type={HappeningType.Happening} />}
                />
            }
        />
        <Route
            path={createPrivateHappeningLink}
            element={
                <Bouncer
                    permissions={[canSuggestOrCreateHappenings]}
                    element={<CreateHappeningContainer type={HappeningType.PrivateHappening} />}
                />
            }
        />

        <Route
            element={
                <Bouncer
                    permissions={[canViewHappenings]}
                    element={
                        <AttachmentsContextProvider>
                            <ViewHappeningContainer />
                        </AttachmentsContextProvider>
                    }
                />
            }
            path="activities/:happeningId"
        />

        <Route
            path={createExternalHappeningLink}
            element={
                <Bouncer
                    permissions={[canSuggestOrCreateHappenings]}
                    element={<CreateHappeningContainer type={HappeningType.ExternalHappening} />}
                />
            }
        />

        <Route
            element={<Bouncer permissions={[canViewHappenings]} element={<UpdateHappeningContainer />} />}
            path="activities/:happeningId/update"
        />

        {useTabRoute("activities", happeningTabs, [canViewHappenings])}
    </>
);

export default useHappeningsRoute;
