import { useMemo } from "react";
import styles from "./MapMarker.module.scss";
import strings from "../../../localization/strings";
import LocationMarker from "../../../assets/location_marker.svg";
import { FoundLocation } from "../../../hooks/UseLocationSearch";

export interface MarkerLocation extends FoundLocation {
    name?: string;
}

const MapMarker = ({ name, address }: MarkerLocation) => {
    const addressNodes = useMemo(
        () => (address ? address.split(",").map((value, i) => <p key={i}>{value}</p>) : []),
        [address],
    );

    return (
        <div className={styles.marker}>
            <img src={LocationMarker} alt={strings.marker} />
            <div className={styles.popup}>
                <div className={styles.name}>{name}</div>
                <div className={styles.address}>{addressNodes}</div>
            </div>
        </div>
    );
};

export default MapMarker;
