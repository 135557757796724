import { ReactNode, useCallback, useMemo } from "react";
import AccountsTable from "./AccountsTable";
import { useDownloadParticipants, UserFilter, useUsers } from "../../hooks/UserHooks";
import { Role, SortOrder, TagType, UserOrderBy } from "../../openapi/backend";
import strings from "../../localization/strings";
import { Column } from "react-table";
import moment from "moment";
import { ProfileModal } from "../Modals/ProfileModal";
import { FilterSpecs, PageNumberFilter, StringFilter, useFilters } from "../../hooks/UseSearchParam";
import { ParticipantFilterBar } from "../core/FilterBar/UserFilterBars";
import { NavigationTab } from "../../types/NavigationTab";
import { ParticipantAccountsHeader } from "./AccountsHeader";
import { getName } from "../../utils.ts/GetName";

interface ParticipantItem {
    Id: string;
    Name: ReactNode;
    DateOfBirth: string;
    Municipality: string;
    PhoneNumber: string;
    Email: string;
    Subprofiles: string;
}

const filterSpecs: FilterSpecs = {
    query: new StringFilter(),
    page: new PageNumberFilter(),
};

export default function useParticipantAccountsTab(): NavigationTab {
    const [participantUsersState, getParticipantUsers] = useUsers();
    const [, downloadParticipants] = useDownloadParticipants();

    // Filter
    const [rawFilter, setFilter] = useFilters(filterSpecs);
    const filter = rawFilter as UserFilter;
    const onFilterChange = useCallback(
        (filter: UserFilter) => {
            setFilter({ ...filter, page: 1 });
        },
        [setFilter],
    );

    const setPage = useCallback(
        (p: number) => {
            setFilter({ ...filter, page: p });
        },
        [filter, setFilter],
    );

    // Header
    const callbackDownload = useCallback(() => downloadParticipants(filter), [filter, downloadParticipants]);

    const header = <ParticipantAccountsHeader downloadUsers={callbackDownload} />;

    // Element
    const filterBar = <ParticipantFilterBar onChange={onFilterChange} value={filter} />;

    const columns: Column<ParticipantItem>[] = [
        {
            Header: strings.name,
            accessor: "Name",
            width: "20%",
        },
        {
            Header: strings.subprofiles,
            accessor: "Subprofiles",
            width: "20%",
            disableSortBy: true,
        },
        {
            Header: strings.age,
            accessor: "DateOfBirth",
            width: "15%",
        },
        {
            Header: strings.municipality,
            accessor: "Municipality",
            width: "15%",
        },
        {
            Header: strings.email,
            accessor: "Email",
            width: "15%",
        },
        {
            Header: strings.phone,
            accessor: "PhoneNumber",
            width: "15%",
        },
    ];

    const data = useMemo<ParticipantItem[]>(() => {
        return (
            participantUsersState.value?.items.map((user) => ({
                Id: user.id,
                Name: getName(user),
                Subprofiles: user.subprofiles.map(getName).join(", "),
                DateOfBirth: user.dateOfBirth
                    ? `${moment().diff(moment(user.dateOfBirth), "years").toLocaleString()} ${strings.years}`
                    : strings.unknownDateOfBirth,
                PhoneNumber: user.phoneNumber ?? strings.unknownPhoneNumber,
                Email: user.email ?? strings.unknownEmail,
                Municipality:
                    user.tags.filter((t) => t.type === TagType.Municipality).length > 0
                        ? user.tags
                              .filter((t) => t.type === TagType.Municipality)
                              .map((t) => t.name)
                              .join(", ")
                        : strings.unknownMunicipality,
            })) ?? []
        );
    }, [participantUsersState]);

    const callbackUsers = useCallback(
        (orderBy: UserOrderBy, sortOrder: SortOrder, itemsPerPage: number) =>
            getParticipantUsers(orderBy, sortOrder, itemsPerPage, [Role.Participant], filter),
        [getParticipantUsers, filter],
    );

    const element = (
        <AccountsTable
            columns={columns}
            data={data}
            meta={participantUsersState.value?.meta}
            page={filter.page}
            setPage={setPage}
            getUsers={callbackUsers}
            error={participantUsersState.error}
            filterBar={filterBar}
            AccountModal={ProfileModal}
        />
    );

    return {
        pathname: "participants",
        label: strings.participants,
        element,
        header,
    };
}
