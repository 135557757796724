import cx from "classnames";
import { useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ImagePlaceholder from "../../../assets/SBG_placeholder.png";
import { CLUSTER_KEY } from "../../../constants/Route";
import { LocalHappeningContext } from "../../../contexts/LocalHappeningContext";
import strings from "../../../localization/strings";
import { ClusterViewModel, HappeningState } from "../../../openapi/backend";
import { toastSuccess } from "../../../utils.ts/Toaster";
import {
    createHappeningLink,
    createPrivateHappeningLink,
    getDynamicClusterAppUrl,
    getHappeningLink,
} from "../../../utils.ts/Urls";
import ChangeClusterNameModalContainer from "../../Forms/ViewHappening/ChangeClusterNameModal";
import ChangeClusterOrganizerModalContainer from "../../Forms/ViewHappening/ChangeClusterOrganizerModal";
import GroupIcon from "../AvatarIcon/GroupIcon";
import FloatingMenuButton from "../FloatingMenuButton/FloatingMenuButton";
import { ClusterMediaLibraryModalContainer } from "../MediaLibrary/ClusterMediaLibraryModalContainer";
import SimpleTextButton from "../SimpleTextButton/SimpleTextButton";
import styles from "./ClusterHeader.module.scss";

interface Props {
    className?: string;
    cluster: ClusterViewModel;
    happeningId: string;
}

const links = [
    {
        id: "linkToNewClusterHappening",
        label: strings.createNormalHappening,
        path: createHappeningLink,
    },
    {
        id: "linkToNewClusterPrivateHappening",
        label: strings.createPrivateHappening,
        path: createPrivateHappeningLink,
    },
];

interface StatusTabProps {
    id: string;
    title: string;
    state: HappeningState;
}
const StatusTab = ({ id, title, state }: StatusTabProps) => {
    const getStatusStyle = () => {
        switch (state) {
            case HappeningState.Open:
                return styles.open;
            case HappeningState.Concept:
                return styles.concept;
            case HappeningState.PartnerSuggestion:
                return styles.suggestion;
            case HappeningState.Archived:
                return styles.archived;
            case HappeningState.Cancelled:
                return styles.cancelled;
            case HappeningState.PlannedForPublication:
                return styles.plannedForPublication;
            default:
                return styles.open;
        }
    };

    return (
        <div id={state} className={styles.happeningLabel}>
            <div className={`${styles.statusIcon} ${getStatusStyle()}`}></div>
            <label className={styles.title} htmlFor={id}>
                {title}
            </label>
        </div>
    );
};

export default function ClusterHeader({
    className,
    cluster: { id: clusterId, happenings, name, cover, organizer },
    happeningId,
}: Props) {
    const { refresh } = useContext(LocalHappeningContext);
    const [updateNameFormOpen, setUpdateNameFormOpen] = useState(false);
    const [updateCoverFormOpen, setUpdateCoverFormOpen] = useState(false);
    const [updateOrganizerFormOpen, setUpdateOrganizerFormOpen] = useState(false);
    const options = useMemo(
        () =>
            links.map(({ id, label, path }) => (
                <Link
                    key={id}
                    id={id}
                    to={{ pathname: path, search: new URLSearchParams({ [CLUSTER_KEY]: clusterId }).toString() }}
                >
                    <label htmlFor={id} className={styles.linkLabel}>
                        {label}
                    </label>
                </Link>
            )),
        [clusterId],
    );

    const onSuccess = useCallback(() => {
        refresh();
        setUpdateNameFormOpen(false);
        setUpdateCoverFormOpen(false);
        setUpdateOrganizerFormOpen(false);
    }, [refresh]);

    const copyClusterLink = useCallback(async () => {
        var link = getDynamicClusterAppUrl(clusterId);
        await navigator.clipboard.writeText(link);
        toastSuccess(strings.confirmCopyLink);
    }, [clusterId]);

    return (
        <div className={cx(styles.container, className)}>
            <div className={styles.cluster}>
                <div className={styles.name}>
                    <h1 className={styles.label}>{name}</h1>
                    <SimpleTextButton
                        text={strings.clusterChangeName}
                        className={styles.changeButton}
                        onClick={() => setUpdateNameFormOpen(true)}
                        type="tertiary"
                    />
                </div>
                <div className={styles.cover}>
                    <img
                        className={styles.image}
                        src={cover?.href ?? ImagePlaceholder}
                        alt={cover?.altText ?? strings.clusterAltCoverDefaultText}
                    />
                    <SimpleTextButton
                        text={strings.clusterChangeCover}
                        className={styles.changeButton}
                        onClick={() => setUpdateCoverFormOpen(true)}
                        type="tertiary"
                    />
                </div>
                <div className={styles.organisation}>
                    {organizer ? (
                        <GroupIcon group={organizer} />
                    ) : (
                        <img className={styles.image} src={ImagePlaceholder} alt={strings.clusterAltCoverDefaultText} />
                    )}
                    <SimpleTextButton
                        text={strings.clusterChangeOrganizer}
                        className={styles.changeButton}
                        onClick={() => setUpdateOrganizerFormOpen(true)}
                        type="tertiary"
                    />
                </div>
                <div className={styles.action}>
                    <SimpleTextButton
                        text={strings.clusterShare}
                        className={styles.changeButton}
                        onClick={copyClusterLink}
                        type="tertiary"
                    />
                </div>
            </div>
            <div className={styles.tabs}>
                {happenings.map((h) => (
                    <Link
                        key={h.id}
                        className={cx(styles.tabItem, h.id === happeningId && styles.active)}
                        to={getHappeningLink(h.id!)}
                        tabIndex={h.id === happeningId ? -1 : undefined}
                        title={h.title}
                    >
                        <StatusTab id={h.id!} title={h.title!} state={h.displayState!} />
                    </Link>
                ))}
                <FloatingMenuButton options={options} className={cx(styles.addButton)}>
                    <SimpleTextButton text={strings.clusterAddHappening} type="tertiary" />
                </FloatingMenuButton>
            </div>
            {updateNameFormOpen && (
                <ChangeClusterNameModalContainer
                    isOpen={updateNameFormOpen}
                    onDismiss={() => setUpdateNameFormOpen(false)}
                    onSuccess={onSuccess}
                    clusterId={clusterId}
                    clusterName={name}
                />
            )}
            {updateCoverFormOpen && (
                <ClusterMediaLibraryModalContainer
                    onClose={() => setUpdateCoverFormOpen(false)}
                    onSuccess={onSuccess}
                    clusterId={clusterId}
                    currentSelectedImage={cover}
                />
            )}
            {updateOrganizerFormOpen && (
                <ChangeClusterOrganizerModalContainer
                    isOpen={updateOrganizerFormOpen}
                    onDismiss={() => setUpdateOrganizerFormOpen(false)}
                    onSuccess={onSuccess}
                    clusterId={clusterId}
                    organizer={organizer}
                />
            )}
        </div>
    );
}
