import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import styles from "./FileDeleteConfirmationModal.module.scss";

interface FileDeleteConfirmationModalProps {
    message: string;
    show: boolean;
    onClose: () => void;
    onDelete: () => void;
}

export default function FileDeleteConfirmationModal({
    message,
    show,
    onClose,
    onDelete,
}: FileDeleteConfirmationModalProps) {
    return (
        <ConfirmationModal isOpen={show} onCancel={onClose} onConfirm={onDelete}>
            <div className={styles.container}>
                <div>{message}</div>
            </div>
        </ConfirmationModal>
    );
}
