import { yupResolver } from "@hookform/resolvers/yup";
import cx from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { MEDIA_LIBRARY_MY_CATEGORY_ID } from "../../../constants/MediaLibraryConstants";
import strings from "../../../localization/strings";
import { ImageViewModel, MediaCategoryViewModel } from "../../../openapi/backend";
import { MediaLibraryInput, MediaLibraryInputProps, MediaLibraryModalProps } from "../../../types/MediaLibraryType";
import { toastError } from "../../../utils.ts/Toaster";
import Form from "../Form/Form/Form";
import FormListVideo from "../Form/FormListVideo/FormListVideo";
import MediaLibraryImageUploadInputContainer from "../ImageUploadInput/MediaLibraryImageUploadInputContainer";
import SearchInput from "../Inputs/SearchInput/SearchInput";
import MediaCategoryListItem from "../MediaCategoryListItem/MediaCategoryListItem";
import styles from "./MediaLibrary.module.scss";
import { validationSchema } from "./MediaLibraryValidation";

export type Props = MediaLibraryInputProps &
    MediaLibraryModalProps & {
        libraryContent: MediaCategoryViewModel[];
        query: string;
        setQuery: (query: string) => void;
        onRemoveFromMy: (media: ImageViewModel) => void;
    };

export const MediaLibrary = ({
    libraryContent,
    images,
    videos,
    query,
    setQuery,
    onClose,
    onConfirm,
    onRemoveFromMy,
    excludeVideos,
    type,
    isWaitingForResponse,
    minImages = 0,
    single,
}: Props) => {
    const [selectedImages, setSelectedImages] = useState(images);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<MediaLibraryInput>({
        defaultValues: { videos },
        resolver: yupResolver(validationSchema) as any,
    });

    const onAdd = useCallback(
        (formData: MediaLibraryInput) => {
            if (selectedImages.length < minImages) {
                toastError(
                    strings.formatString(
                        minImages === 1
                            ? strings.mediaLibraryMinImagesErrorSingle
                            : strings.mediaLibraryMinImagesErrorPlural,
                        minImages,
                    ) as string,
                );
                return;
            }

            const keepOpen = onConfirm(
                selectedImages,
                formData.videos?.filter((v) => v.href.trim() && v.altText.trim()),
            );

            if (keepOpen) {
                return;
            }

            onClose();
        },
        [onConfirm, onClose, selectedImages, minImages],
    );

    const onMediaClick = useCallback(
        (media: ImageViewModel) => {
            setSelectedImages((current) => {
                const selectedIndex = current.findIndex((i) => i.id === media.id);
                if (selectedIndex !== -1) {
                    return current.filter((i) => i.id !== media.id);
                } else if (single) {
                    return [media];
                } else {
                    return [...current, media];
                }
            });
        },
        [single],
    );

    const generalDescriptionLabel = useMemo(() => {
        switch (type) {
            case "happening":
                return strings.mediaLibraryModalHappeningDescription;
            case "organisationInfo":
                return strings.mediaLibraryModalOrganisationInfoDescription;
            case "cluster":
                return strings.mediaLibraryModalClusterDescription;
        }
    }, [type]);

    const submitLabel = useMemo(() => {
        switch (type) {
            case "happening":
                return strings.addToHappening;
            case "organisationInfo":
                return strings.addToOrganisationInfo;
            case "cluster":
                return strings.addToCluster;
        }
    }, [type]);

    return (
        <Form
            className={styles.container}
            onSubmit={onAdd}
            handleSubmit={handleSubmit}
            onDismiss={onClose}
            submitText={submitLabel}
            waitingForSubmit={isWaitingForResponse}
        >
            <p className={styles.description}>{generalDescriptionLabel}</p>
            <div className={styles.addContainer}>
                <div className={styles.uploadImageContainer}>
                    <h2 className={styles.addHeader}>{strings.mediaLibraryAddImageHeader}</h2>
                    <MediaLibraryImageUploadInputContainer />
                </div>
                {!excludeVideos && (
                    <div className={styles.addVideoContainer}>
                        <h2 className={styles.addHeader}>{strings.mediaLibraryAddVideoHeader}</h2>
                        <FormListVideo
                            name={"videos" as any}
                            errors={errors.videos}
                            register={register}
                            control={control}
                        />
                    </div>
                )}
            </div>
            <div className={cx(styles.separator, styles.dashed)} />
            <div className={styles.searchContainer}>
                <SearchInput
                    className={styles.searchInput}
                    value={query}
                    onSearch={setQuery}
                    placeholder={strings.searchMediaPlaceholder}
                    autoFocus={true}
                    activateOnBlur={true}
                />
            </div>
            {query && (
                <>
                    <h2 className={styles.searchResultsHeader}>
                        {strings.formatString(strings.searchMediaResultsHeader, query)}
                    </h2>
                    <div className={cx(styles.separator, styles.dashed)} />
                </>
            )}
            <div className={cx(styles.categoryContainer)}>
                {libraryContent.map((category, index) => {
                    const isUploadedByMe = category.id === MEDIA_LIBRARY_MY_CATEGORY_ID;
                    return (
                        <MediaCategoryListItem
                            key={category.id}
                            className={styles.category}
                            category={category}
                            selectedMedia={selectedImages}
                            onMediaClick={onMediaClick}
                            onDeleteClick={isUploadedByMe ? onRemoveFromMy : undefined}
                            defaultExpanded={index === 0}
                            type={single ? "single" : "multiple"}
                        />
                    );
                })}
            </div>
        </Form>
    );
};
