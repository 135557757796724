import { ComponentType, useCallback, useState } from "react";
import strings from "../../localization/strings";
import Modal from "../core/Modal/Modal";
import ProfileParticipantContainer from "../Profile/ProfileContainer";
import { AccountModalProps } from "./EditAccountModal";

export interface ProfileModalProps extends AccountModalProps {
    onSelectProfile?: (id: string) => void;
}

export const ProfileModal = ({ selectedUserId, onSelectProfile, onClose }: ProfileModalProps) => (
    <Modal isOpen={selectedUserId !== undefined} title={strings.editAccount} onClose={onClose}>
        <ProfileParticipantContainer id={selectedUserId!} onSelectProfile={onSelectProfile!} onDismiss={onClose} />
    </Modal>
);

export interface WithProfileModalProps {
    onProfileSelect: (id: string) => void;
}

export const withProfileModal =
    <T extends object>(WrappedComponent: ComponentType<T & WithProfileModalProps>) =>
    (props: T) => {
        const [selectedUser, setSelectedUser] = useState<string>();
        const handleClose = useCallback(() => setSelectedUser(undefined), []);
        return (
            <>
                <WrappedComponent {...props} onProfileSelect={setSelectedUser} />
                <ProfileModal selectedUserId={selectedUser} onSelectProfile={setSelectedUser} onClose={handleClose} />
            </>
        );
    };
