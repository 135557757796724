import { useCallback } from "react";
import DataListInput, { DataListInputProps } from "../DataListInput/DataListInput";
import MultiField, { FieldProps } from "../MultiField/MultiField";

export interface MultiDataListInputProps extends Omit<DataListInputProps, "value" | "onChange"> {
    value?: string[];
    onChange: (selected: string[]) => void;
}

const MutliDataListInput = ({ value, onChange, options, name, list, ...props }: MultiDataListInputProps) => {
    const Field = useCallback(
        ({ index, fieldValue, listValue, onChange }: FieldProps<string>) => (
            <DataListInput
                {...props}
                name={`${name}.${index}`}
                list={`${list}-${index}`}
                onChange={onChange}
                value={fieldValue}
                options={options.filter((x) => x.value === fieldValue || !listValue.includes(x.value))}
            />
        ),
        [list, name, options, props],
    );

    return <MultiField Field={Field} value={value} onChange={onChange} readOnly={props.readOnly} />;
};

export default MutliDataListInput;
