import strings from "../localization/strings";
import { getStringForNotificationType } from "./GetNotificationType";
import { toastError, toastSuccess } from "./Toaster";

export function showSuccess(GetNotificationType: string) {
    toastSuccess(strings.formatString(strings.sent, getStringForNotificationType(GetNotificationType)) as string);
}

export function showError(GetNotificationType: string) {
    toastError(strings.formatString(strings.sentError, getStringForNotificationType(GetNotificationType)) as string);
}
