import React from "react";
import strings from "../../../localization/strings";
import { ImageViewModel } from "../../../openapi/backend";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import styles from "./ConfirmSoftDeleteMediaModal.module.scss";

interface Props {
    target: ImageViewModel | undefined;
    onCancel: () => void;
    onConfirm: () => void;
    message?: string;
}

const ConfirmSoftDeleteMediaModal = ({ onCancel, onConfirm, target, message }: Props) => {
    if (!target) {
        return null;
    }

    return (
        <ConfirmationModal isOpen onConfirm={onConfirm} onCancel={onCancel}>
            <p className={styles.text}>{message || strings.confirmDeleteHappeningImage}</p>
            <img className={styles.image} src={target.href} alt={target.altText} />
        </ConfirmationModal>
    );
};

export default ConfirmSoftDeleteMediaModal;
