import { PropsWithChildren } from "react";
import strings from "../../../localization/strings";
import Form from "../../core/Form/Form/Form";
import Modal from "../../core/Modal/Modal";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
    error?: Response;
    errorMessage?: string;
    confirmText?: string;
}

const ConfirmationModal = ({
    isOpen,
    onCancel,
    onConfirm,
    children,
    isLoading,
    error,
    confirmText = strings.yes,
    errorMessage = strings.somethingWentWrong,
}: PropsWithChildren<Props>) => {
    return (
        <Modal isOpen={isOpen} onDismiss={onCancel} title={strings.confirmation}>
            <Form
                onSubmit={onConfirm}
                onDismiss={onCancel}
                submitText={confirmText}
                cancelText={strings.no}
                waitingForSubmit={isLoading}
            >
                {children}
            </Form>
            {error && <ErrorMessage error={error} defaultMessage={errorMessage} isToast />}
        </Modal>
    );
};

export default ConfirmationModal;
