import React, { PropsWithChildren } from "react";
import { Configuration, IndicatorsApi } from "../openapi/backend";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<IndicatorsApi> = (params) => new IndicatorsApi(new Configuration(params));

export const IndicatorsApiContext = React.createContext(createApi({}));

export const IndicatorsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={IndicatorsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
