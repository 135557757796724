import * as yup from "yup";
import { MAX_IMAGE_SIZE } from "../../../constants/Validation";
import { FormImageInputModel } from "../../../types/MediaLibraryType";
import { isAllowedImageFileType } from "../../../utils.ts/Media";

export const validationSchema: yup.SchemaOf<FormImageInputModel, FileList> = yup.object({
    altText: yup.string().required(),
    fileList: yup
        .mixed<FileList>()
        .required()
        .test("required", (fileList) => {
            return fileList?.length === 1;
        })
        .test("InvalidImageFile", (file) => {
            return isAllowedImageFileType(file?.item(0));
        })
        .test("FileSize", (file) => {
            const imageFile = file?.item(0);
            return !imageFile?.size || imageFile?.size < MAX_IMAGE_SIZE;
        }),
});
