/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum HappeningNotificationGroup {
    Registered = "Registered",
    StandBy = "StandBy",
    Favorited = "Favorited",
}

export function HappeningNotificationGroupFromJSON(json: any): HappeningNotificationGroup {
    return HappeningNotificationGroupFromJSONTyped(json, false);
}

export function HappeningNotificationGroupFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): HappeningNotificationGroup {
    return json as HappeningNotificationGroup;
}

export function HappeningNotificationGroupToJSON(value?: HappeningNotificationGroup | null): any {
    return value as any;
}
