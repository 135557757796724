import { OrganizerViewModel, HappeningState, HappeningTimeAndDateViewModel } from "../../../openapi/backend";
import styles from "./AgendaItem.module.scss";
import moment from "moment";
import strings from "../../../localization/strings";
import "moment/min/locales.min"; //Importing locales
import { DEFAULT_COLOR } from "../../../constants/Defaults";
import { CancelButton } from "../RoundButton/RoundButton";
import { canCancelSession } from "../../../utils.ts/Happening";
import StateLabel from "../StateLabel/StateLabel";

interface AgendaItemProps {
    date: HappeningTimeAndDateViewModel;
    happeningState: HappeningState;
    onCancelDate: () => void;
    group?: OrganizerViewModel;
    className?: string;
    preventCancel?: boolean;
}

const DateDisplay = ({ date }: { date: Date }) => {
    const formattedDate = `${moment(date).format("LL")}`;
    const day = `${moment(date).format("dddd").charAt(0).toUpperCase() + moment(date).format("dddd").slice(1)}`;

    return (
        <div className={styles.dateDescription}>
            <div>{formattedDate}</div>
            <div className={styles.dayDescription}>{day}</div>
        </div>
    );
};

export default function AgendaItem({
    date,
    group,
    className,
    onCancelDate,
    happeningState,
    preventCancel,
}: AgendaItemProps) {
    //Set locale
    moment.locale(strings.getLanguage());

    return (
        <div className={`${styles.agendaItemContainer} ${className}`}>
            <div className={styles.timeContainer} style={{ borderColor: group?.color ?? DEFAULT_COLOR }}>
                <div>{`${moment(date.sessionStart).format("HH:mm")}`}</div>
                <div>{`${moment(date.sessionEnd).format("HH:mm")}`}</div>
            </div>

            <div className={styles.descriptionContainer}>
                <DateDisplay date={date.sessionStart} />
                {!preventCancel && happeningState !== HappeningState.Cancelled && canCancelSession(date) && (
                    <CancelButton onClick={onCancelDate} />
                )}
                {happeningState === HappeningState.Cancelled ||
                    (date.cancelled && <StateLabel state={HappeningState.Cancelled} />)}
            </div>
        </div>
    );
}
