import strings from "../localization/strings";
import { Gender } from "../openapi/backend";

export function getStringForNotificationGender(type: string) {
    switch (type) {
        case Gender.Male:
            return strings.male;
        case Gender.Female:
            return strings.female;
        case Gender.Other:
            return strings.other;
        default:
            return strings.ratherNotSay;
    }
}
