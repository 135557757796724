import { useContext, useCallback } from "react";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { useApiCallback } from "./UseApiCall";
import { FilesApiContext } from "../contexts/FilesApiContext";
import fileDownload from "js-file-download";

export const useDownloadFile = () => {
    const api = useContext(FilesApiContext);
    const callback = useCallback(
        async (fileId: number, fileName: string, exportFile: boolean) => {
            const fileData = await api.download({
                version: REACT_APP_BACKEND_VERSION,
                id: fileId,
            });
            if (exportFile) {
                fileDownload(fileData, fileName);
            }
            return new File([fileData], fileName, { type: fileData.type, lastModified: Date.now() });
        },
        [api],
    );

    return useApiCallback(callback);
};
