import { useForm } from "react-hook-form";
import { useRejectSuggestion } from "../../../hooks/HappeningsHooks";
import { RejectHappeningRequest } from "../../../openapi/backend";
import Form from "../../core/Form/Form/Form";
import Modal from "../../core/Modal/Modal";
import { useCallback, useEffect } from "react";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";

interface RejectSuggestionProps {
    onDismiss: () => void;
    onSuccess: () => void;
    happeningId: string;
}

type RejectSuggestionForm = Omit<RejectHappeningRequest, "version" | "id">;

export default function RejectSuggestionModal({ onDismiss, onSuccess, happeningId }: RejectSuggestionProps) {
    const [state, rejectSuggestion] = useRejectSuggestion(happeningId);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RejectSuggestionForm>();

    const onSubmit = useCallback(
        (data: RejectSuggestionForm) => {
            rejectSuggestion(data.reason);
        },
        [rejectSuggestion],
    );

    useEffect(() => {
        if (state.state === RequestState.DONE) {
            onSuccess();
            onDismiss();
        }
    }, [onSuccess, onDismiss, state]);

    return (
        <Modal isOpen onDismiss={onDismiss} title={strings.rejectSuggestionTitle}>
            <Form
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onDismiss={onDismiss}
                error={state.error}
                waitingForSubmit={state.state === RequestState.LOADING}
                submitText={strings.send}
            >
                <FormFieldMultiline
                    rows={4}
                    name={"reason"}
                    label={strings.rejectReason}
                    register={register}
                    errors={errors.reason}
                    required
                />
            </Form>
        </Modal>
    );
}
