import React, { PropsWithChildren } from "react";
import { Configuration } from "../openapi/backend";
import { ProfilesApi } from "../openapi/backend/apis/ProfilesApi";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<ProfilesApi> = (params) => new ProfilesApi(new Configuration(params));

export const ProfilesApiContext = React.createContext(createApi({}));

export const ProfilesApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={ProfilesApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
