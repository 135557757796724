import moment from "moment";
import * as yup from "yup";
import {
    emailPattern,
    MAX_PARTICIPANT_AGE,
    MIN_PRICE_VALUE,
    namePattern,
    phoneNumber,
    postalCodePattern,
    pricePattern,
} from "../../../constants/Validation";
import strings from "../../../localization/strings";
import {
    JscApplicationInput,
    Gender,
    FormType,
    KeiEducationType,
    KeiComputerApplicationInput,
    KeiParticipasApplicationInput,
    KeiBudgetType,
    KeiSchoolTripApplicationInput,
} from "../../../openapi/backend";
import { safeNumber } from "../../../utils.ts/Validation";

export const validationSchemaGeneric = {
    type: yup.mixed().oneOf(Object.values(FormType)),
    givenName: yup.string().required().matches(namePattern, strings.invalidName),
    familyName: yup.string().required().matches(namePattern, strings.invalidName),
    street: yup.string().required(),
    houseNumber: yup.string().required(),
    postalCode: yup.string().required().matches(postalCodePattern, strings.invalidPostalCode),
    city: yup.string().required(),
    gender: yup.mixed().required().oneOf(Object.values(Gender)),
    dateOfBirth: yup.date().required().min(moment().subtract(MAX_PARTICIPANT_AGE, "years").toDate()).max(new Date()),
    email: yup.string().required().matches(emailPattern, strings.invalidEmail),
    phoneNumber: yup.string().required().matches(phoneNumber, strings.invalidPhoneNumber),
};

export const validationSchema: yup.SchemaOf<JscApplicationInput> = yup.object({
    ...validationSchemaGeneric,
    socialAssistance: yup.string().required(),
    reasonOfDeficiency: yup.string().required(),
    cityPassName: yup.string().when("hasCityPass", { is: true, then: (schema) => schema.required() }),
    cityPassNumber: yup.string().when("hasCityPass", { is: true, then: (schema) => schema.required() }),
    sportsClubName: yup.string().required(),
    activityCity: yup.string().required(),
    intendedActivity: yup.string().required(),
    membershipFee: safeNumber
        .required()
        .min(MIN_PRICE_VALUE)
        .test("pattern", strings.stepError, (val?: number) => pricePattern.test(val?.toString() || "")),
    membershipStartDate: yup.date().required(),
    membershipEndDate: yup
        .date()
        .required()
        .test(
            "pattern",
            strings.dateBeforeStart,
            (val: any, context: any) => context.parent.membershipStartDate <= val,
        ),
    additionalRequirements: yup.string(),
    requirementsPrice: safeNumber.min(0.0).test("pattern", strings.stepError, (val?: number) => {
        return pricePattern.test(val?.toString() || "") || val === undefined;
    }),
    requirementsShop: yup.string(),
    requirementsCity: yup.string(),
    supplement: yup.string(),
    reviewerId: yup.string(),
    subjectId: yup.string(),
    hasCityPass: yup.bool().required(),
});

export const validationSchemaKei = {
    ...validationSchemaGeneric,
    supplement: yup.string(),
    reviewerId: yup.string(),
    subjectId: yup.string(),
    grade: yup.number().required(),
    childSchool: yup.string().required(),
    childEducationType: yup.mixed().oneOf(Object.values(KeiEducationType)),
    childDateOfBirth: yup
        .date()
        .required()
        .min(moment().subtract(MAX_PARTICIPANT_AGE, "years").toDate())
        .max(new Date()),
    passNumber: yup.string().required(),
    childGivenName: yup.string().required().matches(namePattern, strings.invalidName),
    childFamilyName: yup.string().required().matches(namePattern, strings.invalidName),
    childGender: yup.mixed().required().oneOf(Object.values(Gender)),
};

export const validationSchemaKeiGeneric = yup.object({
    ...validationSchemaKei,
});

export const validationSchemaKeiComputer: yup.SchemaOf<Omit<KeiComputerApplicationInput, "fileId">> = yup.object({
    ...validationSchemaKei,
    computerDescription: yup.string().required(),
    isPurchase: yup.bool().required(),
    price: safeNumber
        .required()
        .min(MIN_PRICE_VALUE)
        .test("pattern", strings.stepError, (val?: number) => pricePattern.test(val?.toString() || "")),
});

export const validationSchemaKeiParticipas: yup.SchemaOf<KeiParticipasApplicationInput> = yup.object({
    ...validationSchemaKei,
    participasNumber: yup.string(),
    budgetRequests: yup.array(yup.mixed().oneOf(Object.values(KeiBudgetType))).required(),
});

export const validationSchemaKeiSchoolTrip: yup.SchemaOf<Omit<KeiSchoolTripApplicationInput, "fileId">> = yup.object({
    ...validationSchemaKei,
    tripDescription: yup.string().required(),
    price: safeNumber
        .required()
        .min(MIN_PRICE_VALUE)
        .test("pattern", strings.stepError, (val?: number) => pricePattern.test(val?.toString() || "")),
});
