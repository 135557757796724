import styles from "./ApplicationForm.module.scss";
import strings from "../../../localization/strings";
import FormField from "../../core/Form/FormField/FormField";
import moment from "moment";
import FormDatepicker from "../../core/Form/FormDatePicker/FormDatePicker";
import { Control, FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import {
    emailPattern,
    MAX_PARTICIPANT_AGE,
    namePattern,
    phoneNumber,
    postalCodePattern,
} from "../../../constants/Validation";
import { FormStringSelect } from "../../core/Form/FormSelect/FormSelect";
import { getGenderOptions } from "../../../utils.ts/StateOptions";
import { useMemo } from "react";
import { getStringForGender } from "../../../utils.ts/GetGenderString";

interface RenderScApplicationFieldsProps {
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
    control: Control<any, object>;
    watch: UseFormWatch<any>;
}

const genderOptions = getGenderOptions();

export default function GenericApplicationFields({ register, errors, control, watch }: RenderScApplicationFieldsProps) {
    const localizedGenderOptions = useMemo(
        () => genderOptions.map((o) => ({ value: o.value, label: getStringForGender(o.value) })),
        [],
    );

    const dateOfBirth = watch("dateOfBirth");

    return (
        <>
            <div className={styles.section}>
                <h2 className={styles.sectionHeader}>{strings.applicationFormHeaderPersonal}</h2>
                <div className={styles.row}>
                    <FormField
                        name="givenName"
                        label={strings.applicationFormGivenName}
                        register={register}
                        errors={errors.givenName}
                        required
                        placeholder=""
                        validationPattern={{ value: namePattern, message: strings.invalidName }}
                    />
                    <FormField
                        name="familyName"
                        label={strings.applicationFormFamilyName}
                        register={register}
                        errors={errors.familyName}
                        required
                        placeholder=""
                        validationPattern={{ value: namePattern, message: strings.invalidName }}
                    />
                </div>
                <div className={styles.row}>
                    <FormField
                        name="street"
                        label={strings.applicationFormStreet}
                        register={register}
                        errors={errors.street}
                        required
                        placeholder=""
                    />
                    <FormField
                        name="houseNumber"
                        label={strings.applicationFormHouseNumber}
                        register={register}
                        errors={errors.houseNumber}
                        required
                        placeholder=""
                    />
                    <FormField
                        name="postalCode"
                        label={strings.applicationFormPostalCode}
                        register={register}
                        errors={errors.postalCode}
                        required
                        placeholder=""
                        validationPattern={{ value: postalCodePattern, message: strings.invalidPostalCode }}
                    />
                    <FormField
                        name="city"
                        label={strings.applicationFormCity}
                        register={register}
                        errors={errors.city}
                        required
                        placeholder=""
                    />
                </div>
                <div className={styles.row}>
                    <FormStringSelect
                        name="gender"
                        label={strings.applicationFormGender}
                        control={control}
                        errors={errors.gender}
                        required
                        options={localizedGenderOptions}
                        placeholder=" "
                    />
                    <FormDatepicker
                        name="dateOfBirth"
                        title={strings.applicationFormBirthdate}
                        control={control}
                        value={dateOfBirth}
                        maxDate={new Date()}
                        minDate={moment().subtract(MAX_PARTICIPANT_AGE, "years").toDate()}
                        required
                        errors={errors.dateOfBirth}
                        useUtc={true}
                    />
                    <FormField
                        name="email"
                        label={strings.email}
                        register={register}
                        errors={errors.email}
                        required
                        placeholder=""
                        validationPattern={{ value: emailPattern, message: strings.invalidEmail }}
                    />
                    <FormField
                        name="phoneNumber"
                        label={strings.phone}
                        register={register}
                        errors={errors.phoneNumber}
                        required
                        placeholder=""
                        validationPattern={{ value: phoneNumber, message: strings.invalidPhoneNumber }}
                    />
                </div>
            </div>
        </>
    );
}
