/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    HappeningNotificationGroup,
    HappeningNotificationGroupFromJSON,
    HappeningNotificationGroupFromJSONTyped,
    HappeningNotificationGroupToJSON,
    NotificationFilterType,
    NotificationFilterTypeFromJSON,
    NotificationFilterTypeFromJSONTyped,
    NotificationFilterTypeToJSON,
    NotificationGroup,
    NotificationGroupFromJSON,
    NotificationGroupFromJSONTyped,
    NotificationGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface NotificationFilterViewModel
 */
export interface NotificationFilterViewModel {
    /**
     *
     * @type {NotificationFilterType}
     * @memberof NotificationFilterViewModel
     */
    type: NotificationFilterType;
    /**
     *
     * @type {string}
     * @memberof NotificationFilterViewModel
     */
    happeningId?: string;
    /**
     *
     * @type {Array<HappeningNotificationGroup>}
     * @memberof NotificationFilterViewModel
     */
    groups?: Array<HappeningNotificationGroup>;
    /**
     *
     * @type {NotificationGroup}
     * @memberof NotificationFilterViewModel
     */
    audience?: NotificationGroup;
    /**
     *
     * @type {number}
     * @memberof NotificationFilterViewModel
     */
    minAge?: number;
    /**
     *
     * @type {number}
     * @memberof NotificationFilterViewModel
     */
    maxAge?: number;
    /**
     *
     * @type {Array<Gender>}
     * @memberof NotificationFilterViewModel
     */
    genders?: Array<Gender>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    happeningTypeTags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    locationTags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    partnerTags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    happeningGroupTags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    serviceGroupTags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    targetGroupTags?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof NotificationFilterViewModel
     */
    otherTags?: Array<string>;
}

/**
 * Check if a given object implements the NotificationFilterViewModel interface.
 */
export function instanceOfNotificationFilterViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function NotificationFilterViewModelFromJSON(json: any): NotificationFilterViewModel {
    return NotificationFilterViewModelFromJSONTyped(json, false);
}

export function NotificationFilterViewModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): NotificationFilterViewModel {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, "type") ? (undefined as any) : NotificationFilterTypeFromJSON(json["type"]),
        happeningId: !exists(json, "happeningId") ? (undefined as any) : json["happeningId"],
        groups: !exists(json, "groups")
            ? (undefined as any)
            : ((json["groups"] as Array<any>) ?? []).map(HappeningNotificationGroupFromJSON),
        audience: !exists(json, "audience") ? (undefined as any) : NotificationGroupFromJSON(json["audience"]),
        minAge: !exists(json, "minAge") ? (undefined as any) : json["minAge"],
        maxAge: !exists(json, "maxAge") ? (undefined as any) : json["maxAge"],
        genders: !exists(json, "genders")
            ? (undefined as any)
            : ((json["genders"] as Array<any>) ?? []).map(GenderFromJSON),
        happeningTypeTags: !exists(json, "happeningTypeTags") ? (undefined as any) : json["happeningTypeTags"],
        locationTags: !exists(json, "locationTags") ? (undefined as any) : json["locationTags"],
        partnerTags: !exists(json, "partnerTags") ? (undefined as any) : json["partnerTags"],
        happeningGroupTags: !exists(json, "happeningGroupTags") ? (undefined as any) : json["happeningGroupTags"],
        serviceGroupTags: !exists(json, "serviceGroupTags") ? (undefined as any) : json["serviceGroupTags"],
        targetGroupTags: !exists(json, "targetGroupTags") ? (undefined as any) : json["targetGroupTags"],
        otherTags: !exists(json, "otherTags") ? (undefined as any) : json["otherTags"],
    };
}

export function NotificationFilterViewModelToJSON(value?: NotificationFilterViewModel | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
