import { useCallback, useContext } from "react";
import styles from "./FilterBar.module.scss";
import { TagsContext } from "../../../contexts/TagsContext";
import { UserFilter } from "../../../hooks/UserHooks";
import strings from "../../../localization/strings";
import MultiselectDropdown from "../Inputs/MultiselectDropdown/MultiselectDropdown";
import FilterBar, { FilterBarPropsWithValue } from "./FilterBar";
import GroupIcon from "../AvatarIcon/GroupIcon";
import { TagViewModel } from "../../../openapi/backend";
import QueryFilterInput from "./QueryFilterInput";

export type ParticipantFilter = Pick<UserFilter, "query">;

export function ParticipantFilterBar(props: FilterBarPropsWithValue<ParticipantFilter>) {
    const { value: filters, onChange } = props;

    const onReset = useCallback(() => onChange({}), [onChange]);

    return (
        <FilterBar onReset={onReset}>
            <QueryFilterInput filters={filters} onChange={onChange} />
        </FilterBar>
    );
}

export type PartnerFilter = Pick<UserFilter, "query" | "partners">;

export function PartnerFilterBar(props: FilterBarPropsWithValue<PartnerFilter>) {
    const { value: filters, onChange } = props;
    const tags = useContext(TagsContext).allTags;

    const onReset = useCallback(() => onChange({}), [onChange]);
    const onChangePartners = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, partners: filter }),
        [onChange, filters],
    );

    return (
        <FilterBar onReset={onReset}>
            <QueryFilterInput filters={filters} onChange={onChange} />
            <MultiselectDropdown
                options={tags.partners}
                renderOption={(option) => (
                    <div className={styles.group}>
                        <GroupIcon group={option} />
                        <span>{option.detailedName}</span>
                    </div>
                )}
                placeholder={strings.partnerGroups}
                name={"partnerFilter"}
                className={styles.filter}
                onItemSelect={onChangePartners}
                value={filters.partners}
                searchable
                searchPlaceholder={strings.searchByOrganisation}
            />
        </FilterBar>
    );
}

export type BackofficeUserFilter = Pick<UserFilter, "query" | "happeningGroups">;

export function BackofficeUserFilterBar(props: FilterBarPropsWithValue<BackofficeUserFilter>) {
    const { value: filters, onChange } = props;
    const tags = useContext(TagsContext).allTags;

    const onReset = useCallback(() => onChange({}), [onChange]);
    const onChangeHappeningGroups = useCallback(
        (filter: TagViewModel[]) => onChange({ ...filters, happeningGroups: filter }),
        [onChange, filters],
    );

    return (
        <FilterBar onReset={onReset}>
            <QueryFilterInput filters={filters} onChange={onChange} />
            <MultiselectDropdown
                options={tags.happeningGroups}
                renderOption={(option) => (
                    <div className={styles.group}>
                        <GroupIcon group={option} />
                        <span>{option.detailedName}</span>
                    </div>
                )}
                placeholder={strings.happeningGroups}
                name={"happeningGroupFilter"}
                className={styles.filter}
                onItemSelect={onChangeHappeningGroups}
                value={filters.happeningGroups}
                searchable
                searchPlaceholder={strings.searchByOrganisation}
            />
        </FilterBar>
    );
}
