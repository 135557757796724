export enum HappeningSubset {
    Participant = "Participant",
    NotVisible = "NotVisible",
    Public = "Public",
    Private = "Private",
    Clustered = "Clustered",
    Accessible = "Accessible",
    OrganizedByPartner = "OrganizedByPartner",
    Internal = "Internal",
    External = "External",
}
