import strings from "../../localization/strings";
import { TagType } from "../../openapi/backend";
import TagManagementContainer from "./TagManagementContainer";

const HappeningGroupManagement = () => (
    <TagManagementContainer
        title={strings.happeningGroups}
        addLabel={""}
        tagType={TagType.HappeningGroup}
        nested
        nestedAddLabel={strings.addProjectGroup}
    />
);

export default HappeningGroupManagement;
