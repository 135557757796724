import strings from "../../../localization/strings";
import styles from "./ReserveListItem.module.scss";
import MedicalIcon from "../../../assets/medical_icon.svg";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import FlexRow from "../../Layouts/FlexRow/FlexRow";

interface ReserveListItemProps {
    name: string;
    medicalNotes?: string;
    happeningId: string;
    participantId: string;
    onClick: () => void;
    age: number;
}

export default function ReserveListItem({ name, medicalNotes, onClick, age }: ReserveListItemProps) {
    return (
        <div className={styles.container}>
            <button
                type="button"
                onClick={onClick}
                aria-label={`${strings.openProfileOf} ${name}`}
                className={styles.label}
            >
                {name}
            </button>
            <FlexRow>
                <div className={styles.labelAge}>{`${age} ${strings.years}`}</div>
                {medicalNotes && <TooltipIcon message={medicalNotes} icon={MedicalIcon} className={styles.tooltip} />}
            </FlexRow>
        </div>
    );
}
