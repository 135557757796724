import moment from "moment";
import { useMemo, useState } from "react";
import styles from "./DateTimePicker.module.scss";
import TimeOptions from "../../../types/TimeOptions";
import { HourOptions, MinuteOptions } from "../../../types/TimeOptions";
import strings from "../../../localization/strings";
import Datepicker from "../Inputs/DatePicker/DatePicker";
import { RemoveButton, QuickAddButton } from "../RoundButton/RoundButton";
import { StringSelectField } from "../Inputs/SelectField/SelectField";
import { HappeningTimeAndDateInput } from "../../../openapi/backend";
import { InitialTimeAndDates } from "../../../utils.ts/InitialTimeAndDates";

interface DateTimePickerProps {
    dateTimes: HappeningTimeAndDateInput[];
    setDateTimes: (dates: HappeningTimeAndDateInput[]) => void;
    concept?: boolean;
    single?: boolean;
    index: number;
}

type HeaderLabelProps = {
    label: string;
};
const HeaderLabel = ({ label }: HeaderLabelProps) => <label className={styles.label}>{label}</label>;

export default function DateTimePicker({ dateTimes, setDateTimes, concept, single, index }: DateTimePickerProps) {
    const [inputDateTime, setInputDateTime] = useState<HappeningTimeAndDateInput>(
        dateTimes.length > 0 ? dateTimes[0] : InitialTimeAndDates(),
    );
    const dateTime = useMemo(() => dateTimes[index], [dateTimes, index]);

    let startTimeHours = moment(dateTime.sessionStart).format("HH");
    let startTimeMinutes = moment(dateTime.sessionStart).format("mm");

    let endTimeHours = moment(dateTime.sessionEnd).format("HH");
    let endTimeMinutes = moment(dateTime.sessionEnd).format("mm");

    return (
        <div>
            <div className={styles.timeContainer}>
                <div className={styles.mediumField}>
                    {index === 0 && <HeaderLabel label={strings.date} />}
                    <Datepicker
                        disabled={!concept && moment(dateTimes[index].sessionStart).isBefore()}
                        placeholderText={strings.date}
                        selected={dateTime.sessionStart}
                        minDate={moment().toDate()}
                        onChange={(selected) => {
                            if (selected) {
                                let sessionStartTime = moment(dateTime.sessionStart).format("HH:mm");

                                let sessionEndTime = moment(dateTime.sessionEnd).format("HH:mm");

                                if (moment(selected).isSame(new Date(), "day")) {
                                    sessionStartTime = moment(InitialTimeAndDates().sessionStart).format("HH:mm");
                                    sessionEndTime = moment(InitialTimeAndDates().sessionEnd).format("HH:mm");
                                }

                                const newDatetimes = [...dateTimes];
                                newDatetimes.splice(index, 1, {
                                    sessionStart: new Date(
                                        `${moment(selected).format("YYYY-MM-DD")}T${sessionStartTime}`,
                                    ),
                                    sessionEnd: new Date(`${moment(selected).format("YYYY-MM-DD")}T${sessionEndTime}`),
                                });
                                setDateTimes(newDatetimes);
                                setInputDateTime(newDatetimes[0]);
                            }
                        }}
                    />
                </div>

                <div className={styles.timeFields}>
                    {index === 0 && <HeaderLabel label={strings.startTime} />}
                    <div className={styles.shortField}>
                        <StringSelectField
                            className={styles.timeSelect}
                            disabled={!concept && moment(dateTimes[index].sessionStart).isBefore()}
                            value={moment(dateTimes[index].sessionStart).format("HH")}
                            onChange={(value) => {
                                startTimeHours = value;
                                let combinedTime = startTimeHours + ":" + startTimeMinutes;
                                let endTime;
                                if (
                                    dateTime.sessionEnd &&
                                    new Date(`${moment(dateTime.sessionStart).format("YYYY-MM-DD")}T${combinedTime}`) >
                                        dateTime.sessionEnd
                                ) {
                                    endTime = new Date(
                                        `${moment(dateTime.sessionEnd).format("YYYY-MM-DD")}T${
                                            TimeOptions[TimeOptions.indexOf(combinedTime) + 1]
                                        }`,
                                    );
                                } else {
                                    endTime = dateTime.sessionEnd;
                                }

                                const newDatetimes = [...dateTimes];
                                newDatetimes.splice(index, 1, {
                                    sessionStart: new Date(
                                        `${moment(dateTime.sessionStart).format("YYYY-MM-DD")}T${combinedTime}`,
                                    ),
                                    sessionEnd: endTime,
                                });
                                setDateTimes(newDatetimes);
                                setInputDateTime(newDatetimes[0]);
                            }}
                            name={`sessionStart-${index}`}
                            options={HourOptions.reduce((options: { label: string; value: string }[], hours) => {
                                let combinedTime = hours + ":" + startTimeMinutes;
                                // Disable hour options in the past on the same day
                                if (
                                    !moment(dateTime.sessionStart).isSame(new Date(), "day") ||
                                    moment().format("HH:mm") <= combinedTime ||
                                    moment(dateTime.sessionStart).format("HH:mm") === combinedTime
                                ) {
                                    options.push({ label: hours, value: hours });
                                }
                                return options;
                            }, [])}
                        />
                        <div className={styles.colonText}>:</div>
                        <StringSelectField
                            className={styles.timeSelect}
                            disabled={!concept && moment(dateTimes[index].sessionStart).isBefore()}
                            value={moment(dateTimes[index].sessionStart).format("mm")}
                            onChange={(value) => {
                                startTimeMinutes = value;
                                let combinedTime = startTimeHours + ":" + startTimeMinutes;
                                let endTime;
                                if (
                                    dateTime.sessionEnd &&
                                    new Date(`${moment(dateTime.sessionStart).format("YYYY-MM-DD")}T${combinedTime}`) >
                                        dateTime.sessionEnd
                                ) {
                                    endTime = new Date(
                                        `${moment(dateTime.sessionEnd).format("YYYY-MM-DD")}T${
                                            TimeOptions[TimeOptions.indexOf(combinedTime) + 1]
                                        }`,
                                    );
                                } else {
                                    endTime = dateTime.sessionEnd;
                                }

                                const newDatetimes = [...dateTimes];
                                newDatetimes.splice(index, 1, {
                                    sessionStart: new Date(
                                        `${moment(dateTime.sessionStart).format("YYYY-MM-DD")}T${combinedTime}`,
                                    ),
                                    sessionEnd: endTime,
                                });
                                setDateTimes(newDatetimes);
                                setInputDateTime(newDatetimes[0]);
                            }}
                            name={`sessionStart-${index}`}
                            options={MinuteOptions.reduce((options: { label: string; value: string }[], minutes) => {
                                // Disable 55 minutes option for the start of an activity so 23:55 is disabled.
                                if (startTimeHours !== HourOptions[HourOptions.length - 1]) {
                                    options.push({ label: minutes, value: minutes });
                                } else {
                                    if (minutes !== MinuteOptions[MinuteOptions.length - 1]) {
                                        options.push({ label: minutes, value: minutes });
                                    }
                                }
                                return options;
                            }, [])}
                        />
                    </div>
                </div>

                <div className={styles.timeFields}>
                    {index === 0 && <HeaderLabel label={strings.endTime} />}
                    <div className={styles.shortField}>
                        <StringSelectField
                            className={styles.timeSelect}
                            disabled={!concept && moment(dateTimes[index].sessionEnd).isBefore()}
                            value={moment(dateTimes[index].sessionEnd).format("HH")}
                            onChange={(value) => {
                                endTimeHours = value;
                                let combinedTime = endTimeHours + ":" + endTimeMinutes;
                                const newDatetimes = [...dateTimes];
                                newDatetimes.splice(index, 1, {
                                    sessionStart: dateTime.sessionStart,
                                    sessionEnd: new Date(
                                        `${moment(dateTime.sessionEnd).format("YYYY-MM-DD")}T${combinedTime}`,
                                    ),
                                });
                                setDateTimes(newDatetimes);
                                setInputDateTime(newDatetimes[0]);
                            }}
                            name={`sessionEnd-${index}`}
                            options={HourOptions.reduce((options: { label: string; value: string }[], hours) => {
                                // Disable same hour option if sessionStart minutes are set to 55
                                if (
                                    hours > startTimeHours &&
                                    startTimeMinutes === MinuteOptions[MinuteOptions.length - 1]
                                ) {
                                    options.push({ label: hours, value: hours });
                                }
                                if (
                                    hours >= startTimeHours &&
                                    startTimeMinutes !== MinuteOptions[MinuteOptions.length - 1]
                                ) {
                                    options.push({ label: hours, value: hours });
                                }
                                return options;
                            }, [])}
                        />
                        <div className={styles.colonText}>:</div>
                        <StringSelectField
                            className={styles.timeSelect}
                            disabled={!concept && moment(dateTimes[index].sessionEnd).isBefore()}
                            value={moment(dateTimes[index].sessionEnd).format("mm")}
                            onChange={(value) => {
                                endTimeMinutes = value;
                                let combinedTime = endTimeHours + ":" + endTimeMinutes;
                                const newDatetimes = [...dateTimes];
                                newDatetimes.splice(index, 1, {
                                    sessionStart: dateTime.sessionStart,
                                    sessionEnd: new Date(
                                        `${moment(dateTime.sessionEnd).format("YYYY-MM-DD")}T${combinedTime}`,
                                    ),
                                });
                                setDateTimes(newDatetimes);
                                setInputDateTime(newDatetimes[0]);
                            }}
                            name={`sessionEnd-${index}`}
                            options={MinuteOptions.reduce((options: { label: string; value: string }[], minutes) => {
                                // Disable minute options lower than sessionStart minutes if starting and ending hours are the same
                                if (endTimeHours === startTimeHours) {
                                    if (minutes > startTimeMinutes) {
                                        options.push({ label: minutes, value: minutes });
                                    }
                                }
                                if (endTimeHours > startTimeHours) {
                                    options.push({ label: minutes, value: minutes });
                                }
                                return options;
                            }, [])}
                        />
                    </div>
                </div>

                {index === 0 && !single && (
                    <QuickAddButton
                        onClick={() => {
                            let newDatetimes;
                            if (inputDateTime.sessionStart < InitialTimeAndDates().sessionStart) {
                                newDatetimes = [InitialTimeAndDates()].concat(dateTimes);
                            } else {
                                newDatetimes = dateTimes.concat(inputDateTime);
                            }
                            setDateTimes(newDatetimes);
                        }}
                        className={styles.dateButtonAdd}
                    />
                )}

                {index > 0 && (moment(dateTimes[index].sessionEnd).isAfter() || concept) && (
                    <RemoveButton
                        onClick={() => {
                            const newDatetimes = [...dateTimes];
                            newDatetimes.splice(index, 1);
                            setDateTimes(newDatetimes);
                        }}
                        className={styles.dateButtonAdd}
                    />
                )}
            </div>
        </div>
    );
}
