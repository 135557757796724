import { Route } from "react-router-dom";
import Bouncer from "../authentication/Bouncer";
import {
    canCreateIndicator,
    canCreateMeasurement,
    canEditMeasurement,
    canViewIndicators,
    canViewMeasurements,
    canViewMonitoring,
} from "../authentication/Permissions";
import CreateIndicatorForm from "../components/Forms/Indicator/CreateIndicatorForm";
import UpdateIndicatorForm from "../components/Forms/Indicator/UpdateIndicatorForm";
import CreateMeasurementForm from "../components/Forms/Measurement/CreateMeasurementForm";
import UpdateMeasurementForm from "../components/Forms/Measurement/UpdateMeasurementForm";
import AllIndicatorsContainer from "../components/Monitoring/AllIndicatorsContainer";
import AllMeasurementsContainer from "../components/Monitoring/AllMeasurementsContainer";
import MyMeasurementsContainer from "../components/Monitoring/MyMeasurementsContainer";
import strings from "../localization/strings";
import { NavigationTab } from "../types/NavigationTab";
import { createIndicatorLink, getAddMeasurementLink, getEditMeasurementLink } from "../utils.ts/Urls";
import useTabRoute from "./UseTabRoute";

const monitoringTabs: NavigationTab[] = [
    {
        pathname: "measurements/all",
        label: strings.allMeasurements,
        element: <AllMeasurementsContainer />,
        permissions: [canViewMeasurements],
    },
    {
        pathname: "measurements/my",
        label: strings.measurements,
        element: <MyMeasurementsContainer />,
        permissions: [canViewMeasurements],
    },
    {
        pathname: "indicators/all",
        label: strings.allIndicators,
        element: <AllIndicatorsContainer />,
        permissions: [canViewIndicators],
    },
];

const useMonitoringRoute = () => {
    return (
        <>
            {/* Separate route outside of tab structure */}
            <Route
                element={<Bouncer permissions={[canViewIndicators]} element={<UpdateIndicatorForm />} />}
                path="monitoring/indicators/:indicatorId"
            />
            <Route
                element={<Bouncer permissions={[canEditMeasurement]} element={<UpdateMeasurementForm />} />}
                path={getEditMeasurementLink(":id")}
            />
            <Route
                path={createIndicatorLink}
                element={<Bouncer permissions={[canCreateIndicator]} element={<CreateIndicatorForm />} />}
            />
            <Route
                path={getAddMeasurementLink(":id")}
                element={<Bouncer permissions={[canCreateMeasurement]} element={<CreateMeasurementForm />} />}
            />

            {useTabRoute("monitoring", monitoringTabs, [canViewMonitoring])}
        </>
    );
};

export default useMonitoringRoute;
