/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    FormType,
    FormTypeFromJSON,
    FormTypeFromJSONTyped,
    FormTypeToJSON,
    Gender,
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
    KeiEducationType,
    KeiEducationTypeFromJSON,
    KeiEducationTypeFromJSONTyped,
    KeiEducationTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface KeiComputerApplicationInput
 */
export interface KeiComputerApplicationInput {
    /**
     *
     * @type {FormType}
     * @memberof KeiComputerApplicationInput
     */
    type: FormType;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    givenName: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    familyName: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    street: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    houseNumber: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    postalCode: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    city: string;
    /**
     *
     * @type {Gender}
     * @memberof KeiComputerApplicationInput
     */
    gender: Gender;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    email: string;
    /**
     *
     * @type {Date}
     * @memberof KeiComputerApplicationInput
     */
    dateOfBirth: Date;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    supplement?: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    reviewerId?: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    subjectId?: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    childGivenName: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    childFamilyName: string;
    /**
     *
     * @type {Gender}
     * @memberof KeiComputerApplicationInput
     */
    childGender: Gender;
    /**
     *
     * @type {Date}
     * @memberof KeiComputerApplicationInput
     */
    childDateOfBirth: Date;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    passNumber: string;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    childSchool: string;
    /**
     *
     * @type {KeiEducationType}
     * @memberof KeiComputerApplicationInput
     */
    childEducationType: KeiEducationType;
    /**
     *
     * @type {number}
     * @memberof KeiComputerApplicationInput
     */
    grade: number;
    /**
     *
     * @type {boolean}
     * @memberof KeiComputerApplicationInput
     */
    isPurchase: boolean;
    /**
     *
     * @type {number}
     * @memberof KeiComputerApplicationInput
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof KeiComputerApplicationInput
     */
    computerDescription: string;
    /**
     *
     * @type {number}
     * @memberof KeiComputerApplicationInput
     */
    fileId: number;
}

/**
 * Check if a given object implements the KeiComputerApplicationInput interface.
 */
export function instanceOfKeiComputerApplicationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "houseNumber" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "childGivenName" in value;
    isInstance = isInstance && "childFamilyName" in value;
    isInstance = isInstance && "childGender" in value;
    isInstance = isInstance && "childDateOfBirth" in value;
    isInstance = isInstance && "passNumber" in value;
    isInstance = isInstance && "childSchool" in value;
    isInstance = isInstance && "childEducationType" in value;
    isInstance = isInstance && "grade" in value;
    isInstance = isInstance && "isPurchase" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "computerDescription" in value;
    isInstance = isInstance && "fileId" in value;

    return isInstance;
}

export function KeiComputerApplicationInputFromJSON(json: any): KeiComputerApplicationInput {
    return KeiComputerApplicationInputFromJSONTyped(json, false);
}

export function KeiComputerApplicationInputFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): KeiComputerApplicationInput {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, "type") ? (undefined as any) : FormTypeFromJSON(json["type"]),
        givenName: !exists(json, "givenName") ? (undefined as any) : json["givenName"],
        familyName: !exists(json, "familyName") ? (undefined as any) : json["familyName"],
        street: !exists(json, "street") ? (undefined as any) : json["street"],
        houseNumber: !exists(json, "houseNumber") ? (undefined as any) : json["houseNumber"],
        postalCode: !exists(json, "postalCode") ? (undefined as any) : json["postalCode"],
        city: !exists(json, "city") ? (undefined as any) : json["city"],
        gender: !exists(json, "gender") ? (undefined as any) : GenderFromJSON(json["gender"]),
        email: !exists(json, "email") ? (undefined as any) : json["email"],
        dateOfBirth: !exists(json, "dateOfBirth") ? (undefined as any) : new Date(json["dateOfBirth"]),
        phoneNumber: !exists(json, "phoneNumber") ? (undefined as any) : json["phoneNumber"],
        supplement: !exists(json, "supplement") ? (undefined as any) : json["supplement"],
        reviewerId: !exists(json, "reviewerId") ? (undefined as any) : json["reviewerId"],
        subjectId: !exists(json, "subjectId") ? (undefined as any) : json["subjectId"],
        childGivenName: !exists(json, "childGivenName") ? (undefined as any) : json["childGivenName"],
        childFamilyName: !exists(json, "childFamilyName") ? (undefined as any) : json["childFamilyName"],
        childGender: !exists(json, "childGender") ? (undefined as any) : GenderFromJSON(json["childGender"]),
        childDateOfBirth: !exists(json, "childDateOfBirth") ? (undefined as any) : new Date(json["childDateOfBirth"]),
        passNumber: !exists(json, "passNumber") ? (undefined as any) : json["passNumber"],
        childSchool: !exists(json, "childSchool") ? (undefined as any) : json["childSchool"],
        childEducationType: !exists(json, "childEducationType")
            ? (undefined as any)
            : KeiEducationTypeFromJSON(json["childEducationType"]),
        grade: !exists(json, "grade") ? (undefined as any) : json["grade"],
        isPurchase: !exists(json, "isPurchase") ? (undefined as any) : json["isPurchase"],
        price: !exists(json, "price") ? (undefined as any) : json["price"],
        computerDescription: !exists(json, "computerDescription") ? (undefined as any) : json["computerDescription"],
        fileId: !exists(json, "fileId") ? (undefined as any) : json["fileId"],
    };
}

export function KeiComputerApplicationInputToJSON(value?: KeiComputerApplicationInput | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
