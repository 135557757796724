import * as yup from "yup";
import { basicUrl, emailPattern, MAX_AGE, MAX_TITLE_LENGTH, MIN_AGE, phoneNumber } from "../../../constants/Validation";
import { OrganisationInfoInput } from "../../../hooks/OrganisationInfoHooks";
import strings from "../../../localization/strings";
import { LocationInputModel, OrganisationExternalLinkInputModel } from "../../../openapi/backend";
import { modelWithImagesSchema, safeNumber } from "../../../utils.ts/Validation";

const locationSchema: yup.SchemaOf<LocationInputModel> = yup.object({
    address: yup.string().required(),
    name: yup.string().required(),
    longitude: safeNumber.required(),
    latitude: safeNumber.required(),
});

const externalLinkSchema: yup.SchemaOf<OrganisationExternalLinkInputModel> = yup.object({
    name: yup.string(),
    url: yup.string().when("name", {
        is: (val: string) => !!val,
        then: (schema) => schema.required().matches(basicUrl, strings.basicUrlError),
    }),
});

export const validationSchema: yup.SchemaOf<OrganisationInfoInput> = modelWithImagesSchema.shape({
    title: yup.string().required().max(MAX_TITLE_LENGTH),
    organisationTag: yup.string(),
    isActive: yup.boolean().default(false),
    category: yup.string().required(),
    minimumAge: safeNumber.required().min(MIN_AGE).max(MAX_AGE),
    maximumAge: safeNumber
        .required()
        .min(MIN_AGE)
        .max(MAX_AGE)
        .test("pattern", strings.ageError, (val: any, context: any) => context.parent.minimumAge <= val),
    description: yup.string().required(),
    locations: yup.array(locationSchema).required() as any,
    externalLinks: yup.array(externalLinkSchema as any),
    contactName: yup.string().required(),
    contactEmail: yup.string().required().matches(emailPattern, strings.invalidEmail),
    contactPhoneNumber: yup.string().required().matches(phoneNumber, strings.invalidPhoneNumber),
    sector: yup.string(),
    specificTargetGroup: yup.string(),
}) as any;
