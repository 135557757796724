import { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import styles from "./FormAddressInput.module.scss";
import { InputFieldProps } from "../../Inputs/InputField/InputField";
import useLocationSearch from "../../../../hooks/UseLocationSearch";
import SearchInput from "../../Inputs/SearchInput/SearchInput";
import Map from "../../Map/Map";
import strings from "../../../../localization/strings";
import { MarkerLocation } from "../../Map/MapMarker";
import FormField from "../FormField/FormField";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import cx from "classnames";

interface LocationInput {
    locationName?: string;
    locationAddress?: string;
    locationLatitude?: number;
    locationLongitude?: number;
}

type Props<T extends LocationInput> = Omit<InputFieldProps, "type" | "form"> & {
    form: UseFormReturn<T, object>;
    className?: string;
    defaultAddress?: string;
};

const FormAddressInput = <T extends LocationInput>({ disabled, form, className, defaultAddress }: Props<T>) => {
    const [location, search, isSearching] = useLocationSearch();
    const [firstSearchPerformed, setFirstSearchPerformed] = useState(false);
    const locationNotFoundError = useMemo(
        () => !isSearching && !location && firstSearchPerformed,
        [location, firstSearchPerformed, isSearching],
    );

    const {
        watch,
        setValue,
        register,
        formState: { errors },
        getValues,
    } = form as any as UseFormReturn<LocationInput, object>;
    const name = watch("locationName");
    const [markerLocation, setMarkerLocation] = useState<MarkerLocation>({
        address: getValues("locationAddress"),
        latitude: getValues("locationLatitude"),
        longitude: getValues("locationLongitude"),
        name,
    });

    useEffect(() => {
        if (!location) {
            return;
        }

        setMarkerLocation((oldValue) => ({ ...oldValue, ...location }));
        setValue("locationAddress", location.address);
        setValue("locationLatitude", location.latitude);
        setValue("locationLongitude", location.longitude);
    }, [location, setValue]);

    useEffect(() => {
        setMarkerLocation((oldValue) => ({ ...oldValue, name }));
    }, [name]);

    return (
        <div className={cx(styles.container, className)}>
            <FormFieldContainer label={strings.address} name="searchAddress">
                <SearchInput
                    name="searchAddress"
                    onSearch={(value) => {
                        setFirstSearchPerformed(true);
                        search(value);
                    }}
                    disabled={disabled}
                    defaultValue={defaultAddress}
                />
                <FormField
                    type="hidden"
                    name={"locationAddress"}
                    errors={
                        locationNotFoundError
                            ? { type: "pattern", message: "Locatie niet gevonden" }
                            : errors.locationAddress
                    }
                    register={register}
                    placeholder=""
                    required
                    disabled={disabled}
                />
                <FormField
                    name="locationLatitude"
                    type="hidden"
                    register={register}
                    placeholder=""
                    disabled={disabled}
                />
                <FormField
                    name="locationLongitude"
                    type="hidden"
                    register={register}
                    placeholder=""
                    disabled={disabled}
                />
                <div className={styles.subText}>{strings.checkAddress}</div>
            </FormFieldContainer>
            <FormField
                label={strings.locationName}
                name={"locationName"}
                errors={errors.locationName}
                placeholder={""}
                register={register}
                required
                disabled={disabled}
            />
            <Map location={markerLocation} className={styles.mapContainer} />
        </div>
    );
};

export default FormAddressInput;
