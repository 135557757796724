import { ErrorOption, useForm } from "react-hook-form";
import strings from "../../../localization/strings";
import Accordion from "../../core/Accordion/Accordion";
import Form from "../../core/Form/Form/Form";
import FormField from "../../core/Form/FormField/FormField";
import { UserInput } from "../../../hooks/UserHooks";
import { useEffect } from "react";
import { emailPattern } from "../../../constants/Validation";
import { ArrangementListOutput } from "../../../openapi/backend";
import AuthorizationFields from "./AuthorizationFields";

interface CreateAccountProps {
    emailError?: ErrorOption;
    globalError?: Response;
    onDismiss: () => void;
    onSubmit: (input: UserInput) => void;
    isSubmitting: boolean;
    arrangements: ArrangementListOutput[];
}

export default function CreateAccount({
    onDismiss,
    onSubmit,
    emailError,
    globalError,
    isSubmitting,
    arrangements,
}: CreateAccountProps) {
    const {
        control,
        register,
        unregister,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
        watch,
    } = useForm<UserInput>();

    useEffect(() => {
        if (emailError) {
            setError("email", emailError, { shouldFocus: true });
        }
    }, [setError, emailError]);

    useEffect(() => {
        setFocus("givenName");
    }, [setFocus]);

    return (
        <Form
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            onDismiss={onDismiss}
            error={globalError}
            waitingForSubmit={isSubmitting}
        >
            <Accordion title={strings.details} collapsible open>
                <FormField
                    name={"givenName"}
                    label={strings.givenName}
                    register={register}
                    errors={errors.givenName}
                    required
                    placeholder={""}
                />
                <FormField
                    name={"familyName"}
                    label={strings.familyName}
                    register={register}
                    errors={errors.familyName}
                    required
                    placeholder={""}
                />
                <FormField
                    name={"email"}
                    label={strings.email}
                    register={register}
                    errors={errors.email}
                    required
                    placeholder={""}
                    type="email"
                    validationPattern={{ value: emailPattern, message: strings.invalidEmail }}
                />
                <AuthorizationFields
                    control={control}
                    watch={watch}
                    unregister={unregister}
                    errors={errors}
                    arrangements={arrangements}
                />
            </Accordion>
        </Form>
    );
}
