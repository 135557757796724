import { PropsWithChildren } from "react";
import styles from "./FieldView.module.scss";

interface Props {
    label: string;
    className?: string;
    allowNewLines?: boolean;
}

const FieldView = ({ label, className, allowNewLines, children }: PropsWithChildren<Props>) => (
    <div className={className}>
        <div className={styles.label}>{label}</div>
        <div className={`${styles.content} ${allowNewLines ? styles.allowNewLines : ""}`}>{children}</div>
    </div>
);

export default FieldView;
