import styles from "./RegistrationStatisticCard.module.scss";

interface RegistrationStatisticCardProps {
    label: string;
    value: number;
}

export default function RegistrationStatisticCard({ label, value }: RegistrationStatisticCardProps) {
    return (
        <div className={styles.card}>
            <div className={styles.value}>{value}</div>
            <div className={styles.label}>{label}</div>
        </div>
    );
}
