import { useState, useMemo } from "react";
import { TagViewModel } from "../openapi/backend";

export function useTagSearch<T extends TagViewModel>(tags?: Array<T>) {
    const [search, setSearch] = useState("");

    const filteredTags = useMemo(
        () => tags?.filter((t) => t.name.toLowerCase().includes(search.trim().toLowerCase())) ?? [],
        [tags, search],
    );

    return useMemo(() => ({ search, setSearch, filteredTags }), [search, filteredTags]);
}
