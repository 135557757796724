import strings from "../../../localization/strings";
import { HappeningState } from "../../../openapi/backend";
import TextButton from "../../core/TextButton/TextButton";
import { BareHappening, canCancelHappening } from "../../../utils.ts/Happening";
import { RequestState } from "../../../hooks/UseApiCall";
import { ConfirmationButton } from "../../core/ConfirmationButton/ConfirmationButton";
import FlexRow from "../../Layouts/FlexRow/FlexRow";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { ReactNode, useContext } from "react";
import {
    canArchiveHappening,
    canCancelAHappening,
    canDeleteHappening,
    canEditHappening,
    canCloneHappening,
    canUpsertClusters,
    canRejectHappening,
    canForceEditHappening,
} from "../../../authentication/Permissions";

interface ButtonProps {
    happening: BareHappening;
    onArchive: () => void;
    onDelete: () => void;
    deleteState: RequestState;
    onUpdate: () => void;
    onPublish: () => void;
    onCreateCluster: () => void;
    onCancel: () => void;
    onClone: () => void;
    onReject: () => void;
    hasRegisteredParticipants?: boolean;
}

interface FilterableElement {
    element: ReactNode;
    show: boolean;
}

const EditButtons = ({
    happening,
    onClone,
    onDelete,
    onUpdate,
    onCancel,
    onArchive,
    onPublish,
    onCreateCluster,
    onReject,
    hasRegisteredParticipants = false,
}: ButtonProps) => {
    const { profile } = useContext(ProfileContext);
    const hasCluster = !!happening.cluster;
    let editButtons: FilterableElement[];
    switch (happening.displayState) {
        case HappeningState.Rejected:
        case HappeningState.Concept:
            editButtons = [
                {
                    element: (
                        <ConfirmationButton
                            key="delete"
                            onConfirm={onDelete}
                            title={strings.confirmation}
                            body={strings.confirmDeleteHappening}
                            text={strings.delete}
                            buttonType="tertiary-alert"
                        />
                    ),
                    show: canDeleteHappening(happening, profile, hasRegisteredParticipants),
                },
                {
                    element: (
                        <ConfirmationButton
                            key="cluster"
                            onConfirm={onCreateCluster}
                            title={strings.confirmation}
                            body={strings.confirmClusterHappening}
                            text={strings.clusterButtonLabel}
                            buttonType="secondary"
                        />
                    ),
                    show: !hasCluster && canUpsertClusters(profile),
                },
                {
                    element: <TextButton key="clone" text={strings.clone} onClick={onClone} buttonType="secondary" />,
                    show: canCloneHappening(profile),
                },
                {
                    element: <TextButton key="edit" text={strings.update} onClick={onUpdate} />,
                    show: canEditHappening(happening, profile),
                },
            ];
            break;
        case HappeningState.PlannedForPublication:
        case HappeningState.Open:
            editButtons = [
                {
                    element: (
                        <ConfirmationButton
                            key="delete"
                            onConfirm={onDelete}
                            title={strings.confirmation}
                            body={strings.confirmDeleteHappening}
                            text={strings.delete}
                            buttonType="tertiary-alert"
                        />
                    ),
                    show: canDeleteHappening(happening, profile, hasRegisteredParticipants),
                },
                {
                    element: <TextButton key="cancel" text={strings.cancel} onClick={onCancel} buttonType="tertiary" />,
                    show: canCancelHappening(happening) && canCancelAHappening(happening, profile),
                },
                {
                    show: canArchiveHappening(happening, profile),
                    element: (
                        <TextButton key="archive" text={strings.archive} onClick={onArchive} buttonType="tertiary" />
                    ),
                },
                {
                    element: (
                        <ConfirmationButton
                            key="cluster"
                            onConfirm={onCreateCluster}
                            title={strings.confirmation}
                            body={strings.confirmClusterHappening}
                            text={strings.clusterButtonLabel}
                            buttonType="secondary"
                        />
                    ),
                    show: !hasCluster && canUpsertClusters(profile),
                },
                {
                    element: <TextButton key="clone" text={strings.clone} onClick={onClone} buttonType="secondary" />,
                    show: canCloneHappening(profile),
                },
                {
                    element: <TextButton key="edit" text={strings.update} onClick={onUpdate} />,
                    show: canEditHappening(happening, profile),
                },
            ];
            break;
        case HappeningState.PartnerSuggestion:
            editButtons = [
                {
                    element: (
                        <ConfirmationButton
                            key="delete"
                            onConfirm={onDelete}
                            title={strings.confirmation}
                            body={strings.confirmDeleteHappening}
                            text={strings.delete}
                            buttonType="tertiary-alert"
                        />
                    ),
                    show: canDeleteHappening(happening, profile, hasRegisteredParticipants),
                },
                {
                    element: <TextButton key="reject" text={strings.reject} onClick={onReject} buttonType="tertiary" />,
                    show: canRejectHappening(happening, profile),
                },
                {
                    element: <TextButton key="clone" text={strings.clone} onClick={onClone} buttonType="secondary" />,
                    show: canCloneHappening(profile),
                },
                {
                    element: <TextButton key="edit" text={strings.update} onClick={onUpdate} />,
                    show: canEditHappening(happening, profile),
                },
            ];
            break;
        case HappeningState.Archived:
            editButtons = [
                {
                    element: (
                        <ConfirmationButton
                            key="delete"
                            onConfirm={onDelete}
                            title={strings.confirmation}
                            body={strings.confirmDeleteHappening}
                            text={strings.delete}
                            buttonType="tertiary-alert"
                        />
                    ),
                    show: canDeleteHappening(happening, profile, hasRegisteredParticipants),
                },
                {
                    element: (
                        <TextButton
                            key="republish"
                            text={strings.republish}
                            onClick={onPublish}
                            buttonType="secondary"
                        />
                    ),
                    show: canEditHappening(happening, profile),
                },
                {
                    element: <TextButton key="clone" text={strings.clone} onClick={onClone} buttonType="secondary" />,
                    show: canCloneHappening(profile),
                },
                {
                    element: <TextButton key="edit" text={strings.update} onClick={onUpdate} />,
                    show: canEditHappening(happening, profile),
                },
            ];
            break;
        case HappeningState.Cancelled:
            editButtons = [
                {
                    element: (
                        <ConfirmationButton
                            key="delete"
                            onConfirm={onDelete}
                            title={strings.confirmation}
                            body={strings.confirmDeleteHappening}
                            text={strings.delete}
                            buttonType="tertiary-alert"
                        />
                    ),
                    show: canDeleteHappening(happening, profile, hasRegisteredParticipants),
                },
                {
                    element: <TextButton key="edit" text={strings.update} onClick={onUpdate} />,
                    show: canForceEditHappening(profile),
                },
            ];
            break;
        default:
            editButtons = [];
            break;
    }

    return <FlexRow>{editButtons.filter((x) => x.show).map((x) => x.element)}</FlexRow>;
};

export default EditButtons;
