import { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import Table from "../Table/Table";
import styles from "./StaticTable.module.scss";

export type StaticTableProps<T extends object> = {
    data: T[];
    dateId?: number;
    columns: Column<T>[];
    renderHeader?: boolean;
    itemsPerPage?: number;
    showPagination?: boolean;
    emptyString?: string;
};

export default function StaticTable<T extends object>({
    data,
    dateId,
    columns,
    renderHeader = false,
    itemsPerPage = 10,
    showPagination = true,
    emptyString,
}: StaticTableProps<T>) {
    const [page, setPage] = useState(1);
    const tableData = useMemo(
        () => data.slice((page - 1) * itemsPerPage, page * itemsPerPage),
        [page, data, itemsPerPage],
    );

    const totalPages = useMemo(() => Math.ceil(data.length / itemsPerPage), [data.length, itemsPerPage]);

    useEffect(() => {
        // @NOTE(Lejun) Make sure the page is within the bounds.
        // This could occur when the data is filtered and the page is higher than the total pages
        // or an item in the data is deleted
        setPage((currentPage) => {
            return Math.max(Math.min(currentPage, totalPages), 1);
        });
    }, [data.length, totalPages]);

    return (
        <Table
            paginationMeta={{
                totalPages: totalPages,
                itemsPerPage: itemsPerPage,
                totalItems: data.length,
            }}
            className={styles.container}
            columns={columns}
            data={tableData}
            onRowClick={() => {}}
            rowSelectable={false}
            renderHeader={renderHeader}
            setPage={setPage}
            forcePage={page}
            showPagination={showPagination}
            emptyString={emptyString}
        />
    );
}
