import { ReactNode, useMemo } from "react";
import { SubmitHandler, UseFormHandleSubmit } from "react-hook-form";
import strings from "../../../../localization/strings";
import { DropdownButton } from "../../DropdownButton/DropdownButton";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import SimpleTextButton from "../../SimpleTextButton/SimpleTextButton";
import TextButton from "../../TextButton/TextButton";
import styles from "./Form.module.scss";

interface FormProps {
    onSubmit?: SubmitHandler<any>;
    handleSubmit?: UseFormHandleSubmit<any>;
    waitingForSubmit?: boolean;
    onDismiss?: () => void;
    children?: ReactNode;
    submitText?: string;
    cancelText?: string;
    error?: Response;
    defaultErrorMesssage?: string;
    secondaryButtons?: ReactNode;
    tertiaryButtons?: ReactNode;
    className?: string;
    dropdownSubmit?: ReactNode;
    dropdownAltText?: string;
    dropdownTitle?: string;
    readOnly?: boolean;
    disabled?: boolean;
}

export default function Form({
    onSubmit,
    handleSubmit,
    onDismiss,
    children,
    submitText = strings.save,
    cancelText = strings.cancel,
    error,
    defaultErrorMesssage,
    waitingForSubmit = false,
    secondaryButtons,
    tertiaryButtons,
    className,
    dropdownSubmit,
    dropdownAltText,
    dropdownTitle,
    readOnly,
    disabled,
}: FormProps) {
    const blockingSubmit = useMemo(() => {
        if (waitingForSubmit || !onSubmit) {
            return undefined;
        }

        return handleSubmit ? handleSubmit(onSubmit) : onSubmit;
    }, [handleSubmit, onSubmit, waitingForSubmit]);

    return (
        <form className={className}>
            {children}
            {!readOnly && (
                <div className={styles.buttonContainer}>
                    {onSubmit &&
                        (dropdownSubmit ? (
                            <DropdownButton
                                onClick={blockingSubmit}
                                text={submitText}
                                isLoading={waitingForSubmit}
                                altText={dropdownAltText}
                                dropdownTitle={dropdownTitle}
                            >
                                {dropdownSubmit}
                            </DropdownButton>
                        ) : (
                            <TextButton
                                onClick={blockingSubmit}
                                text={submitText}
                                isLoading={waitingForSubmit}
                                disabled={disabled}
                            />
                        ))}
                    {secondaryButtons}
                    {onDismiss && <SimpleTextButton onClick={onDismiss} text={cancelText} />}
                    {tertiaryButtons}
                </div>
            )}

            {error && <ErrorMessage error={error} defaultMessage={defaultErrorMesssage} isToast />}
        </form>
    );
}
