import { useState } from "react";
import { DownloadUsersFun } from "../../hooks/UserHooks";
import strings from "../../localization/strings";
import Modal from "../core/Modal/Modal";
import TextButton from "../core/TextButton/TextButton";
import DownloadIcon from "../../assets/downloads.svg";
import CreateAccountContainer from "../Forms/AccountForm/CreateAccountContainer";

export const ParticipantAccountsHeader = ({ downloadUsers }: { downloadUsers: DownloadUsersFun }) => (
    <TextButton
        buttonType="alternate"
        text={strings.downloadList}
        icon={DownloadIcon}
        onClick={() => downloadUsers()}
    />
);

export const UserAccountsHeader = ({
    createUserTitle,
    downloadUsers,
}: {
    createUserTitle: string;
    downloadUsers: DownloadUsersFun;
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ParticipantAccountsHeader downloadUsers={downloadUsers} />
            <TextButton text={createUserTitle} onClick={() => setShowModal(true)} />
            <Modal title={strings.createAccount} onClose={() => setShowModal(false)} isOpen={showModal}>
                <CreateAccountContainer onDismiss={() => setShowModal(false)} />
            </Modal>
        </>
    );
};
