import { canOnlySuggestHappenings } from "../authentication/Permissions";
import { isChiefEditor, isApprover, isEditor } from "../authentication/UserMethods";
import { TagViewModel, TagType, TagCollection, UserOutput } from "../openapi/backend";
import { Operation } from "../types/Operation";

export function getHappeningGroupOptions(
    operation: Operation,
    user: UserOutput,
    tags: TagCollection,
    currentHappeningGroup?: string,
) {
    switch (operation) {
        case Operation.CREATE:
            return getHappeningGroupOptionsForCreate(user, tags, currentHappeningGroup);
        case Operation.UPDATE:
            return getHappeningGroupOptionsForUpdate(user, tags, currentHappeningGroup);
    }
}

function getHappeningGroupOptionsForCreate(user: UserOutput, tags: TagCollection, currentHappeningGroup?: string) {
    switch (true) {
        case isChiefEditor(user):
            return [...tags.happeningGroups, ...tags.partners];
        case isEditor(user):
            return tags.happeningGroups.filter(
                (tag) =>
                    tag.id === currentHappeningGroup ||
                    user.tags.some((t) => t.id === tag.id || tag.parentTags?.includes(t.id)),
            );
        case canOnlySuggestHappenings(user):
            return [
                tags.happeningGroups.find((t) => t.id === currentHappeningGroup),
                ...user.tags.filter((t) => t.isActive && t.type === TagType.Partner),
            ].filter((t) => t !== undefined) as TagViewModel[];
        default:
            return tags.happeningGroups;
    }
}

function getHappeningGroupOptionsForUpdate(user: UserOutput, tags: TagCollection, currentHappeningGroup?: string) {
    switch (true) {
        case isChiefEditor(user) || isApprover(user):
            return [...tags.happeningGroups, ...tags.partners];
        case isEditor(user):
            return tags.happeningGroups.filter(
                (tag) =>
                    tag.id === currentHappeningGroup ||
                    user.tags.some((t) => t.id === tag.id || tag.parentTags?.includes(t.id)),
            );
        case canOnlySuggestHappenings(user):
            return [
                tags.happeningGroups.find((t) => t.id === currentHappeningGroup),
                ...user.tags.filter((t) => t.isActive && t.type === TagType.Partner),
            ].filter((t) => t !== undefined) as TagViewModel[];
        default:
            return tags.happeningGroups;
    }
}
