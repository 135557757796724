import { useEffect, useMemo, useState } from "react";
import styles from "./MultiDataListInput.module.scss";
import { QuickAddButton, RemoveButton } from "../../RoundButton/RoundButton";
import DataListInputTagSearch, { DataListInputTagSearchProps } from "../DataListInput/DataListInputTagSearch";
import { TagType, TagViewModel } from "../../../../openapi/backend";
import strings from "../../../../localization/strings";

export interface MultiDataListInputLocationSearchProps
    extends Omit<DataListInputTagSearchProps, "value" | "onChange" | "options" | "tagType" | "setValue"> {
    value?: TagViewModel[];
    onChange?: (selected: TagViewModel[]) => void;
}

interface LocationProps {
    value?: TagViewModel;
    remove: () => void;
}

const Location = ({ remove, value }: LocationProps) => (
    <div className={`${styles.rowField} ${styles.location}`}>
        <div className={styles.nameField}>{value?.detailedName}</div>
        <RemoveButton onClick={remove} />
    </div>
);

export default function MultiDataListInputLocationSearch({
    value,
    onChange,
    ...props
}: MultiDataListInputLocationSearchProps) {
    const [innerValue, setInnerValue] = useState<TagViewModel[]>(
        value?.filter(
            (val) =>
                val.type === TagType.Municipality ||
                val.type === TagType.District ||
                val.type === TagType.Neighbourhood,
        ) || [],
    );

    const [chosenMunicipality, setChosenMunicipality] = useState<TagViewModel | undefined>();
    const [chosenDistrict, setChosenDistrict] = useState<TagViewModel | undefined>();
    const [chosenNeighbourhood, setChosenNeighbourhood] = useState<TagViewModel | undefined>();
    const districtParentTags = useMemo(
        () => (chosenMunicipality ? [chosenMunicipality.id] : undefined),
        [chosenMunicipality],
    );
    const neighbourhoodParentTags = useMemo(
        () => (chosenDistrict ? [chosenDistrict.id] : chosenMunicipality ? [chosenMunicipality.id] : undefined),
        [chosenMunicipality, chosenDistrict],
    );

    const Locations = useMemo(
        () =>
            innerValue.map((item, index) => {
                return (
                    <Location
                        key={index}
                        remove={() => setInnerValue((current) => current.filter((_, i) => i !== index))}
                        value={item}
                        {...props}
                    />
                );
            }),
        [innerValue, props],
    );

    useEffect(() => {
        if (onChange) {
            onChange(innerValue);
        }
    }, [onChange, innerValue]);

    return (
        <div className={styles.selectFields}>
            <div className={styles.locationSearchWindow}>
                <DataListInputTagSearch
                    {...props}
                    list={`${props.list}.1`}
                    name={`${props.name}.1`}
                    placeholder={strings.searchMunicipality}
                    onChange={(e) => {
                        setChosenMunicipality(e);
                        setChosenDistrict(undefined);
                        setChosenNeighbourhood(undefined);
                    }}
                    tagType={TagType.Municipality}
                    value={chosenMunicipality?.detailedName}
                />
                <DataListInputTagSearch
                    {...props}
                    list={`${props.list}.2`}
                    name={`${props.name}.2`}
                    placeholder={strings.searchDistrict}
                    onChange={(e) => {
                        setChosenDistrict(e);
                        setChosenNeighbourhood(undefined);
                    }}
                    tagType={TagType.District}
                    parentTags={districtParentTags}
                    value={chosenDistrict?.detailedName}
                />
                <DataListInputTagSearch
                    {...props}
                    list={`${props.list}.3`}
                    name={`${props.name}.3`}
                    placeholder={strings.searchNeighbourhood}
                    onChange={(e) => {
                        setChosenNeighbourhood(e);
                    }}
                    tagType={TagType.Neighbourhood}
                    parentTags={neighbourhoodParentTags}
                    value={chosenNeighbourhood?.detailedName}
                />
                <div className={styles.addLocation}>
                    <QuickAddButton
                        onClick={() => {
                            if (chosenNeighbourhood) {
                                setInnerValue((current) => [...current, chosenNeighbourhood]);
                                setChosenNeighbourhood(undefined);
                            } else if (chosenDistrict) {
                                setInnerValue((current) => [...current, chosenDistrict]);
                                setChosenDistrict(undefined);
                            } else if (chosenMunicipality) {
                                setInnerValue((current) => [...current, chosenMunicipality]);
                                setChosenMunicipality(undefined);
                            }
                        }}
                    />
                </div>
            </div>
            {Locations}
        </div>
    );
}
