import { PropsWithChildren } from "react";
import cx from "classnames";
import styles from "./SubInputLayout.module.scss";

interface Props {
    className?: string;
}

export default function SubInputLayout({ className, children }: PropsWithChildren<Props>) {
    return <div className={cx(styles.subInput, className)}>{children}</div>;
}
