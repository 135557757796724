import { Link } from "react-router-dom";
import strings from "../../localization/strings";

export default function PageNotFound() {
    return (
        <div>
            <h2 aria-label={strings.pageNotFound}>{strings.pageNotFound}</h2>
            <p>
                <Link to="/" aria-label={strings.backToHome}>
                    {strings.backToHome}
                </Link>
            </p>
        </div>
    );
}
