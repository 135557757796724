import { useHappeningsOverview } from "../../hooks/HappeningsHooks";
import { HappeningState } from "../../openapi/backend";
import { HappeningFilterKeys } from "../../types/HappeningFilterKeys";
import { HappeningSubset } from "../../types/HappeningSubset";
import HappeningsTable from "./HappeningsTable";

const excludeFilters: HappeningFilterKeys = ["happeningTypes"];
const excludeHappeningSubsets: HappeningSubset[] = [HappeningSubset.Internal];
const allowedStates = [HappeningState.Concept, HappeningState.Open, HappeningState.PlannedForPublication];

export default function ParticipantHappeningsContainer() {
    const [happeningsState, getHappenings] = useHappeningsOverview();

    return (
        <HappeningsTable
            happenings={happeningsState.value}
            getHappenings={getHappenings}
            error={happeningsState.error}
            excludeFilters={excludeFilters}
            excludeHappeningSubsets={excludeHappeningSubsets}
            allowedStates={allowedStates}
        />
    );
}
