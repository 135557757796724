import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { emailPattern, MAX_AGE, MAX_TITLE_LENGTH, MIN_AGE, phoneNumber } from "../../../constants/Validation";
import { TagsContext } from "../../../contexts/TagsContext";
import { OrganisationInfoInput, useGetAvailableOrganisationTags } from "../../../hooks/OrganisationInfoHooks";
import { ApiCallState, RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { ImageViewModel, OrganisationInfoViewModel } from "../../../openapi/backend";
import Accordion from "../../core/Accordion/Accordion";
import Form from "../../core/Form/Form/Form";
import FormAddressListInput from "../../core/Form/FormAddressListInput/FormAddressListInput";
import FormCheckbox from "../../core/Form/FormCheckbox/FormCheckbox";
import FormDataListInput from "../../core/Form/FormDataList/FormDataListInput";
import FormExternalReferencesListInput from "../../core/Form/FormExternalReferencesListInput/FormExternalReferencesInput";
import FormField from "../../core/Form/FormField/FormField";
import FormFieldMultiline from "../../core/Form/FormFieldMultiline/FormFieldMultiline";
import FormMediaLibraryInput from "../../core/Form/FormMediaLibraryInput/FormMediaLibraryInput";
import FormPageLayout from "../../FormPageLayout/FormPageLayout";
import styles from "./OrganisationInfoForm.module.scss";
import { validationSchema } from "./OrganisationInfoValidation";

interface Props {
    saveCallback: [
        ApiCallState<OrganisationInfoViewModel>,
        (request: OrganisationInfoInput) => Promise<OrganisationInfoViewModel>,
        () => void,
    ];
    storedImages: ImageViewModel[];
    defaultValues?: OrganisationInfoInput;
}

function prepareForSubmission(data: OrganisationInfoInput) {
    let modifications = {} as Partial<OrganisationInfoInput>;

    modifications.externalLinks = data.externalLinks?.filter((el) => !!el.url && !!el.name);

    return { ...data, ...modifications };
}

export default function OrganisationInfoForm({ saveCallback, storedImages, defaultValues }: Props) {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setFocus,
        watch,
        getValues,
    } = useForm<OrganisationInfoInput>({
        defaultValues,
        resolver: yupResolver(validationSchema) as any,
    });

    const { value: availableOrganisationTags } = useGetAvailableOrganisationTags(defaultValues?.organisationTag);
    const { categories } = useContext(TagsContext).allTags;
    const [{ state, error }, save] = saveCallback;
    const navigate = useNavigate();

    const minAge = watch("minimumAge");
    const maxAge = watch("maximumAge");

    const preparedSave = useCallback<typeof save>((data) => save(prepareForSubmission(data)), [save]);
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const organisationTagOptions = useMemo(
        () => (availableOrganisationTags || []).map((t) => ({ label: t.detailedName, value: t.id })),
        [availableOrganisationTags],
    );

    const categoryOptions = useMemo(
        // Options are all active tags and previously selected category (in case it gets deactivated over time)
        () =>
            (categories || [])
                .filter((tag) => tag.isActive || tag.id === defaultValues?.category)
                .map((t) => ({ label: t.detailedName, value: t.id })),
        [categories, defaultValues?.category],
    );

    useEffect(() => {
        if (state === RequestState.DONE) {
            goBack();
        }
    }, [state, goBack]);

    useEffect(() => {
        setFocus("title");
    }, [setFocus]);

    return (
        <FormPageLayout title={strings.organisationInfoFormTitleNew}>
            <Form
                className={styles.form}
                onSubmit={preparedSave}
                handleSubmit={handleSubmit}
                onDismiss={goBack}
                error={error}
                waitingForSubmit={state === RequestState.LOADING}
            >
                <Accordion className={styles.formSection} title={strings.details} collapsible={false} open>
                    <FormField
                        name={"title"}
                        label={strings.title}
                        register={register}
                        errors={errors.title}
                        required
                        placeholder={""}
                        maxLength={MAX_TITLE_LENGTH}
                    />
                    <FormCheckbox name={"isActive"} label={strings.isVisibleInApp} control={control} />
                    {availableOrganisationTags && (
                        <FormDataListInput
                            name={"organisationTag"}
                            options={organisationTagOptions}
                            label={strings.happeningGroup}
                            control={control}
                            defaultValue={getValues("organisationTag")}
                            errors={errors.organisationTag}
                            list={"OrganisationGroupOptions"}
                        />
                    )}
                    <FormDataListInput
                        name={"category"}
                        options={categoryOptions}
                        label={strings.category}
                        control={control}
                        defaultValue={getValues("category")}
                        errors={errors.category}
                        list={"CategoryOptions"}
                        required
                    />
                    <div className={styles.ageContainer}>
                        <FormField
                            label={strings.targetAudience}
                            name={"minimumAge"}
                            type={"number"}
                            register={register}
                            required
                            min={MIN_AGE}
                            max={MAX_AGE}
                            errors={
                                minAge !== undefined && maxAge !== undefined && minAge > maxAge
                                    ? { type: "pattern", message: strings.ageError }
                                    : errors.minimumAge
                            }
                            placeholder={""}
                            className={styles.ageInputField}
                        />
                        <div className={styles.ageText}>{strings.until}</div>
                        <FormField
                            name={"maximumAge"}
                            type={"number"}
                            register={register}
                            required
                            min={MIN_AGE}
                            max={MAX_AGE}
                            errors={
                                minAge !== undefined && maxAge !== undefined && minAge > maxAge
                                    ? { type: "pattern", message: strings.ageError }
                                    : errors.maximumAge
                            }
                            placeholder={""}
                            className={styles.ageInputField}
                        />
                        <div className={styles.ageText}>{strings.years.toLowerCase()}</div>
                    </div>
                    <FormFieldMultiline
                        rows={4}
                        name={"description"}
                        label={strings.description}
                        register={register}
                        errors={errors.description}
                        required
                    />
                </Accordion>
                <Accordion className={styles.formSection} title={strings.media} collapsible={true} open>
                    <p className={styles.mediaPreviewDescription}>{strings.addMediaDescription}</p>
                    <FormMediaLibraryInput
                        alreadyStoredImages={storedImages}
                        className={styles.mediaPreview}
                        errors={errors.images}
                        control={control}
                        excludeVideos={true}
                        type="organisationInfo"
                    />
                </Accordion>
                <Accordion className={styles.formSection} title={strings.location} collapsible={true} open>
                    <FormAddressListInput name={"locations"} control={control} errors={errors.locations} />
                </Accordion>
                <Accordion className={styles.formSection} title={strings.websiteAndSocials} collapsible={true} open>
                    <FormExternalReferencesListInput
                        name={"externalLinks"}
                        errors={errors.externalLinks}
                        register={register}
                        control={control}
                    />
                </Accordion>
                <Accordion className={styles.formSection} title={strings.contact} collapsible={true} open>
                    <FormField
                        name={"contactName"}
                        label={strings.contactFullName}
                        register={register}
                        errors={errors.contactName}
                        required
                        placeholder={""}
                    />
                    <FormField
                        name={"contactEmail"}
                        label={strings.contactEmail}
                        register={register}
                        errors={errors.contactEmail}
                        required
                        placeholder={""}
                        type="email"
                        validationPattern={{ value: emailPattern, message: strings.invalidEmail }}
                    />
                    <FormField
                        name={"contactPhoneNumber"}
                        label={strings.contactPhone}
                        register={register}
                        errors={errors.contactPhoneNumber}
                        required
                        placeholder={""}
                        validationPattern={{ value: phoneNumber, message: strings.invalidPhoneNumber }}
                    />
                </Accordion>
            </Form>
        </FormPageLayout>
    );
}
