import styles from "./InlineLoading.module.scss";
import cx from "classnames";

interface InlineLoadingProps {
    className?: string;
    alternative?: boolean;
}

const InlineLoading = ({ className = "", alternative }: InlineLoadingProps) => (
    <div className={cx(styles.container, className)}>
        <div className={cx(styles.loadEllipsis, alternative && styles.alternative)}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
        </div>
    </div>
);

export default InlineLoading;
