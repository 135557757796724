/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum UserOrderBy {
    Default = "Default",
    Name = "Name",
    GivenName = "GivenName",
    FamilyName = "FamilyName",
    Email = "Email",
    DateOfBirth = "DateOfBirth",
    Profession = "Profession",
    PhoneNumber = "PhoneNumber",
    Municipality = "Municipality",
    HappeningGroup = "HappeningGroup",
    PartnerGroup = "PartnerGroup",
    Role = "Role",
}

export function UserOrderByFromJSON(json: any): UserOrderBy {
    return UserOrderByFromJSONTyped(json, false);
}

export function UserOrderByFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOrderBy {
    return json as UserOrderBy;
}

export function UserOrderByToJSON(value?: UserOrderBy | null): any {
    return value as any;
}
