import strings from "../localization/strings";
import { NotificationGroup } from "../openapi/backend";

export function getStringForNotificationGroup(type: string) {
    switch (type) {
        case NotificationGroup.Participants:
            return strings.participants;
        case NotificationGroup.Editors:
            return strings.editors;
        case NotificationGroup.Partners:
            return strings.partners;
        case NotificationGroup.OrganizationAccounts:
            return strings.accounts;
        case NotificationGroup.ApplicationAdmins:
            return strings.applicationAdmins;
        case NotificationGroup.Approvers:
            return strings.approvers;
        case NotificationGroup.Marketing:
            return strings.marketeers;
        case NotificationGroup.UserAdmins:
            return strings.userAdmins;
        case NotificationGroup.Intermediaries:
            return strings.intermediaries;
        default:
            return strings.unknown;
    }
}
