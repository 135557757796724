import { Control, Controller, FieldError, FieldValues } from "react-hook-form";
import MultiDataListInput, { MultiDataListInputProps } from "../../Inputs/MultiDataListInput/MultiDataListInput";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import { FieldName } from "../FormTypes";

export interface FormMultiDataListInputProps<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, string[]>,
> extends Omit<MultiDataListInputProps, "onChange"> {
    label: string;
    name: TName;
    control: Control<TFieldValues, object>;
    errors?: FieldError[] | FieldError;
}

export default function FormMultiDataListInput<
    TFieldValues extends FieldValues,
    TName extends FieldName<TFieldValues, string[]>,
>({ control, name, label, errors, required, ...fieldProps }: FormMultiDataListInputProps<TFieldValues, TName>) {
    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <Controller
                name={name}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, value } }) => (
                    <MultiDataListInput {...fieldProps} name={name} value={value} onChange={onChange} />
                )}
            />
        </FormFieldContainer>
    );
}
