import { Link, useLocation } from "react-router-dom";
import styles from "./NavigationMenuItem.module.scss";

interface NavigationMenuItemProps {
    icon: string;
    text: string;
    pathname: string;
    showText: boolean;
}

export default function NavigationMenuItem({ icon, text, pathname, showText }: NavigationMenuItemProps) {
    const location = useLocation();

    const currentlyActive = () => {
        if (location.pathname.split("/")[1] === pathname.split("/")[1]) {
            return true;
        }
        if (location.pathname !== "/" && pathname !== "/") {
            return location.pathname.replace(/^\/+/, "").startsWith(pathname.replace(/^\/+/, ""));
        }
    };

    return (
        <Link
            to={pathname}
            id={`linkTo-${text}`}
            className={`${styles.menuItem} ${currentlyActive() && styles.active}`}
        >
            <img src={icon} alt={text} className={styles.icon} />
            {showText && (
                <label htmlFor={`linkTo-${text}`} className={styles.text}>
                    {text}
                </label>
            )}
        </Link>
    );
}
