import { ChangeEvent, WheelEventHandler, useCallback, useEffect, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form/dist/types/form";
import styles from "./InputField.module.scss";
import { asNumber } from "../../../../utils.ts/Formatting";
import LengthIndicator from "../../LengthIndicator/LengthIndicator";
import cx from "classnames";

export interface InputFieldProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    formRegistration?: UseFormRegisterReturn;
    invalid?: boolean;
    isLoading?: boolean;
}
const InputField = ({ maxLength, type, ...props }: InputFieldProps) => {
    if (maxLength) {
        return <MaxLengthField maxLength={maxLength} type={type} {...props} />;
    } else if (type === "number") {
        return <NumberField type={type} {...props} />;
    }

    return <Field type={type} {...props} dropdown={!!props.list} />;
};

const Field = ({
    className,
    formRegistration,
    invalid,
    dropdown,
    isLoading,
    ...props
}: InputFieldProps & { dropdown?: boolean }) => (
    <input
        className={cx(
            styles.inputField,
            className,
            invalid && styles.invalid,
            dropdown && !props.readOnly && styles.inputFieldDropdown,
            isLoading && !props.readOnly && styles.inputFieldLoading,
        )}
        {...formRegistration}
        {...props}
        autoComplete="off"
    />
);

const MaxLengthField = ({
    maxLength,
    value,
    defaultValue,
    onChange,
    ...props
}: InputFieldProps & { maxLength: number }) => {
    const initValue = value ?? defaultValue;
    const [innerValue, setValue] = useState(typeof initValue === "number" ? initValue.toString() : initValue ?? "");
    const innerOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.currentTarget.value);
            onChange && onChange(e);
        },
        [onChange, setValue],
    );

    useEffect(() => {
        initValue && setValue(initValue as any);
    }, [initValue, setValue]);

    return (
        <div className={styles.lengthContainer}>
            <Field value={innerValue} onChange={innerOnChange} maxLength={maxLength} {...props} />
            {maxLength && <LengthIndicator length={innerValue.length} maxLength={maxLength} />}
        </div>
    );
};

// Our numeric inputs have the habit of changing values when scrolling on them while they are focused.
// In Chrome, this gives a weird behaviour where both the page and the value is 'scrolled'.
// The blur -> focus sequence allows us to prevent changing value, but continue the page scroll.
// With just preventDefault, the page won't scroll either.
const preventValueChangeOnScroll: WheelEventHandler<HTMLInputElement> = (e) => {
    const target = e.currentTarget;
    // in case a different element is focused, we don't have to do anything
    if (target !== document.activeElement) {
        return;
    }

    target.blur();
    e.preventDefault();
};

const NumberField = ({ value, defaultValue, onChange, formRegistration, onWheel, ...props }: InputFieldProps) => {
    const initValue = value ?? defaultValue;
    const [innerValue, setValue] = useState<number | undefined>(typeof initValue === "number" ? initValue : undefined);
    const innerOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setValue(asNumber(e.currentTarget.value));
            var temp = onChange || formRegistration?.onChange;
            temp && temp(e);
        },
        [onChange, formRegistration],
    );

    useEffect(() => {
        initValue !== undefined && setValue(initValue as any);
    }, [initValue, setValue]);

    const onWheelCombi = useCallback<WheelEventHandler<HTMLInputElement>>(
        (e) => {
            if (onWheel) {
                onWheel(e);
            }

            preventValueChangeOnScroll(e);
        },
        [onWheel],
    );

    return (
        <Field
            onWheel={onWheelCombi}
            onChange={innerOnChange}
            value={innerValue ?? ""}
            formRegistration={formRegistration}
            {...props}
        />
    );
};

export default InputField;
