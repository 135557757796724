import { useState, useCallback } from "react";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import { TextModal } from "../Modal/Modal";
import TextButton, { TextButtonProps } from "../TextButton/TextButton";

type ConfirmationButtonProps = {
    onConfirm: () => void;
    title: string;
    body: string;
    confirmLabel?: string;
    cancelLabel?: string;
    state?: RequestState;
} & Omit<TextButtonProps, "onClick" | "isLoading">;

/**
 * A textbutton that opens a modal to confirm the user's action before actually executing it.
 */
export const ConfirmationButton = ({
    title,
    body,
    state,
    confirmLabel,
    cancelLabel,
    onConfirm,
    ...buttonProps
}: ConfirmationButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleConfirm = useCallback(() => {
        onConfirm();
        setIsOpen(false);
    }, [onConfirm]);

    return (
        <>
            <TextButton
                onClick={() => setIsOpen(true)}
                isLoading={state === RequestState.LOADING}
                disabled={state === RequestState.LOADING}
                {...buttonProps}
            />
            <TextModal
                isOpen={isOpen}
                onDismiss={() => setIsOpen(false)}
                title={title}
                text={body}
                Buttons={
                    <>
                        <TextButton text={confirmLabel ?? strings.yes} onClick={handleConfirm} />
                        <TextButton
                            text={cancelLabel ?? strings.no}
                            onClick={() => setIsOpen(false)}
                            buttonType="alternate"
                        />
                    </>
                }
            />
        </>
    );
};
