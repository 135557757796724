import strings from "../localization/strings";
import { ReasonForCancellation } from "../openapi/backend/models/ReasonForCancellation";

export default function getStringForReason(reason: ReasonForCancellation) {
    switch (reason) {
        case ReasonForCancellation.BadWeather:
            return strings.badWeather;
        case ReasonForCancellation.InsufficientRegistrations:
            return strings.insufficientRegistrations;
        case ReasonForCancellation.UnforeseenCircumstances:
            return strings.unforseenCircumstances;
        default:
            return strings.unknown;
    }
}
