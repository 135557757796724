import { useCallback, useContext } from "react";
import {
    GetMeasurementsRequest,
    GetMyMeasurementsRequest,
    MeasurementListOutputPaginatedViewModel,
    MeasurementOrderBy,
    SortOrder,
    TagViewModel,
} from "../openapi/backend";
import { ApiCallState, useApiCall, useApiCallback } from "./UseApiCall";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { MeasurementsApiContext } from "../contexts/MeasurementsApiContext";
import { UserNamedType } from "../types/UserNamedType";
import { MeasurementsStateOptions } from "../types/DropdownOption";
import { MeasurementInputModel } from "../models/IndicatorModels";
import { measurementInputToData } from "../utils.ts/MeasurementUtils";

export type MeasurementsFilter = Pick<GetMeasurementsRequest, "query" | "date" | "page"> & {
    statuses?: MeasurementsStateOptions[];
    organisations?: TagViewModel[];
    organizers?: UserNamedType[];
    measurers?: UserNamedType[];
};

export type MyMeasurementsFilter = Pick<GetMyMeasurementsRequest, "query" | "date" | "page"> & {
    statuses?: MeasurementsStateOptions[];
    organisations?: TagViewModel[];
    organizers?: UserNamedType[];
};

export type GetMeasurementsFun = (
    orderBy: MeasurementOrderBy,
    sortOrder: SortOrder,
    filters: MeasurementsFilter,
) => void;

export const useGetMeasurements = (): [
    ApiCallState<MeasurementListOutputPaginatedViewModel>,
    GetMeasurementsFun,
    () => void,
] => {
    const api = useContext(MeasurementsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, filter: MeasurementsFilter = {}) => {
            const transformedFilter = {
                statuses: filter.statuses?.map((status) => status.value),
                organisations: filter.organisations?.map((org) => org.id),
                organizers: filter?.organizers?.map((organizer) => organizer.id),
                measurers: filter?.measurers?.map((measurer) => measurer.id),
            };

            return api.getMeasurements({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                ...filter,
                ...transformedFilter,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export type GetMyMeasurementsFun = (
    orderBy: MeasurementOrderBy,
    sortOrder: SortOrder,
    filters: MyMeasurementsFilter,
) => void;

export const useGetMyMeasurements = (): [
    ApiCallState<MeasurementListOutputPaginatedViewModel>,
    GetMyMeasurementsFun,
    () => void,
] => {
    const api = useContext(MeasurementsApiContext);
    const callback = useCallback(
        (orderBy, sortOrder, filter: MyMeasurementsFilter = {}) => {
            const transformedFilter = {
                statuses: filter.statuses?.map((status) => status.value),
                organisations: filter.organisations?.map((org) => org.id),
                organizers: filter?.organizers?.map((organizer) => organizer.id),
            };

            return api.getMyMeasurements({
                version: REACT_APP_BACKEND_VERSION,
                orderBy,
                sortOrder,
                ...filter,
                ...transformedFilter,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useDeleteMeasurement = () => {
    const api = useContext(MeasurementsApiContext);
    const callback = useCallback(
        (id: number) => api.deleteMeasurement({ version: REACT_APP_BACKEND_VERSION, id }),
        [api],
    );
    return useApiCallback(callback);
};

export const useGetMeasurement = (id: number) => {
    const api = useContext(MeasurementsApiContext);
    const callback = useCallback(() => api.getMeasurement({ version: REACT_APP_BACKEND_VERSION, id }), [api, id]);
    return useApiCall(callback);
};

export const useUpdateMeasurement = (id: number) => {
    const api = useContext(MeasurementsApiContext);
    const callback = useCallback(
        (input: MeasurementInputModel) =>
            api.updateMeasurement({
                version: REACT_APP_BACKEND_VERSION,
                ...measurementInputToData(input),
                id,
            }),
        [api, id],
    );
    return useApiCallback(callback);
};
