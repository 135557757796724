import styles from "./DurationInput.module.scss";
import { StringSelectField } from "../SelectField/SelectField";
import { hourSelectOptions, minuteSelectOptions, prependZero } from "../../../../types/TimeOptions";

export interface DurationInputProps {
    name: string;
    value: number; // minutes
    onChange: (value: number) => void;
}

export default function DurationInput({ value, onChange, name }: DurationInputProps) {
    const hours = Math.min(Math.floor(value / 60), 23);
    const minutes = Math.floor((value % 60) / 5) * 5;

    return (
        <div className={styles.container}>
            <StringSelectField
                name={`${name}_hours`}
                value={prependZero(hours)}
                onChange={(h) => onChange(toMinutes(Number(h), minutes))}
                options={hourSelectOptions}
            />
            <div className={styles.colon}>:</div>
            <StringSelectField
                name={`${name}_minutes`}
                value={prependZero(minutes)}
                onChange={(m) => onChange(toMinutes(hours, Number(m)))}
                options={minuteSelectOptions}
            />
        </div>
    );
}

function toMinutes(hours: number, minutes: number) {
    return hours * 60 + minutes;
}
