import React, { PropsWithChildren } from "react";
import { Configuration } from "../openapi/backend";
import { NotificationsApi } from "../openapi/backend/apis/NotificationsApi";
import { ApiContextProvider, CreateApi } from "./BackendApiContext";

const createApi: CreateApi<NotificationsApi> = (params) => new NotificationsApi(new Configuration(params));

export const NotificationsApiContext = React.createContext(createApi({}));

export const NotificationsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={NotificationsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
