import { ApplicationEventOutput, ArrangementApplicationOperation, ProfileShortOutput } from "../../../openapi/backend";
import styles from "./ApplicationEventHistory.module.scss";
import cx from "classnames";
import { PropsWithChildren, useMemo } from "react";
import { hasValues } from "../../../utils.ts/Array";
import strings from "../../../localization/strings";
import SystemBotIcon from "../../../assets/system_bot_icon.svg";
import ProfileIcon from "../AvatarIcon/ProfileIcon";
import { Size } from "../../../types/Size";
import { getName } from "../../../utils.ts/GetName";
import { formatDate, formatTime } from "../../../utils.ts/DateTime";
import { getApplicationChangeEventValue, getApplicationFieldName } from "../../../utils.ts/Application";

interface ChangeLineProps {
    operation: ArrangementApplicationOperation;
    additionalData: ApplicationEventOutput;
}

function ImportantText({ children }: PropsWithChildren<{}>) {
    return <span className={styles.important}>{children}</span>;
}

function Text({ children }: PropsWithChildren<{}>) {
    return <span className={styles.text}>{children}</span>;
}

function ChangeLine({ operation: { path, op, value }, additionalData }: ChangeLineProps) {
    switch (op) {
        case "replace":
        case "add":
            return (
                <>
                    <ImportantText>{getApplicationFieldName(path)}</ImportantText>
                    <Text>{`${strings.changeLineReplace}`}</Text>
                    <ImportantText>{getApplicationChangeEventValue(path, value, additionalData)}</ImportantText>
                    <Text>.</Text>
                </>
            );
        case "remove":
            return (
                <>
                    <ImportantText>{getApplicationFieldName(path)}</ImportantText>
                    <Text>{` ${strings.changeLineRemove}.`}</Text>
                </>
            );
    }

    return null;
}

interface Props {
    className?: string;
    events: ApplicationEventOutput[];
}

const systemUser = {
    id: "sbg-system-bot",
    familyName: strings.systemName,
    givenName: "",
    avatar: {
        altText: strings.systemName,
        fileName: "sbg-bot.svg",
        href: SystemBotIcon,
    },
} as ProfileShortOutput;

export default function ApplicationEventHistory({ className, events }: Props) {
    const hasHistory = useMemo(() => hasValues(events), [events]);
    return (
        <div className={cx(styles.container, className)}>
            <h2 className={styles.header}>{strings.eventHistory}</h2>
            {hasHistory ? (
                <div className={styles.history}>
                    {events.map((event) => {
                        const triggerer = event.triggeredBy || systemUser;
                        return (
                            <div key={event.id} className={styles.row}>
                                <ProfileIcon className={styles.avatar} profile={triggerer} size={Size.MEDIUM} />
                                <div className={styles.details}>
                                    <ul className={styles.changes}>
                                        {!!event.statusChange && (
                                            <ChangeLine
                                                operation={{
                                                    path: "/status",
                                                    value: event.statusChange as any,
                                                    op: "replace",
                                                }}
                                                additionalData={event}
                                            />
                                        )}
                                        {event.change.map((o) => {
                                            return (
                                                <li key={o.path} className={styles.changeLine}>
                                                    <ChangeLine operation={o} additionalData={event} />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    {event.message && (
                                        <div className={styles.messageContainer}>
                                            <ImportantText>{strings.messageToApplicant}</ImportantText>
                                            <div className={styles.message}>{event.message}</div>
                                        </div>
                                    )}
                                    <div className={styles.logMeta}>
                                        <span className={styles.triggerer}>{`${getName(triggerer)}, `}</span>
                                        <span className={styles.timestamp}>
                                            {strings.formatString(
                                                strings.timestamp,
                                                formatDate(event.date, "dd DD/MM/YYYY"),
                                                formatTime(event.date),
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div>Ooops</div>
            )}
        </div>
    );
}
