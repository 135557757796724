import { Profile } from "../contexts/ProfileContext";
import { Role } from "../openapi/backend";

function checkRole(role: Role): RoleCheck {
    return (user?: Profile) => user?.roles.includes(role) ?? false;
}

export type RoleCheck = (user?: Profile) => boolean;

export const isUserAdmin: RoleCheck = checkRole(Role.UserAdmin);
export const isApplicationAdmin: RoleCheck = checkRole(Role.ApplicationAdmin);
export const isChiefEditor: RoleCheck = checkRole(Role.ChiefEditor);
export const isEditor: RoleCheck = checkRole(Role.Editor);
export const isPartner: RoleCheck = checkRole(Role.Partner);
export const isMarketing: RoleCheck = checkRole(Role.Marketing);
export const isApprover: RoleCheck = checkRole(Role.Approver);
export const isChiefIntermediary: RoleCheck = checkRole(Role.ChiefIntermediary);
export const isIntermediary: RoleCheck = checkRole(Role.Intermediary);
export const isPartnerIntermediary: RoleCheck = checkRole(Role.PartnerIntermediary);
