/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 * - Interpret an unitialized array as an empty array.
 * - If we specify a type for an object, make sure it is the first item in the object. This is required for the .NET deserialisation.
 * - Always check if an object is not null when parsing.
 */

import { exists, mapValues } from "../runtime";
import {
    HappeningState,
    HappeningStateFromJSON,
    HappeningStateFromJSONTyped,
    HappeningStateToJSON,
    UsageType,
    UsageTypeFromJSON,
    UsageTypeFromJSONTyped,
    UsageTypeToJSON,
} from "./";

/**
 *
 * @export
 * @interface MediaUsage
 */
export interface MediaUsage {
    /**
     *
     * @type {UsageType}
     * @memberof MediaUsage
     */
    type: UsageType;
    /**
     *
     * @type {string}
     * @memberof MediaUsage
     */
    name: string;
    /**
     *
     * @type {HappeningState}
     * @memberof MediaUsage
     */
    status?: HappeningState;
}

/**
 * Check if a given object implements the MediaUsage interface.
 */
export function instanceOfMediaUsage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function MediaUsageFromJSON(json: any): MediaUsage {
    return MediaUsageFromJSONTyped(json, false);
}

export function MediaUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaUsage {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, "type") ? (undefined as any) : UsageTypeFromJSON(json["type"]),
        name: !exists(json, "name") ? (undefined as any) : json["name"],
        status: !exists(json, "status") ? (undefined as any) : HappeningStateFromJSON(json["status"]),
    };
}

export function MediaUsageToJSON(value?: MediaUsage | null): any {
    if ((value as any).type !== undefined) {
        return {
            type: (value as any).type,
            ...value,
        };
    }
    return value;
}
