export function hasValues<T>(array: Array<T> | undefined): array is Array<T> {
    if (!array) {
        return false;
    }

    return array.length !== 0;
}

// Removes duplicates from array by only preserving the first occurrence of an item
export function removeDuplicates<T>(array: Array<T>, comparer: (a: T, b: T) => boolean = (a, b) => a === b) {
    return array.filter((item, index) => array.findIndex((other) => comparer(item, other)) === index);
}

// Just make it a damn array!
export function kajify<T>(value: T | T[] | undefined): T[] {
    if (value === undefined) {
        return [];
    }

    return Array.isArray(value) ? value : [value];
}
