import { useGetNotifications } from "../../hooks/NotificationHooks";
import NotificationsHistoryTable from "./NotificationsHistoryTable";
import styles from "./Notifications.module.scss";

interface HappeningNotificationsHistoryContainerProps {
    id: string;
}

export default function HappeningNotificationsHistoryContainer({ id }: HappeningNotificationsHistoryContainerProps) {
    const [notificationsState, getNotifications] = useGetNotifications();

    return (
        <NotificationsHistoryTable
            notifications={notificationsState.value}
            getNotifications={getNotifications}
            error={notificationsState.error}
            id={id}
            tableStyle={styles.happeningNotificationsTable}
            className={styles.happeningNotificationsContainer}
        />
    );
}
