import { useEffect, useState } from "react";
import { ArrayPath, Control, FieldError, useFieldArray, UseFormRegister } from "react-hook-form";
import { mediaUrl } from "../../../../constants/Validation";
import strings from "../../../../localization/strings";
import { QuickAddButton, RemoveButton } from "../../RoundButton/RoundButton";
import FormField from "../FormField/FormField";
import styles from "./FormListVideo.module.scss";

interface FormListVideoProps<T> {
    name: ArrayPath<T>;
    register: UseFormRegister<T>;
    control: Control<T>;
    errors?: { href?: FieldError; altText?: FieldError }[];
}

export default function FormListVideo<T>({ name, register, control, errors, ...props }: FormListVideoProps<T>) {
    const { fields, append, remove, prepend, update } = useFieldArray<any>({ control, name });

    const [firstField, setFirstField] = useState("");
    const [firstFieldTitle, setFirstFieldTitle] = useState("");
    const [firstFieldError, setFirstFieldError] = useState(undefined as any);
    const [firstFieldTitleError, setFirstFieldTitleError] = useState(undefined as any);

    useEffect(() => {
        if (!firstField.match(mediaUrl) && firstField.length > 0) {
            setFirstFieldError({ type: "pattern", message: strings.urlError });
        } else {
            setFirstFieldError(undefined);
        }
    }, [firstField]);

    useEffect(() => {
        if (!(firstFieldTitle.length > 0) && firstField.length > 0) {
            setFirstFieldTitleError({ type: "required", message: strings.mandatoryField });
        } else {
            setFirstFieldTitleError(undefined);
        }
    }, [firstField, firstFieldTitle]);

    useEffect(() => {
        prepend({ altText: "", href: "" }, { shouldFocus: false });
    }, [prepend]);

    return (
        <div className={styles.container}>
            {fields.map((video, index) => {
                return (
                    <div className={styles.listContainer} key={video.id}>
                        <div className={styles.video}>
                            <FormField
                                key={video.id}
                                name={`${name}.${index}.href`}
                                register={register}
                                errors={
                                    (index === 0 && firstFieldError) || (errors && errors[index] && errors[index].href)
                                }
                                required={index === 0 ? false : true}
                                placeholder={strings.addVideo}
                                validationPattern={{
                                    value: mediaUrl,
                                    message: strings.urlError,
                                }}
                                onChange={(event) => index === 0 && setFirstField(event.target.value)}
                                inputClassName={styles.inputNoMargin}
                                {...props}
                            />
                        </div>

                        <div className={styles.videoTitle}>
                            <FormField
                                key={video.id}
                                name={`${name}.${index}.altText`}
                                register={register}
                                errors={
                                    (index === 0 && firstFieldTitleError) ||
                                    (errors && errors[index] && errors[index].altText)
                                }
                                required={firstField.length > 0 ? true : false}
                                placeholder={strings.videoTitle}
                                onChange={(event) => index === 0 && setFirstFieldTitle(event.target.value)}
                                inputClassName={styles.inputNoMargin}
                                {...props}
                            />
                        </div>
                        {index === 0 ? (
                            <QuickAddButton
                                disabled={
                                    firstFieldError ||
                                    firstFieldTitleError ||
                                    firstField.length === 0 ||
                                    firstFieldTitle.length === 0
                                }
                                onClick={() => {
                                    if (!firstFieldError && !firstFieldTitleError) {
                                        append({ altText: firstFieldTitle, href: firstField });
                                        setFirstField("");
                                        setFirstFieldTitle("");
                                        update(0, { altText: "", href: "" });
                                    }
                                }}
                                className={styles.button}
                            />
                        ) : (
                            <RemoveButton onClick={() => remove(index)} className={styles.button} />
                        )}
                    </div>
                );
            })}
        </div>
    );
}
