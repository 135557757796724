import { useCallback } from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import strings from "../../../localization/strings";
import { ArrangementApplicationInput, ApplicationStatus } from "../../../openapi/backend";
import TextButton from "../../core/TextButton/TextButton";
import TextButtonWithMessageModal from "../../core/TextButtonWithMessageModal/TextButtonWithMessageModal";
import { PatchFunction } from "./SharedApplicationTypes";

type Props = {
    onPatch: PatchFunction;
    isLoading: boolean;
    handleSubmit: UseFormHandleSubmit<ArrangementApplicationInput>;
    status?: ApplicationStatus;
};

export default function SecondaryButtons({ onPatch, isLoading, handleSubmit, status }: Props) {
    const onRequestAction = useCallback(
        (message: string) => {
            handleSubmit((data) => onPatch(data, { nextStatus: ApplicationStatus.PendingAction, message }))();
        },
        [handleSubmit, onPatch],
    );

    const onApplicationSubmitted = useCallback(() => {
        handleSubmit((data) => onPatch(data, { nextStatus: ApplicationStatus.Submitted }))();
    }, [handleSubmit, onPatch]);

    const onApplicationNotSubmitted = useCallback(
        (message: string) => {
            handleSubmit((data) => onPatch(data, { nextStatus: ApplicationStatus.NotSubmitted, message }))();
        },
        [handleSubmit, onPatch],
    );

    const onApplicationApproved = useCallback(() => {
        handleSubmit((data) => onPatch(data, { nextStatus: ApplicationStatus.Approved }))();
    }, [handleSubmit, onPatch]);

    const onApplicationRejected = useCallback(
        (message: string) => {
            handleSubmit((data) => onPatch(data, { nextStatus: ApplicationStatus.Rejected, message }))();
        },
        [handleSubmit, onPatch],
    );

    if (!status) {
        return null;
    }

    if ([ApplicationStatus.InReview].includes(status)) {
        return (
            <>
                <TextButton
                    onClick={onApplicationSubmitted}
                    text={strings.saveAndSubmitApplication}
                    buttonType={"alternate"}
                    isLoading={isLoading}
                />
                <TextButtonWithMessageModal
                    onSubmit={onRequestAction}
                    text={strings.saveAndRequestAction}
                    title={strings.saveAndRequestAction}
                    inputTitle={strings.headerMessageRequestAction}
                    isLoading={isLoading}
                />
                <TextButtonWithMessageModal
                    onSubmit={onApplicationNotSubmitted}
                    text={strings.saveAndDontSubmitApplication}
                    title={strings.saveAndDontSubmitApplication}
                    inputTitle={strings.headerMessageNotSubmitted}
                    isLoading={isLoading}
                />
            </>
        );
    }

    if ([ApplicationStatus.Submitted].includes(status)) {
        return (
            <>
                <TextButton
                    onClick={onApplicationApproved}
                    text={strings.saveAndApproveApplication}
                    buttonType={"alternate"}
                    isLoading={isLoading}
                />
                <TextButtonWithMessageModal
                    onSubmit={onApplicationRejected}
                    text={strings.saveAndRejectApplication}
                    title={strings.saveAndRejectApplication}
                    inputTitle={strings.headerMessageRejected}
                    isLoading={isLoading}
                />
            </>
        );
    }

    return null;
}
