import { useEffect, useState, Fragment } from "react";
import strings from "../../../localization/strings";
import styles from "./ErrorMessage.module.scss";
import { toastError } from "../../../utils.ts/Toaster";

interface ErrorMessageProps {
    error: Response;
    defaultMessage?: string;
    isToast?: boolean;
}

interface FieldErrors {
    [key: string]: string[];
}

export interface DotnetError {
    errors?: FieldErrors;
    status: number;
    title: string;
    traceId?: string;
}

const ERROR_CONTENT_TYPES = [
    "application/json",
    "application/problem+json; charset=utf-8",
    "application/json; charset=utf-8",
];

const ErrorMessage = ({ error, defaultMessage = strings.somethingWentWrong, isToast = false }: ErrorMessageProps) => {
    const [parsedError, setParsedError] = useState<DotnetError | undefined>();
    useEffect(() => {
        if (!ERROR_CONTENT_TYPES.includes(error.headers.get("content-type") || "")) {
            setParsedError({ status: error.status, title: strings.unknownError, errors: {} });
            return;
        }

        error
            .clone()
            .json()
            .then((value) => setParsedError(value as DotnetError));
    }, [error, setParsedError]);

    useEffect(() => {
        if (isToast && parsedError) {
            let errorMessage = defaultMessage;

            if (parsedError.errors) {
                const keys = Object.keys(parsedError.errors);
                errorMessage = keys
                    .map((key, idx) => {
                        let message = `${parsedError.errors![key]} - (${key})`;
                        return keys.length > 1 ? `${(idx + 1).toString()}. ${message}` : message;
                    })
                    .join(", ");
            }

            toastError(`${errorMessage} - ${strings.error} (${parsedError.status})`);
        }
    }, [defaultMessage, isToast, parsedError]);

    if (isToast || !parsedError) {
        return null;
    }

    return (
        <div className={styles.error}>
            <div>
                {strings.error} ({parsedError.status})
            </div>
            {parsedError.errors ? (
                <dl>
                    {Object.keys(parsedError.errors).map((key, index) => (
                        <Fragment key={index}>
                            <dt>{key}</dt>
                            <dd>{parsedError.errors![key]}</dd>
                        </Fragment>
                    ))}
                </dl>
            ) : (
                <div>{defaultMessage}</div>
            )}
        </div>
    );
};

export default ErrorMessage;
