import { UseFormRegister, FieldError } from "react-hook-form";
import cx from "classnames";
import FormFieldContainer from "../FormFieldContainer/FormFieldContainer";
import styles from "./FormFieldMultiline.module.scss";
import { useState } from "react";
import LengthIndicator from "../../LengthIndicator/LengthIndicator";

interface FormFieldMultilineProps {
    name: string;
    label: string;
    register: UseFormRegister<any>;
    rows: number;
    errors?: FieldError;
    placeholder?: string;
    required?: boolean;
    maxLength?: number;
    canResize?: boolean;
    readOnly?: boolean;
}

export default function FormFieldMultiline({
    name,
    label,
    register,
    rows,
    errors,
    placeholder,
    required,
    maxLength,
    canResize = true,
    readOnly,
}: FormFieldMultilineProps) {
    const [length, setLength] = useState(0);
    return (
        <FormFieldContainer name={name} label={label} errors={errors}>
            <textarea
                id={`textarea-${name}`}
                rows={rows}
                {...register(name, { required: required })}
                placeholder={placeholder}
                className={cx(styles.formFieldMultiline, canResize ? undefined : styles.noResize)}
                maxLength={maxLength}
                onChange={(e) => setLength(e.target.value.length)}
                readOnly={readOnly}
            />
            {maxLength && <LengthIndicator length={length} maxLength={maxLength} />}
        </FormFieldContainer>
    );
}
