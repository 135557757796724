import { useCallback, useContext } from "react";
import { ApiCallState, useApiCallback } from "./UseApiCall";
import { REACT_APP_BACKEND_VERSION } from "../utils.ts/Env";
import { NotificationsApiContext } from "../contexts/NotificationsApiContext";
import {
    GeneralNotificationAudienceCountRequest,
    GetNotificationsRequest,
    SendGeneralNotificationRequest,
    SendHappeningNotificationRequest,
} from "../openapi/backend/apis/NotificationsApi";
import { TagViewModel } from "../openapi/backend/models/TagViewModel";
import { NotificationViewModelPaginatedViewModel } from "../openapi/backend/models/NotificationViewModelPaginatedViewModel";

export type SendNotificationInput = Omit<SendGeneralNotificationRequest, "version"> & {
    areas: Omit<TagViewModel, "childTags">[];
    interests: string[];
    partnerGroups: string[];
    happeningGroups: string[];
};

export type GeneralNotificationAudienceCountRequestInput = Omit<GeneralNotificationAudienceCountRequest, "version">;

export type SendHappeningNotificationInput = Omit<SendHappeningNotificationRequest, "version">;
export type GetNotificationInput = Omit<GetNotificationsRequest, "version">;
export type NotificationsFilter = Pick<GetNotificationsRequest, "query">;

export type GetNotificationsFun = (
    input: GetNotificationInput,
    filter: NotificationsFilter,
    id?: string,
) => Promise<NotificationViewModelPaginatedViewModel>;

export const useSendGeneralNotification = () => {
    const api = useContext(NotificationsApiContext);
    const callback = useCallback(
        (input: SendNotificationInput) => {
            const areas = input.areas || [];

            return api.sendGeneralNotification({
                version: REACT_APP_BACKEND_VERSION,
                generalFilterOptionsPartnerTags: input.partnerGroups,
                generalFilterOptionsHappeningTypeTags: input.interests,
                generalFilterOptionsHappeningGroupTags: input.happeningGroups || [],
                generalFilterOptionsLocationTags: areas.map((tag) => tag.id) || [],
                ...input,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useGeneralNotificationAudienceCount = (): [
    ApiCallState<number>,
    (request: GeneralNotificationAudienceCountRequestInput) => void,
    () => void,
] => {
    const api = useContext(NotificationsApiContext);
    const callback = useCallback(
        (input: GeneralNotificationAudienceCountRequestInput) => {
            return api.generalNotificationAudienceCount({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useSendHappeningNotification = () => {
    const api = useContext(NotificationsApiContext);
    const callback = useCallback(
        (input: SendHappeningNotificationInput) => {
            return api.sendHappeningNotification({
                version: REACT_APP_BACKEND_VERSION,
                ...input,
            });
        },
        [api],
    );
    return useApiCallback(callback);
};

export const useGetNotifications = (): [
    ApiCallState<NotificationViewModelPaginatedViewModel>,
    GetNotificationsFun,
    () => void,
] => {
    const api = useContext(NotificationsApiContext);
    const callback = useCallback<GetNotificationsFun>(
        (input, filter, id?: string) => {
            if (id) {
                return api.getHappeningNotifications({
                    version: REACT_APP_BACKEND_VERSION,
                    ...filter,
                    ...input,
                    id,
                });
            } else {
                return api.getNotifications({
                    version: REACT_APP_BACKEND_VERSION,
                    ...filter,
                    ...input,
                });
            }
        },
        [api],
    );
    return useApiCallback(callback);
};
