import strings from "../localization/strings";
import { ApplicationStatus } from "../openapi/backend";

export function applicationStatusToString(status: ApplicationStatus): string {
    return getStringForApplicationStatus(status);
}

function getStringForApplicationStatus(status: ApplicationStatus) {
    switch (status) {
        case ApplicationStatus.Approved:
            return strings.applicationStatusApproved;
        case ApplicationStatus.InReview:
            return strings.applicationStatusInReview;
        case ApplicationStatus.NotSubmitted:
            return strings.applicationStatusNotSubmitted;
        case ApplicationStatus.PendingAction:
            return strings.applicationStatusPendingAction;
        case ApplicationStatus.Received:
            return strings.applicationStatusReceived;
        case ApplicationStatus.Rejected:
            return strings.applicationStatusRejected;
        case ApplicationStatus.Submitted:
            return strings.applicationStatusSubmitted;
        default:
            return strings.unknown;
    }
}
