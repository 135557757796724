import { MEDIA_LIBRARY_MY_CATEGORY_ID, MEDIA_LIBRARY_UNCATEGORIZED_ID } from "../constants/MediaLibraryConstants";
import strings from "../localization/strings";
import { MediaCategoryViewModel } from "../openapi/backend";

export const acceptImageFileTypes = "image/png, image/jpeg, image/jpg";

export const isAllowedImageFileType = (file?: File | null) => !!file && acceptImageFileTypes.includes(file.type);

export function getCategoryName(category: MediaCategoryViewModel) {
    switch (category.id) {
        case MEDIA_LIBRARY_MY_CATEGORY_ID:
            return strings.mediaLibraryMyCategory;
        case MEDIA_LIBRARY_UNCATEGORIZED_ID:
            return strings.mediaLibraryUncategorized;
        default:
            return category.name;
    }
}
