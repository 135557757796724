import { useMemo } from "react";
import { ChartProps } from "react-chartjs-2";
import BarChart from "./BarChart";
import styles from "./Chart.module.scss";

type Props = {
    title: string;
    labels: string[];
    data: number[];
    dataLabel: string;
};

const barThickness = 24;

export function HorizontalBarChart({ title, labels, data, dataLabel }: Props) {
    const options = useMemo(
        () =>
            ({
                indexAxis: "y" as const,
                responsive: true,
                maintainAspectRatio: false,
                maxBarThickness: barThickness,
                scales: {
                    x: {
                        beginAtZero: true,
                        precision: 0,
                        max: Math.max(...data, 10),
                    },
                },
                plugins: {
                    legend: {
                        position: "bottom" as const,
                    },
                    title: {
                        display: true,
                        text: title,
                    },
                },
            } as ChartProps<"bar">["options"]),
        [title, data],
    );

    const dataOptions = useMemo(
        () => ({
            labels,
            datasets: [
                {
                    label: dataLabel,
                    data,
                    backgroundColor: "#361dd1",
                },
            ],
        }),
        [labels, data, dataLabel],
    );

    return (
        <div className={styles.barChartContainer} style={{ height: data.length * (barThickness + 6) + 100 }}>
            <BarChart options={options} data={dataOptions} />
        </div>
    );
}
