import React from "react";
import strings from "../../../localization/strings";
import { MediaUsage, UsageType } from "../../../openapi/backend";
import { MediaDetailsModalProps } from "../../../types/MediaLibraryType";
import { hasValues } from "../../../utils.ts/Array";
import { statusToString } from "../../../utils.ts/GetStatus";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import styles from "./MediaDeleteConfirmationModal.module.scss";
import cx from "classnames";

export type Props = MediaDetailsModalProps & {
    onConfirm: () => void;
    usages: Array<MediaUsage>;
};

function getUsageTypeString(type: UsageType) {
    switch (type) {
        case UsageType.Happening:
            return strings.happening;
        case UsageType.Cluster:
            return strings.cluster;
        case UsageType.OrganisationInfo:
            return strings.organisationInfo;
    }
}

export const MediaDeleteConfirmationModal = ({ onClose, onConfirm, usages }: Props) => (
    <ConfirmationModal isOpen={true} onCancel={onClose} onConfirm={onConfirm}>
        <div className={styles.container}>
            <p className={styles.bodyText}>{strings.mediaDeleteConfirmationBody1}</p>
            {hasValues(usages) ? (
                <>
                    <p className={cx(styles.bodyText, styles.alert)}>
                        {strings.formatString(strings.mediaDeleteConfirmationBody2, usages.length)}
                    </p>
                    <ul className={styles.usageContainer}>
                        {usages.map((usage, index) => (
                            <li key={index} className={styles.row}>
                                <p className={cx(styles.bodyText, styles.inline)}>{`${getUsageTypeString(
                                    usage.type,
                                )}: ${usage.name}`}</p>
                                {usage.status && (
                                    <p className={cx(styles.bodyText, styles.inline)}>
                                        &nbsp;
                                        {`(${statusToString(usage.status)})`}
                                    </p>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <p className={cx(styles.bodyText, styles.lowkey)}>{strings.mediaDeleteConfirmationBody3}</p>
            )}
        </div>
    </ConfirmationModal>
);
