import React, { ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./MultiselectDropdown.module.scss";
import useToggle from "../../../../hooks/UseToggle";
import useRealBlur from "../../../../hooks/useRealBlur";
import SearchInput from "../SearchInput/SearchInput";
import { NamedOption } from "../../../../utils.ts/NamedOption";

interface MultiselectDropdownProps<T extends NamedOption> {
    options: T[];
    optionToString?: (option: T) => string;
    optionToKey?: (option: T) => string;
    renderOption?: (option: T) => ReactNode;
    renderSelection?: (options: T[]) => ReactNode;
    value?: T[];
    defaultValue?: T[];
    onChange?: (value: T[]) => void;
    onItemSelect?: (value: T[]) => void;
    placeholder: string;
    name: string;
    className?: string;
    searchable?: boolean;
    searchPlaceholder?: string;
    restrictToSingle?: boolean;
    disabled?: boolean;
}

interface ItemProps {
    child: ReactNode;
    selected: boolean;
    select: () => void;
}

function focusSearchInputElement(ref: React.RefObject<HTMLUListElement>) {
    (ref.current?.firstChild?.firstChild as HTMLElement | null)?.focus();
}

function focusFirstLiElement(ref: React.RefObject<HTMLUListElement>, searchable?: boolean) {
    (ref.current?.children[searchable ? 1 : 0] as HTMLElement | null)?.focus();
}

function SelectItem({ child, selected, select }: ItemProps) {
    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            var handled = false;

            switch (e.key) {
                case "ArrowDown":
                case "ArrowUp":
                    handled = true;
                    const next = e.key === "ArrowDown" ? e.currentTarget.nextSibling : e.currentTarget.previousSibling;

                    if (!next) {
                        break;
                    }

                    (next as HTMLElement).focus();
                    break;
                case " ":
                    handled = true;
                    select();
                    break;
            }

            if (handled) {
                e.stopPropagation();
                e.preventDefault();
            }
        },
        [select],
    );

    return (
        <li
            tabIndex={-1}
            aria-selected={selected}
            onKeyDown={onKeyDown}
            role={"option"}
            onClick={select}
            onMouseEnter={(e) => e.currentTarget.focus()}
        >
            <div className={`${styles.checkbox} ${selected && styles.selected}`}></div>
            {child}
        </li>
    );
}

function decorateSetStateAction<T>(action: (value: T[]) => T[], callback?: (value: T[]) => void): SetStateAction<T[]> {
    if (!callback) {
        return action;
    }

    return (current) => {
        const newValue = action(current);
        callback(newValue);
        return newValue;
    };
}

function filterOnSearch<T extends NamedOption>(options: T[], search: string) {
    const trimmedSearch = search.trim();
    return options.filter(
        (option) => trimmedSearch.length === 0 || option.name.toLowerCase().includes(trimmedSearch.toLowerCase()),
    );
}

export default function MultiselectDropdown<T extends NamedOption>({
    placeholder,
    value,
    defaultValue: defaultValueProp,
    options,
    optionToString = (option) => option.name,
    optionToKey = (option) => option.name,
    renderOption = (option) => <div>{optionToString(option)}</div>,
    renderSelection,
    name,
    className,
    onChange,
    onItemSelect,
    searchable,
    searchPlaceholder,
    restrictToSingle,
    disabled,
}: MultiselectDropdownProps<T>) {
    const defaultValue = useMemo<T[]>(() => defaultValueProp || [], [defaultValueProp]);
    const [isOpen, toggleOpen] = useToggle(false);
    const [innerValue, setInnerValue] = useState(value || defaultValue);
    const [search, setSearch] = useState("");
    const listRef = useRef<HTMLUListElement>(null);
    const selectOption = useCallback(
        (option: T, index: number) => {
            let setValue: (value: T[]) => T[];
            if (index >= 0) {
                setValue = (current) => [...current.filter((_, i) => i !== index)];
            } else if (restrictToSingle) {
                setValue = () => [option];
            } else {
                setValue = (current) => [...current, option];
            }

            setInnerValue(decorateSetStateAction(setValue, onItemSelect));
        },
        [setInnerValue, restrictToSingle, onItemSelect],
    );

    useEffect(() => setInnerValue(value || defaultValue), [value, defaultValue]);

    const renderedOptions = useMemo(() => {
        const innerValueFiltered = filterOnSearch(innerValue, search);

        return filterOnSearch(options, search)
            .sort((a, b) => {
                // Place selected options first
                const aIndex = innerValueFiltered.findIndex((x) => optionToKey(x) === optionToKey(a));
                const bIndex = innerValueFiltered.findIndex((x) => optionToKey(x) === optionToKey(b));

                if (aIndex >= 0 && bIndex < 0) {
                    return -1;
                }
                if (aIndex < 0 && bIndex >= 0) {
                    return 1;
                }
                return 0;
            })
            .map((option, i) => {
                const index = innerValueFiltered.findIndex((x) => optionToKey(x) === optionToKey(option));

                return (
                    <>
                        <SelectItem
                            key={`${name}-${i}`}
                            child={renderOption(option)}
                            selected={index >= 0}
                            select={() => selectOption(option, index)}
                        />
                        {i === innerValueFiltered.length - 1 && !restrictToSingle && <div className={styles.divider} />}
                    </>
                );
            });
    }, [renderOption, name, innerValue, selectOption, options, search, optionToKey, restrictToSingle]);

    // Close dropdown on Escape
    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            switch (e.key) {
                case "Escape":
                    toggleOpen(false);
            }
        },
        [toggleOpen],
    );

    // Close dropdown on blur
    const onBlur = useRealBlur(
        useCallback(() => {
            if (isOpen) {
                onChange && onChange(innerValue);
            }
            toggleOpen(false);
        }, [onChange, isOpen, innerValue, toggleOpen]),
    );

    // Focus first element when dropdown is opened
    useEffect(() => {
        if (isOpen) {
            if (searchable) {
                focusSearchInputElement(listRef);
            } else {
                focusFirstLiElement(listRef, searchable);
            }
        }
    }, [isOpen, listRef, searchable]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        // Clear search when dropdown gets closed
        setSearch("");
    }, [isOpen]);

    const onButtonKeyPress = useCallback<React.KeyboardEventHandler<HTMLButtonElement>>(
        (e) => {
            switch (true) {
                case e.key === "Tab" && !e.getModifierState("Shift"):
                case e.key === "ArrowDown":
                    // Focus li element when keyboard navigating away from searchinput
                    focusFirstLiElement(listRef, searchable);
                    e.preventDefault();
                    break;
            }
        },
        [listRef, searchable],
    );

    const onInputKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            switch (e.key) {
                case "ArrowDown":
                    // Focus li element when keyboard navigating away from searchinput
                    focusFirstLiElement(listRef, searchable);
                    e.preventDefault();
                    break;
            }
        },
        [listRef, searchable],
    );

    return (
        <div
            className={`${styles.input} ${isOpen && styles.active} ${className}`}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
        >
            <button
                type="button"
                className={styles.toggleButton}
                aria-haspopup="listbox"
                onClick={() => toggleOpen()}
                disabled={disabled}
            >
                {innerValue.length > 0 ? (
                    renderSelection ? (
                        renderSelection(innerValue)
                    ) : (
                        <div className={styles.value}>{innerValue.map(optionToString).join(", ")}</div>
                    )
                ) : (
                    <div className={styles.placeholder}>{placeholder}</div>
                )}
            </button>
            <ul role="listbox" className={`${!isOpen && styles.hidden}`} ref={listRef}>
                {searchable && (
                    <SearchInput
                        // Remount SearchInput when dropdown opens/closes
                        key={`SearchInput-${isOpen}`}
                        value={search}
                        onSearch={setSearch}
                        placeholder={searchPlaceholder}
                        onKeyDown={onInputKeyPress}
                        onButtonKeyDown={onButtonKeyPress}
                    />
                )}
                {renderedOptions}
            </ul>
        </div>
    );
}
