export interface NamedOption {
    name: string;
}

// Returns the NamedOptions that match any of the given names
export const findOptionsByNames = <T extends NamedOption>(names: string[], availableOptions: T[]): T[] =>
    names.reduce((acc: T[], name: string) => {
        const foundOption = availableOptions.find((option) => option.name === name);

        if (foundOption) {
            return [...acc, foundOption];
        } else {
            return acc;
        }
    }, []);
