import { useCallback, useEffect, useMemo, useState } from "react";
import { NotificationViewModelPaginatedViewModel } from "../../openapi/backend/models/NotificationViewModelPaginatedViewModel";
import Table from "../core/Table/Table";
import { getName } from "../../utils.ts/GetName";
import { Column } from "react-table";
import strings from "../../localization/strings";
import { GetNotificationsFun, NotificationsFilter } from "../../hooks/NotificationHooks";
import { formatAsDate, formatAsTime } from "../../utils.ts/Formatting";
import { getStringForNotificationGroup } from "../../utils.ts/GetNotificationGroup";
import { getStringForNotificationType } from "../../utils.ts/GetNotificationType";
import { StringFilter, useFilters } from "../../hooks/UseSearchParam";
import { NotificationFilterBar } from "../core/FilterBar/NotificationFilterBar";
import { doNothing } from "../../utils.ts/DoNothingCallback";

interface NotificationsHistoryTableProps {
    notifications?: NotificationViewModelPaginatedViewModel;
    getNotifications: GetNotificationsFun;
    error?: Response;
    id?: string;
    tableStyle?: string;
    className?: string;
}

interface NotificationsHistoryTableItem {
    Title: string;
    Message: string;
    Type: string;
    From: string;
    To: string;
    Date: string;
    Time: string;
}

export default function NotificationsHistoryTable({
    id,
    notifications,
    tableStyle,
    className,
    getNotifications,
}: NotificationsHistoryTableProps) {
    const [itemsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const filterSpecs = useMemo(
        () => ({
            query: new StringFilter(),
        }),
        [],
    );

    const [filter, setFilter] = useFilters(filterSpecs);

    const onFilterChange = useCallback(
        (filter: NotificationsFilter) => {
            setPage(1);

            setFilter(filter);
        },
        [setPage, setFilter],
    );

    const loadNotifications = useCallback(() => {
        return getNotifications({ itemsPerPage, page }, filter, id);
    }, [getNotifications, id, itemsPerPage, page, filter]);

    useEffect(() => {
        loadNotifications();
    }, [loadNotifications]);

    const columns: Column<NotificationsHistoryTableItem>[] = [
        {
            Header: strings.date,
            accessor: "Date",
            width: "10%",
        },
        {
            Header: strings.time,
            accessor: "Time",
            width: "5%",
        },
        {
            Header: strings.typeNotifications,
            accessor: "Type",
            width: "10%",
        },
        {
            Header: strings.title,
            accessor: "Title",
            width: "10%",
        },
        {
            Header: strings.messageNotifications,
            accessor: "Message",
        },
        {
            Header: strings.fromNotifications,
            accessor: "From",
            width: "15%",
        },
        {
            Header: strings.toNotifications,
            accessor: "To",
            width: "10%",
        },
    ];

    const data = useMemo<NotificationsHistoryTableItem[]>(() => {
        return (
            notifications?.items?.map((notification) => ({
                Date: formatAsDate(notification.createdOn),
                Time: formatAsTime(notification.createdOn),
                Type: getStringForNotificationType(notification.channel),
                Title: notification.title,
                Message: notification.body,
                From: getName(notification.by),
                To: getStringForNotificationGroup(notification.to?.audience || ""),
            })) ?? []
        );
    }, [notifications]);

    return (
        <div className={className}>
            <NotificationFilterBar onChange={onFilterChange} value={filter} />
            <Table
                className={tableStyle}
                columns={columns}
                data={data}
                onRowClick={doNothing}
                rowSelectable={false}
                setPage={setPage}
                emptyString={strings.noNotifications}
                paginationMeta={notifications?.meta}
            />
        </div>
    );
}
