import { ApiCallState, useApiCallback } from "../hooks/UseApiCall";
import { useCallback, useMemo } from "react";

export function useGoogleServices() {
    return useMemo(() => {
        try {
            return new google.maps.places.AutocompleteService();
        } catch (e) {
            console.warn("Failed initializing Google maps", e);
        }
    }, []);
}

/*
    Searches for schools in a rectangluar region bound by southwest / northeast coordinates (SW: 51.647224,3.816384, NE: 52.334668,5.051143). 
    To search for schools in the entire country, remove the locationrestriction parameter
*/
export const useSchoolPlaces = (): [
    ApiCallState<google.maps.places.AutocompleteResponse>,
    (query: string) => void,
    () => void,
] => {
    const googleMapsService = useGoogleServices();
    const callback = useCallback(
        async (query: string) => {
            if (!googleMapsService) {
                return Promise.resolve({ predictions: [] });
            }

            const response = await googleMapsService.getPlacePredictions({
                input: query,
                componentRestrictions: { country: "nl" },
                types: ["school"],
                language: "NL-nl",
                locationRestriction: new google.maps.LatLngBounds(
                    new google.maps.LatLng(51.647224, 3.816384),
                    new google.maps.LatLng(52.334668, 5.051143),
                ),
            });

            if (!response) {
                return Promise.reject(new Error("Something went wrong while retrieving school results"));
            }

            return response;
        },
        [googleMapsService],
    );

    return useApiCallback(callback);
};
