import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RequestState } from "../../../hooks/UseApiCall";
import strings from "../../../localization/strings";
import Form from "../../core/Form/Form/Form";
import { SmallModal } from "../../core/Modal/Modal";
import { UpdateClusterNameInput, useClusterNameUpdate } from "../../../hooks/ClusterHooks";
import FormField from "../../core/Form/FormField/FormField";
import { MAX_TITLE_LENGTH } from "../../../constants/Validation";

type FormInput = Omit<UpdateClusterNameInput, "id">;

interface ContainerProps {
    isOpen: boolean;
    onDismiss: () => void;
    onSuccess: () => void;
    clusterId: string;
    clusterName: string;
}

export interface ChangeClusterNameModalProps {
    isOpen: boolean;
    onSubmit: (input: Omit<UpdateClusterNameInput, "id">) => void;
    onDismiss: () => void;
    error?: Response;
    clusterName: string;
    isSubmitting: boolean;
}

const ChangeClusterNameModalContainer = ({ isOpen, onDismiss, onSuccess, clusterId, clusterName }: ContainerProps) => {
    const [{ state, error }, updateName] = useClusterNameUpdate();
    const onSubmit = useCallback(
        (input: Omit<UpdateClusterNameInput, "id">) => updateName({ id: clusterId, ...input }),
        [updateName, clusterId],
    );

    useEffect(() => {
        if (state === RequestState.DONE) {
            onDismiss();
            onSuccess();
        }
    }, [state, onDismiss, onSuccess]);

    return (
        <ChangeClusterNameModal
            isOpen={isOpen}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            error={error}
            clusterName={clusterName}
            isSubmitting={state === RequestState.LOADING}
        />
    );
};

const ChangeClusterNameModal = ({
    isOpen,
    onSubmit,
    onDismiss,
    error,
    clusterName,
    isSubmitting,
}: ChangeClusterNameModalProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormInput>();

    return (
        <SmallModal isOpen={isOpen} onDismiss={onDismiss} title={strings.update}>
            <Form
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                onDismiss={onDismiss}
                error={error}
                submitText={strings.save}
                cancelText={strings.cancel}
                waitingForSubmit={isSubmitting}
            >
                <FormField
                    name={"body"}
                    label={strings.clusterName}
                    placeholder={clusterName}
                    value={clusterName}
                    maxLength={MAX_TITLE_LENGTH}
                    errors={errors.body}
                    required
                    register={register}
                />
            </Form>
        </SmallModal>
    );
};

export default ChangeClusterNameModalContainer;
