import moment from "moment";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { MAX_PARTICIPANT_AGE, phoneNumber, postalCodePattern } from "../../constants/Validation";
import { Profile as ProfileModel } from "../../contexts/ProfileContext";
import { UpdateProfileInput } from "../../hooks/UserHooks";
import strings from "../../localization/strings";
import { Gender, Role } from "../../openapi/backend";
import { getStringForGender } from "../../utils.ts/GetGenderString";
import { getGenderOptions } from "../../utils.ts/StateOptions";
import Accordion from "../core/Accordion/Accordion";
import Form from "../core/Form/Form/Form";
import FormDatepicker from "../core/Form/FormDatePicker/FormDatePicker";
import FormField from "../core/Form/FormField/FormField";
import { FormStringSelect } from "../core/Form/FormSelect/FormSelect";
import AvatarInput from "../core/Inputs/AvatarInput/AvatarInput";
import styles from "./Profile.module.scss";
import ErrorMessage from "../core/ErrorMessage/ErrorMessage";

interface ProfileProps {
    profile: ProfileModel;
    updateProfile: (input: UpdateProfileInput) => void;
    updateAvatar: (file: File, id?: string) => void;
    isSubmitting: boolean;
    error?: Response;
}
const genderOptions = getGenderOptions();

export default function ProfileForm({ profile, updateProfile, updateAvatar, isSubmitting, error }: ProfileProps) {
    const localizedGenderOptions = useMemo(
        () => genderOptions.map((o) => ({ value: o.value, label: getStringForGender(o.value) })),
        [],
    );
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm<UpdateProfileInput>({
        defaultValues: {
            givenName: profile.givenName,
            familyName: profile.familyName,
            dateOfBirth: profile.dateOfBirth,
            gender: profile.gender || Gender.Unknown,
            phoneNumber: profile.phoneNumber,
            postalCode: profile.postalCode,
            profession: profile.profession,
        },
    });

    const dateOfBirth = watch("dateOfBirth");

    const isParticipant = profile.roles?.includes(Role.Participant);

    return (
        <div className={styles.profile}>
            <Accordion title={strings.profile} contentClassName={styles.accordionContent}>
                <div className={styles.formLeft}>
                    <Form onSubmit={updateProfile} handleSubmit={handleSubmit} waitingForSubmit={isSubmitting}>
                        <FormField
                            name="givenName"
                            register={register}
                            label={strings.givenName}
                            placeholder={""}
                            required
                            errors={errors.givenName}
                        />
                        <FormField
                            name="familyName"
                            register={register}
                            label={strings.familyName}
                            placeholder={""}
                            required
                            errors={errors.familyName}
                        />
                        <FormField
                            name="email"
                            register={register}
                            label={strings.email}
                            placeholder={""}
                            disabled
                            defaultValue={profile.email}
                        />
                        <FormStringSelect
                            name="gender"
                            label={strings.applicationFormGender}
                            control={control}
                            errors={errors.gender}
                            required
                            options={localizedGenderOptions}
                            placeholder=" "
                        />
                        <FormDatepicker
                            name="dateOfBirth"
                            title={strings.applicationFormBirthdate}
                            control={control}
                            value={dateOfBirth}
                            maxDate={new Date()}
                            minDate={moment().subtract(MAX_PARTICIPANT_AGE, "years").toDate()}
                            required
                            errors={errors.dateOfBirth}
                            useUtc={true}
                        />
                        <FormField
                            name="phoneNumber"
                            register={register}
                            label={strings.phone}
                            placeholder={""}
                            errors={errors.phoneNumber}
                            validationPattern={{ value: phoneNumber, message: strings.invalidPhoneNumber }}
                        />
                        <FormField
                            name="postalCode"
                            label={strings.applicationFormPostalCode}
                            register={register}
                            errors={errors.postalCode}
                            required
                            placeholder=""
                            validationPattern={{ value: postalCodePattern, message: strings.invalidPostalCode }}
                        />
                        {!isParticipant && (
                            <FormField
                                name="profession"
                                register={register}
                                label={strings.function}
                                placeholder={""}
                                errors={errors.profession}
                            />
                        )}
                    </Form>
                    {error && <ErrorMessage error={error} />}
                </div>
                <div className={styles.formRight}>
                    {profile && <AvatarInput name={"avatar"} profile={profile} onChange={updateAvatar} />}
                </div>
            </Accordion>
        </div>
    );
}
