import strings from "../localization/strings";
import { HappeningState, IndicatorStatus, MeasurementStatus } from "../openapi/backend";

export function statusToString(status: StatusType): string {
    return getStringForStatus(status);
}

function getStringForStatus(status: StatusType) {
    switch (status) {
        case HappeningState.Archived:
        case IndicatorStatus.Archived:
        case MeasurementStatus.Closed:
            return strings.archived;
        case HappeningState.Concept:
        case IndicatorStatus.Concept:
            return strings.concept;
        case HappeningState.Open:
        case IndicatorStatus.Open:
            return strings.open;
        case HappeningState.Cancelled:
            return strings.cancelled;
        case HappeningState.PartnerSuggestion:
            return strings.suggestion;
        case HappeningState.PlannedForPublication:
        case IndicatorStatus.PlannedForPublication:
            return strings.planned;
        case HappeningState.Rejected:
            return strings.rejected;
        default:
            return strings.unknown;
    }
}

export type StatusType = HappeningState | IndicatorStatus | MeasurementStatus;
